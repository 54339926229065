export default [
  {
    groupName: 'Labels.Information',
    fields: [
      {
        label: 'Labels.Name',
        name: 'name',
        required: true,
        disabled: false,
        component: 'text'
      },
      {
        label: 'OrgStructure.ParentUnitId',
        name: 'parentUnitId',
        required: false,
        disabled: false,
        component: 'text',
        type: 'number'
      },
      {
        label: 'OrgStructure.ParentUnitName',
        name: 'parentUnitName',
        required: false,
        disabled: false,
        component: 'text'
      }
    ]
  }
];
