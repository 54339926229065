import React, { useState } from 'react';
import Localize from 'react-intl-universal';
import { useNavigate } from 'react-location';

import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import Drawer from '@mui/material/Drawer';
import Icon from '@mui/material/Icon';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { styled } from '@mui/material/styles';

import { TAB_KEYS } from '@common/Constants';
import SideBarConfig from '@config/SideBarConfig';

const StyledList = styled(List)(({ theme }) => ({
  width: '100%',
  minWidth: theme.sizes.leftSidebar,
  backgroundColor: theme.palette.background.paper
}));

const SideBar = ({ hideSideBar, setHideSideBar }) => (
  <Drawer anchor="left" open={hideSideBar} onClose={() => setHideSideBar(!hideSideBar)}>
    <StyledList>
      {SideBarConfig.map((navItem, navItemIndex) => (
        <MenuItem
          setHideSideBar={setHideSideBar}
          key={navItemIndex}
          sx={{ display: 'block' }}
          navItem={navItem}
        ></MenuItem>
      ))}
    </StyledList>
    {/* <StyledLogo>
    // TODO: Think about where to put logo since it overlaps menu items on smaller screens
      <img alt="Contrimo Logo" src={logo} />
    </StyledLogo> */}
  </Drawer>
);

const MenuItem = ({ navItem, setHideSideBar }) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(true);

  const subItemClicked = (path) => {
    setHideSideBar(false);
    navigate({ to: path, search: { activeTab: TAB_KEYS.OVERVIEW }, replace: false });
  };

  return (
    <>
      <ListItemButton data-test-id={`${navItem.text}-list-open`} onClick={() => setOpen(!open)}>
        <ListItemIcon>
          <Icon>{navItem?.icon}</Icon>
        </ListItemIcon>
        <ListItemText primary={Localize.get(navItem.text)} />
        {open ? (
          <ExpandLess data-test-id={`${navItem.text}-open`} />
        ) : (
          <ExpandMore data-test-id={`${navItem.text}-close`} />
        )}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {navItem.sideNavigationSubItems.map((subItem, subItemIndex) => (
            <ListItemButton
              data-test-id={`${subItem.linkName}-name`}
              onClick={() => subItemClicked(subItem.linkName)}
              key={subItemIndex}
              sx={{ pl: 4 }}
              disabled={subItem?.isItemDisabled}
            >
              <ListItemIcon>
                <Icon>{subItem?.icon}</Icon>
              </ListItemIcon>
              <ListItemText primary={Localize.get(subItem.text)} />
            </ListItemButton>
          ))}
        </List>
      </Collapse>
    </>
  );
};

export default SideBar;
