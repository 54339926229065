import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HOTEL_PATHS } from '@common/network/ApiPaths';
import { getByPathAndParams } from '@services/BaseApi';

export const PAGE_SIZE = 5;
export const PAGE_SIZE_OPTIONS = [5, 10, 15];

export const initialState = {
  data: [],
  filter: {
    search: '',
    page: 0,
    size: PAGE_SIZE
  },
  totalPages: 0,
  totalElements: 0,
  isLoading: false,
  selectionModel: [],
  isEditEnabled: true
};

export const fetchContingents = createAsyncThunk(
  'locationContingents/getAll',
  ({ entityId, filter }) => {
    return getByPathAndParams({
      path: HOTEL_PATHS.GET_CONTINGENTS,
      pathVariables: { id: entityId },
      params: filter
    })
      .then((response) => response.data)
      .catch((error) => error);
  }
);

export const locationContingentsSlice = createSlice({
  name: 'locationContingents',
  initialState,
  reducers: {
    resetState: () => initialState,
    setData: (state, { payload }) => {
      state.data = payload;
    },
    setSelectionModel: (state, { payload }) => {
      if (Array.isArray(payload)) {
        state.selectionModel = [...payload];
      } else {
        const index = state.selectionModel.findIndex((s) => s === payload);
        if (index === -1) {
          state.selectionModel = [...state.selectionModel, payload];
        } else {
          state.selectionModel = [
            ...state.selectionModel.slice(0, index),
            ...state.selectionModel.slice(index + 1)
          ];
        }
      }
    },
    setFilterParams: (state, action) => {
      let newFilterValues = {};

      // Case when search value is reset to empty and search bar is closed
      if (action.payload.key === 'search' && !action.payload.value && !state.filter.search) {
        state.isLoading = false;
        return;
      }

      if (Array.isArray(action.payload)) {
        newFilterValues = action.payload.reduce(
          (obj, item) => ((obj[item.key] = item.value), obj),
          {}
        );
      } else {
        newFilterValues = { [action.payload.key]: action.payload.value };
      }

      state.filter = { ...state.filter, ...newFilterValues, page: action.payload.page ?? 0 };
    }
  },
  extraReducers: (builder) => {
    builder
      // Get all
      .addCase(fetchContingents.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.totalElements = payload.totalElements;
        state.totalPages = payload.totalPages;
        state.data = payload.content;
      })
      .addCase(fetchContingents.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchContingents.rejected, (state) => {
        state.data = [];
        state.isLoading = false;
      });
  }
});

export const selectList = (state) => state.locationContingents.data;
export const selectRows = (state) => state.locationContingents.rows;
export const selectTotalElements = (state) => state.locationContingents.totalElements;
export const selectTotalPages = (state) => state.locationContingents.totalPages;
export const selectFilter = (state) => state.locationContingents.filter;
export const selectIsLoading = (state) => state.locationContingents.isLoading;
export const selectSelectionModel = (state) => state.locationContingents.selectionModel;

const { actions, reducer } = locationContingentsSlice;

export const { setData, setFilterParams, resetState, setEmailAnchor, setSelectionModel } = actions;

export default reducer;
