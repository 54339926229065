import Localize from 'react-intl-universal';

import * as yup from 'yup';

export default () => {
  return yup.object().shape({
    name: yup.string().required(Localize.get('ValidationMessages.NameRequired')),
    minParticipants: yup
      .number()
      .min(0, Localize.get('ValidationMessages.MinNumber'))
      .max(yup.ref('maxParticipants'), Localize.get('ValidationMessages.MaxMinParticipants')),
    maxParticipants: yup
      .number()
      .min(1, Localize.get('ValidationMessages.MinNumber'))
      .required(Localize.get('ValidationMessages.MaxParticipants'))
      .when('minParticipants', {
        is: (minParticipants) => minParticipants,
        then: yup
          .number()
          .min(yup.ref('minParticipants'), Localize.get('ValidationMessages.MinMaxParticipants')),
        otherwise: yup.number()
      }),
    optParticipants: yup
      .number()
      .min(0, Localize.get('ValidationMessages.MinNumber'))
      .max(yup.ref('maxParticipants'), Localize.get('ValidationMessages.OptimalParticipants'))
      .when('minParticipants', {
        is: (minParticipants) => minParticipants,
        then: yup
          .number()
          .min(yup.ref('minParticipants'), Localize.get('ValidationMessages.OptimalParticipants')),
        otherwise: yup.number()
      }),
    timeBlockDate: yup
      .date()
      .required(Localize.get('ValidationMessages.DateRequired'))
      .typeError(Localize.get('ValidationMessages.DateInvalid')),
    startTime: yup
      .date()
      .required(Localize.get('ValidationMessages.StartTimeRequired'))
      .typeError(Localize.get('ValidationMessages.TimeInvalid')),
    endTime: yup
      .date()
      .required(Localize.get('ValidationMessages.EndTimeRequired'))
      .min(yup.ref('startTime'), Localize.get('ValidationMessages.EndTimeAfterStart'))
      .typeError(Localize.get('ValidationMessages.TimeInvalid'))
  });
};
