import { useState, useEffect } from 'react';

import useIsMounted from '@hooks/isMounted/isMounted';

function useFetching(
  fetchFn,
  fetchOnQ = false,
  customCallback = undefined,
  withoutLoadingOnMount = false
) {
  const setStateIfMounted = useIsMounted();
  const [shouldFetch, setShouldFetch] = useState(!fetchOnQ);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(!withoutLoadingOnMount && !fetchOnQ);
  const [pagination, setPagination] = useState(undefined);

  useEffect(() => {
    const asyncFetch = async () => {
      if (shouldFetch) {
        try {
          setLoading(true);
          const response = await fetchFn();
          if (response?.data?.content) {
            let responseDataContent = customCallback
              ? customCallback(response.data.content)
              : response.data.content;
            setStateIfMounted(setData, responseDataContent);
            setPagination({
              size: response.data.size,
              totalElements: response.data.totalElements,
              totalPages: response.data.totalPages,
              numberOfElements: response.data.numberOfElements
            });
          } else if (response?.data) {
            let responseData = customCallback ? customCallback(response.data) : response.data;
            setStateIfMounted(setData, responseData);
          }
        } catch (e) {
          setStateIfMounted(setData, null);
        } finally {
          setStateIfMounted(setLoading, false);
          setStateIfMounted(setShouldFetch, false);
        }
      }
    };
    asyncFetch();
  }, [shouldFetch]);

  return [data, loading, setShouldFetch.bind(null, true), setData, pagination];
}

export default useFetching;
