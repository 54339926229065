import { useRef, useState } from 'react';
import Localize from 'react-intl-universal';

import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import SearchIcon from '@mui/icons-material/Search';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles, styled } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  textField: {
    margin: 0,
    marginRight: '-55px',
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: `${theme.palette.primary.main} !important`
    }
  },
  input: {
    height: '30px'
  },
  icon: {
    fill: `${theme.palette.primary.main} !important`
  }
}));

const StyledIcon = styled(CancelOutlinedIcon)(({ theme }) => ({
  color: theme.palette.primary.main
}));

const GridToolbarSearch = ({ onChange = () => {} }) => {
  const classes = useStyles();
  const ref = useRef();
  const [isVisible, setIsVisible] = useState(false);
  const [value, setValue] = useState('');

  return !isVisible ? (
    <Button
      size="small"
      onClick={() => {
        setIsVisible(true);
        ref?.current?.focus();
      }}
      aria-label={Localize.get('Labels.Search')}
      variant="text"
      sx={{ mr: 1 }}
    >
      <SearchIcon />
      {Localize.get('Labels.Search')}
    </Button>
  ) : (
    <>
      <TextField
        inputRef={(input) => input && input.focus()}
        data-test-id="grid-toolbar-search"
        className={classes.textField}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        value={value}
        label={Localize.get('Labels.Search')}
        variant="outlined"
        margin="dense"
        size="small"
        type="input"
        InputLabelProps={{
          shrink: true
        }}
        InputProps={{
          ref: ref,
          className: classes.input,
          endAdornment: (
            <Tooltip title={Localize.get('Labels.Clear')}>
              <StyledIcon
                cursor="pointer"
                onClick={() => {
                  setValue('');
                  setIsVisible(false);
                  onChange('');
                }}
              />
            </Tooltip>
          )
        }}
      />
    </>
  );
};

export default GridToolbarSearch;
