// https://experience.sap.com/fiori-design-web/colors/

export default {
  mode: 'dark',
  common: {
    black: '#000',
    white: '#fff'
  },
  primary: {
    main: '#fff',
    light: '#333333',
    dark: '#9e9e9e',
    contrastText: '#333333'
  },
  secondary: {
    main: '#333333',
    light: '#333333',
    dark: '#333333',
    contrastText: '#333333'
  },
  launchpad: {
    main: '#333333',
    light: '#333333',
    dark: '#333333',
    border: '#E0E0E0',
    contrastText: '#E7ECF0',
    tileBackground: '#333333',
    newsLines: '#3F5161'
  },
  error: {
    main: '#BB0000',
    light: '#FF8888',
    dark: '#880000',
    contrastText: '#5E696E'
  },
  warning: {
    main: '#E78C07',
    light: '#FABD64',
    dark: '#E78C07'
  },
  info: {
    main: '#0092D1',
    light: '#91c8f6',
    dark: '#177DDC'
  },
  success: {
    main: '#2B7D2B',
    light: '#ABE2AB',
    dark: '#2B7C2B'
  },
  accent: {
    main: '#E6600D',
    light: '#E09D00',
    dark: '#C14646'
  },
  chart: {
    label: '#FFF'
  },
  grey: {
    50: '#333333',
    100: '#666666',
    200: '#BFBFBF',
    300: '#CCCCCC',
    400: '#333333',
    500: '#FFFFFF'
  },
  action: {
    active: '#0099DB',
    hover: 'rgba(0, 0, 0, 0.1)',
    hoverOpacity: '0.4'
  },
  eventStatus: {
    open: '#91C8F6',
    inProgress: '#ABE2AB',
    completed: '#D3D7D9',
    cancelled: '#FF8888',
    openDark: '#4aa5f0',
    inProgressDark: '#72ce72',
    completedDark: '#aab2b6',
    cancelledDark: '#ff3c3c'
  },
  calendar: {
    main: '#000',
    contrastText: '#427CAC',
    weekendDays: '#D10000',
    monthDays: '#FFFFFF',
    tileNow: '#383838',
    activeDaySidebar: '#427CAC',
    neighboringMonth: '#757575',
    labelColor: '#FFFFFF',
    bigCalOfRange: '#292929',
    bigCalToday: '#1f1f1f',
    bigCalBorder: '#282828'
  },
  login: {
    button: '#01579B'
  }
};
