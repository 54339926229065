import * as React from 'react';

import { get } from 'lodash';

import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

export default function ComboBox({
  field = {},
  form: { touched, errors } = {},
  options = [],
  labelStyle = {},
  required = false,
  label = '',
  ...rest
}) {
  return (
    <Autocomplete
      value={field?.value}
      multiple
      data-test-id="tags-outlined"
      size="small"
      options={options}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={(option) => option.value}
      filterSelectedOptions
      renderInput={(params) => (
        <TextField
          {...labelStyle}
          {...params}
          label={label}
          required={required}
          error={Boolean(get(touched, field?.name) && get(errors, field?.name))}
          helperText={get(touched, field?.name) && get(errors, field?.name)}
        />
      )}
      {...rest}
    />
  );
}
