import React from 'react';
import Localize from 'react-intl-universal';
import { useDispatch, useSelector } from 'react-redux';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Icon from '@mui/material/Icon';

import {
  selectIsOpen,
  selectConfig,
  closeConfirmDialog,
  CONFIRM_ACTIONS
} from './confirmDialogSlice';

const ConfirmDialog = () => {
  const isOpen = useSelector(selectIsOpen);
  const dispatch = useDispatch();

  const {
    confirmButton = Localize.get('Buttons.Confirm').toUpperCase(),
    cancelButton = Localize.get('Buttons.Cancel').toUpperCase(),
    confirmColor = 'error',
    cancelColor = 'primary',
    description = '',
    title = '',
    disabler = {},
    confirmIcon = 'delete',
    cancelIcon = 'cancel'
  } = useSelector(selectConfig);

  return (
    <Dialog
      open={isOpen}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{description}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          disableFocusRipple
          variant="outlined"
          startIcon={<Icon>{confirmIcon}</Icon>}
          data-test-id="confirm-dialog-confirm"
          disabled={disabler?.confirm}
          color={confirmColor}
          onClick={() => dispatch(closeConfirmDialog(CONFIRM_ACTIONS.Confirm))}
        >
          {confirmButton}
        </Button>
        <Button
          disableFocusRipple
          variant="outlined"
          startIcon={<Icon>{cancelIcon}</Icon>}
          data-test-id="confirm-dialog-cancel"
          disabled={disabler?.cancel}
          color={cancelColor}
          onClick={() => dispatch(closeConfirmDialog(CONFIRM_ACTIONS.Cancel))}
        >
          {cancelButton}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
