import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

// import { SORT_DIRECTION } from '@common/Constants';
// import { getByPathAndParams } from '@services/BaseApi';

import { CONTACTS_PATHS } from '@common/network/ApiPaths';
import { getByPathAndParams } from '@services/BaseApi';
// import { SORT_DATA_PARTICIPANTS } from '../../util/sortConfig';
export const PAGE_SIZE = 5;
export const PAGE_SIZE_OPTIONS = [5, 10, 15];
export const initialState = {
  data: [],
  rows: [],
  filter: {
    search: '',
    // WAITING FOR THE BE
    // sortBy: SORT_DATA_CONTACTS[0].name,
    // sortDirection: SORT_DIRECTION.ASCENDING,
    page: 0,
    size: PAGE_SIZE
  },
  totalPages: 0,
  totalElements: 0,
  isLoading: false,
  selectionModel: []
};

export const fetchContacts = createAsyncThunk('locationContact/contacts', (filterObj) => {
  return getByPathAndParams({
    path: CONTACTS_PATHS.GET_CONTACTS_TAB,
    pathVariables: { id: filterObj.entityId },
    params: filterObj.filter
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => error);
});

export const locationContactTableSlice = createSlice({
  name: 'locationContacts',
  initialState,
  reducers: {
    resetState: () => initialState,
    setData: (state, { payload }) => {
      state.data = payload;
    },
    setSelectionModel: (state, { payload }) => {
      if (Array.isArray(payload)) {
        state.selectionModel = [...payload];
      } else {
        const index = state.selectionModel.findIndex((s) => s === payload);
        if (index === -1) {
          state.selectionModel = [...state.selectionModel, payload];
        } else {
          state.selectionModel = [
            ...state.selectionModel.slice(0, index),
            ...state.selectionModel.slice(index + 1)
          ];
        }
      }
    },
    setFilterParams: (state, action) => {
      let newFilterValues = {};
      // Case when search value is reset to empty and search bar is closed
      if (action.payload.key === 'search' && !action.payload.value && !state.filter.search) {
        state.isLoading = false;
        return;
      }
      if (Array.isArray(action.payload)) {
        newFilterValues = action.payload.reduce(
          (obj, item) => ((obj[item.key] = item.value), obj),
          {}
        );
      } else {
        newFilterValues = { [action.payload.key]: action.payload.value };
      }
      state.filter = { ...state.filter, ...newFilterValues, page: action.payload.page ?? 0 };
    }
  },
  extraReducers: (builder) => {
    builder
      // Get all
      .addCase(fetchContacts.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.totalPages = payload?.totalPages || state.totalPages;
        state.totalElements = payload?.totalElements || state.totalElements;
        state.data = payload?.content ?? [];
      })
      .addCase(fetchContacts.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchContacts.rejected, (state) => {
        state.data = [];
        state.isLoading = false;
      });
  }
});

export const selectList = (state) => state.locationContacts.data;
export const selectRows = (state) => state.locationContacts.rows;
export const selectTotalElements = (state) => state.locationContacts.totalElements;
export const selectTotalPages = (state) => state.locationContacts.totalPages;
export const selectFilter = (state) => state.locationContacts.filter;
export const selectIsLoading = (state) => state.locationContacts.isLoading;
export const selectSelectionModel = (state) => state.locationContacts.selectionModel;

const { actions, reducer } = locationContactTableSlice;

export const { setData, setFilterParams, resetState, setSelectionModel } = actions;

export default reducer;
