import React from 'react';
import Localize from 'react-intl-universal';

import { Paper } from '@mui/material';
import { makeStyles, styled } from '@mui/styles';
import { DataGrid } from '@mui/x-data-grid';

import { PAGE_SIZE_OPTIONS } from '@common/Constants';
import getLocalesText from '@common/helpers/tables/getLocalesText';
import TableToolbar from '@components/TableToolbar';
import { containsOnlyDialogFilters, equalNumbersDialogFilters } from '@config/filterOperators';

import useDialogSearch from '../utils/useDialogSearch';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%'
  },
  toolbarContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(1),
    alignItems: 'center'
  },
  toolbar: {
    minHeight: '3rem'
  },
  content: {
    width: '100%',
    marginTop: theme.spacing(1)
  },
  container: {
    height: 400,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  tableRow: {
    '&:hover': {
      backgroundColor: `${theme.palette.grey[400]} !important`,
      cursor: 'pointer'
    }
  }
}));

export const StyledDataGrid = styled(DataGrid, {
  shouldForwardProp: (prop) => prop !== 'loading'
})(({ loading }) => ({ pointerEvents: loading ? 'none' : 'auto' }));

const DialogDataGrid = ({
  fetchDataPath = '/',
  initQueryState,
  columns,
  // selectionModel,
  selectItem,
  setModalOpen,
  extendedComponents,
  extendedComponentProps,
  getRowId = (params) => params?.id ?? '',
  organizationPersonId = null
}) => {
  const classes = useStyles();
  const {
    data,
    loadingData,
    pagination,
    setActivePage,
    itemHandler,
    activePage,
    changePageSize,
    pageSize,
    queryState
  } = useDialogSearch(fetchDataPath, initQueryState);

  return (
    <div className={classes.root}>
      <Paper className={classes.content}>
        <div className={classes.container}>
          <StyledDataGrid
            localeText={getLocalesText(Localize)}
            loading={loadingData}
            rows={data ? data.filter((el) => el.id !== organizationPersonId) : []}
            getRowId={getRowId}
            columns={[
              ...columns.map((col) => {
                return {
                  ...col,
                  headerName: Localize.get(col.headerName),
                  filterOperators:
                    col?.type === 'number' ? equalNumbersDialogFilters : containsOnlyDialogFilters
                };
              })
            ]}
            getRowClassName={() => classes.tableRow}
            components={{ Toolbar: TableToolbar }}
            componentsProps={{
              toolbar: {
                queryState,
                itemHandler,
                extendedComponents,
                extendedComponentProps
              }
            }}
            onSelectionModelChange={(value) => {
              if (data?.length) {
                selectItem(
                  data.find(({ id, hotel }) => id === value[0] || hotel?.id === value[0]),
                  queryState
                );
                setModalOpen(false);
              }
            }}
            pagination
            paginationMode="server"
            sortingMode="server"
            filterMode="server"
            page={activePage}
            pageSize={pageSize}
            rowCount={pagination?.totalElements || 0}
            rowsPerPageOptions={PAGE_SIZE_OPTIONS}
            onPageChange={setActivePage}
            onPageSizeChange={changePageSize}
            onFilterModelChange={itemHandler}
          />
        </div>
      </Paper>
    </div>
  );
};

export default DialogDataGrid;
