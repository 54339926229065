export default [
  {
    field: 'id',
    headerName: 'Labels.ID',
    width: 100,
    editable: false,
    defaultValue: '',
    headerAlign: 'left',
    sortable: false
  },
  {
    field: 'name',
    headerName: 'Labels.Name',
    width: 350,
    editable: false,
    defaultValue: '',
    headerAlign: 'left',
    sortable: false
  }
  // {
  //   field: 'city',
  //   headerName: 'Address.City',
  //   width: 250,
  //   editable: false,
  //   defaultValue: '',
  //   headerAlign: 'left',
  //   sortable: false
  // },
  // {
  //   field: 'country',
  //   headerName: 'Address.Country',
  //   width: 250,
  //   editable: false,
  //   defaultValue: '',
  //   headerAlign: 'left',
  //   sortable: false
  // },
  // {
  //   field: 'region',
  //   headerName: 'Address.Region',
  //   width: 250,
  //   editable: false,
  //   defaultValue: '',
  //   headerAlign: 'left',
  //   sortable: false
  // },
  // {
  //   field: 'rating',
  //   headerName: 'Hotel.Rating',
  //   width: 150,
  //   editable: false,
  //   defaultValue: '0',
  //   headerAlign: 'left',
  //   sortable: false,
  //   operatorValue: '=',
  //   type: 'number'
  // }
];
