import React from 'react';

import NoData from '@components/NoData';
import Tabs from '@components/Tabs';
import tabsConfig, { RESOURCES_TABS_COMPONENTS } from '@pages/Resources/util/tabsConfig';

const ResourcesTabs = ({
  entityId = '',
  entityType = '',
  details = null,
  setDetails = () => {}
}) => {
  return (
    <Tabs
      tabsConfig={tabsConfig}
      count={details?.counts}
      renderContent={(tab) => {
        const DynamicComponent = RESOURCES_TABS_COMPONENTS[tab?.key];
        return DynamicComponent ? (
          <DynamicComponent
            entityId={entityId}
            entityType={entityType}
            columns={tab.columns}
            toolbarOptions={tab.options}
            allowedEntityId={8}
            setDetails={setDetails}
          />
        ) : (
          <NoData />
        );
      }}
    ></Tabs>
  );
};

export default ResourcesTabs;
