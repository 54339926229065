import { Navigate } from 'react-location';

import { PARTICIPANTS_PATHS, SETTINGS_PATH } from '@common/network/ApiPaths';
import EntityTypes from '@common/network/EntityTypes';
import Participants, { CreateParticipant } from '@pages/Participants';
import { getByPathAndParams } from '@services/BaseApi';

const ParticipantRoute = (routeCache) => ({
  path: 'participants',
  children: [
    {
      path: '/',
      element: <Participants />,
      loader: routeCache.createLoader(
        async () => ({
          config: await getByPathAndParams({
            path: SETTINGS_PATH.GET,
            pathVariables: { tile: EntityTypes.PARTICIPANT }
          }),
          participantData: await getByPathAndParams({
            path: PARTICIPANTS_PATHS.PARTICIPANT_FORM
          }),
          participantStatuses: await getByPathAndParams({
            path: PARTICIPANTS_PATHS.PARTICIPANT_STATUSES
          })
        }),
        { policy: 'cache-first' }
      )
    },
    {
      path: 'new',
      element: <CreateParticipant />
    },
    {
      element: <Navigate to="/participants" />
    }
  ]
});

export default ParticipantRoute;
