import { copyObjectWithoutRef } from '@common/helpers/helpers';
import mapPropsToComponent from '@common/helpers/mapPropsToComponent';
import { ADDRESS_PATH, LOCALES_PATHS } from '@common/network/ApiPaths';
import create from '@pages/Employees/util/fields/create';

export const mapPropsToAddress = (setFieldValue, arrayItemIndex, arrayItem, values) => {
  return {
    ['region']: {
      formItemChangeHandler: (e, data) => {
        setFieldValue(`addressDtos[${arrayItemIndex}].region`, data?.newValue);
      },
      path: ADDRESS_PATH.GET_REGION_BY_ID,
      pathVariables: { id: arrayItem?.country?.id },
      pathVariablesRequired: ['id'],
      disabled: !values?.addressDtos[arrayItemIndex]?.country
    },
    ['country']: {
      path: LOCALES_PATHS.COUNTRIES,
      formItemChangeHandler: (e, data, name) => {
        const fieldIndex = name && name.split('.')[1];
        const addressDtos = copyObjectWithoutRef(values.addressDtos);
        addressDtos[fieldIndex].country = data.newValue;
        addressDtos[fieldIndex].region = null;
        setFieldValue('addressDtos', addressDtos);
      }
    }
  };
};

export const getFieldsConfig = (setFieldValue, phoneTypes, emailTypes, addressTypes) => {
  return mapPropsToComponent(create, {
    ['personDto.birthDate']: {
      maxDate: new Date(),
      onChange: (value) => setFieldValue('personDto.birthDate', value || null)
    },
    ['phoneDtos']: {
      ['type.id']: {
        options: phoneTypes
      }
    },
    ['emailDtos']: {
      ['type.id']: {
        options: emailTypes
      }
    },
    ['addressDtos']: {
      ['type.id']: {
        options: addressTypes
      }
    }
  });
};

export default getFieldsConfig;
