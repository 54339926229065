import React from 'react';
import Localize from 'react-intl-universal';
import { useSelector } from 'react-redux';

import Overview from '@components/Overview';
import { selectDetails, selectIsDetailsLoading } from '@pages/Employees/employeesSlice';

const config = {
  title: 'Employees.Overview',
  skeletonCount: 6,
  header: {
    title: 'Labels.EmployeeName',
    subTitle: ['personDto.firstName', 'personDto.lastName'],
    icon: 'badge'
  },
  details: [
    {
      label: 'Labels.Job',
      name: 'job'
    },
    {
      label: 'Labels.DepartmentName',
      name: 'department'
    },
    {
      label: 'Labels.CompanyName',
      name: 'companyName'
    },
    {
      label: 'Labels.ManagerName',
      name: 'managerName'
    }
  ]
};

const EmployeesOverview = () => {
  const details = useSelector(selectDetails);
  const isDetailsLoading = useSelector(selectIsDetailsLoading);

  return (
    <Overview
      invite={{
        text: Localize.get('Links.InviteLink', {
          entity: Localize.get('Employees.Item')
        }),
        link: '/employees/new'
      }}
      isLoading={isDetailsLoading}
      data={details}
      config={config}
    />
  );
};

export default EmployeesOverview;
