import React, { useState } from 'react';
import Localize from 'react-intl-universal';

import CancelIcon from '@mui/icons-material/Cancel';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import SaveIcon from '@mui/icons-material/Save';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { makeStyles } from '@mui/styles';

import { SORT_DIRECTION } from '@common/Constants';

const useStyles = makeStyles(() => ({
  displayPaper: {
    minWidth: '21rem'
  }
}));

const SortDialog = ({
  onCancel,
  open,
  sortState: { sortDirection = '', sortBy = '' } = {},
  fields = [],
  sortHandler,
  initialState = {}
}) => {
  const classes = useStyles();
  const [state, setState] = useState({
    sortDirection,
    sortBy
  });

  const onSortDirectionClick = (e) => {
    setState((prevState) => ({ ...prevState, sortDirection: e.target.value }));
  };

  const onSortByClick = (e) => {
    setState((prevState) => ({ ...prevState, sortBy: e.target.value }));
  };

  const onResetClick = () => {
    setState({
      sortDirection: initialState?.sortDirection || SORT_DIRECTION.DESCENDING,
      sortBy: initialState?.sortBy || 'id'
    });
  };

  const onSaveClick = () => {
    sortHandler(state);
    onCancel();
  };

  return (
    <Dialog
      fullWidth={false}
      maxWidth={'sm'}
      PaperProps={{ classes: { root: classes.displayPaper } }}
      open={open}
      onClose={onCancel}
      aria-labelledby="sort-dialog-title"
      aria-describedby="sort-dialog-description"
    >
      <DialogTitle sx={{ boxShadow: 3, marginBottom: '1rem', textAlign: 'center' }}>
        <Button
          data-test-id="resetButton"
          onClick={onResetClick}
          startIcon={<RestartAltIcon />}
          disableFocusRipple
          variant="outlined"
        >
          {Localize.get('Buttons.Reset')}
        </Button>
      </DialogTitle>

      <DialogContent sx={{ minWidth: '20rem' }}>
        <FormControl>
          <FormLabel data-test-id="sortOrder">{Localize.get('Labels.SortOrder')}</FormLabel>
          <RadioGroup
            onChange={onSortDirectionClick}
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={state.sortDirection}
          >
            <FormControlLabel
              value={SORT_DIRECTION.ASCENDING}
              control={<Radio data-test-id="ascButton" onChange={onSortDirectionClick} />}
              label={Localize.get('Labels.Ascending')}
            />
            <FormControlLabel
              value={SORT_DIRECTION.DESCENDING}
              data-test-id="customListItem"
              control={<Radio data-test-id="descButton" onChange={onSortDirectionClick} />}
              label={Localize.get('Labels.Descending')}
            />
          </RadioGroup>
          <FormLabel data-test-id="sortBy">{Localize.get('Labels.SortBy')}</FormLabel>
          <RadioGroup onChange={onSortByClick} value={state.sortBy}>
            {fields.map((item, index) => (
              <FormControlLabel
                data-test-id={`sortBy-${index}`}
                key={index}
                name={item.name}
                value={item.name}
                label={Localize.get(item.text)}
                control={<Radio />}
                checked={state.sortBy === item.name}
                onChange={onSortByClick}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </DialogContent>
      <DialogActions sx={{ backgroundColor: 'primary.contrastText', justifyContent: 'center' }}>
        <Button
          // sx={{ marginRight: '6rem' }}
          disableFocusRipple
          variant="outlined"
          startIcon={<CancelIcon />}
          data-test-id="cancel-dialog-sort "
          onClick={() => {
            onCancel();
            setState({ sortDirection, sortBy });
          }}
          color="error"
        >
          {Localize.get('Buttons.Cancel')}
        </Button>
        <Button
          disableFocusRipple
          variant="outlined"
          startIcon={<SaveIcon />}
          data-test-id="save-dialog-sort"
          onClick={onSaveClick}
        >
          {Localize.get('Buttons.Save')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SortDialog;
