import { Navigate } from 'react-location';

import Courses from '@pages/Courses';

const CourseRoute = () => ({
  path: 'courses',
  children: [
    {
      path: '/',
      element: <Courses />
    },
    {
      element: <Navigate to="/courses" />
    }
  ]
});

export default CourseRoute;
