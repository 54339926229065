import React, { useEffect } from 'react';
import Localize from 'react-intl-universal';
import { useMatch, useNavigate } from 'react-location';
import { useSelector, useDispatch } from 'react-redux';

import EntityTypes from '@common/network/EntityTypes';
import { clearState } from '@common/storage/persistSlice';
import { CONFIRM_ACTIONS, openConfirmDialog } from '@components/ConfirmDialog';
import LayoutContainer, { LeftContainer, RightContainer } from '@components/LayoutContainer';
import { setMode } from '@components/RightSidebar/rightSidebarSlice';

import { IltSessionTabs } from '../../components';
import Sidebar from '../../components/Sidebar';
import { setDetails, saveIltSession, deleteIltSession, selectDetails } from '../../iltSessionSlice';
import IltSessionDetails from './components/IltSessionDetails';

const Details = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    data: {
      session: { data: session }
    }
  } = useMatch();

  const details = useSelector(selectDetails);

  useEffect(() => dispatch(setDetails(session)), [session]);

  // Clear saved event information
  useEffect(() => {
    return () => {
      dispatch(clearState());
    };
  }, []);

  const onDelete = () => {
    dispatch(
      openConfirmDialog({
        title: Localize.get('ConfirmationMessages.Delete', {
          item: Localize.get('Events.Item')?.toLowerCase()
        }),
        confirmButton: Localize.get('Buttons.Delete'),
        cancelButton: Localize.get('Buttons.Cancel')
      })
    )
      .unwrap()
      .then((result) => {
        if (result === CONFIRM_ACTIONS.Cancel) {
          return;
        }

        dispatch(deleteIltSession(session?.id))
          .unwrap()
          .then(() => {
            dispatch(setMode('read'));
            navigate({ to: '/events', replace: false });
          })
          .catch((rejectedValueOrSerializedError) => console.error(rejectedValueOrSerializedError));
      });
  };

  const onSave = (values) => {
    dispatch(saveIltSession(values))
      .unwrap()
      .then(() => dispatch(setMode('read')))
      .catch((rejectedValueOrSerializedError) => {
        console.error(rejectedValueOrSerializedError);
      });
  };

  return (
    <LayoutContainer>
      <LeftContainer isFullScreen></LeftContainer>

      <RightContainer open={false} isFullScreen>
        <IltSessionDetails headerTitle={details?.name} entityId={details?.id} details={details}>
          <IltSessionTabs
            entityType={EntityTypes.ILT_SESSION}
            setDetails={setDetails}
            entityId={details?.id}
            details={details}
          />
        </IltSessionDetails>
      </RightContainer>

      <Sidebar details={details} onSave={onSave} onDelete={onDelete} />
    </LayoutContainer>
  );
};

export default Details;
