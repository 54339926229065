import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { ThemeProvider } from '@mui/material/styles';

import { lightTheme, darkTheme } from '@common/theme';
import { selectColorMode } from '@components/NavBar/navbarSlice';

const Theme = ({ children }) => {
  const colorMode = useSelector(selectColorMode);

  const theme = useMemo(() => (colorMode === 'light' ? lightTheme : darkTheme), [colorMode]);

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default Theme;
