import { useMemo } from 'react';
import Localize from 'react-intl-universal';
import { useMatch } from 'react-location';

import moment from 'moment';

import BlockIcon from '@mui/icons-material/Block';
import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';
import Box from '@mui/material/Box';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import { dateInitFormats } from '@common/Constants';
import Header from '@components/Header/Header';
import { StyledLink } from '@pages/Events/components/Sessions/Details/SessionDetails';

const StyledHeader = styled(Header)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'light' ? theme.palette.common.white : '',
  width: '100%',
  minHeight: '104px',
  height: '104px',
  padding: 0,
  boxShadow: 'none'
}));

const StyledInfoHeader = styled(Header)(({ theme }) => ({
  width: '100%',
  backgroundColor: theme.palette.primary.contrastText,
  padding: 0,
  color: theme.palette.grey[50],
  fontSize: '12px',
  fontWeight: 400,
  height: '48px',
  minHeight: '48px',
  zIndex: 10,
  boxShadow: theme.palette.mode === 'dark' ? 'none' : ''
}));

const StyledActionHeader = styled(Header)(({ theme }) => ({
  width: '100%',
  padding: 0,
  height: '56px',
  minHeight: '56px',
  color: theme.palette.grey[50],
  boxShadow: 'none',
  fontSize: '12px',
  fontWeight: 400,
  backgroundColor:
    theme.palette.mode === 'light' ? theme.palette.common.white : theme.palette.common.black
}));

const BookingHeader = ({
  isEdit = false,
  setIsEdit = () => {},
  onEventDelete = () => {},
  selectedEvents = [],
  data = {}
}) => {
  const {
    params,
    data: {
      session: { data: session }
    }
  } = useMatch();

  const renderInformation = useMemo(
    () => (
      <>
        <Typography variant="body1" color="text.primary" ml={2}>
          {Localize.get('Hotel.RoomType')}: {data?.accommodation?.roomType?.value}
        </Typography>
        <Typography variant="body1" color="text.primary">
          {Localize.get('Hotel.MaxOccupancy')}: {data?.accommodation?.maxOccupancy}
        </Typography>
        <Typography variant="body1" color="text.primary">
          {Localize.get('Labels.Amount')}: {data.contingent}
        </Typography>
        <Typography variant="body1" color="text.primary">
          {Localize.get('Hotel.CheckIn')}:{' '}
          {moment(data?.startDate).format(dateInitFormats.monthDay)}
        </Typography>
        <Typography variant="body1" color="text.primary">
          {Localize.get('Hotel.CheckOut')}: {moment(data?.endDate).format(dateInitFormats.monthDay)}
        </Typography>
        {data?.validUntil && (
          <Typography variant="body1" color="text.primary">
            {Localize.get('Labels.ValidUntil')}:
            {moment(data?.validUntil).format(dateInitFormats.monthDay)}
          </Typography>
        )}
      </>
    ),
    [data]
  );

  return (
    <StyledHeader display="flex" flexDirection="column">
      <StyledInfoHeader display="flex" flexDirection="row" justifyContent="center">
        <Breadcrumbs aria-label="breadcrumb">
          <StyledLink to="/ilt-session">{session?.name}</StyledLink>

          <StyledLink to={`/ilt-session/${params.sessionId}/hotels/${params.id}`}>
            {data?.hotelName}
          </StyledLink>

          <Typography sx={{ fontWeight: 600, color: 'action.active' }}>
            {Localize.get('Labels.Contingent')} {data?.id}
          </Typography>
        </Breadcrumbs>
      </StyledInfoHeader>
      <StyledActionHeader display="flex" flexDirection="row" justifyContent="space-between">
        {!isEdit && (
          <Box
            flexGrow={1}
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            {renderInformation}
            <Box
              flex={'0 0 29em'}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'end'
              }}
            >
              <Button
                data-test-id="edit"
                size="small"
                onClick={() => setIsEdit(true)}
                aria-label="search"
                variant="outlined"
                sx={{ mr: 10 }}
                startIcon={<EditIcon />}
              >
                {Localize.get('Buttons.Edit')}
              </Button>
            </Box>
          </Box>
        )}

        {isEdit && (
          <Box
            flexGrow={1}
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            {renderInformation}
            <Box flex={'0 0 29em'}>
              <Button
                disableFocusRipple
                data-test-id="obsolete"
                variant="outlined"
                color="error"
                sx={{ mr: 1 }}
                size="small"
                onClick={onEventDelete}
                startIcon={<BlockIcon />}
                disabled={!selectedEvents?.length}
              >
                {Localize.get('Buttons.Delete')}
              </Button>

              <Button
                disableFocusRipple
                data-test-id="cancel"
                variant="outlined"
                size="small"
                onClick={() => setIsEdit(false)}
                color="error"
                sx={{ mr: 1 }}
                startIcon={<CancelIcon />}
              >
                {Localize.get('Buttons.StopEditing')}
              </Button>
            </Box>
          </Box>
        )}
      </StyledActionHeader>
    </StyledHeader>
  );
};

export default BookingHeader;
