import ExternalLogin from '@pages/Login/ExternalLogin';
import Login from '@pages/Login/Login';

const publicRoutes = [
  {
    children: [
      {
        path: '/',
        element: <Login />
      },
      {
        path: '/saml-login/:externalToken',
        element: <ExternalLogin />
      },
      {
        path: '*',
        element: <Login />
      }
    ]
  }
];

export default publicRoutes;
