import React, { useEffect, useMemo } from 'react';
import Localize from 'react-intl-universal';
import { useSelector, useDispatch } from 'react-redux';

import Paper from '@mui/material/Paper';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import { DataGrid } from '@mui/x-data-grid';

import getLocalesText from '@common/helpers/tables/getLocalesText';
import TabContainer from '@components/TabContainer';
import TableToolbar from '@components/TableToolbar';
import ToolbarItem from '@components/TableToolbarItem';
import {
  fetchTasks,
  resetState,
  selectFilter,
  selectIsLoading,
  selectList,
  selectMode,
  selectSelectionModel,
  selectTotalElements,
  selectTotalPages,
  setFilterParams,
  setSelectionModel,
  setMode
} from '@pages/IltSession/components/Tabs/Tasks/tasksTableSlice';

export const PAGE_SIZE_OPTIONS = [5, 10, 15];

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  toolbarContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(1),
    alignItems: 'center'
  },
  toolbar: {
    minHeight: '3rem'
  },
  content: {
    margin: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  tableRow: {
    '&:hover': {
      backgroundColor: `${theme.palette.grey[400]} !important`,
      cursor: 'pointer'
    }
  },
  cellCheckbox: {
    padding: '5px 40px 5px 5px !important'
  },
  customTable: {
    overflowX: 'hidden',
    width: '92rem',
    '&:lastChild': {
      width: '100%',
      minWidth: 200
    }
  }
}));

const TasksTable = ({ columns = [], entityId = null, entityType = null, toolbarOptions = [] }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const data = useSelector(selectList);
  const filter = useSelector(selectFilter);
  const isLoading = useSelector(selectIsLoading);
  const totalElements = useSelector(selectTotalElements);
  const totalPages = useSelector(selectTotalPages);
  const selectionModel = useSelector(selectSelectionModel);
  const mode = useSelector(selectMode);

  useEffect(() => {
    if (entityId && entityType) {
      dispatch(fetchTasks({ entityId, filter }));
    }
  }, [entityId, filter?.page, filter?.sortBy, filter?.sortDirection, filter?.size]);

  // Resets state on component destroy
  useEffect(() => {
    return () => dispatch(resetState());
  }, []);

  const isToolbarItemDisabled = (fieldName) => {
    switch (fieldName) {
      case 'add_tasks':
        return !entityId || !entityType;
      case 'delete':
        return selectionModel.length === 0;
      default:
        return false;
    }
  };

  const onToolbarItemClick = (fieldName) => {
    switch (fieldName) {
      case 'add_tasks':
        dispatch(setMode('add_tasks'));
        break;
      case 'delete': {
        // TODO dispatch();
        break;
      }
      default:
        return false;
    }
  };

  const getComponents = useMemo(() => {
    return {
      Toolbar: () => <TableToolbar />
    };
  }, [mode]);

  return (
    <>
      <div className={classes.root}>
        <Paper className={classes.content}>
          <div className={classes.toolbarContainer}>
            <Typography variant="h6" component="h6" color="primary">
              {Localize.get('Labels.Tasks')}
            </Typography>
            <Toolbar className={classes.toolbar}>
              {toolbarOptions
                ?.filter((option) => {
                  if (mode === 'details') {
                    return (
                      option.fieldName === 'add_tasks' ||
                      option.fieldName === 'edit' ||
                      option.fieldName === 'delete'
                    );
                  }

                  return true;
                })
                ?.map((item, index) => (
                  <ToolbarItem
                    key={index}
                    item={item}
                    isDisabled={item.disabled || isToolbarItemDisabled(item.fieldName)}
                    onToolbarItemClick={onToolbarItemClick}
                  />
                ))}
            </Toolbar>
          </div>
          <TabContainer>
            <DataGrid
              keepNonExistentRowsSelected={true}
              loading={isLoading}
              rows={mode === 'add_tasks' ? [...data] : data}
              columns={[
                ...columns.map((column) => ({
                  ...column,
                  headerName: Localize.get(column.headerName)
                }))
              ]}
              initialState={{
                pagination: {
                  pageSize: totalPages,
                  rowCount: totalElements,
                  page: filter?.page
                }
              }}
              onSelectionModelChange={(newSelectionModel) => {
                dispatch(setSelectionModel(newSelectionModel));
              }}
              selectionModel={mode === 'add_tasks' ? [] : selectionModel}
              checkboxSelection
              localeText={getLocalesText(Localize)}
              pagination
              paginationMode="server"
              disableColumnMenu
              page={filter?.page}
              pageSize={filter?.size}
              rowCount={totalElements}
              rowsPerPageOptions={PAGE_SIZE_OPTIONS}
              disableSelectionOnClick
              components={getComponents}
              onPageChange={(page) => dispatch(setFilterParams({ page, selectionModel }))}
              onPageSizeChange={(value) =>
                dispatch(setFilterParams([{ key: 'size', value, selectionModel }]))
              }
            />
          </TabContainer>
        </Paper>
      </div>
    </>
  );
};

export default TasksTable;
