import React from 'react';
import Localize from 'react-intl-universal';
import { useMatch, useNavigate } from 'react-location';
import { useDispatch } from 'react-redux';

import { Formik } from 'formik';

import CancelIcon from '@mui/icons-material/Cancel';
import SaveIcon from '@mui/icons-material/Save';
import Button from '@mui/material/Button';

import { ACTION_MODES } from '@common/Constants';
import { errorMessageFormatter } from '@common/helpers/MessageFormatter';
import CreateForm from '@components/CreateForm';
import InlineCreateHeaderContainer from '@components/InlineCreateHeaderContainer/InlineCreateHeaderContainer';
import { showSnackbar, SnackbarSeverityTypes } from '@components/Snackbar/snackbarSlice';
import { createSession } from '@pages/Events/eventsSlice';
import useSessionSchema from '@pages/Events/util/schemas/useSessionSchema';
import initialValues from '@pages/Events/util/sessionInitialValues';

import getFieldsConfig from './getFieldsConfig';

const CreateSession = ({ event = null }) => {
  const {
    data: {
      sessionTypes: { data: sessionTypes }
    }
  } = useMatch();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const sessionsValidationSchema = useSessionSchema();
  const startTime = event?.dayScheduleDtos[0]?.startTime.split(':');
  const endTime = event?.dayScheduleDtos[0]?.endTime.split(':');

  const onSubmit = (values, { setSubmitting }) => {
    dispatch(createSession(values))
      .unwrap()
      .then(() => {
        localStorage.setItem('idAfterSessionCreate', JSON.stringify(event.id));
        navigate({
          search: (previousUrlParams) => ({ ...previousUrlParams, mode: 'list' }),
          replace: false
        });
      })
      .catch(({ data = {} }) => {
        dispatch(
          showSnackbar({
            message: errorMessageFormatter(data, 'SESSION', ACTION_MODES.Create),
            severity: SnackbarSeverityTypes.ERROR
          })
        );
      })
      .finally(() => setSubmitting(false));
  };

  const onCancel = () => {
    navigate({
      search: (previousUrlParams) => ({ ...previousUrlParams, mode: 'list' }),
      replace: false
    });
  };

  return (
    event && (
      <>
        <Formik
          validateOnMount
          enableReinitialize
          validationSchema={sessionsValidationSchema}
          onSubmit={onSubmit}
          initialValues={{
            eventName: event.name,
            eventStartDateTime: event.startDateTime,
            eventEndDateTime: event.endDateTime,
            venues: event.venues,
            timeBlockDate: new Date(event.startDateTime),
            startTime: new Date(0, 0, 0, startTime[0], startTime[1]),
            endTime: new Date(0, 0, 0, endTime[0], endTime[1]),
            dayScheduleId: event.dayScheduleDtos[0].id,
            ...initialValues
          }}
        >
          {({
            isSubmitting,
            handleSubmit,
            isValid,
            setFieldValue,
            values,
            setValues,
            setFieldTouched
          }) => (
            <>
              <InlineCreateHeaderContainer>
                <Button
                  data-test-id="cancel-btn"
                  variant="outlined"
                  onClick={onCancel}
                  color="error"
                  sx={{ mr: 1 }}
                  startIcon={<CancelIcon />}
                >
                  {Localize.get('Buttons.Cancel')}
                </Button>

                <Button
                  data-test-id="save-btn"
                  variant="outlined"
                  disabled={isSubmitting || !isValid}
                  onClick={handleSubmit}
                  startIcon={<SaveIcon />}
                >
                  {Localize.get('Buttons.Save')}
                </Button>
              </InlineCreateHeaderContainer>

              <CreateForm
                values={values}
                fieldsConfig={getFieldsConfig(
                  setFieldValue,
                  setFieldTouched,
                  values,
                  setValues,
                  event,
                  sessionTypes
                )}
              />
            </>
          )}
        </Formik>
      </>
    )
  );
};

export default CreateSession;
