import React from 'react';

import { styled } from '@mui/material/styles';

const StyledFooter = styled('footer')(({ theme }) => ({
  color: theme.palette.action.active,
  height: theme.sizes.masterListFooter,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignContent: 'center',
  boxShadow: theme.shadows[4],
  width: '100%',
  position: 'relative'
}));

const Footer = ({ children }) => <StyledFooter>{children}</StyledFooter>;

export default Footer;
