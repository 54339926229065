import React from 'react';
import Localize from 'react-intl-universal';

import moment from 'moment';

import GroupsIcon from '@mui/icons-material/Groups';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import { dateInitFormats } from '@common/Constants';

const CustomEvent = ({ event = null, onEventRemove = () => {} }) => {
  return (
    <Box
      sx={{
        m: 0,
        p: 0,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        alignContent: 'center',
        height: '2px'
      }}
    >
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          alignContent: 'center'
        }}
      >
        <GroupsIcon sx={{ color: (theme) => theme.palette.common.black, ml: 1 }} fontSize="small" />
        <Typography
          sx={{ display: 'flex', flexGrow: 1, justifyContent: 'center' }}
          fontSize="14px"
          color="black"
        >
          {`${moment(event.start).format(dateInitFormats.time)} -  ${moment(event.end).format(
            dateInitFormats.time
          )}`}
        </Typography>
        {event?.isDraggable && (
          <>
            <Tooltip
              title={<Typography variant="inherit">{Localize.get('Buttons.Remove')}</Typography>}
            >
              <IconButton
                disableFocusRipple
                disableRipple
                sx={{
                  display: 'flex',
                  flexGrow: 0,
                  width: '2px',
                  height: '2px',
                  color: (theme) => theme.palette.success.dark,
                  borderRadius: '50%',
                  textAlign: 'center',
                  background: 'black',
                  '&::after': {
                    content: "'\\00d7'",
                    display: 'inline-block',
                    marginTop: '1px',
                    fontSize: '22px'
                  }
                }}
                onClick={() => onEventRemove(event)}
              ></IconButton>
            </Tooltip>
          </>
        )}
      </Box>
      <Box>
        <Typography fontSize="16px">{event.name}</Typography>
      </Box>
    </Box>
  );
};

export default CustomEvent;
