import React from 'react';
import { Calendar as Cal } from 'react-calendar';
// import Localize from 'react-intl-universal';
import { useDispatch, useSelector } from 'react-redux';

// import { styled } from '@mui/material/styles';
// import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';

import { CAL_BAR_STATE_FORMAT } from '@common/Constants';
import { formatToDate, dateToFormat } from '@common/helpers/dates';
// import CheckboxInput from '@components/CheckboxInput';

// import { setFilters, selectFilters } from '../calendarSlice';
import { setSideBarDate, selectSideBarDate } from '../calendarSlice';

import 'react-calendar/dist/Calendar.css';

// const StyledGroupOfCheckboxes = styled('div')(
//   () => `
//    padding: 10px;
//    display: flex;
//    flex-direction: column;
//   `
// );

const calendarFontFamily = 'Helvetica, Arial, sans-serif';

const useStyles = makeStyles((theme) => ({
  calendarContainer: {
    gridColumnStart: '2',
    gridColumnEnd: '3',
    width: '320px',
    backgroundColor: `${theme.palette.calendar.main}`,
    padding: '10px',
    border: `1px solid ${theme.palette.primary.main}`,
    fontFamily: calendarFontFamily,

    '& .react-calendar__navigation__label__labelText': {
      color: `${theme.palette.primary.main}`,
      fontFamily: calendarFontFamily
    },
    '& .react-calendar__navigation__arrow': {
      color: `${theme.palette.primary.main}`,
      '@media (max-width: 1100px)': {
        minWidth: '20px'
      }
    },
    '& .react-calendar__tile--now': {
      backgroundColor: `${theme.palette.calendar.tileNow}`,
      '&:hover': {
        backgroundColor: `${theme.palette.grey[400]}`
      }
    },
    '& .react-calendar__month-view__days__day': {
      color: `${theme.palette.calendar.monthDays}`,
      fontFamily: calendarFontFamily
    },
    '& .react-calendar__month-view__days__day--weekend': {
      color: `${theme.palette.calendar.weekendDays}`
    },
    '& .react-calendar__month-view__days__day--neighboringMonth': {
      color: `${theme.palette.calendar.neighboringMonth}`
    },
    '& .react-calendar__month-view__weekdays__weekday': {
      color: `${theme.palette.primary.main}`
    },
    '& .react-calendar__year-view__months__month': {
      color: `${theme.palette.calendar.labelColor}`
    },
    '& .react-calendar__decade-view__years__year': {
      color: `${theme.palette.calendar.labelColor}`
    },
    '& .react-calendar__century-view__decades__decade': {
      color: `${theme.palette.calendar.labelColor}`
    },
    '& .react-calendar__tile--active, & .react-calendar__tile--rangeStart, & .react-calendar__tile--rangeEnd, & .react-calendar__tile--rangeBothEnds, & .react-calendar__tile--active:enabled:hover, & .react-calendar__tile--active:enabled:focus':
      {
        background: theme.palette.calendar.activeDaySidebar,
        backgroundColor: theme.palette.calendar.activeDaySidebar,
        color: '#FFFFFF'
      }
  }
}));

// const GroupTitle = ({ title }) => {
//   return (
//     <Typography component="h6" variant="h6" color="text.secondary" sx={{ mb: 3, mt: 4 }}>
//       {title}
//     </Typography>
//   );
// };

const Sidebar = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  // const filtersState = useSelector(selectFilters);
  const sideBarDate = useSelector(selectSideBarDate);

  // const onCheckboxChange = (value, field) => {
  //   dispatch(setFilters({ field, value }));
  // };

  const onDateClick = (date) => {
    dispatch(setSideBarDate(dateToFormat(date, CAL_BAR_STATE_FORMAT)));
  };

  return (
    <div>
      <Cal
        className={classes.calendarContainer}
        onChange={onDateClick}
        value={formatToDate(sideBarDate)}
      />
      {/* <StyledGroupOfCheckboxes>
        <GroupTitle title={Localize.get('Calendar.Filters')} />
        <CheckboxInput
          label={Localize.get('Event.Statuses.Open')}
          field={{
            disabled: true,
            value: filtersState.open,
            onChange: (e, data) => onCheckboxChange(data, 'open')
          }}
          // sx={{ lineHeight: '0.6rem' }}
        />
        <CheckboxInput
          label={Localize.get('Event.Statuses.In Progress')}
          field={{
            disabled: true,
            value: filtersState.inProgress,
            onChange: (e, data) => onCheckboxChange(data, 'inProgress')
          }}
        />
        <CheckboxInput
          label={Localize.get('Event.Statuses.Completed')}
          field={{
            disabled: true,
            value: filtersState.completed,
            onChange: (e, data) => onCheckboxChange(data, 'completed')
          }}
        />
        <CheckboxInput
          label={Localize.get('Event.Statuses.Cancelled')}
          field={{
            disabled: true,
            value: filtersState.cancelled,
            onChange: (e, data) => onCheckboxChange(data, 'cancelled')
          }}
        />
        <CheckboxInput
          label={Localize.get('Calendar.EventOfUtilizationGood')}
          field={{
            disabled: true,
            value: filtersState.eventOfUtilizationGood,
            onChange: (e, data) => onCheckboxChange(data, 'eventOfUtilizationGood')
          }}
        />
        <CheckboxInput
          label={Localize.get('Calendar.EventOfUtilizationMedium')}
          field={{
            disabled: true,
            value: filtersState.eventOfUtilizationMedium,
            onChange: (e, data) => onCheckboxChange(data, 'eventOfUtilizationMedium')
          }}
        />
        <CheckboxInput
          label={Localize.get('Calendar.EventOfUtilizationBad')}
          field={{
            disabled: true,
            value: filtersState.eventOfUtilizationBad,
            onChange: (e, data) => onCheckboxChange(data, 'eventOfUtilizationBad')
          }}
        />
        <CheckboxInput
          label={Localize.get('Calendar.FullyBooked')}
          field={{
            disabled: true,
            value: filtersState.fullyBooked,
            onChange: (e, data) => onCheckboxChange(data, 'fullyBooked')
          }}
        />
      </StyledGroupOfCheckboxes> */}
    </div>
  );
};

export default Sidebar;
