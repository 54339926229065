import React from 'react';
import Localize from 'react-intl-universal';
import { useNavigate } from 'react-location';

import HomeIcon from '@mui/icons-material/Home';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

const InitializeError = () => {
  const navigate = useNavigate();

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignContent="center"
      alignItems="center"
      sx={{ mt: 20 }}
    >
      <Typography fontWeight="500" color="error" variant="h3">
        {Localize.get('InitializeError.Title')}
      </Typography>
      <Button
        color="error"
        sx={{ mt: 5 }}
        disableFocusRipple
        variant="outlined"
        startIcon={<HomeIcon />}
        onClick={() => navigate({ to: '/', replace: true })}
      >
        {Localize.get('NotFound.InviteText')}
      </Button>
    </Box>
  );
};

export default InitializeError;
