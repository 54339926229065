import React from 'react';
import Localize from 'react-intl-universal';
import { useMatch } from 'react-location';

import { dateInitFormats } from '@common/Constants';
import { dateToFormat } from '@common/helpers/dates';
import RightSidebar from '@components/RightSidebar';
import sidebar from '@pages/Employees/util/fields/sidebar';
import useSidebarValidationSchema from '@pages/Employees/util/schemas/useSidebarSchema';

import { mapPropsToAddress } from '../CreateEmployees/getFieldsConfig';

const Sidebar = ({
  details = null,
  isDetailsLoading = false,
  onSave = () => {},
  onDelete = () => {}
}) => {
  const sidebarSchema = useSidebarValidationSchema();
  const {
    data: {
      phoneTypes: { data: phoneTypes },
      emailTypes: { data: emailTypes },
      addressTypes: { data: addressTypes }
    }
  } = useMatch();

  const extendedConfig = (setFieldValue) => ({
    ['personDto.birthDate']: {
      maxDate: new Date(),
      onChange: (newDate) =>
        setFieldValue('personDto.birthDate', dateToFormat(newDate, dateInitFormats.dateTime))
    },
    ['phoneDtos']: {
      ['type.id']: {
        options: phoneTypes
      }
    },
    ['emailDtos']: {
      ['type.id']: {
        options: emailTypes
      }
    },
    ['addressDtos']: {
      ['type.id']: {
        options: addressTypes
      }
    }
  });

  return (
    <RightSidebar
      config={{ title: Localize.get('Employees.Details'), fieldsConfig: sidebar }}
      extendedConfig={extendedConfig}
      data={details}
      validationSchema={sidebarSchema}
      isDetailsLoading={isDetailsLoading}
      onSave={onSave}
      onDelete={onDelete}
      mapPropsToAddress={mapPropsToAddress}
    />
  );
};

export default Sidebar;
