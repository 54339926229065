import React from 'react';
import { useSelector } from 'react-redux';

import Overview from '@components/Overview';
import { selectDetails, selectIsLoading } from '@pages/OrgStructures/orgStructureSlice';

const config = {
  title: 'OrgStructure.Overview',
  skeletonCount: 3,
  header: {
    title: 'OrgStructure.Name',
    subTitle: ['name'],
    icon: 'schema'
  },
  details: [
    {
      label: 'OrgStructure.ParentUnitName',
      name: 'parentUnitName'
    },
    {
      label: 'OrgStructure.AssociatedBusinessPartner',
      name: 'associatedBusinessPartner'
    }
  ]
};

const OrgStructureOverview = () => {
  const details = useSelector(selectDetails);
  const isLoading = useSelector(selectIsLoading);

  return <Overview isLoading={isLoading} data={details} config={config} />;
};

export default OrgStructureOverview;
