import React from 'react';
import Localize from 'react-intl-universal';

import Typography from '@mui/material/Typography';

const CustomShowMore = (total) => {
  return (
    <Typography
      sx={{
        cursor: 'pointer',
        color: (theme) => theme.palette.calendar.labelColor,
        backgroundColor: (theme) => theme.palette.calendar.bigCalOfRange,
        fontSize: '0.75rem'
      }}
    >{`+${total} ${Localize.get('Calendar.ShowMore')}`}</Typography>
  );
};

export default CustomShowMore;
