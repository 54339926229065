import React, { useEffect, useState } from 'react';
import { useMatch } from 'react-location';
import { useSelector, useDispatch } from 'react-redux';

import Checkbox from '@mui/material/Checkbox';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';

import DatePicker from '@components/DatePicker';
import TextInput from '@components/TextInput';

import { setNewPrices, selectNewPrices, setErrors, selectErrors } from '../priceTableSlice';
import { pricesSchema } from './utils/pricesSchema';

export default function RowCreate({ classes }) {
  const {
    data: {
      priceType: { data: priceType },
      currencyType: { data: currencyType }
    }
  } = useMatch();
  const dispatch = useDispatch();
  const prices = useSelector(selectNewPrices);
  const validationErrors = useSelector(selectErrors);
  const [touched, setTouched] = useState({});

  const handleOnChange = (e, fieldName) => {
    dispatch(setNewPrices({ ...prices, [fieldName]: e.target.value }));
  };

  const handleDateChange = (date, fieldName) => {
    dispatch(setNewPrices({ ...prices, [fieldName]: date }));
  };

  useEffect(() => {
    pricesSchema()
      .validate(prices, { abortEarly: false })
      .then(() => {
        dispatch(setErrors(null));
      })
      .catch((err) => {
        let errors = err.inner.reduce((errorsTemp, currentErr) => {
          errorsTemp[currentErr.path] = currentErr.errors.toString();
          return errorsTemp;
        }, {});
        dispatch(setErrors(errors));
      });
  }, [prices]);

  const onTouched = (fieldName) => {
    if (!touched[fieldName]) {
      setTouched((prevState) => ({ ...prevState, [fieldName]: true }));
    }
  };

  return (
    <TableContainer sx={{ overflowX: 'hidden' }}>
      <Table className={classes.customTable}>
        <TableBody>
          <TableRow sx={{ overflowX: 'hidden' }}>
            <TableCell
              className={classes.cellCheckbox}
              sx={{
                width: 5,
                maxWidth: 5,
                minWidth: 60
              }}
            >
              <Checkbox checked={false} disabled={true}></Checkbox>
            </TableCell>

            {/* price type */}
            <TableCell
              sx={{
                width: 250,
                maxWidth: 300,
                padding: '0 !important',
                align: 'left',
                minWidth: 250
              }}
            >
              <Select
                sx={{
                  width: 200,
                  height: 40
                }}
                labelId="pricesType-label"
                name="typeId"
                value={priceType[5]?.id || ''}
                disabled
              >
                {priceType[5] && (
                  <MenuItem key={priceType[5].id} value={priceType[5].id}>
                    {priceType[5].value}
                  </MenuItem>
                )}
              </Select>
            </TableCell>

            {/* price */}
            <TableCell
              sx={{
                width: 250,
                maxWidth: 250,
                padding: '0 !important',
                align: 'left',
                minWidth: 250
              }}
            >
              <TextInput
                variant="outlined"
                type="number"
                name="price"
                disableBlacklist={true}
                InputLabelProps={{
                  shrink: true
                }}
                onChange={(e) => handleOnChange(e, 'price')}
                onBlur={() => onTouched('price')}
                error={Boolean(validationErrors?.price && touched?.price)}
                label={
                  Boolean(touched?.price) && validationErrors?.price ? validationErrors?.price : ''
                }
                value={prices?.price}
              >
                {prices?.price}
              </TextInput>
            </TableCell>

            {/* currency */}
            <TableCell
              sx={{
                width: 250,
                maxWidth: 250,
                padding: '0 !important',
                align: 'left',
                minWidth: 250
              }}
            >
              <Select
                sx={{
                  width: 200,
                  height: 40
                }}
                labelId="currencyId-label"
                name="currencyId"
                value={prices?.currencyId}
                onChange={(e) => handleOnChange(e, 'currencyId')}
                onBlur={() => onTouched('currencyId')}
                error={Boolean(validationErrors?.currencyId && touched?.currencyId)}
              >
                {currencyType.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.value}
                  </MenuItem>
                ))}
              </Select>
            </TableCell>

            {/* valid from */}
            <TableCell
              sx={{
                width: 250,
                maxWidth: 250,
                padding: '0 !important',
                align: 'left',
                minWidth: 250
              }}
            >
              <DatePicker
                name="validFrom"
                variant="outlined"
                InputLabelProps={{
                  shrink: true
                }}
                onChange={(newDate) => handleDateChange(newDate, 'validFrom')}
                value={prices?.validFrom}
                field={{ name: 'validFrom', onBlur: () => onTouched('validFrom') }}
                form={{ errors: validationErrors }}
              />
            </TableCell>

            <TableCell
              sx={{
                width: 10,
                padding: '20 !important',
                align: 'left'
              }}
            />

            <TableCell
              sx={{
                padding: '0 !important',
                align: 'left'
              }}
            >
              <DatePicker
                name="validTo"
                variant="outlined"
                InputLabelProps={{
                  shrink: true
                }}
                onChange={(newDate) => handleDateChange(newDate, 'validTo')}
                value={prices?.validTo}
                field={{ name: 'validTo', onBlur: () => onTouched('validTo') }}
                form={{ errors: validationErrors, touched: touched }}
                isHelperTextVisible={false}
                label={
                  Boolean(touched?.validTo) && validationErrors?.validTo
                    ? validationErrors?.validTo
                    : ''
                }
              />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
