import { useState } from 'react';
import Localize from 'react-intl-universal';
import { useNavigate } from 'react-location';
import { useDispatch } from 'react-redux';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Button } from '@mui/material';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import { saveState } from '@common/storage/persistSlice';
import renderDescription, {
  StyledDescriptionContainer
} from '@pages/Courses/util/renderDescription';

import SessionRow from '../SessionRow/SessionRow';
import { StyledActive, StyledInactive } from '../Table/CoursesTable';

const MODULE_TYPE_ILT_SESSION = 12;

function ModuleRow({ module = {}, row = {} }) {
  const [isOpen, setOpen] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onSessionAdd = () => {
    dispatch(saveState({ module, course: row }));
    navigate({ to: '/ilt-session/new', replace: false });
  };

  return (
    <Table>
      <TableBody>
        <TableRow
          key={module.id}
          sx={{
            '& > *': { borderBottom: 'unset !important' },
            width: '100%',
            display: 'flex',
            flexDirection: 'row'
          }}
        >
          <TableCell
            sx={{ '& > *': { borderBottom: 'unset' }, width: '3%', paddingTop: '5px !important' }}
          >
            <IconButton
              aria-label="expand row"
              disabled={module.moduleType !== MODULE_TYPE_ILT_SESSION}
              onClick={() => setOpen(!isOpen)}
            >
              {isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>

          <TableCell
            align="left"
            sx={{
              width: '30%',
              minWidth: '30%',
              maxWidth: '30%',
              verticalAlign: 'middle !important'
            }}
          >
            <StyledDescriptionContainer width={'25rem'}>
              <Tooltip arrow title={renderDescription(module.name, true)}>
                {renderDescription(module.name)}
              </Tooltip>
            </StyledDescriptionContainer>
          </TableCell>
          <TableCell
            align="left"
            sx={{
              width: '30%',
              minWidth: '30%',
              maxWidth: '30%',
              overflowX: 'hidden',
              overflowY: 'auto',
              verticalAlign: 'middle !important'
            }}
          >
            <StyledDescriptionContainer width={'24rem'}>
              <Tooltip arrow title={renderDescription(module.description, true)}>
                {renderDescription(module.description)}
              </Tooltip>
            </StyledDescriptionContainer>
          </TableCell>

          <TableCell align="left" sx={{ width: '30%' }}>
            {module.type}
          </TableCell>
          <TableCell align="left" sx={{ width: '10%' }}>
            {module.active ? (
              <StyledActive>{Localize.get('Labels.Yes')}</StyledActive>
            ) : (
              <StyledInactive>{Localize.get('Labels.No')}</StyledInactive>
            )}
          </TableCell>
        </TableRow>

        <TableRow sx={{ display: 'table', width: '100%' }}>
          <TableCell>
            <Collapse in={isOpen} timeout="auto" unmountOnExit>
              <Box display="flex" flexDirection="row" justifyContent="space-between">
                <Typography variant="h6" m="6px" component="div">
                  {Localize.get('Labels.Sessions')}
                </Typography>

                <Button
                  data-test-id="add-session"
                  sx={{ m: 1 }}
                  variant="outlined"
                  aria-label="add"
                  onClick={onSessionAdd}
                  endIcon={<Icon>add</Icon>}
                >
                  {Localize.get('Courses.AddSession')}
                </Button>
              </Box>

              <Table>
                <TableHead
                  sx={{ backgroundColor: (theme) => theme.palette.calendar.bigCalOfRange }}
                >
                  <TableRow sx={{ display: 'table', width: '100%' }}>
                    <TableCell align="left" sx={{ width: '16%', fontWeight: 900 }}>
                      {Localize.get('Labels.Name')}
                    </TableCell>
                    <TableCell align="left" sx={{ width: '16%', fontWeight: 900 }}>
                      {Localize.get('Labels.Type')}
                    </TableCell>
                    <TableCell align="left" sx={{ width: '16%', fontWeight: 900 }}>
                      {Localize.get('Labels.Instructor')}
                    </TableCell>
                    <TableCell align="left" sx={{ width: '16%', fontWeight: 900 }}>
                      {Localize.get('Labels.Location')}
                    </TableCell>
                    <TableCell align="left" sx={{ width: '16%', fontWeight: 900 }}>
                      {Localize.get('Labels.StartDate')}
                    </TableCell>
                    <TableCell align="left" sx={{ width: '16%', fontWeight: 900 }}>
                      {Localize.get('Labels.EndDate')}
                    </TableCell>
                  </TableRow>
                </TableHead>
              </Table>

              {module?.sessionTreeDtos?.map((session) => (
                <SessionRow key={session?.id} session={session} />
              ))}
            </Collapse>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
}

export default ModuleRow;
