import { useSelector } from 'react-redux';

import { get } from 'lodash';

import TextField from '@mui/material/TextField';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import { INPUT_DATE_TIME_FORMAT } from '@config/inputs';
import { selectLanguage } from '@pages/Users/usersSlice';

const DateTimePickerInput = ({ field = {}, form: { errors } = {}, required = false, ...rest }) => {
  const lang = useSelector(selectLanguage);
  const isError = Boolean(get(errors, field?.name));
  const errorMsg = get(errors, field?.name);

  return (
    <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={lang}>
      <DateTimePicker
        inputFormat={INPUT_DATE_TIME_FORMAT}
        showToolbar
        renderInput={(params) => (
          <TextField
            size="small"
            helperText={errorMsg}
            required={required}
            {...field}
            {...params}
            error={isError}
          />
        )}
        {...field}
        {...rest}
      />
    </LocalizationProvider>
  );
};

export default DateTimePickerInput;
