import Localize from 'react-intl-universal';

import * as yup from 'yup';

export default function () {
  return yup.object().shape({
    userName: yup
      .string()
      .max(255, Localize.get('ValidationMessages.LogInCharacterLimit'))
      .required(Localize.get('ValidationMessages.UserRequired')),
    password: yup
      .string()
      .min(6, Localize.get('ValidationMessages.6Characters'))
      .required(Localize.get('ValidationMessages.PasswordRequired'))
  });
}
