const SEARCH_INPUT_DELAY = 1000;

const DATE_FORMAT = 'MMM, DD YYYY';

const INPUT_DATE_FORMAT = 'dd/MM/yyyy';

const INPUT_DATE_TIME_FORMAT = 'DD/MM/yyyy HH:mm';

const DATE_TIME_FORMAT = 'MMM, DD YYYY HH:mm';

const INPUT_HEIGHT = '40px';

const PHONE_REGEXP = /^[0-9+()./ -]+$/; // Accepts only numbers with special characters ()/+.-

export {
  SEARCH_INPUT_DELAY,
  PHONE_REGEXP,
  DATE_FORMAT,
  INPUT_HEIGHT,
  INPUT_DATE_FORMAT,
  INPUT_DATE_TIME_FORMAT,
  DATE_TIME_FORMAT
};
