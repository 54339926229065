import { omit } from 'lodash';

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { ILT_SESSION_PATHS } from '@common/network/ApiPaths';
import { getByPathAndParams } from '@services/BaseApi';

export const PAGE_SIZE = 5;
export const PAGE_SIZE_OPTIONS = [5, 10, 15, 20];

export const initialState = {
  data: [],
  rows: [],
  filter: {
    search: '',
    page: 0,
    size: PAGE_SIZE,
    // Saved in filter object but not sent as url param
    sessionId: ''
  },
  totalPages: 0,
  totalElements: 0,
  isLoading: false,
  selectionModel: []
};

export const fetchSessionDays = createAsyncThunk(
  'sessionDays/getSessionDays',
  ({ filter }) => {
    return getByPathAndParams({
      path: ILT_SESSION_PATHS.GET_SESSION_DAY,
      pathVariables: { id: filter.sessionId },
      params: omit(filter, 'sessionId')
    })
      .then((response) => response.data)
      .catch((error) => error);
  },
  {
    condition: ({ filter }) => Boolean(filter.sessionId)
  }
);

export const sessionDaysTableSlice = createSlice({
  name: 'sessionDays',
  initialState,
  reducers: {
    resetState: () => initialState,
    setData: (state, { payload }) => {
      state.data = payload;
    },
    setSelectionModel: (state, { payload }) => {
      state.selectionModel = payload;
    },
    setFilterParams: (state, action) => {
      let newFilterValues = {};

      // Case when search value is reset to empty and search bar is closed
      if (action.payload.key === 'search' && !action.payload.value && !state.filter.search) {
        state.isLoading = false;
        return;
      }

      if (Array.isArray(action.payload)) {
        newFilterValues = action.payload.reduce(
          (obj, item) => ((obj[item.key] = item.value), obj),
          {}
        );
      } else {
        newFilterValues = { [action.payload.key]: action.payload.value };
      }

      state.filter = {
        ...state.filter,
        ...newFilterValues
      };
    }
  },
  extraReducers: (builder) => {
    builder
      // Get all
      .addCase(fetchSessionDays.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.totalPages = payload?.totalPages || state.totalPages;
        state.totalElements = payload?.totalElements || state.totalElements;
        state.data = payload?.content || [];
      })
      .addCase(fetchSessionDays.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchSessionDays.rejected, (state) => {
        state.data = [];
        state.isLoading = false;
      });
  }
});

export const selectList = (state) => state.sessionDays.data;
export const selectTotalElements = (state) => state.sessionDays.totalElements;
export const selectTotalPages = (state) => state.sessionDays.totalPages;
export const selectFilter = (state) => state.sessionDays.filter;
export const selectIsLoading = (state) => state.sessionDays.isLoading;
export const selectSelectionModel = (state) => state.sessionDays.selectionModel;

const { actions, reducer } = sessionDaysTableSlice;

export const { setData, setFilterParams, resetState, setSelectionModel } = actions;

export default reducer;
