import React from 'react';
import Localize from 'react-intl-universal';

import { get } from 'lodash';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Rating from '@mui/material/Rating';
import { styled } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

const StyledListItem = styled(ListItem)(() => ({
  minHeight: '1.5rem'
}));

const isRatingVisible = (data) =>
  get(data, 'roles[0].supplierType.id') === 1 || get(data, 'roles[0].supplierType.id') === 4;

const ratingNumber = (data) => {
  return Number(get(data, 'roles[0].hotel.rating'));
};

const BasicInfo = ({ data = null }) => {
  return (
    <Box>
      <StyledListItem>
        <ListItemText
          sx={{ whiteSpace: 'nowrap' }}
          primary={
            <>
              <Typography
                sx={{ display: 'inline-block', mr: 1 }}
                component="span"
                variant="body2"
                color="text.primary"
              >
                {Localize.get('Communication.Phone')}:
              </Typography>

              <Tooltip
                title={get(data, 'organizationPerson.phoneDtos[0].phone')}
                placement="top"
                arrow
              >
                <Typography
                  noWrap={true}
                  sx={{
                    width: `calc(100% - ${Localize.get('Communication.Phone').length * 8}px)`,
                    display: 'inline-block',
                    verticalAlign: 'middle'
                  }}
                  component="span"
                  variant="body2"
                  color="text.secondary"
                >
                  {get(data, 'organizationPerson.phoneDtos[0].phone')}
                </Typography>
              </Tooltip>
            </>
          }
        />
      </StyledListItem>
      <Divider />

      <StyledListItem>
        <ListItemText
          sx={{ whiteSpace: 'nowrap' }}
          primary={
            <>
              <Typography
                sx={{ display: 'inline-block', mr: 1 }}
                component="span"
                variant="body2"
                color="text.primary"
              >
                {Localize.get('Communication.Email')}:
              </Typography>

              <Tooltip
                title={get(data, 'organizationPerson.emailDtos[0].email')}
                placement="top"
                arrow
              >
                <Typography
                  noWrap={true}
                  sx={{
                    width: `calc(100% - ${Localize.get('Communication.Email').length * 8}px)`,
                    display: 'inline-block',
                    verticalAlign: 'middle'
                  }}
                  component="span"
                  variant="body2"
                  color="text.secondary"
                >
                  {get(data, 'organizationPerson.emailDtos[0].email')}
                </Typography>
              </Tooltip>
            </>
          }
        />
      </StyledListItem>
      <Divider />

      <StyledListItem>
        <ListItemText
          sx={{ whiteSpace: 'nowrap' }}
          primary={
            <>
              <Typography
                sx={{ display: 'inline-block', mr: 1 }}
                component="span"
                variant="body2"
                color="text.primary"
              >
                {Localize.get('Communication.Website')}:
              </Typography>

              <Tooltip title={get(data, 'organizationPerson.website')} placement="top" arrow>
                <Typography
                  noWrap={true}
                  sx={{
                    width: `calc(100% - ${Localize.get('Communication.Website').length * 8}px)`,
                    display: 'inline-block',
                    verticalAlign: 'middle'
                  }}
                  component="span"
                  variant="body2"
                  color="text.secondary"
                >
                  {get(data, 'organizationPerson.website')}
                </Typography>
              </Tooltip>
            </>
          }
        />
      </StyledListItem>

      {isRatingVisible(data) && (
        <>
          <Divider />
          <StyledListItem>
            <ListItemText
              primary={
                <>
                  <Typography sx={{ mr: 1 }} component="span" variant="body2" color="text.primary">
                    {Localize.get('Hotel.Rating')}:
                  </Typography>

                  <Rating
                    sx={{
                      display: 'inline-flex',
                      verticalAlign: 'middle'
                    }}
                    readOnly
                    value={ratingNumber(data)}
                  />
                </>
              }
            />
          </StyledListItem>
        </>
      )}
    </Box>
  );
};

export default BasicInfo;
