import React, { useCallback, useMemo } from 'react';
import { Calendar as ReactCalendar, momentLocalizer, Views } from 'react-big-calendar';
import Localize from 'react-intl-universal';
import { useDispatch, useSelector } from 'react-redux';

import moment from 'moment';
import deLocale from 'moment/locale/de';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';

import { makeStyles } from '@mui/styles';
import useTheme from '@mui/styles/useTheme';

import CustomEvent from './components/CustomEvent';
import CustomToolbar from './components/CustomToolbar';
import {
  fetchData,
  selectDragAndDropEvents,
  setDragAndDropNonDraggableItems,
  selectDragAndDropResources,
  setDragAndDropRemoveEvent,
  selectDragAndDropFilter,
  selectIsLoading,
  selectVenuesFilterValue,
  setIsDiagramChanged
} from './dragAndDropDialogSlice';
import { isOverlap } from './utils';

const DragAndDropCalendar = withDragAndDrop(ReactCalendar);

moment.locale('en-GB');
moment.updateLocale('en', [deLocale]);
const localizer = momentLocalizer(moment);

const useStyles = makeStyles((theme) => ({
  calendarContainer: {
    textAlign: 'center',
    display: 'flex',
    width: '100%',
    height: 'calc(100vh - 3rem)'
  },
  calendarComponentLoading: {
    opacity: 0.7,
    pointerEvents: 'none'
  },
  calendarComponent: {
    height: (props) => props?.calendarComponent?.height || 'calc(100vh - 150px)',
    width: (props) =>
      props?.calendarComponent?.width || `calc(100% - ${theme.sizes.dragAndDropSidebar})`,
    padding: '5px',
    backgroundColor: theme.palette.calendar.main,
    '.rbc-allday-cell, & rbc-row': {
      display: 'none'
    },
    '& .rbc-event': {
      border: `1px solid black !important`
    },
    '& .rbc-month-row, & .rbc-time-content': {
      borderTop: `1px solid ${theme.palette.calendar.bigCalBorder}`
    },
    '& .rbc-event-label': {
      display: 'none'
    },
    '& .rbc-day-slot .rbc-events-container': {
      marginRight: '0px'
    },
    '& .rbc-event.isDraggable': {
      padding: '0 5px 0 0'
    },
    '& .rbc-today': {
      backgroundColor: theme.palette.common.white
    },
    '& .rbc-timeslot-group': {
      minHeight: '120px'
    },
    '& .rbc-label.rbc-time-header-gutter': {
      backgroundColor:
        theme.palette.mode === 'light' ? theme.palette.common.white : theme.palette.common.black
    },
    '&.is-loading': {
      opacity: 0.7,
      pointerEvents: 'none'
    }
  }
}));

const DragandropCalendar = ({
  entityId = '',
  fetchDataPath = '',
  customCss = {},
  withDialogProps = {}
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const classes = useStyles(customCss);

  const dragAndDropNonDraggableItems = useSelector(selectDragAndDropEvents);
  const dragAndDropResources = useSelector(selectDragAndDropResources);
  const dragAndDropFilter = useSelector(selectDragAndDropFilter);
  const isLoading = useSelector(selectIsLoading);
  const venuesFilter = useSelector(selectVenuesFilterValue) || [];

  const defaultScrollTime = useMemo(() => {
    const currentTime = moment(dragAndDropFilter?.sessionDayDate).toDate();
    currentTime.setHours(8);
    currentTime.setMinutes(0);
    currentTime.setSeconds(0);
    currentTime.setMilliseconds(0);
    return currentTime;
  }, [dragAndDropFilter?.sessionDayDate]);

  const eventPropGetter = useCallback(
    ({ isDraggable, iltSessionId }) => {
      const style = {
        backgroundColor: isDraggable
          ? theme.palette.success.dark
          : iltSessionId == entityId
          ? theme.palette.error.light
          : theme.palette.error.main,
        borderRadius: '3px',
        opacity: 0.8,
        color: 'black',
        border: '0px',
        display: 'block'
      };

      return {
        style: style,
        ...(isDraggable ? { className: 'isDraggable' } : { className: 'nonDraggable' })
      };
    },
    [entityId]
  );

  const customSlotPropGetter = () => {
    return {
      className: 'slot',
      style: {
        backgroundColor:
          theme.palette.mode === 'light' ? theme.palette.common.white : theme.palette.common.black
      }
    };
  };

  const { formats } = useMemo(() => {
    return {
      formats: {
        timeGutterFormat: (date) => {
          return moment(date).format('HH:mm');
        }
      }
    };
  }, [dragAndDropFilter?.sessionDayDate]);

  const scrollToTime = useMemo(() => {
    if (!dragAndDropNonDraggableItems?.length) {
      return defaultScrollTime;
    }

    return dragAndDropNonDraggableItems.reduce((prev, curr) => {
      if (curr.start && moment(curr.start).isAfter(moment(prev))) {
        return curr.start;
      }

      if (curr.predefinedStart && moment(curr.predefinedStart).isAfter(moment(prev))) {
        return curr.predefinedStart;
      }

      return prev;
    }, defaultScrollTime);
  }, [entityId, dragAndDropNonDraggableItems?.length]);

  const moveEvent = ({ event, start, end, resourceId }) => {
    if (isOverlap(dragAndDropNonDraggableItems, start, end, resourceId, event)) {
      return;
    }

    const maxDragAndDropTime = moment(dragAndDropFilter?.sessionDayDate).add(1, 'days').toDate();
    maxDragAndDropTime.setHours(0);
    maxDragAndDropTime.setMinutes(0);
    maxDragAndDropTime.setSeconds(0);
    maxDragAndDropTime.setMilliseconds(0);

    // Can't resize after 00:00
    if (moment(end).isSameOrAfter(moment(maxDragAndDropTime))) {
      return;
    }

    const existing = dragAndDropNonDraggableItems.find((ev) => ev.id === event.id) ?? {};
    const filtered = dragAndDropNonDraggableItems.filter((ev) => ev.id !== event.id);
    dispatch(
      setDragAndDropNonDraggableItems([...filtered, { ...existing, start, end, resourceId }])
    );
    dispatch(setIsDiagramChanged(true));
  };

  const resizeEvent = ({ event, start, end, resourceId }) => {
    // Can't resize overlapped elements
    if (isOverlap(dragAndDropNonDraggableItems, start, end, resourceId, event)) {
      return;
    }

    // Can't resize overlapped elements
    if (moment(start).isSame(moment(end))) {
      return;
    }

    const existing = dragAndDropNonDraggableItems.find((ev) => ev.id === event.id) ?? {};
    const filtered = dragAndDropNonDraggableItems.filter((ev) => ev.id !== event.id);

    dispatch(
      setDragAndDropNonDraggableItems([
        ...filtered,
        { ...existing, start, end, isPredefinedTimeInUse: false }
      ])
    );
    dispatch(setIsDiagramChanged(true));
  };

  const onEventRemove = (event) => dispatch(setDragAndDropRemoveEvent(event));

  const onRangeChange = (e) => {
    dispatch(
      fetchData({
        path: fetchDataPath,
        filter: {
          ...dragAndDropFilter,
          sessionDayDate: moment(e[0]).format(moment.HTML5_FMT.DATE)
        },
        filterValues: venuesFilter
      })
    );
  };

  return (
    <DragAndDropCalendar
      date={moment(dragAndDropFilter?.sessionDayDate).toDate()}
      className={`${classes.calendarComponent} ${isLoading ? 'is-loading' : ''}`}
      views={[Views.DAY]}
      defaultView={Views.DAY}
      scrollToTime={scrollToTime}
      formats={formats}
      onNavigate={() => {}}
      localizer={localizer}
      resourceIdAccessor="resourceId"
      resourceTitleAccessor="resourceTitle"
      draggableAccessor="isDraggable"
      resizableAccessor="isResizable"
      titleAccessor="name"
      dayLayoutAlgorithm="no-overlap"
      resources={dragAndDropResources}
      step={5}
      timeslots={6}
      resizable
      dragFromOutsideItem={withDialogProps?.dragFromOutsideItem}
      eventPropGetter={eventPropGetter}
      slotPropGetter={customSlotPropGetter}
      events={dragAndDropNonDraggableItems}
      onDropFromOutside={withDialogProps?.onDropFromOutside}
      onEventDrop={moveEvent}
      onEventResize={resizeEvent}
      onSelectSlot={withDialogProps?.newEvent}
      startAccessor={(event) => event.start}
      endAccessor={(event) => event.end}
      onRangeChange={onRangeChange}
      components={{
        toolbar: (props) => <CustomToolbar {...props} />,
        event: (props) => <CustomEvent {...props} onEventRemove={onEventRemove} />
      }}
      messages={{
        agenda: Localize.get('Calendar.Agenda'),
        allDay: Localize.get('Calendar.AllDay'),
        month: Localize.get('Calendar.Month'),
        week: Localize.get('Calendar.Week'),
        day: Localize.get('Calendar.Day'),
        today: Localize.get('Calendar.Today'),
        previous: Localize.get('Calendar.Previous'),
        next: Localize.get('Calendar.Next'),
        date: Localize.get('Calendar.Date'),
        noEventsInRange: Localize.get('Calendar.NoEventsInRange'),
        time: Localize.get('Calendar.Time'),
        tomorrow: Localize.get('Calendar.Tomorrow'),
        yesterday: Localize.get('Calendar.Yesterday')
      }}
    />
  );
};

export default DragandropCalendar;
