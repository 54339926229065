// import { TAB_KEYS } from '@common/Constants';
import { EVENTS_PATHS /*TAB_PATHS*/ } from '@common/network/ApiPaths';
// import { closeConfirmDialog } from '@components/ConfirmDialog/confirmDialogSlice';
import { getByPathAndParams } from '@services/BaseApi';

import {
  DELETE_ATTACHMENT_SUCCESS,
  LOAD_SESSIONS,
  SESSIONS_FILTER_UPDATE,
  SET_LOADING,
  SET_SELECTION_MODEL
} from './types';

export async function getSessions(dispatch, entityId, { page = 0, size = 10 }) {
  if (!entityId) {
    return;
  }

  dispatch(setLoading(true));

  try {
    const { data } = await getByPathAndParams({
      path: EVENTS_PATHS.GET_SESSIONS,
      pathVariables: { id: entityId },
      params: { page, size }
    });

    dispatch(loadSessions({ ...data, content: data?.content }));
  } catch (error) {
    console.error(error);
  }
}

export function setLoading(payload) {
  return {
    type: SET_LOADING,
    isLoading: payload
  };
}

export function loadSessions(payload) {
  return {
    type: LOAD_SESSIONS,
    payload
  };
}

export function setSelectionModel(payload) {
  return {
    type: SET_SELECTION_MODEL,
    payload
  };
}

export function removeComplete(payload) {
  return {
    type: DELETE_ATTACHMENT_SUCCESS,
    payload
  };
}

export function filterUpdate(payload) {
  return {
    type: SESSIONS_FILTER_UPDATE,
    payload
  };
}
