import React, { useEffect, useState, useCallback } from 'react';
import Localize from 'react-intl-universal';
import { useNavigate } from 'react-location';
import { useSelector, useDispatch } from 'react-redux';

import EntityTypes from '@common/network/EntityTypes';
import { CONFIRM_ACTIONS, openConfirmDialog } from '@components/ConfirmDialog';
import { selectIsFullScreen } from '@components/DetailsToolbar/detailsToolbarSlice';
import Header from '@components/Header';
import LayoutContainer, { LeftContainer, RightContainer } from '@components/LayoutContainer';
import MasterList from '@components/MasterList';
import MasterListFooter from '@components/MasterListFooter';
import MasterListItem from '@components/MasterListItem';
import MasterListTitle from '@components/MasterListTitle';
import MasterListToolbar from '@components/MasterListToolbar';
import { selectIsOpen, setMode } from '@components/RightSidebar/rightSidebarSlice';
import SortDialog from '@components/SortDialog';
import { SEARCH_INPUT_DELAY } from '@config/inputs';
import useDebounce from '@hooks/handlers/useDebounce';
import {
  setFilterParams,
  setLoading,
  selectFilter,
  selectTotalElements,
  selectTotalPages,
  selectLocationDetails,
  selectIsLoading,
  selectList,
  setSelectedId,
  selectId,
  selectIsDetailsLoading,
  initialState,
  deleteBusinessPartner,
  fetchBusinessPartners,
  fetchLocationDetails,
  saveLocations,
  resetState,
  setLocationDetails
} from '@pages/BusinessPartners/businessPartnersSlice';

import { LocationsDetails, LocationsTabs } from './components';
import Sidebar from './components/Sidebar';
import {
  setCancelCreateMode,
  setSelectionModel
} from './components/Tabs/AccommodationTable/accommodationTableSlice';
import { getFirstRow } from './util/helpers';
import { SORT_DATA } from './util/sortConfig';

const Locations = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const selectedId = useSelector(selectId);
  const filter = useSelector(selectFilter);
  const data = useSelector(selectList);
  const details = useSelector(selectLocationDetails);
  const totalElements = useSelector(selectTotalElements);
  const totalPages = useSelector(selectTotalPages);
  const [isOpenDialogSort, setIsOpenDialogSort] = useState(false);

  const isDetailsLoading = useSelector(selectIsDetailsLoading);
  const isLoading = useSelector(selectIsLoading);

  const isOpen = useSelector(selectIsOpen);
  const isFullScreen = useSelector(selectIsFullScreen);

  // Get initial List of entities and on sort change
  useEffect(() => {
    dispatch(fetchBusinessPartners({ ...filter, isLocations: true }));
  }, [filter.sortBy, filter.sortDirection, filter.page]);

  // Get Details when selectedId changes
  useEffect(() => dispatch(fetchLocationDetails(selectedId)), [selectedId]);

  // Get initial List of entities on search change
  useDebounce(
    () => dispatch(fetchBusinessPartners({ ...filter, isLocations: true })),
    SEARCH_INPUT_DELAY,
    [filter.search]
  );

  useEffect(() => {
    return () => dispatch(resetState());
  }, []);

  const onEditSave = (values) =>
    dispatch(saveLocations(values))
      .unwrap()
      .then(() => {
        return Promise.resolve();
      })
      .catch((rejectedValueOrSerializedError) => {
        return Promise.reject({
          rejectedValueOrSerializedError,
          entityType: EntityTypes.LOCATION
        });
      });

  const onDelete = () => {
    dispatch(
      openConfirmDialog({
        title: Localize.get('ConfirmationMessages.Delete', {
          item: Localize.get('Locations.Item')?.toLowerCase()
        }),
        confirmButton: Localize.get('Buttons.Delete'),
        cancelButton: Localize.get('Buttons.Cancel')
      })
    )
      .unwrap()
      .then((result) => {
        if (result === CONFIRM_ACTIONS.Cancel) {
          return;
        }
        dispatch(deleteBusinessPartner({ id: selectedId, type: EntityTypes.LOCATION }))
          .unwrap()
          .then(() => dispatch(setMode('read')));
      });
  };

  // Render Master List Item
  const renderMasterListItem = useCallback(
    ({ id, organizationPerson, roles }) => {
      return (
        <MasterListItem
          key={id}
          id={id}
          heading={{ left: organizationPerson?.name, right: id }}
          rightDetails={{ firstRow: getFirstRow(roles, Localize.get('Labels.HotelAndVenue')) }}
          selectedId={selectedId}
          onItemSelect={(payload) => {
            dispatch(setSelectedId(payload));
            dispatch(setCancelCreateMode());
            dispatch(setSelectionModel([]));
          }}
        />
      );
    },
    [selectedId]
  );

  return (
    <LayoutContainer>
      <LeftContainer isFullScreen={isFullScreen}>
        <Header>
          <MasterListTitle>{`${Localize.get(
            'Locations.MasterListTittle'
          )} (${totalElements})`}</MasterListTitle>
        </Header>

        <MasterListToolbar
          isDisabled={data?.length === 0}
          onSortClick={() => setIsOpenDialogSort(true)}
          onFilterClick={() => alert('Not implemented.')}
          isSortActive={
            filter.sortBy !== initialState.filter.sortBy ||
            filter.sortDirection !== initialState.filter.sortDirection
          }
          searchProps={{
            value: filter.search,
            onSearchChange: (e) => {
              dispatch(setLoading(true));
              dispatch(setFilterParams({ key: 'search', value: e.target.value }));
            }
          }}
        />

        <MasterList isLoading={isLoading} data={data} renderMasterItem={renderMasterListItem} />
        <MasterListFooter
          onPageChange={(e, page) => dispatch(setFilterParams({ ...filter, page: page - 1 }))}
          onAddClick={() => navigate({ to: '/locations/new', replace: false })}
          pagination={{ totalPages, location: true, page: filter.page + 1 }}
        />

        <SortDialog
          open={isOpenDialogSort}
          onCancel={() => setIsOpenDialogSort(false)}
          sortState={filter}
          fields={SORT_DATA}
          sortHandler={({ sortBy, sortDirection }) => {
            dispatch(
              setFilterParams([
                { key: 'sortBy', value: sortBy },
                { key: 'sortDirection', value: sortDirection }
              ])
            );
          }}
        />
      </LeftContainer>

      <RightContainer open={isOpen} isFullScreen={isFullScreen}>
        <LocationsDetails entityId={selectedId} details={details}>
          <LocationsTabs
            entityId={selectedId}
            entityType={EntityTypes.BUSINESS_PARTNER}
            details={details}
            setDetails={setLocationDetails}
          />
        </LocationsDetails>
      </RightContainer>

      <Sidebar
        onSave={onEditSave}
        details={details}
        isDetailsLoading={isDetailsLoading}
        onDelete={onDelete}
      />
    </LayoutContainer>
  );
};

export default Locations;
