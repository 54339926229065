import React from 'react';
import Localize from 'react-intl-universal';
import { useDispatch } from 'react-redux';

import { get } from 'lodash';

import CloseIcon from '@mui/icons-material/Close';
import FilterNoneIcon from '@mui/icons-material/FilterNone';
import FormHelperText from '@mui/material/FormHelperText';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';

import { setRemoveResult, setDragAndDropIsDialogOpen } from './dragAndDropDialogSlice';
import DragAndDropWithDialog from './DragAndDropWithDialog';

const StyledIcon = styled(FilterNoneIcon)(
  () => `
    &:hover {
      cursor: pointer;
    }
  `
);

function Tag(props) {
  const { label, onDelete, disabled, ...other } = props;
  return (
    <div {...other}>
      <span>{label}</span>
      <CloseIcon disabled={disabled} onClick={onDelete} />
    </div>
  );
}

const StyledTag = styled(Tag)(
  ({ theme }) => `
  display: flex;
  align-items: center;
  height: 24px;
  margin: 2px;
  line-height: 22px;
  background-color: ${theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.08)' : '#fafafa'};
  border: 1px solid ${theme.palette.mode === 'dark' ? '#303030' : '#e8e8e8'};
  border-radius: 2px;
  box-sizing: content-box;
  padding: 0 4px 0 10px;
  outline: 0;
  overflow: hidden;

  &:focus {
    border-color: ${theme.palette.mode === 'dark' ? '#177ddc' : '#40a9ff'};
    background-color: ${theme.palette.mode === 'dark' ? '#003b57' : '#e6f7ff'};
  }

  & span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 1rem;
    
  }

  & svg {
    font-size: 12px;
    cursor: pointer;
    padding: 4px;
  }
`
);

const DragAndDropWithDialogWithInput = ({
  field = {},
  form: { touched, errors } = {},
  dialogTitle = '',
  helperText = '',
  tokenValue,
  disabled = false,
  onIconClick = () => {},
  required = false,
  fetchDataPath = '',
  initQueryState = {},
  ...rest
}) => {
  const dispatch = useDispatch();

  return (
    <>
      <DragAndDropWithDialog
        initQueryState={initQueryState}
        fetchDataPath={fetchDataPath}
        dialogTitle={dialogTitle}
        onIconClick={onIconClick}
        {...rest}
      />
      <TextField
        label="limitTags"
        onKeyPress={(event) => event.preventDefault()}
        style={{ caretColor: 'transparent' }}
        autoComplete="off"
        readOnly={true}
        disabled={disabled}
        size="small"
        variant="outlined"
        error={Boolean(get(touched, field?.name) && get(errors, field?.name))}
        helperText={get(touched, field?.name) && get(errors, field?.name)}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                data-test-id={field?.name}
                disabled={disabled}
                onClick={() => {
                  dispatch(setDragAndDropIsDialogOpen(true));
                }}
              >
                <StyledIcon />
              </IconButton>
            </InputAdornment>
          ),
          startAdornment: tokenValue?.length ? (
            <InputAdornment position="start">
              {tokenValue.map((option, index) => (
                <IconButton
                  disableRipple
                  disabled={disabled}
                  key={index}
                  onClick={() => {
                    dispatch(setRemoveResult());
                    onIconClick({ target: { name: 'delete' } });
                  }}
                >
                  <StyledTag label={option.title} />
                </IconButton>
              ))}
            </InputAdornment>
          ) : null
        }}
        {...field}
        {...rest}
        required={required || Boolean(get(errors, field?.name))}
      ></TextField>

      {helperText && (
        <FormHelperText id="drag-and-drop-helper-text">{Localize.get(helperText)}</FormHelperText>
      )}
    </>
  );
};

export default DragAndDropWithDialogWithInput;
