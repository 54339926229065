import React from 'react';

import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

const StyledContainer = styled(Box)(({ theme }) => ({
  paddingTop: theme.spacing(1),
  overflowY: 'scroll',
  height: '100vh'
}));

const DetailsContainer = ({ children }) => {
  return <StyledContainer>{children}</StyledContainer>;
};

export default DetailsContainer;
