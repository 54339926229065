import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

// import { SORT_DIRECTION } from '@common/Constants';
import { DOCUMENTS_PATHS, EVENTS_PATHS } from '@common/network/ApiPaths';
import { getByPathAndParams, postByPathAndData } from '@services/BaseApi';

// import { SORT_DATA_PARTICIPANTS } from '../../util/sortConfig';
export const PAGE_SIZE = 5;
export const PAGE_SIZE_OPTIONS = [5, 10, 15];

export const initialState = {
  data: [],
  rows: [],
  filter: {
    search: '',
    // WAITING FOR THE BE
    // sortBy: SORT_DATA_PARTICIPANTS[0].name,
    // sortDirection: SORT_DIRECTION.ASCENDING,
    page: 0,
    size: PAGE_SIZE
  },
  totalPages: 0,
  totalElements: 0,
  isLoading: false,
  isEmailDialogOpen: false,
  emailDialogAnchor: null,
  confirmStatusDialog: {
    isConfirmStatusDialogOpen: false,
    config: { title: '', listOfParticipants: [] }
  },
  previewDialog: { title: '', isPreviewDialogOpen: false, emailStatus: null },
  selectionModel: [],
  emailStatus: null
};

export const fetchParticipants = createAsyncThunk(
  'eventsParticipants/participants',
  (filterObj) => {
    return getByPathAndParams({
      path: EVENTS_PATHS.GET_PARTICIPANTS,
      pathVariables: { id: filterObj.entityId },
      params: filterObj.filter
    })
      .then((response) => response.data)
      .catch((error) => error);
  }
);

export const sendDocument = createAsyncThunk(
  'eventsParticipants/sendDocument',
  ({ type, id, data }, thunkAPI) => {
    return postByPathAndData({
      path: DOCUMENTS_PATHS.SEND_DOCUMENT,
      data: data,
      pathVariables: { type, id }
    })
      .then((response) => response.data)
      .catch((error) => thunkAPI.rejectWithValue(error.response));
  }
);

export const sendDocumentWithForce = createAsyncThunk(
  'eventsParticipants/sendDocumentWithForce',
  ({ type, id, data }, thunkAPI) => {
    return postByPathAndData({
      path: DOCUMENTS_PATHS.SEND_DOCUMENT_WITH_FORCE,
      data: data,
      pathVariables: { type, id }
    })
      .then((response) => response.data)
      .catch((error) => thunkAPI.rejectWithValue(error.response));
  }
);

export const previewDocument = createAsyncThunk(
  'eventsParticipants/previewDocument',
  ({ type, id }, thunkAPI) => {
    return getByPathAndParams({
      path: DOCUMENTS_PATHS.TEMPLATE_PREVIEW,
      pathVariables: { type, id },
      responseType: 'blob'
    })
      .then((response) => response.data)
      .catch((error) => thunkAPI.rejectWithValue(error.response));
  }
);

export const participantTableSlice = createSlice({
  name: 'eventsParticipants',
  initialState,
  reducers: {
    resetState: () => initialState,
    setData: (state, { payload }) => {
      state.data = payload;
    },
    setEmailStatus: (state, { payload }) => {
      state.emailStatus = payload;
    },
    setEmailAnchor: (state, { payload }) => {
      state.emailDialogAnchor = payload;
      state.isEmailDialogOpen = Boolean(payload);
    },
    setPreviewDialog: (state, { payload }) => {
      state.previewDialog = payload;
    },
    setConfirmStatusDialog: (state, { payload }) => {
      state.confirmStatusDialog = payload;
    },
    setSelectionModel: (state, { payload }) => {
      if (Array.isArray(payload)) {
        state.selectionModel = [...payload];
      } else {
        const index = state.selectionModel.findIndex((s) => s === payload);
        if (index === -1) {
          state.selectionModel = [...state.selectionModel, payload];
        } else {
          state.selectionModel = [
            ...state.selectionModel.slice(0, index),
            ...state.selectionModel.slice(index + 1)
          ];
        }
      }
    },
    setIsEmailDialogOpen: (state, { payload }) => {
      state.isEmailDialogOpen = payload;
    },
    setFilterParams: (state, action) => {
      let newFilterValues = {};

      // Case when search value is reset to empty and search bar is closed
      if (action.payload.key === 'search' && !action.payload.value && !state.filter.search) {
        state.isLoading = false;
        return;
      }

      if (Array.isArray(action.payload)) {
        newFilterValues = action.payload.reduce(
          (obj, item) => ((obj[item.key] = item.value), obj),
          {}
        );
      } else {
        newFilterValues = { [action.payload.key]: action.payload.value };
      }

      state.filter = { ...state.filter, ...newFilterValues, page: action.payload.page ?? 0 };
    }
  },
  extraReducers: (builder) => {
    builder
      // Get all
      .addCase(fetchParticipants.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.totalPages = payload?.totalPages || state.totalPages;
        state.totalElements = payload?.totalElements || state.totalElements;
        state.data =
          payload?.content?.map((el) => ({
            ...el.eventParticipantDto.personDto,
            ...el.eventParticipantDto,
            status: el?.eventParticipantDto?.status?.value,
            id: el?.eventParticipantDto?.id,
            sessionParticipants: el?.sessionParticipants?.map((e) => ({
              ...e.session,
              status: e.status.value
            })),
            event: el.eventParticipantDto.event
          })) || [];
      })
      .addCase(fetchParticipants.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchParticipants.rejected, (state) => {
        state.data = [];
        state.isLoading = false;
      });
  }
});

export const selectList = (state) => state.eventsParticipants.data;
export const selectRows = (state) => state.eventsParticipants.rows;
export const selectTotalElements = (state) => state.eventsParticipants.totalElements;
export const selectTotalPages = (state) => state.eventsParticipants.totalPages;
export const selectFilter = (state) => state.eventsParticipants.filter;
export const selectIsLoading = (state) => state.eventsParticipants.isLoading;
export const selectIsEmailDialogOpen = (state) => state.eventsParticipants.isEmailDialogOpen;
export const selectEmailAnchor = (state) => state.eventsParticipants.emailDialogAnchor;
export const selectEmailStatus = (state) => state.eventsParticipants.emailStatus;
export const selectPreviewDialog = (state) => state.eventsParticipants.previewDialog;
export const selectConfirmStatusDialog = (state) => state.eventsParticipants.confirmStatusDialog;
export const selectSelectionModel = (state) => state.eventsParticipants.selectionModel;

const { actions, reducer } = participantTableSlice;

export const {
  setData,
  setFilterParams,
  resetState,
  setEmailAnchor,
  setIsPreviewDialogOpen,
  setPreviewDialogTitle,
  setIsEmailDialogOpen,
  setSelectionModel,
  setPreviewDialog,
  setConfirmStatusDialog,
  setEmailStatus
} = actions;

export default reducer;
