export const LOAD_SESSIONS = 'LOAD_SESSIONS';
export const SET_SESSIONS_ERROR = 'SET_SESSIONS_ERROR';
export const SET_SESSIONS_LOADING = 'SET_SESSIONS_LOADING';
export const SET_SESSIONS_SELECTION_MODEL = 'SET_SESSIONS_SELECTION_MODEL';
export const SET_SESSIONS_DIALOG_SELECTION_MODEL = 'SET_SESSIONS_DIALOG_SELECTION_MODEL';

export const SESSIONS_DIALOG_OPEN = 'SESSIONS_DIALOG_OPEN';
export const SESSIONS_DIALOG_CLOSE = 'SESSIONS_DIALOG_CLOSE';

export const UPDATE_SESSIONS_SUCCESS = 'UPDATE_SESSIONS_SUCCESS';
export const UPDATE_SESSIONS_ERROR = 'UPDATE_SESSIONS_ERROR';

export const DELETE_SESSIONS_SUCCESS = 'DELETE_SESSIONS_SUCCESS';
export const DELETE_SESSIONS_ERROR = 'DELETE_SESSIONS_ERROR';
