import Localize from 'react-intl-universal';

export const initialLanchpadState = () => {
  return {
    planning: [
      {
        id: 1,
        wide: 'extra',
        tileHeader: Localize.get('Launchpad.EventsCalendar'),
        iconName: 'date_range',
        iconNumber: '0',
        numberTooltip: 'this week',
        iconNumberText: Localize.get('Launchpad.ThisWeek'),
        numberTextColor: '',
        footerText: Localize.get('Launchpad.StartPlaningFromHere'),
        chart: 'bar',
        linkName: '/calendar',
        fieldName: 'eventsByWeekNo',
        dataset: [],
        dimensions: [
          {
            accessor: 'name'
          }
        ],
        measures: [
          {
            accessor: 'events',
            label: 'Events'
          }
        ]
      },
      {
        id: 2,
        wide: 'small',
        tileHeader: Localize.get('Launchpad.Emails'),
        iconName: 'email',
        iconNumber: '0',
        numberTooltip: '',
        iconNumberText: Localize.get('Launchpad.Unread'),
        numberTextColor: 'error.light',
        footerText: '',
        chart: '',
        linkName: '/emails',
        fieldName: ''
      },
      {
        id: 3,
        wide: 'large',
        newsBox: true,
        newsLine: Localize.get('Launchpad.UpcomingEvent'),
        newsHeder: Localize.get('Launchpad.FirstNextEventTitle'),
        newsDate: Localize.get('Launchpad.Datum'),
        linkName: '/events',
        iconName: '',
        fieldName: ''
      }
    ],
    masterData: [
      {
        id: 4,
        wide: 'small',
        tileHeader: Localize.get('Launchpad.Employees'),
        iconName: 'badge',
        fieldName: 'countOfEmployees',
        iconNumber: '0',
        linkName: '/employees'
      },
      {
        id: 5,
        wide: 'small',
        tileHeader: Localize.get('Launchpad.Rooms'),
        fieldName: 'countOfMeetingRooms',
        iconName: 'meeting_room',
        iconNumber: '0',
        linkName: '/meeting-rooms'
      },
      {
        id: 6,
        wide: 'small',
        tileHeader: Localize.get('Launchpad.OrganisationalStructures'),
        fieldName: '',
        iconName: 'schema',
        iconNumber: '0',
        linkName: '/org-structure'
      },
      {
        id: 7,
        wide: 'small',
        tileHeader: Localize.get('Launchpad.Contacts'),
        fieldName: 'countOfContacts',
        iconName: 'contact_mail',
        iconNumber: '0',
        linkName: '/contacts'
      },
      {
        id: 8,
        wide: 'small',
        tileHeader: Localize.get('Launchpad.Resources'),
        fieldName: 'countOfResources',
        iconName: 'insights',
        iconNumber: '0',
        linkName: '/resources'
      },
      {
        id: 9,
        wide: 'small',
        tileHeader: Localize.get('Launchpad.BusinessPartners'),
        fieldName: 'countOfBusinessPartners',
        iconName: 'business',
        iconNumber: '0',
        linkName: '/business-partners'
      },
      {
        id: 10,
        wide: 'small',
        tileHeader: Localize.get('Launchpad.Locations'),
        fieldName: 'countOfLocations',
        iconName: 'maps',
        iconNumber: '0',
        linkName: '/locations'
      }
    ],
    transactionalData: [
      {
        id: 11,
        wide: 'small',
        tileHeader: Localize.get('Launchpad.Courses'),
        fieldName: 'countOfCourses',
        iconName: 'auto_stories',
        iconNumber: '0',
        linkName: '/courses'
      },
      {
        id: 12,
        wide: 'small',
        tileHeader: Localize.get('Launchpad.IltSession'),
        fieldName: 'countOfIltSessions',
        iconName: 'event_seat',
        iconNumber: '0',
        linkName: '/ilt-session'
      },
      {
        id: 13,
        wide: 'small',
        tileHeader: Localize.get('Launchpad.Activities'),
        tileSubheader: Localize.get('Launchpad.ToDoS'),
        fieldName: 'activitiesByType',
        iconName: 'format_list_bulleted',
        numberTextColor: 'error.light',
        numberTextAboveColor: 'error.light',
        iconNumber: '0',
        iconNumberAbove: '0',
        iconNumberBelowText: Localize.get('Launchpad.Appointments'),
        iconNumberAboveText: Localize.get('Launchpad.Tasks'),
        linkName: '/activities',
        iconMarginBottom: '20px'
      },
      {
        id: 14,
        wide: 'small',
        tileHeader: Localize.get('Launchpad.Events'),
        fieldName: 'eventsByStatus',
        iconName: 'insert_invitation',
        iconNumber: '0',
        footerText: Localize.get('Launchpad.ManageEvents'),
        chart: 'microBar',
        linkName: '/events',
        dataset: [],
        dimension: {
          accessor: 'name'
        },
        measure: {
          accessor: 'data',
          colors: ['#f0ab00', 'var(--sapHighlightColor)'],
          width: 13
        },
        loading: false
      },
      {
        id: 15,
        wide: 'extra',
        tileHeader: Localize.get('Launchpad.Participants'),
        fieldName: 'participantsByStatus',
        iconName: 'manage_accounts',
        iconNumber: '0',
        footerText: Localize.get('Launchpad.ManageParticipants'),
        chart: 'donut',
        dataset: [],
        dimension: {
          accessor: 'name'
        },
        measure: {
          accessor: 'users'
        },
        linkName: '/participants'
      }
    ]
  };
};

export const initialArrowState = () => {
  return {
    planning: { left: true, right: false },
    masterData: { left: true, right: false },
    transactionalData: { left: true, right: false }
  };
};

//define the width of scrolling to right
export const moveStep = 210;
