import React from 'react';
import Localize from 'react-intl-universal';

import { Field } from 'formik';

import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';

import SelectInput from '@components/SelectInput';

const RoleTypes = ({ options = [], handleChange }) => (
  <Grid container spacing={2} sx={{ justifyContent: 'center', mt: 4 }}>
    <Grid item xs={12} sm={8} md={6} lg={4}>
      <FormControl fullWidth>
        <Field
          name="roles"
          label={Localize.get('Labels.Role')}
          options={options}
          component={SelectInput}
          onChange={handleChange}
        />
      </FormControl>
    </Grid>
  </Grid>
);

export default RoleTypes;
