import React from 'react';
import Localize from 'react-intl-universal';
import { useSelector } from 'react-redux';

import Overview from '@components/Overview';
import { selectDetails, selectIsDetailsLoading } from '@pages/Resources/resourcesSlice';

const config = {
  title: 'ResourcesTile.Overview',
  skeletonCount: 3,
  details: [
    {
      label: 'ResourcesTile.Category',
      name: 'resourceCategory.value'
    },
    {
      label: 'ResourcesTile.Price',
      name: 'price'
    },
    {
      label: 'ResourcesTile.Currency',
      name: 'currency.value'
    }
  ]
};

const ResourcesOverview = () => {
  const details = useSelector(selectDetails);
  const isLoading = useSelector(selectIsDetailsLoading);

  return (
    <Overview
      invite={{
        text: Localize.get('Links.InviteLink', {
          entity: Localize.get('ResourcesTile.Resource')
        }),
        link: '/resources/new'
      }}
      isLoading={isLoading}
      data={details}
      config={config}
    />
  );
};

export default ResourcesOverview;
