import React, { forwardRef } from 'react';
import Localize from 'react-intl-universal';
import { useDispatch, useSelector } from 'react-redux';

import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';

import {
  hideSnackbar,
  selectConfig,
  selectIsOpen,
  SnackbarSeverityTypes,
  SNACKBAR_ACTIONS
} from './snackbarSlice';

const Alert = forwardRef(function Alert(props, ref) {
  return (
    <MuiAlert
      elevation={6}
      ref={ref}
      variant="filled"
      {...props}
      slotProps={{ closeButton: { title: `${Localize.get('Buttons.Close')}` } }}
    />
  );
});

const SnackbarComponent = () => {
  const isOpen = useSelector(selectIsOpen);
  const dispatch = useDispatch();

  const {
    message = 'Default message',
    severity = SnackbarSeverityTypes.INFO,
    duration = 10000,
    vertical = 'bottom',
    horizontal = 'center'
  } = useSelector(selectConfig);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    dispatch(hideSnackbar(SNACKBAR_ACTIONS.Cancel));
  };

  return (
    <Snackbar
      open={isOpen}
      autoHideDuration={duration}
      onClose={handleClose}
      anchorOrigin={{ vertical, horizontal }}
    >
      <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default SnackbarComponent;
