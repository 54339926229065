// import { SORT_DIRECTION } from '@common/Constants';

import {
  SESSIONS_FILTER_UPDATE,
  SET_LOADING,
  LOAD_SESSIONS,
  SET_SELECTION_MODEL,
  DELETE_ATTACHMENT_SUCCESS,
  LOAD_SESSIONS_ERROR
} from './types';

export const PAGE_SIZE = 5;
export const PAGE_SIZE_OPTIONS = [5, 10, 15];

const sessionsState = {
  data: [],
  isLoading: false,
  isError: false,
  filter: {
    // TODO: Will be used later
    // sortBy: SORT_DATA[0].KEY,
    // sortDirection: SORT_DIRECTION.ASCENDING,
    search: '',
    match: '',
    searchValue: '',
    page: 0,
    size: PAGE_SIZE
  },
  totalElements: 0,
  selectionModel: []
};

function sessionsReducer(state, { type, payload }) {
  switch (type) {
    case SET_LOADING:
      return { ...state, isLoading: payload };
    case SET_SELECTION_MODEL:
      return { ...state, selectionModel: payload };
    case DELETE_ATTACHMENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: [...state.data.filter((item) => item.id !== payload)],
        selectionModel: []
      };
    case LOAD_SESSIONS_ERROR:
      return {
        ...state,
        isLoading: false,
        isError: true,
        data: [],
        filter: sessionsState.filter,
        totalElements: 0
      };
    case LOAD_SESSIONS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        data: payload.content,
        filter: {
          ...state.filter,
          page: payload.pageable.pageNumber,
          size: payload.pageable.pageSize
        },
        totalElements: payload.totalElements
      };
    case SESSIONS_FILTER_UPDATE: {
      return {
        ...state,
        filter: {
          ...state.filter,
          page: payload?.value?.page || 0,
          size: payload?.value?.size || sessionsState.filter.size,
          search: payload?.value?.search || '',
          match: payload.value.match,
          searchValue: payload.value.searchValue
        }
      };
    }

    default: {
      return state;
    }
  }
}

export { sessionsReducer, sessionsState };
