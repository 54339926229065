import React, { useState } from 'react';
import Localize from 'react-intl-universal';

import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import Skeleton from '@components/CustomSkeleton/CustomSkeleton';
import NoData from '@components/NoData/NoData';

import EventInformation from './EventInformation';
import SessionInformation from './SessionInformation';

const StyledAccordion = styled(Accordion, { shouldForwardProp: (prop) => prop !== 'isEmpty' })(
  ({ theme, isEmpty }) => ({
    width: isEmpty ? '30%' : 'auto',
    marginLeft: `${theme.spacing(2)} !important`,
    marginBottom: theme.spacing(1)
  })
);

const StyledAccordionDetails = styled(AccordionDetails, {
  shouldForwardProp: (prop) => prop !== 'isEmpty' && prop !== 'isLoading'
})(({ theme, isEmpty, isLoading }) => ({
  minHeight: isEmpty ? 'auto' : '200px',
  flexDirection: isEmpty ? 'column' : isLoading ? 'column' : 'row',
  display: 'flex',
  marginLeft: `${theme.spacing(2)} !important`,
  marginBottom: theme.spacing(1),
  padding: 0
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary expandIcon={<ArrowForwardIosSharpIcon />} {...props} />
))(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)'
  }
}));

const SessionOverview = ({ isLoading = false, data = null }) => {
  const [expanded, setExpanded] = useState(true);

  return (
    <StyledAccordion
      isEmpty={data === null}
      expanded={expanded}
      onChange={() => setExpanded(!expanded)}
    >
      <AccordionSummary sx={{ mt: 1 }}>
        <Typography sx={{ pl: 2, color: 'accent.main' }}>
          {Localize.get('Sessions.Overview')}
        </Typography>
      </AccordionSummary>

      <StyledAccordionDetails isLoading={isLoading} isEmpty={data === null}>
        {isLoading ? (
          <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap' }}>
            <Box>
              <Skeleton
                skeletonCount={6}
                skeletonProps={{
                  variant: 'rectangular',
                  height: '40px',
                  width: 270,
                  sx: { mb: 1 }
                }}
              />
            </Box>
            <Box>
              <Skeleton
                skeletonCount={3}
                skeletonProps={{
                  variant: 'rectangular',
                  height: '40px',
                  width: 270,
                  sx: { ml: 3, mb: 1 }
                }}
              />
            </Box>
          </Box>
        ) : data ? (
          <>
            <Box sx={{ mr: 2 }}>
              <SessionInformation data={data} />
            </Box>
            <Box sx={{ mr: 2 }}>
              <EventInformation data={data} />
            </Box>
          </>
        ) : (
          <NoData titleText={Localize.get('NoDataMessage.TitleTextOverview')} />
        )}
      </StyledAccordionDetails>
    </StyledAccordion>
  );
};

export default SessionOverview;
