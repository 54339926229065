import React from 'react';

import Button from '@mui/material/Button';
import Icon from '@mui/material/Icon';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';

const DetailsToolbar = ({ options = [] }) => (
  <Toolbar sx={{ display: 'flex', justifyContent: 'flex-end' }}>
    {options?.map(({ onClick, icon, label, tooltip, isDisabled }, index) => (
      <Tooltip title={tooltip} key={index}>
        {/* Div needed since button can be disabled and Tooltip will throw an error. Do not remove. */}
        <div>
          <Button
            disabled={isDisabled}
            size="small"
            sx={{ mr: 1 }}
            variant="outlined"
            aria-label={label}
            onClick={onClick}
          >
            <Icon>{icon}</Icon>
          </Button>
        </div>
      </Tooltip>
    ))}
  </Toolbar>
);

export default DetailsToolbar;
