import React from 'react';
import Localize from 'react-intl-universal';
import { useDispatch, useSelector } from 'react-redux';

import BlockIcon from '@mui/icons-material/Block';
import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

import Footer from '@components/Footer';
import {
  selectMode,
  setMode,
  selectIsEditDisabled
} from '@components/RightSidebar/rightSidebarSlice';

const RightSidebarFooter = ({
  isSaveDisabled = true,
  handleSubmit = () => {},
  handleDelete = () => {},
  disableDelete = false,
  resetForm = () => {}
}) => {
  const dispatch = useDispatch();
  const mode = useSelector(selectMode);
  const isEditDisabled = useSelector(selectIsEditDisabled);

  return (
    <Footer data-test-id="rightSidebarFooter">
      <Stack
        direction="row"
        sx={{
          alignItems: 'center',
          alignContent: 'center',
          justifyContent: 'center',
          width: '100%'
        }}
      >
        {mode === 'read' && (
          <Button
            disabled={isEditDisabled}
            data-test-id="sidebar-edit"
            size="small"
            onClick={() => dispatch(setMode('edit'))}
            aria-label="search"
            variant="outlined"
            sx={{ mr: 1, ml: 1, flex: 1, width: '100%' }}
            startIcon={<EditIcon />}
          >
            {Localize.get('Buttons.Edit')}
          </Button>
        )}

        {mode === 'edit' && (
          <>
            <Button
              disableFocusRipple
              data-test-id="sidebar-cancel"
              variant="outlined"
              size="small"
              onClick={() => {
                resetForm();
                dispatch(setMode('read'));
              }}
              color="error"
              sx={{ mr: 1 }}
              startIcon={<CancelIcon />}
            >
              {Localize.get('Buttons.Cancel')}
            </Button>

            <Button
              disableFocusRipple
              data-test-id="sidebar-obsolete"
              variant="outlined"
              color="error"
              sx={{ mr: 1 }}
              size="small"
              onClick={handleDelete}
              startIcon={<BlockIcon />}
              disabled={disableDelete}
            >
              {Localize.get('Buttons.Delete')}
            </Button>

            <Button
              disableFocusRipple
              data-test-id="sidebar-save"
              size="small"
              variant="outlined"
              disabled={isSaveDisabled}
              onClick={handleSubmit}
              sx={{ mr: 1 }}
              startIcon={<SaveIcon />}
            >
              {Localize.get('Buttons.Save')}
            </Button>
          </>
        )}
      </Stack>
    </Footer>
  );
};
export default RightSidebarFooter;
