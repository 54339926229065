import React, { useEffect } from 'react';
import Localize from 'react-intl-universal';
import { useNavigate, useSearch } from 'react-location';
import { useSelector, useDispatch } from 'react-redux';

import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import { DataGrid } from '@mui/x-data-grid';

import getLocalesText from '@common/helpers/tables/getLocalesText';
import { saveState } from '@common/storage/persistSlice';
import TabContainer from '@components/TabContainer';
import TabContent from '@components/TabContent/TabContent';
import TableToolbar from '@components/TableToolbar';
import ToolbarItem from '@components/TableToolbarItem';
import {
  selectList,
  fetchParticipants,
  selectFilter,
  selectIsLoading,
  resetState,
  selectTotalElements,
  selectTotalPages,
  setFilterParams,
  selectSelectionModel,
  setSelectionModel
} from '@pages/IltSession/components/Tabs/ParticipantsTable/participantTableSlice';
import { selectFilter as selectIltSessionFilter } from '@pages/IltSession/iltSessionSlice';

import CreateParticipant from '../../CreateParticipant/CreateParticipant';

export const PAGE_SIZE_OPTIONS = [5, 10, 15];

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  toolbarContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(1),
    alignItems: 'center'
  },
  toolbar: {
    minHeight: '3rem'
  },
  container: {
    height: 450,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  tableRow: {
    '&:hover': {
      backgroundColor: `${theme.palette.grey[400]} !important`,
      cursor: 'pointer'
    }
  },
  cellCheckbox: {
    padding: '5px 40px 5px 5px !important'
  },
  noNightsBooked: { color: theme.palette.error.main },
  someNightsBooked: { color: theme.palette.warning.main },
  allNightsBooked: { color: theme.palette.success.light }
}));

const statusColor = (nightsBookedData, classes) => {
  if (!nightsBookedData) {
    return;
  }

  const nightsBooked = nightsBookedData.slice(0, nightsBookedData.indexOf('/'));
  const nightsTotal = nightsBookedData.slice(nightsBookedData.indexOf('/') + 1);

  return nightsBooked === '0'
    ? classes.noNightsBooked
    : nightsBooked !== nightsTotal
    ? classes.someNightsBooked
    : classes.allNightsBooked;
};

const ParticipantsTable = ({
  columns = [],
  entityId = null,
  entityType = null,
  toolbarOptions = []
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const search = useSearch();

  const data = useSelector(selectList);
  const filter = useSelector(selectFilter);
  const isLoading = useSelector(selectIsLoading);
  const totalElements = useSelector(selectTotalElements);
  const totalPages = useSelector(selectTotalPages);
  const selectionModel = useSelector(selectSelectionModel);
  const iltSessionFilter = useSelector(selectIltSessionFilter);

  const hasHotelRequest = (data) =>
    data.some((row) => row?.iltSessionParticipantResponseDto?.hotelRequest);

  useEffect(() => {
    if (entityId && entityType) {
      dispatch(fetchParticipants({ entityId: entityId, filter: filter }));
    }
  }, [entityId, filter?.page, filter?.sortBy, filter?.sortDirection, filter?.size]);

  // Resets state on component destroy
  useEffect(() => {
    return () => dispatch(resetState());
  }, []);

  const isToolbarItemDisabled = (fieldName) => {
    switch (fieldName) {
      case 'add_participant':
        return !entityId || !entityType;
      case 'delete':
        return true; // selectionModel.length === 0;
      default:
        return false;
    }
  };

  const onToolbarItemClick = (fieldName) => {
    switch (fieldName) {
      case 'add_participant':
        dispatch(saveState({ filter: iltSessionFilter, selectedId: entityId }));
        navigate({ search: (previousUrlParams) => ({ ...previousUrlParams, mode: 'create' }) });
        return !entityId || !entityType;
      case 'delete':
        return true;
      default:
        return false;
    }
  };

  return (
    <div className={classes.root}>
      {search?.mode === 'create' ? (
        <CreateParticipant />
      ) : (
        <TabContent>
          <div className={classes.toolbarContainer}>
            <Typography variant="h6" component="h6" color="primary">
              {Localize.get('Labels.Participants')}
            </Typography>
            <Toolbar className={classes.toolbar}>
              {toolbarOptions?.map((item, index) => (
                <ToolbarItem
                  key={index}
                  item={item}
                  isDisabled={item.disabled || isToolbarItemDisabled(item.fieldName)}
                  onToolbarItemClick={onToolbarItemClick}
                />
              ))}
            </Toolbar>
          </div>
          <TabContainer>
            <DataGrid
              keepNonExistentRowsSelected={true}
              getRowId={(row) => row?.iltSessionParticipantResponseDto?.id}
              loading={isLoading}
              rows={data}
              columns={
                hasHotelRequest(data)
                  ? columns.map((column) => ({
                      ...column,
                      headerName: Localize.get(column.headerName),
                      renderCell: (params) => (
                        <div data-test-id={`${params.field}-${params?.row?.id ?? 'default'}`}>
                          {['nightsBooked'].includes(params.field) ? (
                            <Typography
                              className={statusColor(params?.value, classes)}
                              variant="body"
                              component="div"
                            >
                              {params.value}
                            </Typography>
                          ) : ['name', 'company'].includes(params.field) ? (
                            <Tooltip title={params.value} arrow>
                              <Typography
                                variant="body"
                                component="div"
                                sx={{
                                  maxWidth: params?.colDef?.width,
                                  whiteSpace: 'nowrap',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis'
                                }}
                              >
                                {params.value}
                              </Typography>
                            </Tooltip>
                          ) : (
                            <Typography variant="body" component="div">
                              {params.value}
                            </Typography>
                          )}
                        </div>
                      )
                    }))
                  : columns
                      .filter(
                        (column) =>
                          column.headerName !== 'IltSession.Hotel' &&
                          column.headerName !== 'IltSession.Arrival' &&
                          column.headerName !== 'IltSession.Departure' &&
                          column.headerName !== 'IltSession.NightsBooked'
                      )
                      .map((column) => ({
                        ...column,
                        headerName: Localize.get(column.headerName),
                        renderCell: (params) => (
                          <div data-test-id={`${params.field}-${params?.row?.id ?? 'default'}`}>
                            {['nightsBooked'].includes(params.field) ? (
                              <Typography
                                className={statusColor(params?.value, classes)}
                                variant="body"
                                component="div"
                              >
                                {params.value}
                              </Typography>
                            ) : ['name', 'company'].includes(params.field) ? (
                              <Tooltip title={params.value} arrow>
                                <Typography
                                  variant="body"
                                  component="div"
                                  sx={{
                                    maxWidth: params?.colDef?.width,
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis'
                                  }}
                                >
                                  {params.value}
                                </Typography>
                              </Tooltip>
                            ) : (
                              <Typography variant="body" component="div">
                                {params.value}
                              </Typography>
                            )}
                          </div>
                        )
                      }))
              }
              initialState={{
                pagination: {
                  pageSize: totalPages,
                  rowCount: totalElements,
                  page: filter?.page
                }
              }}
              onSelectionModelChange={(values) => dispatch(setSelectionModel(values))}
              selectionModel={selectionModel}
              checkboxSelection
              localeText={getLocalesText(Localize)}
              pagination
              paginationMode="server"
              disableColumnMenu
              page={filter?.page}
              pageSize={filter?.size}
              rowCount={totalElements}
              rowsPerPageOptions={PAGE_SIZE_OPTIONS}
              disableSelectionOnClick
              components={{
                Toolbar: () => <TableToolbar />
              }}
              onPageChange={(page) => dispatch(setFilterParams({ page, selectionModel }))}
              onPageSizeChange={(value) =>
                dispatch(setFilterParams([{ key: 'size', value, selectionModel }]))
              }
            />
          </TabContainer>
        </TabContent>
      )}
    </div>
  );
};

export default ParticipantsTable;
