import { isNil } from 'lodash';

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { ACTION_MODES } from '@common/Constants';
import { deepMerge } from '@common/helpers/deepMerge';
import { successMessageFormatter } from '@common/helpers/MessageFormatter';
import { ILT_SESSION_PATHS } from '@common/network/ApiPaths';
import EntityTypes from '@common/network/EntityTypes';
import { SnackbarSeverityTypes, showSnackbar } from '@components/Snackbar/snackbarSlice';
import { deleteByPath, getByPathAndParams, putByPathAndData } from '@services/BaseApi';

export const PAGE_SIZE = 5;
export const PAGE_SIZE_OPTIONS = [5, 10, 15];
export const initialState = {
  data: [],
  rows: [],
  filter: {
    search: '',
    page: 0,
    size: PAGE_SIZE
  },
  totalPages: 0,
  totalElements: 0,
  isLoading: false,
  selectionModel: [],
  isDetailsLoading: false,
  details: {}
};

export const fetchHotels = createAsyncThunk('iltSessionHotels/hotels', (filterObj) => {
  return getByPathAndParams({
    path: ILT_SESSION_PATHS.GET_CONTINGENTS,
    pathVariables: { id: filterObj.entityId },
    params: filterObj.filter
  })
    .then(({ data }) => data)
    .catch((error) => error);
});

export const fetchHotelDetails = createAsyncThunk(
  'iltSessionHotels/fetchDetails',
  (selectedId) =>
    getByPathAndParams({
      path: ILT_SESSION_PATHS.GET_CONTINGENTS_HOTEL,
      pathVariables: { id: selectedId }
    })
      .then((response) => response.data)
      .catch((error) => error),
  {
    condition: (selectedId) => Boolean(selectedId),
    dispatchConditionRejection: true
  }
);

export const deleteHotels = createAsyncThunk(
  'iltSessionHotels/delete',
  ({ id, contingentIds }, thunkAPI) => {
    return deleteByPath({
      path: ILT_SESSION_PATHS.DELETE_CONTINGENT,
      pathVariables: { id, contingentIds }
    })
      .then((response) => ({ response, contingentIds }))
      .catch((error) => thunkAPI.rejectWithValue(error.response));
  }
);

export const saveHotels = createAsyncThunk('iltSessionHotels/save', (postData, thunkAPI) =>
  putByPathAndData({
    path: ILT_SESSION_PATHS.PUT_CONTINGENTS,
    data: postData,
    pathVariables: { iltSessionId: postData.iltSessionId, id: postData.id }
  })
    .then((response) => {
      thunkAPI.dispatch(
        showSnackbar({
          message: successMessageFormatter(EntityTypes.HOTEL, ACTION_MODES.Edit),
          severity: SnackbarSeverityTypes.SUCCESS
        })
      );
      return response.data;
    })
    .catch((error) => thunkAPI.rejectWithValue(error.response))
);

export const iltSessionHotelTableSlice = createSlice({
  name: 'iltSessionHotels',
  initialState,
  reducers: {
    resetState: () => initialState,
    setData: (state, { payload }) => {
      state.data = payload;
    },
    setSelectionModel: (state, { payload }) => {
      if (Array.isArray(payload)) {
        state.selectionModel = [...payload];
      } else {
        const index = state.selectionModel.findIndex((s) => s === payload);
        if (index === -1) {
          state.selectionModel = [...state.selectionModel, payload];
        } else {
          state.selectionModel = [
            ...state.selectionModel.slice(0, index),
            ...state.selectionModel.slice(index + 1)
          ];
        }
      }
    },
    setHotelDetails: (state, { payload }) => {
      state.details = deepMerge(state.details, payload);
    },
    setFilterParams: (state, action) => {
      let newFilterValues = {};
      // Case when search value is reset to empty and search bar is closed
      if (action.payload.key === 'search' && !action.payload.value && !state.filter.search) {
        state.isLoading = false;
        return;
      }
      if (Array.isArray(action.payload)) {
        newFilterValues = action.payload.reduce(
          (obj, item) => ((obj[item.key] = item.value), obj),
          {}
        );
      } else {
        newFilterValues = { [action.payload.key]: action.payload.value };
      }
      state.filter = { ...state.filter, ...newFilterValues, page: action.payload.page ?? 0 };
    }
  },
  extraReducers: (builder) => {
    builder
      // Get all
      .addCase(fetchHotels.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.totalElements = isNil(payload?.totalElements)
          ? state.totalElements
          : payload?.totalElements;
        state.totalElements = payload?.totalElements || state.totalElements;
        state.data = payload.content;
      })
      .addCase(fetchHotels.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchHotels.rejected, (state) => {
        state.data = [];
        state.isLoading = false;
      })
      .addCase(deleteHotels.fulfilled, (state, { payload }) => {
        const { contingentIds } = payload;
        state.selectionModel = [];
        state.data = state.data.filter(({ id }) => !contingentIds.includes(id));
      })
      // Get Details
      .addCase(fetchHotelDetails.fulfilled, (state) => {
        state.details = null;
        state.isDetailsLoading = false;
      })
      .addCase(fetchHotelDetails.pending, (state) => {
        state.isDetailsLoading = true;
      })
      .addCase(fetchHotelDetails.rejected, (state) => {
        state.details = null;
        state.isDetailsLoading = false;
      })
      // Update hotels
      .addCase(saveHotels.fulfilled, (state, action) => {
        state.isDetailsLoading = false;
        state.data = state.data.map((item) =>
          item.id === action.meta.arg.id ? deepMerge(item, action.meta.arg) : item
        );
        state.details = deepMerge(state.details, action.meta.arg);
      })
      .addCase(saveHotels.pending, (state) => {
        state.isDetailsLoading = true;
      })
      .addCase(saveHotels.rejected, (state) => {
        state.isDetailsLoading = false;
      });
  }
});

export const selectList = (state) => state.iltSessionHotels.data;
export const selectRows = (state) => state.iltSessionHotels.rows;
export const selectTotalElements = (state) => state.iltSessionHotels.totalElements;
export const selectTotalPages = (state) => state.iltSessionHotels.totalPages;
export const selectHotelDetails = (state) => state.iltSessionHotels.details;
export const selectFilter = (state) => state.iltSessionHotels.filter;
export const selectIsLoading = (state) => state.iltSessionHotels.isLoading;
export const selectSelectionModel = (state) => state.iltSessionHotels.selectionModel;
export const selectIsDetailsLoading = (state) => state.iltSession.isDetailsLoading;

const { actions, reducer } = iltSessionHotelTableSlice;

export const { setData, setFilterParams, resetState, setSelectionModel, setHotelDetails } = actions;

export default reducer;
