import { isNil } from 'lodash';

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import {
  ACTION_MODES,
  dateInitFormats,
  NAVIGATE_TOASTER_TIMEOUT,
  SORT_DIRECTION
} from '@common/Constants';
import { dateToFormat } from '@common/helpers/dates';
import { deepMerge } from '@common/helpers/deepMerge';
import { errorMessageFormatter, successMessageFormatter } from '@common/helpers/MessageFormatter';
import { ACTIVITIES_PATHS } from '@common/network/ApiPaths';
import EntityTypes from '@common/network/EntityTypes';
import { showSnackbar, SnackbarSeverityTypes } from '@components/Snackbar/snackbarSlice';
import { MASTER_LIST_PAGE_SIZE } from '@config/network';
import {
  deleteByPath,
  getByPathAndParams,
  postByPathAndData,
  putByPathAndData
} from '@services/BaseApi';

import { SORT_DATA } from './util/sortConfig';

export const initialState = {
  selectedId: null,
  data: [],
  filter: {
    search: '',
    sortBy: SORT_DATA[0].name,
    sortDirection: SORT_DIRECTION.DESCENDING,
    page: 0,
    size: MASTER_LIST_PAGE_SIZE
  },
  totalPages: 0,
  totalElements: 0,
  details: null,
  isLoading: true,
  isDetailsLoading: false
};

export const fetchActivities = createAsyncThunk('activities/fetchAll', (filter) =>
  getByPathAndParams({
    path: ACTIVITIES_PATHS.GET,
    params: filter
  })
    .then((response) => response.data)
    .catch((error) => error)
);

export const fetchActivityDetails = createAsyncThunk(
  'activities/fetchDetails',
  (selectedId) =>
    getByPathAndParams({
      path: ACTIVITIES_PATHS.GET_DETAILS,
      pathVariables: { id: selectedId }
    })
      .then((response) => response.data)
      .catch((error) => error),
  {
    condition: (selectedId) => Boolean(selectedId),
    dispatchConditionRejection: true
  }
);

export const createActivity = createAsyncThunk('activities/create', (postData, thunkAPI) => {
  const { startDateTime, endDateTime } = postData;
  const formatted = {
    startDateTime: dateToFormat(startDateTime, dateInitFormats.dateTime),
    endDateTime: dateToFormat(endDateTime, dateInitFormats.dateTime)
  };
  return postByPathAndData({
    path: ACTIVITIES_PATHS.POST,
    data: {
      ...postData,
      startDateTime: formatted.startDateTime.split('.')[0],
      endDateTime: formatted.endDateTime.split('.')[0]
    }
  })
    .then((response) => {
      setTimeout(() => {
        thunkAPI.dispatch(
          showSnackbar({
            message: successMessageFormatter(EntityTypes.ACTIVITY, ACTION_MODES.Create),
            severity: SnackbarSeverityTypes.SUCCESS
          })
        );
      }, NAVIGATE_TOASTER_TIMEOUT);
      return response.data;
    })
    .catch((error) => thunkAPI.rejectWithValue(error.response));
});

export const deleteActivity = createAsyncThunk('activities/delete', (id, thunkAPI) =>
  deleteByPath({
    path: ACTIVITIES_PATHS.DELETE,
    pathVariables: { id }
  })
    .then((response) => {
      setTimeout(() => {
        thunkAPI.dispatch(
          showSnackbar({
            message: successMessageFormatter(EntityTypes.ACTIVITY, ACTION_MODES.Delete),
            severity: SnackbarSeverityTypes.SUCCESS
          })
        );
      }, NAVIGATE_TOASTER_TIMEOUT);
      return response.data;
    })
    .catch((error) => {
      thunkAPI.dispatch(
        showSnackbar({
          message: errorMessageFormatter(error, EntityTypes.ACTIVITY, ACTION_MODES.Delete),
          severity: SnackbarSeverityTypes.ERROR
        })
      );

      return error.response;
    })
);

export const saveActivity = createAsyncThunk('activities/save', (postData, thunkAPI) =>
  putByPathAndData({
    path: ACTIVITIES_PATHS.PUT,
    data: postData,
    pathVariables: { id: postData.id }
  })
    .then((response) => {
      setTimeout(() => {
        thunkAPI.dispatch(
          showSnackbar({
            message: successMessageFormatter(EntityTypes.ACTIVITY, ACTION_MODES.Edit),
            severity: SnackbarSeverityTypes.SUCCESS
          })
        );
      }, NAVIGATE_TOASTER_TIMEOUT);
      return response.data;
    })
    .catch((error) => thunkAPI.rejectWithValue(error.response))
);

export const activitiesSlice = createSlice({
  name: 'activities',
  type: [EntityTypes.APPOINTMENT, EntityTypes.TASK],
  initialState,
  reducers: {
    resetState: () => initialState,
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setSelectedId: (state, action) => {
      state.selectedId = action.payload;
    },
    setDetails: (state, { payload }) => {
      state.details = deepMerge(state.details, payload);
    },
    deleteSuccess: (state, action) => {
      state.data = [...state.data.filter((item) => item.id !== action.payload.id)];
      state.selectedId = [...state.data.filter((item) => item.id !== action.payload.id)];
    },
    setFilterParams: (state, action) => {
      let newFilterValues = {};

      // Case when search value is reset to empty and search bar is closed
      if (action.payload.key === 'search' && !action.payload.value && !state.filter.search) {
        state.isLoading = false;
        return;
      }

      if (Array.isArray(action.payload)) {
        newFilterValues = action.payload.reduce(
          (obj, item) => ((obj[item.key] = item.value), obj),
          {}
        );
      } else {
        newFilterValues = { [action.payload.key]: action.payload.value };
      }

      state.filter = { ...state.filter, ...newFilterValues, page: 0 };
    }
  },
  extraReducers: (builder) => {
    builder
      // Get all
      .addCase(fetchActivities.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.data = payload?.content || state.data;
        state.totalPages = payload?.totalPages || state.totalPages;
        state.totalElements = isNil(payload?.totalElements)
          ? state.totalElements
          : payload?.totalElements;
        state.selectedId = payload?.content?.[0]?.id;
        state.filter.page = payload?.pageable?.pageNumber;
        state.filter.size = payload?.pageable?.pageSize;
      })
      .addCase(fetchActivities.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchActivities.rejected, (state) => {
        state.data = [];
        state.isLoading = false;
      })
      // Get Details
      .addCase(fetchActivityDetails.fulfilled, (state, { payload }) => {
        state.details = payload;
        state.isDetailsLoading = false;
      })
      .addCase(fetchActivityDetails.pending, (state) => {
        state.isDetailsLoading = true;
      })
      .addCase(fetchActivityDetails.rejected, (state) => {
        state.details = null;
        state.isDetailsLoading = false;
      })
      // Create
      .addCase(createActivity.fulfilled, (state) => {
        state.filter.page = 0;
      })
      // Update
      .addCase(saveActivity.pending, (state) => {
        state.isDetailsLoading = true;
      })
      // Update
      .addCase(saveActivity.fulfilled, (state, action) => {
        state.isDetailsLoading = false;
        state.data = state.data.map((item) =>
          item.id === action.meta.arg.id ? deepMerge(item, action.meta.arg) : item
        );
        state.details = deepMerge(state.details, action.meta.arg);
      })
      // Delete
      .addCase(deleteActivity.fulfilled, (state, action) => {
        state.totalElements -= 1;
        state.isLoading = false;
        state.details = null;
        state.data = state.data.filter((item) => item.id !== action.meta.arg);
        state.selectedId = state.data.filter((item) => item.id !== action.meta.arg)[0]?.id;

        if (state.data.length === 0 && state.totalPages === 1) {
          state.filter.page = 0;
          state.totalPages = 0;
          state.totalElements = 0;
        } else if (state.data.length === 0 && state.totalPages > 1) {
          state.filter.page -= 1;
        }
      });
  }
});

export const selectId = (state) => state.activities.selectedId;
export const selectList = (state) => state.activities.data;
export const selectFilter = (state) => state.activities.filter;
export const selectTotalElements = (state) => state.activities.totalElements;
export const selectTotalPages = (state) => state.activities.totalPages;
export const selectDetails = (state) => state.activities.details;

export const selectIsLoading = (state) => state.activities.isLoading;
export const selectIsDetailsLoading = (state) => state.activities.isDetailsLoading;

const { actions, reducer } = activitiesSlice;

export const {
  setError,
  setLoading,
  setSelectedId,
  setConfirmationDialog,
  deleteSuccess,
  setFilterParams,
  resetPage,
  resetState,
  setDetails
} = actions;

export default reducer;
