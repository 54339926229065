import React from 'react';
import Localize from 'react-intl-universal';
import { useSelector } from 'react-redux';

import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';

import Skeleton from '@components/CustomSkeleton/CustomSkeleton';
import NoData from '@components/NoData/NoData';
import TabContainer from '@components/TabContainer/TabContainer';
import TabContent from '@components/TabContent/TabContent';
import { selectSessionDayDetails, selectIsDetailsLoading } from '@pages/IltSession/iltSessionSlice';

import SessionInformation from './SessionInformation';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  toolbarContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(1),
    alignItems: 'center',
    minHeight: '3rem'
  },
  tableRow: {
    '&:hover': {
      backgroundColor: `${theme.palette.grey[400]} !important`
    }
  }
}));

const SessionOverview = () => {
  const details = useSelector(selectSessionDayDetails);
  const isDetailsLoading = useSelector(selectIsDetailsLoading);

  const classes = useStyles({ width: '400px' });

  return (
    <div className={classes.root}>
      <TabContent>
        <div className={classes.toolbarContainer}>
          <Typography variant="h6" component="h6" color="primary">
            {Localize.get('Labels.Overview')}
          </Typography>
        </div>
        <TabContainer>
          <Paper sx={{ height: '400px', width: '400px' }}>
            {isDetailsLoading ? (
              <Skeleton
                skeletonCount={6}
                skeletonProps={{
                  variant: 'rectangular',
                  height: '40px',
                  sx: { mb: 1 }
                }}
              />
            ) : details ? (
              <>
                <SessionInformation data={details} />
              </>
            ) : (
              <NoData titleText={Localize.get('NoDataMessage.TitleTextOverview')} />
            )}
          </Paper>
        </TabContainer>
      </TabContent>
    </div>
  );
};

export default SessionOverview;
