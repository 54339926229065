import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';

const FormContainer = ({ children }) => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      pointerEvents: 'auto'
    }}
  >
    <Paper
      sx={{
        width: '100rem',
        margin: '24px 24px',
        padding: '10px 24px 30px 24px',
        minHeight: '300px',
        justifyContent: 'center',
        alignItems: 'center',
        verticalAlign: 'center',
        flexDirection: 'column',
        display: 'flex'
      }}
    >
      <Box sx={{ width: '100%' }}>{children}</Box>
    </Paper>
  </Box>
);

export default FormContainer;
