import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLayoutFullScreen: false,
  activeTab: ''
};

export const detailsToolbarSlice = createSlice({
  name: 'detailsToolbar',
  initialState,
  reducers: {
    setLayoutFullScreen: (state, { payload }) => {
      state.isLayoutFullScreen = payload;
    },
    setActiveTab: (state, { payload }) => {
      state.activeTab = payload;
    }
  }
});

export const selectIsFullScreen = (state) => state.detailsToolbar.isLayoutFullScreen;
export const selectActiveTab = (state) => state.detailsToolbar.activeTab;

const { actions, reducer } = detailsToolbarSlice;

export const { setLayoutFullScreen, setActiveTab } = actions;

export default reducer;
