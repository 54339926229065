import { ACTION_MODES, TAB_KEYS } from '@common/Constants';
import { errorMessageFormatter, successMessageFormatter } from '@common/helpers/MessageFormatter';
import { TAB_PATHS } from '@common/network/ApiPaths';
import EntityTypes from '@common/network/EntityTypes';
import { closeConfirmDialog } from '@components/ConfirmDialog/confirmDialogSlice';
import { SnackbarSeverityTypes, showSnackbar } from '@components/Snackbar/snackbarSlice';
import {
  deleteByPath,
  getByPathAndParams,
  postByPathAndData,
  putByPathAndData
} from '@services/BaseApi';

import {
  ADD_NOTES_ERROR,
  ADD_NOTES_SUCCESS,
  DELETE_NOTE_ERROR,
  DELETE_NOTE_SUCCESS,
  LOAD_NOTES,
  NOTES_DIALOG_CLOSE,
  NOTES_DIALOG_OPEN,
  SET_ANCHOR_EL,
  SET_LOADING,
  SET_NOTES_ERROR,
  SET_NOTES_SELECTION_MODEL,
  SET_NOTES_TYPE,
  UPDATE_NOTES_ERROR,
  UPDATE_NOTES_SUCCESS
} from './types';

export async function getNotes(dispatch, entityId, entityType) {
  if (!entityId) {
    return;
  }

  dispatch(setLoading(true));

  try {
    const { data } = await getByPathAndParams({
      path: TAB_PATHS.NOTES.GET,
      pathVariables: { id: entityId, entity: entityType }
    });

    dispatch(loadNotes(data));
  } catch (error) {
    dispatch(setNotesError({ message: error?.message, time: error?.timestamp }));
  }
}

export async function createNote(dispatch, reduxDispatch, postData, setDetails) {
  dispatch(setLoading(true));

  try {
    const { data: { content, count } = {} } = await postByPathAndData({
      path: TAB_PATHS.NOTES.POST,
      pathVariables: {
        entityType: postData.entityType,
        noteType: postData.type,
        entityId: postData.entityId
      },
      data: postData
    });
    if (content) {
      dispatch(addNoteSuccess(content));
      reduxDispatch(setDetails({ counts: { [TAB_KEYS.NOTE]: count } }));
      reduxDispatch(
        showSnackbar({
          message: successMessageFormatter(EntityTypes.NOTES, ACTION_MODES.Create),
          severity: SnackbarSeverityTypes.SUCCESS
        })
      );
    }
  } catch ({ response }) {
    dispatch(addNoteError({ message: response?.data?.message, time: response?.data?.timestamp }));
  }
}

export async function updateNote(dispatch, postData, reduxDispatch) {
  dispatch(setLoading(true));

  try {
    const { data } = await putByPathAndData({
      path: TAB_PATHS.NOTES.PUT,
      pathVariables: { id: postData.noteId },
      data: postData
    });

    dispatch(updateNoteSuccess(data));
    reduxDispatch(
      showSnackbar({
        message: successMessageFormatter(EntityTypes.NOTES, ACTION_MODES.Edit),
        severity: SnackbarSeverityTypes.SUCCESS
      })
    );
  } catch ({ response }) {
    dispatch(updateNoteError(response?.data.message));
  }
}

export async function removeNote(dispatch, reduxDispatch, entityId, entityType, id, setDetails) {
  if (!entityId) {
    return;
  }

  dispatch(setLoading(true));

  try {
    const {
      data: { count }
    } = await deleteByPath({
      path: TAB_PATHS.NOTES.DELETE,
      pathVariables: { entityId, entityType, id }
    });

    dispatch(removeNoteComplete(id));
    reduxDispatch(closeConfirmDialog());
    reduxDispatch(setDetails({ counts: { [TAB_KEYS.NOTE]: count } }));

    reduxDispatch(
      showSnackbar({
        message: successMessageFormatter(EntityTypes.NOTES, ACTION_MODES.Delete),
        severity: SnackbarSeverityTypes.SUCCESS
      })
    );
  } catch (error) {
    reduxDispatch(
      showSnackbar({
        message: errorMessageFormatter(error, EntityTypes.NOTES, ACTION_MODES.Delete),
        severity: SnackbarSeverityTypes.ERROR
      })
    );
    dispatch(removeNoteError({ message: error?.message, time: error?.timestamp }));
  }
}

export function setLoading(payload) {
  return {
    type: SET_LOADING,
    isLoading: payload
  };
}

export function setNotesError(payload) {
  return {
    type: SET_NOTES_ERROR,
    error: payload
  };
}

export function loadNotes(payload) {
  return {
    type: LOAD_NOTES,
    payload
  };
}

export function setSelectionModel(payload) {
  return {
    type: SET_NOTES_SELECTION_MODEL,
    payload
  };
}

export function removeNoteComplete(payload) {
  return {
    type: DELETE_NOTE_SUCCESS,
    payload
  };
}

export function removeNoteError(payload) {
  return {
    type: DELETE_NOTE_ERROR,
    payload
  };
}

export function openNotesDialog(payload) {
  return {
    type: NOTES_DIALOG_OPEN,
    payload
  };
}

export function closeNotesDialog() {
  return {
    type: NOTES_DIALOG_CLOSE
  };
}

export function addNoteSuccess(payload) {
  return {
    type: ADD_NOTES_SUCCESS,
    payload
  };
}

export function addNoteError(payload) {
  return {
    type: ADD_NOTES_ERROR,
    payload
  };
}

export function updateNoteError(payload) {
  return {
    type: UPDATE_NOTES_ERROR,
    payload
  };
}

export function updateNoteSuccess(payload) {
  return {
    type: UPDATE_NOTES_SUCCESS,
    payload
  };
}

export function setAnchorEl(payload) {
  return {
    type: SET_ANCHOR_EL,
    payload
  };
}
export function setNotesType(payload) {
  return {
    type: SET_NOTES_TYPE,
    payload
  };
}
