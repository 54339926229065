export default [
  {
    groupName: 'Events.Information',
    fields: [
      {
        label: 'Sessions.Name',
        name: 'name',
        required: true,
        disabled: false,
        component: 'text'
      },
      {
        label: 'Sessions.SessionDescription',
        name: 'description',
        required: false,
        disabled: false,
        component: 'text'
      },
      {
        label: 'Sessions.Type',
        name: 'typeId',
        required: true,
        disabled: false,
        component: 'select'
      },
      {
        label: 'Sessions.ParticipantMax',
        name: 'maxParticipants',
        required: true,
        disabled: false,
        component: 'text',
        type: 'number'
      },
      {
        label: 'Sessions.ParticipantMin',
        name: 'minParticipants',
        required: false,
        disabled: false,
        component: 'text',
        type: 'number'
      },
      {
        label: 'Sessions.ParticipantOpt',
        name: 'optParticipants',
        required: false,
        disabled: false,
        component: 'text',
        type: 'number'
      }
    ]
  },
  {
    groupName: 'Sessions.ScheduleInformation',
    fields: [
      {
        label: 'Labels.Date',
        name: 'timeBlockDate',
        required: true,
        disabled: false,
        component: 'datepicker'
      },
      {
        label: 'Sessions.TimeFrom',
        name: 'startTime',
        required: true,
        disabled: false,
        component: 'timepicker'
      },
      {
        label: 'Sessions.TimeTo',
        name: 'endTime',
        required: true,
        disabled: false,
        component: 'timepicker'
      }
    ]
  },
  {
    groupName: 'Sessions.VenueInformation',
    fields: [
      {
        label: 'Labels.VenueName',
        name: 'venues[0].name',
        required: false,
        disabled: true,
        component: 'text'
      },
      {
        label: 'Labels.VenueCity',
        name: 'venues[0].city',
        required: false,
        disabled: true,
        component: 'text'
      },
      {
        label: 'Labels.VenueCountry',
        name: 'venues[0].country',
        required: false,
        disabled: true,
        component: 'text'
      }
    ]
  }
];
