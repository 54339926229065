import React, { useEffect, useState, useCallback } from 'react';
import Localize from 'react-intl-universal';
import { useDispatch, useSelector } from 'react-redux';

import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Icon from '@mui/material/Icon';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/styles';

import { DIALOG_PATHS } from '@common/network/ApiPaths';
import { scrubFiltersForBE } from '@components/FilterDialog/filtersSlice';
import { SEARCH_INPUT_DELAY } from '@config/inputs';
import useDebounce from '@hooks/handlers/useDebounce';

const AUTOCOMPLETE_HEIGHT = '260px';
const AUTOCOMPLETE_WIDTH = '240px';

import {
  fetchData,
  fetchMeetingRoomsByVenueId,
  selectDragAndDropFilter,
  selectListOfMeetingRooms,
  selectMeetingRoomFilter,
  selectMeetingRoomFilterValue,
  selectVenuesFilterValue,
  setMeetingRoomFilterValue
} from '../dragAndDropDialogSlice';

const StyledAutocomplete = styled(Autocomplete)(() => ({
  '& .MuiInputBase-root ': {
    padding: '0px !important',
    width: AUTOCOMPLETE_WIDTH
  },
  '& .MuiFormLabel-root': {
    top: '-8px'
  },
  '& legend': {
    display: 'none'
  },
  '& .MuiOutlinedInput-notchedOutline': {
    top: 0
  }
}));

const RoomsFilter = () => {
  const dispatch = useDispatch();
  const meetingRoomFilter = useSelector(selectMeetingRoomFilter);
  const listOfMeetingRooms = useSelector(selectListOfMeetingRooms);
  const value = useSelector(selectMeetingRoomFilterValue);
  const dragAndDropFilter = useSelector(selectDragAndDropFilter);
  const venuesFilter = useSelector(selectVenuesFilterValue) || [];

  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => setOptions(listOfMeetingRooms), [listOfMeetingRooms]);

  useDebounce(
    () => {
      if (inputValue === '') {
        setIsLoading(false);
        return;
      }

      dispatch(
        fetchMeetingRoomsByVenueId({
          filter: {
            filters: {
              advancedFilters: scrubFiltersForBE({
                filters: [
                  {
                    id: 'venueId',
                    operator: { key: 'contains' },
                    value: dragAndDropFilter.venueIds
                  }
                ]
              })
            },
            page: 0,
            search: inputValue
          },
          isSearch: true
        })
      )
        .unwrap()
        .then(() => setIsLoading(false));
    },
    SEARCH_INPUT_DELAY,
    [inputValue]
  );

  const onChange = useCallback(
    (e, newValue) => {
      if (Array.isArray(newValue) && newValue?.length === 0) {
        return;
      }

      // Backspace disabled
      if (e.which === 8) {
        return;
      }

      dispatch(setMeetingRoomFilterValue(newValue.map((v) => ({ id: v.id, name: v.name }))));
      dispatch(
        fetchMeetingRoomsByVenueId({
          filter: {
            filters: {
              advancedFilters: scrubFiltersForBE({
                filters: [
                  {
                    id: 'venueId',
                    operator: { key: 'contains' },
                    value: dragAndDropFilter.venueIds
                  }
                ]
              })
            },
            page: 0,
            search: ''
          },
          isSearch: true
        })
      );
      dispatch(
        fetchData({
          path: DIALOG_PATHS.MEETING_ROOMS,
          filter: {
            ...dragAndDropFilter,
            meetingRoomIds: newValue.map(({ id }) => id)
          },
          filterValues: venuesFilter
        })
      );
    },
    [dragAndDropFilter]
  );

  const onInputChange = useCallback((e, newInputValue) => {
    if (newInputValue === '') {
      return;
    }

    setIsLoading(true);
    setInputValue(newInputValue);
  }, []);

  const loadMoreRooms = () => {
    if (meetingRoomFilter.totalElements === listOfMeetingRooms.length) {
      return;
    }

    setIsLoading(true);
    dispatch(
      fetchMeetingRoomsByVenueId({
        filter: {
          ...meetingRoomFilter,
          page: meetingRoomFilter.page + 1
        }
      })
    )
      .unwrap()
      .then(() => setIsLoading(false));
  };

  return (
    <StyledAutocomplete
      onChange={onChange}
      onInputChange={onInputChange}
      loadingText={Localize.get('Labels.Loading')}
      noOptionsText={Localize.get('Labels.NoOptions')}
      autoComplete
      openOnFocus
      multiple
      disableClearable
      clearOnBlur={false}
      limitTags={1}
      id="multiple-limit-tags"
      loading={isLoading}
      options={options}
      getOptionLabel={(option) => option?.name}
      value={value}
      renderInput={(params) => (
        <TextField {...params} label={Localize.get('DragAndDrop.RoomsFilter')} variant="outlined" />
      )}
      freeSolo
      size="small"
      isOptionEqualToValue={(option, value) => option.id === value.id || option.id === ''}
      // No Option helper since freeSolo removes No Option
      getOptionDisabled={(option) => option.id === -1}
      filterOptions={() => {
        if (!options.length) return [{ name: Localize.get('Labels.NoOptions'), id: -1 }];
        return options;
      }}
      renderTags={(tagValue, getTagProps) => {
        return tagValue.map((option, index) => (
          <Chip
            sx={{ height: '27px' }}
            key={index}
            color="primary"
            variant="outlined"
            label={option.name}
            {...getTagProps({ index })}
            disabled={value.length === 1}
            onDelete={() => {
              const index = value.findIndex((r) => r.id === option.id);
              const resources = [...value.slice(0, index), ...value.slice(index + 1)].map(
                ({ name, id }) => ({ id: id, name: name })
              );
              dispatch(setMeetingRoomFilterValue(resources));
              dispatch(
                fetchData({
                  path: DIALOG_PATHS.MEETING_ROOMS,
                  filter: {
                    ...dragAndDropFilter,
                    meetingRoomIds: resources.map(({ id }) => id)
                  },
                  filterValues: venuesFilter
                })
              );
            }}
          />
        ));
      }}
      renderOption={(props, option) => (
        <Box component="li" {...props} key={option.id}>
          <Icon color="primary">meeting_room</Icon>
          <Typography sx={{ ml: 1 }}>{option.name}</Typography>
        </Box>
      )}
      ListboxProps={{
        sx: { maxHeight: AUTOCOMPLETE_HEIGHT },
        onScroll: (event) => {
          const listboxNode = event.currentTarget;
          if (listboxNode.scrollTop + listboxNode.clientHeight === listboxNode.scrollHeight) {
            loadMoreRooms();
          }
        }
      }}
    />
  );
};

export default RoomsFilter;
