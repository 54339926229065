import React from 'react';
import Localize from 'react-intl-universal';
import { useDispatch, useSelector } from 'react-redux';

import { Formik } from 'formik';

import CancelIcon from '@mui/icons-material/Cancel';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import SaveIcon from '@mui/icons-material/Save';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import useTheme from '@mui/styles/useTheme';

import FormArray from './components/FormArray';
import useValidationSchema from './config/useValidationSchema';
import {
  selectIsOpen,
  selectConfig,
  closeFilterDialog,
  setFilter,
  selectInitialValues,
  reset,
  FILTER_ACTIONS
} from './filtersSlice';

const FilterDialog = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const validationSchema = useValidationSchema();

  const isOpen = useSelector(selectIsOpen);
  const initialValues = useSelector(selectInitialValues);
  const { title = '', config = [] } = useSelector(selectConfig);

  const onResetClick = () => {
    dispatch(reset());
  };

  const onCancelClick = () => {
    dispatch(closeFilterDialog(FILTER_ACTIONS.Cancel));
  };

  const onSubmit = (values) => {
    dispatch(setFilter(values));
    dispatch(closeFilterDialog(FILTER_ACTIONS.Confirm));
  };

  return (
    <Dialog fullWidth fullScreen={fullScreen} maxWidth={'md'} open={isOpen}>
      <DialogTitle
        sx={{
          boxShadow: 3,
          marginBottom: '1rem',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between'
        }}
      >
        <span data-test-id="filter-title">{title}</span>
        <Button
          data-test-id="reset-button"
          onClick={onResetClick}
          startIcon={<RestartAltIcon />}
          disableFocusRipple
          variant="outlined"
        >
          {Localize.get('Buttons.ResetAndClose')}
        </Button>
      </DialogTitle>

      <Formik
        validationSchema={validationSchema}
        validateOnMount
        initialValues={initialValues}
        onSubmit={onSubmit}
      >
        {({ values, setFieldValue, isValid, handleSubmit }) => (
          <>
            <DialogContent sx={{ pt: '1rem!important' }}>
              <FormArray config={config} values={values} setFieldValue={setFieldValue} />
            </DialogContent>

            <DialogActions
              sx={{
                backgroundColor: 'primary.contrastText',
                justifyContent: 'center'
              }}
            >
              <Button
                disableFocusRipple
                variant="outlined"
                startIcon={<CancelIcon />}
                data-test-id="filter-cancel"
                onClick={onCancelClick}
                color="error"
              >
                {Localize.get('Buttons.Cancel')}
              </Button>
              <Button
                disabled={!isValid}
                disableFocusRipple
                variant="outlined"
                startIcon={<SaveIcon />}
                data-test-id="filter-save"
                onClick={handleSubmit}
              >
                {Localize.get('Buttons.Save')}
              </Button>
            </DialogActions>
          </>
        )}
      </Formik>
    </Dialog>
  );
};

export default FilterDialog;
