import { useSelector } from 'react-redux';

import deLocale from 'date-fns/locale/de';
import enLocale from 'date-fns/locale/en-US';
import { get } from 'lodash';

import CalendarPickerSkeleton from '@mui/lab/CalendarPickerSkeleton';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';

import { dateInitFormats } from '@common/Constants';
import { selectLanguage } from '@pages/Users/usersSlice';

const localeMap = {
  en: enLocale,
  de: deLocale
};

const Timepicker = ({ field = {}, form: { touched, errors } = {}, required = false, ...rest }) => {
  const lang = useSelector(selectLanguage);
  const isError = Boolean(get(touched, field?.name) && get(errors, field?.name));
  const errorMsg = get(touched, field?.name) && get(errors, field?.name);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={localeMap[lang]}>
      <TimePicker
        inputFormat={dateInitFormats.time}
        renderLoading={() => <CalendarPickerSkeleton />}
        renderInput={(params) => (
          <TextField
            size="small"
            helperText={errorMsg}
            required={required}
            {...field}
            {...params}
            error={isError}
          />
        )}
        {...field}
        {...rest}
      />
    </LocalizationProvider>
  );
};

export default Timepicker;
