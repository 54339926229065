import { createSlice } from '@reduxjs/toolkit';

// Saved state through routes change
const initialState = {
  savedState: null
};

export const persistSlice = createSlice({
  name: 'persist',
  initialState,
  reducers: {
    saveState: (state, action) => {
      state.savedState = action.payload;
    },
    clearState: (state) => {
      state.savedState = null;
    }
  }
});

export const selectSavedState = (state) => state.persist.savedState;

export const { saveState, clearState } = persistSlice.actions;

export default persistSlice.reducer;
