import React, { memo } from 'react';

import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

const StyledControlsContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  flexGrow: 1,
  width: theme.sizes.leftContainer
}));

const StyledTypography = styled(Typography)(() => ({
  maxWidth: '100%',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  fontWeight: 600
}));

const MasterListTitle = memo(({ children }) => (
  <StyledControlsContainer>
    <StyledTypography>{children}</StyledTypography>
  </StyledControlsContainer>
));

MasterListTitle.displayName = 'MasterListTitle';

export default MasterListTitle;
