import React from 'react';
import Localize from 'react-intl-universal';
import { useSelector } from 'react-redux';

import Overview from '@components/Overview';
import { selectDetails, selectIsDetailsLoading } from '@pages/MeetingRooms/meetingRoomsSlice';

const config = {
  title: 'MeetingRooms.Overview',
  skeletonCount: 3,
  details: [
    {
      label: 'Labels.AvailableSeats',
      name: 'availableSeats'
    },
    {
      label: 'Labels.VenueName',
      name: 'venue.name'
    },
    {
      label: 'Labels.VenueCity',
      name: 'venue.city'
    }
  ]
};

const MeetingRoomsOverview = () => {
  const details = useSelector(selectDetails);
  const isLoading = useSelector(selectIsDetailsLoading);

  return (
    <Overview
      invite={{
        text: Localize.get('Links.InviteLink', {
          entity: Localize.get('MeetingRooms.Item')
        }),
        link: '/meeting-rooms/new'
      }}
      isLoading={isLoading}
      data={details}
      config={config}
    />
  );
};

export default MeetingRoomsOverview;
