import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Localize from 'react-intl-universal';
import { useMatch, useNavigate } from 'react-location';
import { useSelector, useDispatch } from 'react-redux';

import EntityTypes from '@common/network/EntityTypes';
import { CONFIRM_ACTIONS, openConfirmDialog } from '@components/ConfirmDialog';
import { selectIsFullScreen } from '@components/DetailsToolbar/detailsToolbarSlice';
import {
  FILTER_ACTIONS,
  openFilterDialog,
  resetFilterState,
  scrubFiltersForBE,
  selectIsActive,
  selectFilters
} from '@components/FilterDialog/filtersSlice';
import Header from '@components/Header';
import LayoutContainer, { LeftContainer, RightContainer } from '@components/LayoutContainer';
import MasterList from '@components/MasterList';
import MasterListFooter from '@components/MasterListFooter';
import MasterListItem from '@components/MasterListItem';
import MasterListTitle from '@components/MasterListTitle';
import MasterListToolbar from '@components/MasterListToolbar';
import { selectIsOpen, setMode } from '@components/RightSidebar/rightSidebarSlice';
import SortDialog from '@components/SortDialog';
import { SEARCH_INPUT_DELAY } from '@config/inputs';
import useDebounce from '@hooks/handlers/useDebounce';
import {
  setFilterParams,
  setLoading,
  selectFilter,
  selectTotalElements,
  selectTotalPages,
  selectDetails,
  selectIsLoading,
  selectIsDetailsLoading,
  selectIsFilterChanged,
  selectList,
  setSelectedId,
  selectId,
  initialState,
  fetchEmployees,
  fetchEmployeesDetails,
  resetState,
  setDetails,
  saveEmployee,
  deleteEmployee
} from '@pages/Employees/employeesSlice';

import { EmployeesDetails, EmployeesTabs } from './components';
import Sidebar from './components/Sidebar';
import allFilters from './util/fields/filters';
import { SORT_DATA } from './util/sortConfig';

const Employees = () => {
  const {
    data: {
      config: {
        data: { filters } //TODO sorts, tabs
      }
    }
  } = useMatch();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isOpenDialogSort, setIsOpenDialogSort] = useState(false);

  const selectedId = useSelector(selectId);
  const data = useSelector(selectList);
  const details = useSelector(selectDetails);
  const totalElements = useSelector(selectTotalElements);
  const totalPages = useSelector(selectTotalPages);
  const filter = useSelector(selectFilter);

  const isLoading = useSelector(selectIsLoading);
  const isDetailsLoading = useSelector(selectIsDetailsLoading);
  const isFilterDataChanged = useSelector(selectIsFilterChanged);

  const isOpen = useSelector(selectIsOpen);
  const isFullScreen = useSelector(selectIsFullScreen);

  const isFilterActive = useSelector(selectIsActive);
  const additionalFilters = useSelector(selectFilters);

  // Get List of entities on initialization and sort change
  useEffect(() => {
    isFilterActive
      ? dispatch(
          fetchEmployees({
            ...filter,
            filters: { advancedFilters: scrubFiltersForBE(additionalFilters) }
          })
        )
      : dispatch(fetchEmployees(filter));
  }, [filter.sortBy, filter.sortDirection, filter.page]);

  useEffect(() => {
    if (isFilterActive) {
      dispatch(
        fetchEmployees({
          ...filter,
          filters: { advancedFilters: scrubFiltersForBE(additionalFilters) }
        })
      );
    }
  }, [isFilterDataChanged]);

  // Get initial List of entities and on sort change
  useEffect(() => {
    dispatch(fetchEmployeesDetails(selectedId));
  }, [selectedId]);

  // Get List of entities on search change
  useDebounce(
    () => {
      isFilterActive
        ? dispatch(
            fetchEmployees({
              ...filter,
              filters: { advancedFilters: scrubFiltersForBE(additionalFilters) }
            })
          )
        : dispatch(fetchEmployees(filter));
    },
    SEARCH_INPUT_DELAY,
    [filter.search]
  );

  // On Destroy
  useEffect(() => {
    return () => {
      dispatch(resetState());
      dispatch(resetFilterState());
    };
  }, []);

  const filterKeys = useMemo(() => filters?.map(({ key }) => key), [filters]);

  const activeFilters = useMemo(
    () => allFilters.filter(({ id }) => filterKeys.includes(id)),
    [allFilters, filterKeys]
  );

  const onFilterClick = useCallback(
    () =>
      dispatch(
        openFilterDialog({
          title: `${Localize.get('Events.Item')} ${Localize.get('Labels.Filter')}`,
          config: activeFilters.map((filter) => ({
            ...filter,
            label: Localize.get(filter.label),
            operator: { label: Localize.get(filter.operator.label), key: filter.operator.key },
            options: filter.options?.map((option) => Localize.get(option))
          }))
        })
      ).then(({ payload }) => {
        if (payload.action === FILTER_ACTIONS.Cancel) {
          return;
        }

        if (payload.action === FILTER_ACTIONS.Reset) {
          dispatch(fetchEmployees({ ...filter }));
          return;
        }

        dispatch(
          fetchEmployees({
            ...filter,
            filters: { advancedFilters: scrubFiltersForBE(payload.filters) }
          })
        );
      }),

    []
  );

  const onSave = (values) => {
    return dispatch(saveEmployee(values))
      .unwrap()
      .then(() => {
        return Promise.resolve();
      })
      .catch((rejectedValueOrSerializedError) => {
        return Promise.reject({
          rejectedValueOrSerializedError,
          entityType: EntityTypes.EMPLOYEE
        });
      });
  };

  // Render Master List Item
  const renderMasterListItem = useCallback(
    ({ id, personDto, companyName }) => (
      <MasterListItem
        key={id}
        id={id}
        heading={{ left: `${personDto?.firstName} ${personDto?.lastName}`, right: id }}
        leftDetails={{ firstRow: companyName }}
        selectedId={selectedId}
        onItemSelect={(payload) => dispatch(setSelectedId(payload))}
      />
    ),
    [selectedId]
  );

  // Delete Employee
  const onEmployeeDelete = () =>
    dispatch(
      openConfirmDialog({
        title: Localize.get('ConfirmationMessages.Delete', {
          item: Localize.get('Employees.Item')?.toLowerCase()
        }),
        confirmButton: Localize.get('Buttons.Delete'),
        cancelButton: Localize.get('Buttons.Cancel')
      })
    )
      .unwrap()
      .then((result) => {
        if (result === CONFIRM_ACTIONS.Cancel) {
          return;
        }
        dispatch(deleteEmployee(selectedId))
          .unwrap()
          .then(() => dispatch(setMode('read')))
          .catch((rejectedValueOrSerializedError) => console.error(rejectedValueOrSerializedError));
      });

  return (
    <LayoutContainer>
      <LeftContainer isFullScreen={isFullScreen}>
        <Header>
          <MasterListTitle>{`${Localize.get(
            'Employees.MasterListTittle'
          )} (${totalElements})`}</MasterListTitle>
        </Header>
        <MasterListToolbar
          isFilterActive={isFilterActive}
          isDisabled={data?.length === 0}
          onSortClick={() => setIsOpenDialogSort(true)}
          onFilterClick={onFilterClick}
          isSortActive={
            filter.sortBy !== initialState.filter.sortBy ||
            filter.sortDirection !== initialState.filter.sortDirection
          }
          searchProps={{
            value: filter.search,
            onSearchChange: (e) => {
              dispatch(setLoading(true));
              dispatch(setFilterParams({ key: 'search', value: e.target.value }));
            }
          }}
        />
        <MasterList isLoading={isLoading} data={data} renderMasterItem={renderMasterListItem} />
        <MasterListFooter
          onPageChange={(e, page) => dispatch(fetchEmployees({ ...filter, page: page - 1 }))}
          onAddClick={() => navigate({ to: '/employees/new', replace: false })}
          pagination={{ totalPages, page: filter.page + 1 }}
        />

        <SortDialog
          onCancel={() => setIsOpenDialogSort(false)}
          open={isOpenDialogSort}
          sortState={filter}
          fields={SORT_DATA}
          sortHandler={({ sortBy, sortDirection }) => {
            dispatch(
              setFilterParams([
                { key: 'sortBy', value: sortBy },
                { key: 'sortDirection', value: sortDirection }
              ])
            );
          }}
        />
      </LeftContainer>

      <RightContainer open={isOpen} isFullScreen={isFullScreen}>
        <EmployeesDetails details={details} entityId={selectedId}>
          <EmployeesTabs
            entityId={selectedId}
            entityType={EntityTypes.EMPLOYEE}
            details={details}
            setDetails={setDetails}
          />
        </EmployeesDetails>
      </RightContainer>

      <Sidebar
        details={details}
        isDetailsLoading={isDetailsLoading}
        onDelete={onEmployeeDelete}
        onSave={onSave}
      />
    </LayoutContainer>
  );
};

export default Employees;
