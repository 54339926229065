import { get } from 'lodash';

import Icon from '@mui/material/Icon';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';

const TextInput = ({
  field = {}, // { name, value, onChange, onBlur }
  form: { touched, errors } = {}, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  icon = '',
  size = 'small',
  variant = 'outlined',
  required,
  disableBlacklist = false,
  type = 'text',
  maxLength = null,
  ...rest
}) => {
  const blacklistForNumber = (e) => {
    if (!disableBlacklist && type === 'number') {
      // 0 for null values
      // 8 for backspace
      // 48-57 for 0-9 numbers
      if ((e.which != 8 && e.which != 0 && e.which < 48) || e.which > 57) {
        e.preventDefault();
      }
    }
  };

  return (
    <TextField
      onKeyPress={blacklistForNumber}
      autoComplete="off"
      size={size}
      type={type}
      variant={variant}
      error={Boolean(get(touched, field?.name) && get(errors, field?.name))}
      helperText={get(touched, field?.name) && get(errors, field?.name)}
      inputProps={{ maxLength }}
      InputProps={{
        endAdornment: icon ? (
          <InputAdornment position="end">
            <Icon>{icon}</Icon>
          </InputAdornment>
        ) : null
      }}
      {...field}
      {...rest}
      required={required}
    />
  );
};

export default TextInput;
