import { useSelector } from 'react-redux';

import Box from '@mui/material/Box';

import { selectIsFullScreen } from '@components/DetailsToolbar/detailsToolbarSlice';

const InlineCreateHeaderContainer = ({ children }) => {
  const isFullScreen = useSelector(selectIsFullScreen);

  return (
    <Box
      bottom="0"
      right="0"
      padding="0.7rem"
      zIndex="10"
      position="absolute"
      sx={{
        left: (theme) => (isFullScreen ? 0 : `calc(100vw - ${theme.sizes.rightContainer})`),
        backgroundColor: 'primary.contrastText',
        pr: 4,
        pl: 4
      }}
      display="flex"
      flexDirection="row"
      flexWrap="wrap"
      justifyContent="space-between"
      alignItems="center"
    >
      {children}
    </Box>
  );
};

export default InlineCreateHeaderContainer;
