import React from 'react';
import Localize from 'react-intl-universal';
import { useMatch } from 'react-location';

import { BUSINESS_PARTNERS_PATHS } from '@common/network/ApiPaths';
import RightSidebar from '@components/RightSidebar';
import { sidebar } from '@pages/Locations/util/fields';
import useLocationSchemaEdit from '@pages/Locations/util/schemas/useLocationSchemaEdit';

import { mapPropsToAddress } from '../CreateLocation/getLocationConfig';

const Sidebar = ({
  details = null,
  isDetailsLoading = false,
  onSave = () => {},
  onDelete = () => {}
}) => {
  const {
    data: {
      phoneTypes: { data: phoneTypes },
      addressTypes: { data: addressTypes },
      emailTypes: { data: emailTypes }
    }
  } = useMatch();

  const locationValidationSchemaEdit = useLocationSchemaEdit();

  const extendedConfig = (setFieldValue, values, setValues) => {
    return {
      ['hotelFacilities']: {
        isVisible: details?.roles[0]?.supplierType?.id !== 2
      },
      ['businessPartnerType']: {
        formItemChangeHandler: (e, data) => {
          setFieldValue('businessPartnerType', data.newValue);
        },
        path: BUSINESS_PARTNERS_PATHS.BUSINESS_PARTNER_TYPES
      },
      ['roles[0].roleType']: {
        formItemChangeHandler: (e, data) => {
          setFieldValue('roles[0].roleType', data.newValue);
        },
        path: BUSINESS_PARTNERS_PATHS.BUSINESS_PARTNER_ROLE_TYPES
      },
      ['roles[0].supplierType']: {
        formItemChangeHandler: (e, data) => {
          setFieldValue('roles[0].supplierType', data.newValue);
        },
        path: BUSINESS_PARTNERS_PATHS.SUPPLIER_TYPES
      },
      ['owner.personTypeId']: {
        tokenValue:
          values?.owner?.firstName && values.owner?.lastName
            ? [{ title: `${values.owner.firstName} ${values.owner.lastName}` }]
            : undefined,
        selectionModel: values?.owner?.id,
        selectItem: (owner) => {
          setValues({
            ...values,
            owner: {
              id: owner.personTypeId,
              firstName: owner.firstName,
              lastName: owner.lastName
            }
          });
        },
        onIconClick: (e) => {
          if (e?.target?.name === 'delete') {
            setValues({
              ...values,
              owner: null
            });
          }
        }
      },
      ['organizationPerson.organization.parentalAccount.id']: {
        tokenValue: values?.organizationPerson?.organization?.parentalAccount?.name
          ? [{ title: `${values?.organizationPerson?.organization?.parentalAccount?.name}` }]
          : undefined,
        selectionModel: values?.organizationPerson?.organization?.parentalAccount?.id,
        selectItem: (value) => {
          setFieldValue('organizationPerson.organization.parentalAccount', value);
          setFieldValue(
            'organizationPerson.organization.parentalAccount.id',
            value.organizationPersonId
          );
        },
        onIconClick: (e) => {
          if (e?.target?.name === 'delete') {
            setFieldValue('organizationPerson.organization.parentalAccount', null);
          }
        },
        organizationPersonId: details.organizationPerson.id
      },
      ['roles[0].hotel.rating']: {
        onChange: (e, value) => {
          setFieldValue('roles[0].hotel.rating', value);
        }
      },
      ['organizationPerson.phoneDtos']: {
        ['type.id']: {
          options: phoneTypes
        }
      },
      ['organizationPerson.emailDtos']: {
        ['type.id']: {
          options: emailTypes
        }
      },
      ['abcClassification']: {
        formItemChangeHandler: (e, data) => {
          setFieldValue('abcClassification', data.newValue);
        },
        path: BUSINESS_PARTNERS_PATHS.ABC_CLASSIFICATION
      },
      ['organizationPerson.addressDtos']: {
        ['type.id']: {
          options: addressTypes
        }
      }
    };
  };

  return (
    <RightSidebar
      config={{
        title: Localize.get('Locations.Details'),
        fieldsConfig: sidebar
      }}
      mapPropsToAddress={mapPropsToAddress}
      extendedConfig={extendedConfig}
      data={details}
      isDetailsLoading={isDetailsLoading}
      onSave={onSave}
      onDelete={onDelete}
      validationSchema={locationValidationSchemaEdit}
    />
  );
};

export default Sidebar;
