import React from 'react';
import Localize from 'react-intl-universal';
import { useDispatch, useSelector } from 'react-redux';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import DetailsContainer from '@components/DetailsContainer/DetailsContainer';
import DetailsToolbar from '@components/DetailsToolbar';
import {
  setLayoutFullScreen,
  selectIsFullScreen
} from '@components/DetailsToolbar/detailsToolbarSlice';
import Header from '@components/Header';
import { openSidebar } from '@components/RightSidebar/rightSidebarSlice';

const OrgStructureDetails = ({ entityId = '', headerTitle = '', children }) => {
  const dispatch = useDispatch();
  const isFullScreen = useSelector(selectIsFullScreen);

  const toolbarOptions = [
    {
      icon: isFullScreen ? 'close_fullscreen' : 'fullscreen',
      label: 'expand',
      tooltip: isFullScreen
        ? `${Localize.get('Labels.ExitFullScreen')}`
        : `${Localize.get('Labels.EnterFullScreen')}`,
      isDisabled: false,
      onClick: () =>
        isFullScreen ? dispatch(setLayoutFullScreen(false)) : dispatch(setLayoutFullScreen(true))
    },
    {
      icon: 'menu_open',
      label: 'menu',
      tooltip: Localize.get('Labels.ViewSidebar'),
      isDisabled: !entityId,
      onClick: () => dispatch(openSidebar())
    }
  ];

  return (
    <>
      <Header display="flex" flexDirection="row">
        <Box flexGrow="1" textAlign="right" justifyContent="flex-end">
          <Typography sx={{ p: '6px 8px', fontWeight: 600 }}>{headerTitle}</Typography>
        </Box>

        <Box flexGrow="1" flexDirection="row" justifyContent="flex-end">
          <DetailsToolbar options={toolbarOptions} />
        </Box>
      </Header>

      <DetailsContainer>{children}</DetailsContainer>
    </>
  );
};

export default OrgStructureDetails;
