const SORT_DATA = [
  { name: 'id', text: 'Events.ID' },
  // { name: 'coordinator', text: 'Events.Coordinator' },
  { name: 'type', text: 'Events.EventType' },
  { name: 'eventName', text: 'Events.EventName' },
  { name: 'startDate', text: 'Events.StartDate' },
  { name: 'status', text: 'Events.Status' }
];

const SORT_DATA_PARTICIPANTS = [
  { name: 'id', text: 'Events.ID' },
  { name: 'firstName', text: 'Labels.FirstName' },
  { name: 'lastName', text: 'Labels.LastName' },
  { name: 'startDate', text: 'Events.StartDate' },
  { name: 'status', text: 'Events.Status' }
];

export { SORT_DATA, SORT_DATA_PARTICIPANTS };
