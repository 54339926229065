import Localize from 'react-intl-universal';

import { dateInitFormats } from '@common/Constants';
import { calculateDayDifference, dateToFormat } from '@common/helpers/dates';
import { copyObjectWithoutRef } from '@common/helpers/helpers';
import mapPropsToComponent from '@common/helpers/mapPropsToComponent';
import {
  ADDRESS_PATH,
  LOCALES_PATHS,
  PARTICIPANTS_PATHS,
  PERSONS_PATHS
} from '@common/network/ApiPaths';
import ContactTypeDropdown from '@components/ContactTypeDropdown/ContactTypeDropdown';
import create from '@pages/Participants/utils/fields/create';
import dialogFieldsHotelSearch from '@pages/Participants/utils/fields/dialogFieldsHotelSearch';
import initialValues from '@pages/Participants/utils/initialValues';

export const mapPropsToAddress = (setFieldValue, arrayItemIndex, arrayItem, values) => {
  return {
    ['region']: {
      formItemChangeHandler: (e, data) => {
        setFieldValue(`addressDtos[${arrayItemIndex}].region`, data?.newValue);
      },
      path: ADDRESS_PATH.GET_REGION_BY_ID,
      pathVariables: { id: arrayItem?.country?.id },
      pathVariablesRequired: ['id']
    },
    ['country']: {
      path: LOCALES_PATHS.COUNTRIES,
      formItemChangeHandler: (e, data, name) => {
        const fieldIndex = name && name.split('.')[1];
        const addressDtos = copyObjectWithoutRef(values.addressDtos);
        addressDtos[fieldIndex].country = data.newValue;
        addressDtos[fieldIndex].region = null;
        setFieldValue('addressDtos', addressDtos);
      }
    }
  };
};

export const getFieldsConfig = (
  values,
  setFieldValue,
  setValues,
  isNavigatedFromParticipantTabInEvents,
  allergy,
  language,
  occupancy,
  phoneTypes,
  emailTypes,
  addressTypes,
  statuses,
  setOccupancy
) => {
  const isPersonDtoActive = Boolean(values?.personDto?.id);

  return mapPropsToComponent(create, {
    ['personDto.firstName']: { disabled: isPersonDtoActive },
    ['personDto.lastName']: { disabled: isPersonDtoActive },
    ['personDto.title.id']: { disabled: isPersonDtoActive },
    ['company']: {
      tokenValue: values?.company?.name ? [{ title: values.company?.name }] : undefined,
      onIconClick: (e) => {
        if (e?.target?.name === 'delete') {
          setFieldValue('company', initialValues.company);
        }
      },
      selectItem: (value) => {
        setFieldValue('company', {
          ...value
        });
        setFieldValue('company.id', value.organizationPersonId);
      }
    },
    ['company.id']: {
      // disabled: Boolean(values?.personDto?.id),
      tokenValue: values?.company?.name ? [{ title: values.company?.name }] : undefined,
      onIconClick: (e) => {
        if (e?.target?.name === 'delete') {
          setFieldValue('company', initialValues.company);
        }
      },
      selectItem: (value) => {
        setFieldValue('company', { ...value });
        setFieldValue('company.id', value.organizationPersonId);
      }
    },
    ['invoiceCompany']: {
      tokenValue: values?.invoiceCompany?.name
        ? [{ title: values.invoiceCompany?.name }]
        : undefined,
      onIconClick: (e) => {
        if (e?.target?.name === 'delete') {
          setFieldValue('invoiceCompany', initialValues.invoiceCompany);
        }
      },
      selectItem: (value) => {
        setFieldValue('invoiceCompany', {
          ...value
        });
        setFieldValue('invoiceCompany.id', value.organizationPersonId);
      }
    },
    ['nativeLanguage.id']: {
      options: language
    },
    ['nativeLanguage']: {
      options: language,
      formItemChangeHandler: (e, { newValue = {} }) => {
        setValues({
          ...values,
          nativeLanguage: newValue
        });
      }
    },
    ['allergies']: {
      options: allergy,
      // disabled: !values?.hasAllergies,
      onChange: (e, newValue) => setFieldValue('allergies', newValue)
    },

    ['event']: {
      tokenValue: values?.event?.name ? [{ title: values.event?.name }] : undefined,
      onIconClick: (e) => {
        if (e?.target?.name === 'delete') {
          setFieldValue('event', initialValues.event, true);
        }
      },
      selectItem: (value) => {
        setFieldValue('event', {
          ...value
        });
      },
      initQueryState: { event: values?.id },
      selectionModel: values?.event?.id,
      disabled: isNavigatedFromParticipantTabInEvents
    },
    ['personDto.name']: {
      tokenValue: values?.personDto?.name ? [{ title: values?.personDto?.name }] : null,
      onIconClick: (e) => {
        if (e?.target?.name === 'delete') {
          setValues({
            ...values,
            personDto: {
              ...initialValues.personDto,
              birthDate: null
            },
            company: initialValues.company,
            addressDtos: initialValues.addressDtos,
            emailDtos: initialValues.emailDtos,
            phoneDtos: initialValues.phoneDtos
          });
        }
      },
      selectItem: (value) => {
        setValues({
          ...values,
          personDto: {
            firstName: value?.firstName,
            lastName: value?.lastName,
            name: `${value.firstName} ${value.lastName}`,
            birthDate: value?.birthDate || null,
            birthPlace: value?.birthPlace || '',
            id: value?.id || null,
            title: value?.title || initialValues.personDto.title
          },
          company: value?.companyName,
          emailDtos: value?.emailDtos,
          addressDtos: value?.addressDtos,
          phoneDtos: value?.phoneDtos
        });
      },
      selectionModel: values?.personDto?.id,
      extendedComponents: ContactTypeDropdown
    },
    ['personDto.birthDate']: {
      disabled: isPersonDtoActive,
      maxDate: new Date(),
      onChange: (value) => setFieldValue('personDto.birthDate', value || null)
    },
    ['personDto.birthPlace']: {
      disabled: isPersonDtoActive
    },
    ['hotelReservation.id.hotel.id']: {
      dialogColumns: dialogFieldsHotelSearch,
      tokenValue: values?.hotelReservation?.id?.hotel?.name
        ? [{ title: values.hotelReservation.id.hotel.name }]
        : undefined,
      selectionModel: values?.hotelReservation?.id?.hotel?.id,
      selectItem: (value) => {
        setValues({
          ...values,
          hotelReservation: {
            ...values.hotelReservation,
            id: {
              ...values.hotelReservation.id,
              hotel: { id: value.id, name: value.name }
            },
            roomType: initialValues.hotelReservation.roomType
          }
        });
      },
      onIconClick: (e) => {
        if (e?.target?.name === 'delete') {
          setValues({
            ...values,
            hotelReservation: initialValues.hotelReservation
          });
        }
      }
    },
    ['hotelReservation.roomType']: {
      disabled: !values.hotelReservation.id.hotel.id,
      required: Boolean(values?.hotelReservation?.id?.hotel?.id),
      responseFormater: ({ content = [] }) => {
        return content;
      },
      getOptionLabel: (option) => {
        return option?.type?.value ? option.type.value : option.value;
      },
      isOptionEqualToValue: (option, value) => {
        return option?.type?.id === value?.id;
      },
      formItemChangeHandler: (e, { newValue = {} }) => {
        let type = newValue?.type ?? null;
        let occupancy = newValue?.occupancy ?? 0;
        setValues({
          ...values,
          hotelReservation: {
            ...values.hotelReservation,
            roomType: type,
            persons:
              values.hotelReservation.persons > occupancy
                ? occupancy
                : values.hotelReservation.persons
          }
        });
        setOccupancy(occupancy);
      },
      path: PARTICIPANTS_PATHS.HOTELS_ACCOMMODATIONS,
      pathVariables: { id: values?.hotelReservation?.id?.hotel?.id },
      pathVariablesRequired: ['id']
    },
    ['hotelReservation.persons']: {
      disabled: !values.hotelReservation.id.hotel.id,
      required: Boolean(values?.hotelReservation?.id?.hotel?.id),
      InputProps: { inputProps: { min: 0, max: occupancy } }
    },
    ['hotelReservation.arrivalDate']: {
      disabled: !values.hotelReservation.id.hotel.id,
      required: Boolean(values?.hotelReservation?.id?.hotel?.id),
      emptyLabel: Localize.get('Hotel.ArrivalDate'),
      onChange: (newValue) =>
        setValues({
          ...values,
          hotelReservation: {
            ...values.hotelReservation,
            arrivalDate: dateToFormat(newValue, dateInitFormats.arrivalDate),
            nights: calculateDayDifference(newValue, values.hotelReservation.departureDate)
          }
        }),
      value: values.hotelReservation.arrivalDate
    },
    ['hotelReservation.departureDate']: {
      disabled: !values.hotelReservation.id.hotel.id,
      required: Boolean(values?.hotelReservation?.id?.hotel?.id),
      emptyLabel: Localize.get('Hotel.DepartureDate'),
      onChange: (newValue) =>
        setValues({
          ...values,
          hotelReservation: {
            ...values.hotelReservation,
            departureDate: dateToFormat(newValue, dateInitFormats.departureDate),
            nights: calculateDayDifference(values.hotelReservation.arrivalDate, newValue)
          }
        }),
      value: values.hotelReservation.departureDate
    },
    ['hotelReservation.shuttle']: {
      disabled: !values.hotelReservation.id.hotel.id,
      checked: values.hotelReservation.shuttle
    },

    ['status']: {
      options: statuses
    },
    ['cancellationReason']: {
      path: PARTICIPANTS_PATHS.PARTICIPANTS_CANCELLATION_REASON,
      responseFormater: (data) => data.filter((el) => el.value !== ''),
      formItemChangeHandler: (e, { newValue = {} }) => {
        setValues({
          ...values,
          cancellationReason: newValue
        });
      }
    },
    ['result']: {
      path: PARTICIPANTS_PATHS.PARTICIPANT_RESULTS,
      responseFormater: (data) => data.filter((el) => el.value !== ''),
      formItemChangeHandler: (e, { newValue = {} }) => {
        setValues({
          ...values,
          result: newValue
        });
      }
    },
    ['personDto.gender']: {
      path: PERSONS_PATHS.GENDERS,
      formItemChangeHandler: (e, { newValue = {} }) => {
        setValues({
          ...values,
          personDto: { ...values.personDto, gender: newValue }
        });
      }
    },

    ['emailDtos']: {
      arrayConfig: { actions: { areButtonsHidden: isPersonDtoActive } },
      ['type.id']: {
        options: emailTypes,
        disabled: isPersonDtoActive
      },
      ['email']: {
        disabled: isPersonDtoActive
      }
    },
    ['phoneDtos']: {
      arrayConfig: { actions: { areButtonsHidden: isPersonDtoActive } },
      ['type.id']: {
        options: phoneTypes,
        disabled: isPersonDtoActive
      },
      ['phone']: {
        disabled: isPersonDtoActive
      }
    },
    ['addressDtos']: {
      arrayConfig: { actions: { areButtonsHidden: isPersonDtoActive } },
      ['type.id']: {
        options: addressTypes,
        disabled: isPersonDtoActive
      },
      ['street']: { disabled: isPersonDtoActive },
      ['number']: { disabled: isPersonDtoActive },
      ['postalCode']: { disabled: isPersonDtoActive },
      ['city']: { disabled: isPersonDtoActive },
      ['poBox']: { disabled: isPersonDtoActive },
      ['state']: { disabled: isPersonDtoActive },
      ['region']: { disabled: isPersonDtoActive },
      ['country']: { disabled: isPersonDtoActive }
    }
  });
};

export default getFieldsConfig;
