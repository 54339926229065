import Localize from 'react-intl-universal';

import * as yup from 'yup';

import { DATE_PICKER_MIN_BIRTH_DATE } from '@common/Constants';
import { addresses, phone, email } from '@common/yup';

export default function () {
  return yup.object().shape({
    person: yup.object().shape({
      firstName: yup.string().required(Localize.get('ValidationMessages.FirstNameRequired')),
      lastName: yup.string().required(Localize.get('ValidationMessages.LastNameRequired')),
      birthPlace: yup.string(),
      birthDate: yup
        .date()
        .nullable(true)
        .typeError(Localize.get('ValidationMessages.DateInvalid'))
        .max(new Date(), Localize.get('ValidationMessages.BirthDateInvalid'))
        .min(DATE_PICKER_MIN_BIRTH_DATE, Localize.get('ValidationMessages.BirthDateInvalid'))
    }),
    function: yup.string(),
    jobTitle: yup.string(),
    department: yup.string(),
    organizationPerson: yup
      .object()
      .shape({
        id: yup
          .number()
          .required(Localize.get('ValidationMessages.BusinessPartnerName'))
          .nullable(true),
        value: yup.string()
      })
      .nullable(true)
      .required(Localize.get('ValidationMessages.BusinessPartnerName')),
    title: yup.object().shape({
      id: yup.number().nullable(true),
      value: yup.string()
    }),
    contactStatus: yup.string(),
    phoneDtos: phone(),
    emailDtos: email(),
    addressDtos: addresses()
  });
}
