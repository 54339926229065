import React from 'react';

import LayoutContainer, { LeftContainer, RightContainer } from '@components/LayoutContainer';

import DnDCalendar from './components/DnDCalendar';
import Sidebar from './components/Sidebar';

const Calendar = () => {
  return (
    <LayoutContainer>
      <LeftContainer isFullScreen={false}>
        <Sidebar />
      </LeftContainer>
      <RightContainer open={true} isFullScreen={true}>
        <DnDCalendar />
      </RightContainer>
    </LayoutContainer>
  );
};

export default Calendar;
