import React from 'react';
import Localize from 'react-intl-universal';
import { useMatch } from 'react-location';

import { Field } from 'formik';

import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import Rating from '@mui/material/Rating';
import Typography from '@mui/material/Typography';

import CheckboxInput from '@components/CheckboxInput';
import SelectInput from '@components/SelectInput';
import TextInput from '@components/TextInput';

const LocationType = ({ values, handleChange }) => {
  const {
    data: {
      supplierTypes: { data: supplierTypes }
    }
  } = useMatch();

  const locationsType = supplierTypes.filter((el) => el.id !== 3);

  return (
    <>
      <Grid container spacing={2} sx={{ justifyContent: 'center', mt: 4 }}>
        <Grid item xs={12} sm={8} md={6} lg={4}>
          <FormControl fullWidth>
            <Field
              name="supplierType"
              label={Localize.get('Labels.LocationType')}
              options={locationsType}
              component={SelectInput}
            />
          </FormControl>
        </Grid>
      </Grid>

      <Box sx={{ maxWidth: '900px', margin: '20px auto ' }}>
        {[1, 4].includes(values.supplierType) && (
          <>
            <Grid container spacing={{ xs: 2, md: 3 }}>
              <Grid item xs={12}>
                <Typography
                  component="h5"
                  variant="h5"
                  sx={{ mb: 2, mt: 2 }}
                  color="text.secondary"
                >
                  {Localize.get('HotelFacilities.Title')}
                </Typography>
              </Grid>
            </Grid>

            <Grid container spacing={{ xs: 2, md: 3 }} sx={{ mb: 4 }}>
              <Grid item xs={12} md={6} lg={4}>
                <FormControl>
                  <Field
                    label={Localize.get('HotelFacilities.ShuttleService')}
                    name="facilities.hasShuttleService"
                    component={CheckboxInput}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <FormControl>
                  <Field
                    label={Localize.get('HotelFacilities.Bar')}
                    name="facilities.hasBar"
                    component={CheckboxInput}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <FormControl>
                  <Field
                    label={Localize.get('HotelFacilities.Parking')}
                    name="facilities.hasParking"
                    component={CheckboxInput}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <FormControl>
                  <Field
                    label={Localize.get('HotelFacilities.AirportShuttle')}
                    name="facilities.hasAirportShuttle"
                    component={CheckboxInput}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <FormControl>
                  <Field
                    label={Localize.get('HotelFacilities.WellnessCenter')}
                    name="facilities.hasWellness"
                    component={CheckboxInput}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <FormControl>
                  <Field
                    label={Localize.get('HotelFacilities.FitnessCenter')}
                    name="facilities.hasFitnessCenter"
                    component={CheckboxInput}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <FormControl>
                  <Field
                    label={Localize.get('HotelFacilities.PetsAllowed')}
                    name="facilities.petsAllowed"
                    component={CheckboxInput}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <FormControl>
                  <Field
                    label={Localize.get('HotelFacilities.SPA')}
                    name="facilities.hasSpa"
                    component={CheckboxInput}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <FormControl>
                  <Field
                    label={Localize.get('HotelFacilities.FreeWiFi')}
                    name="facilities.hasFreeWifi"
                    component={CheckboxInput}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <FormControl>
                  <Field
                    label={Localize.get('HotelFacilities.Restaurant')}
                    name="facilities.hasRestaurant"
                    component={CheckboxInput}
                  />
                </FormControl>
              </Grid>
            </Grid>

            <Grid container spacing={{ xs: 2, md: 3 }}>
              <Grid item xs={6}>
                <Box flexDirection="row">
                  <Typography component="legend">
                    {Localize.get('HotelFacilities.Rating')}
                  </Typography>
                  <Rating
                    name="facilities.rating"
                    onChange={handleChange}
                    value={Number(values?.facilities?.rating)}
                  />
                </Box>
              </Grid>

              <Grid
                item
                xs={6}
                sx={{
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                <FormControl fullWidth>
                  <Field
                    label={Localize.get('HotelFacilities.Reception')}
                    name="facilities.receptionNote"
                    component={TextInput}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </>
        )}
      </Box>
    </>
  );
};

export default LocationType;
