import { memo, useCallback, useEffect } from 'react';
import Localize from 'react-intl-universal';
import { useNavigate, useMatch, useSearch } from 'react-location';
import { useDispatch, useSelector } from 'react-redux';

import Link from '@mui/material/Link';
import TablePagination from '@mui/material/TablePagination';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';

import getLocalesText from '@common/helpers/tables/getLocalesText';
import { saveState } from '@common/storage/persistSlice';
import TabContainer from '@components/TabContainer';
import TabContent from '@components/TabContent/TabContent';
import TableToolbar from '@components/TableToolbar';
import ToolbarItem from '@components/TableToolbarItem';
import { StyledDataGrid } from '@components/TokenValueModalTrigger/components/DialogDataGrid';
import {
  selectDetails,
  selectFilter as selectIltSessionFilter,
  selectId as selectSessionId
} from '@pages/IltSession/iltSessionSlice';

import { CreateSessionDay } from '../../SessionDay';
import {
  fetchSessionDays,
  PAGE_SIZE_OPTIONS,
  selectIsLoading,
  selectList,
  selectSelectionModel,
  selectTotalElements,
  setFilterParams,
  setSelectionModel,
  resetState,
  selectFilter
} from './sessionDaysSlice';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  toolbarContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(1),
    alignItems: 'center'
  },
  toolbar: {
    minHeight: '3rem'
  },
  container: {
    height: 400,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  tableRow: {
    '&:hover': {
      backgroundColor: `${theme.palette.grey[400]} !important`,
      cursor: 'pointer'
    }
  }
}));

const SessionsDayTable = ({ columns = [], entityType = null, toolbarOptions = [] }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { params } = useMatch();
  const search = useSearch();

  const details = useSelector(selectDetails);
  const data = useSelector(selectList);
  const filter = useSelector(selectFilter);
  const sessionsFilter = useSelector(selectIltSessionFilter);
  const isLoading = useSelector(selectIsLoading);
  const totalElements = useSelector(selectTotalElements);
  const selectionModel = useSelector(selectSelectionModel);
  const sessionId = useSelector(selectSessionId);

  useEffect(() => {
    if (sessionId || params.sessionId) {
      dispatch(fetchSessionDays({ filter }));
    }
  }, [filter]);

  useEffect(() => {
    if (sessionId) {
      dispatch(
        setFilterParams([
          { key: 'page', value: 0 },
          { key: 'sessionId', value: sessionId }
        ])
      );
    }
  }, [sessionId]);

  useEffect(() => {
    if (params?.sessionId) {
      dispatch(
        setFilterParams([
          { key: 'page', value: 0 },
          { key: 'sessionId', value: params.sessionId }
        ])
      );
    }
  }, [params.sessionId]);

  const onPageChange = useCallback(
    (e, value) => dispatch(setFilterParams({ key: 'page', value })),
    []
  );

  const onPageSizeChange = useCallback(({ target }) => {
    dispatch(
      setFilterParams([
        { key: 'page', value: 0 },
        { key: 'size', value: target.value }
      ])
    );
  }, []);

  useEffect(() => {
    return () => dispatch(resetState());
  }, []);

  const onRowClick = ({ id }) => {
    const sessionIdentifier = sessionId || params?.sessionId;

    if (sessionIdentifier) {
      dispatch(saveState({ sessionsFilter, sessionsSelectedId: sessionIdentifier }));
      navigate({ to: `/ilt-session/${sessionIdentifier}/session-day/${id}`, replace: false });
    } else {
      alert('Invalid session identifier');
    }
  };

  const onToolbarItemClick = (fieldName, files) => {
    if (fieldName === 'add_sessions') {
      if (files.length > 1) {
        alert('Batch upload currently not supported');
        return;
      }

      const sessionIdentifier = sessionId || params?.sessionId;

      if (sessionIdentifier) {
        dispatch(saveState({ sessionsFilter, sessionsSelectedId: sessionIdentifier }));
        navigate({ search: (previousUrlParams) => ({ ...previousUrlParams, mode: 'create' }) });
      } else {
        alert('Invalid session identifier');
      }
    } else if (fieldName === 'delete') {
      if (selectionModel.length > 1) {
        alert('Batch delete currently not supported');
        return;
      }
    }
  };

  const isToolbarItemDisabled = (fieldName) => {
    switch (fieldName) {
      case 'add_sessions':
        return !(sessionId && entityType) && !params?.sessionId;
      case 'delete':
        return true; //selectionModel.length === 0;
      default:
        return false;
    }
  };

  const renderTablePagination = useCallback(
    (props) => {
      return (
        <TablePagination
          sx={{ borderBottom: 'none' }}
          rowsPerPageOptions={PAGE_SIZE_OPTIONS}
          rowsPerPage={filter?.size}
          page={filter?.page}
          count={totalElements}
          onPageChange={onPageChange}
          onRowsPerPageChange={onPageSizeChange}
          labelDisplayedRows={({ from, to, count }) => {
            return <span>{`${from}–${to} of ${count !== -1 ? count : `more than ${to}`}`}</span>;
          }}
          backIconButtonProps={
            isLoading
              ? {
                  disabled: isLoading
                }
              : undefined
          }
          nextIconButtonProps={
            isLoading
              ? {
                  disabled: isLoading
                }
              : undefined
          }
          {...props}
        />
      );
    },
    [filter.page, filter.size, isLoading]
  );

  return (
    <div className={classes.root}>
      {search?.mode === 'create' ? (
        <CreateSessionDay session={details} />
      ) : (
        <TabContent>
          <div className={classes.toolbarContainer}>
            <Typography variant="h6" component="h6" color="primary">
              {Localize.get('Labels.Sessions')}
            </Typography>
            <Toolbar className={classes.toolbar}>
              {toolbarOptions?.map((item, index) => (
                <ToolbarItem
                  key={index}
                  item={item}
                  isDisabled={item.disabled || isToolbarItemDisabled(item.fieldName)}
                  onToolbarItemClick={onToolbarItemClick}
                />
              ))}
            </Toolbar>
          </div>
          <TabContainer>
            <StyledDataGrid
              loading={isLoading}
              rows={data}
              getRowClassName={() => classes.tableRow}
              columns={[
                ...columns.map((column) => ({
                  ...column,
                  headerName: Localize.get(column.headerName),
                  renderCell: (params) => (
                    <div data-test-id={`${params.field}-${params?.row?.id ?? 'default'}`}>
                      {['name'].includes(params.field) ? (
                        <Tooltip title={params.value} arrow>
                          <Link
                            sx={{
                              display: 'block',
                              cursor: 'pointer',
                              maxWidth: params?.colDef?.width,
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis'
                            }}
                            onClick={() => onRowClick(params)}
                          >
                            {params?.value}
                          </Link>
                        </Tooltip>
                      ) : (
                        <Typography variant="body" component="div">
                          {params.value}
                        </Typography>
                      )}
                    </div>
                  )
                }))
              ]}
              localeText={getLocalesText(Localize)}
              paginationMode="server"
              sortingMode="server"
              filterMode="client"
              checkboxSelection
              disableColumnMenu
              rowCount={totalElements}
              disableSelectionOnClick
              components={{
                Toolbar: TableToolbar,
                Pagination: (props) => (
                  <table>
                    <tbody>
                      <tr>{renderTablePagination(props)}</tr>
                    </tbody>
                  </table>
                )
              }}
              onSelectionModelChange={(values) => dispatch(setSelectionModel(values))}
            />
          </TabContainer>
        </TabContent>
      )}
    </div>
  );
};

export default memo(SessionsDayTable);
