import { isNil } from 'lodash';

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { SORT_DIRECTION, ACTION_MODES, NAVIGATE_TOASTER_TIMEOUT } from '@common/Constants';
import { deepMerge } from '@common/helpers/deepMerge';
import { errorMessageFormatter, successMessageFormatter } from '@common/helpers/MessageFormatter';
import { CONTACTS_PATHS } from '@common/network/ApiPaths';
import EntityTypes from '@common/network/EntityTypes';
import { showSnackbar, SnackbarSeverityTypes } from '@components/Snackbar/snackbarSlice';
import { MASTER_LIST_PAGE_SIZE } from '@config/network';
import { fetchContacts as fetchBusinessPartnerContacts } from '@pages/BusinessPartners/components/Tabs/ContactTable/contactTableSlice';
import { fetchContacts as fetchLocationContacts } from '@pages/Locations/components/Tabs/ContactTable/contactTableSlice';
import {
  deleteByPath,
  getByPathAndParams,
  postByPathAndData,
  putByPathAndData
} from '@services/BaseApi';

import { SORT_DATA } from './util/sortConfig';
import { formatContactForSave, formatDetailsResponse } from './util/util';

export const initialState = {
  selectedId: null,
  data: [],
  filter: {
    search: '',
    sortBy: SORT_DATA[0].name,
    sortDirection: SORT_DIRECTION.DESCENDING,
    page: 0,
    size: MASTER_LIST_PAGE_SIZE
  },
  totalPages: 0,
  totalElements: 0,
  details: null,
  isLoading: false,
  createIsLoading: false,
  isDetailsLoading: false
};

export const fetchContacts = createAsyncThunk('contacts/fetchMasterList', (filter) =>
  getByPathAndParams({
    path: CONTACTS_PATHS.GET_MASTER_LIST,
    params: { ...filter }
  })
    .then((response) => response.data)
    .catch((error) => error)
);

export const fetchContactsDetails = createAsyncThunk(
  'contacts/fetchDetails',
  (selectedId) =>
    getByPathAndParams({
      path: CONTACTS_PATHS.GET_BY_ID,
      pathVariables: { id: selectedId }
    })
      .then((response) => {
        return formatDetailsResponse(response.data);
      })
      .catch((error) => error),
  {
    condition: (selectedId) => Boolean(selectedId),
    dispatchConditionRejection: true
  }
);

export const createContact = createAsyncThunk(
  'contacts/create',
  ({ contact, isBusinessPartnerTab, isLocationTab }, { dispatch, rejectWithValue, getState }) => {
    const { selectedId } = getState()?.businessPartners;
    const { filter: bpContactFilter } = getState()?.businessPartnerContacts;
    const { filter: locationContactFilter } = getState()?.locationContacts;

    return postByPathAndData({
      path: CONTACTS_PATHS.POST,
      data: formatContactForSave(contact)
    })
      .then((response) => {
        setTimeout(() => {
          dispatch(
            showSnackbar({
              message: successMessageFormatter(EntityTypes.CONTACT, ACTION_MODES.Create),
              severity: SnackbarSeverityTypes.SUCCESS
            })
          );
        }, NAVIGATE_TOASTER_TIMEOUT);

        if (isBusinessPartnerTab) {
          dispatch(fetchBusinessPartnerContacts({ entityId: selectedId, filter: bpContactFilter }));
        }

        if (isLocationTab) {
          dispatch(fetchLocationContacts({ entityId: selectedId, filter: locationContactFilter }));
        }

        return response.data;
      })
      .catch((error) => {
        dispatch(
          showSnackbar({
            message: errorMessageFormatter(error, 'CONTACT', ACTION_MODES.Create),
            severity: SnackbarSeverityTypes.ERROR
          })
        );
        return rejectWithValue(error?.response);
      });
  }
);

export const deleteContact = createAsyncThunk('contacts/delete', (id, thunkAPI) =>
  deleteByPath({
    path: CONTACTS_PATHS.DELETE,
    pathVariables: { id }
  })
    .then((response) => {
      setTimeout(() => {
        thunkAPI.dispatch(
          showSnackbar({
            message: successMessageFormatter(EntityTypes.CONTACT, ACTION_MODES.Delete),
            severity: SnackbarSeverityTypes.SUCCESS
          })
        );
      }, NAVIGATE_TOASTER_TIMEOUT);
      return response.data;
    })
    .catch((error) => {
      thunkAPI.dispatch(
        showSnackbar({
          message: errorMessageFormatter(error, EntityTypes.CONTACT, ACTION_MODES.Delete),
          severity: SnackbarSeverityTypes.ERROR
        })
      );

      return error.response;
    })
);

export const saveContact = createAsyncThunk('contacts/save', (postData, thunkAPI) => {
  return putByPathAndData({
    path: CONTACTS_PATHS.PUT,
    data: formatContactForSave(postData),
    pathVariables: { id: postData.id }
  })
    .then((response) => {
      setTimeout(() => {
        thunkAPI.dispatch(
          showSnackbar({
            message: successMessageFormatter(EntityTypes.CONTACT, ACTION_MODES.Edit),
            severity: SnackbarSeverityTypes.SUCCESS
          })
        );
      }, NAVIGATE_TOASTER_TIMEOUT);
      return response.data;
    })
    .catch((error) => thunkAPI.rejectWithValue(error.response));
});

export const contactsSlice = createSlice({
  name: 'contacts',
  initialState,
  reducers: {
    resetState: () => initialState,
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setSelectedId: (state, action) => {
      state.selectedId = action.payload;
    },
    setConfirmationDialog: (state, action) => {
      state.isConfirmDialogOpen = action.payload;
    },
    setDetails: (state, { payload }) => {
      state.details = deepMerge(state.details, payload);
    },
    deleteSuccess: (state, action) => {
      state.data = [...state.data.filter((item) => item.id !== action.payload.id)];
      state.selectedId = [...state.data.filter((item) => item.id !== action.payload.id)];
      state.isConfirmDialogOpen = false;
    },
    setFilterParams: (state, action) => {
      let newFilterValues = {};

      // Case when search value is reset to empty and search bar is closed
      if (action.payload.key === 'search' && !action.payload.value && !state.filter.search) {
        state.isLoading = false;
        return;
      }

      if (Array.isArray(action.payload)) {
        newFilterValues = action.payload.reduce(
          (obj, item) => ((obj[item.key] = item.value), obj),
          {}
        );
      } else {
        newFilterValues = { [action.payload.key]: action.payload.value };
      }
      // return first in list after search or sort
      state.selectedId = null;

      state.filter = { ...state.filter, ...newFilterValues, page: 0 };
    },
    resetPage: (state) => {
      state.filter.page = 0;
    }
  },
  extraReducers: (builder) => {
    builder
      // Get all
      .addCase(fetchContacts.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.data = payload?.content || state.data;
        state.totalPages = payload?.totalPages || state.totalPages;
        state.totalElements = isNil(payload?.totalElements)
          ? state.totalElements
          : payload?.totalElements;
        state.selectedId = payload?.content?.[0]?.id;
        state.filter.page = payload?.pageable?.pageNumber;
        state.filter.size = payload?.pageable?.pageSize;
      })
      .addCase(fetchContacts.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchContacts.rejected, (state) => {
        state.data = [];
        state.isLoading = false;
      })
      // Get Details
      .addCase(fetchContactsDetails.fulfilled, (state, action) => {
        state.details = action.payload;
        state.isDetailsLoading = false;
      })
      .addCase(fetchContactsDetails.pending, (state) => {
        state.isDetailsLoading = true;
      })
      .addCase(fetchContactsDetails.rejected, (state) => {
        state.details = null;
        state.isDetailsLoading = false;
      })
      // Delete
      .addCase(deleteContact.fulfilled, (state, action) => {
        state.isConfirmDialogOpen = false;
        state.totalElements -= 1;
        state.isLoading = false;

        state.details = null;
        state.data = state.data.filter((item) => item.id !== action.meta.arg);
        state.selectedId = state.data.filter((item) => item.id !== action.meta.arg)[0]?.id;

        if (state.data.length === 0 && state.totalPages === 1) {
          state.filter.page = 0;
          state.totalPages = 0;
          state.totalElements = 0;
        } else if (state.data.length === 0 && state.totalPages > 1) {
          state.filter.page -= 1;
        }
      })
      .addCase(createContact.fulfilled, (state, { payload }) => {
        state.filter.page = 0;
        state.selectedId = payload?.id;
        state.createIsLoading = false;
      })
      .addCase(createContact.pending, (state) => {
        state.createIsLoading = true;
      })
      .addCase(createContact.rejected, (state) => {
        state.createIsLoading = false;
      })
      .addCase(saveContact.fulfilled, (state, action) => {
        state.isDetailsLoading = false;
        state.data = state.data.map((item) =>
          item.id === action.meta.arg.id ? deepMerge(item, action.meta.arg) : item
        );
        state.details = deepMerge(state.details, action.meta.arg);
      })
      .addCase(saveContact.pending, (state) => {
        state.isDetailsLoading = true;
      })
      .addCase(saveContact.rejected, (state) => {
        state.isDetailsLoading = false;
      });
  }
});

export const selectId = (state) => state.contacts.selectedId;
export const selectList = (state) => state.contacts.data;
export const selectFilter = (state) => state.contacts.filter;
export const selectTotalElements = (state) => state.contacts.totalElements;
export const selectTotalPages = (state) => state.contacts.totalPages;
export const selectDetails = (state) => state.contacts.details;

export const selectCreateIsLoading = (state) => state.contacts.createIsLoading;
export const selectIsDetailsLoading = (state) => state.contacts.isDetailsLoading;
export const selectIsLoading = (state) => state.contacts.isLoading;

const { actions, reducer } = contactsSlice;

export const {
  setError,
  setLoading,
  setSelectedId,
  setConfirmationDialog,
  deleteSuccess,
  setFilterParams,
  resetPage,
  resetState,
  setDetails
} = actions;

export default reducer;
