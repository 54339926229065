export default [
  {
    groupName: 'ResourcesTile.Information',
    fields: [
      {
        label: 'ResourcesTile.ResourceName',
        name: 'name',
        required: true,
        disabled: false,
        component: 'text'
      },
      {
        label: 'ResourcesTile.Category',
        name: 'resourceCategory',
        required: true,
        disabled: false,
        component: 'autocomplete'
      },
      {
        label: 'ResourcesTile.AmountIrrelevant',
        name: 'irrAmount',
        required: false,
        disabled: false,
        component: 'switch'
      },
      {
        label: 'ResourcesTile.AvailableAmount',
        name: 'availableAmount',
        required: false,
        disabled: false,
        component: 'text',
        type: 'number'
      },
      {
        label: 'ResourcesTile.GLAccount',
        name: 'glAccount',
        required: false,
        disabled: false,
        component: 'autocomplete'
      },
      {
        label: 'ResourcesTile.ConsumableItem',
        name: 'consumableItem',
        required: false,
        disabled: false,
        component: 'switch'
      },
      {
        label: 'ResourcesTile.GiftWarehouse',
        name: 'giftWarehouse',
        required: false,
        disabled: false,
        component: 'autocomplete'
      }
    ]
  },
  {
    groupName: 'ResourcesTile.PriceInformation',
    fields: [
      {
        label: 'ResourcesTile.BillableItem',
        name: 'billableItem',
        required: false,
        disabled: false,
        component: 'switch'
      },
      {
        label: 'ResourcesTile.Price',
        name: 'price',
        required: true,
        disabled: false,
        component: 'text',
        type: 'number'
      },
      {
        label: 'ResourcesTile.Currency',
        name: 'currency',
        required: false,
        disabled: false,
        component: 'autocomplete'
      }
    ]
  }
];
