export default {
  leftContainer: '20%', // Left sidebar width (Master List)
  rightContainer: '80%', // Right container width (Details)

  rightContainerRightMargin: '0rem',
  rightContainerLeftMargin: '0rem',

  appHeader: '2.4rem', // Main header height
  subheader: '3rem', // Subheader height (used in all pages),
  tabsHeight: '4.2rem', // Tabs header height,

  masterListToolbar: '3rem', // Master list toolbar (search, sort, filter)

  leftSidebar: 300, // Left sidebar width
  rightSidebar: 600, // Right sidebar width,

  masterListFooter: '3rem', // Master list footer
  rightContainerFullScreen: '100%', // Right Container full screen,

  dragAndDropSidebar: '15%' // Sidebar for drag&drop component
};
