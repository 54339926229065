import { DIALOG_PATHS } from '@common/network/ApiPaths';
import IndividualTypes from '@common/network/IndividualTypes';

export default [
  {
    groupName: 'Labels.Information',
    fields: [
      {
        label: 'Labels.Name',
        name: 'organizationPerson.name',
        required: true,
        disabled: false,
        component: 'text'
      },
      {
        label: 'Labels.Role',
        name: 'roles',
        required: true,
        disabled: true,
        component: 'select'
      },
      {
        label: 'Labels.Owner',
        name: 'owner',
        required: false,
        disabled: false,
        dialogTitle: 'Labels.Owner',
        component: 'tokenValue',
        value: '',
        initQueryState: {
          type: IndividualTypes.EMPLOYEE,
          personTypeId: '',
          firstName: '',
          lastName: '',
          companyName: ''
        },
        fetchDataPath: DIALOG_PATHS.PERSONS,
        dialogColumns: [
          {
            field: 'personTypeId',
            headerName: 'Labels.ID',
            width: 100,
            editable: false,
            defaultValue: '',
            headerAlign: 'left',
            sortable: false
          },
          {
            field: 'firstName',
            headerName: 'Labels.FirstName',
            width: 350,
            editable: false,
            defaultValue: '',
            headerAlign: 'left',
            sortable: false
          },
          {
            field: 'lastName',
            headerName: 'Labels.LastName',
            width: 350,
            editable: false,
            defaultValue: '',
            headerAlign: 'left',
            sortable: false
          },
          {
            field: 'companyName',
            headerName: 'Labels.CompanyName',
            width: 350,
            editable: false,
            defaultValue: '',
            headerAlign: 'left',
            sortable: false
          }
        ]
      },
      {
        label: 'Labels.AbcClassification',
        name: 'abcClassification',
        required: false,
        disabled: false,
        component: 'autocomplete'
      },
      {
        label: 'Labels.ParentalAccount',
        name: 'organizationPerson.organization.parentalAccount',
        required: false,
        disabled: false,
        dialogTitle: 'Labels.SearchParentalAccount',
        component: 'tokenValue',
        value: '',
        initQueryState: {
          type: 'ORGANIZATION',
          id: '',
          firstName: '',
          lastName: ''
        },
        fetchDataPath: DIALOG_PATHS.ACCOUNTS,
        dialogColumns: [
          {
            field: 'id',
            headerName: 'Labels.ID',
            width: 100,
            editable: false,
            defaultValue: '',
            headerAlign: 'left',
            sortable: false
          },
          {
            field: 'name',
            headerName: 'Labels.Name',
            width: 350,
            editable: false,
            defaultValue: '',
            headerAlign: 'left',
            sortable: false
          }
        ]
      },
      {
        label: 'Labels.VAT',
        name: 'organizationPerson.organization.vatno',
        required: false,
        disabled: false,
        component: 'text'
      }
    ]
  },
  {
    groupName: 'Communication.Email',
    arrayName: 'organizationPerson.emailDtos',
    fields: [
      {
        label: 'Labels.Type',
        name: 'type.id',
        required: false,
        disabled: false,
        component: 'select',
        fieldWidth: 6
      },
      {
        label: 'Communication.Email',
        name: 'email',
        required: true,
        disabled: false,
        component: 'text',
        fieldWidth: 6
      }
    ]
  },
  {
    groupName: 'Address.Title',
    arrayName: 'organizationPerson.addressDtos',
    fields: [
      {
        label: 'Labels.Type',
        name: 'type.id',
        required: false,
        disabled: false,
        component: 'select',
        fieldWidth: 6
      },
      {
        label: 'Address.Street',
        name: 'street',
        required: false,
        disabled: false,
        component: 'text',
        fieldWidth: 6
      },
      {
        label: 'Address.Number',
        name: 'number',
        required: false,
        disabled: false,
        component: 'text',
        fieldWidth: 6
      },
      {
        label: 'Address.PostalCode',
        name: 'postalCode',
        required: false,
        disabled: false,
        component: 'text',
        fieldWidth: 6
      },
      {
        label: 'Address.State',
        name: 'state',
        required: false,
        disabled: false,
        component: 'text',
        fieldWidth: 6
      },
      {
        label: 'Address.City',
        name: 'city',
        required: false,
        disabled: false,
        component: 'text',
        fieldWidth: 6
      },
      {
        label: 'Address.POBox',
        name: 'poBox',
        required: false,
        disabled: false,
        component: 'text',
        fieldWidth: 6
      },
      {
        label: 'Address.Country',
        name: 'country',
        required: true,
        disabled: false,
        component: 'autocomplete',
        fieldWidth: 6
      },
      {
        label: 'Address.Region',
        name: 'region',
        required: false,
        disabled: false,
        component: 'autocomplete',
        fieldWidth: 6
      }
    ]
  },
  {
    groupName: 'Events.AdditionalInformation',
    fields: [
      {
        label: 'Communication.Website',
        name: 'organizationPerson.website',
        required: false,
        disabled: false,
        component: 'text'
      }
    ]
  },
  {
    groupName: 'Communication.Title',
    arrayName: 'organizationPerson.phoneDtos',
    fields: [
      {
        label: 'Labels.Type',
        name: 'type.id',
        required: false,
        disabled: false,
        component: 'select',
        fieldWidth: 6
      },
      {
        label: 'Labels.Phone',
        name: 'phone',
        required: true,
        disabled: false,
        component: 'text',
        fieldWidth: 6
      }
    ]
  }
];
