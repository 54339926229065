import _ from 'lodash';
import moment from 'moment';

import { DATE_FORMAT_IN_RANGE } from '@common/Constants';
import {
  startOfMonthToMoment,
  endOfMonthToMoment,
  getStartOfMonthFormated,
  getEndOfMonthFormated,
  momentFormatValidation
} from '@common/helpers/dates';

const formatDateForTem = (d) => {
  const date = moment(d);
  const year = date.format('YYYY');
  const month = date.format('MM');
  const day = date.format('DD');
  const hour = date.format('HH');
  const minute = date.format('mm');

  return `${year}${month}${day}${hour}${minute}`;
};

const formatDateFromTem = (temDate) => {
  const fr = _.split(temDate, '');
  const year = _.join(_.slice(fr, 0, 4), '');
  const month = _.join(_.slice(fr, 4, 6), '');
  const day = _.join(_.slice(fr, 6, 8), '');
  const hour = _.join(_.slice(fr, 8, 10), '');
  const minute = _.join(_.slice(fr, 10, 12), '');

  return moment(`${year}-${month}-${day} ${hour}:${minute}`).toDate();
};

const getStyle = ({ id }, palette) => {
  switch (id) {
    case 1:
      return palette.eventStatus.open;
    case 2:
      return palette.eventStatus.inProgress;
    case 3:
      return palette.eventStatus.completed;
    case 4:
      return palette.eventStatus.cancelled;
    default:
      return palette.primary.main;
  }
};

const isRangeChanged = (newDate, oldDate) => {
  if (moment(newDate).isValid() && moment(oldDate).isValid()) {
    const oldDateStart = startOfMonthToMoment(oldDate);
    const oldDateEnd = endOfMonthToMoment(oldDate);
    const newDateMomented = moment(newDate);
    return !newDateMomented.isBetween(oldDateStart, oldDateEnd);
  }
  return false;
};

const getStartEndDateTimeObj = (date) => {
  let startDateTime = getStartOfMonthFormated(date, DATE_FORMAT_IN_RANGE);
  let endDateTime = getEndOfMonthFormated(date, DATE_FORMAT_IN_RANGE);
  if (
    momentFormatValidation(startDateTime, DATE_FORMAT_IN_RANGE) &&
    momentFormatValidation(endDateTime, DATE_FORMAT_IN_RANGE)
  )
    return {
      startDateTime,
      endDateTime
    };

  return {};
};

export { getStyle, formatDateFromTem, formatDateForTem, isRangeChanged, getStartEndDateTimeObj };
