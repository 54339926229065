import React from 'react';

import { Paper } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledContainer = styled(Paper)(({ theme }) => ({
  margin: theme.spacing(2),
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  paddingBottom: theme.spacing(2),
  height: `calc(100vh - (${theme.sizes.tabsHeight} + ${theme.sizes.subheader} + ${theme.sizes.appHeader} + 2.5rem))`
}));

const TabContent = ({ children }) => {
  return <StyledContainer>{children}</StyledContainer>;
};

export default TabContent;
