import { useSelector } from 'react-redux';

import deLocale from 'date-fns/locale/de';
import enLocale from 'date-fns/locale/en-US';
import { get } from 'lodash';

import CalendarPickerSkeleton from '@mui/lab/CalendarPickerSkeleton';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import { INPUT_DATE_FORMAT } from '@config/inputs';
import { selectLanguage } from '@pages/Users/usersSlice';

const localeMap = {
  en: enLocale,
  de: deLocale
};

const Datepicker = ({
  field = {},
  form: { touched, errors } = {},
  required = false,
  isManualInputDisabled = false,
  isHelperTextVisible = true,
  ...rest
}) => {
  const lang = useSelector(selectLanguage);
  const isError = Boolean(get(touched, field?.name) && get(errors, field?.name));
  const errorMsg = get(touched, field?.name) && get(errors, field?.name);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={localeMap[lang]}>
      <DatePicker
        showToolbar
        inputFormat={INPUT_DATE_FORMAT}
        renderLoading={() => <CalendarPickerSkeleton />}
        renderInput={(params) => {
          return (
            <TextField
              size="small"
              onKeyDown={(e) => {
                isManualInputDisabled && e.preventDefault();
              }}
              name={field?.name}
              helperText={isHelperTextVisible && errorMsg}
              required={required}
              onBlur={field?.onBlur}
              {...params}
              InputProps={{ ...params.inputProps, ...params.InputProps }}
              error={isError}
            />
          );
        }}
        {...field}
        {...rest}
      />
    </LocalizationProvider>
  );
};

export default Datepicker;
