import React from 'react';

import Icon from '@mui/material/Icon';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  listItemButton: {
    minHeight: '7.125rem',
    '&:first-child': {
      borderTop: `1px solid ${theme.palette.divider}`
    }
  },
  listItem: {
    flexGrow: '1',
    alignSelf: 'stretch',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  leftDetails: {
    alignSelf: 'stretch',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  rightDetails: {
    alignSelf: 'stretch',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    textAlign: 'right',
    flex: '1 1 30%',
    maxWidth: '30%'
  },
  icon: {
    marginRight: '1rem',
    marginTop: '1rem',
    display: 'flex',
    alignSelf: 'flex-start',
    fontSize: '1.8rem'
  }
}));

// Icon name value from material icon font: https://fonts.google.com/icons?selected=Material+Icons,
// Icon color and other props are from MUI Icon API: https://mui.com/api/icon/
// renderIcon can be used for passing custom Icon component with custom styles
const MasterListItem = ({
  id = '',
  heading = { left: '', right: '' },
  leftDetails = { firstRow: '', secondRow: '', thirdRow: '' },
  rightDetails = { firstRow: '', secondRow: '', thirdRow: '' },
  icon = { name: '', color: 'warning' },
  selectedId = null,
  onItemSelect = null,
  renderIcon = null
}) => {
  const classes = useStyles();

  return (
    <ListItemButton
      divider
      onClick={() => onItemSelect && onItemSelect(id)}
      selected={selectedId === id}
      className={classes.listItemButton}
      data-test-id={heading?.left}
    >
      {icon.name && (
        <Icon {...icon} className={classes.icon}>
          {icon?.name}
        </Icon>
      )}

      {renderIcon && renderIcon()}

      <ListItemText
        className={classes.listItem}
        primary={heading?.left}
        primaryTypographyProps={{
          className: 'master-item-data-test',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          overflow: 'hidden'
        }}
        secondary={
          leftDetails && (
            <>
              {Object.keys(leftDetails).map((item) => (
                <Typography
                  key={item}
                  className={`${classes.leftDetails} master-item-data-test`}
                  component="span"
                  variant="body2"
                  color="text.secondary"
                >
                  {leftDetails[item]}
                </Typography>
              ))}
            </>
          )
        }
      />

      {rightDetails?.component ? (
        rightDetails?.component
      ) : (
        <ListItemText
          className={classes.rightDetails}
          primary={heading?.right}
          primaryTypographyProps={{
            className: 'master-item-data-test'
          }}
          secondary={
            rightDetails && (
              <>
                {Object.keys(rightDetails).map((item) => (
                  <Typography
                    key={item}
                    className="master-item-data-test"
                    component="span"
                    variant="body2"
                    color="text.secondary"
                  >
                    {rightDetails[item]}
                  </Typography>
                ))}
              </>
            )
          }
        />
      )}
    </ListItemButton>
  );
};

export default MasterListItem;
