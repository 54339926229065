import {
  SET_SESSIONS_LOADING,
  SET_SESSIONS_ERROR,
  LOAD_SESSIONS,
  SESSIONS_DIALOG_OPEN,
  SESSIONS_DIALOG_CLOSE,
  SET_SESSIONS_SELECTION_MODEL,
  SET_SESSIONS_DIALOG_SELECTION_MODEL,
  UPDATE_SESSIONS_SUCCESS,
  DELETE_SESSIONS_SUCCESS
} from './types';

const sessionsState = {
  data: [],
  isLoading: false,
  error: null,
  selectionModel: [],
  selectionDialogModel: [],
  isSearchDialogOpen: false
};

function sessionsReducer(state, { type, payload }) {
  switch (type) {
    case SET_SESSIONS_ERROR:
      return { ...state, error: payload, isLoading: false, data: [] };
    case SET_SESSIONS_LOADING:
      return { ...state, isLoading: true };
    case SESSIONS_DIALOG_OPEN:
      return { ...state, isSearchDialogOpen: true };
    case SESSIONS_DIALOG_CLOSE:
      return { ...state, isSearchDialogOpen: false };
    case SET_SESSIONS_SELECTION_MODEL:
      return { ...state, selectionModel: payload };
    case SET_SESSIONS_DIALOG_SELECTION_MODEL: {
      let selectionDialogModel = [];

      if (Array.isArray(payload)) {
        selectionDialogModel = [...payload];
      } else {
        const index = state.selectionModel.findIndex((s) => s === payload);
        if (index === -1) {
          selectionDialogModel = [...state.selectionModel, payload];
        } else {
          selectionDialogModel = [
            ...state.selectionDialogModel.slice(0, index),
            ...state.selectionDialogModel.slice(index + 1)
          ];
        }
      }

      return { ...state, selectionDialogModel };
    }
    case DELETE_SESSIONS_SUCCESS: {
      let updatedList = state.data.filter((item) => !payload.includes(item.session.id));
      return {
        ...state,
        isLoading: false,
        data: [...updatedList],
        selectionModel: []
      };
    }
    case UPDATE_SESSIONS_SUCCESS: {
      return {
        ...state,
        isSearchDialogOpen: false,
        isLoading: false,
        data: [...state.data, ...payload]
      };
    }
    case LOAD_SESSIONS:
      return {
        ...state,
        isLoading: false,
        data: payload?.content,
        filter: {
          ...state.filter,
          page: payload.pageable?.pageNumber,
          size: payload.pageable?.pageSize
        },
        totalPages: payload.totalPages,
        totalElements: payload.totalElements
      };

    default: {
      return state;
    }
  }
}

export { sessionsReducer, sessionsState };
