import moment from 'moment';

export const isOverlap = (events, start, end, resourceId, event) => {
  const overlapEvent = events
    .filter((e) => e.id !== event?.id)
    .find(
      (event) =>
        (moment(start).isBetween(event.start, event.end, null) ||
          moment(end).isBetween(event.start, event.end, null) ||
          moment(start).isSame(event.start) ||
          moment(end).isSame(event.end) ||
          moment(event.start).isBetween(start, end, null) ||
          moment(event.end).isBetween(start, end, null)) &&
        event?.resourceId === resourceId
    );

  return overlapEvent ? overlapEvent : false;
};

export const isBeforeNow = (start) => {
  const now = moment().toDate();

  if (moment(start).isBefore(now)) {
    return true;
  }

  return false;
};
