import Localize from 'react-intl-universal';

import * as yup from 'yup';

import { addresses, phone, email } from '@common/yup';

export default () => {
  return yup.object().shape({
    organizationPerson: yup.object().shape({
      name: yup.string().required(Localize.get('ValidationMessages.NameRequired')),
      phoneDtos: phone(),
      emailDtos: email(),
      addressDtos: addresses()
    }),
    businessPartnerType: yup
      .object()
      .shape({
        id: yup.number(),
        value: yup.string()
      })
      .nullable(true)
      .required(Localize.get('ValidationMessages.TypeRequired')),
    roles: yup.array().of(
      yup.object().shape({
        roleType: yup
          .object()
          .shape({
            id: yup.number(),
            value: yup.string()
          })
          .nullable(true)
          .required(Localize.get('ValidationMessages.RoleRequired'))
      })
    )
  });
};
