import { Navigate } from 'react-location';

import { SETTINGS_PATH } from '@common/network/ApiPaths';
import EntityTypes from '@common/network/EntityTypes';
import MeetingRooms, { CreateMeetingRoom } from '@pages/MeetingRooms';
import { getByPathAndParams } from '@services/BaseApi';

const MeetingRoomRoute = (routeCache) => ({
  path: 'meeting-rooms',
  children: [
    {
      path: '/',
      element: <MeetingRooms />,
      loader: routeCache.createLoader(
        () => ({
          config: getByPathAndParams({
            path: SETTINGS_PATH.GET,
            pathVariables: { tile: EntityTypes.MEETING_ROOM }
          })
        }),
        { policy: 'cache-first' }
      )
    },
    {
      path: 'new',
      element: <CreateMeetingRoom />
    },
    {
      element: <Navigate to="/meeting-rooms" />
    }
  ]
});

export default MeetingRoomRoute;
