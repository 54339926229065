import { useEffect, useReducer } from 'react';
import Localize from 'react-intl-universal';
import { useDispatch } from 'react-redux';

import { get } from 'lodash';

import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import { DataGrid } from '@mui/x-data-grid';

import getLocalesText from '@common/helpers/tables/getLocalesText';
import { openConfirmDialog, CONFIRM_ACTIONS } from '@components/ConfirmDialog';
import TabContainer from '@components/TabContainer';
import TabContent from '@components/TabContent/TabContent';
import TableToolbar from '@components/TableToolbar';
import ToolbarItem from '@components/TableToolbarItem';
import { attachmentTableFilters, BACKEND_FILTER_VALUES } from '@config/filterOperators';

import {
  removeAttachments,
  getAttachments,
  setSelectionModel,
  uploadAttachment,
  filterUpdate
} from './store/actions';
import { attachmentsReducer, attachmentsState, PAGE_SIZE_OPTIONS } from './store/reducer';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  toolbarContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(1),
    alignItems: 'center'
  },
  toolbar: {
    minHeight: '3rem'
  },
  tableRow: {
    '&:hover': {
      backgroundColor: `${theme.palette.grey[400]} !important`
    }
  }
}));

const AttachmentsTable = ({
  columns = [],
  entityId = null,
  entityType = null,
  toolbarOptions = [],
  setDetails = () => {}
}) => {
  const classes = useStyles();
  const reduxDispatch = useDispatch();
  const [{ isLoading, data, filter, totalElements, selectionModel }, dispatch] = useReducer(
    attachmentsReducer,
    attachmentsState
  );

  useEffect(() => {
    if (entityId && entityType) {
      getAttachments(dispatch, entityId, entityType, filter);
    }
  }, [
    entityId,
    entityType,
    filter?.size,
    filter?.page,
    filter?.searchValue,
    filter?.match,
    filter?.search
  ]);

  const onToolbarItemClick = (fieldName, files) => {
    switch (fieldName) {
      case 'add':
        if (files.length > 1) {
          alert('Batch upload currently not supported');
          return;
        }

        uploadAttachment(dispatch, reduxDispatch, entityId, entityType, files[0], setDetails);
        return;
      case 'delete': {
        if (selectionModel.length > 1) {
          alert('Batch delete currently not supported');
          return;
        }

        reduxDispatch(
          openConfirmDialog({
            title: Localize.get('ConfirmationMessages.Delete', {
              item: Localize.get('Labels.Attachment')?.toLowerCase()
            }),
            confirmButton: Localize.get('Buttons.Delete'),
            cancelButton: Localize.get('Buttons.Cancel')
          })
        )
          .unwrap()
          .then((result) => {
            if (result === CONFIRM_ACTIONS.Confirm) {
              removeAttachments(
                dispatch,
                reduxDispatch,
                entityId,
                entityType,
                selectionModel[0],
                setDetails
              );
            }
          });

        return;
      }

      default:
        return false;
    }
  };

  const isToolbarItemDisabled = (fieldName) => {
    switch (fieldName) {
      case 'add':
        return !entityId || !entityType;
      case 'delete':
        return selectionModel.length === 0;
      default:
        return false;
    }
  };

  return (
    <div className={classes.root}>
      <TabContent>
        <div className={classes.toolbarContainer}>
          <Typography variant="h6" component="h6" color="primary">
            {Localize.get('Labels.Attachments')}
          </Typography>
          <Toolbar className={classes.toolbar}>
            {toolbarOptions?.map((item, index) => (
              <ToolbarItem
                key={index}
                item={item}
                isDisabled={item.disabled || isToolbarItemDisabled(item.fieldName)}
                onToolbarItemClick={onToolbarItemClick}
              />
            ))}
          </Toolbar>
        </div>
        <TabContainer>
          <DataGrid
            loading={isLoading}
            rows={data}
            getRowClassName={() => classes.tableRow}
            columns={[
              ...columns.map((column) => ({
                ...column,
                headerName: Localize.get(column.headerName),
                renderCell: (params) => (
                  <div data-test-id={`${params.field}-${params?.row?.id ?? 'default'}`}>
                    {get(params.row, params.field)}
                  </div>
                ),
                filterOperators: attachmentTableFilters
              }))
            ]}
            localeText={getLocalesText(Localize)}
            paginationMode="server"
            hideFooterPagination
            sortingMode="server"
            filterMode="server"
            checkboxSelection
            disableColumnMenu
            page={filter?.page}
            pageSize={filter?.size}
            rowCount={totalElements}
            rowsPerPageOptions={PAGE_SIZE_OPTIONS}
            disableSelectionOnClick
            components={{ Toolbar: TableToolbar }}
            onSelectionModelChange={(values) => dispatch(setSelectionModel(values))}
            onPageChange={(value) => dispatch(filterUpdate({ type: 'page', value }))}
            onPageSizeChange={(value) => dispatch(filterUpdate({ type: 'size', value }))}
            onFilterModelChange={(values) => {
              // For now only one filter can be passed to BE
              const activeFilter = values.items[0];
              if (!activeFilter) {
                return;
              }

              dispatch(
                filterUpdate({
                  type: 'filter',
                  value: {
                    search: activeFilter.columnField,
                    searchValue: activeFilter.value,
                    match: BACKEND_FILTER_VALUES[activeFilter.operatorValue] // map with backend values
                  }
                })
              );
            }}
          />
        </TabContainer>
      </TabContent>
    </div>
  );
};

export default AttachmentsTable;
