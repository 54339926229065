import Localize from 'react-intl-universal';

import * as yup from 'yup';

export default function () {
  return yup.array().of(
    yup.object().shape({
      id: yup.number().nullable(true),
      region: yup
        .object()
        .shape({
          id: yup.number(),
          value: yup.string()
        })
        .nullable(true),
      country: yup
        .object()
        .shape({
          id: yup
            .number()
            .nullable(true)
            .required(Localize.get('ValidationMessages.CountryRequired')),
          value: yup.string()
        })
        .nullable(true)
        .required(Localize.get('ValidationMessages.CountryRequired'))
    })
  );
}
