import { useEffect, useState } from 'react';

import { LAUNCHPAD_PATHS } from '@common/network/ApiPaths';
import { getByPathAndParams } from '@services/BaseApi';

import useIsMounted from '../../hooks/isMounted/isMounted';
import { initialLanchpadState } from './initialLanchpadState';
import { formatLaunchpadData } from './util';

function Uselaunchpadservices() {
  const [launchPadLoading, setLaunchpadLoading] = useState(false);
  const [launchpadData, setLaunchpadData] = useState(initialLanchpadState);

  const setStateIfMounted = useIsMounted();

  const fetchLaunchpadData = () => {
    setLaunchpadLoading(true);
    getByPathAndParams({
      path: LAUNCHPAD_PATHS.GET
    })
      .then(({ data }) => {
        const myNewState = formatLaunchpadData(data, {
          ...launchpadData
        });
        setStateIfMounted(setLaunchpadData, myNewState);
        setLaunchpadLoading(false);
      })
      .catch((error) => error);
  };

  useEffect(() => {
    fetchLaunchpadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { launchpadData, launchPadLoading, fetchLaunchpadData };
}

export default Uselaunchpadservices;
