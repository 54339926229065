import React from 'react';
import Localize from 'react-intl-universal';

import moment from 'moment';

import { alpha } from '@mui/material';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { styled } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import { dateInitFormats } from '@common/Constants';

const SessionInformation = ({ data = null }) => {
  const timeFrom = moment(data?.timeFrom).format(dateInitFormats.time);
  const timeTo = moment(data?.timeTo).format(dateInitFormats.time);
  const minParticipants = data?.minParticipants;
  const optParticipants = data?.optParticipants;
  const maxParticipants = data?.maxParticipants;
  const type = data?.type?.value;

  const COLOR_OPACITY = 0.12;

  const StyledBox = styled(Box)(({ theme }) => ({
    border: '1px solid',
    borderColor: alpha(theme.palette.primary.main, COLOR_OPACITY),
    borderRadius: 1,
    minHeight: '30vh',
    width: '45vh'
  }));

  const StyledTypography = styled(Typography)(() => ({
    paddingLeft: '1rem',
    paddingTop: '1rem',
    color: '#808080',
    fontWeight: 'bold'
  }));

  return (
    <StyledBox>
      <StyledTypography variant="body2" color="text.primary">
        {Localize.get('Sessions.SessionInformation')}:
      </StyledTypography>
      <ListItem>
        <ListItemText
          sx={{ whiteSpace: 'nowrap' }}
          primary={
            <>
              <Typography
                sx={{ display: 'inline-block', mr: 1 }}
                component="span"
                variant="body2"
                color="text.primary"
              >
                {Localize.get('Sessions.TimeFrom')}:
              </Typography>
              <Typography
                noWrap={true}
                sx={{
                  width: `calc(100% - ${Localize.get('Sessions.TimeFrom').length * 8}px)`,
                  display: 'inline-block',
                  verticalAlign: 'middle'
                }}
                component="span"
                variant="body2"
                color="text.secondary"
              >
                {timeFrom}
              </Typography>
            </>
          }
        />
      </ListItem>
      <Divider />
      <ListItem>
        <ListItemText
          sx={{ whiteSpace: 'nowrap' }}
          primary={
            <>
              <Typography
                sx={{ display: 'inline-block', mr: 1 }}
                component="span"
                variant="body2"
                color="text.primary"
              >
                {Localize.get('Sessions.TimeTo')}:
              </Typography>
              <Typography
                noWrap={true}
                sx={{
                  width: `calc(100% - ${Localize.get('Sessions.TimeTo').length * 8}px)`,
                  display: 'inline-block',
                  verticalAlign: 'middle'
                }}
                component="span"
                variant="body2"
                color="text.secondary"
              >
                {timeTo}
              </Typography>
            </>
          }
        />
      </ListItem>
      <Divider />
      <ListItem>
        <ListItemText
          sx={{ whiteSpace: 'nowrap' }}
          primary={
            <>
              <Typography
                sx={{ display: 'inline-block', mr: 1 }}
                component="span"
                variant="body2"
                color="text.primary"
              >
                {Localize.get('Sessions.ParticipantMin')}:
              </Typography>
              <Typography
                noWrap={true}
                sx={{
                  width: `calc(100% - ${Localize.get('Sessions.ParticipantMin').length * 8}px)`,
                  display: 'inline-block',
                  verticalAlign: 'middle'
                }}
                component="span"
                variant="body2"
                color="text.secondary"
              >
                {minParticipants}
              </Typography>
            </>
          }
        />
      </ListItem>
      <Divider />
      <ListItem>
        <ListItemText
          sx={{ whiteSpace: 'nowrap' }}
          primary={
            <>
              <Typography
                sx={{ display: 'inline-block', mr: 1 }}
                component="span"
                variant="body2"
                color="text.primary"
              >
                {Localize.get('Sessions.ParticipantOpt')}:
              </Typography>
              <Typography
                noWrap={true}
                sx={{
                  width: `calc(100% - ${Localize.get('Sessions.ParticipantOpt').length * 8}px)`,
                  display: 'inline-block',
                  verticalAlign: 'middle'
                }}
                component="span"
                variant="body2"
                color="text.secondary"
              >
                {optParticipants}
              </Typography>
            </>
          }
        />
      </ListItem>
      <Divider />
      <ListItem>
        <ListItemText
          sx={{ whiteSpace: 'nowrap' }}
          primary={
            <>
              <Typography
                sx={{ display: 'inline-block', mr: 1 }}
                component="span"
                variant="body2"
                color="text.primary"
              >
                {Localize.get('Sessions.ParticipantMax')}:
              </Typography>
              <Typography
                noWrap={true}
                sx={{
                  width: `calc(100% - ${Localize.get('Sessions.ParticipantMax').length * 8}px)`,
                  display: 'inline-block',
                  verticalAlign: 'middle'
                }}
                component="span"
                variant="body2"
                color="text.secondary"
              >
                {maxParticipants}
              </Typography>
            </>
          }
        />
      </ListItem>
      <Divider />
      <ListItem>
        <ListItemText
          sx={{ whiteSpace: 'nowrap' }}
          primary={
            <>
              <Typography
                sx={{ display: 'inline-block', mr: 1 }}
                component="span"
                variant="body2"
                color="text.primary"
              >
                {Localize.get('Labels.Type')}:
              </Typography>
              <Tooltip title={type} placement="top" arrow>
                <Typography
                  noWrap={true}
                  sx={{
                    width: `calc(100% - ${Localize.get('Labels.Type').length * 8}px)`,
                    display: 'inline-block',
                    verticalAlign: 'middle'
                  }}
                  component="span"
                  variant="body2"
                  color="text.secondary"
                >
                  {type}
                </Typography>
              </Tooltip>
            </>
          }
        />
      </ListItem>
    </StyledBox>
  );
};

export default SessionInformation;
