const SORT_DATA = [
  { name: 'id', text: 'IltSession.IltId' },
  { name: 'iltSessionName', text: 'IltSession.IltSessionName' },
  { name: 'startDate', text: 'Events.StartDate' }
  // { name: 'endDate', text: 'Events.EndDate' }
];

const BOOKING_SORT_DATA = [
  { name: 'fullName', text: 'Labels.FullName' },
  { name: 'participantBookingStatus', text: 'IltSession.Status' }
];

export { SORT_DATA, BOOKING_SORT_DATA };
