export const fieldsTabs = [
  {
    fieldName: 'Attachments',
    propName: 'Attachments',
    iconName: 'attachment',
    inputType: 'text',
    options: [
      {
        fieldName: 'Delete',
        propName: 'delete',
        editable: true,
        iconName: 'delete',
        disabled: true,
        text: ''
      },
      {
        fieldName: 'Upload',
        propName: 'upload',
        editable: true,
        disabled: true,
        iconName: 'upload',
        text: ''
      },
      {
        fieldName: 'Select',
        propName: 'select',
        editable: false,
        disabled: true,
        iconName: 'drop-down-list',
        text: ''
      },
      {
        fieldName: 'Settings',
        propName: 'settings',
        editable: false,
        disabled: true,
        iconName: 'settings',
        text: ''
      },
      {
        fieldName: 'Edit',
        propName: 'edit',
        disabled: false,
        editable: false,
        iconName: '',
        text: 'Edit'
      }
    ],
    columns: [
      {
        Header: 'Name',
        accessor: 'name',
        headerTooltip: 'Name of file',
        canResize: false
      },
      {
        Header: 'Size',
        accessor: 'size',
        canResize: false
      },
      {
        Header: 'CreatedBy',
        accessor: 'createdBy',
        canResize: false
      }
    ]
  },
  {
    fieldName: 'Notes',
    propName: 'Notes',
    iconName: 'note_alt',
    inputType: 'text',
    options: [
      {
        fieldName: 'add',
        propName: 'add',
        disabled: true,
        editable: true,
        iconName: 'add',
        text: ''
      },
      {
        fieldName: 'edit',
        propName: 'edit',
        editable: true,
        disabled: true,
        iconName: 'edit',
        text: ''
      },
      {
        fieldName: 'Delete',
        propName: 'delete',
        editable: true,
        iconName: 'delete',
        disabled: true,
        text: ''
      },
      {
        fieldName: 'Select',
        propName: 'select',
        editable: false,
        disabled: true,
        iconName: 'drop-down-list',
        text: ''
      },
      {
        fieldName: 'Settings',
        propName: 'settings',
        editable: false,
        disabled: true,
        iconName: 'settings',
        text: ''
      },
      {
        fieldName: 'Edit',
        propName: 'edit',
        editable: true,
        disabled: false,
        iconName: '',
        text: 'Edit'
      }
    ],
    columns: [
      {
        Header: 'Text type',
        accessor: 'type.value',
        headerTooltip: 'Name of file'
      },
      {
        Header: 'Text',
        accessor: 'text'
      }
    ]
  }
];

export const fieldsSort = [
  {
    name: 'id',
    text: 'ResourcesTile.ResourceID'
  },
  {
    name: 'name',
    text: 'ResourcesTile.ResourceName'
  },
  {
    name: 'glAccount',
    text: 'ResourcesTile.GLAccount'
  },
  {
    name: 'price',
    text: 'ResourcesTile.Price'
  },
  {
    name: 'category',
    text: 'ResourcesTile.Category'
  }
];
