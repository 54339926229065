import Localize from 'react-intl-universal';

import * as yup from 'yup';

import { addresses, phone, email } from '@common/yup';

export default () => {
  return yup.object().shape({
    hotelRequest: yup.boolean(),
    arrivalDate: yup.date().when('hotelRequest', {
      is: true,
      then: yup
        .date()
        .nullable()
        .max(yup.ref('departureDate'), Localize.get('ValidationMessages.ArrivalDepartureRule'))
        .required(Localize.get('ValidationMessages.DateRequired'))
        .typeError(Localize.get('ValidationMessages.DateInvalid'))
    }),
    departureDate: yup.date().when('hotelRequest', {
      is: true,
      then: yup
        .date()
        .nullable()
        .min(yup.ref('arrivalDate'), Localize.get('ValidationMessages.DepartureArrivalRule'))
        .required(Localize.get('ValidationMessages.DateRequired'))
        .typeError(Localize.get('ValidationMessages.DateInvalid'))
    }),
    personDto: yup
      .object()
      .shape({
        id: yup.number().required().nullable(true),
        name: yup.string().required(Localize.get('ValidationMessages.PersonRequired'))
      })
      .nullable(true),
    status: yup.number().required(Localize.get('ValidationMessages.StatusRequired')),
    phoneDtos: phone(),
    emailDtos: email(),
    addressDtos: addresses()
  });
};
