import React from 'react';
import Localize from 'react-intl-universal';

import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  textField: {
    margin: 0,
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: `${theme.palette.primary.main} !important`
    }
  },
  input: {
    height: '32px'
  },
  icon: {
    fill: `${theme.palette.primary.main} !important`
  }
}));

const SearchContainer = styled('div', { shouldForwardProp: (prop) => prop !== 'isVisible' })(
  ({ theme, isVisible }) => ({
    display: 'flex',
    justifyContent: 'center',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(2),
    width: '100%',
    opacity: isVisible ? 1 : 0,
    pointerEvents: isVisible ? 'auto' : 'none'
  })
);

const SearchLoader = styled(CircularProgress)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(1.7),
  right: theme.spacing(16.5)
}));

const SearchField = ({
  onSearchChange = () => {},
  isDisabled = false,
  value = '',
  isLoading = false,
  isVisible = true,
  setIsVisible
}) => {
  const classes = useStyles();

  return (
    <SearchContainer data-test-id="master-search-container" isVisible={isVisible}>
      <TextField
        inputRef={(input) => input && input.focus()}
        data-test-id="master-search-input"
        className={classes.textField}
        onChange={onSearchChange}
        value={value}
        disabled={isDisabled}
        label={Localize.get('Labels.Search')}
        variant="outlined"
        margin="dense"
        size="small"
        fullWidth
        type="input"
        InputLabelProps={{
          shrink: true
        }}
        InputProps={{
          className: classes.input,
          endAdornment: value ? (
            <Tooltip title={Localize.get('Labels.Clear')}>
              <CloseIcon
                onClick={() => onSearchChange({ target: { value: '' } })}
                cursor="pointer"
                className={classes.icon}
              />
            </Tooltip>
          ) : (
            <SearchIcon className={classes.icon} />
          )
        }}
      />
      {isLoading && <SearchLoader size={20} />}

      <Tooltip title={Localize.get('Search.CloseSearch')}>
        <Button
          data-test-id="closeSearch"
          size="small"
          onClick={() => {
            onSearchChange({ target: { value: '' } });
            setIsVisible(true);
          }}
          aria-label="search"
          variant="outlined"
          sx={{ ml: 1 }}
        >
          <CancelOutlinedIcon />
        </Button>
      </Tooltip>
    </SearchContainer>
  );
};

export default SearchField;
