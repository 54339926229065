import React from 'react';
import Localize from 'react-intl-universal';
import { useMatch } from 'react-location';

import RightSidebar from '@components/RightSidebar';
import sidebar from '@pages/MeetingRooms/util/fields/sidebar';
import initialValues from '@pages/MeetingRooms/util/initialValues';
import useSidebarValidationSchema from '@pages/MeetingRooms/util/schemas/useSidebarSchema';

const Sidebar = ({
  details = null,
  isDetailsLoading = false,
  onSave = () => {},
  onDelete = () => {}
}) => {
  const sidebarSchema = useSidebarValidationSchema();
  const {
    data: {
      phoneTypes: { data: phoneTypes }
    }
  } = useMatch();

  const extendedConfig = (_, values, setValues) => ({
    ['venue']: {
      tokenValue: values?.venue?.id ? [{ title: values.venue.name }] : undefined,
      selectionModel: values?.venue?.id,
      selectItem: (venue) => {
        setValues({
          ...values,
          venue: {
            id: venue.id,
            name: venue.name,
            city: venue?.addresses[0]?.city,
            country: venue?.addresses[0]?.country
          }
        });
      },
      onIconClick: (e) => {
        if (e?.target?.name === 'delete') {
          setValues({
            ...values,
            venue: initialValues.venue
          });
        }
      }
    },
    ['phoneDtos']: {
      ['type.id']: {
        options: phoneTypes
      }
    }
  });

  return (
    <RightSidebar
      config={{ title: Localize.get('MeetingRooms.Details'), fieldsConfig: sidebar }}
      extendedConfig={extendedConfig}
      data={details}
      validationSchema={sidebarSchema}
      isDetailsLoading={isDetailsLoading}
      onSave={onSave}
      onDelete={onDelete}
    />
  );
};

export default Sidebar;
