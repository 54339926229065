import { FILTER_KEYS } from '@components/FilterDialog';

const bookingFilters = [
  {
    label: 'Labels.CompanyName',
    id: 'companyName',
    type: 'string',
    operator: { key: FILTER_KEYS.Contains, label: 'Tables.FilterValues.Contains' }
  },
  {
    label: 'Labels.Status',
    id: 'participantBookingStatus',
    type: 'select',
    operator: { key: FILTER_KEYS.Equals, label: 'Tables.FilterValues.Is' },
    options: [
      { label: 'BookingFilterTypes.Unbooked', key: 'UNBO' },
      { label: 'BookingFilterTypes.Completed', key: 'COMP' },
      { label: 'BookingFilterTypes.InProgress', key: 'INPR' }
    ]
  }
];

export default bookingFilters;
