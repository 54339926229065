import Localize from 'react-intl-universal';

import * as yup from 'yup';

import { DATE_PICKER_MIN_BIRTH_DATE } from '@common/Constants';
import { addresses, phone, email } from '@common/yup';

export default function () {
  return yup.object().shape({
    personDto: yup.object().shape({
      firstName: yup.string().required(Localize.get('ValidationMessages.FirstNameRequired')),
      lastName: yup.string().required(Localize.get('ValidationMessages.LastNameRequired')),
      name: yup.string().when(['firstName', 'lastName'], {
        is: (firstName, lastName) => !firstName && !lastName,
        then: yup.string().required(Localize.get('ValidationMessages.PersonRequired'))
      }),
      birthDate: yup
        .date()
        .nullable(true)
        .typeError(Localize.get('ValidationMessages.DateInvalid'))
        .max(new Date(), Localize.get('ValidationMessages.BirthDateInvalid'))
        .min(DATE_PICKER_MIN_BIRTH_DATE, Localize.get('ValidationMessages.BirthDateInvalid'))
    }),
    event: yup.object().shape({
      id: yup.number().required(Localize.get('ValidationMessages.EventRequired')),
      name: yup.string().required(Localize.get('ValidationMessages.EventRequired'))
    }),
    phoneDtos: phone(),
    emailDtos: email(),
    addressDtos: addresses()
  });
}
