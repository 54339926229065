import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { CAL_BAR_STATE_FORMAT } from '@common/Constants';
import { dateToFormat } from '@common/helpers/dates';
import { EVENTS_PATHS } from '@common/network/ApiPaths';
import { getByPathAndParams } from '@services/BaseApi';

const initialState = {
  list: [],
  isLoading: false,
  filters: {
    open: true,
    inProgress: true,
    completed: true,
    cancelled: true,
    fullyBooked: false,
    eventOfUtilizationGood: true,
    eventOfUtilizationMedium: true,
    eventOfUtilizationBad: true
  },
  sideBarDate: dateToFormat(new Date(), CAL_BAR_STATE_FORMAT),
  currentView: 'month'
};

export const fetchEvents = createAsyncThunk('calendar/fetchMasterList', (filter = {}) => {
  return getByPathAndParams({
    path: EVENTS_PATHS.GET,
    params: { ...filter }
  })
    .then((response) => response.data)
    .catch((error) => error);
});

export const calendarSlice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    setFilters: (state, action) => {
      state.filters = {
        ...state.filters,
        [action.payload.field]: action.payload.value
      };
    },
    setSideBarDate: (state, action) => {
      state.sideBarDate = action.payload;
    },
    setCurrentView: (state, action) => {
      state.currentView = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchEvents.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.list = payload?.content ?? [];
      })
      .addCase(fetchEvents.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchEvents.rejected, (state) => {
        state.isLoading = false;
      });
  }
});

export const selectList = (state) => state.calendar.list;
export const selectIsLoading = (state) => state.calendar.isLoading;
export const selectFilters = (state) => state.calendar.filters;
export const selectSideBarDate = (state) => state.calendar.sideBarDate;
export const selectCurrentView = (state) => state.calendar.currentView;

const { actions, reducer } = calendarSlice;

export const { setFilters, setSideBarDate, setCurrentView } = actions;

export default reducer;
