import { WORK_EMAIL_TYPE_ID } from '@common/Constants';
import { address } from '@components/FormArray';

export const customerInitialValues = {
  businessPartnerType: 1, // Individual Customer selected as default
  roles: 1,
  organizationPerson: {
    phoneDtos: [],
    emailDtos: [{ type: { id: WORK_EMAIL_TYPE_ID }, email: '' }],
    addressDtos: [address],
    website: '',
    name: 'test',
    organizationPersonInfo: 'person',
    person: {
      firstName: '',
      lastName: '',
      birthDate: null,
      birthPlace: '',
      title: { id: 0 }
    }
  },
  owner: null
};

export const accountInitialValues = {
  businessPartnerType: 2, // Account selected as default
  supplierType: 1, // Venue selected
  roles: 1,
  organizationPerson: {
    name: '',
    website: '',
    organizationPersonInfo: 'organization',
    organization: {
      vatno: '',
      parentalAccount: null
    },
    phoneDtos: [],
    addressDtos: [address]
  },
  facilities: {
    hasWellness: false,
    hasShuttleService: false,
    hasBar: false,
    hasParking: false,
    hasAirportShuttle: false,
    hasFitnessCenter: false,
    petsAllowed: false,
    hasSpa: false,
    hasFreeWifi: false,
    hasRestaurant: false,
    rating: 0,
    receptionNote: ''
  },
  owner: null,
  abcClassification: null
};
