import { copyObjectWithoutRef } from '@common/helpers/helpers';
import mapPropsToComponent from '@common/helpers/mapPropsToComponent';
import { ADDRESS_PATH, LOCALES_PATHS } from '@common/network/ApiPaths';
import BusinessPartnersTypesDropdown from '@components/BusinessPartnersTypesDropdown';
import create, { createWithoutBusinessPartner } from '@pages/Contacts/util/fields/create';
import { initialValues } from '@pages/Contacts/util/initialValues';
import dialogFieldsHotelSearch from '@pages/Participants/utils/fields/dialogFieldsHotelSearch';

export const mapPropsToAddress = (setFieldValue, arrayItemIndex, arrayItem, values) => {
  return {
    ['region']: {
      formItemChangeHandler: (e, data) => {
        setFieldValue(`addressDtos[${arrayItemIndex}].region`, data?.newValue);
      },
      path: ADDRESS_PATH.GET_REGION_BY_ID,
      pathVariables: { id: arrayItem?.country?.id },
      pathVariablesRequired: ['id'],
      disabled: !values.addressDtos[arrayItemIndex]?.country
    },
    ['country']: {
      path: LOCALES_PATHS.COUNTRIES,
      formItemChangeHandler: (e, data, name) => {
        const fieldIndex = name && name.split('.')[1];
        const addressDtos = copyObjectWithoutRef(values.addressDtos);
        addressDtos[fieldIndex].country = data.newValue;
        addressDtos[fieldIndex].region = null;
        setFieldValue('addressDtos', addressDtos);
      }
    }
  };
};

export const getFieldsConfig = (
  setFieldValue,
  phoneTypes,
  emailTypes,
  addressTypes,
  values,
  setValues,
  navigatedFromTab
) => {
  return mapPropsToComponent(navigatedFromTab ? createWithoutBusinessPartner : create, {
    ['organizationPerson.id']: {
      extendedComponents: BusinessPartnersTypesDropdown,
      dialogColumns: dialogFieldsHotelSearch,
      tokenValue: values?.organizationPerson?.id
        ? [{ title: values?.organizationPerson?.name }]
        : undefined,
      selectionModel: values?.organizationPerson?.id,
      selectItem: (value) => {
        setValues({
          ...values,
          organizationPerson: {
            id: value.id,
            name: value.name,
            organizationPersonId: value?.organizationPersonId
          }
        });
      },
      onIconClick: (e) => {
        if (e?.target?.name === 'delete') {
          setValues({
            ...values,
            organizationPerson: initialValues.organizationPerson
          });
        }
      },
      disabled: !!navigatedFromTab
    },
    ['person.birthDate']: {
      maxDate: new Date(),
      onChange: (value) => setFieldValue('person.birthDate', value || null)
    },
    ['phoneDtos']: {
      ['type.id']: {
        options: phoneTypes
      }
    },
    ['emailDtos']: {
      ['type.id']: {
        options: emailTypes
      }
    },
    ['addressDtos']: {
      ['type.id']: {
        options: addressTypes
      }
    }
  });
};

export default getFieldsConfig;
