import moment from 'moment';

import { dateInitFormats } from '@common/Constants';
import { combineDateAndTime, dateToFormat } from '@common/helpers/dates';

const getUpdatedSessionTime = (dayDate = null, startTime = '', endTime = '', iltSession) => {
  if (startTime === '' || endTime === '') {
    return {};
  }

  const splitStart = startTime.split(':');
  const splitEnd = endTime.split(':');
  const startDateTime = combineDateAndTime(
    dayDate,
    new Date(0, 0, 0, splitStart[0], splitStart[1], 0)
  );
  const endDateTime = combineDateAndTime(dayDate, new Date(0, 0, 0, splitEnd[0], splitEnd[1], 0));

  // If only one session day, update both
  if (iltSession?.dayScheduleDtos?.length === 1) {
    return {
      newStartTime: dateToFormat(startDateTime, dateInitFormats.dateTime),
      newEndTime: dateToFormat(endDateTime, dateInitFormats.dateTime)
    };
  }

  if (moment(startDateTime, dateInitFormats.basicDate).isBefore(iltSession.startDateTime)) {
    return { newStartTime: dateToFormat(startDateTime, dateInitFormats.dateTime) };
  }

  if (moment(endDateTime, dateInitFormats.basicDate).isAfter(iltSession.endDateTime)) {
    return { newEndTime: dateToFormat(endDateTime, dateInitFormats.dateTime) };
  }

  return {};
};

export default getUpdatedSessionTime;
