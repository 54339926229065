import React from 'react';

import FolderOffIcon from '@mui/icons-material/FolderOff';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const NoData = ({ titleText = 'No data', subtitleText = '' }) => (
  <>
    <Box
      sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', flex: 1, mt: 10 }}
    >
      <FolderOffIcon
        sx={{
          margin: 'auto',
          fontSize: 100,
          color: 'text.secondary'
        }}
      />

      <Typography variant="h6" align="center" marginTop="1rem" sx={{ color: 'text.primary' }}>
        {titleText}
      </Typography>

      <Typography variant="body2" align="center" sx={{ color: 'text.primary' }}>
        {subtitleText}
      </Typography>
    </Box>
  </>
);
export default NoData;
