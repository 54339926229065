import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import { styled } from '@mui/material/styles';

const FilterLabel = styled(InputLabel)(
  ({ theme }) => `
      color: ${theme.palette.info.dar};
  `
);

const FilterSelect = styled(Select)(
  ({ theme }) => `
  color: ${theme.palette.info.dark};
  & div {
    padding-top: 4px;
    padding-bottom: 4px;
  }
  & svg {
    color: ${theme.palette.info.dar};
  }
`
);

export { FilterLabel, FilterSelect };
