import React from 'react';
import Localize from 'react-intl-universal';

import moment from 'moment';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import { DATE_FORMAT } from '@config/inputs';

const SessionInformation = ({ data = null }) => {
  const description = data?.description;
  const dayDate = data?.dayScheduleDto?.dayDate;
  const startTime = data?.startTime;
  const endTime = data?.endTime;

  return (
    <Box>
      <ListItem>
        <ListItemText
          sx={{ whiteSpace: 'nowrap' }}
          primary={
            <>
              <Typography
                sx={{ display: 'inline-block', mr: 1 }}
                component="span"
                variant="body2"
                color="text.primary"
              >
                {Localize.get('IltSession.DetailsOverview')}:
              </Typography>
              <Tooltip title={description} placement="top" arrow>
                <Typography
                  noWrap={true}
                  sx={{
                    width: `calc(100% - ${
                      Localize.get('IltSession.DetailsOverview').length * 8
                    }px)`,
                    display: 'inline-block',
                    verticalAlign: 'middle'
                  }}
                  component="span"
                  variant="body2"
                  color="text.secondary"
                >
                  {description}
                </Typography>
              </Tooltip>
            </>
          }
        />
      </ListItem>
      <Divider />
      <ListItem>
        <ListItemText
          sx={{ whiteSpace: 'nowrap' }}
          primary={
            <>
              <Typography
                sx={{ display: 'inline-block', mr: 1 }}
                component="span"
                variant="body2"
                color="text.primary"
              >
                {Localize.get('IltSession.Date')}:
              </Typography>
              <Typography
                noWrap={true}
                sx={{
                  width: `calc(100% - ${Localize.get('IltSession.Date').length * 8}px)`,
                  display: 'inline-block',
                  verticalAlign: 'middle'
                }}
                component="span"
                variant="body2"
                color="text.secondary"
              >
                {moment(dayDate).format(DATE_FORMAT)}
              </Typography>
            </>
          }
        />
      </ListItem>
      <Divider />
      <ListItem>
        <ListItemText
          sx={{ whiteSpace: 'nowrap' }}
          primary={
            <>
              <Typography
                sx={{ display: 'inline-block', mr: 1 }}
                component="span"
                variant="body2"
                color="text.primary"
              >
                {Localize.get('IltSession.TimeFrom')}:
              </Typography>
              <Typography
                noWrap={true}
                sx={{
                  width: `calc(100% - ${Localize.get('IltSession.TimeFrom').length * 8}px)`,
                  display: 'inline-block',
                  verticalAlign: 'middle'
                }}
                component="span"
                variant="body2"
                color="text.secondary"
              >
                {startTime}
              </Typography>
            </>
          }
        />
      </ListItem>
      <Divider />
      <ListItem>
        <ListItemText
          sx={{ whiteSpace: 'nowrap' }}
          primary={
            <>
              <Typography
                sx={{ display: 'inline-block', mr: 1 }}
                component="span"
                variant="body2"
                color="text.primary"
              >
                {Localize.get('IltSession.TimeTo')}:
              </Typography>
              <Typography
                noWrap={true}
                sx={{
                  width: `calc(100% - ${Localize.get('IltSession.TimeTo').length * 8}px)`,
                  display: 'inline-block',
                  verticalAlign: 'middle'
                }}
                component="span"
                variant="body2"
                color="text.secondary"
              >
                {endTime}
              </Typography>
            </>
          }
        />
      </ListItem>
      <Divider />
    </Box>
  );
};

export default SessionInformation;
