import React from 'react';
import { useSelector } from 'react-redux';

import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';

import { dateToFormat } from '@common/helpers/dates';
import { selectLanguage } from '@pages/Users/usersSlice';

const useStyles = makeStyles((theme) => ({
  container: {
    marginBottom: '5px'
  },
  button: {
    padding: '1px 10px'
  },
  buttonLeft: {
    float: 'left',
    marginRight: '10px'
  },
  buttonRight: {
    float: 'right',
    marginLeft: '10px'
  },
  currentMonth: {
    display: 'inline',
    fontWeight: '600',
    color: theme.palette.primary.main
  }
}));

const CustomToolbar = ({ date, view, onNavigate, onView, localizer: { messages = {} } = {} }) => {
  const classes = useStyles();
  const lang = useSelector(selectLanguage);

  return (
    <div className={classes.container}>
      <Button
        size="small"
        variant="outlined"
        className={`${classes.button} ${classes.buttonLeft}`}
        onClick={() => onNavigate('TODAY')}
      >
        {messages.today}
      </Button>
      <ButtonGroup className={classes.buttonLeft} disableElevation size="small" variant="outlined">
        <Button className={classes.button} onClick={() => onNavigate('PREV')}>
          {'<'}
        </Button>
        <Button className={classes.button} onClick={() => onNavigate('NEXT')}>
          {'>'}
        </Button>
      </ButtonGroup>
      <Typography className={classes.currentMonth}>{`${dateToFormat(
        date,
        'MMM YYYY',
        lang
      )}`}</Typography>
      <Button
        size="small"
        variant={view === 'day' ? 'contained' : 'outlined'}
        className={`${classes.button} ${classes.buttonRight}`}
        onClick={() => onView('day')}
      >
        {messages.day}
      </Button>
      <Button
        size="small"
        variant={view === 'week' ? 'contained' : 'outlined'}
        className={`${classes.button} ${classes.buttonRight}`}
        onClick={() => onView('week')}
      >
        {messages.week}
      </Button>
      <Button
        size="small"
        variant={view === 'month' ? 'contained' : 'outlined'}
        className={`${classes.button} ${classes.buttonRight}`}
        onClick={() => onView('month')}
      >
        {messages.month}
      </Button>
    </div>
  );
};

export default CustomToolbar;
