import mapPropsToComponent from '@common/helpers/mapPropsToComponent';
import create, { meetingRoomWithoutVenue } from '@pages/MeetingRooms/util/fields/create';
import initialValues from '@pages/MeetingRooms/util/initialValues';

export const getFieldsConfig = (
  values,
  setFieldValue,
  phoneTypes,
  isNavigatedFromMeetingRoomTabInLocation
) =>
  mapPropsToComponent(isNavigatedFromMeetingRoomTabInLocation ? meetingRoomWithoutVenue : create, {
    ['venue']: {
      tokenValue: values?.venue?.name ? [{ title: `${values?.venue?.name}` }] : undefined,
      onIconClick: (e) => {
        if (e?.target?.name === 'delete') setFieldValue('venue', initialValues.venue);
      },
      selectItem: (value) =>
        setFieldValue('venue', {
          ...value
        }),
      disabled: isNavigatedFromMeetingRoomTabInLocation
    },
    ['phoneDtos']: {
      ['type.id']: {
        options: phoneTypes
      }
    }
  });
export default getFieldsConfig;
