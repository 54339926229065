import { isNil, omit } from 'lodash';
import moment from 'moment';

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import {
  ACTION_MODES,
  dateInitFormats,
  NAVIGATE_TOASTER_TIMEOUT,
  SORT_DIRECTION
} from '@common/Constants';
import { dateToFormat } from '@common/helpers/dates';
import { deepMerge } from '@common/helpers/deepMerge';
import { errorMessageFormatter, successMessageFormatter } from '@common/helpers/MessageFormatter';
import { EVENTS_PATHS, SESSION_PATHS } from '@common/network/ApiPaths';
import EntityTypes from '@common/network/EntityTypes';
import { showSnackbar, SnackbarSeverityTypes } from '@components/Snackbar/snackbarSlice';
import { MASTER_LIST_PAGE_SIZE } from '@config/network';
import {
  deleteByPath,
  getByPathAndParams,
  patchByPathAndData,
  postByPathAndData
} from '@services/BaseApi';

import { SORT_DATA } from './util/sortConfig';

export const initialState = {
  selectedId: null,
  data: [],
  filter: {
    search: '',
    sortBy: SORT_DATA[0].name,
    sortDirection: SORT_DIRECTION.DESCENDING,
    page: 0,
    size: MASTER_LIST_PAGE_SIZE
  },
  totalPages: 0,
  totalElements: 0,
  details: null,
  isLoading: true,
  isDetailsLoading: false,
  sessionDetails: {}
};

export const fetchEvents = createAsyncThunk('events/fetchAll', (filter, { rejectWithValue }) =>
  getByPathAndParams({
    path: EVENTS_PATHS.GET,
    params: filter
  })
    .then((response) => response.data)
    .catch((error) => rejectWithValue(error.response))
);

export const fetchEventDetails = createAsyncThunk(
  'events/fetchDetails',
  (selectedId, { rejectWithValue }) =>
    getByPathAndParams({
      path: EVENTS_PATHS.GET_DETAILS,
      pathVariables: { id: selectedId }
    })
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error)),
  {
    condition: (selectedId) => Boolean(selectedId),
    dispatchConditionRejection: true
  }
);

export const createEvent = createAsyncThunk(
  'events/create',
  (postData, { rejectWithValue, dispatch }) => {
    const { startDateTime, endDateTime, registrationDueDate } = postData;
    const formatted = {
      startDateTime: dateToFormat(startDateTime, dateInitFormats.dateTime),
      endDateTime: dateToFormat(endDateTime, dateInitFormats.dateTime),
      registrationDueDate: dateToFormat(registrationDueDate, dateInitFormats.dateTime)
    };

    return postByPathAndData({
      path: EVENTS_PATHS.POST,
      data: {
        ...postData,
        startDateTime: formatted.startDateTime.split('.')[0],
        endDateTime: formatted.endDateTime.split('.')[0],
        registrationDueDate: formatted.registrationDueDate
          ? formatted.registrationDueDate.split('.')[0]
          : null,
        typeId: postData.typeId.id,
        minParticipants: postData?.minParticipants || 0,
        optParticipants: postData?.optParticipants || 0,
        countryId: postData?.country?.id || null
      }
    })
      .then((response) => {
        setTimeout(() => {
          dispatch(
            showSnackbar({
              message: successMessageFormatter(EntityTypes.EVENT, ACTION_MODES.Create),
              severity: SnackbarSeverityTypes.SUCCESS
            })
          );
        }, NAVIGATE_TOASTER_TIMEOUT);
        return response.data;
      })
      .catch((error) => rejectWithValue(error.response));
  }
);

// Session
export const createSession = createAsyncThunk(
  'events/create',
  (postData, { rejectWithValue, dispatch }) =>
    postByPathAndData({
      path: SESSION_PATHS.POST,
      data: {
        ...omit(postData, 'eventName', 'eventStartDateTime', 'eventEndDateTime', 'venues'),
        endTime: moment(postData.endTime).format(dateInitFormats.time),
        startTime: moment(postData.startTime).format(dateInitFormats.time),
        timeBlockDate: moment(postData?.timeBlockDate).format(moment.HTML5_FMT.DATE),
        duration: null,
        minParticipants: postData.minParticipants !== '' ? postData.minParticipants : 0,
        optParticipants: postData.optParticipants !== '' ? postData.optParticipants : 0
      }
    })
      .then((response) => {
        setTimeout(() => {
          dispatch(
            showSnackbar({
              message: successMessageFormatter(EntityTypes.SESSION, ACTION_MODES.Create),
              severity: SnackbarSeverityTypes.SUCCESS
            })
          );
        }, NAVIGATE_TOASTER_TIMEOUT);
        return response.data;
      })
      .catch((error) => rejectWithValue(error.response))
);

export const saveSession = createAsyncThunk(
  'events/edit',
  (patchData, { rejectWithValue, getState, dispatch }) => {
    const state = getState();

    if (!Object.keys(patchData).includes('timeBlockDate')) {
      delete patchData.dayScheduleId;
    }
    const data = Object.keys(patchData).reduce((objValue, currentParameter) => {
      switch (currentParameter) {
        case 'type':
          objValue.typeId = patchData?.type?.id;
          break;
        case 'timeTo':
          objValue.endTime = patchData.timeTo
            ? moment(patchData.timeTo).format(dateInitFormats.time)
            : '';
          break;
        case 'timeFrom':
          objValue.startTime = patchData.timeFrom
            ? moment(patchData.timeFrom).format(dateInitFormats.time)
            : '';
          break;
        case 'timeBlockDate':
          objValue.timeBlockDate = patchData?.timeBlockDate
            ? moment(patchData?.timeBlockDate).format(moment.HTML5_FMT.DATE)
            : '';
          break;
        case 'minParticipants':
          objValue.minParticipants =
            patchData.minParticipants !== '' ? patchData.minParticipants : 0;
          break;
        case 'optParticipants':
          objValue.optParticipants =
            patchData.optParticipants !== '' ? patchData.optParticipants : 0;
          break;
        default:
          objValue[currentParameter] = patchData[currentParameter];
          break;
      }

      return objValue;
    }, {});

    return patchByPathAndData({
      path: SESSION_PATHS.PATCH,
      pathVariables: { id: state?.events?.sessionDetails?.id },
      data: data
    })
      .then((response) => {
        setTimeout(() => {
          dispatch(
            showSnackbar({
              message: successMessageFormatter(EntityTypes.SESSION, ACTION_MODES.Edit),
              severity: SnackbarSeverityTypes.SUCCESS
            })
          );
        }, NAVIGATE_TOASTER_TIMEOUT);
        return response?.data;
      })
      .catch((error) => rejectWithValue(error?.response));
  }
);

export const deleteEvent = createAsyncThunk('events/delete', (id, { dispatch, rejectWithValue }) =>
  deleteByPath({
    path: EVENTS_PATHS.DELETE,
    pathVariables: { id }
  })
    .then((response) => {
      dispatch(
        showSnackbar({
          message: successMessageFormatter(EntityTypes.EVENT, ACTION_MODES.Delete),
          severity: SnackbarSeverityTypes.SUCCESS
        })
      );
      return response.data;
    })
    .catch((error) => {
      dispatch(
        showSnackbar({
          message: errorMessageFormatter(error, EntityTypes.EVENT, ACTION_MODES.Delete),
          severity: SnackbarSeverityTypes.ERROR
        })
      );

      return rejectWithValue(error.response);
    })
);

export const saveEvent = createAsyncThunk(
  'events/save',
  (params, { dispatch, rejectWithValue }) => {
    let postData = { ...params.postData };
    const { id } = params;

    if (postData.startDateTime) {
      postData.startDateTime = dateToFormat(postData.startDateTime, dateInitFormats.dateTime);
    }

    if (postData.endDateTime) {
      postData.endDateTime = dateToFormat(postData.endDateTime, dateInitFormats.dateTime);
    }

    if (postData.registrationDueDate) {
      postData.registrationDueDate = dateToFormat(
        postData.registrationDueDate,
        dateInitFormats.dateTime
      );
    }

    if (postData.status) {
      postData['statusId'] = postData.status.id;
      delete postData['status'];
    }

    if (postData.type) {
      postData['typeId'] = postData.type.id;
      delete postData['type'];
    }

    if (postData.nativeLanguage) {
      postData['nativeLanguageId'] = postData.nativeLanguage.id;
      delete postData['nativeLanguage'];
    }

    if (postData.venues?.length) {
      postData.venues = postData?.venues?.length ? postData.venues.map(({ id }) => ({ id })) : [];
      postData.countryId = null;
      postData.city = null;
      delete postData['country'];
    } else if (postData.country === '' || postData.country === null) {
      postData.countryId = null;
      delete postData['country'];
    }

    if (postData?.country?.id) {
      postData.countryId = postData?.country?.id;
      delete postData['country'];
    }

    if (postData.optParticipants === '') {
      postData.optParticipants = 0;
    }

    if (postData.minParticipants === '') {
      postData.minParticipants = 0;
    }

    return patchByPathAndData({
      path: EVENTS_PATHS.PATCH,
      data: postData,
      pathVariables: { id }
    })
      .then((response) => {
        dispatch(
          showSnackbar({
            message: successMessageFormatter(EntityTypes.EVENT, ACTION_MODES.Edit),
            severity: SnackbarSeverityTypes.SUCCESS
          })
        );
        return response.data;
      })
      .catch((error) => rejectWithValue(error.response));
  }
);

export const eventsSlice = createSlice({
  name: 'events',
  initialState,
  reducers: {
    resetState: () => initialState,
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setSelectedId: (state, action) => {
      state.selectedId = action.payload;
    },
    setDetails: (state, { payload }) => {
      state.details = deepMerge(state.details, payload);
    },
    setSessionDetails: (state, { payload }) => {
      state.sessionDetails = deepMerge(state.sessionDetails, payload);

      // Need to get time data in order to convert it to date object since time component expects it.
      const startTime = state.sessionDetails && state.sessionDetails?.startTime.split(':');
      const endTime = state.sessionDetails && state.sessionDetails?.endTime.split(':');

      state.sessionDetails = {
        ...state.sessionDetails,
        timeFrom: new Date(0, 0, 0, startTime[0], startTime[1], 0),
        timeTo: new Date(0, 0, 0, endTime[0], endTime[1], 0)
      };
    },
    deleteSuccess: (state, action) => {
      state.data = [...state.data.filter((item) => item.id !== action.payload.id)];
      state.selectedId = [...state.data.filter((item) => item.id !== action.payload.id)];
    },
    setFilterParams: (state, action) => {
      let newFilterValues = {};

      // Case when search value is reset to empty and search bar is closed
      if (action.payload.key === 'search' && !action.payload.value && !state.filter.search) {
        state.isLoading = false;
        return;
      }

      if (Array.isArray(action.payload)) {
        newFilterValues = action.payload.reduce(
          (obj, item) => ((obj[item.key] = item.value), obj),
          {}
        );
      } else {
        newFilterValues = { [action.payload.key]: action.payload.value };
      }

      state.filter = { ...state.filter, ...newFilterValues, page: action.payload.page ?? 0 };
      state.selectedId = null;
    }
  },
  extraReducers: (builder) => {
    builder
      // Get all
      .addCase(fetchEvents.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.data = payload?.content || state.data;
        state.totalPages = payload?.totalPages || state.totalPages;
        state.totalElements = isNil(payload?.totalElements)
          ? state.totalElements
          : payload?.totalElements;
        state.selectedId = state.selectedId ? state.selectedId : payload?.content?.[0]?.id;
        state.filter.page = payload?.pageable?.pageNumber;
        state.filter.size = payload?.pageable?.pageSize;
      })
      .addCase(fetchEvents.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchEvents.rejected, (state) => {
        state.data = [];
        state.isLoading = false;
      })
      // Get Details
      .addCase(fetchEventDetails.fulfilled, (state, { payload }) => {
        // TODO: Mocked
        state.details = payload
          ? {
              ...payload,
              currentParticipants: 0,
              orderNumberInGroup: payload.orderNumberInGroup ?? '',
              registrationDueDate: payload.registrationDueDate ?? '',
              city: payload.city ?? '',
              participantsCount: [
                payload.totalToBeInvited,
                payload.totalInvited,
                payload.totalConfirmed,
                payload.totalCanceled,
                payload.totalCompleted,
                payload.totalRegistered,
                payload.totalReserved
              ]
            }
          : null;
        state.isDetailsLoading = false;
      })
      .addCase(fetchEventDetails.pending, (state) => {
        state.isDetailsLoading = true;
      })
      .addCase(fetchEventDetails.rejected, (state) => {
        state.details = null;
        state.isDetailsLoading = false;
      })
      // Create
      .addCase(createEvent.fulfilled, (state) => {
        state.filter.page = 0;
      })
      // Update pending
      .addCase(saveEvent.pending, (state, action) => {
        const { postData } = action.meta.arg;

        state.isDetailsLoading = true;

        // Must convert moment object
        if (postData.startDateTime) {
          postData.startDateTime = dateToFormat(postData.startDateTime, dateInitFormats.dateTime);
        }

        if (postData.endDateTime) {
          postData.endDateTime = dateToFormat(postData.endDateTime, dateInitFormats.dateTime);
        }

        if (postData.registrationDueDate) {
          postData.registrationDueDate = dateToFormat(
            postData.registrationDueDate,
            dateInitFormats.dateTime
          );
        }
      })
      //Update failed
      .addCase(saveEvent.rejected, (state) => {
        state.isDetailsLoading = false;
      })
      // Update success
      .addCase(saveEvent.fulfilled, (state, action) => {
        state.isDetailsLoading = false;
        state.data = state.data.map((item) =>
          item.id === action.meta.arg.id ? deepMerge(item, action.meta.arg.postData) : item
        );
        state.details = deepMerge(state.details, action.meta.arg.postData);
      })
      // Delete success
      .addCase(deleteEvent.fulfilled, (state, action) => {
        state.totalElements -= 1;
        state.isLoading = false;
        state.details = null;
        state.data = state.data.filter((item) => item.id !== action.meta.arg);
        state.selectedId = state.data.filter((item) => item.id !== action.meta.arg)[0]?.id;

        if (state.data.length === 0 && state.totalPages === 1) {
          state.filter.page = 0;
          state.totalPages = 0;
          state.totalElements = 0;
        } else if (state.data.length === 0 && state.totalPages > 1) {
          state.filter.page -= 1;
        }
      })
      .addCase(saveSession.fulfilled, (state, action) => {
        state.isDetailsLoading = false;
        state.sessionDetails = deepMerge(state.sessionDetails, action.meta.arg);
        if (action.meta.arg.timeTo) {
          state.sessionDetails.timeTo = action.meta.arg.timeTo;
        }
        if (action.meta.arg.timeFrom) {
          state.sessionDetails.timeFrom = action.meta.arg.timeFrom;
        }
        if (action.meta.arg.timeBlockDate) {
          state.sessionDetails.timeBlockDate = action.meta.arg.timeBlockDate;
          state.sessionDetails.dayScheduleId = '';
        }
      })
      .addCase(saveSession.rejected, (state) => {
        state.isDetailsLoading = false;
      })
      .addCase(saveSession.pending, (state) => {
        state.isDetailsLoading = true;
      });
  }
});

export const selectId = (state) => state.events.selectedId;
export const selectList = (state) => state.events.data;
export const selectFilter = (state) => state.events.filter;
export const selectTotalElements = (state) => state.events.totalElements;
export const selectTotalPages = (state) => state.events.totalPages;
export const selectDetails = (state) => state.events.details;
export const selectSessionDetails = (state) => state.events.sessionDetails;

export const selectIsLoading = (state) => state.events.isLoading;
export const selectIsDetailsLoading = (state) => state.events.isDetailsLoading;

const { actions, reducer } = eventsSlice;

export const {
  setError,
  setLoading,
  setSelectedId,
  setConfirmationDialog,
  deleteSuccess,
  setFilterParams,
  resetPage,
  resetState,
  setDetails,
  setSessionDetails
} = actions;

export default reducer;
