import { useEffect, useReducer, useState } from 'react';
import Localize from 'react-intl-universal';
import { useDispatch } from 'react-redux';

import { get } from 'lodash';

import Icon from '@mui/material/Icon';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { styled } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import { DataGrid } from '@mui/x-data-grid';

import { isJsonString } from '@common/helpers/string';
import getLocalesText from '@common/helpers/tables/getLocalesText';
import { CONFIRM_ACTIONS, openConfirmDialog } from '@components/ConfirmDialog';
import noteTypeConfig from '@components/NotesTable/noteTypesConfig';
import { showSnackbar, SnackbarSeverityTypes } from '@components/Snackbar/snackbarSlice';
import TabContainer from '@components/TabContainer';
import TabContent from '@components/TabContent/TabContent';
import TableToolbar from '@components/TableToolbar';
import ToolbarItem from '@components/TableToolbarItem';

import NotesDialog from './components/NoteDialog/NoteDialog';
import {
  getNotes,
  closeNotesDialog,
  openNotesDialog,
  createNote,
  removeNote,
  setSelectionModel,
  updateNote,
  setAnchorEl,
  setNotesType
} from './store/actions';
import { notesReducer, notesState } from './store/reducer';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  toolbarContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(1),
    alignItems: 'center'
  },
  toolbar: {
    minHeight: '3rem'
  },
  container: {
    height: 400,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  tableRow: {
    '&:hover': {
      backgroundColor: `${theme.palette.grey[400]} !important`,
      cursor: 'pointer'
    }
  }
}));

const StyledMenu = styled(Menu)(() => ({
  '& .MuiMenu-paper': {
    overflow: 'visible',
    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
    marginTop: '0.6rem'
  }
}));

const readStyledText = (params) => {
  let text = get(params.row, params.field);
  if (isJsonString(text)) {
    return get(JSON.parse(text), 'blocks[0].text');
  } else {
    return text;
  }
};

const NotesTable = ({
  columns = [],
  entityId = null,
  entityType = null,
  toolbarOptions = [],
  setDetails = () => {}
}) => {
  const classes = useStyles();
  const reduxDispatch = useDispatch();

  const [
    {
      isLoading,
      data,
      selectionModel,
      isNotesDialogOpen,
      selectedNote,
      error,
      anchorEl,
      notesType
    },
    dispatch
  ] = useReducer(notesReducer, notesState);

  const [open, setOpen] = useState(Boolean(anchorEl));

  const handleClose = () => {
    dispatch(setAnchorEl(null));
    setOpen(false);
  };

  const dataTypeIdArr = data.map((elem) => elem.type.id);

  useEffect(() => {
    if (entityId && entityType) {
      getNotes(dispatch, entityId, entityType);
    }
  }, [entityId, entityType]);

  useEffect(() => {
    if (error) {
      const message = error.message;
      reduxDispatch(
        showSnackbar({
          message: message,
          severity: SnackbarSeverityTypes.ERROR
        })
      );
    }
  }, [error]);

  const onToolbarItemClick = (fieldName, event) => {
    switch (fieldName) {
      case 'add_note':
        dispatch(setAnchorEl(event.currentTarget));
        setOpen(true);
        // dispatch(openNotesDialog());
        return;
      case 'delete': {
        if (selectionModel.length > 1) {
          alert('Batch delete currently not supported');
          return;
        }

        reduxDispatch(
          openConfirmDialog({
            title: Localize.get('ConfirmationMessages.Delete', {
              item: Localize.get('Labels.Note')?.toLowerCase()
            }),
            confirmButton: Localize.get('Buttons.Delete'),
            cancelButton: Localize.get('Buttons.Cancel')
          })
        )
          .unwrap()
          .then((result) => {
            if (result === CONFIRM_ACTIONS.Confirm) {
              removeNote(
                dispatch,
                reduxDispatch,
                entityId,
                entityType,
                selectionModel[0],
                setDetails
              );
            }
          });
        return;
      }

      default:
        return false;
    }
  };

  const onMenuClick = (item) => {
    setOpen(false);
    dispatch(setNotesType(item));
    dispatch(openNotesDialog());
  };

  const isToolbarItemDisabled = (fieldName) => {
    switch (fieldName) {
      case 'add_note':
        return !entityId || !entityType;
      case 'delete':
        return selectionModel.length === 0;
      default:
        return false;
    }
  };

  const onNotesDialogClose = (value, id) => {
    if (!value) {
      dispatch(closeNotesDialog());
      return;
    }

    id
      ? updateNote(
          dispatch,
          {
            entityId,
            noteId: id,
            entityType,
            text: value,
            createdBy: 'Demo User',
            type: selectedNote.type.value
          },
          reduxDispatch
        )
      : createNote(
          dispatch,
          reduxDispatch,
          {
            entityId,
            entityType,
            text: value,
            createdBy: 'Demo User',
            type: notesType
          },
          setDetails
        );
  };

  return (
    <div className={classes.root}>
      <TabContent>
        <div className={classes.toolbarContainer}>
          <Typography variant="h6" component="h2" color="primary">
            {Localize.get('Labels.Notes')}
          </Typography>
          <Toolbar className={classes.toolbar}>
            {toolbarOptions.map((item, index) => (
              <ToolbarItem
                id="basic-menu"
                key={index}
                item={item}
                isDisabled={item.disabled || isToolbarItemDisabled(item.fieldName)}
                onToolbarItemClick={onToolbarItemClick}
              />
            ))}
          </Toolbar>
        </div>
        <TabContainer>
          <DataGrid
            loading={isLoading}
            rows={data}
            getRowClassName={() => classes.tableRow}
            columns={[
              ...columns.map((column) => ({
                ...column,
                headerName: Localize.get(column.headerName),
                renderCell: (params) => (
                  <div data-test-id={`${params.field}-${params?.row?.id ?? 'default'}`}>
                    {params.field === 'text'
                      ? readStyledText(params)
                      : get(params.row, params.field)}
                  </div>
                )
              }))
            ]}
            disableColumnMenu
            hideFooterPagination
            checkboxSelection
            disableSelectionOnClick
            components={{
              Toolbar: () => <TableToolbar isFilterVisible={false} />
            }}
            onSelectionModelChange={(values) => dispatch(setSelectionModel(values))}
            onRowClick={({ row }) => dispatch(openNotesDialog(row))}
            localeText={getLocalesText(Localize)}
          />

          <StyledMenu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            className={classes.menuBox}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button'
            }}
            PaperProps={{
              elevation: 0,
              sx: {
                '&:before': {
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  top: 0,
                  left: 25,
                  width: 10,
                  height: 10,
                  bgcolor: 'background.paper',
                  transform: 'translateY(-50%) rotate(45deg)',
                  zIndex: 0
                }
              }
            }}
          >
            {noteTypeConfig.map((el) => (
              <MenuItem
                key={el.type}
                onClick={() => onMenuClick(el?.type)}
                disabled={dataTypeIdArr.includes(el?.id)}
              >
                <ListItemIcon>
                  <Icon fontSize="small">{el.icon}</Icon>
                </ListItemIcon>
                <ListItemText> {Localize.get(`Notes.${el?.type}`)}</ListItemText>
              </MenuItem>
            ))}
          </StyledMenu>
        </TabContainer>
      </TabContent>

      <NotesDialog
        note={selectedNote}
        open={isNotesDialogOpen}
        onClose={onNotesDialogClose}
        type={notesType}
      />
    </div>
  );
};

export default NotesTable;
