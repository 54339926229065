import Localize from 'react-intl-universal';

import * as yup from 'yup';

import { PHONE_REGEXP } from '@config/inputs';

export default function () {
  return yup.array().of(
    yup.object().shape({
      phone: yup.lazy((value) => {
        return value === ''
          ? yup.string()
          : yup
              .string()
              .matches(PHONE_REGEXP, Localize.get('ValidationMessages.PhoneNumberInvalid'))
              .nullable(true)
              .required(Localize.get('ValidationMessages.PhoneRequired'));
      })
    })
  );
}
