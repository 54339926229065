import { ACTION_MODES, NAVIGATE_TOASTER_TIMEOUT, TAB_KEYS } from '@common/Constants';
import formatBytes from '@common/formatters/formatBytes';
import { errorMessageFormatter, successMessageFormatter } from '@common/helpers/MessageFormatter';
import { TAB_PATHS } from '@common/network/ApiPaths';
import EntityTypes from '@common/network/EntityTypes';
import { closeConfirmDialog } from '@components/ConfirmDialog/confirmDialogSlice';
import { showSnackbar, SnackbarSeverityTypes } from '@components/Snackbar/snackbarSlice';
import { getByPathAndParams, deleteByPath, postByPathAndData } from '@services/BaseApi';

import {
  ATTACHMENT_FILTER_UPDATE,
  DELETE_ATTACHMENT_SUCCESS,
  LOAD_ATTACHMENTS,
  SET_ATTACHMENTS_ERROR,
  SET_LOADING,
  SET_SELECTION_MODEL,
  UPLOAD_ATTACHMENT_SUCCESS
} from './types';

export async function getAttachments(
  dispatch,
  entityId,
  entityType,
  { page, size, match, search, searchValue }
) {
  if (!entityId) {
    return;
  }

  dispatch(setLoading(true));

  try {
    const { data } = await getByPathAndParams({
      path: TAB_PATHS.ATTACHMENTS.GET,
      pathVariables: { entityId, entityType },
      params: {
        page,
        size,
        match,
        search,
        searchValue
      }
    });

    const formatted = data.content.map((item) => {
      if (item.size) {
        item.size = formatBytes(item.size);
        return item;
      }
      return item;
    });

    dispatch(loadAttachments({ ...data, content: formatted }));
  } catch (error) {
    dispatch(setAttachmentsError(error.message));
  }
}

export async function removeAttachments(
  dispatch,
  reduxDispatch,
  entityId,
  entityType,
  id,
  setDetails
) {
  if (!entityId) {
    return;
  }

  dispatch(setLoading(true));

  try {
    const {
      data: { count }
    } = await deleteByPath({
      path: TAB_PATHS.ATTACHMENTS.DELETE,
      pathVariables: { entityId, entityType, id }
    });

    reduxDispatch(closeConfirmDialog());
    reduxDispatch(setDetails({ counts: { [TAB_KEYS.ATTACHMENT]: count } }));
    dispatch(removeComplete(id));

    reduxDispatch(
      showSnackbar({
        message: successMessageFormatter(EntityTypes.ATTACHMENTS, ACTION_MODES.Delete),
        severity: SnackbarSeverityTypes.SUCCESS
      })
    );
  } catch (error) {
    reduxDispatch(
      showSnackbar({
        message: errorMessageFormatter(error, EntityTypes.ATTACHMENTS, ACTION_MODES.Delete),
        severity: SnackbarSeverityTypes.ERROR
      })
    );

    dispatch(setAttachmentsError(error.message));
  }
}

export async function uploadAttachment(
  dispatch,
  reduxDispatch,
  entityId,
  entityType,
  file,
  setDetails
) {
  if (!entityId) {
    return;
  }

  const formData = new FormData();
  formData.append('objectId', entityId);
  formData.append('objectName', entityType);
  formData.append('file', file);
  formData.append('createdBy', 'Demo User');

  dispatch(setLoading(true));

  try {
    const {
      data: { content, count }
    } = await postByPathAndData({
      path: TAB_PATHS.ATTACHMENTS.POST,
      data: formData
    });

    if (content) {
      dispatch(uploadComplete({ ...content, size: formatBytes(content.size) }));
      reduxDispatch(setDetails({ counts: { [TAB_KEYS.ATTACHMENT]: count } }));
      setTimeout(() => {
        reduxDispatch(
          showSnackbar({
            message: successMessageFormatter(EntityTypes.ATTACHMENTS, ACTION_MODES.Upload),
            severity: SnackbarSeverityTypes.SUCCESS
          })
        );
      }, NAVIGATE_TOASTER_TIMEOUT);
    }
  } catch (error) {
    dispatch(setAttachmentsError(error.message));
  }
}

export function setLoading(payload) {
  return {
    type: SET_LOADING,
    isLoading: payload
  };
}

export function setAttachmentsError(payload) {
  return {
    type: SET_ATTACHMENTS_ERROR,
    error: payload
  };
}

export function loadAttachments(payload) {
  return {
    type: LOAD_ATTACHMENTS,
    payload
  };
}

export function setSelectionModel(payload) {
  return {
    type: SET_SELECTION_MODEL,
    payload
  };
}

export function removeComplete(payload) {
  return {
    type: DELETE_ATTACHMENT_SUCCESS,
    payload
  };
}

export function uploadComplete(payload) {
  return {
    type: UPLOAD_ATTACHMENT_SUCCESS,
    payload
  };
}

export function filterUpdate(payload) {
  return {
    type: ATTACHMENT_FILTER_UPDATE,
    payload
  };
}
