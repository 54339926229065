import React, { memo } from 'react';
import Localize from 'react-intl-universal';

import { CircularProgress } from '@mui/material';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

const StyledNoData = styled(Typography)(({ theme }) => ({
  fontFamily: theme.typography.fontFamily,
  textAlign: 'center'
}));

const StyledContainer = styled('div')(({ theme }) => ({
  overflow: 'auto',
  maxHeight: `calc(100vh - ${theme.sizes.appHeader} - ${theme.sizes.subheader} - ${theme.sizes.masterListToolbar}  - ${theme.sizes.masterListFooter})`,
  minHeight: `calc(100vh - ${theme.sizes.appHeader} - ${theme.sizes.subheader} - ${theme.sizes.masterListToolbar}  - ${theme.sizes.masterListFooter})`
}));

const MasterList = memo(
  ({
    isLoading = false,
    renderMasterItem,
    data = [],
    noDataMessage = Localize.get('NoDataMessage.List')
  }) => (
    <StyledContainer>
      {isLoading ? (
        <CircularProgress size="2rem" sx={{ mt: '70%', ml: '50%' }} disableShrink />
      ) : data?.length ? (
        <List data-test-id="master-list-test">
          {data?.map((item) => renderMasterItem && renderMasterItem(item))}
        </List>
      ) : (
        <Box sx={{ mt: '70%' }}>
          <StyledNoData>{noDataMessage}</StyledNoData>
        </Box>
      )}
    </StyledContainer>
  )
);

MasterList.displayName = 'MasterList';

export default MasterList;
