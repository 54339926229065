import Localize from 'react-intl-universal';

import * as yup from 'yup';

export default function () {
  return yup.object().shape({
    name: yup.string().required(Localize.get('ValidationMessages.NameRequired')),
    resourceCategory: yup
      .object()
      .shape({
        id: yup
          .number()
          .required(Localize.get('ValidationMessages.CategoryRequired'))
          .nullable(true),
        value: yup.string()
      })
      .nullable(true)
      .required(Localize.get('ValidationMessages.CategoryRequired')),
    glAccount: yup
      .object()
      .shape({
        id: yup.number().nullable(true),
        value: yup.string()
      })
      .nullable(true),
    irrAmount: yup.bool(),
    availableAmount: yup
      .number()
      .integer(Localize.get('ValidationMessages.IntNumberRule'))
      .nullable(true),
    consumableItem: yup.bool(),
    giftWarehouse: yup
      .object()
      .shape({
        id: yup.number().nullable(true),
        value: yup.string()
      })
      .nullable(true),
    billableItem: yup.bool(),
    price: yup
      .number()
      .nullable(true)
      .when(['billableItem'], {
        is: (billableItem) => billableItem,
        then: yup
          .number()
          .nullable(true)
          .required(Localize.get('ValidationMessages.PriceMin'))
          .min(0.1, Localize.get('ValidationMessages.PriceMin'))
      }),
    currency: yup
      .object()
      .shape({
        id: yup.number().nullable(true),
        value: yup.string()
      })
      .nullable(true),
    costCentre: yup.string().nullable(true)
  });
}
