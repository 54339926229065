import { DIALOG_PATHS } from '@common/network/ApiPaths';
import IndividualTypes from '@common/network/IndividualTypes';

export default [
  {
    groupName: 'IltSession.IltSessionInformation',
    fields: [
      {
        label: 'Hotel.ArrivalDate',
        name: 'arrivalDate',
        required: false,
        disabled: false,
        column: 4,
        component: 'datepicker'
      },
      {
        label: 'Hotel.DepartureDate',
        name: 'departureDate',
        required: false,
        disabled: false,
        component: 'datepicker',
        column: 4
      },
      {
        label: 'IltSession.HotelRequest',
        name: 'hotelRequest',
        required: false,
        disabled: false,
        component: 'switch',
        column: 4,
        alignItems: 'center'
      }
    ]
  },
  {
    groupName: 'Events.Information',
    fields: [
      {
        label: 'ParticipantsTile.Person',
        name: 'personDto.name',
        required: true,
        disabled: false,
        dialogTitle: 'Labels.SearchPerson',
        component: 'tokenValue',
        fetchDataPath: DIALOG_PATHS.PERSONS,
        value: '',
        initQueryState: {
          type: IndividualTypes.CONTACT,
          firstName: '',
          lastName: '',
          companyName: '',
          personTypeId: ''
        },
        dialogColumns: [
          {
            field: 'personTypeId',
            headerName: 'Labels.ID',
            width: 100,
            editable: false,
            defaultValue: '',
            headerAlign: 'left',
            sortable: false
          },
          {
            field: 'firstName',
            headerName: 'Labels.FirstName',
            width: 350,
            editable: false,
            defaultValue: '',
            headerAlign: 'left',
            sortable: false
          },
          {
            field: 'lastName',
            headerName: 'Labels.LastName',
            width: 350,
            editable: false,
            defaultValue: '',
            headerAlign: 'left',
            sortable: false
          },
          {
            field: 'companyName',
            headerName: 'Labels.CompanyName',
            width: 350,
            editable: false,
            defaultValue: '',
            headerAlign: 'left',
            sortable: false
          }
        ]
      },
      {
        label: 'Labels.CompanyName',
        name: 'company',
        required: false,
        disabled: false,
        dialogTitle: 'Labels.SearchCompany',
        component: 'tokenValue',
        fetchDataPath: DIALOG_PATHS.ACCOUNTS,
        create: {},
        value: '',
        initQueryState: {
          type: IndividualTypes.ORGANIZATION,
          name: '',
          id: ''
        },
        dialogColumns: [
          {
            field: 'id',
            headerName: 'Labels.ID',
            width: 100,
            editable: false,
            defaultValue: '',
            headerAlign: 'left',
            sortable: false
          },
          {
            field: 'name',
            headerName: 'Labels.CompanyName',
            width: 350,
            editable: false,
            defaultValue: '',
            headerAlign: 'left',
            sortable: false
          }
        ]
      },
      {
        label: 'Labels.CompanyInvoice',
        name: 'invoiceCompany',
        required: false,
        disabled: false,
        dialogTitle: 'Labels.SearchCompany',
        component: 'tokenValue',
        fetchDataPath: DIALOG_PATHS.ACCOUNTS,
        value: '',
        initQueryState: {
          type: IndividualTypes.ORGANIZATION,
          companyName: '',
          id: ''
        },
        dialogColumns: [
          {
            field: 'id',
            headerName: 'Labels.ID',
            width: 100,
            editable: false,
            defaultValue: '',
            headerAlign: 'left',
            sortable: false
          },
          {
            field: 'name',
            headerName: 'Labels.CompanyName',
            width: 350,
            editable: false,
            defaultValue: '',
            headerAlign: 'left',
            sortable: false
          }
        ]
      },
      {
        label: 'Labels.Status',
        name: 'status',
        required: true,
        disabled: false,
        component: 'select'
      },
      {
        label: 'Events.Remarks',
        name: 'remarks',
        required: false,
        disabled: false,
        component: 'text'
      },
      {
        label: 'Event.CancellationReason',
        name: 'cancellationReason',
        required: false,
        disabled: false,
        component: 'autocomplete'
      },
      {
        label: 'ParticipantsTile.JustResult',
        name: 'result',
        required: false,
        disabled: false,
        component: 'autocomplete'
      }
    ]
  },
  {
    groupName: 'Personal.Title',
    fields: [
      {
        label: 'Personal.BirthDate',
        name: 'personDto.birthDate',
        required: false,
        disabled: true,
        component: 'datepicker'
      },
      {
        label: 'Personal.BirthPlace',
        name: 'personDto.birthPlace',
        required: false,
        disabled: true,
        component: 'text'
      },
      {
        label: 'Personal.Gender',
        name: 'personDto.gender',
        required: false,
        disabled: true,
        component: 'autocomplete'
      },
      {
        label: 'Personal.Language',
        name: 'nativeLanguage',
        required: false,
        disabled: false,
        component: 'autocomplete'
      },
      {
        label: 'Personal.Allergies',
        name: 'allergies',
        required: false,
        disabled: false,
        component: 'comboBox'
      },
      {
        label: 'Personal.Vegetarian',
        name: 'isVegetarian',
        required: false,
        disabled: false,
        component: 'switch',
        column: 2,
        alignItems: 'center'
      },
      {
        label: 'Personal.Handicapped',
        name: 'isHandicap',
        required: false,
        disabled: false,
        component: 'switch',
        column: 2,
        alignItems: 'center'
      },
      {
        label: 'Personal.Vegan',
        name: 'isVegan',
        required: false,
        disabled: false,
        component: 'switch',
        column: 2,
        alignItems: 'center'
      },

      {
        label: 'Personal.Biography',
        name: 'biography',
        required: false,
        disabled: false,
        component: 'text',
        multiline: true,
        rows: 4
      }
    ]
  },
  {
    groupName: 'Communication.Email',
    arrayName: 'emailDtos',
    arrayConfig: {
      actions: {
        isAddDisabled: true,
        isRemoveDisabled: true
      }
    },
    fields: [
      {
        label: 'Labels.Type',
        name: 'type.id',
        required: false,
        disabled: true,
        component: 'select',
        fieldWidth: 6
      },
      {
        label: 'Communication.Email',
        name: 'email',
        required: true,
        disabled: true,
        component: 'text',
        fieldWidth: 6
      }
    ]
  },
  {
    groupName: 'Address.Title',
    arrayName: 'addressDtos',
    arrayConfig: {
      actions: {
        isAddDisabled: true,
        isRemoveDisabled: true
      }
    },
    fields: [
      {
        label: 'Labels.Type',
        name: 'type.id',
        required: false,
        disabled: true,
        component: 'select',
        fieldWidth: 6
      },
      {
        label: 'Address.Street',
        name: 'street',
        required: false,
        disabled: true,
        component: 'text',
        fieldWidth: 6
      },
      {
        label: 'Address.Number',
        name: 'number',
        required: false,
        disabled: true,
        component: 'text',
        fieldWidth: 6
      },
      {
        label: 'Address.PostalCode',
        name: 'postalCode',
        required: false,
        disabled: true,
        component: 'text',
        fieldWidth: 6
      },
      {
        label: 'Address.State',
        name: 'state',
        required: false,
        disabled: true,
        component: 'text',
        fieldWidth: 6
      },
      {
        label: 'Address.City',
        name: 'city',
        required: false,
        disabled: true,
        component: 'text',
        fieldWidth: 6
      },
      {
        label: 'Address.POBox',
        name: 'poBox',
        required: false,
        disabled: true,
        component: 'text',
        fieldWidth: 6
      },
      {
        label: 'Address.Country',
        name: 'country',
        required: true,
        disabled: true,
        component: 'autocomplete',
        fieldWidth: 6
      },
      {
        label: 'Address.Region',
        name: 'region',
        required: false,
        disabled: true,
        component: 'autocomplete',
        fieldWidth: 6
      }
    ]
  },
  {
    groupName: 'Communication.Title',
    arrayName: 'phoneDtos',
    arrayConfig: {
      actions: {
        isAddDisabled: true,
        isRemoveDisabled: true
      }
    },
    fields: [
      {
        label: 'Labels.Type',
        name: 'type.id',
        required: false,
        disabled: true,
        component: 'select',
        fieldWidth: 6
      },
      {
        label: 'Labels.Phone',
        name: 'phone',
        required: true,
        disabled: true,
        component: 'text',
        fieldWidth: 6
      }
    ]
  }
];
