import React, { useEffect } from 'react';
import Localize from 'react-intl-universal';
import { useMatch, useNavigate } from 'react-location';
import { useSelector, useDispatch } from 'react-redux';

import EntityTypes from '@common/network/EntityTypes';
import { clearState } from '@common/storage/persistSlice';
import { CONFIRM_ACTIONS, openConfirmDialog } from '@components/ConfirmDialog';
import LayoutContainer, { LeftContainer, RightContainer } from '@components/LayoutContainer';
import { setMode } from '@components/RightSidebar/rightSidebarSlice';

import { ParticipantTabs } from '../components';
import Sidebar from '../components/Sidebar';
import {
  setDetails,
  saveParticipant,
  deleteParticipant,
  selectDetails,
  selectIsDetailsLoading
} from '../participantSlice';
import ParticipantDetails from './components/ParticipantDetails';

const Details = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    data: {
      participantDetails: { data: participantDetails }
    }
  } = useMatch();

  const details = useSelector(selectDetails);
  const isDetailsLoading = useSelector(selectIsDetailsLoading);

  useEffect(() => dispatch(setDetails(participantDetails)), [participantDetails?.id]);

  // Clear saved event information
  useEffect(() => {
    return () => {
      dispatch(clearState());
    };
  }, []);

  const onDelete = () => {
    dispatch(
      openConfirmDialog({
        title: Localize.get('ConfirmationMessages.Delete', {
          item: Localize.get('ParticipantsTile.Item')?.toLowerCase()
        }),
        confirmButton: Localize.get('Buttons.Delete'),
        cancelButton: Localize.get('Buttons.Cancel')
      })
    )
      .unwrap()
      .then((result) => {
        if (result === CONFIRM_ACTIONS.Cancel) {
          return;
        }

        dispatch(deleteParticipant(participantDetails?.id))
          .unwrap()
          .then(() => {
            dispatch(setMode('read'));
            navigate({ to: '/events', replace: false });
          })
          .catch((rejectedValueOrSerializedError) => console.error(rejectedValueOrSerializedError));
      });
  };

  const onSave = (values) => {
    return dispatch(saveParticipant(values))
      .unwrap()
      .then(() => {
        return Promise.resolve();
      })
      .catch((rejectedValueOrSerializedError) => {
        return Promise.reject({
          rejectedValueOrSerializedError,
          entityType: EntityTypes.PARTICIPANT
        });
      });
  };

  return (
    <LayoutContainer>
      <LeftContainer isFullScreen></LeftContainer>

      <RightContainer open={false} isFullScreen>
        <ParticipantDetails entityId={details?.id} details={details}>
          <ParticipantTabs
            entityId={details?.id}
            eventId={details?.event?.id}
            entityType={EntityTypes.SESSION}
            details={details}
            setDetails={setDetails}
          />
        </ParticipantDetails>
      </RightContainer>

      <Sidebar
        isDetailsLoading={isDetailsLoading}
        details={details}
        onSave={onSave}
        onDelete={onDelete}
      />
    </LayoutContainer>
  );
};

export default Details;
