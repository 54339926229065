import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { ILT_SESSION_PATHS } from '@common/network/ApiPaths';
import { getByPathAndParams } from '@services/BaseApi';

export const PAGE_SIZE = 5;
export const PAGE_SIZE_OPTIONS = [5, 10, 15, 20];

export const initialState = {
  data: [],
  filter: {
    search: '',
    page: 0,
    size: PAGE_SIZE
  },
  totalPages: 0,
  totalElements: 0,
  isLoading: false,
  selectionModel: []
};

export const fetchSessionDays = createAsyncThunk('ilt-sessions/session-rooms', (filterObj) => {
  return getByPathAndParams({
    path: ILT_SESSION_PATHS.GET_SESSION_DAY,
    pathVariables: { id: filterObj.entityId },
    params: filterObj.filter
  })
    .then((response) => response.data)
    .catch((error) => error);
});

export const sessionRoomsSlice = createSlice({
  name: 'sessionRooms',
  initialState,
  reducers: {
    resetState: () => initialState,
    setData: (state, { payload }) => {
      state.data = payload;
    },
    setSelectionModel: (state, { payload }) => {
      state.selectionModel = payload;
    },
    setIsLoading: (state, { payload }) => {
      state.isLoading = payload;
    },
    setFilterParams: (state, action) => {
      let newFilterValues = {};

      // Case when search value is reset to empty and search bar is closed
      if (action.payload.key === 'search' && !action.payload.value && !state.filter.search) {
        state.isLoading = false;
        return;
      }

      if (Array.isArray(action.payload)) {
        newFilterValues = action.payload.reduce(
          (obj, item) => ((obj[item.key] = item.value), obj),
          {}
        );
      } else {
        newFilterValues = { [action.payload.key]: action.payload.value };
      }

      state.filter = { ...state.filter, ...newFilterValues, page: action.payload.page ?? 0 };
    }
  },
  extraReducers: (builder) => {
    builder
      // Get all
      .addCase(fetchSessionDays.fulfilled, (state, { payload }) => {
        state.totalPages = payload?.totalPages || state.totalPages;
        state.totalElements = payload?.totalElements || state.totalElements;
        state.data = payload?.content || [];
      })
      .addCase(fetchSessionDays.rejected, (state) => {
        state.data = [];
      });
  }
});

export const selectList = (state) => state.sessionRooms.data;
export const selectTotalElements = (state) => state.sessionRooms.totalElements;
export const selectTotalPages = (state) => state.sessionRooms.totalPages;
export const selectFilter = (state) => state.sessionRooms.filter;
export const selectIsLoading = (state) => state.sessionRooms.isLoading;
export const selectSelectionModel = (state) => state.sessionRooms.selectionModel;

const { actions, reducer } = sessionRoomsSlice;

export const { setData, setFilterParams, resetState, setSelectionModel, setIsLoading } = actions;

export default reducer;
