import Localize from 'react-intl-universal';

import { styled } from '@mui/material/styles';

const StyledActive = styled('span')(({ theme }) => ({
  color: theme.palette.success.main
}));

const StyledObsolete = styled('span')(({ theme }) => ({
  color: theme.palette.error.main
}));

const ActiveStatus = ({ status = 'active' }) =>
  ({
    active: <StyledActive>{Localize.get('Labels.Active')}</StyledActive>,
    aktiv: <StyledActive>{Localize.get('Labels.Active')}</StyledActive>,
    obsolete: <StyledObsolete>{Localize.get('Labels.Obsolete')}</StyledObsolete>,
    Obsolet: <StyledObsolete>{Localize.get('Labels.Obsolete')}</StyledObsolete>
  }[status]);

export default ActiveStatus;
