import NoteTypes from '@common/network/NoteTypes';

const noteTypeConfig = [
  { id: 1, type: NoteTypes.NOTE, icon: 'notes' },
  { id: 2, type: NoteTypes.AGENDA, icon: 'checklist' },
  { id: 3, type: NoteTypes.ABSTRACT, icon: 'short_text' },
  { id: 4, type: NoteTypes.INTERNAL, icon: 'playlist_add_check' },
  { id: 5, type: NoteTypes.CV, icon: 'assignment_ind' },
  { id: 6, type: NoteTypes.ROOM_NOTE, icon: 'meeting_room' }
];

export default noteTypeConfig;
