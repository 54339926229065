import Localize from 'react-intl-universal';

import * as yup from 'yup';

import { addresses, phone, email } from '@common/yup';

export default () => {
  return yup.object().shape({
    organizationPerson: yup.object().shape({
      name: yup.string().required(Localize.get('ValidationMessages.FirstNameRequired')),
      phoneDtos: phone(),
      emailDtos: email(),
      addressDtos: addresses()
    })
  });
};
