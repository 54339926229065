import React, { useEffect, useState, useCallback } from 'react';
import Localize from 'react-intl-universal';
import { useNavigate } from 'react-location';
import { useSelector, useDispatch } from 'react-redux';

import EntityTypes from '@common/network/EntityTypes';
import { CONFIRM_ACTIONS, openConfirmDialog } from '@components/ConfirmDialog';
import { selectIsFullScreen } from '@components/DetailsToolbar/detailsToolbarSlice';
import Header from '@components/Header';
import LayoutContainer, { LeftContainer, RightContainer } from '@components/LayoutContainer';
import MasterList from '@components/MasterList';
import MasterListFooter from '@components/MasterListFooter';
import MasterListItem from '@components/MasterListItem';
import MasterListTitle from '@components/MasterListTitle';
import MasterListToolbar from '@components/MasterListToolbar';
import { selectIsOpen, setMode } from '@components/RightSidebar/rightSidebarSlice';
import SortDialog from '@components/SortDialog';
import { SEARCH_INPUT_DELAY } from '@config/inputs';
import useDebounce from '@hooks/handlers/useDebounce';
import {
  setFilterParams,
  setLoading,
  selectFilter,
  selectTotalElements,
  selectTotalPages,
  selectDetails,
  selectIsLoading,
  selectIsDetailsLoading,
  selectList,
  setSelectedId,
  selectId,
  initialState,
  saveMeetingRoom,
  fetchRooms,
  fetchRoomDetails,
  deleteMeetingRoom,
  resetState,
  setDetails
} from '@pages/MeetingRooms/meetingRoomsSlice';

import { MeetingRoomsDetails, MeetingRoomsTabs, Sidebar } from './components';
import { SORT_DATA } from './util/sortConfig';

const MeetingRooms = () => {
  // TODO:
  // const {
  //   data: {
  //     config: { sorts, tabs }
  //   }
  // } = useMatch();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isOpenDialogSort, setIsOpenDialogSort] = useState(false);

  const selectedId = useSelector(selectId);
  const data = useSelector(selectList);
  const details = useSelector(selectDetails);
  const totalElements = useSelector(selectTotalElements);
  const totalPages = useSelector(selectTotalPages);
  const filter = useSelector(selectFilter);

  const isLoading = useSelector(selectIsLoading);
  const isDetailsLoading = useSelector(selectIsDetailsLoading);

  const isOpen = useSelector(selectIsOpen);
  const isFullScreen = useSelector(selectIsFullScreen);

  // Get List of entities on initialization and sort change
  useEffect(() => dispatch(fetchRooms(filter)), [filter.sortBy, filter.sortDirection, filter.page]);

  // Get initial List of entities and on sort change
  useEffect(() => dispatch(fetchRoomDetails(selectedId)), [selectedId]);

  // Get List of entities on search change
  useDebounce(() => dispatch(fetchRooms(filter)), SEARCH_INPUT_DELAY, [filter.search]);

  // Render Master List Item
  const renderMasterListItem = useCallback(
    ({ id, name, floor, venue, availableSeats }) => {
      return (
        <MasterListItem
          key={id}
          id={id}
          heading={{ left: name, right: id }}
          rightDetails={{
            firstRow: `${availableSeats} ${Localize.get('MeetingRooms.Seats')}`
          }}
          leftDetails={{
            firstRow: floor,
            secondRow: venue?.name,
            thirdRow: venue?.city
          }}
          selectedId={selectedId}
          onItemSelect={(payload) => dispatch(setSelectedId(payload))}
        />
      );
    },
    [selectedId]
  );

  // Save Meeting Room when edited in the sidebar
  const onMeetingRoomSave = (values) => {
    return dispatch(saveMeetingRoom(values))
      .unwrap()
      .then(() => {
        return Promise.resolve();
      })
      .catch((rejectedValueOrSerializedError) => {
        return Promise.reject({
          rejectedValueOrSerializedError,
          entityType: EntityTypes.MEETING_ROOM
        });
      });
  };
  // Delete Meeting Room from the sidebar
  const onMeetingRoomDelete = () => {
    dispatch(
      openConfirmDialog({
        title: Localize.get('ConfirmationMessages.Delete', {
          item: Localize.get('MeetingRooms.Item')?.toLowerCase()
        }),
        confirmButton: Localize.get('Buttons.Delete'),
        cancelButton: Localize.get('Buttons.Cancel')
      })
    )
      .unwrap()
      .then((result) => {
        if (result === CONFIRM_ACTIONS.Cancel) {
          return;
        }
        dispatch(deleteMeetingRoom(selectedId))
          .unwrap()
          .then(() => dispatch(setMode('read')))
          .catch((rejectedValueOrSerializedError) => console.error(rejectedValueOrSerializedError));
      });
  };

  // Resets state on component destroy
  useEffect(() => {
    return () => dispatch(resetState());
  }, []);

  return (
    <LayoutContainer>
      <LeftContainer isFullScreen={isFullScreen}>
        <Header>
          <MasterListTitle>{`${Localize.get(
            'MeetingRooms.MasterListTittle'
          )} (${totalElements})`}</MasterListTitle>
        </Header>

        <MasterListToolbar
          isDisabled={data?.length === 0}
          onSortClick={() => setIsOpenDialogSort(true)}
          onFilterClick={() => alert('Not implemented.')}
          isSortActive={
            filter.sortBy !== initialState.filter.sortBy ||
            filter.sortDirection !== initialState.filter.sortDirection
          }
          searchProps={{
            value: filter.search,
            onSearchChange: (e) => {
              dispatch(setLoading(true));
              dispatch(setFilterParams({ key: 'search', value: e.target.value }));
            }
          }}
        />

        <MasterList isLoading={isLoading} data={data} renderMasterItem={renderMasterListItem} />

        <MasterListFooter
          onPageChange={(e, page) => dispatch(fetchRooms({ ...filter, page: page - 1 }))}
          onAddClick={() => navigate({ to: '/meeting-rooms/new', replace: false })}
          pagination={{ totalPages, page: filter.page + 1 }}
        />

        <SortDialog
          onCancel={() => setIsOpenDialogSort(false)}
          open={isOpenDialogSort}
          sortState={filter}
          fields={SORT_DATA}
          sortHandler={({ sortBy, sortDirection }) => {
            dispatch(
              setFilterParams([
                { key: 'sortBy', value: sortBy },
                { key: 'sortDirection', value: sortDirection }
              ])
            );
          }}
        />
      </LeftContainer>

      <RightContainer open={isOpen} isFullScreen={isFullScreen}>
        <MeetingRoomsDetails entityId={selectedId} details={details}>
          <MeetingRoomsTabs
            entityId={selectedId}
            entityType={EntityTypes.MEETING_ROOM}
            details={details}
            setDetails={setDetails}
          />
        </MeetingRoomsDetails>
      </RightContainer>

      <Sidebar
        details={details}
        isDetailsLoading={isDetailsLoading}
        onSave={onMeetingRoomSave}
        onDelete={onMeetingRoomDelete}
      />
    </LayoutContainer>
  );
};

export default MeetingRooms;
