import { DIALOG_PATHS } from '@common/network/ApiPaths';
import IndividualTypes from '@common/network/IndividualTypes';

export default [
  {
    groupName: 'Events.EventInformation',
    fields: [
      {
        label: 'Events.EventName',
        name: 'event',
        required: true,
        disabled: false,
        dialogTitle: 'Labels.SearchEvent',
        component: 'tokenValue',
        value: '',
        initQueryState: {
          name: '',
          id: ''
        },
        fetchDataPath: DIALOG_PATHS.EVENTS,
        dialogColumns: [
          {
            field: 'id',
            headerName: 'Labels.ID',
            width: 100,
            editable: false,
            defaultValue: '',
            headerAlign: 'left',
            sortable: false
          },
          {
            field: 'name',
            headerName: 'Events.EventName',
            width: 350,
            editable: false,
            defaultValue: '',
            headerAlign: 'left',
            sortable: false
          }
        ]
      }
    ]
  },
  {
    groupName: 'Events.Information',
    fields: [
      {
        label: 'Labels.Title',
        name: 'personDto.title.id',
        required: false,
        disabled: false,
        component: 'select',
        options: [
          { id: 0, value: 'None' },
          { id: 1, value: 'Mrs' },
          { id: 2, value: 'Mr' },
          { id: 3, value: 'Ms' }
        ]
      },
      {
        label: 'ParticipantsTile.Person',
        name: 'personDto.name',
        required: false,
        disabled: false,
        dialogTitle: 'Labels.SearchPerson',
        component: 'tokenValue',
        fetchDataPath: DIALOG_PATHS.PERSONS,
        value: '',
        initQueryState: {
          type: IndividualTypes.CONTACT,
          firstName: '',
          lastName: '',
          companyName: '',
          personTypeId: ''
        },
        dialogColumns: [
          {
            field: 'personTypeId',
            headerName: 'Labels.ID',
            width: 100,
            editable: false,
            defaultValue: '',
            headerAlign: 'left',
            sortable: false
          },
          {
            field: 'firstName',
            headerName: 'Labels.FirstName',
            width: 350,
            editable: false,
            defaultValue: '',
            headerAlign: 'left',
            sortable: false
          },
          {
            field: 'lastName',
            headerName: 'Labels.LastName',
            width: 350,
            editable: false,
            defaultValue: '',
            headerAlign: 'left',
            sortable: false
          },
          {
            field: 'companyName',
            headerName: 'Labels.CompanyName',
            width: 350,
            editable: false,
            defaultValue: '',
            headerAlign: 'left',
            sortable: false
          }
        ]
      },
      {
        label: 'Labels.FirstName',
        name: 'personDto.firstName',
        required: true,
        disabled: false,
        component: 'text'
      },
      {
        label: 'Labels.LastName',
        name: 'personDto.lastName',
        required: true,
        disabled: false,
        component: 'text'
      },
      {
        label: 'Labels.CompanyName',
        name: 'company',
        required: false,
        disabled: false,
        dialogTitle: 'Labels.SearchCompany',
        component: 'tokenValue',
        fetchDataPath: DIALOG_PATHS.ACCOUNTS,
        create: {
          to: '/business-partners/new',
          search: { isAccount: true }
        },
        value: '',
        initQueryState: {
          type: IndividualTypes.ORGANIZATION,
          name: '',
          id: ''
        },
        dialogColumns: [
          {
            field: 'id',
            headerName: 'Labels.ID',
            width: 100,
            editable: false,
            defaultValue: '',
            headerAlign: 'left',
            sortable: false
          },
          {
            field: 'name',
            headerName: 'Labels.CompanyName',
            width: 350,
            editable: false,
            defaultValue: '',
            headerAlign: 'left',
            sortable: false
          }
        ]
      },
      {
        label: 'Labels.CompanyInvoice',
        name: 'invoiceCompany',
        required: false,
        disabled: false,
        dialogTitle: 'Labels.SearchCompany',
        component: 'tokenValue',
        fetchDataPath: DIALOG_PATHS.ACCOUNTS,
        value: '',
        initQueryState: {
          type: IndividualTypes.ORGANIZATION,
          companyName: '',
          id: ''
        },
        dialogColumns: [
          {
            field: 'id',
            headerName: 'Labels.ID',
            width: 100,
            editable: false,
            defaultValue: '',
            headerAlign: 'left',
            sortable: false
          },
          {
            field: 'name',
            headerName: 'Labels.CompanyName',
            width: 350,
            editable: false,
            defaultValue: '',
            headerAlign: 'left',
            sortable: false
          }
        ]
      },
      {
        label: 'Labels.Status',
        name: 'status',
        required: true,
        disabled: false,
        component: 'select'
      },
      {
        label: 'Events.Remarks',
        name: 'remarks',
        required: false,
        disabled: false,
        component: 'text'
      },
      {
        label: 'Event.CancellationReason',
        name: 'cancellationReason',
        required: false,
        disabled: false,
        component: 'autocomplete'
      },
      {
        label: 'ParticipantsTile.JustResult',
        name: 'result',
        required: false,
        disabled: false,
        component: 'autocomplete'
      }
    ]
  },
  {
    groupName: 'Personal.Title',
    fields: [
      {
        label: 'Personal.BirthDate',
        name: 'personDto.birthDate',
        required: false,
        disabled: false,
        component: 'datepicker'
      },
      {
        label: 'Personal.BirthPlace',
        name: 'personDto.birthPlace',
        required: false,
        disabled: false,
        component: 'text'
      },
      {
        label: 'Personal.Gender',
        name: 'personDto.gender',
        required: false,
        disabled: false,
        component: 'autocomplete'
      },
      {
        label: 'Personal.Language',
        name: 'nativeLanguage',
        required: false,
        disabled: false,
        component: 'autocomplete'
      },
      {
        label: 'Personal.Allergies',
        name: 'allergies',
        required: false,
        disabled: false,
        component: 'comboBox'
      },
      {
        label: 'Personal.Vegetarian',
        name: 'isVegetarian',
        required: false,
        disabled: false,
        component: 'switch',
        column: 2,
        alignItems: 'center'
      },

      {
        label: 'Personal.Handicapped',
        name: 'isHandicap',
        required: false,
        disabled: false,
        component: 'switch',
        column: 2,
        alignItems: 'center'
      },
      {
        label: 'Personal.Vegan',
        name: 'isVegan',
        required: false,
        disabled: false,
        component: 'switch',
        column: 2,
        alignItems: 'center'
      },

      {
        label: 'Personal.Biography',
        name: 'biography',
        required: false,
        disabled: false,
        component: 'text',
        multiline: true,
        rows: 4
      }
    ]
  },
  {
    groupName: 'Labels.Hotel',
    fields: [
      {
        label: 'Hotel.Name',
        name: 'hotelReservation.id.hotel.id',
        required: false,
        disabled: false,
        dialogTitle: 'Labels.SearchHotel',
        component: 'tokenValue',
        value: '',
        initQueryState: {
          name: '',
          id: '',
          city: '',
          country: '',
          rating: '',
          region: ''
        },
        fetchDataPath: DIALOG_PATHS.HOTELS,
        dialogColumns: [
          {
            field: 'id',
            headerName: 'Labels.ID',
            width: 100,
            editable: false,
            defaultValue: '',
            headerAlign: 'left',
            sortable: false
          },
          {
            field: 'name',
            headerName: '',
            width: 350,
            editable: false,
            defaultValue: '',
            headerAlign: 'left',
            sortable: false
          }
        ]
      },
      {
        label: 'Hotel.RoomType',
        name: 'hotelReservation.roomType',
        required: false,
        disabled: false,
        component: 'autocomplete'
      },
      {
        label: 'Hotel.ArrivalDate',
        name: 'hotelReservation.arrivalDate',
        required: false,
        disabled: false,
        component: 'datepicker'
      },
      {
        label: 'Hotel.DepartureDate',
        name: 'hotelReservation.departureDate',
        required: false,
        disabled: false,
        component: 'datepicker'
      },
      {
        label: 'Hotel.Nights',
        name: 'hotelReservation.nights',
        required: false,
        disabled: true,
        component: 'text'
      },
      {
        label: 'Hotel.Shuttle',
        name: 'hotelReservation.shuttle',
        required: false,
        disabled: false,
        component: 'switch',
        column: 2,
        alignItems: 'center'
      },
      {
        label: 'Hotel.Persons',
        name: 'hotelReservation.persons',
        required: false,
        disabled: false,
        component: 'text',
        type: 'number'
      }
    ]
  },
  {
    groupName: 'Communication.Email',
    arrayName: 'emailDtos',
    fields: [
      {
        label: 'Labels.Type',
        name: 'type.id',
        required: false,
        disabled: false,
        component: 'select',
        fieldWidth: 6
      },
      {
        label: 'Communication.Email',
        name: 'email',
        required: true,
        disabled: false,
        component: 'text',
        fieldWidth: 6
      }
    ]
  },
  {
    groupName: 'Address.Title',
    arrayName: 'addressDtos',
    fields: [
      {
        label: 'Labels.Type',
        name: 'type.id',
        required: false,
        disabled: false,
        component: 'select',
        fieldWidth: 6
      },
      {
        label: 'Address.Street',
        name: 'street',
        required: false,
        disabled: false,
        component: 'text',
        fieldWidth: 6
      },
      {
        label: 'Address.Number',
        name: 'number',
        required: false,
        disabled: false,
        component: 'text',
        fieldWidth: 6
      },
      {
        label: 'Address.PostalCode',
        name: 'postalCode',
        required: false,
        disabled: false,
        component: 'text',
        fieldWidth: 6
      },
      {
        label: 'Address.State',
        name: 'state',
        required: false,
        disabled: false,
        component: 'text',
        fieldWidth: 6
      },
      {
        label: 'Address.City',
        name: 'city',
        required: false,
        disabled: false,
        component: 'text',
        fieldWidth: 6
      },
      {
        label: 'Address.POBox',
        name: 'poBox',
        required: false,
        disabled: false,
        component: 'text',
        fieldWidth: 6
      },
      {
        label: 'Address.Country',
        name: 'country',
        required: true,
        disabled: false,
        component: 'autocomplete',
        fieldWidth: 6
      },
      {
        label: 'Address.Region',
        name: 'region',
        required: false,
        disabled: false,
        component: 'autocomplete',
        fieldWidth: 6
      }
    ]
  },
  {
    groupName: 'Communication.Title',
    arrayName: 'phoneDtos',
    fields: [
      {
        label: 'Labels.Type',
        name: 'type.id',
        required: false,
        disabled: false,
        component: 'select',
        fieldWidth: 6
      },
      {
        label: 'Labels.Phone',
        name: 'phone',
        required: true,
        disabled: false,
        component: 'text',
        fieldWidth: 6
      }
    ]
  }
];

export const createWithoutEvent = [
  {
    groupName: 'Events.Information',
    fields: [
      {
        label: 'Labels.Title',
        name: 'personDto.title.id',
        required: false,
        disabled: false,
        component: 'select',
        options: [
          { id: 0, value: 'None' },
          { id: 1, value: 'Mrs' },
          { id: 2, value: 'Mr' },
          { id: 3, value: 'Ms' }
        ]
      },
      {
        label: 'ParticipantsTile.Person',
        name: 'personDto.name',
        required: false,
        disabled: false,
        dialogTitle: 'Labels.SearchPerson',
        component: 'tokenValue',
        fetchDataPath: DIALOG_PATHS.PERSONS,
        value: '',
        initQueryState: {
          type: IndividualTypes.CONTACT,
          firstName: '',
          lastName: '',
          companyName: '',
          personTypeId: ''
        },
        dialogColumns: [
          {
            field: 'personTypeId',
            headerName: 'Labels.ID',
            width: 100,
            editable: false,
            defaultValue: '',
            headerAlign: 'left',
            sortable: false
          },
          {
            field: 'firstName',
            headerName: 'Labels.FirstName',
            width: 350,
            editable: false,
            defaultValue: '',
            headerAlign: 'left',
            sortable: false
          },
          {
            field: 'lastName',
            headerName: 'Labels.LastName',
            width: 350,
            editable: false,
            defaultValue: '',
            headerAlign: 'left',
            sortable: false
          },
          {
            field: 'companyName',
            headerName: 'Labels.CompanyName',
            width: 350,
            editable: false,
            defaultValue: '',
            headerAlign: 'left',
            sortable: false
          }
        ]
      },
      {
        label: 'Labels.FirstName',
        name: 'personDto.firstName',
        required: true,
        disabled: false,
        component: 'text'
      },
      {
        label: 'Labels.LastName',
        name: 'personDto.lastName',
        required: true,
        disabled: false,
        component: 'text'
      },
      {
        label: 'Labels.CompanyName',
        name: 'company',
        required: false,
        disabled: false,
        dialogTitle: 'Labels.SearchCompany',
        component: 'tokenValue',
        fetchDataPath: DIALOG_PATHS.ACCOUNTS,
        create: {
          to: '/business-partners/new',
          search: { isAccount: true }
        },
        value: '',
        initQueryState: {
          type: IndividualTypes.ORGANIZATION,
          name: '',
          id: ''
        },
        dialogColumns: [
          {
            field: 'id',
            headerName: 'Labels.ID',
            width: 100,
            editable: false,
            defaultValue: '',
            headerAlign: 'left',
            sortable: false
          },
          {
            field: 'name',
            headerName: 'Labels.CompanyName',
            width: 350,
            editable: false,
            defaultValue: '',
            headerAlign: 'left',
            sortable: false
          }
        ]
      },
      {
        label: 'Labels.CompanyInvoice',
        name: 'invoiceCompany',
        required: false,
        disabled: false,
        dialogTitle: 'Labels.SearchCompany',
        component: 'tokenValue',
        fetchDataPath: DIALOG_PATHS.ACCOUNTS,
        value: '',
        initQueryState: {
          type: IndividualTypes.ORGANIZATION,
          companyName: '',
          id: ''
        },
        dialogColumns: [
          {
            field: 'id',
            headerName: 'Labels.ID',
            width: 100,
            editable: false,
            defaultValue: '',
            headerAlign: 'left',
            sortable: false
          },
          {
            field: 'name',
            headerName: 'Labels.CompanyName',
            width: 350,
            editable: false,
            defaultValue: '',
            headerAlign: 'left',
            sortable: false
          }
        ]
      },
      {
        label: 'Labels.Status',
        name: 'status',
        required: true,
        disabled: false,
        component: 'select'
      },
      {
        label: 'Events.Remarks',
        name: 'remarks',
        required: false,
        disabled: false,
        component: 'text'
      },
      {
        label: 'Event.CancellationReason',
        name: 'cancellationReason',
        required: false,
        disabled: false,
        component: 'autocomplete'
      },
      {
        label: 'ParticipantsTile.JustResult',
        name: 'result',
        required: false,
        disabled: false,
        component: 'autocomplete'
      }
    ]
  },
  {
    groupName: 'Personal.Title',
    fields: [
      {
        label: 'Personal.BirthDate',
        name: 'personDto.birthDate',
        required: false,
        disabled: false,
        component: 'datepicker'
      },
      {
        label: 'Personal.BirthPlace',
        name: 'personDto.birthPlace',
        required: false,
        disabled: false,
        component: 'text'
      },
      {
        label: 'Personal.Gender',
        name: 'personDto.gender',
        required: false,
        disabled: false,
        component: 'autocomplete'
      },
      {
        label: 'Personal.Language',
        name: 'nativeLanguage',
        required: false,
        disabled: false,
        component: 'autocomplete'
      },
      {
        label: 'Personal.Allergies',
        name: 'allergies',
        required: false,
        disabled: false,
        component: 'comboBox'
      },
      {
        label: 'Personal.Vegetarian',
        name: 'isVegetarian',
        required: false,
        disabled: false,
        component: 'switch',
        column: 2,
        alignItems: 'center'
      },

      {
        label: 'Personal.Handicapped',
        name: 'isHandicap',
        required: false,
        disabled: false,
        component: 'switch',
        column: 2,
        alignItems: 'center'
      },
      {
        label: 'Personal.Vegan',
        name: 'isVegan',
        required: false,
        disabled: false,
        component: 'switch',
        column: 2,
        alignItems: 'center'
      },

      {
        label: 'Personal.Biography',
        name: 'biography',
        required: false,
        disabled: false,
        component: 'text',
        multiline: true,
        rows: 4
      }
    ]
  },
  {
    groupName: 'Labels.Hotel',
    fields: [
      {
        label: 'Hotel.Name',
        name: 'hotelReservation.id.hotel.id',
        required: false,
        disabled: false,
        dialogTitle: 'Labels.SearchHotel',
        component: 'tokenValue',
        value: '',
        initQueryState: {
          name: '',
          id: '',
          city: '',
          country: '',
          rating: '',
          region: ''
        },
        fetchDataPath: DIALOG_PATHS.HOTELS,
        dialogColumns: [
          {
            field: 'id',
            headerName: 'Labels.ID',
            width: 100,
            editable: false,
            defaultValue: '',
            headerAlign: 'left',
            sortable: false
          },
          {
            field: 'name',
            headerName: '',
            width: 350,
            editable: false,
            defaultValue: '',
            headerAlign: 'left',
            sortable: false
          }
        ]
      },
      {
        label: 'Hotel.RoomType',
        name: 'hotelReservation.roomType',
        required: false,
        disabled: false,
        component: 'autocomplete'
      },
      {
        label: 'Hotel.ArrivalDate',
        name: 'hotelReservation.arrivalDate',
        required: false,
        disabled: false,
        component: 'datepicker'
      },
      {
        label: 'Hotel.DepartureDate',
        name: 'hotelReservation.departureDate',
        required: false,
        disabled: false,
        component: 'datepicker'
      },
      {
        label: 'Hotel.Nights',
        name: 'hotelReservation.nights',
        required: false,
        disabled: true,
        component: 'text'
      },
      {
        label: 'Hotel.Shuttle',
        name: 'hotelReservation.shuttle',
        required: false,
        disabled: false,
        component: 'switch',
        column: 2,
        alignItems: 'center'
      },
      {
        label: 'Hotel.Persons',
        name: 'hotelReservation.persons',
        required: false,
        disabled: false,
        component: 'text',
        type: 'number'
      }
    ]
  },
  {
    groupName: 'Communication.Email',
    arrayName: 'emailDtos',
    fields: [
      {
        label: 'Labels.Type',
        name: 'type.id',
        required: false,
        disabled: false,
        component: 'select',
        fieldWidth: 6
      },
      {
        label: 'Communication.Email',
        name: 'email',
        required: true,
        disabled: false,
        component: 'text',
        fieldWidth: 6
      }
    ]
  },
  {
    groupName: 'Address.Title',
    arrayName: 'addressDtos',
    fields: [
      {
        label: 'Labels.Type',
        name: 'type.id',
        required: false,
        disabled: false,
        component: 'select',
        fieldWidth: 6
      },
      {
        label: 'Address.Street',
        name: 'street',
        required: false,
        disabled: false,
        component: 'text',
        fieldWidth: 6
      },
      {
        label: 'Address.Number',
        name: 'number',
        required: false,
        disabled: false,
        component: 'text',
        fieldWidth: 6
      },
      {
        label: 'Address.PostalCode',
        name: 'postalCode',
        required: false,
        disabled: false,
        component: 'text',
        fieldWidth: 6
      },
      {
        label: 'Address.State',
        name: 'state',
        required: false,
        disabled: false,
        component: 'text',
        fieldWidth: 6
      },
      {
        label: 'Address.City',
        name: 'city',
        required: false,
        disabled: false,
        component: 'text',
        fieldWidth: 6
      },
      {
        label: 'Address.POBox',
        name: 'poBox',
        required: false,
        disabled: false,
        component: 'text',
        fieldWidth: 6
      },
      {
        label: 'Address.Country',
        name: 'country',
        required: true,
        disabled: false,
        component: 'autocomplete',
        fieldWidth: 6
      },
      {
        label: 'Address.Region',
        name: 'region',
        required: false,
        disabled: false,
        component: 'autocomplete',
        fieldWidth: 6
      }
    ]
  },
  {
    groupName: 'Communication.Title',
    arrayName: 'phoneDtos',
    fields: [
      {
        label: 'Labels.Type',
        name: 'type.id',
        required: false,
        disabled: false,
        component: 'select',
        fieldWidth: 6
      },
      {
        label: 'Labels.Phone',
        name: 'phone',
        required: true,
        disabled: false,
        component: 'text',
        fieldWidth: 6
      }
    ]
  }
];
