import moment from 'moment';

import { ACTION_MODES, TAB_KEYS } from '@common/Constants';
import { successMessageFormatter } from '@common/helpers/MessageFormatter';
import { TAB_PATHS } from '@common/network/ApiPaths';
import EntityTypes from '@common/network/EntityTypes';
import { closeConfirmDialog } from '@components/ConfirmDialog/confirmDialogSlice';
import { showSnackbar, SnackbarSeverityTypes } from '@components/Snackbar/snackbarSlice';
import { getByPathAndParams, postByPathAndData, deleteByPath } from '@services/BaseApi';

import {
  LOAD_SESSIONS,
  SET_SESSIONS_ERROR,
  SET_SESSIONS_LOADING,
  SESSIONS_DIALOG_OPEN,
  SESSIONS_DIALOG_CLOSE,
  SET_SESSIONS_SELECTION_MODEL,
  SET_SESSIONS_DIALOG_SELECTION_MODEL,
  UPDATE_SESSIONS_SUCCESS,
  UPDATE_SESSIONS_ERROR,
  DELETE_SESSIONS_SUCCESS,
  DELETE_SESSIONS_ERROR
} from './types';

export async function getSessions(dispatch, entityId, entityType) {
  if (!entityId) {
    return;
  }

  dispatch(setLoading(true));

  try {
    const { data } = await getByPathAndParams({
      path: TAB_PATHS.SESSIONS.GET_DETAILS,
      pathVariables: { entityType, entityId }
    });
    dispatch(loadSessions(data));
  } catch (error) {
    dispatch(setSessionsError(error.message));
  }
}

export async function updateSessions(dispatch, reduxDispatch, postData, entityId, setDetails) {
  dispatch(setLoading(true));

  const formatTime = (content) =>
    content.map((el) => ({
      ...el,
      session: {
        ...el?.session,
        timeBlockDate: moment(el?.session?.timeBlockDate)
          .subtract(1, 'months') // receive timeBlokDate like array
          .format(moment.HTML5_FMT.DATE)
      }
    }));

  try {
    const {
      data: { content, count }
    } = await postByPathAndData({
      path: TAB_PATHS.SESSIONS.POST_PARTICIPANTS,
      pathVariables: { entityId, postData }
    });
    dispatch(updateSessionsSuccess(formatTime(content)));
    reduxDispatch(setDetails({ counts: { [TAB_KEYS.SESSION]: count } }));
    reduxDispatch(
      showSnackbar({
        message: successMessageFormatter(EntityTypes.SESSION, ACTION_MODES.Add),
        severity: SnackbarSeverityTypes.SUCCESS
      })
    );
  } catch ({ response }) {
    dispatch(updateSessionsError(response?.data.message));
  }
}

export async function removeSessions(
  dispatch,
  reduxDispatch,
  participantId,
  sessionIds,
  setDetails
) {
  if (!participantId) {
    return;
  }

  dispatch(setLoading(true));

  try {
    const {
      data: { count }
    } = await deleteByPath({
      path: TAB_PATHS.SESSIONS.DELETE_PARTICIPANTS,
      pathVariables: { participantId, sessionIds }
    });

    dispatch(removeSessionsComplete(sessionIds));
    reduxDispatch(closeConfirmDialog());
    reduxDispatch(setDetails({ counts: { [TAB_KEYS.SESSION]: count } }));
    reduxDispatch(
      showSnackbar({
        message: successMessageFormatter(EntityTypes.SESSION, ACTION_MODES.Removed),
        severity: SnackbarSeverityTypes.SUCCESS
      })
    );
  } catch (error) {
    dispatch(removeSessionsError(error.message));
  }
}

export function removeSessionsComplete(payload) {
  return {
    type: DELETE_SESSIONS_SUCCESS,
    payload
  };
}

export function removeSessionsError(payload) {
  return {
    type: DELETE_SESSIONS_ERROR,
    payload
  };
}

export function setLoading(payload) {
  return {
    type: SET_SESSIONS_LOADING,
    isLoading: payload
  };
}

export function setSessionsError(payload) {
  return {
    type: SET_SESSIONS_ERROR,
    error: payload
  };
}

export function loadSessions(payload) {
  return {
    type: LOAD_SESSIONS,
    payload
  };
}

export function setSelectionModel(payload) {
  return {
    type: SET_SESSIONS_SELECTION_MODEL,
    payload
  };
}

export function setSelectionDialogModel(payload) {
  return {
    type: SET_SESSIONS_DIALOG_SELECTION_MODEL,
    payload
  };
}

export function openSessionsDialog(payload) {
  return {
    type: SESSIONS_DIALOG_OPEN,
    payload
  };
}

export function closeSessionsDialog() {
  return {
    type: SESSIONS_DIALOG_CLOSE
  };
}

export function updateSessionsError(payload) {
  return {
    type: UPDATE_SESSIONS_ERROR,
    payload
  };
}

export function updateSessionsSuccess(payload) {
  return {
    type: UPDATE_SESSIONS_SUCCESS,
    payload
  };
}
