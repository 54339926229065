import Localize from 'react-intl-universal';

import { styled } from '@mui/material/styles';

const StyledOpen = styled('span')(({ theme }) => ({
  color: theme.palette.success.main
}));

const StyledInProgress = styled('span')(({ theme }) => ({
  color: theme.palette.warning.main
}));

const StyledCompleted = styled('span')(({ theme }) => ({
  color: theme.palette.info.main
}));

const StyledCancelled = styled('span')(({ theme }) => ({
  color: theme.palette.error.main
}));

const ActivityStatus = ({ status = 1 }) =>
  ({
    1: <StyledOpen>{Localize.get('Event.Statuses.Open')}</StyledOpen>,
    2: <StyledInProgress>{Localize.get('Event.Statuses.InProgress')}</StyledInProgress>,
    3: <StyledCompleted>{Localize.get('Event.Statuses.Completed')}</StyledCompleted>,
    4: <StyledCancelled>{Localize.get('Event.Statuses.Cancelled')}</StyledCancelled>
  }[status]);

export default ActivityStatus;
