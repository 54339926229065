import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

const CheckboxInput = ({ field = {}, label = '', ...rest }) => (
  <FormControlLabel
    control={<Checkbox {...field} {...rest} checked={field?.value} />}
    label={label}
  />
);

export default CheckboxInput;
