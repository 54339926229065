const capitalizeFirstLetter = (string) => string?.charAt(0)?.toUpperCase() + string?.slice(1);

const isJsonString = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export { capitalizeFirstLetter, isJsonString };
