import React, { useState } from 'react';
import Localize from 'react-intl-universal';
import { useSelector } from 'react-redux';

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';

import { dateToFormat } from '@common/helpers/dates';
import { selectLanguage } from '@pages/Users/usersSlice';

const useStyles = makeStyles(() => ({
  customEvent: {
    width: '100%',
    height: '100%'
  },
  eventContainer: {
    fontSize: '0.75rem'
  },
  eventName: {
    marginLeft: '10px',
    borderRadius: '0px',
    fontSize: '0.75rem'
  },
  myButton: {
    padding: 0,
    color: 'inherit',
    '&:hover': {
      background: 'none'
    }
  },
  myButtonRight: {
    float: 'right',
    top: '50%',
    transform: 'translateY(10%)'
  },
  myIcon: {
    fontSize: '1rem',
    color: 'inherit'
  },
  startEndBasic: {
    paddingLeft: '0.5rem',
    fontSize: '0.75em',
    display: 'inline-block'
  },
  popupHeader: {
    padding: '0.5rem'
  },
  startEndBold: {
    fontWeight: '700'
  },
  availableSeats: {
    padding: '0.5rem',
    paddingTop: '1rem',
    fontSize: '0.75em'
  }
}));

const checkArrowVisibility = (view) => view !== 'month';

const EventStartEnd = ({ time, lang, text }) => {
  const classes = useStyles();
  return (
    <Box>
      <Typography variant="body1" className={`${classes.startEndBasic} ${classes.startEndBold}`}>
        {text}
      </Typography>
      <Typography variant="body1" className={classes.startEndBasic}>{`${dateToFormat(
        time,
        'MMM DD, YYYY - hh:mm a',
        lang
      )}`}</Typography>
    </Box>
  );
};

const CustomEvent = ({
  event: { name = '', status = {}, startDateTime, endDateTime, maxParticipants, currentView } = {},
  continuesPrior,
  continuesAfter
}) => {
  const classes = useStyles();
  const lang = useSelector(selectLanguage);
  const [anchorEl, setAnchorEl] = useState(null);
  const handlePopoverOpen = (event) => {
    let targetPosition = event.currentTarget.getClientRects();
    let yPopupPosition = targetPosition[0].y + targetPosition[0].height;
    setAnchorEl({ top: yPopupPosition, left: event.clientX });
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  return (
    <div
      className={classes.customEvent}
      onMouseEnter={handlePopoverOpen}
      onMouseLeave={handlePopoverClose}
    >
      <div className={classes.eventContainer}>
        {continuesPrior && checkArrowVisibility(currentView) && (
          <>
            <IconButton disableFocusRipple className={classes.myButton}>
              <ArrowBackIosIcon className={classes.myIcon} pointerEvents="none" />
            </IconButton>
          </>
        )}
        <span className={classes.eventName}>{name}</span>
        {!continuesPrior && !continuesAfter && currentView === 'day' && (
          <span>
            &emsp;
            {`${dateToFormat(startDateTime, 'hh:mm a', lang)} - ${dateToFormat(
              endDateTime,
              'hh:mm a',
              lang
            )}`}
          </span>
        )}
        {continuesAfter && checkArrowVisibility(currentView) && (
          <>
            <IconButton
              disableFocusRipple
              className={`${classes.myButton} ${classes.myButtonRight}`}
            >
              <ArrowForwardIosIcon className={classes.myIcon} pointerEvents="none" />
            </IconButton>
          </>
        )}
      </div>

      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: 'none'
        }}
        open={Boolean(anchorEl)}
        anchorReference="anchorPosition"
        anchorPosition={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography variant="h6" className={classes.popupHeader}>{`${name} - ${
          status?.value ?? ''
        }`}</Typography>
        <EventStartEnd time={startDateTime} lang={lang} text={Localize.get('Events.Start')} />
        <EventStartEnd time={endDateTime} lang={lang} text={Localize.get('Events.End')} />

        <Typography className={classes.availableSeats}>
          {`${Localize.get('Calendar.AvailableSeats')} ${maxParticipants}`}
        </Typography>
      </Popover>
    </div>
  );
};

export default CustomEvent;
