import { WORK_EMAIL_TYPE_ID } from '@common/Constants';
import { address } from '@components/FormArray';

export const initialValues = {
  person: {
    firstName: '',
    lastName: '',
    title: { id: 0, value: '' },
    birthPlace: '',
    birthDate: ''
  },
  function: '',
  department: '',
  jobTitle: '',
  organizationPerson: { id: null },
  contactStatus: 'active',
  phoneDtos: [],
  emailDtos: [{ type: { id: WORK_EMAIL_TYPE_ID }, email: '' }],
  addressDtos: [address]
};
