// MUI Overrides
export default {
  MUIRichTextEditor: {
    root: {
      marginTop: 1,
      minHeight: '25vh',
      '& .MuiButtonBase-root:hover': { color: '#000' },
      '& .MuiIconButton-colorPrimary': { color: '#000' }
    },
    editorContainer: {
      height: '20vh',
      width: '100%',
      '& .public-DraftEditor-content': { height: '20vh', width: '100%' }
    }
  }
};
