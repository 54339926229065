import {
  SET_LOADING,
  SET_NOTES_ERROR,
  DELETE_NOTE_ERROR,
  LOAD_NOTES,
  NOTES_DIALOG_OPEN,
  NOTES_DIALOG_CLOSE,
  ADD_NOTES_SUCCESS,
  ADD_NOTES_ERROR,
  SET_NOTES_SELECTION_MODEL,
  DELETE_NOTE_SUCCESS,
  UPDATE_NOTES_SUCCESS,
  SET_ANCHOR_EL,
  SET_NOTES_TYPE
} from './types';

const notesState = {
  data: [],
  isLoading: false,
  error: null,
  selectionModel: [],
  isNotesDialogOpen: false,
  selectedNote: null,
  anchorEl: null,
  notesType: 'NOTE'
};

function notesReducer(state, { type, payload }) {
  switch (type) {
    case SET_NOTES_ERROR:
      return { ...state, error: payload, isLoading: false, data: [] };
    case DELETE_NOTE_ERROR:
      return { ...state, error: payload };
    case SET_LOADING:
      return { ...state, isLoading: true };
    case DELETE_NOTE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: [...state.data.filter((item) => item.id !== payload)],
        selectionModel: []
      };

    case NOTES_DIALOG_OPEN:
      return { ...state, isNotesDialogOpen: true, selectedNote: payload };
    case NOTES_DIALOG_CLOSE:
      return { ...state, isNotesDialogOpen: false };
    case SET_NOTES_SELECTION_MODEL:
      return { ...state, selectionModel: payload };
    case UPDATE_NOTES_SUCCESS:
      return {
        ...state,
        isNotesDialogOpen: false,
        isLoading: false,
        data: [...state.data.map((item) => (item.id === payload.id ? payload : item))]
      };
    case ADD_NOTES_SUCCESS:
      return {
        ...state,
        isNotesDialogOpen: false,
        isLoading: false,
        data: [...state.data, payload]
      };
    case ADD_NOTES_ERROR:
      return {
        ...state,
        isNotesDialogOpen: false,
        isLoading: false,
        error: payload
      };
    case LOAD_NOTES:
      return {
        ...state,
        isLoading: false,
        data: payload?.content,
        totalPages: payload?.totalPages,
        totalElements: payload?.totalElements
      };
    case SET_ANCHOR_EL:
      return { ...state, anchorEl: payload };
    case SET_NOTES_TYPE:
      return { ...state, notesType: payload };

    default: {
      return state;
    }
  }
}

export { notesReducer, notesState };
