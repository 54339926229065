import React, { useState, useMemo } from 'react';
import Localize from 'react-intl-universal';

import CancelIcon from '@mui/icons-material/Cancel';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import SendIcon from '@mui/icons-material/Send';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import { orange } from '@mui/material/colors';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import useTheme from '@mui/styles/useTheme';

const ConfirmStatusDialog = ({
  isOpen = false,
  onConfirmStatusDialogCancel = () => {},
  onConfirmStatusDialogSave = () => {},
  title = '',
  listOfParticipants = []
}) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [selectedParticipantIds, setParticipantIds] = useState([]);

  const onCheckboxClick = (isChecked, participant) => {
    if (!isChecked) {
      setParticipantIds((prevState) => [...prevState, participant.id]);
      return;
    }

    setParticipantIds((prevState) => {
      const index = prevState.findIndex((id) => id === participant.id);
      return [...prevState.slice(0, index), ...prevState.slice(index + 1)];
    });
  };

  const onSelectAllCheckboxClick = () => {
    selectedParticipantIds?.length
      ? setParticipantIds([])
      : setParticipantIds(listOfParticipants.map(({ id }) => id));
  };

  const renderListOfParticipants = useMemo(() => {
    return listOfParticipants.map((participant) => {
      const labelId = `checkbox-list-secondary-label-${participant.id}`;
      const isChecked = selectedParticipantIds.findIndex((id) => id === participant.id) > -1;

      return (
        <ListItem
          divider
          key={participant.id}
          disablePadding
          secondaryAction={
            <Checkbox
              edge="end"
              onChange={() => onCheckboxClick(isChecked, participant)}
              checked={isChecked}
              inputProps={{ 'aria-labelledby': labelId }}
            />
          }
        >
          <ListItemButton>
            <ListItemAvatar>
              <Avatar sx={{ bgcolor: orange[500] }}>
                {participant.name.split(' ')[0].charAt(0)}
                {participant.name.split(' ')[1].charAt(0)}
              </Avatar>
            </ListItemAvatar>
            <ListItemText id={labelId} primary={`${participant.name}`} />
          </ListItemButton>
        </ListItem>
      );
    });
  }, [listOfParticipants, selectedParticipantIds]);

  return (
    <Dialog fullWidth fullScreen={fullScreen} maxWidth={'sm'} open={isOpen}>
      <DialogTitle
        sx={{
          boxShadow: 3,
          marginBottom: '1rem',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between'
        }}
      >
        <InfoOutlinedIcon fontSize="large" sx={{ mr: 1 }} />
        <Typography align="left" data-test-id="confirm-status-title">
          {title}
        </Typography>
      </DialogTitle>

      <>
        <DialogContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            maxHeight: '400'
          }}
        >
          <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', mr: '4px' }}>
              <ListItem divider disablePadding>
                <ListItemText primary={Localize.get('Labels.SelectAll')} />
                <Checkbox
                  checked={selectedParticipantIds.length === listOfParticipants.length}
                  indeterminate={
                    selectedParticipantIds.length > 0 &&
                    selectedParticipantIds.length !== listOfParticipants.length
                  }
                  onChange={() => onSelectAllCheckboxClick()}
                />
              </ListItem>
            </Box>
            {renderListOfParticipants}
          </List>
        </DialogContent>

        <DialogActions
          sx={{
            backgroundColor: 'primary.contrastText',
            justifyContent: 'center'
          }}
        >
          <Button
            disableFocusRipple
            variant="outlined"
            startIcon={<CancelIcon />}
            data-test-id="confirm-status-cancel"
            onClick={onConfirmStatusDialogCancel}
            color="error"
          >
            {Localize.get('Buttons.Cancel')}
          </Button>
          <Button
            disableFocusRipple
            variant="outlined"
            startIcon={<SendIcon />}
            data-test-id="confirm-status-send"
            onClick={() => {
              setParticipantIds([]);
              onConfirmStatusDialogSave(selectedParticipantIds);
            }}
          >
            {Localize.get('Buttons.Send')}
          </Button>
        </DialogActions>
      </>
    </Dialog>
  );
};

export default ConfirmStatusDialog;
