import { isNil, omit } from 'lodash';
import moment from 'moment';

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import {
  ACTION_MODES,
  dateInitFormats,
  NAVIGATE_TOASTER_TIMEOUT,
  SORT_DIRECTION
} from '@common/Constants';
import { dateToFormat } from '@common/helpers/dates';
import { deepMerge } from '@common/helpers/deepMerge';
import { copyObjectWithoutRef } from '@common/helpers/helpers';
import { errorMessageFormatter, successMessageFormatter } from '@common/helpers/MessageFormatter';
import { ILT_SESSION_DAY_PATHS, ILT_SESSION_PATHS } from '@common/network/ApiPaths';
import EntityTypes from '@common/network/EntityTypes';
import { clearState as clearPersistState } from '@common/storage/persistSlice';
import { scrubFiltersForBE } from '@components/FilterDialog/filtersSlice';
import { showSnackbar, SnackbarSeverityTypes } from '@components/Snackbar/snackbarSlice';
import { MASTER_LIST_PAGE_SIZE } from '@config/network';
import { fetchParticipants } from '@pages/IltSession/components/Tabs/ParticipantsTable/participantTableSlice';
import {
  deleteByPath,
  getByPathAndParams,
  patchByPathAndData,
  postByPathAndData
} from '@services/BaseApi';

import { deleteHotels, fetchHotels } from './components/Tabs/Hotel/hotelTableSlice';
import { fetchSessionDays } from './components/Tabs/SessionDays/sessionDaysSlice';
import { SORT_DATA } from './util/sortConfig';

export const WEBINAR_TYPE_ID = 17;
export const CLASSROOM_TYPE_ID = 16;

export const COMPLETED_STATUS_ID = 3;
export const CANCELLED_STATUS_ID = 4;

export const initialState = {
  isMounted: false,
  selectedId: null,
  data: [],
  filter: {
    search: '',
    sortBy: SORT_DATA[0].name,
    sortDirection: SORT_DIRECTION.DESCENDING,
    page: 0,
    size: MASTER_LIST_PAGE_SIZE
  },
  urlSearchParams: { externalId: '' },
  totalPages: 0,
  totalElements: 0,
  details: null,
  isLoading: true,
  isDetailsLoading: false,
  sessionDayDetails: {}
};

export const fetchIltSession = createAsyncThunk(
  'iltSession/fetchAll',
  (filter, { getState, dispatch }) => {
    const {
      sessionsFilter,
      iltSessionFilter,
      sessionsSelectedId,
      selectedId,
      filter: savedFilter
    } = getState()?.persist?.savedState || {};
    const isNavigatedFromSessionTab = Boolean(sessionsFilter && sessionsSelectedId);
    const isNavigatedFromTab = Boolean(savedFilter && selectedId);
    const isNavigatedFromBookingTab = Boolean(iltSessionFilter && selectedId);
    const { isActive, filter: advancedFilters } = getState()?.filterDialog;

    return getByPathAndParams({
      path: ILT_SESSION_PATHS.GET,
      params: isNavigatedFromSessionTab
        ? { ...sessionsFilter, search: '' }
        : isNavigatedFromTab
        ? { ...savedFilter, search: '' }
        : isNavigatedFromBookingTab
        ? { ...iltSessionFilter, search: '' }
        : isActive
        ? {
            filters: { advancedFilters: scrubFiltersForBE(advancedFilters, false) },
            ...filter
          }
        : filter
    })
      .then((response) => {
        if (isNavigatedFromSessionTab) {
          dispatch(setSelectedId(sessionsSelectedId));
          dispatch(clearPersistState());
        } else if (isNavigatedFromTab) {
          dispatch(setSelectedId(selectedId));
          dispatch(clearPersistState());
        } else if (isNavigatedFromBookingTab) {
          dispatch(setSelectedId(selectedId));
          dispatch(clearPersistState());
        }

        return response.data;
      })
      .catch((error) => error);
  },
  {
    condition: (_, { getState }) => {
      const { urlSearchParams, isMounted } = getState().iltSession;
      return urlSearchParams.externalId === '' && isMounted;
    }
  }
);

export const fetchIltSessionByExternalId = createAsyncThunk(
  'iltSession/fetchIltSessionByExternalId',
  ({ externalId }, thunkAPI) => {
    return getByPathAndParams({
      path: ILT_SESSION_PATHS.GET_EXTERNALS,
      pathVariables: { id: externalId }
    })
      .then((response) => response.data)
      .catch((error) => thunkAPI.rejectWithValue(error?.response));
  },
  {
    condition: ({ externalId }, { getState }) =>
      !!externalId && getState().iltSession.urlSearchParams,
    dispatchConditionRejection: true
  }
);

export const fetchIltSessionDetails = createAsyncThunk(
  'iltSession/fetchDetails',
  (selectedId, { rejectWithValue }) =>
    getByPathAndParams({
      path: ILT_SESSION_PATHS.GET_DETAILS,
      pathVariables: { id: selectedId }
    })
      .then((response) => ({
        ...response.data,
        seminarNumber: isNil(response.data.seminarNumber) ? '' : response.data.seminarNumber,
        // TODO: Mocked data for now
        automaticWaitingList: false,
        automaticWaitingListCancellation: false,
        automaticCancellationDate: new Date()
      }))
      .catch((error) => rejectWithValue(error)),
  {
    condition: (selectedId) => Boolean(selectedId),
    dispatchConditionRejection: true
  }
);

export const createIltSession = createAsyncThunk(
  'iltSession/create',
  (postData, { dispatch, rejectWithValue }) => {
    const startDateTime = dateToFormat(
      `${moment(postData?.sessionDayDto?.dayDate).format(dateInitFormats.basicDate)} ${moment(
        postData.sessionDayDto.startTime
      ).format(dateInitFormats.time)}`,
      dateInitFormats.dateTime
    );
    const endDateTime = dateToFormat(
      `${moment(postData?.sessionDayDto?.dayDate).format(dateInitFormats.basicDate)} ${moment(
        postData.sessionDayDto.endTime
      ).format(dateInitFormats.time)}`,
      dateInitFormats.dateTime
    );

    const cancellationDeadline = dateToFormat(
      postData?.cancellationDeadline,
      dateInitFormats.dateTime
    );
    const registrationFrom = dateToFormat(postData?.registrationFrom, dateInitFormats.dateTime);
    const registrationUntil = dateToFormat(postData?.registrationUntil, dateInitFormats.dateTime);

    const data = {
      ...postData,
      venueDtos: postData?.venueDtos.map(({ id }) => id),
      minSeats: postData?.minSeats || 0,
      statusId: postData?.status,
      typeId: postData?.type,
      nativeLanguageId: 1,
      startDateTime,
      endDateTime,
      course: {
        name: postData.course.name,
        id: postData.course.id
      },
      module: {
        id: postData?.iltModule?.id,
        name: postData?.iltModule?.name
      },
      cancellationDeadline,
      registrationFrom,
      registrationUntil,
      sessionDayDtos: [
        {
          ...postData.sessionDayDto,
          dayDate: moment(postData?.sessionDayDto?.dayDate).format(moment.HTML5_FMT.DATE),
          maxSeats: postData.maxSeats,
          minSeats: postData?.minSeats || 0,
          startTime: moment(postData.sessionDayDto.startTime).format(dateInitFormats.time),
          endTime: moment(postData.sessionDayDto.endTime).format(dateInitFormats.time),
          instructorIds: postData?.sessionDayDto?.instructor.map(({ id }) => id),
          meetingRoomId: postData?.sessionDayDto?.meetingRoomId?.id,
          venueId: postData?.sessionDayDto?.meetingRoomId?.id
            ? postData?.sessionDayDto?.meetingRoomId?.venue?.id
            : null
        }
      ]
    };

    delete data.iltModule;
    delete data.status;
    delete data.type;
    delete data.sessionDayDtos[0].instructor;
    delete data.sessionDayDto;

    if (postData?.type === CLASSROOM_TYPE_ID) {
      data.sessionDayDtos[0].webinarUrl = '';
    }

    if (postData?.type === WEBINAR_TYPE_ID) {
      data.venueDtos = null;
      data.sessionDayDtos[0].meetingRoomId = null;
    }

    return postByPathAndData({
      path: ILT_SESSION_PATHS.POST,
      data
    })
      .then((response) => {
        setTimeout(() => {
          dispatch(
            showSnackbar({
              message: successMessageFormatter(EntityTypes.ILT_SESSION, ACTION_MODES.Create),
              severity: SnackbarSeverityTypes.SUCCESS
            })
          );
        }, NAVIGATE_TOASTER_TIMEOUT);
        return response.data;
      })
      .catch((error) => rejectWithValue(error?.response));
  }
);

export const createIltSessionDay = createAsyncThunk(
  'iltSession/create',
  (postData, { rejectWithValue, dispatch, getState }) => {
    const data = {
      ...postData,
      dayDate: moment(postData.dayDate).format(moment.HTML5_FMT.DATE),
      startTime: moment(postData.startTime).format(dateInitFormats.time),
      endTime: moment(postData.endTime).format(dateInitFormats.time),
      meetingRoomId: postData?.meetingRoomId?.id || null,
      instructorIds: postData?.instructorIds?.map(({ id }) => id),
      iltSessionId: postData?.iltSessionId
    };

    delete data.course;
    delete data.iltModule;

    return postByPathAndData({
      path: ILT_SESSION_DAY_PATHS.POST,
      data
    })
      .then((response) => {
        const { filter } = getState().sessionDays;
        dispatch(
          fetchSessionDays({
            filter: { ...filter, sessionId: parseInt(postData?.iltSessionId, 10) }
          })
        );

        dispatch(
          showSnackbar({
            message: successMessageFormatter(EntityTypes.SESSION_DAY, ACTION_MODES.Create),
            severity: SnackbarSeverityTypes.SUCCESS
          })
        );

        return response.data;
      })
      .catch((error) => rejectWithValue(error?.response));
  }
);

export const createParticipant = createAsyncThunk(
  'participants/create',
  (data, { dispatch, rejectWithValue, getState }) => {
    const { selectedId } = getState().iltSession;
    const { filter } = getState().iltSessionParticipants;

    return postByPathAndData({
      path: ILT_SESSION_PATHS.POST_PARTICIPANT.replace(':id', selectedId),
      data: {
        personId: data.personDto?.id,
        companyId: data?.company?.id,
        invoiceCompanyId: data?.invoiceCompany?.id,
        statusId: data?.status,
        cancellationReasonId: data?.cancellationReason?.id,
        resultId: data?.result?.id,
        nativeLanguageId: data?.nativeLanguage?.id,
        ...omit(
          data,
          'addressDtos',
          'phoneDtos',
          'emailDtos',
          'personDto',
          'company',
          'iltSession',
          'invoiceCompany',
          'result',
          'status',
          'arrivalDate',
          'departureDate'
        ),
        arrivalDate:
          data?.hotelRequest === false
            ? null
            : moment(data.arrivalDate).format(moment.HTML5_FMT.DATE),
        departureDate:
          data?.hotelRequest === false
            ? null
            : moment(data.departureDate).format(moment.HTML5_FMT.DATE)
      }
    })
      .then((response) => {
        setTimeout(() => {
          dispatch(
            showSnackbar({
              message: successMessageFormatter(EntityTypes.PARTICIPANT, ACTION_MODES.Create),
              severity: SnackbarSeverityTypes.SUCCESS
            })
          );
        }, NAVIGATE_TOASTER_TIMEOUT);
        dispatch(fetchParticipants({ entityId: selectedId, filter: filter }));
        return response.data;
      })
      .catch((error) => rejectWithValue(error.response));
  }
);

// create hotel contingent
export const createIltHotelContingent = createAsyncThunk(
  'iltSession/create',
  ({ data, sessionId }, { rejectWithValue, dispatch, getState }) => {
    return postByPathAndData({
      path: ILT_SESSION_PATHS.POST_CONTINGENT.replace(':id', sessionId),
      data: {
        ...omit(data, 'maxOccupancy'),
        startDate: moment(data.startDate).format(moment.HTML5_FMT.DATE),
        endDate: moment(data.endDate).format(moment.HTML5_FMT.DATE),
        validUntil: data.validUntil ? moment(data.validUntil).format(moment.HTML5_FMT.DATE) : null,
        hotel: { id: data.hotel.id },
        accommodation: { id: data.accommodation.id },
        currency: { id: data.currency },
        booked: 0
      }
    })
      .then((response) => {
        dispatch(
          showSnackbar({
            message: successMessageFormatter(EntityTypes.CONTINGENT, ACTION_MODES.Create),
            severity: SnackbarSeverityTypes.SUCCESS
          })
        );
        dispatch(
          fetchHotels({
            entityId: getState().iltSession.selectedId,
            filter: getState().iltSessionHotels.filter
          })
        );
        return response.data;
      })
      .catch((error) => {
        dispatch(
          showSnackbar({
            message: errorMessageFormatter(error, EntityTypes.CONTINGENT, ACTION_MODES.Create),
            severity: SnackbarSeverityTypes.ERROR
          })
        );
        return rejectWithValue(error?.response);
      });
  }
);

// edit ilt session day
export const saveSessionDay = createAsyncThunk(
  'iltSession/edit',
  (patchData, { rejectWithValue, dispatch }) => {
    const data = Object.keys(patchData).reduce((objValue, currentParameter) => {
      switch (currentParameter) {
        case 'description':
          objValue.details = patchData?.description;
          break;
        case 'instructors':
          objValue.instructorIds = patchData.instructors.map((item) => item.id);
          break;
        default:
          objValue[currentParameter] = patchData[currentParameter];
          break;
      }

      return objValue;
    }, {});

    return patchByPathAndData({
      path: ILT_SESSION_DAY_PATHS.PATCH,
      pathVariables: { id: patchData.id },
      data: data
    })
      .then((response) => {
        dispatch(
          showSnackbar({
            message: successMessageFormatter(EntityTypes.SESSION_DAY, ACTION_MODES.Edit),
            severity: SnackbarSeverityTypes.SUCCESS
          })
        );
        return response?.data;
      })
      .catch((error) => rejectWithValue(error?.response));
  }
);

// delete session day
export const deleteSessionDay = createAsyncThunk(
  'ilt-session/delete',
  (id, { dispatch, rejectWithValue }) =>
    deleteByPath({
      path: ILT_SESSION_DAY_PATHS.DELETE,
      pathVariables: { id }
    })
      .then((response) => {
        dispatch(
          showSnackbar({
            message: successMessageFormatter(EntityTypes.SESSION_DAY, ACTION_MODES.Delete),
            severity: SnackbarSeverityTypes.SUCCESS
          })
        );
        return response.data;
      })
      .catch((error) => {
        dispatch(
          showSnackbar({
            message: errorMessageFormatter(error, EntityTypes.SESSION_DAY, ACTION_MODES.Delete),
            severity: SnackbarSeverityTypes.ERROR
          })
        );

        return rejectWithValue(error.response);
      })
);

export const deleteIltSession = createAsyncThunk(
  'iltSession/delete',
  (id, { dispatch, rejectWithValue }) =>
    deleteByPath({
      path: ILT_SESSION_PATHS.DELETE,
      pathVariables: { id }
    })
      .then((response) => {
        dispatch(
          showSnackbar({
            message: successMessageFormatter(EntityTypes.ILT_SESSION, ACTION_MODES.Delete),
            severity: SnackbarSeverityTypes.SUCCESS
          })
        );
        return response.data;
      })
      .catch((error) => {
        dispatch(
          showSnackbar({
            message: errorMessageFormatter(error, EntityTypes.ILT_SESSION, ACTION_MODES.Delete),
            severity: SnackbarSeverityTypes.ERROR
          })
        );

        return rejectWithValue(error.response);
      })
);

export const saveIltSession = createAsyncThunk(
  'iltSession/save',
  ({ postData, id }, { dispatch, rejectWithValue }) => {
    const data = { ...postData };

    if (data.status) {
      data.statusId = data.status.id;
      delete data.status;
    }

    if (data.venues) {
      data.venueDtos = data?.venues?.map(({ id }) => id) || [];
      delete data.venues;
    }

    return patchByPathAndData({
      path: ILT_SESSION_PATHS.PATCH,
      data,
      pathVariables: { id }
    })
      .then((response) => {
        dispatch(
          showSnackbar({
            message: successMessageFormatter(EntityTypes.ILT_SESSION, ACTION_MODES.Edit),
            severity: SnackbarSeverityTypes.SUCCESS
          })
        );
        return response.data;
      })
      .catch((error) => rejectWithValue(error?.response));
  }
);

const checkEmptyValues = (details) => {
  let detailsCopy = copyObjectWithoutRef(details);
  if (detailsCopy?.personDto?.title === null) {
    detailsCopy = {
      ...detailsCopy,
      personDto: { ...detailsCopy?.personDto, title: { id: 0 } }
    };
  }
  if (detailsCopy?.personDto?.birthDate === null) {
    detailsCopy = {
      ...detailsCopy,
      personDto: { ...detailsCopy?.personDto, birthDate: '' }
    };
  }
  if (detailsCopy?.personDto?.birthPlace === null) {
    detailsCopy = {
      ...detailsCopy,
      personDto: { ...detailsCopy?.personDto, birthPlace: '' }
    };
  }
  return detailsCopy;
};

export const iltSessionSlice = createSlice({
  name: 'iltSession',
  initialState,
  reducers: {
    resetState: () => initialState,
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setData: (state, { payload }) => {
      state.data = payload;
    },
    setIsMounted: (state, { payload }) => {
      state.isMounted = payload;
    },
    setUrlSearchParams: (state, { payload }) => {
      state.urlSearchParams = payload;
    },
    setSelectedId: (state, action) => {
      state.selectedId = action.payload;
    },
    setDetails: (state, { payload }) => {
      state.details = deepMerge(state.details || {}, payload);
    },
    deleteSuccess: (state, action) => {
      state.data = [...state.data.filter((item) => item.id !== action.payload.id)];
      state.selectedId = [...state.data.filter((item) => item.id !== action.payload.id)];
    },
    setSessionDayDetails: (state, { payload }) => {
      state.sessionDayDetails = deepMerge(state.sessionDayDetails, payload);

      const startTime = state.sessionDayDetails && state.sessionDayDetails?.startTime.split(':');
      const endTime = state.sessionDayDetails && state.sessionDayDetails?.endTime.split(':');

      state.sessionDayDetails = {
        ...state.sessionDayDetails,
        startTimeDate: new Date(0, 0, 0, startTime[0], startTime[1], 0),
        endTimeDate: new Date(0, 0, 0, endTime[0], endTime[1], 0)
      };
    },
    setFilterParams: (state, action) => {
      let newFilterValues = {};

      // Case when search value is reset to empty and search bar is closed
      if (action.payload.key === 'search' && !action.payload.value && !state.filter.search) {
        state.isLoading = false;
        return;
      }

      if (Array.isArray(action.payload)) {
        newFilterValues = action.payload.reduce(
          (obj, item) => ((obj[item.key] = item.value), obj),
          {}
        );
      } else {
        newFilterValues = { [action.payload.key]: action.payload.value };
      }

      state.filter = { ...state.filter, ...newFilterValues, page: action.payload.page ?? 0 };
      state.selectedId = null;
    }
  },
  extraReducers: (builder) => {
    builder
      // Get all
      .addCase(fetchIltSession.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.data = payload?.content || state.data;
        state.totalPages = payload?.totalPages || state.totalPages;
        state.totalElements = isNil(payload?.totalElements)
          ? state.totalElements
          : payload?.totalElements;
        state.selectedId =
          state.data.length === 0 ? null : state.selectedId || payload?.content?.[0]?.id;
        state.filter.page = payload?.pageable?.pageNumber;
        state.filter.size = payload?.pageable?.pageSize;
      })
      .addCase(fetchIltSession.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchIltSession.rejected, (state) => {
        state.data = [];
        state.isLoading = false;
      })
      // Get by external id
      .addCase(fetchIltSessionByExternalId.fulfilled, (state, { payload }) => {
        state.details = checkEmptyValues(payload);
        state.totalElements = 1;
        state.data = [payload];
        state.selectedId = payload.id;
        state.isDetailsLoading = false;
        state.isLoading = false;
      })
      .addCase(fetchIltSessionByExternalId.pending, (state) => {
        state.isDetailsLoading = true;
      })
      .addCase(fetchIltSessionByExternalId.rejected, (state) => {
        state.data = [];
        state.isLoading = false;
        state.details = null;
        state.selectedId = null;
        state.isDetailsLoading = false;
        state.totalElements = 0;
      })
      // Get Details
      .addCase(fetchIltSessionDetails.fulfilled, (state, action) => {
        state.details = checkEmptyValues(action?.payload);
        state.isDetailsLoading = false;
      })
      .addCase(fetchIltSessionDetails.pending, (state) => {
        state.isDetailsLoading = true;
      })
      .addCase(fetchIltSessionDetails.rejected, (state) => {
        state.details = null;
        state.isDetailsLoading = false;
      })
      // Create
      .addCase(createIltSession.fulfilled, (state) => {
        state.filter.page = 0;
      })
      // Update
      .addCase(saveIltSession.pending, (state) => {
        state.isDetailsLoading = true;
      })
      // Update success
      .addCase(saveIltSession.fulfilled, (state, action) => {
        state.isDetailsLoading = false;
        state.details = deepMerge(state.details, action.meta.arg.postData);
        state.data = state.data.map((item) =>
          item.id === action.meta.arg.id ? deepMerge(item, action.meta.arg.postData) : item
        );
      })
      // Update
      .addCase(saveIltSession.rejected, (state) => {
        state.isDetailsLoading = false;
      })
      // Delete
      .addCase(deleteIltSession.fulfilled, (state, action) => {
        state.totalElements -= 1;
        state.isLoading = false;
        state.details = null;
        state.data = state.data.filter((item) => item.id !== action.meta.arg);
        state.selectedId = state.data.filter((item) => item.id !== action.meta.arg)[0]?.id;

        if (state.data.length === 0 && state.totalPages === 1) {
          state.filter.page = 0;
          state.totalPages = 0;
          state.totalElements = 0;
        } else if (state.data.length === 0 && state.totalPages > 1) {
          state.filter.page -= 1;
        }
      })
      //Save Session Day
      .addCase(saveSessionDay.fulfilled, (state, action) => {
        state.sessionDayDetails = deepMerge(state.sessionDayDetails, action.meta.arg);
        state.isDetailsLoading = false;
      })
      .addCase(saveSessionDay.pending, (state) => {
        state.isDetailsLoading = true;
      })
      .addCase(saveSessionDay.rejected, (state) => {
        state.isDetailsLoading = false;
      })
      //Delete session day
      .addCase(deleteSessionDay.fulfilled, (state, action) => {
        state.totalElements -= 1;
        state.isLoading = false;
        state.details = null;
        state.data = state.data.filter((item) => item.id !== action.meta.arg);
        state.selectedId = state.data.filter((item) => item.id !== action.meta.arg)[0]?.id;

        if (state.data.length === 0 && state.totalPages === 1) {
          state.filter.page = 0;
          state.totalPages = 0;
          state.totalElements = 0;
        } else if (state.data.length === 0 && state.totalPages > 1) {
          state.filter.page -= 1;
        }
      })
      .addCase(deleteHotels.fulfilled, (state, { payload }) => {
        const { response } = payload;
        state.details.counts.hotel = response.data.count;
      });
  }
});

export const selectId = (state) => state.iltSession.selectedId;
export const selectList = (state) => state.iltSession.data;
export const selectFilter = (state) => state.iltSession.filter;
export const selectTotalElements = (state) => state.iltSession.totalElements;
export const selectTotalPages = (state) => state.iltSession.totalPages;
export const selectDetails = (state) => state.iltSession.details;
export const selectSessionDayDetails = (state) => state.iltSession.sessionDayDetails;
export const selectUrlSearchParams = (state) => state.iltSession.urlSearchParams;
export const selectIsMounted = (state) => state.iltSession.isMounted;

export const selectIsLoading = (state) => state.iltSession.isLoading;
export const selectIsDetailsLoading = (state) => state.iltSession.isDetailsLoading;

const { actions, reducer } = iltSessionSlice;

export const {
  setData,
  setError,
  setLoading,
  setSelectedId,
  setConfirmationDialog,
  deleteSuccess,
  setFilterParams,
  resetPage,
  resetState,
  setDetails,
  setSessionDayDetails,
  setUrlSearchParams,
  setIsMounted
} = actions;

export default reducer;
