import React from 'react';
import { Provider } from 'react-redux';

import { configureStore } from '@reduxjs/toolkit';

import persistReducer from '@common/storage/persistSlice';
import confirmDialogReducer from '@components/ConfirmDialog/confirmDialogSlice';
import detailsToolbarReducer from '@components/DetailsToolbar/detailsToolbarSlice';
import dragAndDropDialog from '@components/DragAndDropCalendar/dragAndDropDialogSlice';
import filterDialogReducer from '@components/FilterDialog/filtersSlice';
import navbarReducer from '@components/NavBar/navbarSlice';
import sidebarReducer from '@components/RightSidebar/rightSidebarSlice';
import snackbarReducer from '@components/Snackbar/snackbarSlice';
import activitiesReducer from '@pages/Activities/activitiesSlice';
import businessPartnersReducer from '@pages/BusinessPartners/businessPartnersSlice';
import businessPartnerContactsReducer from '@pages/BusinessPartners/components/Tabs/ContactTable/contactTableSlice';
import calendarReducer from '@pages/Calendar/calendarSlice';
import contactParticipationsReducer from '@pages/Contacts/components/Tabs/ParticipationTable/participationTableSlice';
import contactsReducer from '@pages/Contacts/contactsSlice';
import coursesReducer from '@pages/Courses/coursesSlice';
import employeeParticipationsReducer from '@pages/Employees/components/Tabs/ParticipationTable/participationTableSlice';
import employeesReducer from '@pages/Employees/employeesSlice';
import eventParticipants from '@pages/Events/components/Tabs/ParticipantsTable/participantTableSlice';
import eventsReducer from '@pages/Events/eventsSlice';
import iltSessionHotelsReducer from '@pages/IltSession/components/Tabs/Hotel/hotelTableSlice';
import iltSessionParticipantsReducer from '@pages/IltSession/components/Tabs/ParticipantsTable/participantTableSlice';
import iltSessionPricesReducer from '@pages/IltSession/components/Tabs/Price/priceTableSlice';
import sessionDaysReducer from '@pages/IltSession/components/Tabs/SessionDays/sessionDaysSlice';
import sessionRoomsReducer from '@pages/IltSession/components/Tabs/SessionRooms/sessionRoomsSlice';
import iltSessionTasksReducer from '@pages/IltSession/components/Tabs/Tasks/tasksTableSlice';
import IltSessionReducer from '@pages/IltSession/iltSessionSlice';
import bookingReducer from '@pages/IltSession/pages/Booking/bookingSlice';
import locationAccommodationReducer from '@pages/Locations/components/Tabs/AccommodationTable/accommodationTableSlice';
import locationContactsReducer from '@pages/Locations/components/Tabs/ContactTable/contactTableSlice';
import locationContingentsReducer from '@pages/Locations/components/Tabs/ContingentTable/contingentTableSlice';
import locationHotelBookingsReducer from '@pages/Locations/components/Tabs/HotelBooking/hotelBookingTableSlice';
import locationMeetingRoomsReducer from '@pages/Locations/components/Tabs/MeetingRoomTable/meetingRoomTableSlice';
import loginReducer from '@pages/Login/loginSlice';
import meetingRoomsReducer from '@pages/MeetingRooms/meetingRoomsSlice';
import orgStructureReducer from '@pages/OrgStructures/orgStructureSlice';
import participantsReducer from '@pages/Participants/participantSlice';
import resourceReducer from '@pages/Resources/resourcesSlice';
import userReducer from '@pages/Users/usersSlice';

export const store = configureStore({
  reducer: {
    rightSidebar: sidebarReducer,
    meetingRooms: meetingRoomsReducer,
    orgStructure: orgStructureReducer,
    businessPartners: businessPartnersReducer,
    booking: bookingReducer,
    activities: activitiesReducer,
    resource: resourceReducer,
    events: eventsReducer,
    employees: employeesReducer,
    courses: coursesReducer,
    iltSession: IltSessionReducer,
    contacts: contactsReducer,
    participants: participantsReducer,
    detailsToolbar: detailsToolbarReducer,
    navbar: navbarReducer,
    confirmDialog: confirmDialogReducer,
    snackbar: snackbarReducer,
    filterDialog: filterDialogReducer,
    calendar: calendarReducer,
    user: userReducer,
    login: loginReducer,
    eventsParticipants: eventParticipants,
    persist: persistReducer,
    dragAndDropDialog: dragAndDropDialog,
    sessionDays: sessionDaysReducer,
    sessionRooms: sessionRoomsReducer,
    iltSessionParticipants: iltSessionParticipantsReducer,
    locationContingents: locationContingentsReducer,
    iltSessionHotels: iltSessionHotelsReducer,
    locationHotelBookings: locationHotelBookingsReducer,
    locationContacts: locationContactsReducer,
    locationMeetingRooms: locationMeetingRoomsReducer,
    businessPartnerContacts: businessPartnerContactsReducer,
    locationAccommodation: locationAccommodationReducer,
    iltSessionPrices: iltSessionPricesReducer,
    iltSessionTasks: iltSessionTasksReducer,
    contactParticipations: contactParticipationsReducer,
    employeeParticipations: employeeParticipationsReducer
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false })
});

const ReduxProvider = ({ children }) => <Provider store={store}>{children}</Provider>;

export default ReduxProvider;
