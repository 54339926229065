import React, { useEffect } from 'react';
import Localize from 'react-intl-universal';
import { useNavigate } from 'react-location';
import { useSelector, useDispatch } from 'react-redux';

import Link from '@mui/material/Link';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';

import getLocalesText from '@common/helpers/tables/getLocalesText';
import TabContainer from '@components/TabContainer';
import TabContent from '@components/TabContent/TabContent';
import TableToolbar from '@components/TableToolbar/TableToolbar';
import ToolbarItem from '@components/TableToolbarItem';
import { StyledDataGrid } from '@components/TokenValueModalTrigger/components/DialogDataGrid';
import { selectLocationDetails } from '@pages/BusinessPartners/businessPartnersSlice';

import {
  fetchContingents,
  selectList,
  selectFilter,
  selectIsLoading,
  resetState,
  selectTotalElements,
  selectTotalPages,
  setFilterParams,
  selectSelectionModel,
  setSelectionModel
} from './contingentTableSlice';

export const PAGE_SIZE_OPTIONS = [5, 10, 15];

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  toolbarContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(1),
    alignItems: 'center'
  },
  toolbar: {
    minHeight: '3rem'
  },
  content: {
    margin: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  tableRow: {
    '&:hover': {
      backgroundColor: `${theme.palette.grey[400]} !important`,
      cursor: 'pointer'
    }
  },
  cellCheckbox: {
    padding: '5px 50px 5px 5px !important'
  },
  customTable: {
    overflowX: 'hidden',
    width: '92rem',
    '&:lastChild': {
      width: '100%',
      minWidth: 200
    }
  }
}));

const ContingentTable = ({
  columns = [],
  entityId = null,
  entityType = null,
  toolbarOptions = []
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const data = useSelector(selectList);
  const filter = useSelector(selectFilter);
  const isLoading = useSelector(selectIsLoading);
  const totalElements = useSelector(selectTotalElements);
  const totalPages = useSelector(selectTotalPages);
  const selectionModel = useSelector(selectSelectionModel);
  const details = useSelector(selectLocationDetails);

  useEffect(() => {
    if (details?.roles[0]?.hotel?.id) {
      dispatch(fetchContingents({ entityId: details?.roles[0]?.hotel?.id, filter }));
    }
  }, [
    details?.roles[0]?.hotel?.id,
    filter?.page,
    filter?.sortBy,
    filter?.sortDirection,
    filter?.size
  ]);

  // Resets state on component destroy
  useEffect(() => {
    return () => dispatch(resetState());
  }, []);

  const isToolbarItemDisabled = (fieldName) => {
    switch (fieldName) {
      case 'edit':
        return true;
      case 'add':
        return true;
      case 'delete':
        return true;
      default:
        return false;
    }
  };

  const onToolbarItemClick = (fieldName) => {
    switch (fieldName) {
      case 'edit':
        return;
      case 'add':
        return !entityId || !entityType;
      case 'delete':
        return true;
      default:
        return false;
    }
  };

  return (
    <div className={classes.root}>
      <TabContent>
        <div className={classes.toolbarContainer}>
          <Typography variant="h6" component="h6" color="primary">
            {Localize.get('Labels.Contingent')}
          </Typography>
          <Toolbar className={classes.toolbar}>
            {toolbarOptions?.map((item, index) => (
              <ToolbarItem
                key={index}
                item={item}
                isDisabled={item.disabled || isToolbarItemDisabled(item.fieldName)}
                onToolbarItemClick={onToolbarItemClick}
              />
            ))}
          </Toolbar>
        </div>
        <TabContainer>
          <StyledDataGrid
            keepNonExistentRowsSelected={true}
            loading={isLoading}
            rows={data}
            columns={[
              ...columns.map((column) => ({
                ...column,
                headerName: Localize.get(column.headerName),
                renderCell: (params) => (
                  <div data-test-id={`${params.field}-${params?.row?.id ?? 'default'}`}>
                    {['usedIn'].includes(params.field) ? (
                      <Tooltip title={params.value} arrow>
                        <Link
                          sx={{
                            display: 'block',
                            cursor: 'pointer',
                            maxWidth: params?.colDef?.width,
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis'
                          }}
                          onClick={() =>
                            navigate({
                              to: params?.row?.event?.isEvent
                                ? `/events/${params.row.id}/`
                                : `/ilt-session/${params.row.event.id}`,
                              replace: false
                            })
                          }
                        >
                          {params?.value}
                        </Link>
                      </Tooltip>
                    ) : (
                      <Typography variant="body" component="div">
                        {params.value}
                      </Typography>
                    )}
                  </div>
                )
              }))
            ]}
            initialState={{
              pagination: {
                pageSize: totalPages,
                rowCount: totalElements,
                page: filter?.page
              }
            }}
            onSelectionModelChange={(newSelectionModel) =>
              dispatch(setSelectionModel(newSelectionModel))
            }
            components={{
              Toolbar: () => <TableToolbar />
            }}
            selectionModel={selectionModel}
            checkboxSelection
            localeText={getLocalesText(Localize)}
            pagination
            paginationMode="server"
            disableColumnMenu
            page={filter?.page}
            pageSize={filter?.size}
            rowCount={totalElements}
            rowsPerPageOptions={PAGE_SIZE_OPTIONS}
            disableSelectionOnClick
            onPageChange={(page) => dispatch(setFilterParams({ page, selectionModel }))}
            onPageSizeChange={(value) =>
              dispatch(setFilterParams([{ key: 'size', value, selectionModel }]))
            }
          />
        </TabContainer>
      </TabContent>
    </div>
  );
};

export default ContingentTable;
