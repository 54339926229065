import React from 'react';
import Localize from 'react-intl-universal';

import { Field } from 'formik';

import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';

import SelectInput from '@components/SelectInput';

const ActivitiesType = ({ options, path }) => (
  <>
    <Grid container spacing={2} sx={{ justifyContent: 'center', mt: 4 }}>
      <Grid item xs={12} sm={8} md={6} lg={4}>
        <FormControl fullWidth>
          <Field
            name="type"
            label={Localize.get('Activities.ActivityType')}
            options={options}
            component={SelectInput}
            path={path}
          />
        </FormControl>
      </Grid>
    </Grid>
  </>
);

export default ActivitiesType;
