import { DIALOG_PATHS } from '@common/network/ApiPaths';

export default [
  {
    groupName: 'Events.Information',
    fields: [
      {
        label: 'Events.EventName',
        name: 'name',
        required: true,
        disabled: false,
        component: 'text'
      },
      {
        label: 'Events.Status',
        name: 'status.id',
        required: true,
        disabled: false,
        component: 'select'
      },
      {
        label: 'Events.EventType',
        name: 'type',
        required: true,
        disabled: false,
        component: 'autocomplete'
      }
    ]
  },
  {
    groupName: 'Events.EssentialInformation',
    fields: [
      {
        label: 'Events.MaxParticipants',
        name: 'maxParticipants',
        required: true,
        disabled: false,
        component: 'text',
        type: 'number'
      },
      {
        label: 'Events.OptParticipants',
        name: 'optParticipants',
        required: false,
        disabled: false,
        component: 'text',
        type: 'number'
      },
      {
        label: 'Events.MinParticipants',
        name: 'minParticipants',
        required: false,
        disabled: false,
        component: 'text',
        type: 'number'
      },
      {
        label: 'Events.StartDate',
        name: 'startDateTime',
        required: true,
        disabled: false,
        component: 'datetimepicker'
      },
      {
        label: 'Events.EndDate',
        name: 'endDateTime',
        required: true,
        disabled: false,
        component: 'datetimepicker'
      },
      {
        label: 'Events.RegistrationDueDate',
        name: 'registrationDueDate',
        required: false,
        disabled: false,
        component: 'datetimepicker'
      },
      {
        label: 'Events.VenueName',
        name: 'venues',
        required: false,
        disabled: false,
        dialogTitle: 'Labels.SearchVenue',
        component: 'tokenValueArray',
        fetchDataPath: DIALOG_PATHS.VENUES,
        value: '',
        initQueryState: {
          id: '',
          name: '',
          city: '',
          country: ''
        },
        dialogColumns: [
          {
            field: 'id',
            headerName: 'Labels.ID',
            width: 100,
            editable: false,
            defaultValue: '',
            headerAlign: 'left',
            sortable: false
          },
          {
            field: 'name',
            headerName: 'Labels.Name',
            width: 350,
            editable: false,
            defaultValue: '',
            headerAlign: 'left',
            sortable: false
          },
          {
            field: 'city',
            headerName: 'Address.City',
            width: 350,
            editable: false,
            defaultValue: '',
            headerAlign: 'left',
            sortable: false,
            valueGetter: (params) => params?.row?.addresses[0].city
          },
          {
            field: 'country',
            headerName: 'Address.Country',
            width: 350,
            editable: false,
            defaultValue: '',
            headerAlign: 'left',
            sortable: false,
            valueGetter: (params) => params?.row?.addresses[0].country.value
          }
        ]
      },
      {
        label: 'Address.Country',
        name: 'country',
        required: false,
        disabled: false,
        component: 'autocomplete'
      },
      {
        label: 'Address.City',
        name: 'city',
        required: false,
        disabled: false,
        component: 'text'
      }
    ]
  },
  {
    groupName: 'Events.SpecificInformation',
    fields: [
      {
        label: 'Events.OrderNumber',
        name: 'orderNumberInGroup',
        required: false,
        disabled: false,
        component: 'text',
        type: 'number'
      },
      {
        label: 'Events.EventLanguage',
        name: 'nativeLanguage.id',
        required: true,
        disabled: false,
        component: 'select'
      }
    ]
  },
  {
    groupName: 'Events.AdditionalInformation',
    fields: [
      {
        label: 'Events.EventDescription',
        name: 'description',
        required: false,
        disabled: false,
        component: 'text'
      },
      {
        label: 'Events.Remarks',
        name: 'remarks',
        required: false,
        disabled: false,
        component: 'text'
      },
      {
        label: 'Events.PartnerEvent',
        name: 'isPartnerEvent',
        required: false,
        disabled: false,
        component: 'switch',
        fieldWidth: 6
      },
      {
        label: 'Events.NameTagPrinting',
        name: 'hasNameTagPrinting',
        required: false,
        disabled: false,
        component: 'switch',
        fieldWidth: 6
      },
      {
        label: 'Events.AutomaticConfirmation',
        name: 'hasAutomaticConfirmation',
        required: false,
        disabled: false,
        component: 'switch',
        fieldWidth: 6
      },
      {
        label: 'Events.WaitingList',
        name: 'hasWaitingList',
        required: false,
        disabled: false,
        component: 'switch',
        fieldWidth: 6
      },
      {
        label: 'Events.Private',
        name: 'isPrivate',
        required: false,
        disabled: false,
        component: 'switch',
        fieldWidth: 6
      },
      {
        label: 'Events.MEA',
        name: 'isMeaPublished',
        required: false,
        disabled: false,
        component: 'switch',
        fieldWidth: 6
      },
      {
        label: 'Events.Web',
        name: 'isWebPublished',
        required: false,
        disabled: false,
        component: 'switch',
        fieldWidth: 6
      }
    ]
  }
];
