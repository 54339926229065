import React, { useState, useEffect, useRef } from 'react';
import Localize from 'react-intl-universal';

import { get } from 'lodash';

import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import FormHelperText from '@mui/material/FormHelperText';
import TextField from '@mui/material/TextField';

import { getByPathAndParams } from '@services/BaseApi';

const AutocompleteComponent = ({
  field = {},
  form: { touched, errors, setFieldTouched } = {}, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  path,
  pathVariables,
  pathVariablesRequired = undefined,
  formItemChangeHandler = () => {},
  labelStyle = {},
  responseFormater = (data) => data,
  getOptionLabel = (option) => option?.value || option,
  isOptionEqualToValue = (option, value) => option.id === value.id,
  label = '',
  required = false,
  hideItemId = null,
  disableClearable = false,
  helperText,
  ...rest
}) => {
  const nameRef = useRef();
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  const getData = async () => {
    try {
      if (!pathVariablesRequired) {
        const response = path && (await getByPathAndParams({ path }));
        if (response?.data) {
          setOptions(responseFormater(response.data));
        }
      } else {
        if (
          !pathVariablesRequired
            .map((requiredVariable) => {
              return pathVariables[requiredVariable] ? true : false;
            })
            .includes(false)
        ) {
          const response = path && (await getByPathAndParams({ path, pathVariables }));
          if (response?.data) {
            setOptions(responseFormater(response.data));
          }
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!open) {
      setOptions([]);
    } else {
      setLoading(true);
      getData();
    }
  }, [open]);

  return (
    <Autocomplete
      value={field?.value || null}
      ref={nameRef}
      data-test-id="autocomplete-component"
      size="small"
      disableClearable={disableClearable}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      onKeyPress={() => {
        setOpen(true);
      }}
      isOptionEqualToValue={isOptionEqualToValue}
      getOptionLabel={getOptionLabel}
      options={hideItemId !== null ? options.filter((el) => el.id !== hideItemId) : options}
      loading={loading}
      onChange={(event, newValue) => {
        const nameGetter = nameRef.current.getAttribute('name');
        const index = nameRef.current.getAttribute('data-index');
        const data = { name: nameGetter, newValue };
        formItemChangeHandler(event, data, field?.name, index);
      }}
      {...rest}
      renderInput={(params) => (
        <>
          <TextField
            {...labelStyle}
            {...params}
            required={required}
            label={label}
            onBlur={() => setFieldTouched(field?.name, true, true)}
            error={Boolean(get(touched, field?.name) && get(errors, field?.name))}
            helperText={get(touched, field?.name) && get(errors, field?.name)}
            InputProps={{
              ...params.InputProps,
              name: field?.name,
              endAdornment: (
                <>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              )
            }}
          />
          {helperText && (
            <FormHelperText id="component-helper-text">{Localize.get(helperText)}</FormHelperText>
          )}
        </>
      )}
    />
  );
};

export default AutocompleteComponent;
