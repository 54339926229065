import React from 'react';
import { Pie } from 'react-chartjs-2';
import Localize from 'react-intl-universal';

import { Chart as ChartJS, ArcElement, Tooltip, Legend, Title } from 'chart.js';

import { alpha } from '@mui/material';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/styles';

ChartJS.defaults.font.family = '"Roboto", "Helvetica", "Arial",sans-serif';
ChartJS.register(ArcElement, Tooltip, Legend, Title);

const COLOR_OPACITY = 0.6;

const ParticipantList = ({ data = [] }) => {
  const participantsCount = data?.participantsCount;
  const theme = useTheme();
  const isEmpty = participantsCount.every((n) => n === 0);

  const chartData = {
    labels: [
      Localize.get('ParticipantsTile.Statuses.ToBeInvited'),
      Localize.get('ParticipantsTile.Statuses.Invited'),
      Localize.get('ParticipantsTile.Statuses.Confirmed'),
      Localize.get('ParticipantsTile.Statuses.Cancelled'),
      Localize.get('ParticipantsTile.Statuses.Completed'),
      Localize.get('ParticipantsTile.Statuses.Registered'),
      Localize.get('ParticipantsTile.Statuses.Reserved')
    ],
    datasets: [
      {
        data: participantsCount,
        backgroundColor: [
          alpha(theme.palette.primary.main, COLOR_OPACITY),
          alpha(theme.palette.info.light, COLOR_OPACITY),
          alpha(theme.palette.success.main, COLOR_OPACITY),
          alpha(theme.palette.error.main, COLOR_OPACITY),
          alpha(theme.palette.info.main, COLOR_OPACITY),
          alpha(theme.palette.grey[50], COLOR_OPACITY),
          alpha(theme.palette.accent.main, COLOR_OPACITY)
        ]
      }
    ]
  };

  const placeholderData = {
    labels: '',
    datasets: [
      { label: [''], data: [100], backgroundColor: alpha(theme.palette.grey[100], COLOR_OPACITY) }
    ]
  };

  return (
    <Box
      sx={{
        border: 1,
        borderColor: 'divider',
        borderRadius: 1,
        position: 'relative',
        height: '30vh',
        width: '45vh'
      }}
    >
      <ListItem>
        <ListItemText
          primary={
            <>
              <Typography sx={{ mr: 1 }} component="span" variant="body2" color="text.primary">
                {Localize.get('ParticipantsTile.ParticipantsByStatus')}
              </Typography>
            </>
          }
        />
      </ListItem>
      <Divider />
      <Pie
        data={isEmpty ? placeholderData : chartData}
        options={{
          responsive: true,
          aspectRatio: 2,
          layout: {
            autoPadding: false,
            padding: {
              top: 16,
              right: 16,
              left: 16
            }
          },
          plugins: {
            datalabels: {
              display: true,
              color: 'white',
              font: {
                size: 16
              }
            },
            legend: {
              align: 'center',
              position: 'right',
              labels: {
                padding: 8,
                font: {
                  size: 12
                }
              }
            }
          }
        }}
      />
    </Box>
  );
};

export default ParticipantList;
