import React from 'react';
import Localize from 'react-intl-universal';

import moment from 'moment';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';

import { DATE_FORMAT } from '@config/inputs';

const HotelInformation = ({ data = null }) => {
  const usedFreeBeds = data?.usedFreeBeds;
  const startDate = data?.startDate;
  const endDate = data?.endDate;
  const maxOccupancy = data?.accommodation?.maxOccupancy;

  return (
    <Box sx={{ width: '45vh', minHeight: '30vh' }}>
      <ListItem>
        <ListItemText
          sx={{ whiteSpace: 'nowrap' }}
          primary={
            <>
              <Typography
                sx={{ display: 'inline-block', mr: 1 }}
                component="span"
                variant="body2"
                color="text.primary"
              >
                {Localize.get('Labels.Booked')}:
              </Typography>
              <Typography
                noWrap={true}
                sx={{
                  width: `calc(100% - ${Localize.get('IltSession.DetailsOverview').length * 8}px)`,
                  display: 'inline-block',
                  verticalAlign: 'middle'
                }}
                component="span"
                variant="body2"
                color="text.secondary"
              >
                {usedFreeBeds}
              </Typography>
            </>
          }
        />
      </ListItem>
      <Divider />
      <ListItem>
        <ListItemText
          sx={{ whiteSpace: 'nowrap' }}
          primary={
            <>
              <Typography
                sx={{ display: 'inline-block', mr: 1 }}
                component="span"
                variant="body2"
                color="text.primary"
              >
                {Localize.get('IltSession.StartDate')}:
              </Typography>
              <Typography
                noWrap={true}
                sx={{
                  width: `calc(100% - ${Localize.get('IltSession.Date').length * 8}px)`,
                  display: 'inline-block',
                  verticalAlign: 'middle'
                }}
                component="span"
                variant="body2"
                color="text.secondary"
              >
                {moment(startDate).format(DATE_FORMAT)}
              </Typography>
            </>
          }
        />
      </ListItem>
      <Divider />
      <ListItem>
        <ListItemText
          sx={{ whiteSpace: 'nowrap' }}
          primary={
            <>
              <Typography
                sx={{ display: 'inline-block', mr: 1 }}
                component="span"
                variant="body2"
                color="text.primary"
              >
                {Localize.get('IltSession.EndDate')}:
              </Typography>
              <Typography
                noWrap={true}
                sx={{
                  width: `calc(100% - ${Localize.get('IltSession.Date').length * 8}px)`,
                  display: 'inline-block',
                  verticalAlign: 'middle'
                }}
                component="span"
                variant="body2"
                color="text.secondary"
              >
                {moment(endDate).format(DATE_FORMAT)}
              </Typography>
            </>
          }
        />
      </ListItem>
      <Divider />
      <Divider />
      <ListItem>
        <ListItemText
          sx={{ whiteSpace: 'nowrap' }}
          primary={
            <>
              <Typography
                sx={{ display: 'inline-block', mr: 1 }}
                component="span"
                variant="body2"
                color="text.primary"
              >
                {Localize.get('Labels.MaxOccupancy')}:
              </Typography>
              <Typography
                noWrap={true}
                sx={{
                  width: `calc(100% - ${Localize.get('IltSession.TimeTo').length * 8}px)`,
                  display: 'inline-block',
                  verticalAlign: 'middle'
                }}
                component="span"
                variant="body2"
                color="text.secondary"
              >
                {maxOccupancy}
              </Typography>
            </>
          }
        />
      </ListItem>
      <Divider />
    </Box>
  );
};

export default HotelInformation;
