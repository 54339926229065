import axios from 'axios';
import { template, templateSettings } from 'lodash';

import { API } from '@common/Constants';
import { filterEmptyStrings } from '@common/helpers/helpers';
import { LOGIN_PATH } from '@common/network/ApiPaths';

const instance = axios.create();

instance.interceptors.request.use((request) => {
  const auth = JSON.parse(localStorage.getItem('auth'));
  if (request.headers.Authorization) {
    return request;
  }

  if (auth?.token) {
    request.headers.Authorization = `Bearer ${auth?.token}`;
    return request;
  }
  //for the development process
  request.headers = { 'Access-Control-Allow-Origin': 'http://localhost:3000' };
  return request;
});

/**
 * Wrap the interceptor in a function, so that it can be re-instantiated
 */
function createAxiosResponseInterceptor() {
  const interceptor = instance.interceptors.response.use(
    (response) => response,
    async (error) => {
      // Reject promise if usual error
      if (error?.response?.status !== 403) {
        return Promise.reject(error);
      }

      /*
       * When response code is 403, try to refresh the token.
       * Eject the interceptor so it doesn't loop in case
       * token refresh causes the 401 response.
       *
       * Must be re-attached later on or the token refresh will only happen once
       */
      instance.interceptors.response.eject(interceptor);

      let auth = null;
      try {
        auth = JSON.parse(localStorage.getItem('auth'));

        if (auth) {
          return postByPathAndData({
            path: LOGIN_PATH.REFRESH_TOKEN,
            data: { token: auth?.token, refreshToken: auth?.refreshToken }
          })
            .then(({ data }) => {
              localStorage.removeItem('auth');
              localStorage.setItem('auth', JSON.stringify(data));
              error.response.config.headers['Authorization'] = 'Bearer ' + data.token;
              // Retry the initial call, but with the updated token in the headers.
              // Resolves the promise if successful
              return instance(error.response.config);
            })
            .catch((refreshTokenError) => {
              localStorage.removeItem('auth');
              window.location.href = 'login';
              return Promise.reject(refreshTokenError);
            })
            .finally(createAxiosResponseInterceptor);
        }
      } catch (e) {
        window.location.href = 'login';
        auth = null;
      }
    }
  );
}
createAxiosResponseInterceptor(instance);

const baseURL = API.API_URL;

const getDefaultOptions = ({
  method = 'get',
  params = undefined,
  data = undefined,
  url = undefined,
  responseType = undefined
}) => {
  return {
    baseURL,
    method,
    responseType,
    params,
    data,
    url,
    paramsSerializer: function paramsSerializer(params) {
      return Object.entries(Object.assign({}, params))
        .map(([key, value]) => {
          if (key === 'filters') {
            return `${key}=${encodeURIComponent(JSON.stringify(value))}`;
          }
          return `${key}=${value}`;
        })
        .join('&');
    }
  };
};
// pathVariables - is object that contains key value pair that will replace :key name in path (as variable)
export const insertPathVariables = (path, pathVariables) => {
  if (!pathVariables) {
    return path;
  }
  templateSettings.interpolate = /:([A-z]*)/g;
  // replacing the ':param' with param data
  return template(path)(pathVariables);
};

// params - is object with key value pairs that will be serialized to query string
export const getByPathAndParams = ({ path = '/', params, pathVariables, responseType } = {}) => {
  return instance(
    getDefaultOptions({
      params: filterEmptyStrings(params),
      url: insertPathVariables(path, pathVariables),
      responseType
    })
  );
};

// data - is value that will be sent as payload
export const putByPathAndData = ({ path = '/', data, pathVariables } = {}) => {
  return instance(
    getDefaultOptions({ url: insertPathVariables(path, pathVariables), data, method: 'put' })
  );
};

export const patchByPathAndData = ({ path = '/', data, pathVariables } = {}) => {
  return instance(
    getDefaultOptions({
      url: insertPathVariables(path, pathVariables),
      data,
      method: 'patch'
    })
  );
};

export const postByPathAndData = ({ path = '/', data, pathVariables } = {}) => {
  return instance(
    getDefaultOptions({ url: insertPathVariables(path, pathVariables), data, method: 'post' })
  );
};

export const postAuth = ({ path = '/', loginData, pathVariables } = {}) => {
  const token = `${loginData?.userName}:${loginData?.password}`;
  const encodedToken = Buffer.from(token).toString('base64');
  const headers = {
    Authorization: `Basic ${encodedToken}`
  };
  const url = insertPathVariables(path, pathVariables);
  const method = 'post';
  return instance({
    headers,
    baseURL,
    method,
    url
  });
};

export const deleteByPath = ({ path = '/', pathVariables } = {}) => {
  return instance(
    getDefaultOptions({ url: insertPathVariables(path, pathVariables), method: 'delete' })
  );
};

// configList - is array of path strings
export const getAllByPathList = async (configList = []) => {
  const response = [];
  configList.map((path) => {
    response.push(getByPathAndParams({ path }));
  });
  return await Promise.all(response);
};
