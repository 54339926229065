const INDIVIDUAL_BUSINESS_STEPS = [
  'BusinessPartners.Steps.ChooseBusinessPartnerType',
  'BusinessPartners.Steps.NewCustomer'
];

const ACCOUNT_STEPS = [
  'BusinessPartners.Steps.ChooseBusinessPartnerType',
  'BusinessPartners.Steps.ChooseRole',
  'BusinessPartners.Steps.CreateGeneralInformation'
];

const SUPPLIER_STEPS = [
  'BusinessPartners.Steps.ChooseBusinessPartnerType',
  'BusinessPartners.Steps.ChooseRole',
  'BusinessPartners.Steps.CreateGeneralInformation',
  'BusinessPartners.Steps.ChooseSupplierType'
];

export { INDIVIDUAL_BUSINESS_STEPS, ACCOUNT_STEPS, SUPPLIER_STEPS };
