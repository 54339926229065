import Localize from 'react-intl-universal';

import moment from 'moment';
import * as yup from 'yup';

export default () => {
  return yup.object().shape({
    name: yup.string().required(Localize.get('ValidationMessages.IltSessionName')),
    startTime: yup
      .string()
      .required(Localize.get('ValidationMessages.StartTimeRequired'))
      .nullable(),
    endTime: yup
      .string()
      .required(Localize.get('ValidationMessages.EndTimeRequired'))
      .test(
        'is-after-start',
        Localize.get('ValidationMessages.EndTimeAfterStart'),
        function (value, e) {
          const { startTime } = e?.parent;
          const endTime = moment(value).toDate();
          const startTimeParsed = moment(startTime).toDate();

          return moment(endTime).isAfter(startTimeParsed);
        }
      )
      .nullable(),
    dayDate: yup
      .date()
      .required(Localize.get('ValidationMessages.DateInvalid'))
      .typeError(Localize.get('ValidationMessages.DateInvalid')),
    instructorIds: yup
      .array()
      .nullable(true)
      .required(Localize.get('ValidationMessages.Instructor'))
      .min(1, Localize.get('ValidationMessages.Instructor'))
  });
};
