import Box from '@mui/material/Box';

const CreateFooter = ({ children, position = 'fixed' }) => (
  <Box
    display="flex"
    flexDirection="row"
    justifyContent="space-between"
    position={position}
    bottom="0"
    left="0"
    right="0"
    padding="0.7rem"
    zIndex="10"
    sx={{ backgroundColor: 'primary.contrastText', pr: 4, pl: 4 }}
  >
    {children}
  </Box>
);

export default CreateFooter;
