import React from 'react';
import Localize from 'react-intl-universal';
import { useNavigate } from 'react-location';
import { useDispatch } from 'react-redux';

import { Formik } from 'formik';

import CancelIcon from '@mui/icons-material/Cancel';
import SaveIcon from '@mui/icons-material/Save';
import Button from '@mui/material/Button';

import CreateFooter from '@components/CreateFooter';
import FormContainer from '@components/FormContainer/FormContainer';
import Header from '@components/Header';
import { CreateOrgStructure } from '@pages/OrgStructures/orgStructureSlice';
import create from '@pages/OrgStructures/util/fields/create';
import initialValues from '@pages/OrgStructures/util/initialValues';
import useCreateSchema from '@pages/OrgStructures/util/schemas/useCreateSchema';

import CreateOrgStructureForm from './Form';

const NewOrgStructure = () => {
  const navigate = useNavigate();
  const [orgStructureValidationSchema] = useCreateSchema();
  const dispatch = useDispatch();

  const onSubmit = (values) => {
    dispatch(CreateOrgStructure({ ...values, venue: { id: 1 } }))
      .unwrap()
      .then(() => navigate({ to: '/org-structure', replace: true }))
      .catch();
  };

  return (
    <>
      <Header>{Localize.get('OrgStructure.NewOrgStructure')}</Header>

      <Formik
        validateOnMount
        initialValues={initialValues}
        validationSchema={orgStructureValidationSchema}
        onSubmit={onSubmit}
      >
        {({ isSubmitting, handleSubmit, isValid }) => (
          <>
            <FormContainer>
              <CreateOrgStructureForm fieldsConfig={create} />
            </FormContainer>

            <CreateFooter>
              <Button
                data-test-id="cancel-btn"
                variant="outlined"
                onClick={() => navigate({ to: '/org-structure', replace: true })}
                color="error"
                startIcon={<CancelIcon />}
              >
                {Localize.get('Buttons.Cancel')}
              </Button>

              <Button
                data-test-id="save-btn"
                variant="outlined"
                disabled={isSubmitting || !isValid}
                onClick={handleSubmit}
                startIcon={<SaveIcon />}
              >
                {Localize.get('Buttons.Save')}
              </Button>
            </CreateFooter>
          </>
        )}
      </Formik>
    </>
  );
};

export default NewOrgStructure;
