import React, { useEffect, useMemo } from 'react';
import Localize from 'react-intl-universal';
import { useDispatch, useSelector } from 'react-redux';

import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import { DataGrid, GridRowModes } from '@mui/x-data-grid';

import getLocalesText from '@common/helpers/tables/getLocalesText';
import TabContainer from '@components/TabContainer';
import TabContent from '@components/TabContent/TabContent';
import TableToolbar from '@components/TableToolbar';
import ToolbarItem from '@components/TableToolbarItem';
import { selectLocationDetails } from '@pages/BusinessPartners/businessPartnersSlice';
import {
  createAccommodations,
  fetchAccommodations,
  resetState,
  selectFilter,
  selectIsLoading,
  selectList,
  selectMode,
  selectSelectionModel,
  selectTotalElements,
  selectTotalPages,
  setCancelCreateMode,
  setFilterParams,
  setMode,
  setSelectionModel
} from '@pages/Locations/components/Tabs/AccommodationTable/accommodationTableSlice';

import RowCreate from './components/RowCreate';

export const PAGE_SIZE_OPTIONS = [5, 10, 15];

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  toolbarContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(1),
    alignItems: 'center'
  },
  toolbar: {
    minHeight: '3rem'
  },
  tableRow: {
    '&:hover': {
      backgroundColor: `${theme.palette.grey[400]} !important`,
      cursor: 'pointer'
    }
  },
  cellCheckbox: {
    padding: '5px 40px 5px 5px !important'
  },
  customTable: {
    overflowX: 'hidden',
    width: '92rem',
    '&:lastChild': {
      width: '100%',
      minWidth: 200
    }
  }
}));

const AccommodationTable = ({
  columns = [],
  entityId = null,
  entityType = null,
  toolbarOptions = []
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const data = useSelector(selectList);
  const filter = useSelector(selectFilter);
  const isLoading = useSelector(selectIsLoading);
  const totalElements = useSelector(selectTotalElements);
  const totalPages = useSelector(selectTotalPages);
  const selectionModel = useSelector(selectSelectionModel);
  const details = useSelector(selectLocationDetails);
  const mode = useSelector(selectMode);

  useEffect(() => {
    if (details?.roles[0]?.hotel?.id) {
      dispatch(fetchAccommodations({ entityId: details?.roles[0]?.hotel?.id, filter }));
    }
  }, [details.id, filter?.page, filter?.sortBy, filter?.sortDirection, filter?.size]);

  // Resets state on component destroy
  useEffect(() => {
    return () => dispatch(resetState());
  }, []);

  const isToolbarItemDisabled = (fieldName) => {
    switch (fieldName) {
      case 'add_accommodation':
        return !entityId || !entityType;
      case 'delete':
        return true; // selectionModel.length === 0;
      default:
        return false;
    }
  };

  const onToolbarItemClick = (fieldName) => {
    switch (fieldName) {
      // case 'add_participant':
      //   return !entityId || !entityType;
      case 'delete':
        return true;
      case 'add_accommodation':
        dispatch(setMode('add_accommodation'));
        break;
      case 'cancel_add_accommodation':
        dispatch(setCancelCreateMode());
        break;
      case 'save_accommodation': {
        const role = details?.roles?.find(({ hotel }) => Boolean(hotel?.id));
        if (role?.hotel?.id) {
          dispatch(createAccommodations({ entityId: role.hotel.id }));
        }
        break;
      }
      default:
        return false;
    }
  };

  const getComponents = useMemo(() => {
    if (mode === 'add_accommodation') {
      return {
        Row: ({ row, index }) => {
          if (index === 0) {
            return <RowCreate classes={classes} row={row} accommodationList={data} />;
          }
          return null;
        }
      };
    }
    return {
      Toolbar: () => <TableToolbar />
    };
  }, [mode]);

  return (
    <>
      <div className={classes.root}>
        <TabContent>
          <div className={classes.toolbarContainer}>
            <Typography variant="h6" component="h6" color="primary">
              {Localize.get('Labels.Accommodations')}
            </Typography>
            <Toolbar className={classes.toolbar}>
              {toolbarOptions
                ?.filter((option) => {
                  if (mode === 'details') {
                    return (
                      option.fieldName === 'add_accommodation' ||
                      option.fieldName === 'edit' ||
                      option.fieldName === 'delete'
                    );
                  }

                  if (mode === 'add_accommodation') {
                    return (
                      option.fieldName === 'cancel_add_accommodation' ||
                      option.fieldName === 'save_accommodation'
                    );
                  }

                  return true;
                })
                ?.map((item, index) => (
                  <ToolbarItem
                    key={index}
                    item={item}
                    isDisabled={item.disabled || isToolbarItemDisabled(item.fieldName)}
                    onToolbarItemClick={onToolbarItemClick}
                  />
                ))}
            </Toolbar>
          </div>
          <TabContainer>
            <DataGrid
              keepNonExistentRowsSelected={true}
              loading={isLoading}
              rows={
                mode === 'add_accommodation'
                  ? [
                      {
                        mode: GridRowModes.Edit,
                        id: Math.random(20)
                      },

                      ...data
                    ]
                  : data
              }
              columns={[
                ...columns.map((column) => ({
                  ...column,
                  headerName: Localize.get(column.headerName),
                  renderCell: (params) => (
                    <div data-test-id={`${params.field}-${params?.row?.id ?? 'default'}`}>
                      {['description'].includes(params.field) ? (
                        <Tooltip title={params.value} arrow>
                          <Typography
                            variant="body"
                            component="div"
                            sx={{
                              maxWidth: params?.colDef?.width,
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis'
                            }}
                          >
                            {params.value}
                          </Typography>
                        </Tooltip>
                      ) : (
                        <Typography variant="body" component="div">
                          {params.value}
                        </Typography>
                      )}
                    </div>
                  )
                }))
              ]}
              initialState={{
                pagination: {
                  pageSize: totalPages,
                  rowCount: totalElements,
                  page: filter?.page
                }
              }}
              onSelectionModelChange={(newSelectionModel) => {
                dispatch(setSelectionModel(newSelectionModel));
              }}
              selectionModel={mode === 'add_accommodation' ? [] : selectionModel}
              checkboxSelection
              localeText={getLocalesText(Localize)}
              pagination
              paginationMode="server"
              disableColumnMenu
              page={filter?.page}
              pageSize={filter?.size}
              rowCount={totalElements}
              rowsPerPageOptions={PAGE_SIZE_OPTIONS}
              disableSelectionOnClick
              components={getComponents}
              onPageChange={(page) => dispatch(setFilterParams({ page, selectionModel }))}
              onPageSizeChange={(value) =>
                dispatch(setFilterParams([{ key: 'size', value, selectionModel }]))
              }
            />
          </TabContainer>
        </TabContent>
      </div>
    </>
  );
};

export default AccommodationTable;
