export const formatLaunchpadData = (data, prevState) => {
  let currentState = { ...prevState };
  return {
    ...currentState,
    planning: currentState.planning.map((tile) => {
      return switchValues(tile, data[tile.fieldName]);
    }),
    masterData: currentState.masterData.map((tile) => {
      return switchValues(tile, data[tile.fieldName]);
    }),
    transactionalData: currentState.transactionalData.map((tile) => {
      return switchValues(tile, data[tile.fieldName]);
    })
  };
};

const switchValues = (tile, newValue) => {
  if (tile.fieldName && tile.fieldName === 'eventsByStatus') {
    const eFormatedObj = formatResObjects(newValue, 'data');
    tile.dataset = eFormatedObj.dataset;
    tile.iconNumber = eFormatedObj.counter;
    return tile;
  } else if (tile.fieldName && tile.fieldName === 'participantsByStatus') {
    const pFormatedObj = formatResObjects(newValue, 'users');
    tile.dataset = pFormatedObj.dataset;
    tile.iconNumber = pFormatedObj.counter;
    return tile;
  } else if (tile.fieldName && tile.fieldName === 'eventsByWeekNo') {
    const ebwFormatedObj = formatResObjects(newValue, 'events', true);
    tile.dataset = ebwFormatedObj.dataset;
    tile.iconNumber = ebwFormatedObj.counter;
    return tile;
  } else if (tile.fieldName && tile.fieldName === 'activitiesByType') {
    // TODO: Find a better solution
    tile.iconNumber = newValue.Appointment || newValue.Termin || 0;
    tile.iconNumberAbove = newValue.Task || newValue.Aufgabe || 0;
    return tile;
  } else if (tile.fieldName) {
    tile.iconNumber = newValue;
    return tile;
  } else {
    return tile;
  }
};

const formatResObjects = (myRes, accessor, countOnlyFirst = false) => {
  let counter = 0;
  let providedKeys = Object.keys(myRes);
  let dataset = [];
  providedKeys.forEach((providedKey, index) => {
    if (countOnlyFirst) {
      if (index === 0) counter = parseInt(myRes[providedKey]);
    } else {
      counter = counter + parseInt(myRes[providedKey]);
    }

    dataset.push({ name: providedKey, [accessor]: myRes[providedKey] });
  });

  return { dataset, counter };
};
