import AccountTypes from '@common/network/AccountTypes';
import { DIALOG_PATHS } from '@common/network/ApiPaths';

export default [
  {
    groupName: 'Labels.Information',
    fields: [
      {
        label: 'Labels.Title',
        name: 'person.title.id',
        required: false,
        disabled: false,
        component: 'select',
        options: [
          { id: 0, value: 'None' },
          { id: 1, value: 'Mrs' },
          { id: 2, value: 'Mr' },
          { id: 3, value: 'Ms' }
        ]
      },
      {
        label: 'ContactsTile.FirstName',
        name: 'person.firstName',
        required: true,
        disabled: false,
        component: 'text'
      },
      {
        label: 'ContactsTile.LastName',
        name: 'person.lastName',
        required: true,
        disabled: false,
        component: 'text'
      },
      {
        label: 'ContactsTile.JobTitle',
        name: 'jobTitle',
        required: false,
        disabled: false,
        component: 'text'
      },
      {
        label: 'ContactsTile.Function',
        name: 'function',
        required: false,
        disabled: false,
        component: 'text'
      },
      {
        label: 'ContactsTile.Department',
        name: 'department',
        required: false,
        disabled: false,
        component: 'text'
      },
      {
        label: 'ContactsTile.AccountName',
        name: 'organizationPerson.id',
        required: true,
        disabled: false,
        dialogTitle: 'ContactsTile.SearchAccount',
        component: 'tokenValue',
        value: '',
        initQueryState: {
          name: '',
          id: '',
          city: '',
          country: '',
          rating: '',
          region: '',
          type: AccountTypes.PERSON
        },
        fetchDataPath: DIALOG_PATHS.ACCOUNTS
      }
    ]
  },
  {
    groupName: 'Personal.Title',
    fields: [
      {
        label: 'Personal.BirthDate',
        name: 'person.birthDate',
        required: false,
        disabled: false,
        component: 'datepicker'
      },
      {
        label: 'Personal.BirthPlace',
        name: 'person.birthPlace',
        required: false,
        disabled: false,
        component: 'text'
      }
    ]
  },
  {
    groupName: 'Communication.Email',
    arrayName: 'emailDtos',
    fields: [
      {
        label: 'Labels.Type',
        name: 'type.id',
        required: false,
        disabled: false,
        component: 'select'
      },
      {
        label: 'Communication.Email',
        name: 'email',
        required: true,
        disabled: false,
        component: 'text'
      }
    ]
  },
  {
    groupName: 'Address.Title',
    arrayName: 'addressDtos',
    fields: [
      {
        label: 'Labels.Type',
        name: 'type.id',
        required: false,
        disabled: false,
        component: 'select'
      },
      {
        label: 'Address.Street',
        name: 'street',
        required: false,
        disabled: false,
        component: 'text'
      },
      {
        label: 'Address.Number',
        name: 'number',
        required: false,
        disabled: false,
        component: 'text'
      },
      {
        label: 'Address.PostalCode',
        name: 'postalCode',
        required: false,
        disabled: false,
        component: 'text'
      },
      {
        label: 'Address.State',
        name: 'state',
        required: false,
        disabled: false,
        component: 'text'
      },
      {
        label: 'Address.City',
        name: 'city',
        required: false,
        disabled: false,
        component: 'text'
      },
      {
        label: 'Address.POBox',
        name: 'poBox',
        required: false,
        disabled: false,
        component: 'text'
      },
      {
        label: 'Address.Country',
        name: 'country',
        required: true,
        disabled: false,
        component: 'autocomplete'
      },
      {
        label: 'Address.Region',
        name: 'region',
        required: false,
        disabled: false,
        component: 'autocomplete'
      }
    ]
  },
  {
    groupName: 'Communication.Title',
    arrayName: 'phoneDtos',
    fields: [
      {
        label: 'Labels.Type',
        name: 'type.id',
        required: false,
        disabled: false,
        component: 'select'
      },
      {
        label: 'Labels.Phone',
        name: 'phone',
        required: true,
        disabled: false,
        component: 'text'
      }
    ]
  }
];
