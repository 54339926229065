import { isEqual, reduce } from 'lodash';

const isObject = (a) => a != null && a.constructor === Object;
const isArray = (a) => Array.isArray(a);
const isNumber = (a) => !isNaN(a);
const isString = (a) => typeof a === 'string';
const isBool = (a) => typeof a === 'boolean';
const isInteger = (n) => Number(n) === n && n % 1 === 0;
const isFloat = (n) => Number(n) === n && n % 1 !== 0;
const isDate = (date) => {
  if (date === null) return false;
  return new Date(date) !== 'Invalid Date' && !isNaN(new Date(date));
};

// update fields for a nested array
const jsonFieldsUpdater = (fields, updatefields) => {
  fields.forEach(function iter(a) {
    if (Array.isArray(a.fields)) {
      a.fields.forEach(function iter(b) {
        updatefields.map((updateField) =>
          updateField.includes(b.propName) ? (b.disabled = !b.disabled) : b.disabled
        );
      });
    }
    Array.isArray(a.children) && a.children.forEach(iter);
  });
};

const filterEmptyStrings = (filterObject) => {
  if (!filterObject) return;
  let checkedObj = { ...filterObject };
  // eslint-disable-next-line array-callback-return
  Object.keys(checkedObj).map((column) => {
    if (
      checkedObj[column] === undefined ||
      checkedObj[column] === null ||
      checkedObj[column] === ''
    )
      delete checkedObj[column];
  });
  return checkedObj;
};

const copyObjectWithoutRef = (providedObj) => JSON.parse(JSON.stringify(providedObj));

const compareListOfObjectsForEdit = (newList = [], oldList = [], defaultProps = {}) => {
  if (newList?.length === 0) return [];
  let myNewList = copyObjectWithoutRef(newList);
  return myNewList.map((itemObject) => {
    if (isObject(itemObject)) {
      let sameObjectButOld = oldList.find((item) => isObject(item) && item.id === itemObject.id);
      if (sameObjectButOld) {
        if (!isEqual(itemObject, sameObjectButOld)) {
          if (itemObject?.id) delete itemObject.id;
        }
      } else {
        if (itemObject?.id) delete itemObject.id;
      }
      Object.keys(defaultProps).map((propName) => {
        if (!itemObject[propName]) {
          itemObject[propName] = defaultProps[propName];
        }
      });
    }

    return itemObject;
  });
};

const checkChangedFields = (a, b) => {
  let changedFieldsKeys = reduce(
    a,
    function (result, value, key) {
      return isEqual(value, b[key]) ? result : result.concat(key);
    },
    []
  );
  let obj = {};
  changedFieldsKeys.map((key) => {
    obj[key] = b[key];
  });
  return obj;
};

export {
  copyObjectWithoutRef,
  filterEmptyStrings,
  jsonFieldsUpdater,
  isObject,
  isArray,
  isNumber,
  isString,
  isBool,
  isInteger,
  isFloat,
  isDate,
  compareListOfObjectsForEdit,
  checkChangedFields
};
