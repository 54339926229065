import Localize from 'react-intl-universal';

import * as yup from 'yup';

export default () => {
  let createValidationSchema = yup.object().shape({
    name: yup.string().required(Localize.get('ValidationMessages.NameRequired')),
    parentUnitId: yup
      .number()
      .min(1, Localize.get('ValidationMessages.ParentUnitMustBePositive'))
      .integer(),
    parentUnitName: yup.string().notRequired()
  });

  return [createValidationSchema];
};
