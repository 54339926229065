import moment from 'moment';

export default {
  name: '',
  statusId: 1,
  typeId: null,
  minParticipants: 0,
  optParticipants: 0,
  maxParticipants: '',
  startDateTime: moment(),
  endDateTime: moment().add(30, 'm'),
  registrationDueDate: '',
  venues: [],
  orderNumberInGroup: '',
  nativeLanguageId: 1,
  isPartnerEvent: false,
  hasNameTagPrinting: false,
  hasAutomaticConfirmation: false,
  hasWaitingList: false,
  isPrivate: false,
  isMeaPublished: false,
  isWebPublished: false,
  isFullyBooked: false,
  description: '',
  remarks: '',
  city: ''
};
