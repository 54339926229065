import React from 'react';
import Localize from 'react-intl-universal';

import CancelIcon from '@mui/icons-material/Cancel';
import SaveIcon from '@mui/icons-material/Save';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography
} from '@mui/material';
import { Box } from '@mui/system';

const PdfPreviewDialog = ({
  title = '',
  fullScreen = true,
  isOpen = false,
  onCancel = null,
  onSave = null,
  src = null
}) => {
  return (
    <Dialog fullWidth fullScreen={fullScreen} maxWidth={'md'} open={isOpen}>
      <DialogTitle
        sx={{
          boxShadow: 3,
          marginBottom: '1rem',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between'
        }}
      >
        <Typography data-test-id="pdf-preview-title">{title}</Typography>
      </DialogTitle>

      <DialogContent sx={{ pt: '1rem!important' }}>
        <Box sx={{ minHeight: 400 }}>
          <iframe
            style={{ height: '100vh', width: '100%' }}
            title="pdf-preview"
            src={src}
            frameBorder="0"
            scrolling="auto"
          ></iframe>
        </Box>
      </DialogContent>

      <DialogActions
        sx={{
          backgroundColor: 'primary.contrastText',
          justifyContent: 'space-between'
        }}
      >
        <Button
          disableFocusRipple
          variant="outlined"
          startIcon={<CancelIcon />}
          data-test-id="pdf-preview-cancel"
          onClick={onCancel}
          color="error"
        >
          {Localize.get('Buttons.Cancel')}
        </Button>
        <Button
          disableFocusRipple
          variant="outlined"
          startIcon={<SaveIcon />}
          data-test-id="pdf-preview-save"
          onClick={onSave}
        >
          {Localize.get('Buttons.Save')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PdfPreviewDialog;
