import { Navigate } from 'react-location';

import { SETTINGS_PATH } from '@common/network/ApiPaths';
import EntityTypes from '@common/network/EntityTypes';
import Resources, { CreateResource } from '@pages/Resources';
import { getByPathAndParams } from '@services/BaseApi';

const ResourceRoute = (routeCache) => ({
  path: 'resources',
  children: [
    {
      path: '/',
      element: <Resources />,
      loader: routeCache.createLoader(
        () => ({
          config: getByPathAndParams({
            path: SETTINGS_PATH.GET,
            pathVariables: { tile: EntityTypes.RESOURCE }
          })
        }),
        { policy: 'cache-first' }
      )
    },
    {
      path: 'new',
      element: <CreateResource />
    },
    {
      element: <Navigate to="/resources" />
    }
  ]
});

export default ResourceRoute;
