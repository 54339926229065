import { useEffect, useReducer } from 'react';
import Localize from 'react-intl-universal';
import { useDispatch } from 'react-redux';

import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import { DataGrid } from '@mui/x-data-grid';

import getLocalesText from '@common/helpers/tables/getLocalesText';
import { CONFIRM_ACTIONS, openConfirmDialog } from '@components/ConfirmDialog';
import TabContainer from '@components/TabContainer';
import TabContent from '@components/TabContent/TabContent';
import TableToolbar from '@components/TableToolbar';
import ToolbarItem from '@components/TableToolbarItem';

import {
  getSessions,
  closeSessionsDialog,
  openSessionsDialog,
  setSelectionModel,
  updateSessions,
  removeSessions
} from '../store/actions';
import { sessionsReducer, sessionsState } from '../store/reducer';
import SessionsDialog from './SessionsDialog';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  toolbarContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(1),
    alignItems: 'center'
  },
  toolbar: {
    minHeight: '3rem'
  },
  container: {
    height: 400,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  tableRow: {
    '&:hover': {
      backgroundColor: `${theme.palette.grey[400]} !important`,
      cursor: 'pointer'
    }
  }
}));

const SessionsTable = ({
  columns = [],
  eventId = null,
  entityId = null,
  entityType = null,
  toolbarOptions = [],
  setDetails = () => {}
}) => {
  const classes = useStyles();
  const reduxDispatch = useDispatch();

  const [{ isLoading, data, selectionModel, isSearchDialogOpen, totalPages = 0 }, dispatch] =
    useReducer(sessionsReducer, sessionsState);

  useEffect(() => {
    if (entityId && entityType) {
      getSessions(dispatch, entityId, entityType);
    }
  }, [entityId, entityType]);

  const onToolbarItemClick = (fieldName) => {
    switch (fieldName) {
      case 'add_sessions':
        dispatch(openSessionsDialog());
        return;
      case 'delete': {
        reduxDispatch(
          openConfirmDialog({
            title: Localize.get('Participants.DeleteMsg'),
            confirmButton: Localize.get('Buttons.Delete'),
            cancelButton: Localize.get('Buttons.Cancel'),
            disabler: { confirm: isLoading, cancel: isLoading }
          })
        )
          .unwrap()
          .then((result) => {
            if (result === CONFIRM_ACTIONS.Confirm) {
              removeConfirmed();
            }
          });

        return;
      }
      default:
        return false;
    }
  };

  const isToolbarItemDisabled = (fieldName) => {
    switch (fieldName) {
      case 'add_sessions':
        return !entityId || !entityType;
      case 'delete':
        return selectionModel.length === 0;
      default:
        return false;
    }
  };

  const saveSessions = (value) => {
    if (!value) {
      dispatch(closeSessionsDialog());
      return;
    }
    updateSessions(dispatch, reduxDispatch, value, entityId, setDetails);
  };

  const removeConfirmed = () => {
    if (!entityId && !selectionModel.length) {
      dispatch(closeSessionsDialog());
      return;
    }
    removeSessions(dispatch, reduxDispatch, entityId, selectionModel, setDetails);
  };

  return (
    <div className={classes.root}>
      <TabContent>
        <div className={classes.toolbarContainer}>
          <Typography variant="h6" component="h2" color="primary">
            {Localize.get('Labels.Sessions')}
          </Typography>
          <Toolbar className={classes.toolbar}>
            {toolbarOptions?.map((item, index) => (
              <ToolbarItem
                data-test-id={`ToolbarItem-session-${index}`}
                key={index}
                item={item}
                isDisabled={item.disabled || isToolbarItemDisabled(item.fieldName)}
                onToolbarItemClick={onToolbarItemClick}
              />
            ))}
          </Toolbar>
        </div>
        <TabContainer>
          <DataGrid
            rowCount={totalPages}
            loading={isLoading}
            rows={data}
            disableColumnMenu
            filterMode="server"
            columns={columns}
            getRowId={(params) => params?.session?.id ?? ''}
            getRowClassName={() => classes.tableRow}
            hideFooterPagination
            checkboxSelection
            disableSelectionOnClick
            components={{ Toolbar: () => <TableToolbar /> }}
            onSelectionModelChange={(values) => dispatch(setSelectionModel(values))}
            localeText={getLocalesText(Localize)}
          />
        </TabContainer>
      </TabContent>

      <SessionsDialog
        selectedSessionIds={data.map((el) => el.session.id)}
        eventId={eventId}
        open={isSearchDialogOpen}
        saveSessions={saveSessions}
        onClose={() => dispatch(closeSessionsDialog())}
        disabler={{ confirm: isLoading, cancel: isLoading }}
      />
    </div>
  );
};

export default SessionsTable;
