import Localize from 'react-intl-universal';

import moment from 'moment';
import * as yup from 'yup';

export const pricesSchema = () =>
  yup.object().shape({
    price: yup
      .number()
      .typeError(Localize.get('ValidationMessages.PriceRequired'))
      .min(1, Localize.get('ValidationMessages.PositiveNumber'))
      .required(Localize.get('ValidationMessages.PriceRequired')),
    validFrom: yup
      .string()
      .required(Localize.get('ValidationMessages.ValidFromRequired'))
      .nullable(),
    validTo: yup
      .string()
      .required(Localize.get('ValidationMessages.ValidToRequired'))
      .test(
        'is-after-start',
        Localize.get('ValidationMessages.ValidToAfterValidFrom'),
        function (value, e) {
          const { validFrom } = e?.parent;
          const validTo = moment(value).toDate();
          const validFromParsed = moment(validFrom).toDate();

          return moment(validTo).isAfter(validFromParsed);
        }
      )
      .nullable()
  });
