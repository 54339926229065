import { copyObjectWithoutRef } from '@common/helpers/helpers';

// initial object for creating new resource
const initResource = {
  name: '',
  resourceCategory: null,
  glAccount: null,
  irrAmount: false,
  availableAmount: '',
  consumableItem: true,
  giftWarehouse: null,
  billableItem: false,
  price: '',
  currency: null,
  costCentre: null
};

const formatResourceForSave = (postData) => {
  let resourceForSave = copyObjectWithoutRef(postData);
  return {
    name: resourceForSave?.name,
    irrAmount: resourceForSave?.irrAmount,
    consumableItem: resourceForSave?.consumableItem,
    billableItem: resourceForSave?.billableItem,
    costCentreId: resourceForSave?.costCentreId,
    property: null,
    permanentlyInstalled: null,
    availableAmount: resourceForSave.availableAmount
      ? parseInt(resourceForSave.availableAmount)
      : null,
    price: resourceForSave.price ? resourceForSave.price : null,
    resourceCategoryId: resourceForSave?.resourceCategory?.id,
    glAccountId: resourceForSave?.glAccount?.id,
    giftWarehouseId: resourceForSave?.giftWarehouse?.id,
    currencyId: resourceForSave?.currency?.id
  };
};

const formatResourceForDetails = (res) => {
  return {
    ...res,
    price: res?.price ?? '',
    availableAmount: res.availableAmount ? res.availableAmount : ''
  };
};

export { formatResourceForSave, initResource, formatResourceForDetails };
