import Localize from 'react-intl-universal';

import * as yup from 'yup';

export default function () {
  return yup.object().shape({
    currency: yup
      .object()
      .shape({
        id: yup.number().nullable(true),
        value: yup.string()
      })
      .nullable(true),
    validUntil: yup
      .date()
      .max(yup.ref('startDate'), Localize.get('ValidationMessages.ValidUntilBeforeStartDate'))
      .nullable(),
    startDate: yup
      .date()
      .required(Localize.get('ValidationMessages.StartDate'))
      .typeError(Localize.get('ValidationMessages.DateTimeInvalid')),
    endDate: yup
      .date()
      .min(yup.ref('startDate'), Localize.get('ValidationMessages.EndDateStartDate'))
      .typeError(Localize.get('ValidationMessages.DateTimeInvalid'))
      .required(Localize.get('ValidationMessages.EndDate')),
    price: yup
      .number()
      .typeError(Localize.get('ValidationMessages.PriceRequired'))
      .min(1, Localize.get('ValidationMessages.PositiveNumber'))
      .required(Localize.get('ValidationMessages.PriceRequired')),
    contingent: yup
      .number()
      .typeError(Localize.get('ValidationMessages.ContingentAmount'))
      .min(1, Localize.get('ValidationMessages.PositiveNumber'))
      .required(Localize.get('ValidationMessages.ContingentAmount')),
    accommodation: yup
      .object()
      .required(Localize.get('ValidationMessages.RoomTypeRequired'))
      .nullable()
  });
}
