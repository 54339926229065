import React from 'react';
import ReactDOM from 'react-dom';

import ReduxProvider from '@common/providers/Redux';
import ThemeProvider from '@common/providers/Theme';

import App from './App';

ReactDOM.render(
  <ReduxProvider>
    <ThemeProvider>
      <App />
    </ThemeProvider>
  </ReduxProvider>,
  document.getElementById('root')
);
