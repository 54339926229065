import React from 'react';

import NoData from '@components/NoData';
import Tabs from '@components/Tabs';
import tabsConfig, {
  ACTIVITIES_TABS_COMPONENTS
} from '@pages/Activities/components/Tabs/tabsConfig';

const ActivitiesTabs = ({
  entityId = null,
  entityType = null,
  details = null,
  setDetails = () => {}
}) => {
  return (
    <Tabs
      tabsConfig={tabsConfig}
      count={details?.counts || {}}
      renderContent={(tab) => {
        const DynamicComponent = ACTIVITIES_TABS_COMPONENTS[tab?.key];
        return DynamicComponent ? (
          <DynamicComponent
            entityId={entityId}
            entityType={entityType}
            columns={tab.columns}
            toolbarOptions={tab.options}
            allowedEntityId={8}
            setDetails={setDetails}
          />
        ) : (
          <NoData />
        );
      }}
    />
  );
};

export default ActivitiesTabs;
