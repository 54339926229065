export default [
  {
    groupName: 'Labels.Information',
    fields: [
      {
        label: 'Sessions.Name',
        name: 'name',
        required: true,
        disabled: false,
        component: 'text'
      },
      {
        label: 'Sessions.SessionDescription',
        name: 'description',
        required: false,
        disabled: false,
        component: 'text'
      },
      {
        label: 'Sessions.Type',
        name: 'type',
        required: true,
        disabled: false,
        disableClearable: true,
        component: 'autocomplete'
      },
      {
        label: 'Sessions.ParticipantMax',
        name: 'maxParticipants',
        required: true,
        disabled: false,
        component: 'text',
        type: 'number'
      },
      {
        label: 'Sessions.ParticipantOpt',
        name: 'optParticipants',
        required: false,
        disabled: false,
        component: 'text',
        type: 'number'
      },
      {
        label: 'Sessions.ParticipantMin',
        name: 'minParticipants',
        required: false,
        disabled: false,
        component: 'text',
        type: 'number'
      }
    ]
  },
  {
    groupName: 'Events.EssentialInformation',
    fields: [
      {
        label: 'Labels.Date',
        name: 'timeBlockDate',
        required: true,
        disabled: false,
        component: 'datepicker'
      },
      {
        label: 'Sessions.TimeFrom',
        name: 'timeFrom',
        required: true,
        disabled: false,
        component: 'timepicker'
      },
      {
        label: 'Sessions.TimeTo',
        name: 'timeTo',
        required: true,
        disabled: false,
        component: 'timepicker'
      }
    ]
  }
];
