/**
 * @param {Array} fieldsConfig - fields configuration which should be extended with new props
 * @param {Object} propsToMap - object which will be used to map on configuration fields.
 * Keys of object should match name property from fields array from the configuration.
 * Value is object with props which will be mapped to that field.
 * @returns {Array} fieldsConfig - new fields configuration which will be passed to form component
 * @example mapPropsToComponent(fieldConfiguration, {['name']: {options: ['Item1', 'Item2']}})
 */

export const mapPropsToArrayItem = (fields, propsToMap = {}) => {
  if (!fields.length) return [];

  return fields.map((field, index) => {
    if (propsToMap[field?.name]) {
      return { ...field, ...propsToMap[field.name], index };
    }
    return field;
  });
};

const mapPropsToComponent = (fieldsConfig, propsToMap = {}) => {
  if (!fieldsConfig.length) {
    return [];
  }

  if (!propsToMap) {
    return fieldsConfig;
  }

  return fieldsConfig.map((config) => {
    const { arrayName, name, fields } = config;

    if (arrayName) {
      return {
        ...config,
        ...propsToMap[arrayName],
        fields: fields.map((field, index) => {
          if (propsToMap[config?.arrayName] && propsToMap[config?.arrayName][field.name]) {
            return { ...field, ...propsToMap[config?.arrayName][field.name], index };
          }
          return field;
        })
      };
    }

    // Check for custom props for group configuration
    if (propsToMap[name]) {
      return {
        ...config,
        ...propsToMap[name],
        fields: fields.map((field) => {
          if (propsToMap[field.name]) {
            return { ...field, ...propsToMap[field.name] };
          }
          return field;
        })
      };
    }

    return {
      ...config,
      fields: fields.map((field) => {
        if (propsToMap[field.name]) {
          return { ...field, ...propsToMap[field.name] };
        }
        return field;
      })
    };
  });
};

export default mapPropsToComponent;
