import { useEffect, useReducer } from 'react';
import Localize from 'react-intl-universal';
import { useNavigate, useSearch } from 'react-location';
import { useDispatch, useSelector } from 'react-redux';

import Link from '@mui/material/Link';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import { DataGrid } from '@mui/x-data-grid';

import getLocalesText from '@common/helpers/tables/getLocalesText';
import { saveState } from '@common/storage/persistSlice';
import TabContainer from '@components/TabContainer';
import TabContent from '@components/TabContent/TabContent';
import TableToolbar from '@components/TableToolbar';
import ToolbarItem from '@components/TableToolbarItem';
import { BACKEND_FILTER_VALUES } from '@config/filterOperators';
import { CreateSession } from '@pages/Events/components/Sessions';
import { selectDetails, selectFilter } from '@pages/Events/eventsSlice';

import { setSelectionModel, filterUpdate, getSessions } from './store/actions';
import { sessionsReducer, sessionsState, PAGE_SIZE_OPTIONS } from './store/reducer';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  toolbarContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(1),
    alignItems: 'center'
  },
  toolbar: {
    minHeight: '3rem'
  },
  container: {
    height: 400,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  tableRow: {
    '&:hover': {
      backgroundColor: `${theme.palette.grey[400]} !important`,
      cursor: 'pointer'
    }
  }
}));

const SessionsTable = ({
  columns = [],
  entityId = null,
  entityType = null,
  toolbarOptions = []
}) => {
  const search = useSearch();
  const classes = useStyles();
  const reduxDispatch = useDispatch();
  const navigate = useNavigate();
  const eventFilter = useSelector(selectFilter);
  const details = useSelector(selectDetails);

  const [{ isLoading, data, filter, totalElements, selectionModel }, dispatch] = useReducer(
    sessionsReducer,
    sessionsState
  );
  useEffect(() => {
    if (entityId && entityType) {
      getSessions(dispatch, entityId, filter);
    }
  }, [entityId, filter?.size, filter?.page, filter?.searchValue, filter?.match, filter?.search]);

  const onRowClick = ({ id }) => {
    reduxDispatch(saveState({ filter: eventFilter, selectedId: entityId }));
    navigate({ to: `/events/${entityId}/session-details/${id}`, replace: false });
  };

  const onToolbarItemClick = (fieldName, files) => {
    switch (fieldName) {
      case 'add_sessions':
        if (files.length > 1) {
          alert('Batch upload currently not supported');
          return;
        }

        reduxDispatch(saveState({ filter: eventFilter, selectedId: entityId }));
        navigate({ search: (previousUrlParams) => ({ ...previousUrlParams, mode: 'create' }) });

        return;
      case 'delete': {
        if (selectionModel.length > 1) {
          alert('Batch delete currently not supported');
          return;
        }

        // reduxDispatch(
        //   openConfirmDialog({
        //     title: Localize.get('ConfirmationMessages.Delete', {
        //       item: Localize.get('Labels.Attachment')?.toLowerCase()
        //     }),
        //     confirmButton: Localize.get('Buttons.Delete'),
        //     cancelButton: Localize.get('Buttons.Cancel')
        //   })
        // )
        //   .unwrap()
        //   .then((result) => {
        //     if (result === CONFIRM_ACTIONS.Confirm) {
        //       removeAttachments(
        //         dispatch,
        //         reduxDispatch,
        //         entityId,
        //         entityType,
        //         selectionModel[0],
        //         setDetails
        //       );
        //     }
        //   });

        return;
      }

      default:
        return false;
    }
  };

  const isToolbarItemDisabled = (fieldName) => {
    switch (fieldName) {
      case 'add_sessions':
        return !entityId || !entityType;
      case 'delete':
        return true; //selectionModel.length === 0;
      default:
        return false;
    }
  };

  return (
    <div className={classes.root}>
      {search?.mode === 'create' ? (
        <CreateSession event={details} />
      ) : (
        <TabContent>
          <div className={classes.toolbarContainer}>
            <Typography variant="h6" component="h6" color="primary">
              {Localize.get('Labels.Sessions')}
            </Typography>
            <Toolbar className={classes.toolbar}>
              {toolbarOptions?.map((item, index) => (
                <ToolbarItem
                  key={index}
                  item={item}
                  isDisabled={item.disabled || isToolbarItemDisabled(item.fieldName)}
                  onToolbarItemClick={onToolbarItemClick}
                />
              ))}
            </Toolbar>
          </div>
          <TabContainer>
            <DataGrid
              loading={isLoading}
              rows={data}
              getRowClassName={() => classes.tableRow}
              columns={[
                ...columns.map((column) => ({
                  ...column,
                  headerName: Localize.get(column.headerName),
                  renderCell: (params) => (
                    <div data-test-id={`${params.field}-${params?.row?.id ?? 'default'}`}>
                      {['name'].includes(params.field) ? (
                        <Tooltip title={params.value} arrow>
                          <Link
                            sx={{
                              display: 'block',
                              cursor: 'pointer',
                              maxWidth: params?.colDef?.width,
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis'
                            }}
                            onClick={() => onRowClick(params)}
                          >
                            {params?.value}
                          </Link>
                        </Tooltip>
                      ) : (
                        <Typography variant="body" component="div">
                          {params.value}
                        </Typography>
                      )}
                    </div>
                  )
                }))
              ]}
              localeText={getLocalesText(Localize)}
              paginationMode="server"
              sortingMode="server"
              checkboxSelection
              disableColumnMenu
              page={filter?.page}
              pageSize={filter?.size}
              rowCount={totalElements}
              rowsPerPageOptions={PAGE_SIZE_OPTIONS}
              disableSelectionOnClick
              components={{ Toolbar: TableToolbar }}
              onSelectionModelChange={(values) => dispatch(setSelectionModel(values))}
              onPageChange={(value) =>
                dispatch(filterUpdate({ type: 'page', value: { ...filter, page: value } }))
              }
              onPageSizeChange={(value) =>
                dispatch(filterUpdate({ type: 'page', value: { ...filter, size: value } }))
              }
              onFilterModelChange={(values) => {
                // For now only one filter can be passed to BE
                const activeFilter = values.items[0];
                if (!activeFilter) {
                  return;
                }

                dispatch(
                  filterUpdate({
                    type: 'filter',
                    value: {
                      search: activeFilter.columnField,
                      searchValue: activeFilter.value,
                      match: BACKEND_FILTER_VALUES[activeFilter.operatorValue] // map with backend values
                    }
                  })
                );
              }}
            />
          </TabContainer>
        </TabContent>
      )}
    </div>
  );
};

export default SessionsTable;
