import { get, isUndefined } from 'lodash';

/**
 * Extension of lodash get function which accepts array of paths which will be derived from specified data object.
 * @param {Object} obj - object from which paths will be derived
 * @param {Array} paths - array of fields which will be taken from obj.
 * @param {String} defaultValue - default value for specific field in path array.
 * @returns {Array} fieldsConfig - new fields configuration which will be passed to form component
 * @example getMultiple(data, ['firstName', 'lastName'], '');
 */
export const getMultiple = (obj, paths, defaultValue) => {
  if (!Array.isArray(paths)) {
    return;
  }

  const values = paths.map((path) => get(obj, path)).filter((v) => !isUndefined(v));

  return values.length ? values : [defaultValue];
};
