export default [
  {
    groupName: 'Labels.Information',
    fields: [
      {
        label: 'Labels.Location',
        name: 'appointmentDto.location',
        required: false,
        disabled: false,
        component: 'text'
      },
      {
        label: 'Labels.Category',
        name: 'appointmentDto.appointmentCategory',
        required: false,
        disabled: false,
        component: 'autocomplete'
      }
    ]
  }
];
