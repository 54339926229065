import React from 'react';
import { useSearch } from 'react-location';

import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

import { useAppStyles } from '@navigation/AppRouter';

const StyledHeader = styled(Box)(
  ({
    theme,
    flexDirection = 'row',
    justifyContent = 'center',
    alignItems = 'center',
    alignContent = 'center',
    display = 'flex'
  }) => ({
    left: 0,
    right: 0,
    padding: '1rem',
    color: theme.palette.action.active,
    backgroundColor: theme.palette.primary.contrastText,
    fontWeight: 600,
    height: theme.sizes.subheader,
    minHeight: theme.sizes.subheader,
    maxHeight: theme.sizes.subheader,
    zIndex: 1,
    flexDirection: flexDirection,
    justifyContent: justifyContent,
    alignItems: alignItems,
    alignContent: alignContent,
    display: display
  })
);

const Header = ({ children, ...rest }) => {
  const search = useSearch();
  const appStyles = useAppStyles({ isBlur: search?.mode === 'create' });

  return (
    <StyledHeader className={appStyles.blur} sx={{ boxShadow: 3 }} {...rest}>
      {children}
    </StyledHeader>
  );
};

export default Header;
