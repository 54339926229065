import React, { Fragment } from 'react';
import Localize from 'react-intl-universal';
import { useSelector } from 'react-redux';

import { Field, Form } from 'formik';

import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import FormArray from '@components/FormArray';
import { selectMode } from '@components/RightSidebar/rightSidebarSlice';
import FormComponentsMap from '@config/FormComponentsMap';

const StyledForm = styled(Form)(({ theme }) => ({
  overflowY: 'auto',
  top: theme.sizes.appHeader,
  maxHeight: `calc(100vh - ${theme.sizes.appHeader} - ${theme.sizes.masterListFooter} - ${theme.sizes.subheader})`,
  minHeight: `calc(100vh - ${theme.sizes.appHeader} - ${theme.sizes.masterListFooter} - ${theme.sizes.subheader})`
}));

const StyledContainer = styled('div')(({ theme }) => ({
  position: 'relative',
  marginBottom: theme.spacing(1)
}));

export const MAX_COLUMN_WIDTH = 12;

const RightSidebarForm = ({
  fieldsConfig = [],
  isSubmitting = false,
  values,
  mapPropsToAddress
}) => {
  const mode = useSelector(selectMode);

  return (
    <StyledForm>
      {Boolean(fieldsConfig.length) &&
        fieldsConfig.map(
          (
            {
              groupName = 'Labels.Information',
              isVisible = true,
              fields = [],
              arrayName = '',
              arrayConfig = null
            },
            index
          ) =>
            isVisible && (
              <Fragment key={index}>
                <StyledContainer>
                  <Typography
                    component="h5"
                    variant="h5"
                    color="text.secondary"
                    sx={{ ml: 2, mb: 3, mt: 4 }}
                  >
                    {Localize.get(groupName)}
                  </Typography>

                  {arrayName ? (
                    <FormArray
                      mapPropsToAddress={mapPropsToAddress}
                      arrayName={arrayName}
                      values={values}
                      fields={fields}
                      isSubmitting={isSubmitting}
                      context="edit"
                      arrayConfig={arrayConfig}
                    />
                  ) : (
                    <Grid container rowSpacing={3} sx={{ pl: 2, pr: 1 }}>
                      {fields?.map(
                        (
                          {
                            component,
                            label,
                            disabled,
                            isVisible = true,
                            fieldWidth = MAX_COLUMN_WIDTH,
                            ...rest
                          },
                          fieldIndex
                        ) =>
                          isVisible && (
                            <Grid item sm={fieldWidth} key={`${index}-${fieldIndex}`}>
                              <FormControl size="small" fullWidth>
                                <Field
                                  {...rest}
                                  disabled={disabled || mode === 'read'}
                                  label={Localize.get(label)}
                                  component={FormComponentsMap[component]}
                                />
                              </FormControl>
                            </Grid>
                          )
                      )}
                    </Grid>
                  )}
                </StyledContainer>
              </Fragment>
            )
        )}
    </StyledForm>
  );
};

export default RightSidebarForm;
