// Available filter values supported on BE:
// Attachments Tab - Filter by fields: name, createdAt
// Notes Tab - Filter by fields: text, type
// Accommodations Tab (Hotel tile) - Filter by fields: roomType, roomSize, occupancy, total
// Contacts Tab (Hotel tile) - Filter by fields: locationName, name, function, department
// Participants Tab (Hotel tile) - Filter by fields: name, startDate, endDate, type
// Contingents Tab (Hotel tile) - Filter by fields: year, roomType, contingent, booked, price
import { getGridStringOperators, getGridNumericOperators } from '@mui/x-data-grid';

const availableFilterValues = ['contains', 'startsWith', 'endsWith', 'equals'];
const eventDialogOperators = ['contains'];
const equalNumberDialogOperators = ['='];

export const BACKEND_FILTER_VALUES = {
  contains: 'contains',
  startsWith: 'starting',
  endsWith: 'ending',
  equals: 'equals'
};

export const attachmentTableFilters = getGridStringOperators()
  .filter((operator) => availableFilterValues.includes(operator.value))
  .map((operator) => operator);

export const containsOnlyDialogFilters = getGridStringOperators().filter(({ value }) =>
  eventDialogOperators.includes(value)
);

export const equalNumbersDialogFilters = getGridNumericOperators().filter(({ value }) =>
  equalNumberDialogOperators.includes(value)
);
