export default {
  mode: 'light',
  common: {
    black: '#000',
    white: '#FFF'
  },
  primary: {
    main: '#427CAC',
    light: '#91C8F6',
    dark: '#3F5161',
    contrastText: '#EFF4F9'
  },
  secondary: {
    main: '#BFBFBF',
    light: '#FAFAFA',
    dark: '#333333',
    contrastText: '#FFFFFF'
  },
  launchpad: {
    main: '#2C4E6C',
    light: '#A9C6DE',
    dark: '#9EC7D8',
    contrastText: '#427CAC',
    newsBorder: '#F4FDFC',
    border: '#0074E8',
    subHeaderColor: '#707070',
    arrowBackground: '#D3ECF9',
    tileBackground: '#FFF',
    newsLines: '#F8E9E9'
  },
  error: {
    main: '#BB0000',
    light: '#FF8888',
    dark: '#880000',
    contrastText: '#5E696E'
  },
  warning: {
    main: '#E78C07',
    light: '#FABD64',
    dark: '#E78C07'
  },
  info: {
    main: '#0092D1',
    light: '#91c8f6',
    dark: '#427CAC'
  },
  success: {
    main: '#2B7D2B',
    light: '#ABE2AB',
    dark: '#2B7C2B'
  },
  accent: {
    main: '#E6600D',
    light: '#E09D00',
    dark: '#C14646'
  },
  chart: {
    label: '#808080'
  },
  grey: {
    50: '#333333',
    100: '#666666',
    200: '#BFBFBF',
    300: '#CCCCCC',
    400: '#E5E5E5',
    500: '#FFFFFF',
    600: '#AEB8C2',
    650: '#262626',
    700: '#434343',
    900: '#595959'
  },
  action: {
    active: '#0099DB',
    hover: 'rgba(0, 0, 0, 0.1)',
    hoverOpacity: '0.4'
  },
  eventStatus: {
    open: '#91C8F6',
    inProgress: '#ABE2AB',
    completed: '#D3D7D9',
    cancelled: '#FF8888',
    openDark: '#4aa5f0',
    inProgressDark: '#72ce72',
    completedDark: '#aab2b6',
    cancelledDark: '#ff3c3c'
  },
  calendar: {
    main: '#FFFFFF',
    contrastText: '#427CAC',
    weekendDays: '#D10000',
    monthDays: '#000',
    tileNow: '#BFBFBF',
    activeDaySidebar: '#427CAC',
    neighboringMonth: '#757575',
    labelColor: '#000',
    bigCalOfRange: '#f5f5f5',
    bigCalToday: '#f3f7fa',
    bigCalBorder: '#f1f1f1'
  },
  login: {
    button: '#01579B',
    samlButton: '#618eb1'
  }
};
