import Localize from 'react-intl-universal';

import * as yup from 'yup';

import { phone } from '@common/yup';

export default () => {
  return yup.object().shape({
    name: yup.string().required(Localize.get('ValidationMessages.NameRequired')),
    availableSeats: yup
      .number()
      .required(Localize.get('ValidationMessages.AvailableSeatsRequired'))
      .min(1, Localize.get('ValidationMessages.AvailableSeatsMustBePositive'))
      .max(1000, Localize.get('ValidationMessages.AvailableSeatsMax', { maxSeats: 1000 }))
      .typeError(Localize.get('ValidationMessages.AvailableSeatsRequired'))
      .integer(),
    venue: yup
      .object()
      .shape({
        id: yup
          .number()
          .required(Localize.get('ValidationMessages.VenueNameRequired'))
          .nullable(true),
        value: yup.string()
      })
      .nullable(true)
      .required(Localize.get('ValidationMessages.VenueNameRequired')),
    floor: yup.string().notRequired(),
    phoneDtos: phone()
  });
};
