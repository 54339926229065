import React from 'react';
import Localize from 'react-intl-universal';
import { useMatch } from 'react-location';

import { dateInitFormats } from '@common/Constants';
import { dateToFormat } from '@common/helpers/dates';
import { BUSINESS_PARTNERS_PATHS } from '@common/network/ApiPaths';
import RightSidebar from '@components/RightSidebar';
import { sidebar } from '@pages/BusinessPartners/util/fields';

import { mapPropsToAddress } from '../CreateBusinessPartner/getAccountConfig';

const HIDE_ITEM_ROLE_TYPE = 2; // supplier is hidden

const Sidebar = ({
  details = null,
  isDetailsLoading = false,
  onSave = () => {},
  onDelete = () => {},
  validationSchema = null
}) => {
  const {
    data: {
      phoneTypes: { data: phoneTypes },
      addressTypes: { data: addressTypes },
      emailTypes: { data: emailTypes }
    }
  } = useMatch();
  const extendedConfig = (setFieldValue, values, setValues) => {
    return {
      ['hotelFacilities']: {
        isVisible:
          details?.roles[0]?.supplierType?.id === 1 || details?.roles[0]?.supplierType?.id === 4
      },
      ['personalTitle']: {
        isVisible: details?.businessPartnerType?.id === 1
      },
      ['organizationPerson.person.title.id']: {
        isVisible: details?.businessPartnerType?.id === 1
      },
      ['organizationPerson.person.firstName']: {
        isVisible: details?.businessPartnerType?.id === 1
      },
      ['organizationPerson.person.lastName']: { isVisible: details?.businessPartnerType?.id === 1 },
      ['organizationPerson.name']: {
        isVisible: details?.businessPartnerType?.id === 2
      },
      ['businessPartnerType']: {
        formItemChangeHandler: (e, data) => {
          setFieldValue('businessPartnerType', data.newValue);
        },
        path: BUSINESS_PARTNERS_PATHS.BUSINESS_PARTNER_TYPES
      },
      ['roles[0].roleType']: {
        disabled: details?.businessPartnerType?.id === 2,
        hideItemId: details?.businessPartnerType?.id === 1 ? HIDE_ITEM_ROLE_TYPE : null,
        formItemChangeHandler: (e, data) => {
          setFieldValue('roles[0].roleType', data.newValue);
        },
        path: BUSINESS_PARTNERS_PATHS.BUSINESS_PARTNER_ROLE_TYPES,
        disableClearable: true
      },
      ['roles[0].supplierType']: {
        formItemChangeHandler: (e, data) => {
          setFieldValue('roles[0].supplierType', data.newValue);
        },
        path: BUSINESS_PARTNERS_PATHS.SUPPLIER_TYPES,
        isVisible: details?.roles[0].roleType?.id === 2
      },
      ['abcClassification']: {
        formItemChangeHandler: (e, data) => {
          setFieldValue('abcClassification', data.newValue);
        },
        path: BUSINESS_PARTNERS_PATHS.ABC_CLASSIFICATION
      },
      ['owner']: {
        tokenValue:
          values?.owner?.firstName && values.owner?.lastName
            ? [{ title: `${values.owner.firstName} ${values.owner.lastName}` }]
            : undefined,
        selectionModel: values?.owner?.id,
        selectItem: (owner) => {
          setValues({
            ...values,
            owner: {
              id: owner?.personTypeId,
              firstName: owner?.firstName,
              lastName: owner?.lastName
            }
          });
        },
        onIconClick: (e) => {
          if (e?.target?.name === 'delete') {
            setValues({
              ...values,
              owner: null
            });
          }
        }
      },
      ['organizationPerson.person.birthDate']: {
        maxDate: new Date(),
        onChange: (value) =>
          setFieldValue(
            'organizationPerson.person.birthDate',
            dateToFormat(value, dateInitFormats.dateTime)
          )
      },
      ['organizationPerson.organization.parentalAccount']: {
        isVisible: details?.businessPartnerType?.id === 2,
        tokenValue: values.organizationPerson?.organization?.parentalAccount?.name
          ? [{ title: values.organizationPerson.organization.parentalAccount.name }]
          : undefined,
        onIconClick: (e) => {
          if (e?.target?.name === 'delete') {
            setFieldValue('organizationPerson.organization.parentalAccount', null);
          }
        },
        selectItem: (value) => {
          setFieldValue('organizationPerson.organization.parentalAccount', value);
          setFieldValue(
            'organizationPerson.organization.parentalAccount.id',
            value.organizationPersonId
          );
        }
      },
      ['organizationPerson.organization.vatno']: {
        isVisible: details?.businessPartnerType?.id === 2
      },
      ['roles[0].hotel.rating']: {
        onChange: (e, value) => {
          setFieldValue('roles[0].hotel.rating', value);
        }
      },
      ['organizationPerson.phoneDtos']: {
        ['type.id']: {
          options: phoneTypes
        }
      },
      ['organizationPerson.emailDtos']: {
        ['type.id']: {
          options: emailTypes
        }
      },
      ['organizationPerson.addressDtos']: {
        ['type.id']: {
          options: addressTypes
        }
      }
    };
  };

  return (
    <RightSidebar
      config={{
        title: Localize.get('BusinessPartners.Details'),
        fieldsConfig: sidebar
      }}
      mapPropsToAddress={mapPropsToAddress}
      extendedConfig={extendedConfig}
      data={details}
      isDetailsLoading={isDetailsLoading}
      onSave={onSave}
      onDelete={onDelete}
      validationSchema={validationSchema}
    />
  );
};

export default Sidebar;
