import React, { useEffect, useState } from 'react';
import Localize from 'react-intl-universal';
import { useNavigate } from 'react-location';
import { useSelector, useDispatch } from 'react-redux';

import EntityTypes from '@common/network/EntityTypes';
import { CONFIRM_ACTIONS, openConfirmDialog } from '@components/ConfirmDialog';
import { selectIsFullScreen } from '@components/DetailsToolbar/detailsToolbarSlice';
import Header from '@components/Header';
import LayoutContainer, { LeftContainer, RightContainer } from '@components/LayoutContainer';
import MasterList from '@components/MasterList';
import MasterListFooter from '@components/MasterListFooter';
import MasterListItem from '@components/MasterListItem';
import MasterListTitle from '@components/MasterListTitle';
import MasterListToolbar from '@components/MasterListToolbar';
import RightSidebar from '@components/RightSidebar';
import {
  selectIsOpen,
  setIsEditDisabled,
  setMode
} from '@components/RightSidebar/rightSidebarSlice';
import SortDialog from '@components/SortDialog';
import { SEARCH_INPUT_DELAY } from '@config/inputs';
import useDebounce from '@hooks/handlers/useDebounce';
import {
  setFilterParams,
  setLoading,
  selectFilter,
  selectTotalElements,
  selectTotalPages,
  selectDetails,
  selectIsLoading,
  selectIsDetailsLoading,
  selectList,
  setSelectedId,
  selectId,
  initialState,
  saveOrgStructure,
  fetchStructure,
  fetchStructureDetails,
  deleteOrgStructure,
  resetState,
  setDetails
} from '@pages/OrgStructures/orgStructureSlice';
import sidebar from '@pages/OrgStructures/util/fields/sidebar';
import useSidebarValidationSchema from '@pages/OrgStructures/util/schemas/useSidebarSchema';

import { OrgStructureDetails, OrgStructureTabs } from './components';
import { SORT_DATA } from './util/sortConfig';

const OrgStructures = () => {
  // TODO:
  // const {
  //   data: {
  //     config: { sorts, tabs }
  //   }
  // } = useMatch();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [sidebarValidationSchema] = useSidebarValidationSchema();
  const [isOpenDialogSort, setIsOpenDialogSort] = useState(false);

  const selectedId = useSelector(selectId);
  const data = useSelector(selectList);
  const details = useSelector(selectDetails);
  const totalElements = useSelector(selectTotalElements);
  const totalPages = useSelector(selectTotalPages);
  const filter = useSelector(selectFilter);

  const isLoading = useSelector(selectIsLoading);
  const isDetailsLoading = useSelector(selectIsDetailsLoading);

  const isOpen = useSelector(selectIsOpen);
  const isFullScreen = useSelector(selectIsFullScreen);

  // Get List of entities on initialization and sort change
  useEffect(
    () => dispatch(fetchStructure(filter)),
    [filter.sortBy, filter.sortDirection, filter.page]
  );

  // Get initial List of entities and on sort change
  useEffect(() => dispatch(fetchStructureDetails(selectedId)), [selectedId]);

  // Get List of entities on search change
  useDebounce(() => dispatch(fetchStructure(filter)), SEARCH_INPUT_DELAY, [filter.search]);

  useEffect(() => {
    dispatch(setIsEditDisabled(true));
    return () => dispatch(resetState());
  }, []);

  const onDelete = () => {
    dispatch(
      openConfirmDialog({
        title: Localize.get('ConfirmationMessages.Delete', {
          item: Localize.get('OrgStructure.Item')?.toLowerCase()
        }),
        confirmButton: Localize.get('Buttons.Delete'),
        cancelButton: Localize.get('Buttons.Cancel')
      })
    )
      .unwrap()
      .then((result) => {
        if (result === CONFIRM_ACTIONS.Cancel) {
          return;
        }
        dispatch(deleteOrgStructure(selectedId))
          .unwrap()
          .then(() => dispatch(setMode('read')))
          .catch((rejectedValueOrSerializedError) => console.error(rejectedValueOrSerializedError));
      });
  };

  return (
    <LayoutContainer>
      <LeftContainer isFullScreen={isFullScreen}>
        <Header>
          <MasterListTitle>{`${Localize.get(
            'OrgStructure.MasterListTittle'
          )} (${totalElements})`}</MasterListTitle>
        </Header>

        <MasterListToolbar
          isDisabled={true} //SHOULD be changed to data?.length === 0 when servise would be ready
          onSortClick={() => alert('Not implemented.')}
          onFilterClick={() => alert('Not implemented.')}
          isSortActive={
            filter.sortBy !== initialState.filter.sortBy ||
            filter.sortDirection !== initialState.filter.sortDirection
          }
          searchProps={{
            value: filter.search,
            onSearchChange: (e) => {
              dispatch(setLoading(true));
              dispatch(setFilterParams({ key: 'search', value: e.target.value }));
            }
          }}
        />

        <MasterList
          isLoading={isLoading}
          data={data}
          renderMasterItem={(item) => (
            <MasterListItem
              key={item.id}
              id={item.id}
              heading={{ left: item?.name, right: item?.id }}
              rightDetails={{
                firstRow: item?.parentUnitId
              }}
              leftDetails={{
                firstRow: item?.parentUnitName
              }}
              selectedId={selectedId}
              onItemSelect={(payload) => dispatch(setSelectedId(payload))}
            />
          )}
        />
        <MasterListFooter
          onPageChange={(e, page) => dispatch(fetchStructure({ ...filter, page: page - 1 }))}
          onAddClick={() => navigate({ to: '/org-structure/new', replace: false })}
          pagination={{ totalPages, page: filter.page + 1 }}
        />

        <SortDialog
          onCancel={() => setIsOpenDialogSort(false)}
          open={isOpenDialogSort}
          sortState={filter}
          fields={SORT_DATA}
          sortHandler={({ sortBy, sortDirection }) => {
            dispatch(
              setFilterParams([
                { key: 'sortBy', value: sortBy },
                { key: 'sortDirection', value: sortDirection }
              ])
            );
          }}
        />
      </LeftContainer>

      <RightContainer open={isOpen} isFullScreen={isFullScreen}>
        <OrgStructureDetails entityId={selectedId} headerTitle={details?.name}>
          <OrgStructureTabs
            entityId={selectedId}
            entityType={EntityTypes.ORG_STRUCTURE}
            details={details}
            setDetails={setDetails}
          />
        </OrgStructureDetails>
      </RightContainer>

      <RightSidebar
        config={{ title: Localize.get('OrgStructure.Details'), fieldsConfig: sidebar }}
        data={details}
        validationSchema={sidebarValidationSchema}
        isDetailsLoading={isDetailsLoading}
        onSave={(values) =>
          dispatch(
            saveOrgStructure({ ...values, id: selectedId, venue: { ...values.venue, id: 1 } })
          )
            .unwrap()
            .then(() => dispatch(setMode('read')))
            .catch((rejectedValueOrSerializedError) =>
              console.error(rejectedValueOrSerializedError)
            )
        }
        onDelete={onDelete}
      />
    </LayoutContainer>
  );
};

export default OrgStructures;
