import { isNil } from 'lodash';

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { ACTION_MODES, TAB_KEYS } from '@common/Constants';
import { errorMessageFormatter, successMessageFormatter } from '@common/helpers/MessageFormatter';
import { PARTICIPANTS_PATHS } from '@common/network/ApiPaths';
import EntityTypes from '@common/network/EntityTypes';
import { SnackbarSeverityTypes, showSnackbar } from '@components/Snackbar/snackbarSlice';
import { setLocationDetails } from '@pages/BusinessPartners/businessPartnersSlice';
import { getByPathAndParams, postByPathAndData } from '@services/BaseApi';

// import { SORT_DIRECTION } from '@common/Constants';
// import { getByPathAndParams } from '@services/BaseApi';

// import { SORT_DATA_PARTICIPANTS } from '../../util/sortConfig';
export const PAGE_SIZE = 5;
export const PAGE_SIZE_OPTIONS = [5, 10, 15];
export const initialState = {
  data: [],
  rows: [],
  filter: {
    search: '',
    // WAITING FOR THE BE
    // sortBy: SORT_DATA_CONTACTS[0].name,
    // sortDirection: SORT_DIRECTION.ASCENDING,
    page: 0,
    size: PAGE_SIZE
  },
  totalPages: 0,
  totalElements: 0,
  isLoading: false,
  selectionModel: [],
  mode: 'details',
  newAccommodation: {
    roomType: '',
    maxOccupancy: '',
    hotel: null,
    description: ''
  },
  createErrors: {}
};

export const fetchAccommodations = createAsyncThunk(
  'locationContingents/getAll',
  ({ entityId, filter }) => {
    return getByPathAndParams({
      path: PARTICIPANTS_PATHS.HOTELS_ACCOMMODATIONS,
      pathVariables: { id: entityId },
      params: filter
    })
      .then((response) => response.data)
      .catch((error) => error);
  }
);

export const createAccommodations = createAsyncThunk(
  'locationContingents/create',
  ({ entityId }, { getState, rejectWithValue, dispatch }) => {
    const state = getState();
    const newAccommodation = state.locationAccommodation.newAccommodation;

    return postByPathAndData({
      path: PARTICIPANTS_PATHS.HOTELS_ACCOMMODATIONS,
      data: {
        hotelRoomTypeId: newAccommodation.roomType,
        maxOccupancy: newAccommodation.maxOccupancy,
        description: newAccommodation.description
      },
      pathVariables: { id: entityId }
    })
      .then((response) => {
        dispatch(
          showSnackbar({
            message: successMessageFormatter(EntityTypes.ACCOMMODATION, ACTION_MODES.Create),
            severity: SnackbarSeverityTypes.SUCCESS
          })
        );

        dispatch(setLocationDetails({ counts: { [TAB_KEYS.ACCOMMODATION]: response.data.count } }));
        return response.data;
      })
      .catch((error) => {
        dispatch(
          showSnackbar({
            message: errorMessageFormatter(error, EntityTypes.PARTICIPANT, ACTION_MODES.Delete),
            severity: SnackbarSeverityTypes.ERROR
          })
        );

        return rejectWithValue(error.response);
      });
  }
);

export const accommodationTableSlice = createSlice({
  name: 'locationAccommodation',
  initialState,
  reducers: {
    resetState: () => initialState,
    setMode: (state, { payload }) => {
      state.mode = payload;
    },
    setNewAccommodation: (state, { payload }) => {
      state.newAccommodation = payload;
    },
    setErrors: (state, { payload }) => {
      state.createErrors = payload;
    },
    setCancelCreateMode: (state) => {
      state.mode = 'details';
      state.newAccommodation = {
        roomType: '',
        maxOccupancy: '',
        hotel: null,
        description: ''
      };
      state.createErrors = {};
    },
    setData: (state, { payload }) => {
      state.data = payload;
    },
    setSelectionModel: (state, { payload }) => {
      if (Array.isArray(payload)) {
        state.selectionModel = [...payload];
      } else {
        const index = state.selectionModel.findIndex((s) => s === payload);
        if (index === -1) {
          state.selectionModel = [...state.selectionModel, payload];
        } else {
          state.selectionModel = [
            ...state.selectionModel.slice(0, index),
            ...state.selectionModel.slice(index + 1)
          ];
        }
      }
    },
    setFilterParams: (state, action) => {
      let newFilterValues = {};
      // Case when search value is reset to empty and search bar is closed
      if (action.payload.key === 'search' && !action.payload.value && !state.filter.search) {
        state.isLoading = false;
        return;
      }
      if (Array.isArray(action.payload)) {
        newFilterValues = action.payload.reduce(
          (obj, item) => ((obj[item.key] = item.value), obj),
          {}
        );
      } else {
        newFilterValues = { [action.payload.key]: action.payload.value };
      }
      state.filter = { ...state.filter, ...newFilterValues, page: action.payload.page ?? 0 };
    }
  },
  extraReducers: (builder) => {
    builder
      // Get all
      .addCase(fetchAccommodations.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.totalPages = payload?.totalPages || state.totalPages;
        state.totalElements = isNil(payload?.totalElements)
          ? state.totalElements
          : payload?.totalElements;
        state.data = payload?.content ?? [];
      })
      .addCase(fetchAccommodations.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchAccommodations.rejected, (state) => {
        state.data = [];
        state.isLoading = false;
      })
      .addCase(createAccommodations.fulfilled, (state, { payload }) => {
        state.data = [payload.content, ...state.data];
        state.mode = 'details';
        state.newAccommodation = initialState.newAccommodation;
        state.isLoading = false;
        state.totalElements += 1;
      })
      .addCase(createAccommodations.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createAccommodations.rejected, (state) => {
        state.isLoading = false;
      });
  }
});

export const selectList = (state) => state.locationAccommodation.data;
export const selectRows = (state) => state.locationAccommodation.rows;
export const selectTotalElements = (state) => state.locationAccommodation.totalElements;
export const selectTotalPages = (state) => state.locationAccommodation.totalPages;
export const selectFilter = (state) => state.locationAccommodation.filter;
export const selectIsLoading = (state) => state.locationAccommodation.isLoading;
export const selectSelectionModel = (state) => state.locationAccommodation.selectionModel;
export const selectMode = (state) => state.locationAccommodation.mode;
export const selectNewAccommodation = (state) => state.locationAccommodation.newAccommodation;
export const selectErrors = (state) => state.locationAccommodation.createErrors;

const { actions, reducer } = accommodationTableSlice;

export const {
  setData,
  setFilterParams,
  resetState,
  setSelectionModel,
  setMode,
  setNewAccommodation,
  setErrors,
  setCancelCreateMode
} = actions;

export default reducer;
