import Localize from 'react-intl-universal';

import * as yup from 'yup';

export default function () {
  return yup.array().of(
    yup.object().shape({
      email: yup
        .string()
        .email(Localize.get('ValidationMessages.EmailFormat'))
        .nullable(true)
        .required(Localize.get('ValidationMessages.EmailRequired'))
    })
  );
}
