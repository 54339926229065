import React, { useState, useRef } from 'react';
import Localize from 'react-intl-universal';

import { convertToRaw } from 'draft-js';
import { isEmpty } from 'lodash';
import MUIRichTextEditor from 'mui-rte';

import CancelIcon from '@mui/icons-material/Cancel';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';

import { isJsonString } from '@common/helpers/string';

const StyledNoteDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2)
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  }
}));

const NoteDialogTitle = ({ children, onClose, ...other }) => {
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[700]
        }}
      >
        <CloseIcon />
      </IconButton>
    </DialogTitle>
  );
};

const NotesDialog = ({ open, onClose, note = {}, type = 'NOTE' }) => {
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);

  const editorEl = useRef(null);

  //this object is needed to preserve App in case text field is string type becouse MUI RichTextEditor need JSON
  const richTextObj = {
    blocks: [
      {
        text: note?.text ? note?.text : '',
        type: 'unstyled',
        depth: 0,
        inlineStyleRanges: [],
        entityRanges: [],
        data: {}
      }
    ],
    entityMap: {}
  };

  const isJson = note ? isJsonString(note?.text) : false;
  const defValue = isJson ? note?.text : JSON.stringify(richTextObj);

  const saveEditorText = (data) => {
    onClose(data, note?.id);
    setIsSaveDisabled(true);
  };

  const onChange = (state) => {
    let text = isEmpty(note) ? null : isJson ? JSON.parse(note?.text).blocks[0].text : note?.text;
    if (state.getCurrentContent().getPlainText() === text) {
      // When both text states are the same we need to check object blocks because only those blocks contain
      // style changes (like bold, italic, etc..)
      const isChangeFromToolbar =
        JSON.stringify(convertToRaw(state.getCurrentContent()).blocks[0]) !==
        JSON.stringify(JSON.parse(note?.text).blocks[0]);

      isChangeFromToolbar ? setIsSaveDisabled(false) : setIsSaveDisabled(true);
    } else if (state.getCurrentContent().hasText()) {
      setIsSaveDisabled(false);
    } else {
      setIsSaveDisabled(true);
    }
  };

  return (
    <StyledNoteDialog aria-labelledby="notes-title" open={open} maxWidth="md" fullWidth>
      <NoteDialogTitle
        id="notes-title"
        onClick={() => {
          onClose();
          setIsSaveDisabled(true);
        }}
      >
        {note?.id
          ? `${Localize.get('Notes.EditNote')} ${note?.type?.value}`
          : `${Localize.get('Notes.CreateNote')} ${Localize.get(`Notes.${type}`)}`}
      </NoteDialogTitle>
      <DialogContent dividers>
        <MUIRichTextEditor
          defaultValue={defValue}
          ref={editorEl}
          label={Localize.get('Notes.EnterText')}
          controls={[
            'title',
            'bold',
            'italic',
            'underline',
            'strikethrough',
            'undo',
            'redo',
            'link',
            'media',
            'numberList',
            'bulletList',
            'quote',
            'code',
            'clear'
          ]}
          onSave={saveEditorText}
          onChange={onChange}
        />
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          disabled={isSaveDisabled}
          onClick={() => {
            editorEl.current?.save();
          }}
          startIcon={<SaveIcon />}
        >
          {note?.id ? Localize.get('Buttons.SaveChanges') : Localize.get('Buttons.Create')}
        </Button>
        <Button
          color="error"
          variant="outlined"
          startIcon={<CancelIcon />}
          onClick={() => {
            onClose();
            setIsSaveDisabled(true);
          }}
        >
          {Localize.get('Buttons.Cancel')}
        </Button>
      </DialogActions>
    </StyledNoteDialog>
  );
};

export default NotesDialog;
