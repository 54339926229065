import { DIALOG_PATHS } from '@common/network/ApiPaths';

export default [
  {
    groupName: 'IltSession.IltSessionInformation',
    fields: [
      {
        label: 'IltSession.SeminarNumber',
        name: 'seminarNumber',
        required: false,
        disabled: false,
        component: 'text',
        maxLength: 30
      },
      {
        label: 'IltSession.Course',
        name: 'course',
        required: true,
        disabled: false,
        dialogTitle: 'IltSession.SearchCourse',
        component: 'tokenValue',
        value: '',
        initQueryState: { id: '', name: '' },
        fetchDataPath: DIALOG_PATHS.COURSES,
        dialogColumns: [
          {
            field: 'id',
            headerName: 'Labels.ID',
            width: 100,
            editable: false,
            defaultValue: '',
            headerAlign: 'left',
            sortable: false
          },
          {
            field: 'name',
            headerName: 'Labels.Name',
            width: 350,
            editable: false,
            defaultValue: '',
            headerAlign: 'left',
            sortable: false
          }
        ]
      },
      {
        label: 'IltSession.IltModule',
        name: 'iltModule',
        required: true,
        disabled: false,
        dialogTitle: 'IltSession.SearchIltModule',
        component: 'tokenValue',
        value: '',
        initQueryState: { courseId: '' },
        fetchDataPath: DIALOG_PATHS.ILT_MODULE,
        dialogColumns: [
          {
            field: 'id',
            headerName: 'Labels.ID',
            width: 100,
            editable: false,
            defaultValue: '',
            headerAlign: 'left',
            sortable: false
          },
          {
            field: 'name',
            headerName: 'Labels.Name',
            width: 350,
            editable: false,
            defaultValue: '',
            headerAlign: 'left',
            sortable: false
          }
        ]
      },
      {
        label: 'IltSession.IltSessionName',
        name: 'name',
        required: true,
        disabled: false,
        component: 'text'
      },
      {
        label: 'IltSession.MaximumSeats',
        name: 'maxSeats',
        required: true,
        disabled: false,
        component: 'text',
        type: 'number'
      },
      {
        label: 'IltSession.MinimumSeats',
        name: 'minSeats',
        required: false,
        disabled: false,
        component: 'text',
        type: 'number'
      },
      {
        label: 'IltSession.SessionType',
        name: 'type',
        required: true,
        disabled: false,
        component: 'select',
        helperText: 'IltSession.TypeNote'
      },
      {
        label: 'Events.Status',
        name: 'status',
        required: true,
        disabled: false,
        component: 'select'
      },
      {
        label: 'IltSession.PublishInCatalog',
        name: 'isMeaPublished',
        required: false,
        disabled: false,
        component: 'switch',
        column: 6
      }
    ]
  },
  {
    groupName: 'IltSession.WaitListInformation',
    fields: [
      {
        label: 'IltSession.WaitList',
        name: 'hasWaitingList',
        required: false,
        disabled: false,
        component: 'switch',
        column: 2
      },
      {
        label: 'IltSession.AutomaticWaitingList',
        name: 'automaticWaitingList',
        required: false,
        disabled: true,
        component: 'switch',
        column: 3
      },
      {
        label: 'IltSession.AutomaticWaitingListCancellation',
        name: 'automaticWaitingListCancellation',
        required: false,
        disabled: true,
        component: 'switch',
        column: 4
      },
      {
        label: 'IltSession.AutomaticCancellationOn',
        name: 'automaticCancellationDate',
        required: true,
        disabled: true,
        component: 'datetimepicker',
        column: 3
      }
    ]
  },
  {
    groupName: 'IltSession.RegistrationPeriodInformation',
    fields: [
      {
        label: 'IltSession.RegistrationPeriod',
        name: 'hasRegistrationPeriod',
        required: true,
        disabled: false,
        component: 'switch',
        column: 3
      },
      {
        label: 'IltSession.RegistrationFrom',
        name: 'registrationFrom',
        required: true,
        disabled: false,
        component: 'datetimepicker',
        column: 4
      },
      {
        label: 'IltSession.RegistrationUntil',
        name: 'registrationUntil',
        required: true,
        disabled: false,
        component: 'datetimepicker',
        column: 4
      }
    ]
  },
  {
    groupName: 'IltSession.CancellationDeadlineInformation',
    fields: [
      {
        label: 'IltSession.CancellationDeadline',
        name: 'hasCancellationDeadline',
        required: false,
        disabled: false,
        component: 'switch',
        column: 4
      },
      {
        label: 'IltSession.CancellationDeadlineOn',
        name: 'cancellationDeadline',
        required: true,
        disabled: false,
        component: 'datetimepicker',
        column: 4
      }
    ]
  },
  {
    groupName: 'IltSession.SessionDayInformation',
    fields: [
      {
        label: 'IltSession.IltSessionDayName',
        name: 'sessionDayDto.name',
        required: true,
        disabled: false,
        component: 'text'
      },
      {
        label: 'IltSession.Date',
        name: 'sessionDayDto.dayDate',
        required: true,
        disabled: false,
        component: 'datepicker'
      },
      {
        label: 'IltSession.TimeFrom',
        name: 'sessionDayDto.startTime',
        required: true,
        disabled: false,
        component: 'timepicker'
      },
      {
        label: 'IltSession.TimeTo',
        name: 'sessionDayDto.endTime',
        required: true,
        disabled: false,
        component: 'timepicker'
      },
      {
        label: 'IltSession.Instructor',
        name: 'sessionDayDto.instructor',
        required: true,
        disabled: false,
        dialogTitle: 'IltSession.SearchInstructor',
        component: 'tokenValueArray',
        fetchDataPath: DIALOG_PATHS.INSTRUCTOR,
        value: '',
        initQueryState: {
          id: '',
          firstName: '',
          lastName: '',
          email: ''
        },
        dialogColumns: [
          {
            field: 'id',
            headerName: 'Labels.ID',
            width: 100,
            editable: false,
            defaultValue: '',
            headerAlign: 'left',
            sortable: false
          },
          {
            field: 'firstName',
            headerName: 'Labels.FirstName',
            width: 350,
            editable: false,
            defaultValue: '',
            headerAlign: 'left',
            sortable: false
          },
          {
            field: 'lastName',
            headerName: 'Labels.LastName',
            width: 350,
            editable: false,
            defaultValue: '',
            headerAlign: 'left',
            sortable: false
          }
        ]
      },
      {
        label: 'IltSession.DetailsInformation',
        name: 'sessionDayDto.details',
        required: false,
        disabled: false,
        component: 'text'
      },
      {
        label: 'IltSession.WebinarURL',
        name: 'sessionDayDto.webinarUrl',
        required: false,
        disabled: false,
        component: 'text'
      }
    ]
  },
  {
    groupName: 'IltSession.SessionDayReminder',
    fields: [
      {
        label: 'IltSession.SendRemindersBeforeSession',
        name: 'sessionDayDto.sendReminder',
        required: false,
        disabled: true,
        component: 'switch',
        column: 4
      },
      {
        label: 'IltSession.ReminderValue',
        name: 'sessionDayDto.reminderValue',
        required: true,
        disabled: true,
        component: 'text',
        column: 3,
        type: 'number'
      },
      {
        label: 'IltSession.ReminderMetric',
        name: 'sessionDayDto.reminderMetric.id',
        required: true,
        disabled: true,
        component: 'select',
        column: 3
      }
    ]
  }
];
