import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { SORT_DIRECTION } from '@common/Constants';
import { COURSES_PATHS } from '@common/network/ApiPaths';
import { getByPathAndParams, postByPathAndData } from '@services/BaseApi';

import { SORT_DATA } from './util/sortConfig';

export const initialState = {
  selectedId: null,
  data: [],
  filter: {
    search: '',
    sortBy: SORT_DATA[0].name,
    sortDirection: SORT_DIRECTION.ASCENDING,
    page: 0,
    size: 15
  },
  totalPages: 0,
  totalElements: 0,
  details: null,
  isLoading: true,
  isDetailsLoading: false
};

export const fetchCourses = createAsyncThunk('courses/fetchAll', (filter) => {
  return getByPathAndParams({
    path: COURSES_PATHS.GET,
    params: filter
  })
    .then((response) => response.data)
    .catch((error) => error);
});

export const createCourses = createAsyncThunk('courses/create', () => {
  return postByPathAndData({
    path: COURSES_PATHS.POST
  })
    .then((response) => response.data)
    .catch((error) => error);
});

export const coursesSlice = createSlice({
  name: 'courses',
  initialState,
  reducers: {
    resetState: () => initialState,
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setFilterParams: (state, action) => {
      let newFilterValues = {};

      // Case when search value is reset to empty and search bar is closed
      if (action.payload.key === 'search' && !action.payload.value && !state.filter.search) {
        state.isLoading = false;
        return;
      }

      if (Array.isArray(action.payload)) {
        newFilterValues = action.payload.reduce(
          (obj, item) => ((obj[item.key] = item.value), obj),
          {}
        );
      } else {
        newFilterValues = { [action.payload.key]: action.payload.value };
      }

      state.filter = { ...state.filter, ...newFilterValues };
    }
  },
  extraReducers: (builder) => {
    builder
      // Get all
      .addCase(fetchCourses.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.data = payload?.content || state.data;
        state.totalPages = payload?.totalPages || state.totalPages;
        state.totalElements = payload?.totalElements || state.totalElements;
        state.selectedId = payload?.content?.[0]?.id;
        state.filter.page = payload?.pageable?.pageNumber;
        state.filter.size = payload?.pageable?.pageSize;
      })
      .addCase(fetchCourses.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchCourses.rejected, (state) => {
        state.data = [];
        state.isLoading = false;
      })
      // Create
      .addCase(createCourses.fulfilled, (state) => {
        state.filter.page = 0;
      });
  }
});

export const selectId = (state) => state.courses.selectedId;
export const selectList = (state) => state.courses.data;
export const selectFilter = (state) => state.courses.filter;
export const selectTotalElements = (state) => state.courses.totalElements;
export const selectTotalPages = (state) => state.courses.totalPages;
export const selectDetails = (state) => state.courses.details;

export const selectIsLoading = (state) => state.courses.isLoading;
export const selectIsDetailsLoading = (state) => state.courses.isDetailsLoading;

const { actions, reducer } = coursesSlice;

export const { setFilterParams, resetState } = actions;

export default reducer;
