import Localize from 'react-intl-universal';

import { TAB_KEYS } from '@common/Constants';
import AttachmentsTable from '@components/AttachmentsTable';
import NotesTable from '@components/NotesTable';
import SessionTable from '@components/SessionsTable';

import ParticipantsOverview from '../Overview/ParticipantsOverview';

// Key property is this map and in the config must match
export const PARTICIPANTS_TABS_COMPONENTS = {
  [TAB_KEYS.OVERVIEW]: ParticipantsOverview,
  [TAB_KEYS.SESSION]: SessionTable,
  [TAB_KEYS.NOTE]: NotesTable,
  [TAB_KEYS.ATTACHMENT]: AttachmentsTable
};

export const sessionColumns = () => [
  {
    field: 'session.id',
    valueGetter: (params) => params?.row?.session?.id,
    headerName: Localize.get('Labels.ID'),
    width: 70,
    editable: false,
    headerAlign: 'left'
  },

  {
    field: 'session.name',
    valueGetter: (params) => params?.row?.session?.name,
    headerName: Localize.get('Participants.SessionName'),
    width: 300,
    editable: false,
    defaultValue: '',
    headerAlign: 'left'
  },
  {
    field: 'timeBlockDate',
    valueGetter: (params) => params?.row?.session?.timeBlockDate,
    headerName: Localize.get('Labels.Date'),
    width: 150,
    editable: false,
    defaultValue: '',
    headerAlign: 'left'
  },
  {
    field: 'session.startTime',
    valueGetter: (params) => params?.row?.session?.startTime,
    headerName: Localize.get('Sessions.TimeFrom'),
    width: 150,
    editable: false,
    defaultValue: '',
    headerAlign: 'left'
  },
  {
    field: 'session.endTime',
    valueGetter: (params) => params?.row?.session?.endTime,
    headerName: Localize.get('Sessions.TimeTo'),
    width: 150,
    editable: false,
    defaultValue: '',
    headerAlign: 'left'
  },
  {
    field: 'status.value',
    valueGetter: (params) => params?.row?.status?.value,
    headerName: Localize.get('Event.Status'),
    width: 150,
    editable: false,
    defaultValue: '',
    headerAlign: 'left'
  }
];

export const sessionColumnsDialog = () => [
  {
    field: 'id',
    headerName: Localize.get('Labels.ID'),
    width: 80,
    editable: false,
    defaultValue: '',
    headerAlign: 'left'
  },
  {
    field: 'name',
    headerName: Localize.get('Participants.SessionName'),
    width: 200,
    editable: false,
    defaultValue: '',
    headerAlign: 'left'
  },
  {
    field: 'timeBlockDate',
    headerName: Localize.get('Labels.Date'),
    editable: false,
    width: 100,
    headerAlign: 'left'
  },
  {
    field: 'startTime',
    headerName: Localize.get('Sessions.TimeFrom'),
    width: 100,
    editable: false,
    defaultValue: '',
    headerAlign: 'left'
  },
  {
    field: 'endTime',
    headerName: Localize.get('Sessions.TimeTo'),
    width: 100,
    editable: false,
    defaultValue: '',
    headerAlign: 'left'
  }
];

export default () => {
  return {
    activeIndex: 0, // default tab
    tabs: [
      {
        label: 'Labels.Overview',
        key: TAB_KEYS.OVERVIEW,
        icon: 'info',
        isCountVisible: false
      },
      {
        label: 'Labels.Sessions',
        key: TAB_KEYS.SESSION,
        path: 'participants',
        icon: 'groups',
        options: [
          {
            fieldName: 'add_sessions',
            disabled: false,
            icon: 'add'
          },
          {
            fieldName: 'delete',
            disabled: false,
            icon: 'delete'
          }
        ],
        columns: sessionColumns()
      },
      {
        label: 'Labels.Attachments',
        key: TAB_KEYS.ATTACHMENT,
        path: 'PARTICIPANT',
        icon: 'attachments',
        options: [
          {
            fieldName: 'add',
            disabled: false,
            icon: 'add'
          },
          {
            fieldName: 'delete',
            disabled: false,
            icon: 'delete'
          }
        ],
        columns: [
          {
            field: 'name',
            headerName: 'Labels.Name',
            width: 350,
            editable: false,
            headerAlign: 'left'
          },
          {
            field: 'size',
            headerName: 'Labels.Size',
            width: 350,
            editable: false,
            defaultValue: '0 KB',
            headerAlign: 'left'
          },
          {
            field: 'createdBy',
            headerName: 'Labels.CreatedBy',
            width: 350,
            editable: false,
            defaultValue: 'UnknownUser',
            headerAlign: 'left'
          }
        ]
      },
      {
        label: 'Labels.Notes',
        key: TAB_KEYS.NOTE,
        path: 'PARTICIPANT',
        icon: 'note_alt',
        options: [
          {
            fieldName: 'add_note',
            disabled: false,
            icon: 'add'
          },
          {
            fieldName: 'delete',
            disabled: false,
            icon: 'delete'
          }
        ],
        columns: [
          {
            field: 'type.value',
            headerName: 'Labels.Type',
            width: 350,
            editable: false,
            headerAlign: 'left'
          },
          {
            field: 'text',
            headerName: 'Labels.Text',
            width: 350,
            editable: false,
            headerAlign: 'left'
          }
        ]
      }
    ]
  };
};
