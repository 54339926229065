export default [
  {
    type: 'SideNavigationItem',
    text: 'Launchpad.Planning',
    icon: 'calendar_month',
    sideNavigationSubItems: [
      {
        icon: 'date_range',
        text: 'Launchpad.EventsCalendarNavBar',
        linkName: 'calendar',
        isItemDisabled: false
      },
      {
        icon: 'email',
        text: 'Launchpad.Emails',
        linkName: 'emails',
        isItemDisabled: true
      }
    ]
  },
  {
    type: 'SideNavigationItem',
    text: 'Launchpad.MasterDataNavBar',
    icon: 'stacked_bar_chart',
    sideNavigationSubItems: [
      {
        icon: 'badge',
        text: 'Launchpad.Employees',
        linkName: 'employees',
        isItemDisabled: false
      },
      {
        icon: 'meeting_room',
        linkName: 'meeting-rooms',
        text: 'Launchpad.Rooms',
        isItemDisabled: false
      },
      // {
      //   icon: 'schema',
      //   text: 'Launchpad.OrganisationalStructuresSidebar',
      //   linkName: '/org-structure',
      //   isItemDisabled: false
      // },
      {
        icon: 'contact_mail',
        text: 'Launchpad.ContactsNavBar',
        linkName: 'contacts',
        isItemDisabled: false
      },
      {
        icon: 'insights',
        text: 'Launchpad.Resources',
        linkName: 'resources',
        isItemDisabled: false
      },
      {
        icon: 'business',
        text: 'Launchpad.BusinessPartnersSidebar',
        linkName: 'business-partners',
        isItemDisabled: false
      },
      {
        icon: 'maps',
        text: 'Launchpad.Locations',
        linkName: 'locations',
        isItemDisabled: false
      }
    ]
  },
  {
    type: 'SideNavigationItem',
    text: 'Launchpad.TransactionalDataNavBar',
    icon: 'inventory',
    sideNavigationSubItems: [
      {
        icon: 'auto_stories',
        linkName: 'courses',
        text: 'Launchpad.Courses',
        isItemDisabled: false
      },
      {
        icon: 'event_seat',
        linkName: `ilt-session`,
        text: 'Launchpad.IltSession',
        isItemDisabled: false
      },
      {
        icon: 'format_list_bulleted',
        text: 'Launchpad.Activities',
        linkName: 'activities',
        isItemDisabled: false
      },
      {
        icon: 'insert_invitation',
        text: 'Launchpad.EventsNavBar',
        linkName: 'events',
        isItemDisabled: false
      },
      {
        icon: 'manage_accounts',
        text: 'Launchpad.Participants',
        linkName: 'participants',
        isItemDisabled: false
      }
    ]
  }
];
