import React from 'react';
import Localize from 'react-intl-universal';

import moment from 'moment';

import { alpha } from '@mui/material';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { styled } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import { DATE_TIME_FORMAT } from '@config/inputs';

const EventInformation = ({ data = null }) => {
  const eventName = data?.dayScheduleDto?.eventDto?.name;
  const startDateTimeSession = data?.dayScheduleDto?.eventDto?.startDateTime;
  const endDateTimeSession = data?.dayScheduleDto?.eventDto?.endDateTime;

  const COLOR_OPACITY = 0.12;

  const StyledBox = styled(Box)(({ theme }) => ({
    border: '1px solid',
    borderColor: alpha(theme.palette.primary.main, COLOR_OPACITY),
    borderRadius: 1,
    minHeight: '30vh',
    width: '45vh'
  }));

  const StyledTypography = styled(Typography)(() => ({
    paddingLeft: '1rem',
    paddingTop: '1rem',
    color: '#808080',
    fontWeight: 'bold'
  }));

  return (
    <StyledBox>
      <StyledTypography variant="body2" color="text.primary">
        {Localize.get('Events.EventInformation')}:
      </StyledTypography>
      <ListItem>
        <ListItemText
          sx={{ whiteSpace: 'nowrap' }}
          primary={
            <>
              <Typography
                sx={{ display: 'inline-block', mr: 1 }}
                component="span"
                variant="body2"
                color="text.primary"
              >
                {Localize.get('Events.EventName')}:
              </Typography>
              <Tooltip title={eventName} placement="top" arrow>
                <Typography
                  noWrap={true}
                  sx={{
                    width: `calc(100% - ${Localize.get('Events.EventName').length * 8}px)`,
                    display: 'inline-block',
                    verticalAlign: 'middle'
                  }}
                  component="span"
                  variant="body2"
                  color="text.secondary"
                >
                  {eventName}
                </Typography>
              </Tooltip>
            </>
          }
        />
      </ListItem>
      <Divider />
      <ListItem>
        <ListItemText
          sx={{ whiteSpace: 'nowrap' }}
          primary={
            <>
              <Typography
                sx={{ display: 'inline-block', mr: 1 }}
                component="span"
                variant="body2"
                color="text.primary"
              >
                {Localize.get('Events.StartDate')}:
              </Typography>
              <Typography
                noWrap={true}
                sx={{
                  width: `calc(100% - ${Localize.get('Events.StartDate').length * 8}px)`,
                  display: 'inline-block',
                  verticalAlign: 'middle'
                }}
                component="span"
                variant="body2"
                color="text.secondary"
              >
                {moment(startDateTimeSession).format(DATE_TIME_FORMAT)}
              </Typography>
            </>
          }
        />
      </ListItem>
      <Divider />
      <ListItem>
        <ListItemText
          sx={{ whiteSpace: 'nowrap' }}
          primary={
            <>
              <Typography
                sx={{ display: 'inline-block', mr: 1 }}
                component="span"
                variant="body2"
                color="text.primary"
              >
                {Localize.get('Events.EndDate')}:
              </Typography>
              <Typography
                noWrap={true}
                sx={{
                  width: `calc(100% - ${Localize.get('Events.EndDate').length * 8}px)`,
                  display: 'inline-block',
                  verticalAlign: 'middle'
                }}
                component="span"
                variant="body2"
                color="text.secondary"
              >
                {moment(endDateTimeSession).format(DATE_TIME_FORMAT)}
              </Typography>
            </>
          }
        />
      </ListItem>
      <Divider />
    </StyledBox>
  );
};

export default EventInformation;
