import React, { useCallback, useMemo, useState } from 'react';
import Localize from 'react-intl-universal';
import { useDispatch, useSelector } from 'react-redux';

import moment from 'moment';

import CancelIcon from '@mui/icons-material/Cancel';
import GroupsIcon from '@mui/icons-material/Groups';
import SaveIcon from '@mui/icons-material/Save';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import useTheme from '@mui/styles/useTheme';

import { dateInitFormats } from '@common/Constants';
import { combineDateAndTime, dateToFormat } from '@common/helpers/dates';
import { CONFIRM_ACTIONS, openConfirmDialog } from '@components/ConfirmDialog';

import DragandropCalendar from './DragAndDropCalendar';
import {
  selectDragAndDropDraggableItems,
  selectDragAndDropEvents,
  selectDragAndDropResult,
  setDragAndDropIsDialogOpen,
  selectDragAndDropIsDialogOpen,
  setDragAndDropNonDraggableItems,
  setDragAndDropDraggableItems,
  dragAndDropSaveResult,
  selectDragAndDropFilter,
  selectInitial,
  setDragAndDropFilter,
  setDragAndDropResources,
  selectIsDiagramChanged,
  setMeetingRoomFilterValue,
  setIsDiagramChanged,
  setDragAndDropMeetingRoomsList
} from './dragAndDropDialogSlice';
import { isOverlap } from './utils';

const DragAndDropWithDialog = ({
  dialogTitle = '',
  fetchDataPath = '',
  onIconClick = () => {},
  ...rest
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [draggedEvent, setDraggedEvent] = useState();

  const dragAndDropNonDraggableItems = useSelector(selectDragAndDropEvents);
  const dragAndDropDraggableItems = useSelector(selectDragAndDropDraggableItems);
  const dragAndDropResult = useSelector(selectDragAndDropResult);
  const dragAndDropIsDialogOpen = useSelector(selectDragAndDropIsDialogOpen);
  const dragAndDropDraggableFilter = useSelector(selectDragAndDropFilter);
  const initialItems = useSelector(selectInitial);
  const isDiagramChanged = useSelector(selectIsDiagramChanged);

  const handleDragStart = (event) => setDraggedEvent(event);

  const onSaveButton = () => {
    dispatch(dragAndDropSaveResult())
      .unwrap()
      .then(({ result, resource }) => {
        onIconClick({ target: { name: 'save' } }, result, resource);
        dispatch(setDragAndDropIsDialogOpen(false));
      });
  };

  const isDirty = useMemo(
    () =>
      initialItems?.dragAndDropNonDraggableItems?.length !== dragAndDropNonDraggableItems?.length ||
      isDiagramChanged,
    [
      initialItems?.dragAndDropNonDraggableItems?.length,
      dragAndDropNonDraggableItems?.length,
      dragAndDropIsDialogOpen,
      isDiagramChanged
    ]
  );

  const onCancelButton = () => {
    if (!isDirty) {
      dispatch(setDragAndDropIsDialogOpen(false));
      return;
    }

    dispatch(
      openConfirmDialog({
        title: Localize.get('DragAndDrop.UnsavedChangesTitle'),
        description: Localize.get('DragAndDrop.UnsavedChangesDescription'),
        confirmButton: Localize.get('Buttons.Confirm'),
        cancelButton: Localize.get('Buttons.Cancel'),
        confirmIcon: 'done',
        confirmColor: 'primary'
      })
    )
      .unwrap()
      .then((result) => {
        if (result !== CONFIRM_ACTIONS.Confirm) {
          return;
        }

        const {
          dragAndDropResources,
          dragAndDropDraggableItems,
          dragAndDropNonDraggableItems,
          dragAndDropFilter,
          meetingRoomFilterValue,
          listOfMeetingRooms
        } = initialItems;

        dispatch(setDragAndDropDraggableItems({ items: dragAndDropDraggableItems || [] }));
        dispatch(setDragAndDropResources(dragAndDropResources));
        dispatch(setDragAndDropNonDraggableItems(dragAndDropNonDraggableItems));
        dispatch(setMeetingRoomFilterValue(meetingRoomFilterValue));
        dispatch(setDragAndDropFilter(dragAndDropFilter));
        dispatch(setDragAndDropMeetingRoomsList(listOfMeetingRooms));
        dispatch(setDragAndDropIsDialogOpen(false));

        onIconClick({ target: { name: 'cancel' }, result: dragAndDropResult });
      });
  };

  const dragFromOutsideItem = useCallback(() => draggedEvent, [draggedEvent]);

  const newEvent = (event) => {
    if (event.id) {
      dispatch(setDragAndDropNonDraggableItems([...dragAndDropNonDraggableItems, { ...event }]));
    } else {
      const idList = dragAndDropNonDraggableItems.map((item) => item.id);
      const newId = Math.max(...idList) + 1;
      dispatch(
        setDragAndDropNonDraggableItems([...dragAndDropNonDraggableItems, { ...event, id: newId }])
      );
    }
  };

  const onDropFromOutside = useCallback(
    ({ start, end, resource }) => {
      const { name, predefinedStart, predefinedEnd, id, dayScheduleDto } = draggedEvent || {};
      const { sessionDayDate, venueIds } = dragAndDropDraggableFilter;
      let eventToAdd = null;
      const event = {
        id,
        name,
        resourceId: resource,
        venueId: venueIds,
        isDraggable: true,
        isResizable: true,
        dayScheduleDto: dayScheduleDto
      };

      const isFormStartTimeValue = Boolean(predefinedStart);
      const isFormEndTimeValue = Boolean(predefinedEnd);

      switch (true) {
        case isFormStartTimeValue && !isFormEndTimeValue:
          {
            // Add 15 minute to form start
            const newEnd = moment(combineDateAndTime(sessionDayDate, predefinedStart)).add(15, 'm');
            eventToAdd = {
              ...event,
              start: combineDateAndTime(sessionDayDate, predefinedStart),
              predefinedStart: combineDateAndTime(sessionDayDate, predefinedStart),
              predefinedEnd: newEnd,
              end: newEnd,
              isPredefinedTimeInUse: true
            };
          }
          break;
        case isFormStartTimeValue && isFormEndTimeValue:
          eventToAdd = {
            ...event,
            predefinedStart: combineDateAndTime(sessionDayDate, moment(predefinedStart, 'HH:mm')),
            predefinedEnd: combineDateAndTime(sessionDayDate, moment(predefinedEnd, 'HH:mm')),
            start: combineDateAndTime(sessionDayDate, moment(predefinedStart, 'HH:mm')),
            end: combineDateAndTime(sessionDayDate, moment(predefinedEnd, 'HH:mm')),
            isPredefinedTimeInUse: true
          };
          break;
        case !isFormStartTimeValue && !isFormEndTimeValue: {
          const newEnd = moment(combineDateAndTime(sessionDayDate, start)).add(15, 'm');
          eventToAdd = {
            ...event,
            start: start,
            end: newEnd,
            predefinedStart: null,
            predefinedEnd: null,
            isPredefinedTimeInUse: false
          };
          break;
        }
        default:
          eventToAdd = {
            ...event,
            start,
            end,
            predefinedStart: null,
            predefinedEnd: null,
            isPredefinedTimeInUse: false
          };
      }

      const overlapEvent = isOverlap(
        dragAndDropNonDraggableItems,
        eventToAdd.start,
        eventToAdd.end,
        resource
      );

      // No overlapping elements, place event
      if (!overlapEvent) {
        setDraggedEvent(null);
        const filtered = dragAndDropDraggableItems.filter((ev) => ev.id !== eventToAdd.id);
        dispatch(setDragAndDropDraggableItems({ items: filtered }));
        newEvent(eventToAdd);
        dispatch(setIsDiagramChanged(true));
        return;
      }

      // Events overlapping, show confirm dialog
      dispatch(
        openConfirmDialog({
          title: Localize.get('DragAndDrop.OverlappingTitle'),
          description: Localize.get('DragAndDrop.OverlappingMessage'),
          confirmButton: Localize.get('Buttons.Confirm'),
          cancelButton: Localize.get('Buttons.Cancel'),
          confirmIcon: 'check',
          confirmColor: 'primary',
          cancelColor: 'error'
        })
      )
        .unwrap()
        .then((result) => {
          if (result === CONFIRM_ACTIONS.Cancel) {
            setDraggedEvent(null);
            return;
          }
        });
    },
    [draggedEvent, setDraggedEvent, newEvent]
  );

  const getStartEndTimeLabel = (event) => {
    if (!event) {
      return;
    }

    const { predefinedStart, predefinedEnd, start, end } = event;
    let sentence = '';

    if (predefinedStart || predefinedEnd) {
      if (predefinedStart) {
        sentence += `${dateToFormat(predefinedStart, dateInitFormats.time)}`;
      }

      if (predefinedEnd) {
        sentence += `-${dateToFormat(predefinedEnd, dateInitFormats.time)}`;
        return sentence;
      }

      return sentence;
    }

    if (start || end) {
      if (start) {
        if (typeof start === 'string' || start instanceof String) {
          sentence += `${start}`;
        } else {
          sentence += `${dateToFormat(start, dateInitFormats.time)}`;
        }
      }

      if (end) {
        if (typeof end === 'string' || end instanceof String) {
          sentence += `-${end}`;
        } else {
          sentence += `-${dateToFormat(end, dateInitFormats.time)}`;
        }
        return sentence;
      }

      return sentence;
    }
  };

  return (
    <Dialog
      disableEscapeKeyDown
      fullScreen
      onClose={() => onCancelButton()}
      open={dragAndDropIsDialogOpen}
    >
      <Box display="flex" flexDirection="column">
        <DialogTitle sx={{ textAlign: 'left' }}>{Localize.get(dialogTitle)}</DialogTitle>

        <DialogContent dividers sx={{ display: 'flex', flexDirection: 'row', overflow: 'hidden' }}>
          <List dense sx={{ minWidth: (theme) => theme.sizes.dragAndDropSidebar }}>
            <Typography sx={{ mb: 1 }} variant="h5">
              {Localize.get('IltSession.SessionDay')}
            </Typography>

            {dragAndDropDraggableItems?.map((event) => (
              <ListItem
                divider
                sx={{
                  backgroundColor: theme.palette.success.dark,
                  borderRadius: '3px',
                  opacity: 0.9
                }}
                onDragStart={() => handleDragStart(event)}
                draggable
                key={event?.id}
                disablePadding
              >
                <ListItemIcon fontSize="small" sx={{ ml: 1, minWidth: '32px' }}>
                  <GroupsIcon
                    sx={{ color: (theme) => theme.palette.common.black }}
                    fontSize="small"
                  />
                </ListItemIcon>
                <ListItemText
                  primaryTypographyProps={{
                    maxWidth: '180px',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis'
                  }}
                  fontSize="16px"
                  primary={event?.name}
                  secondary={getStartEndTimeLabel(event)}
                />
              </ListItem>
            ))}
          </List>
          <DragandropCalendar
            fetchDataPath={fetchDataPath}
            withDialogProps={{
              newEvent,
              onDropFromOutside,
              dragFromOutsideItem
            }}
            {...rest}
          />
        </DialogContent>
      </Box>

      <Box
        size="small"
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          margin: '10px'
        }}
      >
        <Button
          disableFocusRipple
          data-test-id="cancel"
          variant="outlined"
          size="small"
          onClick={onCancelButton}
          color="error"
          sx={{ mr: 1 }}
          startIcon={<CancelIcon />}
        >
          {Localize.get('Buttons.Discard')}
        </Button>
        <Button
          disabled={!isDirty}
          disableFocusRipple
          data-test-id="save"
          size="small"
          variant="outlined"
          onClick={onSaveButton}
          sx={{ mr: 1 }}
          startIcon={<SaveIcon />}
        >
          {Localize.get('Buttons.Save')}
        </Button>
      </Box>
    </Dialog>
  );
};

export default DragAndDropWithDialog;
