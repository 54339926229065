import React from 'react';
import Localize from 'react-intl-universal';

import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';

import AccountTypes from '@common/network/AccountTypes';
import { FilterSelect, FilterLabel } from '@components/TokenValueModalTrigger/components/styled';

const BusinessPartnersTypesDropdown = ({ queryState, itemHandler }) => (
  <Box>
    <FormControl>
      <FilterLabel id="demo-simple-select-label">{Localize.get('Labels.Type')}</FilterLabel>
      <FilterSelect
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={queryState.type}
        label={Localize.get('Labels.Type')}
        onChange={(event) => {
          itemHandler({ items: [{ columnField: 'type', value: event.target.value }] });
        }}
      >
        <MenuItem data-test-id={AccountTypes.PERSON} value={AccountTypes.PERSON}>
          {Localize.get('BusinessPartners.IndividualCustomer')}
        </MenuItem>
        <MenuItem data-test-id={AccountTypes.ORGANIZATION} value={AccountTypes.ORGANIZATION}>
          {Localize.get('BusinessPartners.Account')}
        </MenuItem>
      </FilterSelect>
    </FormControl>
  </Box>
);

export default BusinessPartnersTypesDropdown;
