import Localize from 'react-intl-universal';

import moment from 'moment';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import { dateInitFormats } from '@common/Constants';
import { Popup as MobiPopup } from '@mobiscroll/react';

import Chip from '../Chip';

const StyledContainer = styled(Box)(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'light' ? theme.palette.common.white : theme.palette.common.black,
  padding: `0 0 0 ${theme.spacing(0.5)}`,
  margin: 0
}));

const StyledInnerContainer = styled(Box)(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'light' ? theme.palette.common.white : theme.palette.common.black,
  margin: theme.spacing(2)
}));

const Popup = ({ isOpen, anchor, onMouseEnter, onMouseLeave, hoverEvent, information }) => {
  return (
    <MobiPopup
      display="anchored"
      isOpen={isOpen}
      anchor={anchor}
      touchUi={false}
      showOverlay={false}
      contentPadding={false}
      closeOnOverlayClick={false}
      width={350}
    >
      <StyledContainer
        display="flex"
        flexDirection="column"
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <StyledInnerContainer display="flex" flexDirection="column" justifyItems="center">
          <Box display="flex" flexDirection="row" justifyContent="space-between">
            <Typography variant="h5" fontWeight={600}>
              {hoverEvent?.title}
            </Typography>
            <Typography variant="body" fontSize="14px" fontWeight={600}>
              {[
                Localize.get('Labels.Status'),
                `${hoverEvent?.nightsBooked}/${hoverEvent?.nightsToBeBooked}`
              ].join(' ')}
            </Typography>
          </Box>
          <Box mb={1} display="flex" flexDirection="row" justifyContent="space-between">
            <Typography variant="body" fontSize="14px">
              {hoverEvent?.company}
            </Typography>
            <Chip current={hoverEvent?.nightsBooked} total={hoverEvent?.nightsToBeBooked} />
          </Box>
          <Box mb={1} display="flex" flexDirection="row" justifyContent="space-between">
            <Typography variant="body" fontSize="14px">
              {Localize.get('Hotel.RoomType')}
            </Typography>
            <Typography variant="body" fontSize="14px">
              {information?.accommodation?.roomType?.value}
            </Typography>
          </Box>
          <Divider sx={{ mb: 1 }} />
          <Box mb={1} display="flex" flexDirection="row" justifyContent="space-between">
            <Typography variant="body" fontSize="14px">
              {moment(hoverEvent?.arrivalDate).format(dateInitFormats.monthDay)}-
              {moment(hoverEvent?.departureDate).format(dateInitFormats.monthDay)}
            </Typography>
            <Typography variant="body" fontSize="14px">
              {Localize.get('IltSession.Room')} : {hoverEvent?.resource?.split('.')[0]}
            </Typography>
          </Box>
        </StyledInnerContainer>
      </StyledContainer>
    </MobiPopup>
  );
};

export default Popup;
