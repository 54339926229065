import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  Checkbox,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  MenuItem
} from '@mui/material';

import { HOTEL_PATHS } from '@common/network/ApiPaths';
import TextInput from '@components/TextInput';
import useIsMounted from '@hooks/isMounted/isMounted';
import { getByPathAndParams } from '@services/BaseApi';

import {
  setNewAccommodation,
  selectNewAccommodation,
  setErrors,
  selectErrors
} from '../accommodationTableSlice';
import { accommodationSchema } from './utils/accommodationSchema';

export default function RowCreate({ classes, accommodationList }) {
  const setStateIfMounted = useIsMounted();
  const dispatch = useDispatch();
  const accommodation = useSelector(selectNewAccommodation);
  const validationErrors = useSelector(selectErrors);
  const [touched, setTouched] = useState([]);

  const [roomTypeOptions, setRoomTypeOptions] = useState([]);

  const handleOnChange = (e, fieldName) => {
    dispatch(setNewAccommodation({ ...accommodation, [fieldName]: e.target.value }));
  };

  useEffect(() => {
    const fetchRoomTypes = async () => {
      try {
        const { data } = await getByPathAndParams({
          path: HOTEL_PATHS.ROOM_TYPES
        });
        setStateIfMounted(setRoomTypeOptions, data);
      } catch (err) {
        console.error('err', err);
      }
    };
    fetchRoomTypes();
  }, []);

  useEffect(() => {
    accommodationSchema(accommodationList)
      .validate(accommodation, { abortEarly: false })
      .then(() => {
        dispatch(setErrors({}));
      })
      .catch((err) => {
        let errors = err.inner.reduce((errorsTemp, currentErr) => {
          errorsTemp[currentErr.path] = currentErr.errors;
          return errorsTemp;
        }, {});
        dispatch(setErrors(errors));
      });
  }, [accommodation]);

  const onTouched = (fieldName) => {
    if (!touched.includes(fieldName)) {
      setTouched((prevState) => [...prevState, fieldName]);
    }
  };

  return (
    <TableContainer sx={{ overflowX: 'hidden' }}>
      <Table className={classes.customTable}>
        <TableBody>
          <TableRow sx={{ overflowX: 'hidden' }}>
            <TableCell
              className={classes.cellCheckbox}
              sx={{
                width: 5,
                maxWidth: 5,
                minWidth: 5
              }}
            >
              <Checkbox checked={false} disabled={true}></Checkbox>
            </TableCell>
            <TableCell
              sx={{
                width: 100,
                maxWidth: 120,
                align: 'left',
                minWidth: 100
              }}
            >
              <Typography>{''}</Typography>
            </TableCell>

            {/* room type */}
            <TableCell
              sx={{
                width: 250,
                maxWidth: 250,
                padding: '0 !important',
                align: 'left',
                minWidth: 250
              }}
            >
              <Select
                sx={{
                  width: 200,
                  height: 40
                }}
                labelId="roomType-label"
                name="roomType"
                value={roomTypeOptions?.length ? accommodation?.roomType || '' : ''}
                onChange={(e) => handleOnChange(e, 'roomType')}
                onBlur={() => onTouched('roomType')}
                error={Boolean(validationErrors?.roomType && touched.includes('roomType'))}
              >
                {roomTypeOptions.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.value}
                  </MenuItem>
                ))}
              </Select>
            </TableCell>

            {/* max occupancy */}
            <TableCell
              sx={{
                width: 250,
                maxWidth: 250,
                padding: '0 !important',
                align: 'left',
                minWidth: 250
              }}
            >
              <TextInput
                variant="outlined"
                type="number"
                name="maxOccupancy"
                InputLabelProps={{
                  shrink: true
                }}
                onChange={(e) => handleOnChange(e, 'maxOccupancy')}
                onBlur={() => onTouched('maxOccupancy')}
                error={Boolean(validationErrors?.maxOccupancy && touched.includes('maxOccupancy'))}
                label={
                  Boolean(touched.includes('maxOccupancy')) && validationErrors?.maxOccupancy
                    ? validationErrors?.maxOccupancy
                    : ''
                }
              >
                {accommodation?.maxOccupancy}
              </TextInput>
            </TableCell>

            {/* description */}
            <TableCell
              sx={{
                padding: '0 !important',
                align: 'left'
              }}
            >
              <TextInput
                name="description"
                variant="outlined"
                type="string"
                InputLabelProps={{
                  shrink: true
                }}
                onChange={(e) => handleOnChange(e, 'description')}
                value={accommodation?.description}
                onBlur={() => onTouched('description')}
                error={Boolean(validationErrors?.description && touched.includes('description'))}
                label={
                  Boolean(touched.includes('description')) && validationErrors?.description
                    ? validationErrors?.description
                    : ''
                }
              />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
