import { DIALOG_PATHS } from '@common/network/ApiPaths';

export default [
  {
    groupName: 'IltSession.SessionDayInformation',
    fields: [
      {
        label: 'IltSession.IltSessionDayName',
        name: 'name',
        required: true,
        disabled: false,
        component: 'text'
      },
      {
        label: 'IltSession.Date',
        name: 'dayDate',
        required: true,
        disabled: false,
        component: 'datepicker'
      },
      {
        label: 'IltSession.TimeFrom',
        name: 'startTime',
        required: true,
        disabled: false,
        component: 'timepicker'
      },
      {
        label: 'IltSession.TimeTo',
        name: 'endTime',
        required: true,
        disabled: false,
        component: 'timepicker'
      },
      {
        label: 'IltSession.Room',
        name: 'meetingRoomId',
        required: false,
        disabled: false,
        dialogTitle: 'IltSession.SearchRoom',
        component: 'dragAndDropDialogAndInput',
        fetchDataPath: DIALOG_PATHS.MEETING_ROOMS,
        value: ''
      },
      {
        label: 'IltSession.Instructor',
        name: 'instructorIds',
        required: true,
        disabled: false,
        dialogTitle: 'IltSession.SearchInstructor',
        component: 'tokenValueArray',
        fetchDataPath: DIALOG_PATHS.INSTRUCTOR,
        value: '',
        dialogColumns: [
          {
            field: 'id',
            headerName: 'Labels.ID',
            width: 100,
            editable: false,
            defaultValue: '',
            headerAlign: 'left',
            sortable: false
          },
          {
            field: 'firstName',
            headerName: 'Labels.FirstName',
            width: 350,
            editable: false,
            defaultValue: '',
            headerAlign: 'left',
            sortable: false
          },
          {
            field: 'lastName',
            headerName: 'Labels.LastName',
            width: 350,
            editable: false,
            defaultValue: '',
            headerAlign: 'left',
            sortable: false
          }
        ]
      },
      {
        label: 'IltSession.DetailsInformation',
        name: 'details',
        required: false,
        disabled: false,
        component: 'text'
      }
    ]
  }
];
