import Localize from 'react-intl-universal';

import List from '@mui/material/List';

import { ACTION_MODES } from '@common/Constants';
import { capitalizeFirstLetter } from '@common/helpers/string';
import { UIEntityTypes } from '@common/network/EntityTypes';

const successMessageFormatter = (entityType, actionMode) => {
  const entityLabel = Localize.get(UIEntityTypes[entityType].label);
  switch (actionMode) {
    case ACTION_MODES.Create:
      return ` ${Localize.get('SuccessMessage.Create', {
        entity: entityLabel
      })}`;

    case ACTION_MODES.Edit:
      return ` ${Localize.get('SuccessMessage.Edit', {
        entity: entityLabel
      })}`;

    case ACTION_MODES.Delete:
      return ` ${Localize.get('SuccessMessage.Delete', {
        entity: entityLabel
      })}`;

    case ACTION_MODES.Upload:
      return ` ${Localize.get('SuccessMessage.Upload', {
        entity: entityLabel
      })}`;

    case ACTION_MODES.Add:
      return ` ${Localize.get('SuccessMessage.Add', {
        entity: entityLabel
      })}`;

    case ACTION_MODES.Removed:
      return ` ${Localize.get('SuccessMessage.Remove', {
        entity: entityLabel
      })}`;
  }
};

const errorMessageFormatter = (errorObj, entityType, actionMode) => {
  const entityLabel = Localize.get(UIEntityTypes[entityType].label);
  if (Object.prototype.hasOwnProperty.call(errorObj, 'errors')) {
    const { errors = [] } = errorObj;

    return errors.map((errorMessageObj, index) => (
      <List key={index}>{`${capitalizeFirstLetter(errorMessageObj.field)} ${
        errorMessageObj.defaultMessage
      }`}</List>
    ));
  }

  if (errorObj?.response?.data?.message || errorObj?.message) {
    return errorObj?.response?.data?.message || errorObj.message;
  }

  if (actionMode == ACTION_MODES.Create) {
    return ` ${Localize.get('InitializeError.Create', {
      entity: entityLabel
    })}`;
  } else if (actionMode == ACTION_MODES.Edit) {
    return ` ${Localize.get('InitializeError.Edit', {
      entity: entityLabel
    })}`;
  } else if (actionMode == ACTION_MODES.Delete) {
    return ` ${Localize.get('InitializeError.Delete', {
      entity: entityLabel
    })}`;
  }
};

export { errorMessageFormatter, successMessageFormatter };
