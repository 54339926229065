import React, { useState, useEffect, useReducer } from 'react';
import Localize from 'react-intl-universal';

import { get } from 'lodash';

import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
import { DataGrid } from '@mui/x-data-grid';

import getLocalesText from '@common/helpers/tables/getLocalesText';
import { TAB_PATHS } from '@common/network/ApiPaths';
import TableToolbar from '@components/TableToolbar';
import { SEARCH_INPUT_DELAY } from '@config/inputs';
import useFetching from '@hooks/fetching/fetching';
import useDebounce from '@hooks/handlers/useDebounce';
import useHandleOnChange from '@hooks/handlers/useHandleOnChange';
import { sessionColumnsDialog } from '@pages/Participants/components/Tabs/tabsConfig';
import { getByPathAndParams } from '@services/BaseApi';

import { setSelectionDialogModel } from '../store/actions';
import { sessionsReducer, sessionsState } from '../store/reducer';

const StyledNoteDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2)
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  }
}));

const SearchContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  position: 'relative',
  marginLeft: theme.spacing(1),
  marginRight: theme.spacing(1)
}));

const InputContainer = styled(TextField)(({ theme }) => ({
  marginLeft: theme.spacing(1),
  marginRight: theme.spacing(1)
}));

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
    alignItems: 'center'
  },
  content: {
    margin: theme.spacing(4),
    padding: theme.spacing(2)
  },
  container: {
    height: 400,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}));

const SessionDialogTitle = ({ children, onClose, ...rest }) => {
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...rest}>
      {children}
      <IconButton
        onClick={onClose}
        data-test-id="Button-closeTitle"
        aria-label="close"
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[50]
        }}
      >
        <CloseIcon />
      </IconButton>
    </DialogTitle>
  );
};

const SessionDialog = ({ open, saveSessions, onClose, disabler, eventId, selectedSessionIds }) => {
  const classes = useStyles();

  const [queryState, setQueryState] = useState({
    id: '',
    nameSearch: '',
    page: 0,
    size: 10
  });
  // const [selectedSessions, setSelectedSessions] = useState([]);

  const [{ selectionDialogModel }, dispatch] = useReducer(sessionsReducer, sessionsState);

  const [myConfig] = useState(sessionColumnsDialog());

  const [listOfSessions, loading, reFetchSessions, setListOfSessions, pagination] = useFetching(
    getByPathAndParams.bind(null, {
      path: TAB_PATHS.SESSIONS.GET_SESSIONS,
      pathVariables: { eventId },
      params: { search: queryState?.nameSearch, page: queryState?.page, size: queryState?.size }
    }),
    true
  );

  useDebounce(reFetchSessions, SEARCH_INPUT_DELAY, [queryState]);

  const { itemHandler } = useHandleOnChange(setQueryState);

  const handlePagination = (field, value) => {
    itemHandler({ target: { name: field, value } });
  };

  const closeDialog = () => {
    setQueryState({ ...queryState, nameSearch: '' });
    onClose();
  };

  const onSaveSession = () => {
    setQueryState({ ...queryState, nameSearch: '' });
    saveSessions(selectionDialogModel);
  };

  useEffect(() => {
    if (open) {
      reFetchSessions();
    } else {
      dispatch(setSelectionDialogModel([]));
      setListOfSessions([]);
    }
  }, [open]);

  return (
    <StyledNoteDialog aria-labelledby="customized-dialog-title" open={open} fullWidth>
      <SessionDialogTitle id="customized-dialog-title" onClose={closeDialog}>
        {Localize.get('Participants.SearchSessions')}
      </SessionDialogTitle>
      <DialogContent dividers>
        <SearchContainer>
          <InputContainer
            data-test-id="InputContainer-nameSearch"
            onChange={itemHandler}
            value={queryState.name}
            name="nameSearch"
            color="primary"
            label={Localize.get('Labels.SearchByName')}
            variant="outlined"
            margin="dense"
            size="small"
            fullWidth
            type="input"
          />
        </SearchContainer>
        <div className={classes.container}>
          <DataGrid
            keepNonExistentRowsSelected
            data-test-id="DataGrid-sessionTab"
            loading={loading}
            rows={listOfSessions ?? []}
            columns={
              myConfig?.length
                ? [
                    ...myConfig.map((column) => ({
                      ...column,
                      renderCell: (params) => {
                        return <div className="rowitem">{get(params.row, params.field)}</div>;
                      }
                    }))
                  ]
                : []
            }
            paginationMode="server"
            selectionModel={selectionDialogModel}
            sortingMode="server"
            filterMode="server"
            page={queryState?.page}
            pageSize={queryState?.size}
            rowCount={pagination?.totalElements || 0}
            onPageChange={(value) => handlePagination('page', value)}
            rowsPerPageOptions={[10]}
            components={{
              Toolbar: () => <TableToolbar isFilterVisible={false} />
            }}
            localeText={getLocalesText(Localize)}
            disableSelectionOnClick
            checkboxSelection
            isRowSelectable={(params) => !selectedSessionIds.includes(params?.row?.id)}
            onSelectionModelChange={(values) => dispatch(setSelectionDialogModel(values))}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          data-test-id="Button-save"
          disabled={!selectionDialogModel.length || disabler?.confirm}
          onClick={onSaveSession}
        >
          {Localize.get('Buttons.Add')}
        </Button>
        <Button
          data-test-id="Button-close"
          color="error"
          onClick={closeDialog}
          disabled={disabler?.cancel}
        >
          {Localize.get('Buttons.Cancel')}
        </Button>
      </DialogActions>
    </StyledNoteDialog>
  );
};

export default SessionDialog;
