import Localize from 'react-intl-universal';

import { isNil } from 'lodash';
import moment from 'moment';

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { ACTION_MODES, SORT_DIRECTION, dateInitFormats } from '@common/Constants';
import { errorMessageFormatter } from '@common/helpers/MessageFormatter';
import { ILT_SESSION_PATHS } from '@common/network/ApiPaths';
import EntityTypes from '@common/network/EntityTypes';
import { scrubFiltersForBE } from '@components/FilterDialog/filtersSlice';
import { SnackbarSeverityTypes, showSnackbar } from '@components/Snackbar/snackbarSlice';
import {
  deleteByPath,
  getByPathAndParams,
  patchByPathAndData,
  postByPathAndData
} from '@services/BaseApi';

import { BOOKING_SLOT_COLORS } from './components/styled';

export const initialState = {
  isEdit: false,
  contingentBookings: [],
  resources: [],
  listOfParticipants: [],
  viewSettings: {},
  isParticipantsListLoading: false,
  totalPages: 0,
  totalElements: 0,
  filter: {
    search: '',
    sortBy: 'participantBookingStatus',
    sortDirection: SORT_DIRECTION.ASCENDING,
    page: 0,
    size: 10
  }
};

export const fetchBookings = createAsyncThunk(
  'booking/fetchAll',
  async ({ sessionId, contingentId }, { rejectWithValue }) => {
    return getByPathAndParams({
      path: ILT_SESSION_PATHS.GET_CONTINGENT_BOOKINGS,
      pathVariables: { sessionId, contingentId }
    })
      .then(({ data }) => data)
      .catch((error) => rejectWithValue(error));
  }
);

export const fetchParticipants = createAsyncThunk(
  'booking/fetchAllParticipants',
  async ({ filter, sessionId, contingentId }, { rejectWithValue, getState }) => {
    const { isActive, filter: advancedFilters } = getState()?.filterDialog;

    return getByPathAndParams({
      path: ILT_SESSION_PATHS.GET_PARTICIPANTS_ON_CONTINGENT_BOOKINGS,
      params: isActive
        ? { filters: { advancedFilters: scrubFiltersForBE(advancedFilters, false) }, ...filter }
        : filter,
      pathVariables: { sessionId, contingentId }
    })
      .then(({ data }) => data)
      .catch((error) => rejectWithValue(error.response.data));
  }
);

export const addContingentBooking = createAsyncThunk(
  'booking/addContingentBooking',
  async ({ sessionId, contingentId, data }, { rejectWithValue, dispatch, getState }) => {
    return postByPathAndData({
      path: ILT_SESSION_PATHS.ADD_CONTINGENT_BOOKINGS,
      pathVariables: { sessionId },
      data
    })
      .then(({ data }) => {
        const { eventParticipant } = data?.content[0];
        const { listOfParticipants, filter } = getState()?.booking;
        const { isActive, filter: advancedFilters } = getState()?.filterDialog;
        let { nightsBooked, nightsToBeBooked } = listOfParticipants.find(
          (p) => p.id === eventParticipant.id
        );

        if (++nightsBooked === nightsToBeBooked) {
          dispatch(
            fetchParticipants({
              filter: isActive
                ? {
                    filters: { advancedFilters: scrubFiltersForBE(advancedFilters, false) },
                    ...filter
                  }
                : filter,
              sessionId,
              contingentId
            })
          );
        }
        dispatch(
          showSnackbar({
            message: Localize.get('SuccessMessage.MoveSuccess', {
              entity: Localize.get('ParticipantsTile.Item')
            }),
            severity: SnackbarSeverityTypes.SUCCESS
          })
        );
        return data;
      })
      .catch((error) => {
        dispatch(
          showSnackbar({
            message: errorMessageFormatter(error, EntityTypes.PARTICIPANT, ACTION_MODES.Delete),
            severity: SnackbarSeverityTypes.ERROR
          })
        );

        return rejectWithValue(error.response);
      });
  }
);

export const deleteContingentBookings = createAsyncThunk(
  'booking/deleteContingentBookings',
  // eslint-disable-next-line no-unused-vars
  async ({ sessionId, contingentId, contingentIds, events }, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await deleteByPath({
        path: ILT_SESSION_PATHS.DELETE_CONTINGENT_BOOKINGS,
        pathVariables: { id: sessionId, contingentId, contingentIds }
      });
      dispatch(
        showSnackbar({
          message: Localize.get('SuccessMessage.Delete', {
            entity: Localize.get('ParticipantsTile.Item')
          }),
          severity: SnackbarSeverityTypes.SUCCESS
        })
      );
      return data;
    } catch (error) {
      dispatch(
        showSnackbar({
          message: errorMessageFormatter(error, EntityTypes.PARTICIPANT, ACTION_MODES.Delete),
          severity: SnackbarSeverityTypes.ERROR
        })
      );
      return rejectWithValue(error.response);
    }
  }
);

export const updateContingentBooking = createAsyncThunk(
  'booking/updateContingentBooking',
  async (
    { sessionId, contingentId, contingentBookingId, event },
    { rejectWithValue, dispatch }
  ) => {
    return patchByPathAndData({
      path: ILT_SESSION_PATHS.UPDATE_CONTINGENT_BOOKINGS,
      pathVariables: { sessionId, contingentId, contingentBookingId },
      data: {
        bookedNightDate: moment(event.start).format(dateInitFormats.basicDate),
        roomPosition: parseInt(event.resource.split('.')[0], 10),
        bedPosition: parseInt(event.resource.split('.')[1], 10)
      }
    })
      .then(({ data }) => {
        dispatch(
          showSnackbar({
            message: Localize.get('SuccessMessage.MoveSuccess', {
              entity: Localize.get('ParticipantsTile.Item')
            }),
            severity: SnackbarSeverityTypes.SUCCESS
          })
        );
        return data;
      })
      .catch((error) => {
        dispatch(
          showSnackbar({
            message: errorMessageFormatter(error, EntityTypes.PARTICIPANT, ACTION_MODES.Delete),
            severity: SnackbarSeverityTypes.ERROR
          })
        );

        return rejectWithValue(error.response);
      });
  }
);

export const bookingSlice = createSlice({
  name: 'booking',
  initialState,
  reducers: {
    resetState: () => initialState,
    setIsEdit: (state, { payload }) => {
      state.isEdit = payload;
      state.contingentBookings = state.contingentBookings.map((cb) => ({
        ...cb,
        editable: payload
      }));
    },
    revertBookingCreate: (state, { payload }) => {
      state.contingentBookings = [...state.contingentBookings].filter(
        (oldEvent) => payload.id !== oldEvent.id
      );
    },
    revertContingentBookingsUpdate: (state, { payload }) => {
      state.contingentBookings = [...state.contingentBookings].map((event) => {
        if (event.id !== payload.id) {
          return event;
        }

        return {
          ...event,
          end: payload.end,
          start: payload.start,
          resource: payload.resource
        };
      });
    },
    setIsParticipantsLoading: (state, { payload }) => {
      state.isParticipantsListLoading = payload;
    },
    setFilterParams: (state, action) => {
      let newFilterValues = {};

      // Case when search value is reset to empty and search bar is closed
      if (action.payload.key === 'search' && !action.payload.value && !state.filter.search) {
        state.isParticipantsListLoading = false;
        return;
      }

      if (Array.isArray(action.payload)) {
        newFilterValues = action.payload.reduce(
          (obj, item) => ((obj[item.key] = item.value), obj),
          {}
        );
      } else {
        newFilterValues = { [action.payload.key]: action.payload.value };
      }

      state.filter = { ...state.filter, ...newFilterValues, page: 0 };
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchBookings.fulfilled, (state, { payload, meta }) => {
        const { contingent } = payload;
        const { startDate, endDate } = contingent;

        if (!startDate || !endDate) {
          state.isLoading = false;
          return;
        }

        const momentArrivalDate = moment(startDate, 'YYYY-MM-DD');
        const momentDepartureDate = moment(endDate, 'YYYY-MM-DD');
        const timelineArrival = moment(momentArrivalDate).add(1, 'd').toDate();
        const timelineDeparture = moment(momentDepartureDate).toDate();
        const difference = moment(timelineDeparture).diff(timelineArrival);
        const size = moment.duration(difference).add(1, 'd').asDays();
        const numberOfBeds = contingent?.accommodation?.maxOccupancy;
        const numberOfRooms = contingent?.contingent;

        // Set contingent bookings (already assigned Participant slots)
        state.contingentBookings =
          payload.contingentBookings.map(
            ({ bookedNightDate, participant, roomPosition, bedPosition, id }) => ({
              start: bookedNightDate,
              arrivalDate: participant.arrivalDate,
              departureDate: participant.departureDate,
              title: `${participant.firstName} ${participant.lastName}`,
              company: participant.company || 'N/A',
              resource: `${roomPosition}.${bedPosition}`,
              id: id,
              nightsToBeBooked: participant.nightsToBeBooked,
              nightsBooked: participant.nightsBooked,
              allDay: true,
              editable: false,
              participantId: participant.id
            })
          ) ?? initialState.contingentBookings;

        // Set contingent bookings (already assigned Participant slots)
        state.contingentBookings =
          payload.contingentBookings.map(
            ({ bookedNightDate, participant, roomPosition, bedPosition, id }) => ({
              start: bookedNightDate,
              arrivalDate: participant.arrivalDate,
              departureDate: participant.departureDate,
              title: `${participant.firstName} ${participant.lastName}`,
              company: participant.company || 'N/A',
              resource: `${roomPosition}.${bedPosition}`,
              id: id,
              nightsToBeBooked: participant.nightsToBeBooked,
              nightsBooked: participant.nightsBooked,
              allDay: true,
              editable: false,
              participantId: participant.id
            })
          ) ?? initialState.contingentBookings;

        state.resources = [
          ...Array.from({ length: numberOfRooms }, (_, i) => {
            const room = { id: i + 1 };
            const color =
              BOOKING_SLOT_COLORS[Math.floor(Math.random() * BOOKING_SLOT_COLORS.length)];

            return [...Array.from({ length: numberOfBeds })].map((e, i) => ({
              id: `${room.id}.${i + 1}`,
              name: i + 1 === 1 ? `${Localize.get('IltSession.Room')} ${room.id}` : '',
              color
            }));
          })
        ].flat();

        state.viewSettings = {
          timeline: {
            resolutionHorizontal: 'day',
            type: 'day',
            size,
            eventList: true
          },
          selectedDate: timelineArrival,
          refDate: timelineDeparture,
          fromTo: {
            arrivalDate: moment(startDate),
            departureDate: moment(endDate)
          },
          header: { ...payload.contingent, hotelName: meta?.arg?.hotel?.hotel?.name }
        };

        state.isLoading = false;
      })
      .addCase(fetchBookings.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchBookings.rejected, (state) => {
        state.data = [];
        state.isLoading = false;
      })
      .addCase(fetchParticipants.fulfilled, (state, { payload }) => {
        state.listOfParticipants =
          payload.content?.map((p) => ({
            ...p,
            title: `${p.firstName} ${p.lastName}`,
            allDay: true
          })) ?? initialState.listOfParticipants;
        state.isParticipantsListLoading = false;
        state.totalPages = payload?.totalPages || state.totalPages;
        state.totalElements = isNil(payload?.numberOfElements)
          ? state.numberOfElements
          : payload?.numberOfElements;
        state.filter.page = payload?.pageable?.pageNumber;
        state.filter.size = payload?.pageable?.pageSize;
        state.isParticipantsListLoading = false;
      })
      .addCase(fetchParticipants.pending, (state) => {
        state.isParticipantsListLoading = true;
      })
      .addCase(fetchParticipants.rejected, (state) => {
        state.listOfParticipants = [];
        state.isParticipantsListLoading = false;
      })
      .addCase(addContingentBooking.fulfilled, (state, { payload, meta }) => {
        const { content } = payload;
        const { event } = meta.arg;

        // Add new contingent booking (participant to slot)
        state.contingentBookings = [
          ...state.contingentBookings,
          {
            start: content[0].bookedNightDate,
            arrivalDate: event.arrivalDate,
            departureDate: event.departureDate,
            title: `${event.firstName} ${event.lastName}`,
            company: event?.company,
            resource: `${content[0].roomPosition}.${content[0].bedPosition}`,
            id: content[0].id,
            nightsToBeBooked: event.nightsToBeBooked,
            nightsBooked: event.nightsBooked,
            allDay: true,
            editable: true,
            participantId: content[0].eventParticipant.id
          }
        ];

        // Update nightsBooked for contingent bookings
        state.contingentBookings.map((p) => ({
          ...p,
          ...(p.participantId === payload.content[0].eventParticipant.id
            ? p.nightsBooked++
            : p.nightsBooked)
        }));

        // Update nightsBooked for list of participants nights booked
        state.listOfParticipants.map((p) => ({
          ...p,
          ...(p.id === payload.content[0].eventParticipant.id ? p.nightsBooked++ : p.nightsBooked)
        }));

        state.isParticipantsListLoading = false;
      })
      .addCase(addContingentBooking.pending, (state) => {
        state.isParticipantsListLoading = true;
      })
      .addCase(addContingentBooking.rejected, (state, { meta }) => {
        const { event } = meta.arg;
        state.contingentBookings = [...state.contingentBookings].filter(
          (oldEvent) => event.id !== oldEvent.id
        );
        state.isParticipantsListLoading = false;
      })
      .addCase(deleteContingentBookings.fulfilled, (state, { meta }) => {
        const { selectedEvents = [] } = meta?.arg;

        // Remove deleted ones
        for (const event of selectedEvents) {
          state.contingentBookings = [...state.contingentBookings].filter(
            (ev) => ev.id !== event.id
          );
        }

        // Update nights booked for contingent bookings
        state.contingentBookings.map((cb) => {
          const nightsBookedCount = selectedEvents.filter(
            (sel) => sel.participantId === cb.participantId
          ).length;

          return {
            ...cb,
            nightsBooked: (cb.nightsBooked -= nightsBookedCount)
          };
        });

        // Update nights booked for participants
        state.listOfParticipants.map((p) => {
          const nightsBookedCount = selectedEvents.filter(
            (sel) => sel.participantId === p.id
          ).length;

          return {
            ...p,
            nightsBooked: (p.nightsBooked -= nightsBookedCount)
          };
        });

        state.isParticipantsListLoading = false;
      })
      .addCase(deleteContingentBookings.pending, (state) => {
        state.isParticipantsListLoading = true;
      })
      .addCase(deleteContingentBookings.rejected, (state) => {
        state.isParticipantsListLoading = false;
      })
      .addCase(updateContingentBooking.fulfilled, (state, { payload }) => {
        state.contingentBookings = state.contingentBookings.map((cb) => ({
          ...cb,
          ...(cb.id === payload.id
            ? {
                ...cb,
                start: payload.bookedNightDate,
                end: payload.bookedNightDate,
                resource: `${payload.roomPosition}.${payload.bedPosition}`
              }
            : { ...cb })
        }));
      })
      .addCase(updateContingentBooking.rejected, (state, { payload }) => {
        state.contingentBookings = state.contingentBookings.map((cb) => ({
          ...cb,
          ...(cb.id === payload.id
            ? {
                ...cb,
                end: payload.end,
                start: payload.start,
                resource: payload.resource
              }
            : { ...cb })
        }));
      });
  }
});

export const selectIsEdit = (state) => state.booking.isEdit;
export const selectViewSettings = (state) => state.booking.viewSettings;
export const selectListOfParticipants = (state) => state.booking.listOfParticipants;
export const selectIsParticipantListLoading = (state) => state.booking.isParticipantsListLoading;
export const selectFilter = (state) => state.booking.filter;
export const selectTotalElements = (state) => state.booking.totalElements;
export const selectTotalPages = (state) => state.booking.totalPages;
export const selectContingentBookings = (state) => state.booking.contingentBookings;
export const selectResources = (state) => state.booking.resources;

const { reducer, actions } = bookingSlice;

export const {
  setIsEdit,
  setFilterParams,
  setIsParticipantsLoading,
  resetState,
  revertContingentBookingsUpdate,
  revertBookingCreate
} = actions;

export default reducer;
