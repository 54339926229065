import Localize from 'react-intl-universal';

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { LOGIN_PATH } from '@common/network/ApiPaths';
import { SnackbarSeverityTypes, showSnackbar } from '@components/Snackbar/snackbarSlice';
import { getByPathAndParams, postAuth } from '@services/BaseApi';

export const postLogin = createAsyncThunk(
  'login/post',
  (loginData, { rejectWithValue, dispatch }) => {
    return postAuth({
      path: LOGIN_PATH.LOGIN,
      loginData: loginData
    })
      .then((response) => response.data)
      .catch((error) => {
        dispatch(
          showSnackbar({
            message: Localize.get('Login.WrongEmailOrPass'),
            severity: SnackbarSeverityTypes.ERROR
          })
        );
        return rejectWithValue(error.response);
      });
  }
);

export const logOut = createAsyncThunk('login/regularLogout', (data, { rejectWithValue }) => {
  return getByPathAndParams({
    path: LOGIN_PATH.LOGOUT,
    data: data
  })
    .then((response) => {
      if (response.data) {
        window.location.assign(response.data);
      }
    })
    .catch((error) => {
      return rejectWithValue(error.response);
    });
});

export const checkIsAuthEnabled = createAsyncThunk('login/isAuthEnabled', () =>
  getByPathAndParams({
    path: LOGIN_PATH.CHECK
  })
    .then((response) => response.data)
    .catch((error) => error)
);

let JWT_TOKEN = null;
let JWT_REFRESH = null;
try {
  const auth = window.localStorage.getItem('auth');

  if (auth) {
    JWT_TOKEN = JSON.parse(auth).token;
    JWT_REFRESH = JSON.parse(auth).refreshToken;
  } else {
    JWT_TOKEN = null;
    JWT_REFRESH = null;
  }
} catch (err) {
  JWT_TOKEN = null;
  JWT_REFRESH = null;
}

export const initialState = {
  token: JWT_TOKEN,
  refreshToken: JWT_REFRESH,
  isLoggedIn: !!JWT_TOKEN,
  isAuthEnabled: null
};

export const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    setToken: (state, action) => {
      state.token = action.payload;
    },
    setExternalToken: (state, action) => {
      localStorage.setItem('auth', JSON.stringify({ token: action.payload }));
      localStorage.setItem('isExternal', JSON.stringify({ isExternal: true }));
      state.token = action.payload;
      state.isLoggedIn = true;
      window.location.href = '/';
    },
    setRefreshToken: (state, action) => {
      state.refreshToken = action.payload;
    },
    setIsLoggedIn: (state, action) => {
      if (!action.payload) {
        localStorage.removeItem('auth');
      }
      state.isLoggedIn = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(postLogin.fulfilled, (state, { payload }) => {
        state.token = payload?.token;
        state.refreshToken = payload?.refreshToken;
        localStorage.setItem('auth', JSON.stringify(payload));
        state.isLoggedIn = true;
      })
      .addCase(postLogin.rejected, (state) => {
        state.isLoggedIn = false;
      })
      .addCase(logOut.fulfilled, (state) => {
        state.isLoggedIn = false;
        state.token = null;
        localStorage.removeItem('auth');
        localStorage.removeItem('isExternal');
      })
      .addCase(checkIsAuthEnabled.fulfilled, (state, { payload }) => {
        state.isAuthEnabled = payload;
      });
  }
});

export const selectToken = (state) => state.login.token;
export const selectRefreshToken = (state) => state.login.refreshToken;
export const selectIsLogged = (state) => state.login.isLoggedIn;
export const selectIsAuthEnabled = (state) => state.login.isAuthEnabled;

const { actions, reducer } = loginSlice;

export const { setToken, setRefreshToken, setIsLoggedIn, setIsAuthEnabled, setExternalToken } =
  actions;

export default reducer;
