import Localize from 'react-intl-universal';

import * as yup from 'yup';

import { PHONE_REGEXP } from '@config/inputs';

export default () => {
  let sidebarValidationSchema = yup.object().shape({
    name: yup.string().required(Localize.get('ValidationMessages.NameRequired')),
    availableSeats: yup
      .number()
      .required(Localize.get('ValidationMessages.AvailableSeatsRequired'))
      .min(1, Localize.get('ValidationMessages.AvailableSeatsMustBePositive'))
      .max(1000, Localize.get('ValidationMessages.AvailableSeatsMax', { maxSeats: 1000 }))
      .typeError(Localize.get('ValidationMessages.AvailableSeatsRequired'))
      .integer(),
    venue: yup.object().shape({
      name: yup.string().required(Localize.get('ValidationMessages.VenueNameRequired'))
    }),
    floor: yup.string().notRequired(),
    phone: yup.lazy((value) => {
      return value === ''
        ? yup.string()
        : yup.string().matches(PHONE_REGEXP, Localize.get('ValidationMessages.PhoneNumberInvalid'));
    })
  });

  return [sidebarValidationSchema];
};
