import React from 'react';

import AddIcon from '@mui/icons-material/Add';
import Fab from '@mui/material/Fab';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import Stack from '@mui/material/Stack';

import Footer from '@components/Footer';
import useWindowDimensions from '@hooks/window/useWindowDimensions';

const MasterListFooter = ({
  onAddClick,
  pagination: { totalPages = 0, page } = {},
  footerDisabler = {},
  onPageChange,
  isAddVisible = true
}) => {
  const { width } = useWindowDimensions();
  const paginationSize = width <= 1440 ? 'small' : 'medium';

  return (
    <Footer data-test-id="masterListFooter">
      <Stack
        data-test-id="masterListStack"
        justifyContent="right"
        alignItems="center"
        direction="row"
        spacing={1}
        sx={{ mr: 1 }}
      >
        {isAddVisible && (
          <Fab
            data-test-id="addButton"
            onClick={onAddClick}
            disabled={footerDisabler.masterAdd}
            color="primary"
            aria-label="add"
            sx={{ position: 'absolute', right: '1rem', bottom: '5rem' }}
          >
            <AddIcon />
          </Fab>
        )}
      </Stack>
      <Stack
        data-test-id="stackMasterListFooter"
        justifyContent="center"
        alignItems="center"
        direction="row"
        spacing={1}
      >
        <Pagination
          renderItem={(props) => (
            <PaginationItem
              {...props}
              size="small"
              disabled={props.type === 'page' ? props.selected : props.disabled}
            ></PaginationItem>
          )}
          color="primary"
          boundaryCount={0}
          siblingCount={width <= 1440 ? 0 : 1}
          showFirstButton
          showLastButton
          shape="rounded"
          variant="outlined"
          page={isNaN(page) ? 0 : page}
          count={totalPages}
          size={paginationSize}
          onChange={(e, page) => onPageChange(e, page)}
        />
      </Stack>
    </Footer>
  );
};
export default MasterListFooter;
