import FormControlLabel from '@mui/material/FormControlLabel';
import Rating from '@mui/material/Rating';

const RatingInput = ({ field = {}, label = '', ...rest }) => {
  return (
    <FormControlLabel control={<Rating sx={{ pl: 1 }} {...field} {...rest} />} label={label} />
  );
};

export default RatingInput;
