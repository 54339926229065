import { WORK_EMAIL_TYPE_ID } from '@common/Constants';
import { address } from '@components/FormArray';

export default {
  personDto: {
    title: { id: 0 },
    firstName: '',
    lastName: '',
    birthDate: '',
    birthPlace: ''
  },
  academicTitle: '',
  department: '',
  job: '',
  companyName: '',
  managerName: '',
  phoneDtos: [],
  emailDtos: [{ type: { id: WORK_EMAIL_TYPE_ID }, email: '' }],
  addressDtos: [address],
  personnelNumber: '',
  costCenter: ''
};
