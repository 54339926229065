import React, { useEffect } from 'react';
import { useMatch } from 'react-location';
import { useDispatch } from 'react-redux';

import { CircularProgress } from '@mui/material';

import { setExternalToken } from '@pages/Login/loginSlice';

const ExternalLogin = () => {
  const dispatch = useDispatch();
  const {
    params: { externalToken }
  } = useMatch();

  useEffect(() => {
    if (externalToken) {
      dispatch(setExternalToken(externalToken));
    }
  }, [externalToken]);

  return (
    <>
      <CircularProgress size="2rem" />
    </>
  );
};

export default ExternalLogin;
