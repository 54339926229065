import React from 'react';
import Localize from 'react-intl-universal';
import { useMatch } from 'react-location';

import moment from 'moment';

import { FOCUS_FIELD_TIMEOUT, dateInitFormats } from '@common/Constants';
import { dateToFormat } from '@common/helpers/dates';
import RightSidebar from '@components/RightSidebar';
import {
  CANCELLED_STATUS_ID,
  CLASSROOM_TYPE_ID,
  COMPLETED_STATUS_ID
} from '@pages/IltSession/iltSessionSlice';
import sidebar from '@pages/IltSession/util/fields/sidebar';
import initialValues from '@pages/IltSession/util/initialValues';
import useSidebarValidationSchema from '@pages/IltSession/util/schemas/useSidebarSchema';

const isCancelledOrCompleteStatus = (values) =>
  values?.status?.id === COMPLETED_STATUS_ID || values?.status?.id === CANCELLED_STATUS_ID;

const Sidebar = ({
  details = null,
  isDetailsLoading = false,
  onSave = () => {},
  onDelete = () => {}
}) => {
  const sidebarSchema = useSidebarValidationSchema();
  const {
    data: {
      type: { data: type },
      status: { data: status }
    }
  } = useMatch();

  const extendedConfig = (setFieldValue, values) => ({
    ['name']: {
      disabled: isCancelledOrCompleteStatus(values)
    },
    ['maxSeats']: {
      disabled: isCancelledOrCompleteStatus(values)
    },
    ['minSeats']: {
      disabled: isCancelledOrCompleteStatus(values)
    },
    ['startDateTime']: {
      onChange: (value) => setFieldValue('startDateTime', value || null),
      disablePast: true,
      ampm: false,
      minDateTime: moment()
    },
    ['endDateTime']: {
      onChange: (value) => setFieldValue('endDateTime', value),
      disablePast: true,
      ampm: false
    },
    ['status.id']: {
      options: status,
      onChange: (event) => {
        setFieldValue('status', {
          id: event.target.value,
          value: status.find((s) => s.id === event.target.value).value
        });
      }
    },
    ['type.id']: {
      options: type,
      onChange: (event) => {
        setFieldValue('type', {
          id: event.target.value,
          value: type.find((t) => t.id === event.target.value).value
        });
      }
    },
    ['venues']: {
      disabled: isCancelledOrCompleteStatus(values),
      isVisible: values?.type?.id === CLASSROOM_TYPE_ID,
      tokenValue: values?.venues.map((venue) => ({ title: venue.name, id: venue.id })) ?? [],
      onIconClick: (id) => {
        setFieldValue(
          'venues',
          values.venues.filter((item) => item.id !== id)
        );
      },
      selectItem: (value = []) => {
        if (!value?.length) {
          setFieldValue('venues', initialValues.venues);
          return;
        }

        setFieldValue('venues', value);
      },
      selectionModel: values.venues
    },
    ['courseId']: {
      tokenValue: values?.course?.name
        ? [
            {
              title: `${values.course?.name} `
            }
          ]
        : undefined
    },
    ['moduleId']: {
      tokenValue: values?.module?.name
        ? [
            {
              title: `${values?.module?.name}`
            }
          ]
        : undefined
    },
    ['publishInCatalog']: {
      tooltip: {
        title: Localize.get('IltSession.Tooltips.PublishInCatalog')
      }
    },
    ['hasWaitingList']: {
      disabled: isCancelledOrCompleteStatus(values),
      tooltip: {
        title: Localize.get('IltSession.Tooltips.WaitingList')
      }
    },
    ['automaticWaitingList']: {
      isVisible: values?.hasWaitingList,
      tooltip: {
        title: Localize.get('IltSession.Tooltips.AutomaticWaitingList')
      }
    },
    ['automaticWaitingListCancellation']: {
      isVisible: values?.hasWaitingList,
      onClick: (e) => {
        if (e?.target?.checked) {
          setTimeout(
            () => document.getElementsByName('automaticCancellationDate')[0]?.focus(),
            FOCUS_FIELD_TIMEOUT
          );
        }
      },
      tooltip: {
        title: Localize.get('IltSession.Tooltips.AutomaticWaitingListCancellation')
      }
    },
    ['automaticCancellationDate']: {
      isVisible:
        values?.automaticWaitingList &&
        values?.hasWaitingList &&
        values?.automaticWaitingListCancellation,
      onChange: (value) =>
        setFieldValue('automaticCancellationDate', dateToFormat(value, dateInitFormats.dateTime)),
      disablePast: true,
      minDateTime: moment(),
      ampm: false
    },
    ['registrationPeriod']: {
      tooltip: {
        title: Localize.get('IltSession.Tooltips.RegistrationPeriod')
      },
      onClick: (e) => {
        if (e?.target?.checked) {
          setTimeout(
            () => document.getElementsByName('registrationFrom')[0]?.focus(),
            FOCUS_FIELD_TIMEOUT
          );
        }
      }
    },
    ['hasCancellationDeadline']: {
      tooltip: {
        title: Localize.get('IltSession.Tooltips.CancellationDeadline')
      },
      onClick: (e) => {
        if (e?.target?.checked) {
          setTimeout(
            () => document.getElementsByName('cancellationDeadline')[0]?.focus(),
            FOCUS_FIELD_TIMEOUT
          );
        }
      }
    },
    ['registrationFrom']: {
      isVisible: values?.hasRegistrationPeriod,
      ampm: false,
      onChange: (value) =>
        setFieldValue('registrationFrom', dateToFormat(value, dateInitFormats.dateTime))
    },
    ['registrationUntil']: {
      isVisible: values?.hasRegistrationPeriod,
      ampm: false,
      onChange: (value) =>
        setFieldValue('registrationUntil', dateToFormat(value, dateInitFormats.dateTime))
    },
    ['cancellationDeadline']: {
      isVisible: values?.hasCancellationDeadline,
      ampm: false,
      onChange: (value) =>
        setFieldValue('cancellationDeadline', dateToFormat(value, dateInitFormats.dateTime))
    }
  });

  return (
    <RightSidebar
      config={{ title: Localize.get('IltSession.Details'), fieldsConfig: sidebar }}
      extendedConfig={extendedConfig}
      data={details}
      validationSchema={sidebarSchema}
      isDetailsLoading={isDetailsLoading}
      onSave={onSave}
      onDelete={onDelete}
    />
  );
};

export default Sidebar;
