import React from 'react';

import NoData from '@components/NoData';
import Tabs from '@components/Tabs';

import sessionTabsConfig, { HOTEL_TABS_CONFIG } from './hotelTabsConfig';

const SessionTabs = ({ entityId = '', entityType = '', details = null, setDetails = () => {} }) => {
  return (
    <Tabs
      tabsConfig={sessionTabsConfig}
      count={details?.counts}
      renderContent={(tab) => {
        const DynamicComponent = HOTEL_TABS_CONFIG[tab?.key];
        return DynamicComponent ? (
          <DynamicComponent
            entityId={entityId}
            entityType={entityType}
            columns={tab.columns}
            toolbarOptions={tab.options}
            allowedEntityId={8}
            setDetails={setDetails}
          />
        ) : (
          <NoData />
        );
      }}
    ></Tabs>
  );
};

export default SessionTabs;
