import React, { useEffect } from 'react';
import Localize from 'react-intl-universal';
import { useNavigate } from 'react-location';
import { useDispatch, useSelector } from 'react-redux';

import { Formik } from 'formik';
import moment from 'moment';

import CancelIcon from '@mui/icons-material/Cancel';
import SaveIcon from '@mui/icons-material/Save';
import Button from '@mui/material/Button';

import { errorMessageFormatter } from '@common/helpers/MessageFormatter';
import { DIALOG_PATHS } from '@common/network/ApiPaths';
import CreateForm from '@components/CreateForm';
import {
  dragAndDropSaveResult,
  fetchData,
  fetchMeetingRoomsByVenueId,
  resetState,
  selectDragAndDropDraggableItems,
  selectDragAndDropEvents,
  selectDragAndDropFilter,
  selectMeetingRoomFilter,
  setDragAndDropDraggableItems,
  setDragAndDropNonDraggableItems
} from '@components/DragAndDropCalendar/dragAndDropDialogSlice';
import { scrubFiltersForBE } from '@components/FilterDialog/filtersSlice';
import InlineCreateHeaderContainer from '@components/InlineCreateHeaderContainer/InlineCreateHeaderContainer';
import { showSnackbar, SnackbarSeverityTypes } from '@components/Snackbar/snackbarSlice';
import { createIltSessionDay, setDetails } from '@pages/IltSession/iltSessionSlice';
import getUpdatedSessionTime from '@pages/IltSession/util/getUpdatedSessionTime';
import useSessionDaySchema from '@pages/IltSession/util/schemas/useCreateSessionDaySchema';
import sessionDayInitialValues from '@pages/IltSession/util/sessionDayInitialValues';

import getFieldsConfig from './getFieldsConfig';

const CreateSessionDay = ({ session }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const sessionDaySchema = useSessionDaySchema();
  const dragAndDropDraggableItems = useSelector(selectDragAndDropDraggableItems);
  const dragAndDropNonDraggableItems = useSelector(selectDragAndDropEvents);
  const dragAndDropFilter = useSelector(selectDragAndDropFilter);
  const meetingRoomFilter = useSelector(selectMeetingRoomFilter);

  const onSubmit = (values, { setSubmitting }) => {
    dispatch(createIltSessionDay({ ...values, venueId: dragAndDropFilter?.venueIds || null }))
      .unwrap()
      .then(({ dayDate, startDateTime, endDateTime }) => {
        const { newStartTime, newEndTime } = getUpdatedSessionTime(
          dayDate,
          startDateTime,
          endDateTime,
          session
        );

        if (newStartTime) {
          dispatch(setDetails({ startDateTime: newStartTime }));
        }

        if (newEndTime) {
          dispatch(setDetails({ endDateTime: newEndTime }));
        }

        navigate({ search: (previousUrlParams) => ({ ...previousUrlParams, mode: 'list' }) });
      })
      .catch(({ data = {} }) => {
        dispatch(
          showSnackbar({
            message: errorMessageFormatter(data, 'SESSION'),
            severity: SnackbarSeverityTypes.ERROR
          })
        );
      })
      .finally(() => setSubmitting(false));
  };

  useEffect(() => {
    return dispatch(
      fetchMeetingRoomsByVenueId({
        filter: {
          ...meetingRoomFilter,
          page: 0,
          search: '',
          filters: {
            advancedFilters: scrubFiltersForBE({
              filters: [
                {
                  id: 'venueId',
                  operator: { key: 'contains' },
                  value: session?.venues?.[0]?.id
                }
              ]
            })
          }
        },
        isReset: true
      })
    )
      .unwrap()
      .then(({ content }) => {
        return dispatch(
          fetchData({
            path: DIALOG_PATHS.MEETING_ROOMS,
            filter: {
              ...dragAndDropFilter,
              venueIds: session?.venues?.length && session?.venues[0].id,
              meetingRoomIds: content ? content.slice(0, 3).map(({ id }) => id) : []
            },
            filterValues: session?.venues
          })
        );
      })
      .catch(() => {});
  }, [session?.venues]);

  const onSessionDayUpdate = (key, value) => {
    if (dragAndDropNonDraggableItems?.length) {
      const nonDraggableEvents = dragAndDropNonDraggableItems.filter((e) => !e.isDraggable);
      const draggableEvent = dragAndDropNonDraggableItems.filter((e) => e.isDraggable)[0];

      if (!draggableEvent) {
        dispatch(
          setDragAndDropDraggableItems({
            items: [
              {
                ...dragAndDropDraggableItems[0],
                [key]: value
              }
            ],
            saveInitial: true
          })
        );
      } else {
        dispatch(
          setDragAndDropNonDraggableItems(
            nonDraggableEvents.concat({ ...draggableEvent, [key]: value })
          )
        );
      }

      return;
    }

    const oldDraggableItem = dragAndDropDraggableItems[0];
    const updatedDraggableItem = { ...oldDraggableItem, [key]: value };
    dispatch(
      setDragAndDropDraggableItems({
        items: [
          {
            ...updatedDraggableItem,
            isPredefinedTimeInUse: Boolean(
              updatedDraggableItem.predefinedStart && updatedDraggableItem.predefinedEnd
            )
          }
        ],
        saveInitial: true
      })
    );
  };

  const onSessionDayDateChange = (value) => {
    dispatch(
      fetchData({
        path: DIALOG_PATHS.MEETING_ROOMS,
        filter: {
          ...dragAndDropFilter,
          sessionDayDate: moment(value).format(moment.HTML5_FMT.DATE)
        }
      })
    );
  };

  const onRoomRemove = () => {
    const nonDraggableEvents = dragAndDropNonDraggableItems.filter((e) => !e.isDraggable);
    const draggableEvent = dragAndDropNonDraggableItems.filter((e) => e.isDraggable)[0];

    dispatch(setDragAndDropNonDraggableItems(nonDraggableEvents));
    dispatch(dragAndDropSaveResult());
    dispatch(
      setDragAndDropDraggableItems({
        items: [draggableEvent]
      })
    );
  };

  useEffect(() => {
    return () => {
      // Cleans drag and drop slice
      dispatch(resetState());
    };
  }, []);

  return (
    <>
      <Formik
        validateOnMount
        enableReinitialize
        validationSchema={sessionDaySchema}
        onSubmit={onSubmit}
        initialValues={{
          ...sessionDayInitialValues,
          sessionName: session?.name,
          iltSessionId: session?.id,
          course: session?.course?.name,
          iltModule: session?.module?.name,
          minSeats: session?.minSeats,
          maxSeats: session?.maxSeats,
          venues: session?.venues
        }}
      >
        {({ isSubmitting, handleSubmit, isValid, setFieldValue, values, errors }) => (
          <>
            <InlineCreateHeaderContainer>
              <Button
                data-test-id="cancel-btn"
                variant="outlined"
                onClick={() =>
                  navigate({
                    search: (previousUrlParams) => ({ ...previousUrlParams, mode: 'list' })
                  })
                }
                color="error"
                sx={{ mr: 1 }}
                startIcon={<CancelIcon />}
              >
                {Localize.get('Buttons.Cancel')}
              </Button>

              <Button
                data-test-id="save-btn"
                variant="outlined"
                disabled={isSubmitting || !isValid}
                onClick={handleSubmit}
                startIcon={<SaveIcon />}
              >
                {Localize.get('Buttons.Save')}
              </Button>
            </InlineCreateHeaderContainer>

            <CreateForm
              values={values}
              fieldsConfig={getFieldsConfig(
                setFieldValue,
                values,
                session,
                errors,
                onSessionDayUpdate,
                onSessionDayDateChange,
                onRoomRemove
              )}
            />
          </>
        )}
      </Formik>
    </>
  );
};

export default CreateSessionDay;
