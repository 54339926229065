import React, { useEffect } from 'react';
import Localize from 'react-intl-universal';
import { useMatch, useNavigate } from 'react-location';
import { useDispatch, useSelector } from 'react-redux';

import { Formik } from 'formik';
import moment from 'moment';

import CancelIcon from '@mui/icons-material/Cancel';
import SaveIcon from '@mui/icons-material/Save';
import Button from '@mui/material/Button';

import { ACTION_MODES } from '@common/Constants';
import { errorMessageFormatter } from '@common/helpers/MessageFormatter';
import CreateForm from '@components/CreateForm';
import InlineCreateHeaderContainer from '@components/InlineCreateHeaderContainer/InlineCreateHeaderContainer';
import { showSnackbar, SnackbarSeverityTypes } from '@components/Snackbar/snackbarSlice';
import {
  selectCreatedBusinessPartner,
  setCreatedBusinessPartner
} from '@pages/BusinessPartners/businessPartnersSlice';
import { createParticipant } from '@pages/IltSession/iltSessionSlice';
import createParticipantSchema from '@pages/IltSession/util/schemas/createParticipantSchema';
import { selectOccupancy, setOccupancy } from '@pages/Participants/participantSlice';

import getFieldsConfig, { mapPropsToAddress } from './getFieldsConfig';
import initialValues from './initialValues';

const CreateParticipant = ({ session }) => {
  const {
    data: {
      participantData: {
        data: { allergy, language }
      },
      participantStatuses: { data: statuses },
      phoneTypes: { data: phoneTypes },
      emailTypes: { data: emailTypes },
      addressTypes: { data: addressTypes }
    }
  } = useMatch();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const occupancy = useSelector(selectOccupancy);
  const createdBusinessPartner = useSelector(selectCreatedBusinessPartner);
  const startDate = session?.startDateTime;
  const endDate = session?.endDateTime;

  useEffect(() => window.scrollTo(0, 0), []);

  const onSubmit = (values, { setSubmitting }) => {
    dispatch(createParticipant(values))
      .unwrap()
      .then(() => {
        dispatch(setCreatedBusinessPartner(null));
        navigate({ search: (previousUrlParams) => ({ ...previousUrlParams, mode: 'list' }) });
      })
      .catch(({ data = {} }) => {
        dispatch(
          showSnackbar({
            message: errorMessageFormatter(data, 'PARTICIPANT', ACTION_MODES.Create),
            severity: SnackbarSeverityTypes.ERROR
          })
        );
      })
      .finally(() => setSubmitting(false));
  };

  const onCancel = () => {
    dispatch(setCreatedBusinessPartner(null));
    navigate({ search: (previousUrlParams) => ({ ...previousUrlParams, mode: 'list' }) });
  };

  return (
    <Formik
      validateOnMount
      initialValues={{
        ...initialValues,
        iltSession: { name: session?.name, id: session?.id },
        arrivalDate: moment(startDate).toDate(),
        departureDate: moment(endDate).toDate(),
        company: {
          id: createdBusinessPartner?.organizationPerson?.id || '',
          name: createdBusinessPartner?.organizationPerson?.name
        }
      }}
      onSubmit={onSubmit}
      validationSchema={createParticipantSchema}
    >
      {({ isSubmitting, values, setFieldValue, handleSubmit, isValid, setValues }) => (
        <>
          <InlineCreateHeaderContainer>
            <Button
              data-test-id="cancel-btn"
              variant="outlined"
              onClick={onCancel}
              color="error"
              sx={{ mr: 1 }}
              startIcon={<CancelIcon />}
            >
              {Localize.get('Buttons.Cancel')}
            </Button>
            <Button
              data-test-id="save-btn"
              variant="outlined"
              disabled={isSubmitting || !isValid}
              onClick={handleSubmit}
              startIcon={<SaveIcon />}
            >
              {Localize.get('Buttons.Save')}
            </Button>
          </InlineCreateHeaderContainer>

          <CreateForm
            values={values}
            mapPropsToAddress={mapPropsToAddress}
            fieldsConfig={getFieldsConfig(
              values,
              setFieldValue,
              setValues,
              allergy,
              language,
              occupancy,
              phoneTypes,
              emailTypes,
              addressTypes,
              statuses,
              dispatch(setOccupancy(occupancy)),
              session
            )}
          />
        </>
      )}
    </Formik>
  );
};

export default CreateParticipant;
