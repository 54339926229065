import Localize from 'react-intl-universal';

import * as yup from 'yup';

const today = new Date();
today.setHours(0, 0, 0, 0);

export default () => {
  return yup.object().shape({
    name: yup.string().required(Localize.get('ValidationMessages.IltSessionName')),
    minSeats: yup
      .number()
      .min(0, Localize.get('ValidationMessages.MinNumber0'))
      .max(yup.ref('maxSeats'), Localize.get('ValidationMessages.MaxMinSeats')),
    maxSeats: yup
      .number()
      .min(1, Localize.get('ValidationMessages.MinNumber'))
      .when('minSeats', {
        is: (minSeats) => minSeats,
        then: yup.number().min(yup.ref('minSeats'), Localize.get('ValidationMessages.MinMaxSeats')),
        otherwise: yup.number()
      })
      .required(Localize.get('ValidationMessages.MaxSeats')),
    automaticCancellationDate: yup.date().when('automaticWaitingListCancellation', {
      is: true,
      then: yup
        .date()
        .typeError(Localize.get('ValidationMessages.DateTimeInvalid'))
        .required('ValidationMessages.StartDate')
        .min(today, Localize.get('ValidationMessages.DateTimeInThePast'))
    }),
    cancellationDeadline: yup
      .date()
      .nullable()
      .when('hasCancellationDeadline', {
        is: true,
        then: yup
          .date()
          .typeError(Localize.get('ValidationMessages.DateTimeInvalid'))
          .required('ValidationMessages.StartDate')
      }),
    registrationFrom: yup
      .date()
      .nullable()
      .when('hasRegistrationPeriod', {
        is: true,
        then: yup
          .date()
          .typeError(Localize.get('ValidationMessages.DateTimeInvalid'))
          .required('ValidationMessages.StartDate')
      }),
    registrationUntil: yup
      .date()
      .nullable()
      .when('hasRegistrationPeriod', {
        is: true,
        then: yup
          .date()
          .required('ValidationMessages.StartDate')
          .min(
            yup.ref('registrationFrom'),
            Localize.get('ValidationMessages.RegistrationUntilBeforeFrom')
          )
          .typeError(Localize.get('ValidationMessages.DateTimeInvalid'))
      })
  });
};
