import Localize from 'react-intl-universal';

import * as yup from 'yup';

const today = new Date();
today.setHours(0, 0, 0, 0);

export default () => {
  return yup.object().shape({
    registrationDueDate: yup
      .date()
      .max(yup.ref('startDateTime'), Localize.get('ValidationMessages.RegistrationDateStartDate'))
      .typeError(Localize.get('ValidationMessages.RegistrationDueDateInvalid'))
      .nullable(),
    startDateTime: yup
      .date()
      .required(Localize.get('ValidationMessages.StartDate'))
      .min(today, Localize.get('ValidationMessages.StartDateBeforeEndDate'))
      .max(yup.ref('endDateTime'), Localize.get('ValidationMessages.StartDateBeforeEndDate'))
      .typeError(Localize.get('ValidationMessages.StartDateInvalid')),
    endDateTime: yup
      .date()
      .min(yup.ref('startDateTime'), Localize.get('ValidationMessages.EndDateStartDate'))
      .required(Localize.get('ValidationMessages.EndDate'))
      .typeError(Localize.get('ValidationMessages.EndDateInvalid')),
    name: yup.string().required(Localize.get('ValidationMessages.NameRequired')),
    typeId: yup
      .object()
      .shape({
        id: yup.number().required(Localize.get('ValidationMessages.EventType')).nullable(true),
        value: yup.string()
      })
      .nullable(true)
      .required(Localize.get('ValidationMessages.EventType')),
    venues: yup
      .array()
      .nullable()
      .when(['statusId'], {
        is: (statusId) => {
          return statusId === 1;
        },
        then: yup
          .array()
          .of(
            yup.object().shape({
              id: yup.number().required(),
              name: yup.string().required()
            })
          )
          .min(1, Localize.get('ValidationMessages.VenueNameRequired'))
          .nullable()
      }),
    minParticipants: yup
      .number()
      .min(0, Localize.get('ValidationMessages.MinNumber0'))
      .max(yup.ref('maxParticipants'), Localize.get('ValidationMessages.MaxMinParticipants')),
    maxParticipants: yup
      .number()
      .min(1, Localize.get('ValidationMessages.MinNumber'))
      .required(Localize.get('ValidationMessages.MaxParticipants'))
      .when('minParticipants', {
        is: (minParticipants) => minParticipants,
        then: yup
          .number()
          .min(yup.ref('minParticipants'), Localize.get('ValidationMessages.MinMaxParticipants')),
        otherwise: yup.number()
      }),
    optParticipants: yup
      .number()
      .min(0, Localize.get('ValidationMessages.MinNumber0'))
      .max(yup.ref('maxParticipants'), Localize.get('ValidationMessages.OptimalParticipants'))
      .when('minParticipants', {
        is: (minParticipants) => minParticipants,
        then: yup
          .number()
          .min(yup.ref('minParticipants'), Localize.get('ValidationMessages.OptimalParticipants')),
        otherwise: yup.number()
      })
  });
};
