import { useCallback, useMemo, useState } from 'react';
import Localize from 'react-intl-universal';

import moment from 'moment';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { dateInitFormats } from '@common/Constants';
import MasterListItem from '@components/MasterListItem/MasterListItem';
import { Draggable } from '@mobiscroll/react';

import BookingChip from '../Chip/Chip';

const Participant = ({ data, isEdit }) => {
  const [draggable, setDraggable] = useState();
  const setDragElm = useCallback((elm) => setDraggable(elm), []);

  const leftDetails = useMemo(
    () => ({
      firstRow: data?.company,
      secondRow: `${moment(data?.arrivalDate).format(dateInitFormats.monthDay)}-${moment(
        data?.departureDate
      ).format('DD.MM.YYYY')}`
    }),
    [data]
  );

  const isBookingCompleted = useMemo(() => {
    return data?.participantBookingStatus?.id === 3;
  }, [data]);

  const rightDetails = useMemo(
    () => ({
      component: (
        <Box
          sx={{ mt: 1, mb: 1 }}
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          flexGrow="1"
          alignSelf="stretch"
          alignItems="flex-end"
        >
          <Typography alignSelf="end" variant="body2">
            {[Localize.get('Labels.Status'), `${data.nightsBooked}/${data.nightsToBeBooked}`].join(
              ' '
            )}
          </Typography>
          <BookingChip current={data.nightsBooked} total={data.nightsToBeBooked} />
        </Box>
      )
    }),
    [data]
  );

  return (
    <div ref={setDragElm}>
      <MasterListItem
        key={data.id}
        id={data.id}
        heading={{ left: data?.title, right: status }}
        leftDetails={leftDetails}
        rightDetails={rightDetails}
      />
      {(isEdit || isBookingCompleted) && <Draggable dragData={data} element={draggable} />}
    </div>
  );
};

export default Participant;
