import { TAB_KEYS } from '@common/Constants';
import AttachmentsTable from '@components/AttachmentsTable';
import NotesTable from '@components/NotesTable';

import IltSessionOverview from '../Overview/IltSessionOverview';
import ParticipantsTable from './ParticipantsTable/ParticipantsTable';
import PriceTable from './Price/PriceTable';
import SessionsDayTable from './SessionDays/SessionDaysTable';
import TasksTable from './Tasks/TasksTable';

export const ILT_SESSION_TABS_COMPONENTS = {
  [TAB_KEYS.OVERVIEW]: IltSessionOverview,
  [TAB_KEYS.NOTE]: NotesTable,
  [TAB_KEYS.SESSION_DAY]: SessionsDayTable,
  [TAB_KEYS.ATTACHMENT]: AttachmentsTable,
  [TAB_KEYS.PARTICIPANTS]: ParticipantsTable,
  [TAB_KEYS.PRICE]: PriceTable,
  [TAB_KEYS.TASK]: TasksTable
};

export default {
  activeIndex: 0,
  tabs: [
    {
      label: 'Labels.Overview',
      key: TAB_KEYS.OVERVIEW,
      icon: 'info',
      isCountVisible: false
    },
    {
      label: 'IltSession.SessionDays',
      key: TAB_KEYS.SESSION_DAY,
      icon: 'groups',
      options: [
        {
          fieldName: 'add_sessions',
          disabled: false,
          icon: 'add'
        },
        {
          fieldName: 'delete',
          disabled: false,
          icon: 'delete'
        }
      ],
      columns: [
        {
          field: 'id',
          headerName: 'Labels.ID',
          editable: false,
          headerAlign: 'left'
        },
        {
          field: 'name',
          headerName: 'Labels.Name',
          width: 250,
          editable: false,
          headerAlign: 'left'
        },
        {
          field: 'dayDate',
          headerName: 'Labels.Date',
          editable: false,
          width: 150,
          headerAlign: 'left'
        },
        {
          field: 'startTime',
          headerName: 'Labels.TimeFrom',
          editable: false,
          headerAlign: 'left',
          width: 100
        },
        {
          field: 'endTime',
          headerName: 'Labels.TimeTo',
          editable: false,
          headerAlign: 'left',
          width: 100
        },
        {
          field: 'instructors[0].fullName',
          headerName: 'IltSession.Instructor',
          width: 150,
          editable: false,
          headerAlign: 'left',
          filterable: false
        },
        {
          field: 'webinarUrl',
          headerName: 'IltSession.WebinarURL',
          width: 150,
          editable: false,
          headerAlign: 'left'
        }
      ]
    },
    {
      label: 'Labels.Participants',
      key: TAB_KEYS.PARTICIPANTS,
      icon: 'accessibility',
      options: [
        {
          fieldName: 'add_participant',
          disabled: false,
          icon: 'add'
        },
        {
          fieldName: 'email_participant',
          disabled: true,
          icon: 'mail'
        },
        {
          fieldName: 'edit',
          disabled: true,
          icon: 'edit'
        },
        {
          fieldName: 'delete',
          disabled: false,
          icon: 'delete'
        }
      ],
      columns: [
        {
          field: 'name',
          headerName: 'Labels.Name',
          width: 150,
          editable: false,
          headerAlign: 'left'
        },
        {
          field: 'company',
          headerName: 'Labels.Company',
          width: 150,
          editable: false,
          headerAlign: 'left'
        },
        {
          field: 'sessionStatus',
          headerName: 'IltSession.IltSessionStatus',
          editable: false,
          width: 200,
          headerAlign: 'left'
        },
        {
          field: 'status',
          headerName: 'IltSession.Status',
          editable: false,
          headerAlign: 'left',
          width: 100
        },
        {
          field: 'hotelRequest',
          headerName: 'IltSession.HotelRequest',
          editable: false,
          headerAlign: 'left',
          width: 150
        },
        {
          field: 'hotel',
          headerName: 'IltSession.Hotel',
          editable: false,
          headerAlign: 'left',
          width: 200
        },
        {
          field: 'arrival',
          headerName: 'IltSession.Arrival',
          editable: false,
          headerAlign: 'left',
          width: 150
        },
        {
          field: 'departure',
          headerName: 'IltSession.Departure',
          editable: false,
          headerAlign: 'left',
          width: 150
        }
      ]
    },
    {
      label: 'Labels.Prices',
      key: TAB_KEYS.PRICE,
      icon: 'local_offer',
      options: [
        {
          fieldName: 'save_prices',
          disabled: false,
          icon: 'save'
        },
        {
          fieldName: 'add_price',
          disabled: false,
          icon: 'add'
        },
        {
          fieldName: 'cancel_add_price',
          disabled: false,
          icon: 'cancel'
        },
        {
          fieldName: 'edit',
          disabled: true,
          icon: 'edit'
        },
        {
          fieldName: 'delete',
          disabled: false,
          icon: 'delete'
        }
      ],
      columns: [
        {
          field: 'typeId',
          headerName: 'Labels.PriceType',
          width: 250,
          editable: false,
          headerAlign: 'left',
          required: true,
          valueGetter: (params) => params?.row?.type?.value
        },
        {
          field: 'price',
          headerName: 'ResourcesTile.Price',
          width: 250,
          editable: false,
          headerAlign: 'left',
          required: true
        },
        {
          field: 'currencyId',
          headerName: 'ResourcesTile.Currency',
          width: 250,
          editable: false,
          headerAlign: 'left',
          required: true,
          valueGetter: (params) => params?.row?.currency?.value
        },
        {
          field: 'validFrom',
          headerName: 'Labels.ValidFrom',
          editable: false,
          width: 280,
          required: true
        },
        {
          field: 'validTo',
          headerName: 'Labels.ValidTo',
          editable: false,
          headerAlign: 'left',
          width: 280,
          required: true
        }
      ]
    },
    {
      label: 'Labels.Tasks',
      key: TAB_KEYS.TASK,
      icon: 'assignment',
      options: [
        {
          fieldName: 'add_tasks',
          disabled: true,
          icon: 'add'
        },
        {
          fieldName: 'edit',
          disabled: true,
          icon: 'edit'
        },
        {
          fieldName: 'delete',
          disabled: true,
          icon: 'delete'
        }
      ],
      columns: [
        {
          field: 'id',
          headerName: 'Labels.ID',
          editable: false,
          headerAlign: 'left',
          required: true
        },
        {
          field: 'subject',
          headerName: 'Labels.Subject',
          editable: false,
          headerAlign: 'left',
          required: true
        },
        {
          field: 'processor',
          headerName: 'Labels.Processor',
          editable: false,
          headerAlign: 'left',
          required: true,
          valueGetter: ({ row }) =>
            row?.taskDto?.processor?.firstName.concat(' ', row?.taskDto?.processor?.lastName)
        },
        {
          field: 'status',
          headerName: 'Labels.Status',
          editable: false,
          required: true,
          valueGetter: ({ row }) => row?.status?.value
        },
        {
          field: 'completion',
          headerName: 'Labels.Completion',
          editable: false,
          required: true,
          valueGetter: ({ row }) => row?.taskDto?.completion
        },
        {
          field: 'priority',
          headerName: 'Labels.Priority',
          editable: false,
          required: true,
          valueGetter: ({ row }) => row?.priority?.value
        },
        {
          field: 'startDateTime',
          headerName: 'Labels.StartDate',
          width: 170,
          editable: false,
          required: true
        },
        {
          field: 'endDateTime',
          headerName: 'Labels.EndDate',
          width: 170,
          editable: false,
          headerAlign: 'left',
          required: true
        },
        {
          field: 'category',
          headerName: 'Labels.Category',
          editable: false,
          headerAlign: 'left',
          required: true,
          valueGetter: ({ row }) => row?.taskDto?.taskCategory?.value
        },
        {
          field: 'account',
          headerName: 'Labels.Account',
          width: 180,
          editable: false,
          headerAlign: 'left',
          required: true,
          valueGetter: ({ row }) => row?.account?.name
        },
        {
          field: 'primaryContact',
          headerName: 'Labels.PrimaryContact',
          width: 180,
          editable: false,
          headerAlign: 'left',
          required: true,
          valueGetter: ({ row }) => row?.contact?.firstName.concat(' ', row?.contact?.lastName)
        }
      ]
    },
    {
      label: 'Labels.Attachments',
      key: TAB_KEYS.ATTACHMENT,
      icon: 'attachments',
      options: [
        {
          fieldName: 'add',
          disabled: false,
          icon: 'add'
        },
        {
          fieldName: 'delete',
          disabled: false,
          icon: 'delete'
        }
      ],
      columns: [
        {
          field: 'name',
          headerName: 'Labels.Name',
          width: 250,
          editable: false,
          headerAlign: 'left',
          filterable: true
        },
        {
          field: 'size',
          headerName: 'Labels.Size',
          width: 250,
          editable: false,
          defaultValue: '0 KB',
          headerAlign: 'left',
          filterable: true
        },
        {
          field: 'createdBy',
          headerName: 'Labels.CreatedBy',
          width: 250,
          editable: false,
          defaultValue: 'Unknown User',
          headerAlign: 'left',
          filterable: true
        }
      ]
    },
    {
      label: 'Labels.Notes',
      key: TAB_KEYS.NOTE,
      icon: 'note_alt',
      options: [
        {
          fieldName: 'add_note',
          disabled: false,
          icon: 'add'
        },
        {
          fieldName: 'delete',
          disabled: false,
          icon: 'delete'
        }
      ],
      columns: [
        {
          field: 'type.value',
          headerName: 'Labels.Type',
          width: 250,
          editable: false,
          headerAlign: 'left'
        },
        {
          field: 'text',
          headerName: 'Labels.Text',
          width: 250,
          editable: false,
          headerAlign: 'left'
        }
      ]
    }
  ]
};
