export default [
  {
    groupName: 'Labels.Information',
    fields: [
      {
        label: 'Labels.Title',
        name: 'personDto.title.id',
        required: false,
        disabled: false,
        component: 'select',
        options: [
          { id: 0, value: 'None' },
          { id: 1, value: 'Mrs' },
          { id: 2, value: 'Mr' },
          { id: 3, value: 'Ms' }
        ]
      },
      {
        label: 'Labels.AcademicTitle',
        name: 'academicTitle',
        required: false,
        disabled: false,
        component: 'text'
      },
      {
        label: 'Labels.FirstName',
        name: 'personDto.firstName',
        required: true,
        disabled: false,
        component: 'text'
      },
      {
        label: 'Labels.LastName',
        name: 'personDto.lastName',
        required: true,
        disabled: false,
        component: 'text'
      }
    ]
  },
  {
    groupName: 'Personal.Title',
    fields: [
      {
        label: 'Personal.BirthDate',
        name: 'personDto.birthDate',
        required: false,
        disabled: false,
        component: 'datepicker'
      },
      {
        label: 'Personal.BirthPlace',
        name: 'personDto.birthPlace',
        required: false,
        disabled: false,
        component: 'text'
      }
    ]
  },
  {
    groupName: 'Labels.OrganizationalData',
    fields: [
      {
        label: 'Labels.Department',
        name: 'department',
        required: false,
        disabled: false,
        component: 'text'
      },
      {
        label: 'Labels.JobCreate',
        name: 'job',
        required: true,
        disabled: false,
        component: 'text'
      },
      {
        label: 'Labels.CompanyName',
        name: 'companyName',
        required: true,
        disabled: false,
        component: 'text'
      },
      {
        label: 'Personal.Number',
        name: 'personnelNumber',
        required: false,
        disabled: false,
        component: 'text'
      },
      {
        label: 'Personal.CostCenter',
        name: 'costCenter',
        required: false,
        disabled: false,
        component: 'text'
      }
    ]
  },
  {
    groupName: 'Communication.Email',
    arrayName: 'emailDtos',
    fields: [
      {
        label: 'Labels.Type',
        name: 'type.id',
        required: false,
        disabled: false,
        component: 'select'
      },
      {
        label: 'Communication.Email',
        name: 'email',
        required: true,
        disabled: false,
        component: 'text'
      }
    ]
  },
  {
    groupName: 'Address.Title',
    arrayName: 'addressDtos',
    fields: [
      {
        label: 'Labels.Type',
        name: 'type.id',
        required: false,
        disabled: false,
        component: 'select'
      },
      {
        label: 'Address.Street',
        name: 'street',
        required: false,
        disabled: false,
        component: 'text'
      },
      {
        label: 'Address.Number',
        name: 'number',
        required: false,
        disabled: false,
        component: 'text'
      },
      {
        label: 'Address.PostalCode',
        name: 'postalCode',
        required: false,
        disabled: false,
        component: 'text'
      },
      {
        label: 'Address.State',
        name: 'state',
        required: false,
        disabled: false,
        component: 'text'
      },
      {
        label: 'Address.City',
        name: 'city',
        required: false,
        disabled: false,
        component: 'text'
      },
      {
        label: 'Address.POBox',
        name: 'poBox',
        required: false,
        disabled: false,
        component: 'text'
      },
      {
        label: 'Address.Country',
        name: 'country',
        required: true,
        disabled: false,
        component: 'autocomplete'
      },
      {
        label: 'Address.Region',
        name: 'region',
        required: false,
        disabled: false,
        component: 'autocomplete'
      }
    ]
  },
  {
    groupName: 'Communication.Title',
    arrayName: 'phoneDtos',
    fields: [
      {
        label: 'Labels.Type',
        name: 'type.id',
        required: false,
        disabled: false,
        component: 'select'
      },
      {
        label: 'Labels.Phone',
        name: 'phone',
        required: true,
        disabled: false,
        component: 'text'
      }
    ]
  }
];
