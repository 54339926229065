import React from 'react';
import Localize from 'react-intl-universal';
import { useDispatch } from 'react-redux';

import { Typography } from '@mui/material';
import Box from '@mui/material/Box';

import DetailsContainer from '@components/DetailsContainer/DetailsContainer';
import DetailsToolbar from '@components/DetailsToolbar';
import Header from '@components/Header';
import { openSidebar } from '@components/RightSidebar/rightSidebarSlice';

const IltSessionDetails = ({
  // entityId = '',
  headerTitle = '',
  children
  //  isDisabled
}) => {
  const dispatch = useDispatch();

  const toolbarOptions = [
    {
      icon: 'menu_open',
      label: 'menu',
      tooltip: Localize.get('Labels.ViewSidebar'),
      // isDisabled: !entityId,
      isDisabled: true,
      onClick: () => dispatch(openSidebar(false))
    }
  ];

  return (
    <>
      <Header display="flex" flexDirection="row">
        <Box flexGrow="1" textAlign="right" justifyContent="flex-end">
          <Typography sx={{ p: '6px 8px', fontWeight: 600 }}>{headerTitle}</Typography>
        </Box>

        <Box flexGrow="1" flexDirection="row" justifyContent="flex-end">
          <DetailsToolbar options={toolbarOptions} />
        </Box>
      </Header>

      <DetailsContainer>{children}</DetailsContainer>
    </>
  );
};

export default IltSessionDetails;
