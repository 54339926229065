import FormControlLabel from '@mui/material/FormControlLabel';
import Icon from '@mui/material/Icon';
import Switch from '@mui/material/Switch';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

const SwitchInput = ({ field = {}, label = '', tooltip = { title: '', icon: '' }, ...rest }) => (
  <FormControlLabel
    control={<Switch {...field} {...rest} checked={field?.value}></Switch>}
    label={
      <Typography sx={{ lineHeight: '2.5rem', display: 'flex' }}>
        {label}
        {Boolean(tooltip.title) && (
          <Tooltip title={tooltip.title}>
            <Icon sx={{ ml: 1, mt: 1, fontSize: '1.4rem' }}>
              {tooltip.icon || 'help_outline_icon'}
            </Icon>
          </Tooltip>
        )}
      </Typography>
    }
  ></FormControlLabel>
);

export default SwitchInput;
