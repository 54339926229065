import { FILTER_KEYS } from '@components/FilterDialog';

const allFilters = [
  {
    label: 'Personal.Number',
    id: 'personnelNumber',
    type: 'string',
    operator: { key: FILTER_KEYS.Contains, label: 'Tables.FilterValues.Contains' }
  },
  {
    label: 'Personal.CostCenter',
    id: 'costCentre',
    type: 'string',
    operator: { key: FILTER_KEYS.Contains, label: 'Tables.FilterValues.Contains' }
  },
  {
    label: 'Labels.CompanyName',
    id: 'companyName',
    type: 'string',
    operator: { key: FILTER_KEYS.Contains, label: 'Tables.FilterValues.Contains' }
  }
];

export default allFilters;
