import { createTheme } from '@mui/material/styles';

import darkPalette from './darkPalette';
import lightPalette from './lightPalette';
import overrides from './overrides';
import sizes from './sizes';

const lightTheme = createTheme({ palette: lightPalette, overrides, sizes });
const darkTheme = createTheme({ palette: darkPalette, overrides, sizes });

export { lightTheme, darkTheme };
