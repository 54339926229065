import { copyObjectWithoutRef } from '@common/helpers/helpers';
import scrubDtos from '@common/helpers/scrubDtos';

export const formatForDetails = (responseData) => {
  let localOrganization = responseData?.organizationPerson?.organization ?? {};
  if (localOrganization?.id) {
    localOrganization.parentalAccount = localOrganization?.parentalAccount ?? {
      id: '',
      name: ''
    };
  }
  return {
    ...responseData,
    organizationPerson: {
      ...responseData.organizationPerson,
      ...localOrganization
    }
  };
};

export const formatPostObject = (postData) => {
  const postObj = copyObjectWithoutRef(postData);
  const { roles, supplierType, businessPartnerType } = postData;

  switch (businessPartnerType) {
    // Individual Customer
    case 1:
      switch (true) {
        case roles === 1 && !supplierType: // Customer
        case roles === 3 && !supplierType: // Prospect
        case roles === 1 && supplierType === 1: // Prospect
          postObj.roles = [
            {
              roleType: {
                id: roles
              }
            }
          ];
          break;
      }
      break;

    // Account
    case 2:
      switch (true) {
        case roles === 1 && supplierType === 1: // Customer
        case roles === 3 && supplierType === 1: // Prospect
          postObj.roles = [
            {
              roleType: {
                id: roles
              }
            }
          ];
          break;

        // Supplier - Hotel
        case roles === 2 && supplierType === 1:
          postObj.roles = [
            {
              roleType: {
                id: roles
              },
              supplierType: {
                id: supplierType
              },
              hotel: postData.facilities
            }
          ];
          break;

        case roles === 2 && supplierType === 2: // Supplier - Venue
        case roles === 2 && supplierType === 3: // Supplier - Universal
          postObj.roles = [
            {
              roleType: {
                id: roles
              },
              supplierType: {
                id: supplierType
              }
            }
          ];
          break;

        // Supplier - Hotel And Venue
        case roles === 2 && supplierType === 4:
          postObj.roles = [
            {
              roleType: {
                id: roles
              },
              supplierType: {
                id: supplierType
              },
              hotel: postData.facilities
            },
            {
              roleType: {
                id: roles
              },
              supplierType: {
                id: supplierType
              },
              venue: {}
            }
          ];
          break;
      }
      break;

    default:
      throw Error('Type not supported');
  }

  postObj.organizationPerson.addressDtos = scrubDtos(postObj, 'organizationPerson.addressDtos');
  postObj.organizationPerson.emailDtos = scrubDtos(postObj, 'organizationPerson.emailDtos');
  postObj.organizationPerson.phoneDtos = scrubDtos(postObj, 'organizationPerson.phoneDtos');

  postObj.organizationPerson.website = postObj?.organizationPerson?.website || null;

  return postObj;
};

//for edit hotel and venue
export const formatSaveData = (data) => {
  let formatData = copyObjectWithoutRef(data);

  formatData.organizationPerson.addressDtos = scrubDtos(
    formatData,
    'organizationPerson.addressDtos'
  );
  formatData.organizationPerson.emailDtos = scrubDtos(formatData, 'organizationPerson.emailDtos');
  formatData.organizationPerson.phoneDtos = scrubDtos(formatData, 'organizationPerson.phoneDtos');

  formatData.organizationPerson.website = formatData?.organizationPerson?.website || null;

  if (formatData?.roles[0]?.supplierType?.id === 4) {
    formatData = {
      ...formatData,
      roles: [
        {
          roleType: {
            id: 2,
            value: 'Supplier'
          },
          supplierType: {
            id: 1,
            value: 'Hotel'
          },
          hotel: { ...formatData?.roles[0]?.hotel }
        },
        {
          roleType: {
            id: 2,
            value: 'Supplier'
          },
          supplierType: {
            id: 2,
            value: 'Venue'
          },
          venue: { ...formatData?.roles[0]?.venue }
        }
      ]
    };
  }

  return formatData;
};
