import React, { useEffect, useMemo, useCallback } from 'react';
import Localize from 'react-intl-universal';
import { useMatch, useNavigate, useSearch } from 'react-location';
import { useSelector, useDispatch } from 'react-redux';

import Icon from '@mui/material/Icon';
import Link from '@mui/material/Link';
import ListItemIcon from '@mui/material/ListItemIcon';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { makeStyles, styled } from '@mui/styles';

import { dateToFormat } from '@common/helpers/dates';
import { capitalizeFirstLetter } from '@common/helpers/string';
import getLocalesText from '@common/helpers/tables/getLocalesText';
import { saveState } from '@common/storage/persistSlice';
import PdfPreviewDialog from '@components/PdfPreviewDialog';
import TabContainer from '@components/TabContainer';
import TabContent from '@components/TabContent/TabContent';
import TableToolbar from '@components/TableToolbar';
import ToolbarItem from '@components/TableToolbarItem';
import { StyledDataGrid } from '@components/TokenValueModalTrigger/components/DialogDataGrid';
import usePrevious from '@hooks/usePreviousState/usePrevious';
import {
  setData,
  selectList,
  fetchParticipants,
  selectFilter,
  selectIsLoading,
  resetState,
  selectTotalElements,
  selectTotalPages,
  setFilterParams,
  selectIsEmailDialogOpen,
  selectEmailAnchor,
  setEmailAnchor,
  selectSelectionModel,
  setSelectionModel,
  selectPreviewDialog,
  setPreviewDialog,
  selectConfirmStatusDialog,
  setConfirmStatusDialog,
  sendDocument,
  previewDocument,
  sendDocumentWithForce,
  setEmailStatus,
  selectEmailStatus
} from '@pages/Events/components/Tabs/ParticipantsTable/participantTableSlice';
import { selectDetails, selectId } from '@pages/Events/eventsSlice';
import { CreateParticipant } from '@pages/Participants';

import ConfirmStatusDialog from './ConfirmStatusDialog/ConfirmStatusDialog';

export const PAGE_SIZE_OPTIONS = [5, 10, 15];

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  toolbarContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(1),
    alignItems: 'center'
  },
  toolbar: {
    minHeight: '3rem'
  },
  content: {
    margin: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  container: {
    height: 450,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  tableRow: {
    '&:hover': {
      backgroundColor: `${theme.palette.grey[400]} !important`,
      cursor: 'pointer'
    }
  },
  cellCheckbox: {
    padding: '5px 40px 5px 5px !important'
  }
}));

const StyledMenu = styled(Menu)(() => ({
  '& .MuiMenu-paper': {
    overflow: 'visible',
    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
    marginTop: '0.6rem'
  }
}));

// Order matters
const EMAIL_ICONS = [
  'forward_to_inbox', // Invitation
  'email', // Registration
  'mark_email_read', // Confirmation
  'unsubscribe', // Cancellation
  'mail_lock', // Reservation
  'contact_mail' // Custom
];

const mapStatuses = (documentTypes = []) =>
  documentTypes
    // Map first 5 document types, BE returns 7 but UI does not need all 7
    .slice(0, 5)
    .map((documentType, index) => ({
      id: documentType.id,
      key: documentType.key,
      label: documentType.value.trim(),
      icon: EMAIL_ICONS[index]
    }))
    // Add custom item since BE doesn't return it
    .concat({
      id: 'custom',
      icon: 'contact_mail',
      label: Localize.get('EmailTypes.Custom'),
      isDisabled: true
    });

const ParticipantsTable = ({
  columns = [],
  entityId = null,
  entityType = null,
  toolbarOptions = []
}) => {
  const {
    data: {
      documentTypes: { data: documentTypes }
    }
  } = useMatch();

  const classes = useStyles();
  const search = useSearch();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const data = useSelector(selectList);
  const filter = useSelector(selectFilter);
  const isLoading = useSelector(selectIsLoading);
  const eventId = useSelector(selectId);
  const totalElements = useSelector(selectTotalElements);
  const totalPages = useSelector(selectTotalPages);
  const isEmailDialogOpen = useSelector(selectIsEmailDialogOpen);
  const emailAnchor = useSelector(selectEmailAnchor);
  const previewDialogConfig = useSelector(selectPreviewDialog);
  const confirmStatusDialog = useSelector(selectConfirmStatusDialog);
  const selectionModel = useSelector(selectSelectionModel);
  const emailStatus = useSelector(selectEmailStatus);
  const details = useSelector(selectDetails);
  const previousEntityId = usePrevious(entityId);

  useEffect(() => {
    if (!entityId || !entityType) {
      return;
    }

    if (previousEntityId !== entityId) {
      dispatch(resetState());
      dispatch(fetchParticipants({ entityId: entityId, filter: { ...filter, page: 0 } }));
    } else {
      dispatch(fetchParticipants({ entityId: entityId, filter: filter }));
    }
  }, [entityId, filter?.page, filter?.sortBy, filter?.sortDirection, filter?.size]);

  // Resets state on component destroy
  useEffect(() => {
    return () => dispatch(resetState());
  }, []);

  const isToolbarItemDisabled = (fieldName) => {
    switch (fieldName) {
      case 'email_participant':
        return !entityId || !entityType || !selectionModel?.length;
      case 'add_participant':
        return !entityId || !entityType;
      case 'delete':
        return true; // selectionModel.length === 0;
      default:
        return false;
    }
  };

  const onEmailMenuClose = () => dispatch(setEmailAnchor(null));

  const onConfirmStatusDialogSave = (selectedParticipantIds = []) => {
    dispatch(setConfirmStatusDialog({ isConfirmStatusDialogOpen: false }));

    const alreadySentToIds = confirmStatusDialog?.config?.listOfParticipants.map(({ id }) => id);
    let forceSelectionModel = [];

    if (!selectedParticipantIds?.length) {
      forceSelectionModel = selectionModel.filter((id) => !alreadySentToIds.includes(id));
    } else if (selectedParticipantIds.length) {
      forceSelectionModel = selectionModel
        .filter((id) => !alreadySentToIds.includes(id))
        .concat(selectedParticipantIds);
    }

    dispatch(
      sendDocumentWithForce({
        type: emailStatus?.key,
        id: eventId,
        data: forceSelectionModel
      })
    )
      .unwrap()
      .then(({ sentTo = [] }) => markSentDocuments(sentTo));
  };

  const onConfirmStatusDialogCancel = () =>
    dispatch(setConfirmStatusDialog({ isConfirmStatusDialogOpen: false }));

  const onPdfPreviewDialogCancel = () => dispatch(setPreviewDialog({ isPreviewDialogOpen: false }));

  const onPdfPreviewDialogSave = () => {
    dispatch(setPreviewDialog({ isPreviewDialogOpen: false }));
    dispatch(
      sendDocument({
        type: emailStatus?.key,
        id: eventId,
        data: selectionModel
      })
    )
      .unwrap()
      .then(({ alreadySentTo = [], sentTo = [] }) => {
        if (sentTo?.length) {
          markSentDocuments(sentTo);
          return;
        }

        if (alreadySentTo?.length) {
          openConfirmationDialog(alreadySentTo);
          return;
        }

        if (!alreadySentTo?.length && !sentTo?.length) {
          dispatch(setSelectionModel([]));
        }
      });
  };

  const onEmailMenuItemClick = (e, emailStatus) => {
    dispatch(setEmailAnchor(null));
    dispatch(setEmailStatus(emailStatus));

    dispatch(previewDocument({ type: emailStatus.key, id: eventId, data: selectionModel }))
      .unwrap()
      .then((data) => {
        const file = new Blob([data], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(file);
        return fileURL;
      })
      .catch((e) => console.error(e))
      .then((src) =>
        dispatch(
          setPreviewDialog({
            title: `${Localize.get('EmailTypes.PdfPreviewDialogTitle', {
              template: emailStatus.label
            })}`,
            src,
            emailStatus,
            isPreviewDialogOpen: true
          })
        )
      );
  };

  const onToolbarItemClick = (fieldName, event) => {
    switch (fieldName) {
      case 'email_participant':
        dispatch(setEmailAnchor(event.currentTarget));
        return;
      case 'add_participant':
        dispatch(saveState({ filter: filter, selectedId: entityId }));
        navigate({
          search: (previousUrlParams) => ({
            ...previousUrlParams,
            mode: 'create',
            eventId: entityId
          })
        });
        return !entityId || !entityType;
      case 'delete':
        return true;
      default:
        return false;
    }
  };

  const markSentDocuments = (sentTo = []) => {
    const newParticipantList = data.map((participant) => {
      const { sentDocuments = null, status } = sentTo.find((s) => s.id === participant.id) || {};
      if (sentDocuments) {
        return { ...participant, sentDocuments, status: status.value };
      }
      return participant;
    });
    dispatch(setData(newParticipantList));
    dispatch(setSelectionModel([]));
  };

  const openConfirmationDialog = (alreadySentTo = []) => {
    dispatch(
      setConfirmStatusDialog({
        isConfirmStatusDialogOpen: true,
        config: {
          title: `${Localize.get('EmailTypes.EmailAlreadyReceivedMessage', {
            emailType: previewDialogConfig?.emailStatus.label
          })}`,
          listOfParticipants: alreadySentTo.map(({ id, name }) => ({ name, id }))
        }
      })
    );
  };

  const EMAIL_STATUSES_MAP = useMemo(() => mapStatuses(documentTypes), []);

  const emailStatuses = useCallback((row) => {
    return EMAIL_STATUSES_MAP.map((status) => {
      const currentStatus = row.sentDocuments.find(
        ({ documentTypeId }) => documentTypeId === status.id
      );

      return currentStatus ? (
        <Tooltip
          key={status.id}
          title={
            <>
              <Typography variant="inherit">
                {capitalizeFirstLetter(currentStatus.value.toLowerCase())}
              </Typography>
              <Typography variant="inherit">{dateToFormat(currentStatus.createdAt)}</Typography>
            </>
          }
        >
          <Icon>{status.icon}</Icon>
        </Tooltip>
      ) : (
        <Icon color="disabled" key={status.id}>
          {status.icon}
        </Icon>
      );
    });
  }, []);

  const onParticipantDetailsClick = (row) => {
    dispatch(saveState({ selectedId: eventId, filter }));
    navigate({
      to: `/events/${row.event.id}/participant-details/${row.id}`,
      replace: false
    });
  };

  return (
    <>
      {search?.mode === 'create' ? (
        <CreateParticipant event={details} />
      ) : (
        <>
          <div className={classes.root}>
            <TabContent>
              <div className={classes.toolbarContainer}>
                <Typography variant="h6" component="h6" color="primary">
                  {Localize.get('Labels.Participants')}
                </Typography>
                <Toolbar className={classes.toolbar}>
                  {toolbarOptions?.map((item, index) => (
                    <ToolbarItem
                      key={index}
                      item={item}
                      isDisabled={item.disabled || isToolbarItemDisabled(item.fieldName)}
                      onToolbarItemClick={onToolbarItemClick}
                    />
                  ))}
                </Toolbar>
              </div>
              <TabContainer>
                <StyledDataGrid
                  keepNonExistentRowsSelected={true}
                  loading={isLoading}
                  rows={data}
                  columns={[
                    ...columns.map((column) => ({
                      ...column,
                      headerName: Localize.get(column.headerName),
                      renderCell: (params) => (
                        <div data-test-id={`${params.field}-${params?.row?.id ?? 'default'}`}>
                          {['emails'].includes(params.field) ? (
                            <>{emailStatuses(params.row)}</>
                          ) : ['firstName'].includes(params.field) ? (
                            <Tooltip title={params.value} arrow>
                              <Link
                                sx={{
                                  display: 'block',
                                  cursor: 'pointer',
                                  maxWidth: params?.colDef?.width,
                                  whiteSpace: 'nowrap',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis'
                                }}
                                onClick={() => onParticipantDetailsClick(params.row)}
                              >
                                {params?.value}
                              </Link>
                            </Tooltip>
                          ) : (
                            <Typography variant="body" component="div">
                              {params.value}
                            </Typography>
                          )}
                        </div>
                      )
                    }))
                  ]}
                  initialState={{
                    pagination: {
                      pageSize: totalPages,
                      rowCount: totalElements,
                      page: filter?.page
                    }
                  }}
                  onSelectionModelChange={(newSelectionModel) => {
                    dispatch(setSelectionModel(newSelectionModel));
                  }}
                  selectionModel={selectionModel}
                  checkboxSelection
                  localeText={getLocalesText(Localize)}
                  pagination
                  paginationMode="server"
                  disableColumnMenu
                  page={filter?.page}
                  pageSize={filter?.size}
                  rowCount={totalElements}
                  rowsPerPageOptions={PAGE_SIZE_OPTIONS}
                  disableSelectionOnClick
                  components={{ Toolbar: () => <TableToolbar /> }}
                  onPageChange={(page) => dispatch(setFilterParams({ page, selectionModel }))}
                  onPageSizeChange={(value) =>
                    dispatch(setFilterParams([{ key: 'size', value, selectionModel }]))
                  }
                />

                <StyledMenu
                  id="basic-menu"
                  anchorEl={emailAnchor}
                  open={isEmailDialogOpen}
                  className={classes.menuBox}
                  onClose={onEmailMenuClose}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button'
                  }}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      '&:before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        left: 25,
                        width: 10,
                        height: 10,
                        bgcolor: 'background.paper',
                        transform: 'translateY(-50%) rotate(45deg)',
                        zIndex: 0
                      }
                    }
                  }}
                >
                  {EMAIL_STATUSES_MAP.map((emailStatus) => (
                    <MenuItem
                      key={emailStatus.id}
                      onClick={(e) => onEmailMenuItemClick(e, emailStatus)}
                      disabled={emailStatus?.isDisabled}
                    >
                      <ListItemIcon sx={{ color: (theme) => theme.palette.common.black }}>
                        <Icon fontSize="small">{emailStatus.icon}</Icon>
                      </ListItemIcon>
                      <Typography>{emailStatus.label}</Typography>
                    </MenuItem>
                  ))}
                </StyledMenu>
              </TabContainer>
            </TabContent>
          </div>

          <PdfPreviewDialog
            title={previewDialogConfig?.title}
            fullScreen={false}
            src={previewDialogConfig.src}
            isOpen={previewDialogConfig?.isPreviewDialogOpen}
            onCancel={onPdfPreviewDialogCancel}
            onSave={onPdfPreviewDialogSave}
          />

          <ConfirmStatusDialog
            title={confirmStatusDialog?.config?.title}
            listOfParticipants={confirmStatusDialog?.config?.listOfParticipants}
            isOpen={confirmStatusDialog?.isConfirmStatusDialogOpen}
            onConfirmStatusDialogCancel={onConfirmStatusDialogCancel}
            onConfirmStatusDialogSave={onConfirmStatusDialogSave}
          />
        </>
      )}
    </>
  );
};

export default ParticipantsTable;
