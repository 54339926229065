import React from 'react';
import Localize from 'react-intl-universal';
import { useMatch } from 'react-location';

import { dateInitFormats } from '@common/Constants';
import { dateToFormat } from '@common/helpers/dates';
import BusinessPartnersTypesDropdown from '@components/BusinessPartnersTypesDropdown';
import RightSidebar from '@components/RightSidebar';
import sidebar from '@pages/Contacts/util/fields/sidebar';
import { initialValues } from '@pages/Contacts/util/initialValues';
import contactsSchema from '@pages/Contacts/util/schemas/createContactSchema';
import dialogFieldsHotelSearch from '@pages/Participants/utils/fields/dialogFieldsHotelSearch';

import { mapPropsToAddress } from '../CreateContact/getFieldsConfig';

const Sidebar = ({
  details = null,
  isDetailsLoading = false,
  onSave = () => {},
  onDelete = () => {}
}) => {
  const {
    data: {
      phoneTypes: { data: phoneTypes },
      emailTypes: { data: emailTypes },
      addressTypes: { data: addressTypes }
    }
  } = useMatch();

  const extendedConfig = (setFieldValue, values, setValues) => ({
    ['person.birthDate']: {
      maxDate: new Date(),
      onChange: (newDate) =>
        setFieldValue('person.birthDate', dateToFormat(newDate, dateInitFormats.dateTime))
    },
    ['organizationPerson.id']: {
      extendedComponents: BusinessPartnersTypesDropdown,
      dialogColumns: dialogFieldsHotelSearch,
      tokenValue: values?.organizationPerson.id
        ? [{ title: values.organizationPerson.name }]
        : undefined,
      selectionModel: values?.organizationPerson?.id,
      selectItem: (value) => {
        setValues({
          ...values,
          organizationPerson: {
            id: value.id,
            name: value.name,
            organizationPersonId: value.organizationPersonId
          }
        });
      },
      onIconClick: (e) => {
        if (e?.target?.name === 'delete') {
          setValues({
            ...values,
            organizationPerson: initialValues.organizationPerson
          });
        }
      }
    },
    ['phoneDtos']: {
      ['type.id']: {
        options: phoneTypes
      }
    },
    ['emailDtos']: {
      ['type.id']: {
        options: emailTypes
      }
    },
    ['addressDtos']: {
      ['type.id']: {
        options: addressTypes
      }
    }
  });

  return (
    <RightSidebar
      config={{ title: Localize.get('ContactsTile.ContactsDetails'), fieldsConfig: sidebar }}
      extendedConfig={extendedConfig}
      data={details}
      validationSchema={contactsSchema}
      isDetailsLoading={isDetailsLoading}
      onSave={onSave}
      onDelete={onDelete}
      mapPropsToAddress={mapPropsToAddress}
    />
  );
};

export default Sidebar;
