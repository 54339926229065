import React, { useState, useEffect } from 'react';
import Localize from 'react-intl-universal';
import { useDispatch, useSelector } from 'react-redux';

import moment from 'moment';

import { CssBaseline } from '@mui/material';
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';

import AppRouter from '@navigation/AppRouter';
import { checkIsAuthEnabled, selectIsAuthEnabled } from '@pages/Login/loginSlice';

moment.suppressDeprecationWarnings = true;

const App = () => {
  const dispatch = useDispatch();
  const [localizeInitDone, setLocalizeInitDone] = useState(false);
  const isAuthEnabled = useSelector(selectIsAuthEnabled);

  const language = navigator.language.toLowerCase().includes('de') ? 'de' : 'en';

  const locales = {
    en: require('./locales/en-us.json'),
    de: require('./locales/de-de.json')
  };

  const loadLocales = () => {
    Localize.init({
      currentLocale: language,
      locales
    }).then(() => {
      setLocalizeInitDone(true);
    });
  };

  useEffect(() => {
    loadLocales();
    dispatch(checkIsAuthEnabled());
  }, []);

  return localizeInitDone && isAuthEnabled !== null ? (
    <>
      <CssBaseline />
      <AppRouter isAuthEnabled={isAuthEnabled} />
    </>
  ) : null;
};

export default App;
