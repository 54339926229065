import { WORK_EMAIL_TYPE_ID, MOBILE_PHONE_TYPE_ID } from '@common/Constants';
import { address } from '@components/FormArray';

export default {
  personDto: {
    firstName: '',
    lastName: '',
    birthDate: '',
    birthPlace: '',
    id: '',
    title: { id: 0, value: '' },
    name: '',
    website: null,
    gender: null
  },
  status: 6, // Registration
  event: null,
  allergies: [],
  nativeLanguage: '',
  hotelReservation: {
    id: {
      hotel: {
        id: undefined,
        name: ''
      }
    },
    roomType: null,
    shuttle: false,
    arrivalDate: null,
    departureDate: null,
    nights: 0,
    persons: 0
  },
  isVegetarian: false,
  isVegan: false,
  isHandicap: false,
  remarks: '',
  cancellationReason: '',
  result: '',
  invoiceCompany: { id: null, name: '' },
  company: { id: '', name: '' },
  biography: '',
  addressDtos: [address],
  emailDtos: [{ type: { id: WORK_EMAIL_TYPE_ID }, email: '' }],
  phoneDtos: [{ type: { id: MOBILE_PHONE_TYPE_ID }, phone: '' }]
};
