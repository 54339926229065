import React from 'react';
import Localize from 'react-intl-universal';

import { get } from 'lodash';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import { isStringDateChecker } from '@common/helpers/dates';
import { capitalizeFirstLetter } from '@common/helpers/string/index.js';
import ActiveStatus from '@components/ActiveStatus';

const BasicInfo = ({ data = null }) => {
  return (
    <Box>
      <ListItem>
        <ListItemText
          sx={{ whiteSpace: 'nowrap' }}
          primary={
            <>
              <Typography
                sx={{ display: 'inline-block', mr: 1 }}
                component="span"
                variant="body2"
                color="text.primary"
              >
                {Localize.get('Labels.Role')}:
              </Typography>
              {isStringDateChecker(data, 'roles[0].roleType.value') ? (
                <Tooltip title={get(data, 'roles[0].roleType.value')} placement="top" arrow>
                  <Typography
                    noWrap={true}
                    sx={{
                      width: `calc(100% - ${Localize.get('Labels.Role').length * 8}px)`,
                      display: 'inline-block',
                      verticalAlign: 'middle'
                    }}
                    component="span"
                    variant="body2"
                    color="text.secondary"
                  >
                    {get(data, 'roles[0].roleType.value')}
                  </Typography>
                </Tooltip>
              ) : (
                <Typography
                  noWrap={true}
                  sx={{
                    width: `calc(100% - ${Localize.get('Labels.Role').length * 8}px)`,
                    display: 'inline-block',
                    verticalAlign: 'middle'
                  }}
                  component="span"
                  variant="body2"
                  color="text.secondary"
                >
                  {get(data, 'roles[0].roleType.value')}
                </Typography>
              )}
            </>
          }
        />
      </ListItem>
      <Divider />

      <ListItem>
        <ListItemText
          sx={{ whiteSpace: 'nowrap' }}
          primary={
            <>
              <Typography
                sx={{ display: 'inline-block', mr: 1 }}
                component="span"
                variant="body2"
                color="text.primary"
              >
                {get(data, 'businessPartnerType.id') === 2
                  ? Localize.get('Labels.ParentalAccount')
                  : Localize.get('Labels.Owner')}
                :
              </Typography>
              {isStringDateChecker(
                data,
                get(data, 'businessPartnerType.id') === 2
                  ? 'organizationPerson.organization.parentalAccount.name'
                  : 'owner.firstName' && 'owner.lastName'
              ) ? (
                <Tooltip
                  title={
                    get(data, 'businessPartnerType.id') === 2
                      ? get(data, 'organizationPerson.organization.parentalAccount.name')
                      : get(data, 'owner') &&
                        `${capitalizeFirstLetter(
                          get(data, 'owner.firstName')
                        )} ${capitalizeFirstLetter(get(data, 'owner.lastName'))}`
                  }
                  placement="top"
                  arrow
                >
                  <Typography
                    noWrap={true}
                    sx={{
                      width: `calc(100% - ${
                        get(data, 'businessPartnerType.id') === 2
                          ? Localize.get('Labels.ParentalAccount').length * 8
                          : Localize.get('Labels.Owner').length * 8
                      }px)`,
                      display: 'inline-block',
                      verticalAlign: 'middle'
                    }}
                    component="span"
                    variant="body2"
                    color="text.secondary"
                  >
                    {get(data, 'businessPartnerType.id') === 2
                      ? get(data, 'organizationPerson.organization.parentalAccount.name')
                      : get(data, 'owner') &&
                        `${capitalizeFirstLetter(
                          get(data, 'owner.firstName')
                        )} ${capitalizeFirstLetter(get(data, 'owner.lastName'))}`}
                  </Typography>
                </Tooltip>
              ) : (
                <Typography
                  noWrap={true}
                  sx={{
                    width: `calc(100% - ${
                      get(data, 'businessPartnerType.id') === 2
                        ? Localize.get('Labels.ParentalAccount').length * 8
                        : Localize.get('Labels.Owner').length * 8
                    }px)`,
                    display: 'inline-block',
                    verticalAlign: 'middle'
                  }}
                  component="span"
                  variant="body2"
                  color="text.secondary"
                >
                  {get(data, 'businessPartnerType.id') === 2
                    ? get(data, 'organizationPerson.organization.parentalAccount.name')
                    : get(data, 'owner') &&
                      `${capitalizeFirstLetter(
                        get(data, 'owner.firstName')
                      )} ${capitalizeFirstLetter(get(data, 'owner.lastName'))}`}
                </Typography>
              )}
            </>
          }
        />
      </ListItem>
      <Divider />

      <ListItem>
        <ListItemText
          sx={{ whiteSpace: 'nowrap' }}
          primary={
            <>
              <Typography
                sx={{ display: 'inline-block', mr: 1 }}
                component="span"
                variant="body2"
                color="text.primary"
              >
                {Localize.get('Labels.Status')}:
              </Typography>
              {isStringDateChecker(data, 'activeStatus') ? (
                <Tooltip title={get(data, 'activeStatus')} placement="top" arrow>
                  <Typography
                    noWrap={true}
                    sx={{
                      width: `calc(100% - ${Localize.get('Labels.Status').length * 8}px)`,
                      display: 'inline-block',
                      verticalAlign: 'middle'
                    }}
                    component="span"
                    variant="body2"
                    color="text.primary"
                  >
                    <ActiveStatus status={get(data, 'activeStatus')} />
                  </Typography>
                </Tooltip>
              ) : (
                <Typography
                  noWrap={true}
                  sx={{
                    width: `calc(100% - ${Localize.get('Labels.Status').length * 8}px)`,
                    display: 'inline-block',
                    verticalAlign: 'middle'
                  }}
                  component="span"
                  variant="body2"
                  color="text.primary"
                >
                  <ActiveStatus status={get(data, 'activeStatus')} />
                </Typography>
              )}
            </>
          }
        />
      </ListItem>
      <Divider />

      <ListItem>
        <ListItemText
          sx={{ whiteSpace: 'nowrap' }}
          primary={
            <>
              <Typography
                sx={{ display: 'inline-block', mr: 1 }}
                component="span"
                variant="body2"
                color="text.primary"
              >
                {Localize.get('Communication.Phone')}:
              </Typography>
              {isStringDateChecker(data, 'organizationPerson.phoneDtos[0].phone') ? (
                <Tooltip
                  title={get(data, 'organizationPerson.phoneDtos[0].phone')}
                  placement="top"
                  arrow
                >
                  <Typography
                    noWrap={true}
                    component="span"
                    variant="body2"
                    color="text.secondary"
                    sx={{
                      width: `calc(100% - ${Localize.get('Communication.Phone').length * 8}px)`,
                      display: 'inline-block',
                      verticalAlign: 'middle'
                    }}
                  >
                    {get(data, 'organizationPerson.phoneDtos[0].phone')}
                  </Typography>
                </Tooltip>
              ) : (
                <Typography
                  noWrap={true}
                  component="span"
                  variant="body2"
                  color="text.secondary"
                  sx={{
                    width: `calc(100% - ${Localize.get('Communication.Phone').length * 8}px)`,
                    display: 'inline-block',
                    verticalAlign: 'middle'
                  }}
                >
                  {get(data, 'organizationPerson.phoneDtos[0].phone')}
                </Typography>
              )}
            </>
          }
        />
      </ListItem>
      <Divider />

      <ListItem>
        <ListItemText
          sx={{ whiteSpace: 'nowrap' }}
          primary={
            <>
              <Typography
                sx={{ display: 'inline-block', mr: 1 }}
                component="span"
                variant="body2"
                color="text.primary"
              >
                {Localize.get('Communication.Email')}:
              </Typography>
              {isStringDateChecker(data, 'organizationPerson.emailDtos[0].email') ? (
                <Tooltip
                  title={get(data, 'organizationPerson.emailDtos[0].email')}
                  placement="top"
                  arrow
                >
                  <Typography
                    noWrap={true}
                    component="span"
                    variant="body2"
                    color="text.secondary"
                    sx={{
                      width: `calc(100% - ${Localize.get('Communication.Email').length * 8}px)`,
                      display: 'inline-block',
                      verticalAlign: 'middle'
                    }}
                  >
                    {get(data, 'organizationPerson.emailDtos[0].email')}
                  </Typography>
                </Tooltip>
              ) : (
                <Typography
                  noWrap={true}
                  component="span"
                  variant="body2"
                  color="text.secondary"
                  sx={{
                    width: `calc(100% - ${Localize.get('Communication.Email').length * 8}px)`,
                    display: 'inline-block',
                    verticalAlign: 'middle'
                  }}
                ></Typography>
              )}
            </>
          }
        />
      </ListItem>
      <Divider />

      <ListItem>
        <ListItemText
          sx={{ whiteSpace: 'nowrap' }}
          primary={
            <>
              <Typography
                sx={{ display: 'inline-block', mr: 1 }}
                component="span"
                variant="body2"
                color="text.primary"
              >
                {Localize.get('Communication.Website')}:
              </Typography>
              {isStringDateChecker(data, 'organizationPerson.website') ? (
                <Tooltip title={get(data, 'organizationPerson.website')} placement="top" arrow>
                  <Typography
                    noWrap={true}
                    component="span"
                    variant="body2"
                    color="text.secondary"
                    sx={{
                      width: `calc(100% - ${Localize.get('Communication.Website').length * 8}px)`,
                      display: 'inline-block',
                      verticalAlign: 'middle'
                    }}
                  >
                    {get(data, 'organizationPerson.website')}
                  </Typography>
                </Tooltip>
              ) : (
                <Typography
                  noWrap={true}
                  component="span"
                  variant="body2"
                  color="text.secondary"
                  sx={{
                    width: `calc(100% - ${Localize.get('Communication.Website').length * 8}px)`,
                    display: 'inline-block',
                    verticalAlign: 'middle'
                  }}
                >
                  {get(data, 'organizationPerson.website')}
                </Typography>
              )}
            </>
          }
        />
      </ListItem>
      <Divider />
    </Box>
  );
};

export default BasicInfo;
