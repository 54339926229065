import React from 'react';

import { styled } from '@mui/material/styles';

import useWindowDimensions from '@hooks/window/useWindowDimensions';

const StyledContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'currentHeight' && prop !== 'currentWidth'
})(({ theme, sx }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: sx?.justifyContent || 'start',
  alignItems: sx?.alignItems || 'start',
  flexDirection: 'row',
  height: `calc(100vh - (${theme.sizes.tabsHeight} + ${theme.sizes.subheader} + ${theme.sizes.appHeader} + 7rem))`
}));

const TabContainer = ({ children, sx }) => {
  const { height, width } = useWindowDimensions();

  return (
    <StyledContainer sx={sx} currentWidth={width} currentHeight={height}>
      {children}
    </StyledContainer>
  );
};

export default TabContainer;
