import { styled } from '@mui/styles';

const CONTAINS_HTML_REGEX = /^(<\/?[a-z][\s\S]*>)$/;

export const StyledDescriptionWrap = styled('div')(() => ({
  overflow: 'hidden',
  maxWidth: '100%'
}));

export const StyledDescription = styled('div')((nowrap) => ({
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: nowrap ? 'nowrap' : 'normal',
  lineClamp: 2,
  WebkitBoxOrient: 'vertical'
}));

export const StyledDescriptionContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'width'
})(({ width }) => ({
  width: width,
  minWidth: width,
  maxWidth: width
}));

const renderDescription = (description = '', isHover = false) =>
  CONTAINS_HTML_REGEX.test(description) ? (
    <div style={{ textAlign: 'left' }} dangerouslySetInnerHTML={{ __html: description }} />
  ) : isHover ? (
    <>{description}</>
  ) : (
    <StyledDescription>{description}</StyledDescription>
  );

export default renderDescription;
