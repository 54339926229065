import React, { useState, useEffect } from 'react';
import Localize from 'react-intl-universal';
import { useNavigate } from 'react-location';
import { useDispatch, useSelector } from 'react-redux';

import { RESOURCES_PATHS } from '@common/network/ApiPaths';
import EntityTypes from '@common/network/EntityTypes';
import { CONFIRM_ACTIONS, openConfirmDialog } from '@components/ConfirmDialog';
import { selectIsFullScreen } from '@components/DetailsToolbar/detailsToolbarSlice';
import Header from '@components/Header';
import LayoutContainer, { LeftContainer, RightContainer } from '@components/LayoutContainer';
import MasterList from '@components/MasterList/MasterList';
import MasterListFooter from '@components/MasterListFooter';
import MasterListItem from '@components/MasterListItem';
import MasterListTitle from '@components/MasterListTitle';
import MasterListToolbar from '@components/MasterListToolbar';
import RightSidebar from '@components/RightSidebar';
import { selectIsOpen, setMode } from '@components/RightSidebar/rightSidebarSlice';
import SortDialog from '@components/SortDialog';
import { SEARCH_INPUT_DELAY } from '@config/inputs';
import useDebounce from '@hooks/handlers/useDebounce';

import ResourcesDetailContainer from './components/DetailsContainer/ResourcesDetailContainer';
import ResourcesTabs from './components/DetailsContainer/ResourcesTabs';
import {
  initialState,
  selectId,
  selectList,
  selectFilter,
  selectTotalElements,
  selectTotalPages,
  selectDetails,
  selectIsLoading,
  selectIsDetailsLoading,
  setSelectedId,
  setFilterParams,
  fetchResourceDetails,
  fetchResources,
  saveResource,
  resetState,
  setDetails,
  deleteResource
} from './resourcesSlice';
import fieldsDetails from './util/fields/editResourceFields';
import { fieldsSort } from './util/fields/fields';
import resourceSchema from './util/schemas/resourceSchema';
import { initResource } from './util/util';

const Resources = () => {
  /* eslint-disable no-unused-vars */
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const selectedId = useSelector(selectId);
  const filter = useSelector(selectFilter);
  const data = useSelector(selectList);
  const details = useSelector(selectDetails);
  const totalElements = useSelector(selectTotalElements);
  const totalPages = useSelector(selectTotalPages);
  const [isOpenDialogSort, setIsOpenDialogSort] = useState(false);
  const [detailsConfig] = useState({
    title: Localize.get('ResourcesTile.Details'),
    fieldsConfig: fieldsDetails
  });

  const isDetailsLoading = useSelector(selectIsDetailsLoading);
  const isLoading = useSelector(selectIsLoading);

  const isOpen = useSelector(selectIsOpen);
  const isFullScreen = useSelector(selectIsFullScreen);

  useEffect(
    () => dispatch(fetchResources(filter)),
    [filter.sortBy, filter.sortDirection, filter.page]
  );

  useEffect(() => dispatch(fetchResourceDetails(selectedId)), [selectedId]);

  useDebounce(() => dispatch(fetchResources(filter)), SEARCH_INPUT_DELAY, [filter.search]);

  useEffect(() => {
    return () => dispatch(resetState());
  }, []);

  const onAddNewClick = () => {
    navigate({ to: '/resources/new', replace: false });
  };

  const onDelete = () => {
    dispatch(
      openConfirmDialog({
        title: Localize.get('ConfirmationMessages.Delete', {
          item: Localize.get('ResourcesTile.Resource')?.toLowerCase()
        }),
        confirmButton: Localize.get('Buttons.Delete'),
        cancelButton: Localize.get('Buttons.Cancel')
      })
    )
      .unwrap()
      .then((result) => {
        if (result === CONFIRM_ACTIONS.Cancel) {
          return;
        }
        dispatch(deleteResource(selectedId))
          .unwrap()
          .then(() => dispatch(setMode('read')))
          .catch((rejectedValueOrSerializedError) => console.error(rejectedValueOrSerializedError));
      });
  };

  const onEditSave = (values) => {
    return dispatch(saveResource(values))
      .unwrap()
      .then(() => {
        return Promise.resolve();
      })
      .catch((rejectedValueOrSerializedError) => {
        return Promise.reject({
          rejectedValueOrSerializedError,
          entityType: EntityTypes.RESOURCE
        });
      });
  };
  const extendedConfig = (setFieldValue, values, setValues) => {
    return {
      ['resourceCategory']: {
        formItemChangeHandler: (e, data) => {
          setFieldValue('resourceCategory', data.newValue);
        },
        path: RESOURCES_PATHS.RESOURCE_CATEGORIES
      },
      ['glAccount']: {
        formItemChangeHandler: (e, data) => {
          setFieldValue('glAccount', data.newValue);
        },
        path: RESOURCES_PATHS.GL_ACCOUNTS
      },
      ['giftWarehouse']: {
        formItemChangeHandler: (e, data) => {
          setFieldValue('giftWarehouse', data.newValue);
        },
        path: RESOURCES_PATHS.GIFT_WAREHOUSES
      },
      ['availableAmount']: {
        disabled: values.irrAmount
      },
      ['irrAmount']: {
        onChange: (e, newValue) =>
          setValues({
            ...values,
            irrAmount: newValue,
            availableAmount: initResource.availableAmount
          })
      },
      ['price']: {
        disabled: !values.billableItem
      },
      ['currency']: {
        disabled: !values.billableItem,
        formItemChangeHandler: (e, data) => {
          setFieldValue('currency', data.newValue);
        },
        path: RESOURCES_PATHS.CURRENCIES
      },
      ['billableItem']: {
        onChange: (e, newValue) =>
          setValues({
            ...values,
            billableItem: newValue,
            price: initResource.price,
            currency: initResource.currency
          })
      }
    };
  };

  return (
    <LayoutContainer>
      <LeftContainer isFullScreen={isFullScreen}>
        <Header data-test-id="resources">
          <MasterListTitle>{`${Localize.get(
            'ResourcesTile.MasterListTittle'
          )} (${totalElements})`}</MasterListTitle>
        </Header>

        <MasterListToolbar
          isDisabled={data?.length === 0}
          onSortClick={() => setIsOpenDialogSort(true)}
          onFilterClick={() => alert('Not implemented.')}
          isSortActive={
            filter.sortBy !== initialState.filter.sortBy ||
            filter.sortDirection !== initialState.filter.sortDirection
          }
          searchProps={{
            value: filter.search,
            onSearchChange: (e) =>
              dispatch(setFilterParams({ key: 'search', value: e.target.value }))
          }}
        />

        <MasterList
          isLoading={isLoading}
          data={data}
          renderMasterItem={(item) => (
            <MasterListItem
              key={item.id}
              id={item.id}
              heading={{ left: item?.name, right: item?.id }}
              leftDetails={{
                secondRow: item.resourceCategory.value
              }}
              rightDetails={{
                firstRow: item?.price ? `${item.price} ${item?.currency?.value || ''}` : ''
              }}
              selectedId={selectedId}
              onItemSelect={(payload) => dispatch(setSelectedId(payload))}
            />
          )}
        />
        <MasterListFooter
          onAddClick={() => onAddNewClick()}
          onPageChange={(e, page) => dispatch(fetchResources({ ...filter, page: page - 1 }))}
          pagination={{ totalPages, page: filter.page + 1 }}
        />

        <SortDialog
          open={isOpenDialogSort}
          onCancel={() => setIsOpenDialogSort(false)}
          fields={fieldsSort}
          sortHandler={({ sortBy, sortDirection }) => {
            dispatch(
              setFilterParams([
                { key: 'sortBy', value: sortBy },
                { key: 'sortDirection', value: sortDirection }
              ])
            );
          }}
          sortState={filter}
        />
      </LeftContainer>

      <RightContainer open={isOpen} isFullScreen={isFullScreen}>
        <ResourcesDetailContainer entityId={selectedId} details={details}>
          <ResourcesTabs
            entityId={selectedId}
            entityType={EntityTypes.RESOURCE}
            details={details}
            setDetails={setDetails}
          />
        </ResourcesDetailContainer>
      </RightContainer>

      <RightSidebar
        onSave={onEditSave}
        onDelete={onDelete}
        extendedConfig={extendedConfig}
        config={detailsConfig}
        data={details}
        isDetailsLoading={isDetailsLoading}
        validationSchema={resourceSchema}
      />
    </LayoutContainer>
  );
};

export default Resources;
