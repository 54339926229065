import Localize from 'react-intl-universal';

import { isNil } from 'lodash';

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { ACTION_MODES, NAVIGATE_TOASTER_TIMEOUT, SORT_DIRECTION } from '@common/Constants';
import { deepMerge } from '@common/helpers/deepMerge';
import { errorMessageFormatter, successMessageFormatter } from '@common/helpers/MessageFormatter';
import { BUSINESS_PARTNERS_PATHS } from '@common/network/ApiPaths';
import EntityTypes from '@common/network/EntityTypes';
import { clearState as clearPersistState } from '@common/storage/persistSlice';
import { showSnackbar, SnackbarSeverityTypes } from '@components/Snackbar/snackbarSlice';
import { MASTER_LIST_PAGE_SIZE } from '@config/network';
import {
  deleteByPath,
  getByPathAndParams,
  postByPathAndData,
  putByPathAndData
} from '@services/BaseApi';

import { formatForDetails, formatPostObject, formatSaveData } from './util/formatting';
import { SORT_DATA } from './util/sortConfig';

export const initialState = {
  selectedId: null,
  data: [],
  filter: {
    search: '',
    sortBy: SORT_DATA[0].name,
    sortDirection: SORT_DIRECTION.DESCENDING,
    page: 0,
    size: MASTER_LIST_PAGE_SIZE
  },
  totalPages: 0,
  totalElements: 0,
  details: null,
  locationDetails: null,
  isLoading: true,
  isCreateLoading: false,
  isDetailsLoading: false,
  createdBusinessPartner: null
};

export const fetchBusinessPartners = createAsyncThunk(
  'businessPartners/fetchAll',
  async ({ isLocations, ...filter }, { getState, dispatch }) => {
    const { businessPartnerFilter, selectedId } = getState()?.persist?.savedState || {};
    const isNavigatedFromTabs = Boolean(businessPartnerFilter && selectedId);
    const locationParams = {
      filters: {
        advancedFilters: [
          {
            key: 'location',
            value: 'true',
            operation: 'equal'
          }
        ]
      },
      ...(isNavigatedFromTabs ? { ...businessPartnerFilter } : { ...filter })
    };
    const businessPartnerParams = {
      ...(isNavigatedFromTabs ? { ...businessPartnerFilter } : { ...filter })
    };

    return getByPathAndParams({
      path: BUSINESS_PARTNERS_PATHS.GET,
      params: isLocations ? locationParams : businessPartnerParams
    })
      .then((response) => {
        if (isNavigatedFromTabs) {
          dispatch(setSelectedId(selectedId));
          dispatch(clearPersistState());
        }
        return response.data;
      })
      .catch((error) => error);
  }
);

export const fetchBusinessPartnerDetails = createAsyncThunk(
  'businessPartners/fetchDetails',
  (selectedId) =>
    getByPathAndParams({
      path: BUSINESS_PARTNERS_PATHS.GET_DETAILS,
      pathVariables: { id: selectedId }
    })
      .then((response) => response?.data)
      .catch((error) => error),
  {
    condition: (selectedId) => Boolean(selectedId),
    dispatchConditionRejection: true
  }
);

export const fetchLocationDetails = createAsyncThunk(
  'businessPartners/fetchLocationDetails',
  (selectedId) =>
    getByPathAndParams({
      path: BUSINESS_PARTNERS_PATHS.GET_DETAILS,
      pathVariables: { id: selectedId }
    })
      .then((response) => formatForDetails(response?.data ?? {}))
      .catch((error) => error),
  {
    condition: (selectedId) => Boolean(selectedId),
    dispatchConditionRejection: true
  }
);

export const createBusinessPartner = createAsyncThunk(
  'businessPartners/create',
  ({ postData, type }, thunkAPI) => {
    return postByPathAndData({
      path: BUSINESS_PARTNERS_PATHS.POST,
      data: formatPostObject(postData)
    })
      .then((response) => {
        setTimeout(() => {
          thunkAPI.dispatch(
            showSnackbar({
              message: successMessageFormatter(type, ACTION_MODES.Create),
              severity: SnackbarSeverityTypes.SUCCESS
            })
          );
        }, NAVIGATE_TOASTER_TIMEOUT);

        return response.data;
      })
      .catch((error) => {
        return thunkAPI.rejectWithValue(error.response);
      });
  }
);

export const deleteBusinessPartner = createAsyncThunk(
  'businessPartners/delete',
  async ({ id, type }, { dispatch, rejectWithValue }) => {
    return deleteByPath({
      path: BUSINESS_PARTNERS_PATHS.DELETE,
      pathVariables: { id }
    })
      .then((response) => {
        dispatch(
          showSnackbar({
            message: successMessageFormatter(type, ACTION_MODES.Delete),
            severity: SnackbarSeverityTypes.SUCCESS
          })
        );

        return response.data;
      })
      .catch((error) => {
        dispatch(
          showSnackbar({
            message: errorMessageFormatter(error, type, ACTION_MODES.Delete),
            severity: SnackbarSeverityTypes.ERROR
          })
        );

        return rejectWithValue(error.response);
      });
  }
);

export const saveBusinessPartner = createAsyncThunk(
  'businessPartners/save',
  (postData, thunkAPI) => {
    return putByPathAndData({
      path: BUSINESS_PARTNERS_PATHS.PUT,
      data: formatSaveData(postData),
      pathVariables: { id: postData.id }
    })
      .then((response) => {
        thunkAPI.dispatch(
          showSnackbar({
            message: successMessageFormatter(EntityTypes.BUSINESS_PARTNER, ACTION_MODES.Edit),
            severity: SnackbarSeverityTypes.SUCCESS
          })
        );

        return response.data;
      })
      .catch((error) => thunkAPI.rejectWithValue(error.response));
  }
);

export const saveLocations = createAsyncThunk('saveLocations/save', (postData, thunkAPI) =>
  putByPathAndData({
    path: BUSINESS_PARTNERS_PATHS.PUT,
    data: formatSaveData(postData),
    pathVariables: { id: postData.id }
  })
    .then((response) => {
      setTimeout(() => {
        thunkAPI.dispatch(
          showSnackbar({
            message: successMessageFormatter(EntityTypes.LOCATION, ACTION_MODES.Edit),
            severity: SnackbarSeverityTypes.SUCCESS
          })
        );
      }, NAVIGATE_TOASTER_TIMEOUT);

      return response.data;
    })
    .catch((error) => thunkAPI.rejectWithValue(error.response))
);

const formattingDetails = (actionDetails) => {
  const roles = actionDetails?.roles ?? [];
  const rolesCopy = [...roles];
  const person = actionDetails?.organizationPerson?.person;

  const parentalAccount = actionDetails?.organizationPerson?.organization?.parentalAccount;
  let formattedState = {};

  formattedState = {
    ...actionDetails,
    organizationPerson: {
      ...actionDetails?.organizationPerson,
      organization: {
        ...actionDetails?.organizationPerson?.organization,
        vatno: actionDetails?.organizationPerson?.organization?.vatno || ''
      }
    }
  };

  if (person?.id) {
    formattedState = {
      ...formattedState,
      organizationPerson: {
        ...formattedState.organizationPerson,
        person: {
          ...person,
          birthDate: formattedState.organizationPerson.person?.birthDate || null,
          title: formattedState.organizationPerson.person.title || { id: 0 }
        }
      }
    };
  } else {
    formattedState = {
      ...formattedState,
      organizationPerson: {
        ...formattedState.organizationPerson,
        person: null
      }
    };
  }
  formattedState.organizationPerson.website = formattedState?.organizationPerson?.website || '';

  if (!parentalAccount?.id) {
    formattedState = {
      ...formattedState,
      organizationPerson: {
        ...formattedState.organizationPerson,
        organization: { ...formattedState.organizationPerson.organization, parentalAccount: null }
      }
    };
  }

  if (roles?.length > 1) {
    // to be sure that hotel is always on the first place in the row
    rolesCopy.sort((a, b) => a.supplierType.id - b.supplierType.id);
    formattedState = {
      ...formattedState,
      roles: [
        {
          ...rolesCopy[0],
          supplierType: { id: 4, value: Localize.get('Labels.HotelAndVenue') },
          venue: rolesCopy[1].venue
        }
      ]
    };
  }

  return formattedState;
};

export const businessPartnersSlice = createSlice({
  name: 'businessPartners',
  initialState,
  reducers: {
    resetState: () => initialState,
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setSelectedId: (state, action) => {
      state.selectedId = action.payload;
    },
    setCreatedBusinessPartner: (state, action) => {
      state.createdBusinessPartner = action.payload;
    },
    setDetails: (state, { payload }) => {
      state.details = deepMerge(state.details, payload);
    },
    setLocationDetails: (state, { payload }) => {
      state.locationDetails = deepMerge(state.locationDetails, payload);
    },
    deleteSuccess: (state, action) => {
      state.data = [...state.data.filter((item) => item.id !== action.payload.id)];
      state.selectedId = [...state.data.filter((item) => item.id !== action.payload.id)];
    },
    setFilterParams: (state, action) => {
      let newFilterValues = {};

      // Case when search value is reset to empty and search bar is closed
      if (action.payload.key === 'search' && !action.payload.value && !state.filter.search) {
        state.isLoading = false;
        return;
      }

      if (Array.isArray(action.payload)) {
        newFilterValues = action.payload.reduce(
          (obj, item) => ((obj[item.key] = item.value), obj),
          {}
        );
      } else {
        newFilterValues = { [action.payload.key]: action.payload.value };
      }

      state.filter = { ...state.filter, ...newFilterValues, page: action.payload.page ?? 0 };
    }
  },
  extraReducers: (builder) => {
    builder
      // Get all
      .addCase(fetchBusinessPartners.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.data = payload.content;
        state.totalPages = payload.totalPages;
        state.totalElements = isNil(payload?.totalElements)
          ? state.totalElements
          : payload?.totalElements;
        state.selectedId = state.data.some((item) => item.id === state.selectedId)
          ? state.selectedId
          : payload.content?.[0]?.id;
        state.filter.page = payload?.pageable?.pageNumber;
        state.filter.size = payload?.pageable?.pageSize;
      })
      .addCase(fetchBusinessPartners.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchBusinessPartners.rejected, (state) => {
        state.data = [];
        state.isLoading = false;
      })
      // Get Details
      .addCase(fetchBusinessPartnerDetails.fulfilled, (state, action) => {
        state.details = formattingDetails(action.payload);
        state.isDetailsLoading = false;
      })
      .addCase(fetchBusinessPartnerDetails.pending, (state) => {
        state.isDetailsLoading = true;
      })
      .addCase(fetchBusinessPartnerDetails.rejected, (state) => {
        state.details = null;
        state.isDetailsLoading = false;
      })
      .addCase(fetchLocationDetails.fulfilled, (state, action) => {
        state.locationDetails = formattingDetails(action.payload);
        state.isDetailsLoading = false;
      })
      .addCase(fetchLocationDetails.pending, (state) => {
        state.isDetailsLoading = true;
      })
      .addCase(fetchLocationDetails.rejected, (state) => {
        state.locationDetails = null;
        state.isDetailsLoading = false;
      })
      .addCase(createBusinessPartner.fulfilled, (state, action) => {
        state.createdBusinessPartner = action.payload;
      })

      // Update
      .addCase(saveBusinessPartner.pending, (state) => {
        state.isDetailsLoading = true;
      })
      .addCase(saveBusinessPartner.rejected, (state) => {
        state.isDetailsLoading = false;
      })
      .addCase(saveBusinessPartner.fulfilled, (state, action) => {
        let { arg } = action.meta;
        if (state.details.businessPartnerType?.id === 1) {
          arg = {
            ...arg,
            organizationPerson: {
              ...arg?.organizationPerson,
              name: `${arg?.organizationPerson?.person?.firstName} ${arg?.organizationPerson?.person?.lastName}`
            }
          };
        }
        state.isDetailsLoading = false;
        state.data = state.data.map((item) => (item.id === arg.id ? deepMerge(item, arg) : item));
        state.details = deepMerge(state.details, arg);
      })
      .addCase(saveLocations.pending, (state) => {
        state.isDetailsLoading = true;
      })
      .addCase(saveLocations.rejected, (state) => {
        state.isDetailsLoading = false;
      })
      .addCase(saveLocations.fulfilled, (state, action) => {
        state.isDetailsLoading = false;
        state.data = state.data.map((item) =>
          item.id === action.meta.arg.id ? deepMerge(item, action.meta.arg) : item
        );
        state.locationDetails = deepMerge(state.locationDetails, action.meta.arg);
      })
      // Delete
      .addCase(deleteBusinessPartner.rejected, () => {})
      .addCase(deleteBusinessPartner.fulfilled, (state, action) => {
        state.totalElements -= 1;
        state.isLoading = false;
        state.details = null;
        state.locationDetails = null;
        state.data = state.data.filter((item) => item.id !== action.meta.arg.id);
        state.selectedId = state.data.filter((item) => item.id !== action.meta.arg)[0]?.id;

        if (state.data.length === 0 && state.totalPages === 1) {
          state.filter.page = 0;
          state.totalPages = 0;
          state.totalElements = 0;
        } else if (state.data.length === 0 && state.totalPages > 1) {
          state.filter.page -= 1;
        }
      });
  }
});

export const selectId = (state) => state.businessPartners.selectedId;
export const selectList = (state) => state.businessPartners.data;
export const selectFilter = (state) => state.businessPartners.filter;
export const selectTotalElements = (state) => state.businessPartners.totalElements;
export const selectTotalPages = (state) => state.businessPartners.totalPages;
export const selectDetails = (state) => state.businessPartners.details;
export const selectLocationDetails = (state) => state.businessPartners.locationDetails;
export const selectCreatedBusinessPartner = (state) =>
  state.businessPartners.createdBusinessPartner;

export const selectIsLoading = (state) => state.businessPartners.isLoading;
export const selectIsCreateLoading = (state) => state.businessPartners.isCreateLoading;
export const selectIsDetailsLoading = (state) => state.businessPartners.isDetailsLoading;

const { actions, reducer } = businessPartnersSlice;

export const {
  setError,
  setLoading,
  setSelectedId,
  deleteSuccess,
  setFilterParams,
  resetState,
  setDetails,
  setLocationDetails,
  setCreatedBusinessPartner
} = actions;

export default reducer;
