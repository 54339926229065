import Localize from 'react-intl-universal';

import { get } from 'lodash';

import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import { isString, isObject } from '@common/helpers/helpers';

const SelectInput = ({
  field = {},
  options = [],
  form: { touched, errors } = {},
  accessors: { valueAccessor = 'id', textAccessor = 'value' } = {},
  required = false,
  disabled = false,
  helperText = '',
  ...props
}) => {
  const getValue = (option, propName) => {
    if (isString(option)) {
      return option;
    }
    if (isObject(option)) {
      if (propName === textAccessor) return option[textAccessor] ?? '';
      if (propName === valueAccessor) return option[valueAccessor] ?? '';
    }
    return '';
  };

  return (
    <>
      <InputLabel required={required} id={field?.name} disabled={disabled}>
        {props.label}
      </InputLabel>
      <Select
        size="small"
        labelId={field?.name}
        defaultValue={field?.value}
        error={Boolean(get(touched, field?.name) && get(errors, field?.name))}
        disabled={disabled}
        {...field}
        {...props}
      >
        {options.map((option, index) => (
          <MenuItem key={index} value={getValue(option, valueAccessor)}>
            {getValue(option, textAccessor)}
          </MenuItem>
        ))}
      </Select>
      {get(touched, field?.name) && get(errors, field?.name) && (
        <FormHelperText error={true}>{get(errors, field?.name)}</FormHelperText>
      )}
      {helperText && (
        <FormHelperText id="component-helper-text">{Localize.get(helperText)}</FormHelperText>
      )}
    </>
  );
};

export default SelectInput;
