import React, { useEffect } from 'react';
import Localize from 'react-intl-universal';
import { useNavigate } from 'react-location';
import { useSelector, useDispatch } from 'react-redux';

import { Form, Formik, Field } from 'formik';

import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { alpha } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';

import image from '@assets/images/background-waves.jpg';
import { API } from '@common/Constants';
import { LOGIN_PATH } from '@common/network/ApiPaths';
// eslint-disable-next-line no-unused-vars
import CheckboxInput from '@components/CheckboxInput';
import TextInput from '@components/TextInput';
import { postLogin, selectIsLogged } from '@pages/Login/loginSlice';

import loginSchema from './util/schemas/loginSchema';

const COLOR_OPACITY = 0.7;
const SAML_LINK = `${API.API_URL}${LOGIN_PATH.SAML_LOGIN}`;
// 'https://avpz4pzua.accounts.ondemand.com/saml2/idp/sso/avpz4pzua.accounts.ondemand.com?SAMLRequest=hZJLT8MwEIT%2FirX35mGaPqwmqIAQlUCtmsCB28bZkqDEDlmnQvx6QlsEXMrR2m886xkvLt%2BbWuyp48qaGEIvAEFG26IyLzE8ZrejGVwmC8amlq1a9q40W3rriZ0YhIbVcRJD3xllkStWBhti5bRKlw%2F3SnqBajvrrLY1iCUzdW6wuraG%2B4a6lLp9pelxex9D6VyrfL%2B2GuvSslOzYBb4XwZ%2Bmq5B3AyulUF32PQL5oHGffsxbj969FBr2xvHnjUFNWgKT9vmIJd%2BVbQ%2Bs%2F2PBnFrO02HZ8aww5oJxOomBozmZRTlU9L57jUiHY6LXJcTLaNSTmg2QLxB5mpPPzLmnlaGHRoXgwykHAXTkQyz8EJFUyUjbx6GzyA2p3CuKnMM%2FVyS%2BRFidZdlm9FmnWYgnr7LGwA4VaUO7t3vjs5fjN%2FFQHKmhoYcFuhw4f92SU7Hv%2F8j%2BQQ%3D&SigAlg=http%3A%2F%2Fwww.w3.org%2F2000%2F09%2Fxmldsig%23rsa-sha1&Signature=Zf2QQ2rS1KVnc45lPyo6ogOuSvjKfECoxlKyFmBUauTlGgYD4UVl6ObHcewuKgqVdmL%2Fl550WSIbA3s2Edex821UqeXNvyGE0brqeaxsDWBysZHB%2FJRQmmcOSy5nDsFIm3oIBlUDeNWctvqj7RcpZF7fuuphxFGBivn8j3UdJRL4FtlmjAS74GcJDBOgEKMY7ufJxiJvadocwc8cv3tKaPIprgjwJOPvrh5FpoeEEy0XgkGSWNdsnvyR4lr6Au4m%2BEzuj54Y4EoaH8bZciOBuvbGtYuPyK8c2to1Mb8FsJJxbpaSpu07NbB%2B8DQcv25kJs6H7Nt77685wfEy3RaYnw%3D%3D';

const useStyles = makeStyles((theme) => ({
  backgroundBox: {
    backgroundImage: `url(${theme.palette.mode === 'light' ? image : ''})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
  },
  loginBackground: {
    justifyContent: 'center',
    minHeight: '30vh',
    width: '78vw',
    padding: '3rem',
    borderRadius: '0.938rem',
    [theme.breakpoints.up('sm')]: {
      width: '60vw'
    },
    [theme.breakpoints.up('md')]: {
      width: '40vw'
    },
    [theme.breakpoints.up('lg')]: {
      width: '24vw'
    }
  },
  loginForm: {
    justifyContent: 'center',
    minHeight: '100vh',
    alignContent: 'center'
  },
  header: {
    marginBottom: '1rem',
    fontWeight: 'bold',
    fontSize: '1.875rem'
  },
  loginText: {
    marginTop: '0.8rem',
    marginBottom: '2rem',
    fontSize: '14px',
    [theme.breakpoints.up('md')]: {
      fontSize: '1rem'
    }
  },
  formControl: {
    '& .MuiFormControlLabel-label': { fontSize: '0.9rem' }
  },
  button: {
    marginTop: '1.1rem',
    marginBottom: '3px',
    background: theme.palette.login.button,
    color: theme.palette.common.white,
    height: '3rem',
    '&:hover': { background: alpha(theme.palette.login.button, COLOR_OPACITY) },
    '&.MuiButton-root.Mui-disabled': {
      color: alpha(theme.palette.common.white, COLOR_OPACITY),
      '&:hover': { background: alpha(theme.palette.login.button, COLOR_OPACITY) }
    }
  },
  samlButton: {
    background: theme.palette.login.samlButton
  },
  buttonBox: {
    display: 'flex',
    justifyContent: 'center'
  },
  rememberMe: {
    fontSize: '14px',
    [theme.breakpoints.up('md')]: {
      fontSize: '1rem'
    }
  },
  forgotPass: {
    fontSize: '0.7rem',
    '&:hover': {
      background: 'transparent',
      color: alpha(theme.palette.login.button, COLOR_OPACITY)
    }
  }
}));

const initialValues = {
  userName: '',
  password: '',
  rememberMe: false
};

const Login = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isLogin = useSelector(selectIsLogged);

  const onSubmit = (values) => {
    dispatch(postLogin(values));
  };

  const handleSaml = () => {
    window.location.href = SAML_LINK;
  };

  useEffect(() => {
    if (isLogin) {
      window.location.href = '/';
    } else {
      navigate({ to: '/login' });
    }
  }, [isLogin]);

  return (
    <>
      <Box className={classes.backgroundBox}>
        <Grid container direction="column" className={classes.loginForm}>
          <Paper
            data-test-id="login-form"
            variant="elevation"
            elevation={2}
            className={classes.loginBackground}
          >
            <Grid item>
              <Typography
                data-test-id="login-title"
                component="h1"
                variant="h5"
                className={classes.header}
              >
                {Localize.get('Login.Header')}
              </Typography>
              <Typography
                data-test-id="login-description"
                component="h6"
                className={classes.loginText}
              >
                {Localize.get('Login.LoginText')}
              </Typography>
              <Formik
                initialErrors
                initialValues={initialValues}
                validationSchema={loginSchema}
                onSubmit={onSubmit}
              >
                {({ handleSubmit }) => (
                  <>
                    <Form>
                      <FormControl size="small" fullWidth className={classes.formControl}>
                        <Field
                          sx={{ pb: 3 }}
                          required={true}
                          label={Localize.get('Labels.UserName')}
                          name="userName"
                          type="text"
                          component={TextInput}
                          size="normal"
                          variant="filled"
                          icon={<PersonOutlineIcon color="secondary" />}
                        />
                        <Field
                          type="password"
                          sx={{ pb: 1 }}
                          required={true}
                          label={Localize.get('Labels.Password')}
                          name="password"
                          component={TextInput}
                          size="normal"
                          variant="filled"
                          icon={<LockOutlinedIcon color="secondary" />}
                        />
                      </FormControl>

                      <Button
                        data-test-id="login-button"
                        type="submit"
                        fullWidth
                        className={classes.button}
                        variant="outlined"
                        onClick={handleSubmit}
                      >
                        {Localize.get('Buttons.LogIn')}
                      </Button>
                      <Button
                        data-test-id="saml-login-button"
                        fullWidth
                        className={`${classes.button} ${classes.samlButton}`}
                        variant="outlined"
                        onClick={handleSaml}
                      >
                        {Localize.get('Buttons.SamlLogin')}
                      </Button>
                      <Box className={classes.buttonBox}>
                        <Typography component="h6"></Typography>
                        <Button
                          data-test-id="forgot-pass-button"
                          variant="text"
                          className={classes.forgotPass}
                          disabled
                        >
                          {Localize.get('Login.ForgotPass')}
                        </Button>
                      </Box>
                    </Form>
                  </>
                )}
              </Formik>
            </Grid>
          </Paper>
        </Grid>
      </Box>
    </>
  );
};
export default Login;
