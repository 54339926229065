import { get } from 'lodash';

const scrubDtos = (data, path) => {
  return get(data, path, []).map((obj) => {
    if (path.includes('addressDtos')) {
      return {
        ...obj,
        typeId: obj.type?.id,
        countryId: obj.country?.id,
        regionId: obj.region?.id
      };
    }

    if (path.includes('emailDtos')) {
      return {
        ...obj,
        typeId: obj.type?.id
      };
    }

    if (path.includes('phoneDtos')) {
      return {
        ...obj,
        typeId: obj.type?.id
      };
    }

    return obj;
  });
};

export default scrubDtos;
