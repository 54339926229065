import { DIALOG_PATHS } from '@common/network/ApiPaths';

export default [
  {
    groupName: 'Labels.Information',
    fields: [
      {
        label: 'IltSession.Hotel',
        name: 'hotel',
        required: true,
        disabled: true,
        dialogTitle: 'Labels.SearchHotel',
        component: 'tokenValue',
        value: '',
        initQueryState: { id: '', name: '' },
        fetchDataPath: DIALOG_PATHS.HOTELS,
        dialogColumns: [
          {
            field: 'id',
            headerName: 'Labels.ID',
            width: 100,
            editable: false,
            defaultValue: '',
            headerAlign: 'left',
            sortable: false
          },
          {
            field: 'name',
            headerName: 'Labels.Name',
            width: 350,
            editable: false,
            defaultValue: '',
            headerAlign: 'left',
            sortable: false
          },
          {
            field: 'country',
            headerName: 'Labels.Country',
            width: 350,
            editable: false,
            defaultValue: '',
            headerAlign: 'left',
            sortable: false
          },
          {
            field: 'city',
            headerName: 'Labels.City',
            width: 350,
            editable: false,
            defaultValue: '',
            headerAlign: 'left',
            sortable: false
          }
        ]
      },
      {
        label: 'Labels.ContingentAmount',
        name: 'contingent',
        required: true,
        disabled: false,
        component: 'text',
        type: 'number'
      },
      {
        label: 'Hotel.RoomType',
        name: 'accommodation',
        required: true,
        disabled: false,
        dialogTitle: 'IltSession.SearchRoom',
        component: 'tokenValue',
        value: '',
        dialogColumns: [
          {
            field: 'id',
            headerName: 'Labels.ID',
            width: 100,
            editable: false,
            defaultValue: '',
            headerAlign: 'left',
            sortable: false
          },
          {
            field: 'roomType',
            headerName: 'Labels.Name',
            width: 300,
            editable: false,
            defaultValue: '',
            headerAlign: 'left',
            sortable: false,
            valueFormatter: (params) => `${params?.value?.value}`
          },
          {
            field: 'maxOccupancy',
            headerName: 'Labels.MaxOccupancy',
            width: 300,
            editable: false,
            defaultValue: '',
            headerAlign: 'left',
            sortable: false
          },
          {
            field: 'description',
            headerName: 'Labels.Description',
            width: 350,
            editable: false,
            defaultValue: '',
            headerAlign: 'left',
            sortable: false
          }
        ]
      },
      {
        label: 'IltSession.PricePpNight',
        name: 'price',
        required: true,
        disabled: false,
        component: 'text',
        type: 'number',
        column: 4,
        disableBlacklist: true
      },
      {
        label: 'ResourcesTile.Currency',
        name: 'currency.id',
        required: true,
        disabled: false,
        component: 'select',
        column: 2
      },
      {
        label: 'Labels.ValidUntil',
        name: 'validUntil',
        required: false,
        disabled: false,
        component: 'datepicker'
      },
      {
        label: 'Labels.StartDate',
        name: 'startDate',
        required: true,
        disabled: false,
        component: 'datepicker'
      },
      {
        label: 'Labels.EndDate',
        name: 'endDate',
        required: true,
        disabled: false,
        component: 'datepicker'
      }
    ]
  }
];
