import { Navigate } from 'react-location';

import {
  DOCUMENTS_PATHS,
  EVENTS_PATHS,
  PARTICIPANTS_PATHS,
  SESSION_PATHS,
  SETTINGS_PATH
} from '@common/network/ApiPaths';
import EntityTypes from '@common/network/EntityTypes';
import Events, { CreateEvent } from '@pages/Events';
import Session from '@pages/Events/components/Sessions/Session';
import Details from '@pages/Participants/pages/Details';
import { getByPathAndParams } from '@services/BaseApi';

const EventRoute = (routeCache) => ({
  path: 'events',
  children: [
    {
      path: '/',
      element: <Events />,
      loader: routeCache.createLoader(
        async () => ({
          config: getByPathAndParams({
            path: SETTINGS_PATH.GET,
            pathVariables: { tile: EntityTypes.EVENT }
          }),
          documentTypes: await getByPathAndParams({
            path: DOCUMENTS_PATHS.GET_DOCUMENT_TYPES
          }),
          status: await getByPathAndParams({
            path: EVENTS_PATHS.EVENTS_STATUSES
          }),
          type: await getByPathAndParams({
            path: EVENTS_PATHS.EVENTS_TYPES
          }),
          nativeLanguage: await getByPathAndParams({
            path: EVENTS_PATHS.EVENTS_LANGUAGES
          }),
          sessionTypes: await getByPathAndParams({
            path: SESSION_PATHS.TYPES
          })
        }),
        { policy: 'cache-first' }
      )
    },
    {
      path: 'new',
      element: <CreateEvent />,
      loader: routeCache.createLoader(async () => ({
        type: await getByPathAndParams({
          path: EVENTS_PATHS.EVENTS_TYPES
        }),
        status: await getByPathAndParams({
          path: EVENTS_PATHS.EVENTS_STATUSES
        }),
        nativeLanguage: await getByPathAndParams({
          path: EVENTS_PATHS.EVENTS_LANGUAGES
        })
      }))
    },
    {
      path: ':eventId',
      element: null,
      meta: { breadcrumb: (params, event) => event?.name },
      loader: async ({ params: { eventId } }) => ({
        event: await getByPathAndParams({
          path: EVENTS_PATHS.GET_DETAILS,
          pathVariables: { id: eventId }
        })
      }),
      children: [
        {
          path: '/',
          element: <Navigate to="/events" />
        },
        {
          path: 'session-details/:id',
          meta: {
            breadcrumb: (params, session) => {
              return session?.name;
            }
          },
          element: <Session />,
          loader: async ({ params: { id } }) => ({
            sessionDetails: await getByPathAndParams({
              path: SESSION_PATHS.GET_DETAILS,
              pathVariables: { id }
            })
          })
        },
        {
          path: 'participant-details/:id',
          element: <Details />,
          loader: async ({ params: { id } }) => ({
            participantDetails: await getByPathAndParams({
              path: PARTICIPANTS_PATHS.GET_DETAILS,
              pathVariables: { id }
            }),
            participantData: await getByPathAndParams({
              path: PARTICIPANTS_PATHS.PARTICIPANT_FORM
            }),
            participantStatuses: await getByPathAndParams({
              path: PARTICIPANTS_PATHS.PARTICIPANT_STATUSES
            })
          }),
          meta: {
            breadcrumb: (params, participant) => {
              return `${participant?.personDto?.firstName} ${participant?.personDto?.lastName}`;
            }
          }
        }
      ]
    },
    {
      element: <Navigate to="/events" />
    }
  ]
});

export default EventRoute;
