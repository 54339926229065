import { DIALOG_PATHS } from '@common/network/ApiPaths';
import IndividualTypes from '@common/network/IndividualTypes';

export default [
  {
    groupName: 'Labels.Information',
    fields: [
      {
        label: 'Labels.Title',
        name: 'organizationPerson.person.title.id',
        required: false,
        disabled: false,
        component: 'select',
        options: [
          { id: 0, value: 'None' },
          { id: 1, value: 'Mrs' },
          { id: 2, value: 'Mr' },
          { id: 3, value: 'Ms' }
        ]
      },
      {
        label: 'Labels.FirstName',
        name: 'organizationPerson.person.firstName',
        required: true,
        disabled: false,
        component: 'text'
      },
      {
        label: 'Labels.LastName',
        name: 'organizationPerson.person.lastName',
        required: true,
        disabled: false,
        component: 'text'
      },
      {
        label: 'Labels.Name',
        name: 'organizationPerson.name',
        required: true,
        disabled: false,
        component: 'text'
      },
      {
        label: 'Labels.Type',
        name: 'businessPartnerType',
        required: true,
        disabled: true,
        component: 'autocomplete'
      },
      {
        label: 'Labels.Role',
        name: 'roles[0].roleType',
        required: true,
        disabled: true,
        component: 'autocomplete'
      },
      {
        label: 'Labels.SupplierType',
        name: 'roles[0].supplierType',
        required: false,
        disabled: true,
        component: 'autocomplete'
      },
      {
        label: 'Labels.Owner',
        name: 'owner',
        required: false,
        disabled: false,
        dialogTitle: 'Labels.Owner',
        component: 'tokenValue',
        value: '',
        initQueryState: {
          type: IndividualTypes.EMPLOYEE,
          id: '',
          firstName: '',
          lastName: '',
          companyName: ''
        },
        fetchDataPath: DIALOG_PATHS.PERSONS,
        dialogColumns: [
          {
            field: 'personTypeId',
            headerName: 'Labels.ID',
            width: 100,
            editable: false,
            defaultValue: '',
            headerAlign: 'left',
            sortable: false
          },
          {
            field: 'firstName',
            headerName: 'Labels.FirstName',
            width: 350,
            editable: false,
            defaultValue: '',
            headerAlign: 'left',
            sortable: false
          },
          {
            field: 'lastName',
            headerName: 'Labels.LastName',
            width: 350,
            editable: false,
            defaultValue: '',
            headerAlign: 'left',
            sortable: false
          },
          {
            field: 'companyName',
            headerName: 'Labels.CompanyName',
            width: 350,
            editable: false,
            defaultValue: '',
            headerAlign: 'left',
            sortable: false
          }
        ]
      },
      {
        label: 'Labels.ParentalAccount',
        name: 'organizationPerson.organization.parentalAccount',
        required: false,
        disabled: false,
        dialogTitle: 'Labels.SearchParentalAccount',
        component: 'tokenValue',
        value: '',
        initQueryState: {
          type: 'ORGANIZATION',
          id: '',
          firstName: '',
          lastName: ''
        },
        fetchDataPath: DIALOG_PATHS.ACCOUNTS,
        dialogColumns: [
          {
            field: 'id',
            headerName: 'Labels.ID',
            width: 100,
            editable: false,
            defaultValue: '',
            headerAlign: 'left',
            sortable: false
          },
          {
            field: 'name',
            headerName: 'Labels.Name',
            width: 350,
            editable: false,
            defaultValue: '',
            headerAlign: 'left',
            sortable: false
          }
        ]
      },
      {
        label: 'Labels.VAT',
        name: 'organizationPerson.organization.vatno',
        required: false,
        disabled: false,
        component: 'text'
      },
      {
        label: 'Labels.AbcClassification',
        name: 'abcClassification',
        required: false,
        disabled: false,
        component: 'autocomplete'
      }
    ]
  },
  {
    groupName: 'Personal.Title',
    name: 'personalTitle',
    fields: [
      {
        label: 'Personal.BirthDate',
        name: 'organizationPerson.person.birthDate',
        required: false,
        disabled: false,
        component: 'datepicker'
      },
      {
        label: 'Personal.BirthPlace',
        name: 'organizationPerson.person.birthPlace',
        required: false,
        disabled: false,
        component: 'text'
      }
    ]
  },
  {
    groupName: 'HotelFacilities.Title',
    name: 'hotelFacilities',
    fields: [
      {
        label: 'HotelFacilities.Rating',
        name: 'roles[0].hotel.rating',
        required: false,
        disabled: false,
        component: 'rating'
      },
      {
        label: 'HotelFacilities.ShuttleService',
        name: 'roles[0].hotel.hasShuttleService',
        required: false,
        disabled: false,
        component: 'checkbox',
        fieldWidth: 4
      },
      {
        label: 'HotelFacilities.Bar',
        name: 'roles[0].hotel.hasBar',
        required: false,
        disabled: false,
        component: 'checkbox',
        fieldWidth: 4
      },
      {
        label: 'HotelFacilities.Parking',
        name: 'roles[0].hotel.hasParking',
        required: false,
        disabled: false,
        component: 'checkbox',
        fieldWidth: 4
      },
      {
        label: 'HotelFacilities.AirportShuttle',
        name: 'roles[0].hotel.hasAirportShuttle',
        required: false,
        disabled: false,
        component: 'checkbox',
        fieldWidth: 4
      },
      {
        label: 'HotelFacilities.WellnessCenter',
        name: 'roles[0].hotel.hasWellness',
        required: false,
        disabled: false,
        component: 'checkbox',
        fieldWidth: 4
      },
      {
        label: 'HotelFacilities.FitnessCenter',
        name: 'roles[0].hotel.hasFitnessCenter',
        required: true,
        disabled: false,
        component: 'checkbox',
        fieldWidth: 4
      },
      {
        label: 'HotelFacilities.PetsAllowed',
        name: 'roles[0].hotel.petsAllowed',
        required: false,
        disabled: false,
        component: 'checkbox',
        fieldWidth: 4
      },
      {
        label: 'HotelFacilities.SPA',
        name: 'roles[0].hotel.hasSpa',
        required: false,
        disabled: false,
        component: 'checkbox',
        fieldWidth: 4
      },
      {
        label: 'HotelFacilities.FreeWiFi',
        name: 'roles[0].hotel.hasFreeWifi',
        required: false,
        disabled: false,
        component: 'checkbox',
        fieldWidth: 4
      },
      {
        label: 'HotelFacilities.Restaurant',
        name: 'roles[0].hotel.hasRestaurant',
        required: false,
        disabled: false,
        component: 'checkbox',
        fieldWidth: 4
      },
      {
        label: 'HotelFacilities.Reception',
        name: 'roles[0].hotel.receptionNote',
        required: false,
        disabled: false,
        component: 'text'
      }
    ]
  },
  {
    groupName: 'Communication.Email',
    arrayName: 'organizationPerson.emailDtos',
    fields: [
      {
        label: 'Labels.Type',
        name: 'type.id',
        required: false,
        disabled: false,
        component: 'select'
      },
      {
        label: 'Communication.Email',
        name: 'email',
        required: true,
        disabled: false,
        component: 'text'
      }
    ]
  },
  {
    groupName: 'Address.Title',
    arrayName: 'organizationPerson.addressDtos',
    fields: [
      {
        label: 'Labels.Type',
        name: 'type.id',
        required: false,
        disabled: false,
        component: 'select'
      },
      {
        label: 'Address.Street',
        name: 'street',
        required: false,
        disabled: false,
        component: 'text'
      },
      {
        label: 'Address.Number',
        name: 'number',
        required: false,
        disabled: false,
        component: 'text'
      },
      {
        label: 'Address.PostalCode',
        name: 'postalCode',
        required: false,
        disabled: false,
        component: 'text'
      },
      {
        label: 'Address.State',
        name: 'state',
        required: false,
        disabled: false,
        component: 'text'
      },
      {
        label: 'Address.City',
        name: 'city',
        required: false,
        disabled: false,
        component: 'text'
      },
      {
        label: 'Address.POBox',
        name: 'poBox',
        required: false,
        disabled: false,
        component: 'text'
      },
      {
        label: 'Address.Country',
        name: 'country',
        required: true,
        disabled: false,
        component: 'autocomplete'
      },
      {
        label: 'Address.Region',
        name: 'region',
        required: false,
        disabled: false,
        component: 'autocomplete'
      }
    ]
  },
  {
    groupName: 'Events.AdditionalInformation',
    fields: [
      {
        label: 'Communication.Website',
        name: 'organizationPerson.website',
        required: false,
        disabled: false,
        component: 'text'
      }
    ]
  },
  {
    groupName: 'Communication.Title',
    arrayName: 'organizationPerson.phoneDtos',
    fields: [
      {
        label: 'Labels.Type',
        name: 'type.id',
        required: false,
        disabled: false,
        component: 'select'
      },
      {
        label: 'Labels.Phone',
        name: 'phone',
        required: true,
        disabled: false,
        component: 'text'
      }
    ]
  }
];
