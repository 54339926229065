// import { SORT_DIRECTION } from '@common/Constants';

import {
  ATTACHMENT_FILTER_UPDATE,
  SET_LOADING,
  SET_ATTACHMENTS_ERROR,
  LOAD_ATTACHMENTS,
  SET_SELECTION_MODEL,
  DELETE_ATTACHMENT_SUCCESS,
  UPLOAD_ATTACHMENT_SUCCESS
} from './types';

export const PAGE_SIZE = 5;
export const PAGE_SIZE_OPTIONS = [5, 10, 15];

// export const SORT_DATA = [
//   { KEY: 'id', TEXT: 'Meeting Room ID' },
//   { KEY: 'name', TEXT: 'Meeting Room Name' },
//   { KEY: 'venue', TEXT: 'Venue Name' },
//   { KEY: 'availableSeats', TEXT: 'Available Seats' },
//   { KEY: 'floor', TEXT: 'Floor' }
// ];

const attachmentsState = {
  data: [],
  isLoading: false,
  isError: false,
  filter: {
    // TODO: Will be used later
    // sortBy: SORT_DATA[0].KEY,
    // sortDirection: SORT_DIRECTION.ASCENDING,
    search: '',
    match: '',
    searchValue: '',
    page: 0,
    size: PAGE_SIZE
  },
  totalElements: 0,
  selectionModel: []
};

function attachmentsReducer(state, { type, payload }) {
  switch (type) {
    case SET_ATTACHMENTS_ERROR:
      return { ...state, isError: true, isLoading: false, data: [] };
    case SET_LOADING:
      return { ...state, isLoading: payload };
    case SET_SELECTION_MODEL:
      return { ...state, selectionModel: payload };
    case DELETE_ATTACHMENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: [...state.data.filter((item) => item.id !== payload)],
        selectionModel: []
      };
    case UPLOAD_ATTACHMENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: [...state.data, payload]
      };
    case LOAD_ATTACHMENTS:
      return {
        ...state,
        isLoading: false,
        data: payload.content,
        filter: {
          ...state.filter,
          page: payload.pageable.pageNumber,
          size: payload.pageable.pageSize
        },
        totalElements: payload.totalElements
      };

    case ATTACHMENT_FILTER_UPDATE: {
      return {
        ...state,
        filter: {
          ...state.filter,
          search: payload?.value?.search || '',
          match: payload.value.match,
          searchValue: payload.value.searchValue
        }
      };
    }

    default: {
      return state;
    }
  }
}

export { attachmentsReducer, attachmentsState };
