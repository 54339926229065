import { address } from '@components/FormArray';

export default {
  businessPartnerType: 2, // Account selected as default
  supplierType: 1, // Venue selected
  roles: 2, // Supplier
  organizationPerson: {
    name: '',
    organizationPersonInfo: 'organization',
    organization: {
      vatno: '',
      parentalAccount: null
    },
    phoneDtos: [],
    emailDtos: [],
    addressDtos: [address],
    website: ''
  },
  facilities: {
    hasWellness: false,
    hasShuttleService: false,
    hasBar: false,
    hasParking: false,
    hasAirportShuttle: false,
    hasFitnessCenter: false,
    petsAllowed: false,
    hasSpa: false,
    hasFreeWifi: false,
    hasRestaurant: false,
    rating: 0,
    receptionNote: ''
  },
  owner: null,
  abcClassification: null
};
