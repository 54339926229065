import React, { useEffect, useState, useCallback } from 'react';
import Localize from 'react-intl-universal';
import { useNavigate } from 'react-location';
import { useSelector, useDispatch } from 'react-redux';

import EntityTypes from '@common/network/EntityTypes';
import ActivityStatus from '@components/ActivityStatus';
import { CONFIRM_ACTIONS, openConfirmDialog } from '@components/ConfirmDialog';
import { selectIsFullScreen } from '@components/DetailsToolbar/detailsToolbarSlice';
import Header from '@components/Header';
import LayoutContainer, { LeftContainer, RightContainer } from '@components/LayoutContainer';
import MasterList from '@components/MasterList';
import MasterListFooter from '@components/MasterListFooter';
import MasterListItem from '@components/MasterListItem';
import MasterListTitle from '@components/MasterListTitle';
import MasterListToolbar from '@components/MasterListToolbar';
import { selectIsOpen, setMode } from '@components/RightSidebar/rightSidebarSlice';
import SortDialog from '@components/SortDialog';
import { SEARCH_INPUT_DELAY } from '@config/inputs';
import useDebounce from '@hooks/handlers/useDebounce';
import {
  setFilterParams,
  setLoading,
  selectFilter,
  selectTotalElements,
  selectTotalPages,
  selectDetails,
  selectIsLoading,
  selectIsDetailsLoading,
  selectList,
  setSelectedId,
  selectId,
  initialState,
  saveActivity,
  fetchActivities,
  fetchActivityDetails,
  resetState,
  setDetails,
  deleteActivity
} from '@pages/Activities/activitiesSlice';
import Sidebar from '@pages/Activities/components/Sidebar';

import { ActivitiesDetails, ActivitiesTabs } from './components';
import { SORT_DATA } from './util/sortConfig';

const Activities = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isOpenDialogSort, setIsOpenDialogSort] = useState(false);

  const selectedId = useSelector(selectId);
  const data = useSelector(selectList);
  const details = useSelector(selectDetails);
  const totalElements = useSelector(selectTotalElements);
  const totalPages = useSelector(selectTotalPages);
  const filter = useSelector(selectFilter);

  const isLoading = useSelector(selectIsLoading);
  const isDetailsLoading = useSelector(selectIsDetailsLoading);

  const isOpen = useSelector(selectIsOpen);
  const isFullScreen = useSelector(selectIsFullScreen);

  // Get List of entities on initialization and sort change
  useEffect(
    () => dispatch(fetchActivities(filter)),
    [filter.sortBy, filter.sortDirection, filter.page]
  );

  // Get initial List of entities and on sort change
  useEffect(() => dispatch(fetchActivityDetails(selectedId)), [selectedId]);

  // Get List of entities on search change
  useDebounce(() => dispatch(fetchActivities(filter)), SEARCH_INPUT_DELAY, [filter.search]);

  // Resets state on component destroy
  useEffect(() => {
    return () => dispatch(resetState());
  }, []);

  const onSave = useCallback(
    (values) => {
      return dispatch(saveActivity({ ...values, id: selectedId }))
        .unwrap()
        .then(() => {
          return Promise.resolve();
        })
        .catch((rejectedValueOrSerializedError) => {
          return Promise.reject({
            rejectedValueOrSerializedError,
            entityType: EntityTypes.ACTIVITY
          });
        });
    },
    [selectedId]
  );

  const onDelete = () => {
    dispatch(
      openConfirmDialog({
        title: Localize.get('ConfirmationMessages.Delete', {
          item: Localize.get('Activities.Item')?.toLowerCase()
        }),
        confirmButton: Localize.get('Buttons.Delete'),
        cancelButton: Localize.get('Buttons.Cancel')
      })
    )
      .unwrap()
      .then((result) => {
        if (result === CONFIRM_ACTIONS.Cancel) {
          return;
        }
        dispatch(deleteActivity(selectedId))
          .unwrap()
          .then(() => dispatch(setMode('read')))
          .catch((rejectedValueOrSerializedError) => console.error(rejectedValueOrSerializedError));
      });
  };

  const renderMasterListItem = useCallback(
    ({ id, type, subject, status }) => {
      return (
        <MasterListItem
          key={id}
          id={id}
          heading={{ left: subject, right: id }}
          rightDetails={{
            thirdRow: <ActivityStatus status={status?.id}>{status?.value}</ActivityStatus>
          }}
          leftDetails={{
            firstRow: `${type?.value}`
          }}
          selectedId={selectedId}
          onItemSelect={(payload) => dispatch(setSelectedId(payload))}
        />
      );
    },
    [selectedId]
  );
  return (
    <LayoutContainer>
      <LeftContainer isFullScreen={isFullScreen}>
        <Header>
          <MasterListTitle>{`${Localize.get(
            'Activities.MasterListTittle'
          )} (${totalElements})`}</MasterListTitle>
        </Header>

        <MasterListToolbar
          isDisabled={data?.length === 0}
          onSortClick={() => setIsOpenDialogSort(true)}
          onFilterClick={() => alert('Not implemented.')}
          isSortActive={
            filter.sortBy !== initialState.filter.sortBy ||
            filter.sortDirection !== initialState.filter.sortDirection
          }
          searchProps={{
            value: filter.search,
            onSearchChange: (e) => {
              dispatch(setLoading(true));
              dispatch(setFilterParams({ key: 'search', value: e.target.value }));
            }
          }}
        />

        <MasterList isLoading={isLoading} data={data} renderMasterItem={renderMasterListItem} />
        <MasterListFooter
          onPageChange={(e, page) => dispatch(fetchActivities({ ...filter, page: page - 1 }))}
          onAddClick={() => navigate({ to: '/activities/new', replace: false })}
          pagination={{ totalPages, page: filter.page + 1 }}
        />

        <SortDialog
          onCancel={() => setIsOpenDialogSort(false)}
          open={isOpenDialogSort}
          sortState={filter}
          fields={SORT_DATA}
          sortHandler={({ sortBy, sortDirection }) => {
            dispatch(
              setFilterParams([
                { key: 'sortBy', value: sortBy },
                { key: 'sortDirection', value: sortDirection }
              ])
            );
          }}
        />
      </LeftContainer>

      <RightContainer open={isOpen} isFullScreen={isFullScreen}>
        <ActivitiesDetails entityId={selectedId} details={details}>
          <ActivitiesTabs
            entityId={selectedId}
            entityType={details?.type?.id === 1 ? EntityTypes.APPOINTMENT : EntityTypes.TASK}
            details={details}
            setDetails={setDetails}
          />
        </ActivitiesDetails>
      </RightContainer>

      <Sidebar
        isDetailsLoading={isDetailsLoading}
        data={details}
        onSave={onSave}
        onDelete={onDelete}
      />
    </LayoutContainer>
  );
};

export default Activities;
