import AccountTypes from '@common/network/AccountTypes';
import { DIALOG_PATHS } from '@common/network/ApiPaths';
import IndividualTypes from '@common/network/IndividualTypes';

export default [
  {
    groupName: 'Labels.Information',
    fields: [
      {
        label: 'Labels.Subject',
        name: 'subject',
        required: true,
        disabled: false,
        component: 'text'
      },
      {
        label: 'Labels.StartDate',
        name: 'startDateTime',
        required: true,
        disabled: false,
        component: 'datetimepicker'
      },
      {
        label: 'Labels.EndDate',
        name: 'endDateTime',
        required: true,
        disabled: false,
        component: 'datetimepicker'
      },
      {
        label: 'Labels.Priority',
        name: 'priority.id',
        required: true,
        disabled: false,
        component: 'select'
      },
      {
        label: 'Labels.Owner',
        name: 'owner.id',
        required: true,
        disabled: false,
        dialogTitle: 'Labels.Owner',
        component: 'tokenValue',
        value: '',
        initQueryState: {
          personTypeId: '',
          firstName: '',
          lastName: '',
          type: IndividualTypes.EMPLOYEE
        },
        fetchDataPath: DIALOG_PATHS.PERSONS,
        dialogColumns: [
          {
            field: 'personTypeId',
            headerName: 'Labels.ID',
            width: 100,
            editable: false,
            headerAlign: 'left',
            sortable: false
          },
          {
            field: 'firstName',
            headerName: 'Employees.FirstName',
            width: 350,
            editable: false,
            headerAlign: 'left',
            sortable: false
          },
          {
            field: 'lastName',
            headerName: 'Employees.LastName',
            width: 350,
            editable: false,
            headerAlign: 'left',
            sortable: false
          }
        ]
      },
      {
        label: 'Labels.Account',
        name: 'account',
        required: false,
        disabled: false,
        dialogTitle: 'Labels.SearchAccount',
        component: 'tokenValue',
        value: '',
        initQueryState: { id: '', name: '', type: AccountTypes.ORGANIZATION },
        fetchDataPath: DIALOG_PATHS.ACCOUNTS,
        dialogColumns: [
          {
            field: 'id',
            headerName: 'Labels.ID',
            width: 100,
            editable: false,
            defaultValue: '',
            headerAlign: 'left',
            sortable: false
          },
          {
            field: 'name',
            headerName: 'Labels.Name',
            width: 350,
            editable: false,
            defaultValue: '',
            headerAlign: 'left',
            sortable: false
          }
        ]
      },
      {
        label: 'Labels.PrimaryContact',
        name: 'contact',
        required: false,
        disabled: false,
        dialogTitle: 'Labels.PrimaryContact',
        component: 'tokenValue',
        value: '',
        initQueryState: {
          personTypeId: '',
          firstName: '',
          lastName: '',
          type: IndividualTypes.CONTACT
        },
        fetchDataPath: DIALOG_PATHS.PERSONS,
        dialogColumns: [
          {
            field: 'personTypeId',
            headerName: 'Labels.ID',
            width: 100,
            editable: false,
            defaultValue: '',
            headerAlign: 'left',
            sortable: false
          },
          {
            field: 'firstName',
            headerName: 'ContactsTile.FirstName',
            width: 350,
            editable: false,
            defaultValue: '',
            headerAlign: 'left',
            sortable: false
          },
          {
            field: 'lastName',
            headerName: 'ContactsTile.LastName',
            width: 350,
            editable: false,
            defaultValue: '',
            headerAlign: 'left',
            sortable: false
          }
        ]
      },
      {
        label: 'Labels.Type',
        name: 'type.value',
        required: false,
        disabled: true,
        component: 'text'
      },
      {
        label: 'Labels.Status',
        name: 'status.id',
        required: true,
        disabled: false,
        component: 'select'
      }
    ]
  },
  {
    groupName: 'Labels.Details',
    fields: [
      {
        label: 'Labels.Category',
        name: 'taskDto.taskCategory',
        required: false,
        disabled: false,
        component: 'autocomplete'
      },
      {
        label: 'Labels.Processor',
        name: 'taskDto.processor',
        required: false,
        disabled: false,
        dialogTitle: 'Labels.SearchIndividualPerson',
        component: 'tokenValue',
        value: '',
        initQueryState: { id: '', name: '', type: AccountTypes.INDIVIDUAL_CUSTOMER },
        fetchDataPath: DIALOG_PATHS.PERSONS,
        dialogColumns: [
          {
            field: 'personTypeId',
            headerName: 'Labels.ID',
            width: 100,
            editable: false,
            defaultValue: '',
            headerAlign: 'left',
            sortable: false
          },
          {
            field: 'firstName',
            headerName: 'Labels.FirstName',
            width: 350,
            editable: false,
            defaultValue: '',
            headerAlign: 'left',
            sortable: false
          },
          {
            field: 'lastName',
            headerName: 'Labels.LastName',
            width: 350,
            editable: false,
            defaultValue: '',
            headerAlign: 'left',
            sortable: false
          },
          {
            field: 'companyName',
            headerName: 'Labels.CompanyName',
            width: 350,
            editable: false,
            defaultValue: '',
            headerAlign: 'left',
            sortable: false
          }
        ]
      },
      {
        label: 'Labels.Completion',
        name: 'taskDto.completion',
        required: false,
        disabled: false,
        component: 'text',
        type: 'number'
      }
    ]
  }
];
