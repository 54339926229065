import { isNil } from 'lodash';

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import {
  ACTION_MODES,
  dateInitFormats,
  NAVIGATE_TOASTER_TIMEOUT,
  SORT_DIRECTION
} from '@common/Constants';
import { dateToFormat } from '@common/helpers/dates';
import { deepMerge } from '@common/helpers/deepMerge';
import { copyObjectWithoutRef, isDate } from '@common/helpers/helpers';
import { errorMessageFormatter, successMessageFormatter } from '@common/helpers/MessageFormatter';
import scrubDtos from '@common/helpers/scrubDtos';
import { EMPLOYEES_PATHS } from '@common/network/ApiPaths';
import EntityTypes from '@common/network/EntityTypes';
import { SnackbarSeverityTypes, showSnackbar } from '@components/Snackbar/snackbarSlice';
import { MASTER_LIST_PAGE_SIZE } from '@config/network';
import {
  deleteByPath,
  getByPathAndParams,
  postByPathAndData,
  putByPathAndData
} from '@services/BaseApi';

import { SORT_DATA } from './util/sortConfig';

export const initialState = {
  selectedId: null,
  data: [],
  filter: {
    search: '',
    sortBy: SORT_DATA[0].name,
    sortDirection: SORT_DIRECTION.DESCENDING,
    page: 0,
    size: MASTER_LIST_PAGE_SIZE
  },
  totalPages: 0,
  totalElements: 0,
  details: null,
  isLoading: true,
  isDetailsLoading: false,
  isFilterDataChanged: false
};

export const fetchEmployees = createAsyncThunk('employees/fetchAll', (filter) =>
  getByPathAndParams({
    path: EMPLOYEES_PATHS.GET,
    params: filter
  })
    .then((response) => response.data)
    .catch((error) => error)
);

export const fetchEmployeesDetails = createAsyncThunk(
  'employees/fetchDetails',
  (selectedId) =>
    getByPathAndParams({
      path: EMPLOYEES_PATHS.GET_DETAILS,
      pathVariables: { id: selectedId }
    })
      .then(({ data }) => ({
        ...data,
        costCenter: data.costCenter || '',
        personnelNumber: data.personnelNumber || ''
      }))
      .catch((error) => error),
  {
    condition: (selectedId) => Boolean(selectedId),
    dispatchConditionRejection: true
  }
);

export const createEmployee = createAsyncThunk('employees/create', (postData, thunkAPI) => {
  return postByPathAndData({
    path: EMPLOYEES_PATHS.POST,
    data: {
      ...postData,
      personDto: {
        ...postData.personDto,
        birthDate: isDate(postData.personDto.birthDate)
          ? dateToFormat(postData.personDto.birthDate, dateInitFormats.birthDate)
          : null
      },
      addressDtos: scrubDtos(postData, 'addressDtos'),
      emailDtos: scrubDtos(postData, 'emailDtos'),
      phoneDtos: scrubDtos(postData, 'phoneDtos')
    }
  })
    .then((response) => {
      setTimeout(() => {
        thunkAPI.dispatch(
          showSnackbar({
            message: successMessageFormatter(EntityTypes.EMPLOYEE, ACTION_MODES.Create),
            severity: SnackbarSeverityTypes.SUCCESS
          })
        );
      }, NAVIGATE_TOASTER_TIMEOUT);

      return response.data;
    })
    .catch((error) => {
      return thunkAPI.rejectWithValue(error.response);
    });
});

export const deleteEmployee = createAsyncThunk('employees/delete', (id, thunkAPI) =>
  deleteByPath({
    path: EMPLOYEES_PATHS.DELETE,
    pathVariables: { id }
  })
    .then((response) => {
      setTimeout(() => {
        thunkAPI.dispatch(
          showSnackbar({
            message: successMessageFormatter(EntityTypes.EMPLOYEE, ACTION_MODES.Delete),
            severity: SnackbarSeverityTypes.SUCCESS
          })
        );
      }, NAVIGATE_TOASTER_TIMEOUT);
      return response.data;
    })
    .catch((error) => {
      thunkAPI.dispatch(
        showSnackbar({
          message: errorMessageFormatter(error, EntityTypes.EMPLOYEE, ACTION_MODES.Delete),
          severity: SnackbarSeverityTypes.ERROR
        })
      );

      return error.response;
    })
);

export const saveEmployee = createAsyncThunk('employees/save', (postData, thunkAPI) =>
  putByPathAndData({
    path: EMPLOYEES_PATHS.PUT,
    data: {
      ...postData,
      personDto: {
        ...postData.personDto,
        birthDate: isDate(postData.personDto.birthDate)
          ? dateToFormat(postData.personDto.birthDate, dateInitFormats.birthDate)
          : null
      },
      addressDtos: scrubDtos(postData, 'addressDtos'),
      emailDtos: scrubDtos(postData, 'emailDtos'),
      phoneDtos: scrubDtos(postData, 'phoneDtos')
    },
    pathVariables: { id: postData.id }
  })
    .then((response) => {
      setTimeout(() => {
        thunkAPI.dispatch(
          showSnackbar({
            message: successMessageFormatter(EntityTypes.EMPLOYEE, ACTION_MODES.Edit),
            severity: SnackbarSeverityTypes.SUCCESS
          })
        );
      }, NAVIGATE_TOASTER_TIMEOUT);
      return response.data;
    })
    .catch((error) => thunkAPI.rejectWithValue(error.response))
);

const checkEmptyValues = (details) => {
  let detailsCopy = copyObjectWithoutRef(details);
  if (detailsCopy?.personDto?.title === null) {
    detailsCopy = {
      ...detailsCopy,
      personDto: { ...detailsCopy?.personDto, title: { id: 0 } }
    };
  }
  if (detailsCopy?.personDto?.birthDate === null) {
    detailsCopy = {
      ...detailsCopy,
      personDto: { ...detailsCopy?.personDto, birthDate: '' }
    };
  }
  if (detailsCopy?.personDto?.birthPlace === null) {
    detailsCopy = {
      ...detailsCopy,
      personDto: { ...detailsCopy?.personDto, birthPlace: '' }
    };
  }
  return detailsCopy;
};

export const employeesSlice = createSlice({
  name: 'employees',
  initialState,
  reducers: {
    resetState: () => initialState,
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setIsilterDataChanged: (state, action) => {
      state.isFilterDataChanged = !action.payload;
    },
    setSelectedId: (state, action) => {
      state.selectedId = action.payload;
    },
    setDetails: (state, { payload }) => {
      state.details = deepMerge(state.details, payload);
    },
    deleteSuccess: (state, action) => {
      state.data = [...state.data.filter((item) => item.id !== action.payload.id)];
      state.selectedId = [...state.data.filter((item) => item.id !== action.payload.id)];
    },
    setFilterParams: (state, action) => {
      let newFilterValues = {};

      // Case when search value is reset to empty and search bar is closed
      if (action.payload.key === 'search' && !action.payload.value && !state.filter.search) {
        state.isLoading = false;
        return;
      }

      if (Array.isArray(action.payload)) {
        newFilterValues = action.payload.reduce(
          (obj, item) => ((obj[item.key] = item.value), obj),
          {}
        );
      } else {
        newFilterValues = { [action.payload.key]: action.payload.value };
      }

      state.filter = { ...state.filter, ...newFilterValues, page: 0 };
    }
  },
  extraReducers: (builder) => {
    builder
      // Get all
      .addCase(fetchEmployees.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.data = payload?.content || state.data;
        state.totalPages = payload?.totalPages || state.totalPages;
        state.totalElements = isNil(payload?.totalElements)
          ? state.totalElements
          : payload?.totalElements;
        state.selectedId = payload?.content?.[0]?.id;
        state.filter.page = payload?.pageable?.pageNumber;
        state.filter.size = payload?.pageable?.pageSize;
      })
      .addCase(fetchEmployees.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchEmployees.rejected, (state) => {
        state.data = [];
        state.isLoading = false;
      })
      // Get Details
      .addCase(fetchEmployeesDetails.fulfilled, (state, action) => {
        state.details = checkEmptyValues(action?.payload);
        state.isDetailsLoading = false;
      })
      .addCase(fetchEmployeesDetails.pending, (state) => {
        state.isDetailsLoading = true;
      })
      .addCase(fetchEmployeesDetails.rejected, (state) => {
        state.details = null;
        state.isDetailsLoading = false;
      })
      // Create
      .addCase(createEmployee.fulfilled, (state) => {
        state.filter.page = 0;
      })
      // Update
      .addCase(saveEmployee.fulfilled, (state, action) => {
        state.isDetailsLoading = false;
        state.data = state.data.map((item) =>
          item.id === action.meta.arg.id ? deepMerge(item, action.meta.arg) : item
        );
        //checked is filtered filed changed
        if (state.details?.companyName !== action.meta.arg?.companyName) {
          state.isFilterDataChanged = !state.isFilterDataChanged;
        }
        state.details = deepMerge(state.details, action.meta.arg);
      })
      .addCase(saveEmployee.pending, (state) => {
        state.isDetailsLoading = true;
      })
      .addCase(saveEmployee.rejected, (state) => {
        state.isDetailsLoading = false;
      })
      // Delete
      .addCase(deleteEmployee.fulfilled, (state, action) => {
        state.totalElements -= 1;
        state.isLoading = false;
        state.details = null;
        state.data = state.data.filter((item) => item.id !== action.meta.arg);
        state.selectedId = state.data.filter((item) => item.id !== action.meta.arg)[0]?.id;

        if (state.data.length === 0 && state.totalPages === 1) {
          state.filter.page = 0;
          state.totalPages = 0;
          state.totalElements = 0;
        } else if (state.data.length === 0 && state.totalPages > 1) {
          state.filter.page -= 1;
        }
      })
      .addCase(deleteEmployee.rejected, (state, { payload }) => {
        alert(`${payload.data.message}`);
      });
  }
});

export const selectId = (state) => state.employees.selectedId;
export const selectList = (state) => state.employees.data;
export const selectFilter = (state) => state.employees.filter;
export const selectTotalElements = (state) => state.employees.totalElements;
export const selectTotalPages = (state) => state.employees.totalPages;
export const selectDetails = (state) => state.employees.details;

export const selectIsLoading = (state) => state.employees.isLoading;
export const selectIsDetailsLoading = (state) => state.employees.isDetailsLoading;
export const selectIsFilterChanged = (state) => state.employees.isFilterDataChanged;

const { actions, reducer } = employeesSlice;

export const {
  setError,
  setLoading,
  setSelectedId,
  setConfirmationDialog,
  deleteSuccess,
  setFilterParams,
  resetPage,
  resetState,
  setDetails
} = actions;

export default reducer;
