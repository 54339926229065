import Localize from 'react-intl-universal';

import { default as MuiChip } from '@mui/material/Chip';
import { alpha } from '@mui/material/styles';
import useTheme from '@mui/styles/useTheme';

import percentage from '@common/helpers/numbers/percentage';

const getPropertiesForPercentage = (bookingPercentage, theme) => {
  switch (bookingPercentage) {
    case 100:
      return {
        border: `1px solid ${theme.palette.success.main}`,
        backgroundColor: alpha(theme.palette.success.main, 0.3),
        color: theme.palette.success.main,
        label: Localize.get('Event.Statuses.Completed')
      };
    case 0:
      return {
        border: `1px solid ${theme.palette.error.main}`,
        backgroundColor: alpha(theme.palette.error.main, 0.3),
        color: theme.palette.error.main,
        label: Localize.get('Event.Statuses.Unbooked')
      };
    default:
      return {
        border: `1px solid${theme.palette.warning.main}`,
        backgroundColor: alpha(theme.palette.warning.main, 0.3),
        color: theme.palette.warning.main,
        label: Localize.get('Event.Statuses.InProgress')
      };
  }
};

const Chip = ({ current, total }) => {
  const theme = useTheme();
  const bookingPercentage = percentage(current, total);
  const { color, backgroundColor, label, border } = getPropertiesForPercentage(
    bookingPercentage,
    theme
  );

  return (
    <MuiChip
      size="small"
      sx={{
        width: '110px',
        border: border,
        backgroundColor: backgroundColor,
        color: color
      }}
      label={label}
    />
  );
};

export default Chip;
