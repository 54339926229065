import { DIALOG_PATHS } from '@common/network/ApiPaths';

export default [
  {
    groupName: 'IltSession.IltSessionInformation',
    fields: [
      {
        label: 'IltSession.IltSessionName',
        name: 'sessionName',
        required: true,
        disabled: true,
        component: 'text'
      },
      {
        label: 'IltSession.Course',
        name: 'course',
        required: true,
        disabled: true,
        component: 'text'
      },
      // {
      //   label: 'IltSession.StartDate',
      //   name: 'startDateTime',
      //   required: true,
      //   disabled: true,
      //   component: 'datetimepicker'
      // },
      // {
      //   label: 'IltSession.EndDate',
      //   name: 'endDateTime',
      //   required: true,
      //   disabled: true,
      //   component: 'datetimepicker'
      // },
      {
        label: 'IltSession.IltModule',
        name: 'iltModule',
        required: true,
        disabled: true,
        component: 'text'
      }
    ]
  },
  {
    groupName: 'IltSession.SessionDayInformation',
    fields: [
      {
        label: 'IltSession.IltSessionDayName',
        name: 'name',
        required: true,
        disabled: false,
        component: 'text'
      },
      {
        label: 'IltSession.Date',
        name: 'dayDate',
        required: true,
        disabled: false,
        component: 'datepicker'
      },
      {
        label: 'IltSession.TimeFrom',
        name: 'startTime',
        required: true,
        disabled: false,
        component: 'timepicker'
      },
      {
        label: 'IltSession.TimeTo',
        name: 'endTime',
        required: true,
        disabled: false,
        component: 'timepicker'
      },
      {
        label: 'IltSession.Instructor',
        name: 'instructorIds',
        required: true,
        disabled: false,
        dialogTitle: 'IltSession.SearchInstructor',
        component: 'tokenValueArray',
        fetchDataPath: DIALOG_PATHS.INSTRUCTOR,
        value: '',
        dialogColumns: [
          {
            field: 'id',
            headerName: 'Labels.ID',
            width: 100,
            editable: false,
            defaultValue: '',
            headerAlign: 'left',
            sortable: false
          },
          {
            field: 'firstName',
            headerName: 'Labels.FirstName',
            width: 350,
            editable: false,
            defaultValue: '',
            headerAlign: 'left',
            sortable: false
          },
          {
            field: 'lastName',
            headerName: 'Labels.LastName',
            width: 350,
            editable: false,
            defaultValue: '',
            headerAlign: 'left',
            sortable: false
          }
        ]
      },
      {
        label: 'IltSession.DetailsInformation',
        name: 'details',
        required: false,
        disabled: false,
        component: 'text'
      },
      {
        label: 'IltSession.WebinarURL',
        name: 'webinarUrl',
        required: false,
        disabled: false,
        component: 'text'
      }
    ]
  }
];
