import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

// import { SORT_DIRECTION } from '@common/Constants';
import { LOCATION_PATHS } from '@common/network/ApiPaths';
import { getByPathAndParams } from '@services/BaseApi';

import {
  HOTEL_AND_VENUE_TYPES,
  SUPPLIER_HOTEL_AND_VENUE_TYPES,
  VENUE_TYPES
} from '../LocationsTabs';
// import { SORT_DATA_PARTICIPANTS } from '../../util/sortConfig';
export const PAGE_SIZE = 5;
export const PAGE_SIZE_OPTIONS = [5, 10, 15];
export const initialState = {
  data: [],
  rows: [],
  filter: {
    search: '',
    // WAITING FOR THE BE
    // sortBy: SORT_DATA_CONTACTS[0].name,
    // sortDirection: SORT_DIRECTION.ASCENDING,
    page: 0,
    size: PAGE_SIZE
  },
  totalPages: 0,
  totalElements: 0,
  isLoading: false,
  selectionModel: []
};

export const getVenueId = (roles = []) => {
  if (!roles.length) {
    return false;
  }

  const isHotel = roles[0]?.supplierType?.id === SUPPLIER_HOTEL_AND_VENUE_TYPES;
  if (isHotel) {
    return false;
  }
  const supplierType = roles[0].supplierType?.id;
  const venueId = roles[0]?.venue?.id;

  if (supplierType === VENUE_TYPES || supplierType === HOTEL_AND_VENUE_TYPES) {
    return venueId;
  }

  return false;
};

export const fetchMeetingRooms = createAsyncThunk(
  'locationMeetingRooms/meeting-rooms',
  (filterObj, { rejectWithValue }) => {
    return getByPathAndParams({
      path: LOCATION_PATHS.GET_MEETING_ROOM,
      pathVariables: { id: filterObj.entityId },
      params: filterObj.filter
    })
      .then(({ data }) => data)
      .catch((error) => rejectWithValue(error));
  }
);

export const locationMeetingRoomTableSlice = createSlice({
  name: 'locationMeetingRooms',
  initialState,
  reducers: {
    resetState: () => initialState,
    setData: (state, { payload }) => {
      state.data = payload;
    },
    setSelectionModel: (state, { payload }) => {
      if (Array.isArray(payload)) {
        state.selectionModel = [...payload];
      } else {
        const index = state.selectionModel.findIndex((s) => s === payload);
        if (index === -1) {
          state.selectionModel = [...state.selectionModel, payload];
        } else {
          state.selectionModel = [
            ...state.selectionModel.slice(0, index),
            ...state.selectionModel.slice(index + 1)
          ];
        }
      }
    },
    setFilterParams: (state, action) => {
      let newFilterValues = {};
      // Case when search value is reset to empty and search bar is closed
      if (action.payload.key === 'search' && !action.payload.value && !state.filter.search) {
        state.isLoading = false;
        return;
      }
      if (Array.isArray(action.payload)) {
        newFilterValues = action.payload.reduce(
          (obj, item) => ((obj[item.key] = item.value), obj),
          {}
        );
      } else {
        newFilterValues = { [action.payload.key]: action.payload.value };
      }
      state.filter = { ...state.filter, ...newFilterValues, page: action.payload.page ?? 0 };
    }
  },
  extraReducers: (builder) => {
    builder
      // Get all
      .addCase(fetchMeetingRooms.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.totalPages = payload?.totalPages || state.totalPages;
        state.totalElements = payload?.totalElements || state.totalElements;
        state.data = payload.content;
      })
      .addCase(fetchMeetingRooms.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchMeetingRooms.rejected, (state) => {
        state.data = [];
        state.isLoading = false;
      });
  }
});

export const selectList = (state) => state.locationMeetingRooms.data;
export const selectRows = (state) => state.locationMeetingRooms.rows;
export const selectTotalElements = (state) => state.locationMeetingRooms.totalElements;
export const selectTotalPages = (state) => state.locationMeetingRooms.totalPages;
export const selectFilter = (state) => state.locationMeetingRooms.filter;
export const selectIsLoading = (state) => state.locationMeetingRooms.isLoading;
export const selectSelectionModel = (state) => state.locationMeetingRooms.selectionModel;

const { actions, reducer } = locationMeetingRoomTableSlice;

export const { setData, setFilterParams, resetState, setSelectionModel } = actions;

export default reducer;
