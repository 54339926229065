import React from 'react';
import Localize from 'react-intl-universal';

import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';

import IndividualTypes from '@common/network/IndividualTypes';
import { FilterLabel, FilterSelect } from '@components/TokenValueModalTrigger/components/styled';

const ContactTypeDropdown = ({ queryState, itemHandler }) => (
  <Box>
    <FormControl>
      <FilterLabel id="demo-simple-select-label">{Localize.get('Labels.Type')}</FilterLabel>
      <FilterSelect
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={queryState.type}
        label={Localize.get('Labels.Type')}
        onChange={(event) => {
          itemHandler({ items: [{ columnField: 'type', value: event.target.value }] });
        }}
      >
        <MenuItem data-test-id={IndividualTypes.CONTACT} value={IndividualTypes.CONTACT}>
          {Localize.get('ContactsTile.Contact')}
        </MenuItem>
        <MenuItem data-test-id={IndividualTypes.EMPLOYEE} value={IndividualTypes.EMPLOYEE}>
          {Localize.get('Labels.Employee')}
        </MenuItem>
        <MenuItem
          data-test-id={IndividualTypes.INDIVIDUAL_CUSTOMER}
          value={IndividualTypes.INDIVIDUAL_CUSTOMER}
        >
          {Localize.get('Labels.IndividualCustomer')}
        </MenuItem>
      </FilterSelect>
    </FormControl>
  </Box>
);

export default ContactTypeDropdown;
