import Localize from 'react-intl-universal';

import * as yup from 'yup';

import { DATE_PICKER_MIN_BIRTH_DATE } from '@common/Constants';
import { addresses, phone, email } from '@common/yup';

export default () => {
  return yup.object().shape({
    personDto: yup.object().shape({
      firstName: yup.string().required(Localize.get('ValidationMessages.FirstNameRequired')),
      lastName: yup.string().required(Localize.get('ValidationMessages.LastNameRequired')),
      birthDate: yup
        .date()
        .nullable(true)
        .typeError(Localize.get('ValidationMessages.DateInvalid'))
        .max(new Date(), Localize.get('ValidationMessages.BirthDateInvalid'))
        .min(DATE_PICKER_MIN_BIRTH_DATE, Localize.get('ValidationMessages.BirthDateInvalid'))
    }),
    companyName: yup.string().required(Localize.get('ValidationMessages.CompanyNameRequired')),
    job: yup.string().required(Localize.get('ValidationMessages.JobRequired')),
    phoneDtos: phone(),
    emailDtos: email(),
    addressDtos: addresses()
  });
};
