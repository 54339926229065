import React from 'react';
import Localize from 'react-intl-universal';
import { useMatch } from 'react-location';
import { useSelector } from 'react-redux';

import { PERSON_TYPES, dateInitFormats } from '@common/Constants';
import { calculateDayDifference, dateToFormat } from '@common/helpers/dates';
import { PARTICIPANTS_PATHS, PERSONS_PATHS } from '@common/network/ApiPaths';
import ContactTypeDropdown from '@components/ContactTypeDropdown';
import RightSidebar from '@components/RightSidebar';
import { selectOccupancy, setOccupancy } from '@pages/Participants/participantSlice';
import dialogFieldsHotelSearch from '@pages/Participants/utils/fields/dialogFieldsHotelSearch';
import sidebar from '@pages/Participants/utils/fields/sidebar';
import initialValues from '@pages/Participants/utils/initialValues';
import editParticipantSchema from '@pages/Participants/utils/schemas/editParticipantSchema';

import { mapPropsToAddress } from '../CreateParticipant/getFieldsConfig';

const Sidebar = ({
  details = null,
  isDetailsLoading = false,
  onSave = () => {},
  onDelete = () => {}
}) => {
  const {
    data: {
      participantData: {
        data: { allergy, language }
      },
      phoneTypes: { data: phoneTypes },
      emailTypes: { data: emailTypes },
      addressTypes: { data: addressTypes },
      participantStatuses: { data: statuses }
    }
  } = useMatch();

  const occupancy = useSelector(selectOccupancy);

  const extendedConfig = (setFieldValue, values, setValues) => {
    const isPersonDtoDisabled =
      values?.personDto?.relatedPersonEntityDto?.personType === PERSON_TYPES.PARTICIPANT;

    return {
      ['company']: {
        tokenValue: values?.company?.name ? [{ title: values.company?.name }] : undefined,
        onIconClick: (e) => {
          if (e?.target?.name === 'delete') {
            setFieldValue('company', initialValues.company);
          }
        },
        selectItem: (value) => {
          setFieldValue('company', {
            ...value
          });
          setFieldValue('company.id', value.organizationPersonId);
        }
      },
      ['company.id']: {
        tokenValue: values?.company?.name ? [{ title: values.company?.name }] : undefined,
        onIconClick: (e) => {
          if (e?.target?.name === 'delete') {
            setFieldValue('company', initialValues.company);
          }
        },
        selectItem: (value) => {
          setFieldValue('company', {
            ...value
          });
          setFieldValue('company.id', value.organizationPersonId);
        }
      },
      ['invoiceCompany']: {
        tokenValue: values?.invoiceCompany?.name
          ? [{ title: values.invoiceCompany?.name }]
          : undefined,
        onIconClick: (e) => {
          if (e?.target?.name === 'delete') {
            setFieldValue('invoiceCompany', initialValues.invoiceCompany);
          }
        },
        selectItem: (value) => {
          setFieldValue('invoiceCompany', {
            ...value
          });
          setFieldValue('invoiceCompany.id', value.organizationPersonId);
        }
      },
      ['nativeLanguage.id']: {
        options: language
      },
      ['nativeLanguage']: {
        options: language,
        formItemChangeHandler: (e, { newValue = {} }) => {
          setValues({
            ...values,
            nativeLanguage: newValue
          });
        }
      },
      ['allergies']: {
        options: allergy,
        // disabled: !values?.hasAllergies,
        onChange: (e, newValue) => setFieldValue('allergies', newValue)
      },
      ['event.name']: {
        tokenValue: values?.event?.name ? [{ title: values.event?.name }] : undefined,
        onIconClick: (e) => {
          if (e?.target?.name === 'delete') {
            setFieldValue('event', null);
          }
        },
        selectItem: (value) => {
          setFieldValue('event', {
            ...value
          });
        },
        selectionModel: values?.event?.id
      },
      ['personDto.name']: {
        tokenValue:
          values?.personDto?.relatedPersonEntityDto?.personType === PERSON_TYPES.PARTICIPANT
            ? []
            : values?.personDto?.name
            ? [{ title: values?.personDto?.name }]
            : values?.personDto?.firstName && values?.personDto?.lastName
            ? [{ title: `${values?.personDto?.firstName} ${values?.personDto?.lastName}` }]
            : null,
        onIconClick: (e) => {
          if (e?.target?.name === 'delete') {
            setValues({
              ...values,
              personDto: {
                ...initialValues.personDto,
                birthDate: dateToFormat(null, dateInitFormats.birthDate)
              },
              company: initialValues.company,
              addressDtos: initialValues.addressDtos,
              emailDtos: initialValues.emailDtos,
              phoneDtos: initialValues.phoneDtos
            });
          }
        },
        selectItem: (value) => {
          setValues({
            ...values,
            personDto: {
              ...values.personDto,
              firstName: value?.firstName,
              lastName: value?.lastName,
              name: `${value.firstName} ${value.lastName}`,
              birthDate: value?.birthDate || null,
              birthPlace: value?.birthPlace || '',
              id: value?.id || null,
              title: value?.title || initialValues.personDto.title
            },
            company: value?.companyName,
            emailDtos: value?.emailDtos,
            addressDtos: value?.addressDtos,
            phoneDtos: value?.phoneDtos
          });
        },
        required:
          values?.personDto?.relatedPersonEntityDto?.personType !== PERSON_TYPES.PARTICIPANT,
        selectionModel: values?.personDto?.id,
        extendedComponents: ContactTypeDropdown,
        disabled: isPersonDtoDisabled
      },
      ['personDto.firstName']: {
        disabled: !isPersonDtoDisabled
      },
      ['personDto.lastName']: {
        disabled: !isPersonDtoDisabled
      },
      ['personDto.title.id']: { disabled: !isPersonDtoDisabled },
      ['personDto.birthDate']: {
        disabled: !isPersonDtoDisabled,
        maxDate: new Date(),
        onChange: (value) =>
          setFieldValue('cancellationDeadlineFrom', dateToFormat(value, dateInitFormats.dateTime))
      },
      ['personDto.birthPlace']: {
        disabled: !isPersonDtoDisabled
      },
      ['hotelReservation.id.hotel.id']: {
        dialogColumns: dialogFieldsHotelSearch,
        tokenValue: values?.hotelReservation?.id?.hotel?.name
          ? [{ title: values.hotelReservation.id.hotel.name }]
          : undefined,
        selectionModel: values?.hotelReservation?.id?.hotel?.id,
        selectItem: (value) => {
          setValues({
            ...values,
            hotelReservation: {
              ...values.hotelReservation,
              id: {
                ...values.hotelReservation.id,
                hotel: { id: value.id, name: value.name }
              },
              roomType: initialValues.hotelReservation.roomType
            }
          });
        },
        onIconClick: (e) => {
          if (e?.target?.name === 'delete') {
            setValues({
              ...values,
              hotelReservation: initialValues.hotelReservation
            });
          }
        }
      },
      ['hotelReservation.roomType']: {
        disabled: !values?.hotelReservation?.id?.hotel?.id,
        required: Boolean(values?.hotelReservation?.id?.hotel?.id),
        responseFormater: ({ content = [] }) => {
          return content;
        },
        getOptionLabel: (option) => {
          return option?.type?.value ? option.type.value : option.value;
        },
        isOptionEqualToValue: (option, value) => {
          return option?.type?.id === value?.id;
        },
        formItemChangeHandler: (e, { newValue = {} }) => {
          let type = newValue?.type ?? null;
          let occupancy = newValue?.occupancy ?? 0;
          setValues({
            ...values,
            hotelReservation: {
              ...values.hotelReservation,
              roomType: type,
              persons:
                values.hotelReservation.persons > occupancy
                  ? occupancy
                  : values.hotelReservation.persons
            }
          });
          setOccupancy(occupancy);
        },
        path: PARTICIPANTS_PATHS.HOTELS_ACCOMMODATIONS,
        pathVariables: { id: values?.hotelReservation?.id?.hotel?.id },
        pathVariablesRequired: ['id']
      },
      ['hotelReservation.persons']: {
        disabled: !values?.hotelReservation?.id?.hotel?.id,
        required: Boolean(values?.hotelReservation?.id?.hotel?.id),
        InputProps: { inputProps: { min: 0, max: occupancy } }
      },
      ['hotelReservation.arrivalDate']: {
        disabled: !values?.hotelReservation?.id?.hotel?.id,
        required: Boolean(values?.hotelReservation?.id?.hotel?.id),
        emptyLabel: Localize.get('Hotel.ArrivalDate'),
        onChange: (newValue) =>
          setValues({
            ...values,
            hotelReservation: {
              ...values.hotelReservation,
              arrivalDate: dateToFormat(newValue, dateInitFormats.arrivalDate),
              nights: calculateDayDifference(newValue, values.hotelReservation.departureDate)
            }
          }),
        value: values?.hotelReservation?.arrivalDate
      },
      ['hotelReservation.departureDate']: {
        disabled: !values?.hotelReservation?.id?.hotel?.id,
        required: Boolean(values?.hotelReservation?.id?.hotel?.id),
        emptyLabel: Localize.get('Hotel.DepartureDate'),
        onChange: (newValue) =>
          setValues({
            ...values,
            hotelReservation: {
              ...values.hotelReservation,
              departureDate: dateToFormat(newValue, dateInitFormats.departureDate),
              nights: calculateDayDifference(values.hotelReservation.arrivalDate, newValue)
            }
          }),
        value: values?.hotelReservation?.departureDate
      },
      ['hotelReservation.shuttle']: {
        disabled: !values?.hotelReservation?.id?.hotel?.id,
        checked: values?.hotelReservation?.shuttle
      },
      ['status.id']: {
        options: statuses,
        onChange: (event) => {
          setFieldValue('status', {
            id: event.target.value,
            value: statuses.find((status) => status.id === event.target.value).value
          });
        }
      },
      ['cancellationReason']: {
        path: PARTICIPANTS_PATHS.PARTICIPANTS_CANCELLATION_REASON,
        responseFormater: (data) => data.filter((el) => el.value !== ''),
        formItemChangeHandler: (e, { newValue = {} }) => {
          setValues({
            ...values,
            cancellationReason: newValue
          });
        }
      },
      ['result']: {
        path: PARTICIPANTS_PATHS.PARTICIPANT_RESULTS,
        responseFormater: (data) => data.filter((el) => el.value !== ''),
        formItemChangeHandler: (e, { newValue = {} }) => {
          setValues({
            ...values,
            result: newValue
          });
        }
      },
      ['personDto.gender']: {
        path: PERSONS_PATHS.GENDERS,
        formItemChangeHandler: (e, { newValue = {} }) => {
          setValues({
            ...values,
            personDto: { ...values.personDto, gender: newValue }
          });
        }
      },
      ['emailDtos']: {
        arrayConfig: { actions: { areButtonsHidden: !isPersonDtoDisabled } },
        ['type.id']: {
          options: emailTypes,
          disabled: !isPersonDtoDisabled
        },
        ['email']: {
          disabled: !isPersonDtoDisabled
        }
      },
      ['phoneDtos']: {
        arrayConfig: { actions: { areButtonsHidden: !isPersonDtoDisabled } },
        ['type.id']: {
          options: phoneTypes,
          disabled: !isPersonDtoDisabled
        },
        ['phone']: {
          disabled: !isPersonDtoDisabled
        }
      },
      ['addressDtos']: {
        arrayConfig: { actions: { areButtonsHidden: !isPersonDtoDisabled } },
        ['type.id']: {
          options: addressTypes,
          disabled: !isPersonDtoDisabled
        },
        ['street']: { disabled: !isPersonDtoDisabled },
        ['number']: { disabled: !isPersonDtoDisabled },
        ['postalCode']: { disabled: !isPersonDtoDisabled },
        ['city']: { disabled: !isPersonDtoDisabled },
        ['poBox']: { disabled: !isPersonDtoDisabled },
        ['state']: { disabled: !isPersonDtoDisabled },
        ['country']: { disabled: !isPersonDtoDisabled },
        ['region']: { disabled: !isPersonDtoDisabled }
      }
    };
  };

  return (
    <RightSidebar
      config={{ title: Localize.get('ParticipantsTile.Details'), fieldsConfig: sidebar }}
      extendedConfig={extendedConfig}
      data={details}
      validationSchema={editParticipantSchema}
      isDetailsLoading={isDetailsLoading}
      onSave={onSave}
      onDelete={onDelete}
      mapPropsToAddress={mapPropsToAddress}
    />
  );
};

export default Sidebar;
