import React from 'react';
import Localize from 'react-intl-universal';

import RightSidebar from '@components/RightSidebar';
import useSessionDaySidebarSchema from '@pages/IltSession/util/schemas/useSessionDaySidebarSchema';

import sidebarSessionDayClassroom from '../util/fields/sidebarSessionDayClassroom';
import sidebarSessionDayWebinar from '../util/fields/sidebarSessionDayWebinar';

const Sidebar = ({ isDetailsLoading = false, onSave = () => {}, onDelete = () => {}, data }) => {
  const sidebarSchema = useSessionDaySidebarSchema();

  const extendedConfig = (setFieldValue, values) => {
    return {
      ['instructors']: {
        tokenValue:
          values?.instructors.map((instructors) => ({
            title: `${instructors.firstName}`,
            id: instructors.id
          })) ?? [],
        onIconClick: (id) => {
          setFieldValue(
            'instructors',
            values.instructors.filter((item) => item.id !== id)
          );
        },
        selectItem: (value = []) => {
          if (!value?.length) {
            setFieldValue('instructors', []);
            return;
          }

          setFieldValue('instructors', value);
        },
        selectionModel: values.instructors
      },
      ['course']: {
        tokenValue: values?.dayScheduleDto?.iltSessionDto?.course?.name
          ? [
              {
                title: `${values.dayScheduleDto.iltSessionDto.course.name} `
              }
            ]
          : undefined
      },
      ['module']: {
        tokenValue: values?.dayScheduleDto?.iltSessionDto?.module?.name
          ? [
              {
                title: `${values?.dayScheduleDto.iltSessionDto.module?.name}`
              }
            ]
          : undefined
      },
      ['room']: {
        tokenValue: values?.room
          ? [
              {
                title: `${values.room} `
              }
            ]
          : undefined
      }
    };
  };

  return (
    <RightSidebar
      config={{
        title: Localize.get('Sessions.Details'),
        fieldsConfig:
          data?.dayScheduleDto?.iltSessionDto?.type?.id === 16
            ? sidebarSessionDayClassroom
            : sidebarSessionDayWebinar
      }}
      validationSchema={sidebarSchema}
      extendedConfig={extendedConfig}
      data={data}
      isDetailsLoading={isDetailsLoading}
      onSave={onSave}
      onDelete={onDelete}
      disableDelete={true}
    />
  );
};

export default Sidebar;
