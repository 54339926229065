import React, { useEffect } from 'react';
// import Localize from 'react-intl-universal';
import { useMatch } from 'react-location';
import { useSelector, useDispatch } from 'react-redux';

import EntityTypes from '@common/network/EntityTypes';
import LayoutContainer, { LeftContainer, RightContainer } from '@components/LayoutContainer';
import { selectIsOpen } from '@components/RightSidebar/rightSidebarSlice';
import {
  saveHotels,
  selectHotelDetails,
  selectIsDetailsLoading,
  setHotelDetails
} from '@pages/IltSession/components/Tabs/Hotel/hotelTableSlice';

import HotelDetails from './Details/HotelsDetails';
import Sidebar from './Sidebar';
import HotelTabs from './Tabs/HotelTabs';

const Hotels = () => {
  const dispatch = useDispatch();
  const {
    data: {
      session: { data: session },
      hotelDetails: { data: hotelDetails }
    }
  } = useMatch();

  const isOpen = useSelector(selectIsOpen);
  const details = useSelector(selectHotelDetails);
  const isDetailsLoading = useSelector(selectIsDetailsLoading);

  // Set session details to store (TODO: Figure out how to do this from route resolver)
  useEffect(() => dispatch(setHotelDetails(hotelDetails)), [hotelDetails?.id]);

  const onSave = (values) => {
    return dispatch(saveHotels({ ...values, iltSessionId: session.id, id: details.id }))
      .unwrap()
      .then(() => {
        return Promise.resolve();
      })
      .catch((rejectedValueOrSerializedError) => {
        return Promise.reject({
          rejectedValueOrSerializedError,
          entityType: EntityTypes.HOTEL
        });
      });
  };

  // const onDelete = () => {
  //   dispatch(
  //     openConfirmDialog({
  //       title: Localize.get('ConfirmationMessages.Delete', {
  //         item: Localize.get('Events.Item')?.toLowerCase()
  //       }),
  //       confirmButton: Localize.get('Buttons.Delete'),
  //       cancelButton: Localize.get('Buttons.Cancel')
  //     })
  //   )
  //     .unwrap()
  //     .then((result) => {
  //       if (result === CONFIRM_ACTIONS.Cancel) {
  //         return;
  //       }
  //       dispatch(deleteSessionDay(details?.id))
  //         .unwrap()
  //         .then(() => dispatch(setMode('read')))
  //         .catch((rejectedValueOrSerializedError) => console.error(rejectedValueOrSerializedError));
  //     });
  // };

  return (
    <LayoutContainer>
      <LeftContainer isFullScreen></LeftContainer>

      <RightContainer open={isOpen} isFullScreen>
        <HotelDetails entityId={details?.id} headerTitle={details?.name}>
          <HotelTabs
            entityId={details?.id}
            entityType={EntityTypes.SESSION}
            details={details}
            setDetails={setHotelDetails}
          />
        </HotelDetails>
      </RightContainer>

      <Sidebar
        data={details}
        isDetailsLoading={isDetailsLoading}
        onSave={onSave}
        // onDelete={onDelete}
      />
    </LayoutContainer>
  );
};

export default Hotels;
