const email = {
  email: '',
  type: {
    id: 1
  }
};

const phone = { phone: '', type: { id: 1 } };

const address = {
  type: {
    id: 1
  },
  street: '',
  number: '',
  postalCode: '',
  city: '',
  poBox: '',
  state: '',
  region: null,
  country: null
};

export { email, phone, address };
