import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { SEARCH_INPUT_DELAY } from '@config/inputs';
import useDebounce from '@hooks/handlers/useDebounce';
import {
  selectFilter,
  selectTotalElements,
  selectTotalPages,
  selectIsLoading,
  selectList,
  fetchCourses,
  resetState
} from '@pages/Courses/coursesSlice';

import { CoursesTable } from './components';

// import { SORT_DATA } from './util/sortConfig';

const Courses = () => {
  // TODO:
  // const {
  //   data: {
  //     config: { sorts, tabs }
  //   }
  // } = useMatch();

  // const [isOpenDialogSort, setIsOpenDialogSort] = useState(false);
  const dispatch = useDispatch();
  const data = useSelector(selectList);
  const totalElements = useSelector(selectTotalElements);
  const totalPages = useSelector(selectTotalPages);
  const filter = useSelector(selectFilter);
  const isLoading = useSelector(selectIsLoading);

  // Get List of entities on initialization and sort change
  useEffect(() => dispatch(fetchCourses(filter)), [filter.page, filter.size]);

  // Get List of entities on search change
  useDebounce(() => dispatch(fetchCourses({ ...filter, page: 0, size: 15 })), SEARCH_INPUT_DELAY, [
    filter.search
  ]);

  useEffect(() => {
    return () => dispatch(resetState());
  }, []);

  return (
    <CoursesTable
      totalElements={totalElements}
      totalPages={totalPages}
      isLoading={isLoading}
      data={data}
    ></CoursesTable>
  );
};

export default Courses;
