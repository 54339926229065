import React from 'react';
import Localize from 'react-intl-universal';
import { useSelector } from 'react-redux';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';

import Skeleton from '@components/CustomSkeleton/CustomSkeleton';
import NoData from '@components/NoData/NoData';
import TabContainer from '@components/TabContainer/TabContainer';
import TabContent from '@components/TabContent/TabContent';

import { selectHotelDetails, selectIsDetailsLoading } from '../../Tabs/Hotel/hotelTableSlice';
import HotelBookingCard from './HotelBookingCard/HotelBookingCard';
import HotelInformation from './HotelInformation';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  toolbarContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(1),
    alignItems: 'center',
    minHeight: '3rem'
  },
  tableRow: {
    '&:hover': {
      backgroundColor: `${theme.palette.grey[400]} !important`
    }
  }
}));

const HotelsOverview = () => {
  const details = useSelector(selectHotelDetails);
  const isDetailsLoading = useSelector(selectIsDetailsLoading);
  const classes = useStyles({ width: '400px' });

  return (
    <div className={classes.root}>
      <TabContent>
        <div className={classes.toolbarContainer}>
          <Typography variant="h6" component="h6" color="primary">
            {Localize.get('Labels.Overview')}
          </Typography>
        </div>
        <TabContainer>
          <Box sx={{ height: '400px', width: '800px' }}>
            {isDetailsLoading ? (
              <Skeleton
                skeletonCount={6}
                skeletonProps={{
                  variant: 'rectangular',
                  height: '40px',
                  sx: {
                    mb: 1
                  }
                }}
              />
            ) : details ? (
              <Box display="flex" flexDirection="row">
                <Paper sx={{ mr: 2 }}>
                  <HotelInformation data={details} />
                </Paper>
                <Paper sx={{ mr: 2 }}>
                  <HotelBookingCard />
                </Paper>
              </Box>
            ) : (
              <NoData titleText={Localize.get('NoDataMessage.TitleTextOverview')} />
            )}
          </Box>
        </TabContainer>
      </TabContent>
    </div>
  );
};

export default HotelsOverview;
