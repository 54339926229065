import { copyObjectWithoutRef } from '@common/helpers/helpers';
import mapPropsToComponent from '@common/helpers/mapPropsToComponent';
import { ADDRESS_PATH, BUSINESS_PARTNERS_PATHS, LOCALES_PATHS } from '@common/network/ApiPaths';
import { customer } from '@pages/BusinessPartners/util/fields';

export const mapPropsToAddress = (setFieldValue, arrayItemIndex, arrayItem, values) => {
  return {
    ['region']: {
      formItemChangeHandler: (e, data) => {
        setFieldValue(`organizationPerson.addressDtos[${arrayItemIndex}].region`, data?.newValue);
      },
      path: ADDRESS_PATH.GET_REGION_BY_ID,
      pathVariables: { id: arrayItem?.country?.id },
      pathVariablesRequired: ['id'],
      disabled: !values.organizationPerson.addressDtos[arrayItemIndex]?.country
    },
    ['country']: {
      path: LOCALES_PATHS.COUNTRIES,
      formItemChangeHandler: (e, data) => {
        const addressDtos = copyObjectWithoutRef(values.organizationPerson.addressDtos);
        addressDtos[arrayItemIndex].country = data.newValue;
        addressDtos[arrayItemIndex].region = null;
        setFieldValue('organizationPerson.addressDtos', addressDtos);
      }
    }
  };
};

export const getCustomerConfig = (
  values,
  setFieldValue,
  setValues,
  supplierTypes,
  roleTypes,
  phoneTypes,
  emailTypes,
  addressTypes
) => {
  return mapPropsToComponent(customer, {
    ['roles']: {
      options:
        values.businessPartnerType === 2
          ? supplierTypes
          : roleTypes.filter((option) => option.id !== 2),
      disabled: values.businessPartnerType === 2
    },
    ['organizationPerson.person.birthDate']: {
      maxDate: new Date(),
      onChange: (newValue) =>
        setFieldValue('organizationPerson.person.birthDate', newValue || null),
      value: values?.organizationPerson?.person?.birthDate,
      disableFuture: true
    },
    ['abcClassification']: {
      formItemChangeHandler: (e, data) => {
        setFieldValue('abcClassification', data.newValue);
      },
      path: BUSINESS_PARTNERS_PATHS.ABC_CLASSIFICATION
    },
    ['owner']: {
      tokenValue:
        values?.owner?.firstName && values?.owner?.lastName
          ? [
              {
                title: `${values.owner.firstName} ${values.owner.lastName}`
              }
            ]
          : undefined,
      onIconClick: (e) => {
        if (e?.target?.name === 'delete') {
          setFieldValue('owner', null);
        }
      },
      selectItem: (value) => {
        setFieldValue('owner', { ...value, id: value?.personTypeId });
      }
    },
    ['organizationPerson.phoneDtos']: {
      ['type.id']: {
        options: phoneTypes
      }
    },
    ['organizationPerson.emailDtos']: {
      ['type.id']: {
        options: emailTypes
      }
    },
    ['organizationPerson.addressDtos']: {
      ['type.id']: {
        options: addressTypes
      }
    }
  });
};

export default getCustomerConfig;
