import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isOpen: false,
  mode: 'read', // read, edit
  isEditDisabled: false
};

export const rightSidebarSlice = createSlice({
  name: 'rightSidebar',
  initialState,
  reducers: {
    resetState: () => initialState,
    openSidebar: (state) => {
      state.isOpen = true;
    },
    closeSidebar: (state) => {
      state.isOpen = false;
    },
    setMode: (state, action) => {
      state.mode = action.payload;
    },
    setIsEditDisabled: (state, action) => {
      state.isEditDisabled = action.payload;
    }
  }
});

export const selectIsOpen = (state) => state.rightSidebar.isOpen;
export const selectMode = (state) => state.rightSidebar.mode;
export const selectIsEditDisabled = (state) => state.rightSidebar.isEditDisabled;

export const { openSidebar, closeSidebar, setMode, setIsEditDisabled, resetState } =
  rightSidebarSlice.actions;
export default rightSidebarSlice.reducer;
