import moment from 'moment';

import { dateInitFormats } from '@common/Constants';
import mapPropsToComponent from '@common/helpers/mapPropsToComponent';
import createSession from '@pages/Events/util/fields/createSession';

export const getFieldsConfig = (
  setFieldValue,
  setFieldTouched,
  values,
  setValues,
  event,
  sessionTypes
) => {
  const getMinEndTime = () => {
    const { dayScheduleDtos } = event;
    const day = dayScheduleDtos.find(
      (obj) => obj.dayDate === moment(values.timeBlockDate).format(dateInitFormats.basicDate)
    );

    if (day) {
      return moment(`${day.dayDate} ${day.startTime}`, dateInitFormats.dateTime).toDate();
    }

    return moment(new Date(), dateInitFormats.dateTime).toDate();
  };

  const getMinStartTime = () => {
    const { dayScheduleDtos } = event;
    const day = dayScheduleDtos.find(
      (obj) => obj.dayDate === moment(values.timeBlockDate).format(dateInitFormats.basicDate)
    );

    if (day) {
      return moment(`${day.dayDate} ${day.startTime}`, dateInitFormats.dateTime).toDate();
    }

    return moment(new Date(), dateInitFormats.dateTime).toDate();
  };

  const getMaxTime = () => {
    const { dayScheduleDtos } = event;
    const day = dayScheduleDtos.find(
      (obj) => obj.dayDate === moment(values.timeBlockDate).format(dateInitFormats.basicDate)
    );

    if (day) {
      return moment(`${day.dayDate} ${day.endTime}`, dateInitFormats.dateTime).toDate();
    }

    return moment(new Date(), dateInitFormats.dateTime).toDate();
  };

  return mapPropsToComponent(createSession, {
    ['typeId']: {
      options: sessionTypes
    },
    ['timeBlockDate']: {
      ampm: false,
      minDate: new Date(event.startDateTime),
      maxDate: new Date(event.endDateTime),
      onChange: (value) => {
        const { dayScheduleDtos } = event;
        const day = dayScheduleDtos.find(
          (obj) => obj.dayDate === moment(value).format(dateInitFormats.basicDate)
        );

        if (day) {
          const startTime = day.startTime.split(':');
          const endTime = day.endTime.split(':');

          setValues({
            ...values,
            dayScheduleId: day.id,
            timeBlockDate: value,
            startTime: new Date(0, 0, 0, startTime[0], startTime[1]),
            endTime: new Date(0, 0, 0, endTime[0], endTime[1])
          });
        }
      }
    },
    ['endTime']: {
      ampm: false,
      minTime: getMinEndTime(),
      maxTime: getMaxTime(),
      onChange: (value) => {
        if (!value || !(value instanceof Date)) {
          setFieldValue('endTime', null, true);
          setFieldTouched('endTime', true);
          return;
        }

        const hour = new Date(value).getHours();
        const minutes = new Date(value).getMinutes();

        setFieldValue('endTime', new Date(0, 0, 0, hour, minutes), true);
        setFieldTouched('endTime', true);
      }
    },
    ['startTime']: {
      ampm: false,
      minTime: getMinStartTime(),
      maxTime: getMaxTime(),
      onChange: (value) => {
        if (!value || !(value instanceof Date)) {
          setFieldValue('startTime', null, true);
          setFieldTouched('startTime', true);
          return;
        }
        const hour = new Date(value).getHours();
        const minutes = new Date(value).getMinutes();

        setFieldValue('startTime', new Date(0, 0, 0, hour, minutes), true);
        setFieldTouched('startTime', true);
      }
    }
  });
};

export default getFieldsConfig;
