import React from 'react';
import Localize from 'react-intl-universal';
import { useMatch } from 'react-location';

import moment from 'moment';

import { dateInitFormats } from '@common/Constants';
import { SESSION_PATHS } from '@common/network/ApiPaths';
import RightSidebar from '@components/RightSidebar';
import sidebarSession from '@pages/Events/util/fields/sidebarSession';
import useSessionSidebarSchema from '@pages/Events/util/schemas/useSessionSidebarSchema';

const Sidebar = ({ isDetailsLoading = false, onSave = () => {}, onDelete = () => {}, data }) => {
  const sidebarSchema = useSessionSidebarSchema();

  const {
    data: {
      event: { data: event }
    }
  } = useMatch();

  const extendedConfig = (setFieldValue, values, setValues, setFieldTouched) => {
    const getMinEndTime = () => {
      const { dayScheduleDtos } = event;
      const day = dayScheduleDtos?.find(
        (obj) => obj.dayDate === moment(values.timeBlockDate).format(dateInitFormats.basicDate)
      );

      if (day) {
        return moment(`${day.dayDate} ${day.startTime}`, dateInitFormats.dateTime).toDate();
      }
      return moment(new Date(), dateInitFormats.dateTime).toDate();
    };

    const getMinStartTime = () => {
      const { dayScheduleDtos } = event;
      const day = dayScheduleDtos?.find(
        (obj) => obj.dayDate === moment(values.timeBlockDate).format(dateInitFormats.basicDate)
      );

      if (day) {
        return moment(`${day.dayDate} ${day.startTime}`, dateInitFormats.dateTime).toDate();
      }

      return moment(new Date(), dateInitFormats.dateTime).toDate();
    };

    const getMaxTime = () => {
      const { dayScheduleDtos } = event;
      const day = dayScheduleDtos?.find(
        (obj) => obj.dayDate === moment(values.timeBlockDate).format(dateInitFormats.basicDate)
      );

      if (day) {
        return moment(`${day.dayDate} ${day.endTime}`, dateInitFormats.dateTime).toDate();
      }

      return moment(new Date(), dateInitFormats.dateTime).toDate();
    };

    return {
      ['type']: {
        path: SESSION_PATHS.TYPES,
        formItemChangeHandler: (e, data) => {
          setFieldValue('type', data.newValue);
        }
      },
      ['timeBlockDate']: {
        ampm: false,
        minDate: new Date(data?.dayScheduleDto?.eventDto?.startDateTime),
        maxDate: new Date(data?.dayScheduleDto?.eventDto?.endDateTime),
        onChange: (value) => {
          const { dayScheduleDtos } = event;
          const day = dayScheduleDtos?.find(
            (obj) => obj.dayDate === moment(value).format(dateInitFormats.basicDate)
          );

          if (day) {
            const startTime = day.startTime.split(':');
            const endTime = day.endTime.split(':');

            setValues({
              ...values,
              dayScheduleId: day.id,
              timeBlockDate: value,
              timeFrom: new Date(0, 0, 0, startTime[0], startTime[1]),
              timeTo: new Date(0, 0, 0, endTime[0], endTime[1])
            });
          }
        }
      },
      ['timeTo']: {
        ampm: false,
        minTime: getMinEndTime(),
        maxTime: getMaxTime(),
        onChange: (value) => {
          if (!value || !(value instanceof Date)) {
            setFieldValue('timeTo', null, true);
            setFieldTouched('timeTo', true);
            return;
          }

          const hour = value.getHours();
          const minutes = value.getMinutes();

          setFieldValue('timeTo', new Date(0, 0, 0, hour, minutes), true);
          setFieldTouched('timeTo', true);
        }
      },
      ['timeFrom']: {
        ampm: false,
        minTime: getMinStartTime(),
        maxTime: getMaxTime(),
        onChange: (value) => {
          if (!value || !(value instanceof Date)) {
            setFieldValue('timeFrom', null, true);
            setFieldTouched('timeFrom', true);
            return;
          }
          const hour = value.getHours();
          const minutes = value.getMinutes();

          setFieldValue('timeFrom', new Date(0, 0, 0, hour, minutes), true);
          setFieldTouched('timeFrom', true);
        }
      }
    };
  };

  return (
    <RightSidebar
      config={{ title: Localize.get('Sessions.Details'), fieldsConfig: sidebarSession }}
      validationSchema={sidebarSchema}
      extendedConfig={extendedConfig}
      data={data}
      isDetailsLoading={isDetailsLoading}
      onSave={onSave}
      onDelete={onDelete}
      disableDelete={true}
    />
  );
};

export default Sidebar;
