import React from 'react';
import Localize from 'react-intl-universal';

import { get } from 'lodash';
import moment from 'moment';

import { alpha } from '@mui/material';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { styled } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import { checkLanguage } from '@common/helpers/languages';
import EventStatus, { EVENT_STATUS_MAP } from '@components/EventStatus';
import { DATE_FORMAT } from '@config/inputs';

const COLOR_OPACITY = 0.6;

moment.locale(checkLanguage());

const BorderLinearProgress = styled(LinearProgress, {
  shouldForwardProp: (prop) => prop !== 'progressColor'
})(({ theme, progressColor }) => ({
  width: '100%',
  height: 30,
  borderRadius: 5,
  border: `1px solid ${theme.palette.grey[200]}`,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: alpha(theme.palette.background.paper, COLOR_OPACITY)
  },
  [`& .${linearProgressClasses.bar}`]: {
    backgroundColor: alpha(get(theme.palette, progressColor), COLOR_OPACITY)
  }
}));

const LinearProgressWithLabel = ({
  value = 0,
  totalValue = 0,
  progressColor = 'primary.main',
  currentValue = 0
}) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '75%', mr: 1 }}>
        <BorderLinearProgress value={value} variant="determinate" progressColor={progressColor} />
      </Box>
      <Box sx={{ minWidth: 60 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          currentValue
        )} of ${totalValue}`}</Typography>
      </Box>
    </Box>
  );
};

const BasicInfo = ({ data = null }) => {
  const minSeats = data?.minSeats;
  const maxSeats = data?.maxSeats;

  return (
    <Box>
      <ListItem>
        <ListItemText
          sx={{ whiteSpace: 'nowrap' }}
          primary={
            <>
              <Typography
                sx={{ display: 'inline-block', mr: 1 }}
                component="span"
                variant="body2"
                color="text.primary"
              >
                {Localize.get('IltSession.IltModule')}:
              </Typography>
              <Tooltip title={data.module?.name} placement="top" arrow>
                <Typography
                  noWrap={true}
                  sx={{
                    width: `calc(100% - ${Localize.get('IltSession.IltModule').length * 8}px)`,
                    display: 'inline-block',
                    verticalAlign: 'middle'
                  }}
                  component="span"
                  variant="body2"
                  color="text.secondary"
                >
                  {data.module?.name}
                </Typography>
              </Tooltip>
            </>
          }
        />
      </ListItem>
      <Divider />

      <ListItem>
        <ListItemText
          sx={{ whiteSpace: 'nowrap' }}
          primary={
            <>
              <Typography
                sx={{ display: 'inline-block', mr: 1 }}
                component="span"
                variant="body2"
                color="text.primary"
              >
                {Localize.get('IltSession.Course')}:
              </Typography>
              <Tooltip title={data.course?.name} placement="top" arrow>
                <Typography
                  noWrap={true}
                  sx={{
                    width: `calc(100% - ${Localize.get('IltSession.Course').length * 8}px)`,
                    display: 'inline-block',
                    verticalAlign: 'middle'
                  }}
                  component="span"
                  variant="body2"
                  color="text.secondary"
                >
                  {data.course?.name}
                </Typography>
              </Tooltip>
            </>
          }
        />
      </ListItem>
      <Divider />

      <ListItem>
        <ListItemText
          sx={{ whiteSpace: 'nowrap' }}
          primary={
            <>
              <Typography
                sx={{ display: 'inline-block', mr: 1 }}
                component="span"
                variant="body2"
                color="text.primary"
              >
                {Localize.get('IltSession.Status')}:
              </Typography>
              <Tooltip
                title={Localize.get(EVENT_STATUS_MAP[data?.status?.id])}
                placement="top"
                arrow
              >
                <Typography
                  noWrap={true}
                  sx={{
                    width: `calc(100% - ${Localize.get('IltSession.Status').length * 8}px)`,
                    display: 'inline-block',
                    verticalAlign: 'middle'
                  }}
                  component="span"
                  variant="body2"
                  color="text.secondary"
                >
                  <EventStatus status={data?.status?.id}></EventStatus>
                </Typography>
              </Tooltip>
            </>
          }
        />
      </ListItem>
      <Divider />

      <ListItem>
        <ListItemText
          primary={
            <>
              <Typography sx={{ mr: 1 }} component="span" variant="body2" color="text.primary">
                {Localize.get('Event.StartDateTime')}:
              </Typography>
              <Typography component="span" variant="body2" color="text.secondary">
                {moment(get(data, 'startDateTime')).format(DATE_FORMAT)}
              </Typography>
            </>
          }
        />
      </ListItem>
      <Divider />
      <ListItem>
        <ListItemText
          primary={
            <>
              <Typography sx={{ mr: 1 }} component="span" variant="body2" color="text.primary">
                {Localize.get('Event.EndDateTime')}:
              </Typography>
              <Typography component="span" variant="body2" color="text.secondary">
                {moment(get(data, 'endDateTime')).format(DATE_FORMAT)}
              </Typography>
            </>
          }
        />
      </ListItem>
      <Divider />
      <ListItem>
        <Typography sx={{ mr: 1 }} component="span" variant="body2" color="text.primary">
          {Localize.get('IltSession.MinimumSeats')}:
        </Typography>
      </ListItem>
      <ListItem>
        <Box sx={{ width: '100%' }}>
          <LinearProgressWithLabel progressColor="success.main" totalValue={minSeats} />
        </Box>
      </ListItem>
      <ListItem>
        <Typography sx={{ mr: 1 }} component="span" variant="body2" color="text.primary">
          {Localize.get('IltSession.MaximumSeats')}:
        </Typography>
      </ListItem>
      <ListItem>
        <Box sx={{ width: '100%' }}>
          <LinearProgressWithLabel progressColor="error.main" totalValue={maxSeats} />
        </Box>
      </ListItem>
    </Box>
  );
};

export default BasicInfo;
