import React, { useState, useEffect, Fragment } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Localize from 'react-intl-universal';

import ArrowBackIosOutlinedIcon from '@mui/icons-material/ArrowBackIosOutlined';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import { Box, Container, IconButton, Typography } from '@mui/material';
import Icon from '@mui/material/Icon';
import { useTheme, makeStyles } from '@mui/styles';

import image from '@assets/images/background-waves.jpg';
import Tile from '@components/Tile';
import TileCharts from '@components/TileCharts';

import { initialArrowState, moveStep } from './initialLanchpadState';
import useLaunchpadServices from './useLaunchpadServices';

const useStyles = makeStyles((theme) => ({
  metaDataBox: {
    display: 'flex',
    justifyContent: 'left',
    marginLeft: '-1rem',
    marginRight: '-1rem',
    maxWidth: '1350px',
    overflowX: 'scroll',
    '&::-webkit-scrollbar': {
      display: 'none'
    }
  },
  backgroundBox: {
    backgroundImage: `url(${theme.palette.mode === 'light' ? image : ''})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
  }
}));

const LaunchPad = () => {
  const classes = useStyles();
  const { launchpadData } = useLaunchpadServices();
  const theme = useTheme();
  const [storageLaunchpadData] = useState(JSON.parse(localStorage.getItem('launchpadData')));

  const [tempLaunchpadData, setTempLaunchpadData] = useState(
    storageLaunchpadData
      ? storageLaunchpadData
      : {
          masterData: launchpadData.masterData,
          planning: launchpadData.planning,
          transactionalData: launchpadData.transactionalData
        }
  );

  const [dataArrow, setDataArrow] = useState(initialArrowState);
  const [dataScrollAcc, setDataScrollAcc] = useState(0);
  const [dataPlanningAcc, setDataPlanningAcc] = useState(0);
  const [transactionalScrollAcc, setTransactionalScrollAcc] = useState(0);

  const scrollBox = document.getElementById('scrollBox');
  const scrollPlanningBox = document.getElementById('scrollPlanningBox');
  const scrollTransactionalBox = document.getElementById('scrollTransactionalBox');

  // a little function to help us with reordering the result
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  useEffect(() => {
    let master = [];
    let planning = [];
    let transactional = [];

    tempLaunchpadData?.masterData.map((tempEl) => {
      master.push(launchpadData.masterData.find((el) => el.id === tempEl.id));
    });
    tempLaunchpadData?.planning.map((tempEl) => {
      planning.push(launchpadData.planning.find((el) => el.id === tempEl.id));
    });
    tempLaunchpadData?.transactionalData.map((tempEl) => {
      transactional.push(launchpadData.transactionalData.find((el) => el.id === tempEl.id));
    });

    setTempLaunchpadData({
      ...launchpadData,
      masterData: master,
      planning: planning,
      transactionalData: transactional
    });
  }, [launchpadData]);

  const onDragEnd = (result) => {
    const { destination, source } = result;

    if (!destination) {
      return;
    }
    //turning left arrow anable when you scroll by traing to add tails from other rows
    if (destination.droppableId !== source.droppableId) {
      if (destination.droppableId === 'masterDropp') {
        setDataArrow({
          ...dataArrow,
          masterData: { ...dataArrow.masterData, left: !isOverflowing(scrollBox) }
        });
      }
      return;
    }
    // turning left arrow anable when you scroll by shift tails in same rows
    if (source.droppableId === 'masterDropp') {
      setDataArrow({
        ...dataArrow,
        masterData: { ...dataArrow.masterData, left: !isOverflowing(scrollBox) }
      });
    }

    if (destination.droppableId === 'masterDropp') {
      const items = reorder(tempLaunchpadData.masterData, source.index, destination.index);
      setTempLaunchpadData({ ...tempLaunchpadData, masterData: items });
      localStorage.setItem(
        'launchpadData',
        JSON.stringify({ ...tempLaunchpadData, masterData: items })
      );
    } else if (destination.droppableId === 'planningDropp') {
      const items = reorder(tempLaunchpadData.planning, source.index, destination.index);
      setTempLaunchpadData({ ...tempLaunchpadData, planning: items });
      localStorage.setItem(
        'launchpadData',
        JSON.stringify({ ...tempLaunchpadData, planning: items })
      );
    } else if (destination.droppableId === 'transactionalDropp') {
      const items = reorder(tempLaunchpadData.transactionalData, source.index, destination.index);
      setTempLaunchpadData({ ...tempLaunchpadData, transactionalData: items });
      localStorage.setItem(
        'launchpadData',
        JSON.stringify({ ...tempLaunchpadData, transactionalData: items })
      );
    }
  };
  //check to determine if an overflow is happening
  const isOverflowing = (element) => {
    return element?.scrollWidth > element?.offsetWidth + 5;
  };

  const scrollToEnd = (el) => {
    switch (el.id) {
      case 'scrollBox': {
        el.scrollTo({ top: 0, left: dataScrollAcc + moveStep, behavior: 'smooth' });
        setDataScrollAcc(dataScrollAcc + moveStep);

        if (dataScrollAcc === 0 && el.offsetWidth + (dataScrollAcc + moveStep) >= el.scrollWidth) {
          setDataArrow({
            ...dataArrow,
            masterData: { ...dataArrow.masterData, left: false, right: true }
          });
          return;
        }
        if (dataScrollAcc === 0) {
          setDataArrow({
            ...dataArrow,
            masterData: { ...dataArrow.masterData, left: false }
          });
        }
        if (el.offsetWidth + (dataScrollAcc + moveStep) >= el.scrollWidth) {
          setDataArrow({ ...dataArrow, masterData: { ...dataArrow.masterData, right: true } });
        }
        return;
      }
      case 'scrollPlanningBox': {
        el.scrollTo({ top: 0, left: dataPlanningAcc + moveStep, behavior: 'smooth' });
        setDataPlanningAcc(dataPlanningAcc + moveStep);

        if (
          dataPlanningAcc === 0 &&
          el.offsetWidth + (dataPlanningAcc + moveStep) >= el.scrollWidth
        ) {
          setDataArrow({
            ...dataArrow,
            planning: { ...dataArrow.planning, left: false, right: true }
          });
          return;
        }

        if (dataPlanningAcc === 0) {
          setDataArrow({ ...dataArrow, planning: { ...dataArrow.planning, left: false } });
        }
        if (el.offsetWidth + (dataPlanningAcc + moveStep) >= el.scrollWidth) {
          setDataArrow({ ...dataArrow, planning: { ...dataArrow.planning, right: true } });
        }
        return;
      }
      case 'scrollTransactionalBox': {
        el.scrollTo({ top: 0, left: transactionalScrollAcc + moveStep, behavior: 'smooth' });
        setTransactionalScrollAcc(transactionalScrollAcc + moveStep);
        if (
          transactionalScrollAcc === 0 &&
          el.offsetWidth + (transactionalScrollAcc + moveStep) >= el.scrollWidth
        ) {
          setDataArrow({
            ...dataArrow,
            transactionalData: { ...dataArrow.transactionalData, left: false, right: true }
          });
          return;
        }
        if (transactionalScrollAcc === 0) {
          setDataArrow({
            ...dataArrow,
            transactionalData: { ...dataArrow.transactionalData, left: false }
          });
        }
        if (el.offsetWidth + (transactionalScrollAcc + moveStep) >= el.scrollWidth) {
          setDataArrow({
            ...dataArrow,
            transactionalData: { ...dataArrow.transactionalData, right: true }
          });
        }
        return;
      }
      default:
        return false;
    }
  };

  const scrollToStart = (el) => {
    switch (el.id) {
      case 'scrollBox': {
        scrollBox.scrollTo({ top: 0, left: -200, behavior: 'smooth' });
        setDataScrollAcc(0);
        setDataArrow({
          ...dataArrow,
          masterData: { ...dataArrow.masterData, left: true, right: false }
        });
        return;
      }
      case 'scrollPlanningBox': {
        scrollPlanningBox.scrollTo({ top: 0, left: -200, behavior: 'smooth' });
        setDataPlanningAcc(0);
        setDataArrow({
          ...dataArrow,
          planning: { ...dataArrow.planning, left: true, right: false }
        });
        return;
      }
      case 'scrollTransactionalBox': {
        scrollTransactionalBox.scrollTo({ top: 0, left: -200, behavior: 'smooth' });
        setTransactionalScrollAcc(0);
        setDataArrow({
          ...dataArrow,
          transactionalData: { ...dataArrow.transactionalData, left: true, right: false }
        });
        return;
      }
      default:
        return false;
    }
  };

  return (
    <Box className={classes.backgroundBox}>
      <Container
        maxWidth="lg"
        sx={{
          backgroundColor: 'transparent',
          minHeight: `calc(100vh - ${theme.sizes.appHeader})`
        }}
      >
        <DragDropContext onDragEnd={onDragEnd}>
          <Box
            sx={{ display: 'flex', alignItems: 'center', paddingTop: '2rem', position: 'relative' }}
          >
            <Icon sx={{ color: 'transparent', fontSize: '0.9rem' }}>{'circle'}</Icon>
            <Typography sx={{ fontSize: '1.4rem', paddingLeft: '0.6rem', fontWeight: '400' }}>
              {Localize.get('Launchpad.Planning')}
            </Typography>
            <IconButton
              data-test-id="scroll-planning-box-1"
              disabled={dataArrow?.planning?.left}
              sx={{
                background: { xs: theme.palette.launchpad.arrowBackground, xl: 'transparent' },
                opacity: '0.5',
                position: 'absolute',
                zIndex: '100',
                top: '8.8rem',
                left: { xs: '0', sm: '0rem', md: '1rem', lg: '-0.5rem', xl: '-3.5rem' },
                display: `${isOverflowing(scrollPlanningBox) ? 'inherit' : 'none'}`
              }}
              onClick={() => scrollToStart(scrollPlanningBox)}
            >
              <ArrowBackIosOutlinedIcon />
            </IconButton>
            <IconButton
              data-test-id="scroll-planning-box-2"
              disabled={dataArrow?.planning?.right}
              sx={{
                background: { xs: theme.palette.launchpad.arrowBackground, xl: 'transparent' },
                position: 'absolute',
                zIndex: '100',
                top: '8.8rem',
                right: { xs: '0', sm: '0rem', md: '1rem', lg: '-0.5rem', xl: '-3.5rem' },
                display: `${isOverflowing(scrollPlanningBox) ? 'inherit' : 'none'}`
              }}
              onClick={() => scrollToEnd(scrollPlanningBox)}
            >
              <ArrowForwardIosOutlinedIcon />
            </IconButton>
          </Box>

          <Droppable droppableId="planningDropp" direction="horizontal">
            {(provided) => (
              <Fragment>
                <Box
                  data-test-id="scroll-planning-box-3"
                  id={'scrollPlanningBox'}
                  key={'droppBox'}
                  className={classes.metaDataBox}
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                >
                  {tempLaunchpadData.planning.map((el, index) => {
                    const {
                      id,
                      wide,
                      tileHeader,
                      tileSubheader,
                      iconName,
                      iconNumber,
                      numberTooltip,
                      iconNumberText,
                      numberTextColor,
                      footerText,
                      chart,
                      dataset,
                      newsBox,
                      newsLine,
                      newsHeder,
                      newsDate,
                      linkName
                    } = el;
                    return (
                      <Draggable key={id.toString()} draggableId={id.toString()} index={index}>
                        {(provided) => (
                          <Tile
                            dataTestId={`tile-${tileHeader}`}
                            offsetWidth={scrollPlanningBox?.offsetWidth}
                            provided={provided}
                            key={id.toString()}
                            wide={wide}
                            tileHeader={tileHeader}
                            tileSubheader={tileSubheader}
                            iconName={iconName}
                            iconNumber={iconNumber}
                            numberTooltip={numberTooltip}
                            iconNumberText={iconNumberText}
                            numberTextColor={numberTextColor}
                            footerText={footerText}
                            dataset={dataset}
                            chart={TileCharts(chart, dataset, iconNumber)}
                            newsBox={newsBox}
                            newsLine={newsLine}
                            newsHeder={newsHeder}
                            newsDate={newsDate}
                            linkName={linkName}
                          />
                        )}
                      </Draggable>
                    );
                  })}
                  {provided.placeholder}
                </Box>
              </Fragment>
            )}
          </Droppable>
          <Box
            sx={{ display: 'flex', alignItems: 'center', paddingTop: '1rem', position: 'relative' }}
          >
            <Icon sx={{ color: 'transparent', fontSize: '0.9rem' }}>{'circle'}</Icon>
            <Typography sx={{ fontSize: '1.4rem', paddingLeft: '0.6rem', fontWeight: '400' }}>
              {Localize.get('Launchpad.MasterData')}
            </Typography>
            <IconButton
              data-test-id="scroll-box"
              disabled={dataArrow?.masterData?.left}
              sx={{
                background: { xs: theme.palette.launchpad.arrowBackground, xl: 'transparent' },
                position: 'absolute',
                zIndex: '100',
                top: '8.8rem',
                left: { xs: '0', sm: '0rem', md: '1rem', lg: '-0.5rem', xl: '-3.5rem' },
                display: `${isOverflowing(scrollBox) ? 'inherit' : 'none'}`
              }}
              onClick={() => scrollToStart(scrollBox)}
            >
              <ArrowBackIosOutlinedIcon />
            </IconButton>
            <IconButton
              data-test-id="scroll-box-1"
              disabled={dataArrow?.masterData?.right}
              sx={{
                background: { xs: theme.palette.launchpad.arrowBackground, xl: 'transparent' },
                position: 'absolute',
                zIndex: '100',
                top: '8.8rem',
                right: { xs: '0', sm: '0rem', md: '1rem', lg: '-0.5rem', xl: '-3.5rem' },
                display: `${isOverflowing(scrollBox) ? 'inherit' : 'none'}`
              }}
              onClick={() => scrollToEnd(scrollBox)}
            >
              <ArrowForwardIosOutlinedIcon />
            </IconButton>
          </Box>

          <Droppable key={'masterDrop'} droppableId="masterDropp" direction="horizontal">
            {(provided) => (
              <>
                <Box
                  data-test-id="scroll-box-2"
                  id={'scrollBox'}
                  key={'droppBox'}
                  className={classes.metaDataBox}
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                >
                  {tempLaunchpadData?.masterData.map((el = {}, index) => {
                    const {
                      id,
                      wide,
                      tileHeader,
                      tileSubheader,
                      iconName,
                      iconNumber,
                      numberTooltip,
                      iconNumberText,
                      numberTextColor,
                      footerText,
                      linkName
                    } = el;
                    return (
                      <Draggable key={id.toString()} draggableId={id.toString()} index={index}>
                        {(provided) => (
                          <Tile
                            dataTestId={`tile-${tileHeader}`}
                            offsetWidth={scrollPlanningBox?.offsetWidth}
                            provided={provided}
                            key={id.toString()}
                            wide={wide}
                            tileHeader={tileHeader}
                            tileSubheader={tileSubheader}
                            iconName={iconName}
                            iconNumber={iconNumber}
                            numberTooltip={numberTooltip}
                            iconNumberText={iconNumberText}
                            numberTextColor={numberTextColor}
                            footerText={footerText}
                            linkName={linkName}
                          />
                        )}
                      </Draggable>
                    );
                  })}
                  {provided.placeholder}
                </Box>
              </>
            )}
          </Droppable>

          <Box
            sx={{ display: 'flex', alignItems: 'center', paddingTop: '1rem', position: 'relative' }}
          >
            <Icon sx={{ color: 'transparent', fontSize: '0.9rem' }}>{'circle'}</Icon>
            <Typography sx={{ fontSize: '1.4rem', paddingLeft: '0.6rem', fontWeight: '400' }}>
              {Localize.get('Launchpad.TransactionalData')}
            </Typography>
            <IconButton
              data-test-id="scroll-box"
              disabled={dataArrow?.transactionalData?.left}
              sx={{
                background: { xs: theme.palette.launchpad.arrowBackground, xl: 'transparent' },
                position: 'absolute',
                zIndex: '100',
                top: '8.8rem',
                left: { xs: '0', sm: '0rem', md: '1rem', lg: '-0.5rem', xl: '-3.5rem' },
                display: `${isOverflowing(scrollTransactionalBox) ? 'inherit' : 'none'}`
              }}
              onClick={() => scrollToStart(scrollTransactionalBox)}
            >
              <ArrowBackIosOutlinedIcon />
            </IconButton>
            <IconButton
              data-test-id="scroll-box-1"
              disabled={dataArrow?.transactionalData?.right}
              sx={{
                background: { xs: theme.palette.launchpad.arrowBackground, xl: 'transparent' },
                position: 'absolute',
                zIndex: '100',
                top: '8.8rem',
                right: { xs: '0', sm: '0rem', md: '1rem', lg: '-0.5rem', xl: '-3.5rem' },
                display: `${isOverflowing(scrollTransactionalBox) ? 'inherit' : 'none'}`
              }}
              onClick={() => scrollToEnd(scrollTransactionalBox)}
            >
              <ArrowForwardIosOutlinedIcon />
            </IconButton>
          </Box>
          <Droppable
            key={'transactionalDropp'}
            droppableId="transactionalDropp"
            direction="horizontal"
            className={classes.metaDataBox}
          >
            {(provided) => (
              <>
                <Box
                  data-test-id="scroll-transactional-box-3"
                  id={'scrollTransactionalBox'}
                  className={classes.metaDataBox}
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                >
                  {tempLaunchpadData.transactionalData.map((el, index) => {
                    const {
                      id,
                      wide,
                      tileHeader,
                      tileSubheader,
                      iconName,
                      iconNumber,
                      iconNumberBelowText,
                      iconNumberAbove,
                      numberTooltip,
                      iconNumberText,
                      iconNumberAboveText,
                      numberTextColor,
                      numberTextAboveColor,
                      footerText,
                      chart,
                      dataset,
                      linkName,
                      iconMarginBottom
                    } = el;
                    return (
                      <Draggable key={id.toString()} draggableId={id.toString()} index={index}>
                        {(provided) => (
                          <Tile
                            dataTestId={`tile-${tileHeader}`}
                            offsetWidth={scrollTransactionalBox?.offsetWidth}
                            provided={provided}
                            key={id.toString()}
                            wide={wide}
                            tileHeader={tileHeader}
                            tileSubheader={tileSubheader}
                            iconName={iconName}
                            iconNumber={iconNumber}
                            iconNumberBelowText={iconNumberBelowText}
                            iconNumberAbove={iconNumberAbove}
                            numberTooltip={numberTooltip}
                            iconNumberText={iconNumberText}
                            iconNumberAboveText={iconNumberAboveText}
                            numberTextColor={numberTextColor}
                            footerText={footerText}
                            dataset={dataset}
                            chart={TileCharts(chart, dataset, iconNumber)}
                            linkName={linkName}
                            numberTextAboveColor={numberTextAboveColor}
                            iconMarginBottom={iconMarginBottom}
                          />
                        )}
                      </Draggable>
                    );
                  })}
                  {provided.placeholder}
                </Box>
              </>
            )}
          </Droppable>
        </DragDropContext>
      </Container>
    </Box>
  );
};

export default LaunchPad;
