import React from 'react';
import Localize from 'react-intl-universal';

import { Field } from 'formik';

import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';

import FormGroupName from '@components/FormGroupName';
import FormComponentsMap from '@config/FormComponentsMap';

const ActivitiesForm = ({ fieldsConfig }) => (
  <>
    {fieldsConfig.map(({ groupName, fields }, index) => (
      <Grid container spacing={{ xs: 0, md: 0 }} key={index}>
        <Grid item xs={12}>
          <FormGroupName groupName={Localize.get(groupName)} />
        </Grid>

        <Grid container spacing={{ xs: 2, md: 3 }}>
          {fields?.map(({ component, label, ...rest }, fieldIndex) => (
            <Grid key={`${index}-${fieldIndex}`} item xs={12} sm={6} sx={{ mb: 3 }}>
              <FormControl fullWidth size="small">
                <Field
                  {...rest}
                  label={Localize.get(label)}
                  component={FormComponentsMap[component]}
                />
              </FormControl>
            </Grid>
          ))}
        </Grid>
      </Grid>
    ))}
  </>
);

export default ActivitiesForm;
