import { DIALOG_PATHS } from '@common/network/ApiPaths';

export default [
  {
    groupName: 'Labels.Information',
    fields: [
      {
        label: 'Labels.Name',
        name: 'name',
        required: true,
        disabled: false,
        component: 'text'
      },
      {
        label: 'Labels.AvailableSeats',
        name: 'availableSeats',
        required: true,
        disabled: false,
        component: 'text',
        type: 'number'
      },
      {
        label: 'Events.VenueName',
        name: 'venue',
        required: true,
        disabled: false,
        dialogTitle: 'Labels.SearchVenue',
        component: 'tokenValue',
        fetchDataPath: DIALOG_PATHS.VENUES,
        value: '',
        initQueryState: {
          id: '',
          name: '',
          city: '',
          country: ''
        },
        dialogColumns: [
          {
            field: 'id',
            headerName: 'Labels.ID',
            width: 100,
            editable: false,
            defaultValue: '',
            headerAlign: 'left',
            sortable: false
          },
          {
            field: 'name',
            headerName: 'Labels.Name',
            width: 350,
            editable: false,
            defaultValue: '',
            headerAlign: 'left',
            sortable: false
          },
          {
            field: 'city',
            headerName: 'Address.City',
            width: 350,
            editable: false,
            defaultValue: '',
            headerAlign: 'left',
            sortable: false,
            valueGetter: (params) => params?.row?.addresses[0].city
          },
          {
            field: 'country',
            headerName: 'Address.Country',
            width: 350,
            editable: false,
            defaultValue: '',
            headerAlign: 'left',
            sortable: false,
            valueGetter: (params) => params?.row?.addresses[0].country.value
          }
        ]
      },
      {
        label: 'Labels.Floor',
        name: 'floor',
        required: false,
        disabled: false,
        component: 'text'
      }
    ]
  },
  {
    groupName: 'Communication.Title',
    arrayName: 'phoneDtos',
    fields: [
      {
        label: 'Labels.Type',
        name: 'type.id',
        required: false,
        disabled: false,
        component: 'select'
      },
      {
        label: 'Labels.Phone',
        name: 'phone',
        required: true,
        disabled: false,
        component: 'text'
      }
    ]
  }
];
