import React, { useState, useMemo } from 'react';
import Localize from 'react-intl-universal';
import { useNavigate, useMatch } from 'react-location';
import { useDispatch } from 'react-redux';

import { Form, Formik } from 'formik';

import CancelIcon from '@mui/icons-material/Cancel';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import SaveIcon from '@mui/icons-material/Save';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import Typography from '@mui/material/Typography';

import { ACTION_MODES } from '@common/Constants';
import { errorMessageFormatter } from '@common/helpers/MessageFormatter';
import EntityTypes from '@common/network/EntityTypes';
import CreateFooter from '@components/CreateFooter';
import CreateForm from '@components/CreateForm';
import FormContainer from '@components/FormContainer';
import Header from '@components/Header';
import { showSnackbar, SnackbarSeverityTypes } from '@components/Snackbar/snackbarSlice';
import { createBusinessPartner } from '@pages/BusinessPartners/businessPartnersSlice';
import initialValues from '@pages/Locations/util/initialValues';
import useLocationSchema from '@pages/Locations/util/schemas/useLocationSchema';
import { LOCATION_STEPS } from '@pages/Locations/util/stepsConfig';

import getLocationConfig, { mapPropsToAddress } from './getLocationConfig';
import LocationType from './steps/LocationType';

const CreateLocation = () => {
  const LOCATION_STEPS_LOCALIZED = useMemo(
    () => LOCATION_STEPS.map((option) => Localize.get(option)),
    []
  );

  const {
    data: {
      roleTypes: { data: roleTypes },
      addressTypes: { data: addressTypes },
      phoneTypes: { data: phoneTypes },
      emailTypes: { data: emailTypes }
    }
  } = useMatch();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const locationValidationSchema = useLocationSchema();

  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const onSubmit = (location, { setSubmitting }) => {
    dispatch(createBusinessPartner({ postData: location, type: EntityTypes.LOCATION }))
      .unwrap()
      .then(() => navigate({ to: '/locations', replace: false }))
      .catch(({ data = {} }) => {
        dispatch(
          showSnackbar({
            message: errorMessageFormatter(data, 'LOCATION', ACTION_MODES.Create),
            severity: SnackbarSeverityTypes.ERROR
          })
        );
      })
      .finally(() => setSubmitting(false));
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <>
      <Header>{Localize.get('Locations.NewLocation')}</Header>
      <Stepper sx={{ p: '2rem' }} activeStep={activeStep}>
        {LOCATION_STEPS_LOCALIZED.map((label, index) => {
          const stepProps = {};
          const labelProps = {};

          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }

          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>

      <Typography variant="h5" sx={{ textAlign: 'center', color: 'primary.main' }}>
        {Localize.get('Labels.Step')} {activeStep + 1}
      </Typography>

      <Box sx={{ width: '100%' }}>
        <Formik
          initialErrors
          validateOnMount
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={locationValidationSchema}
        >
          {({
            isSubmitting,
            handleChange,
            handleSubmit,
            values,
            isValid,
            setFieldValue,
            setValues
          }) => (
            <>
              <Form>
                {/* 1st step Location Form */}
                {activeStep === 0 && (
                  <CreateForm
                    values={values}
                    mapPropsToAddress={mapPropsToAddress}
                    fieldsConfig={getLocationConfig(
                      values,
                      setFieldValue,
                      setValues,
                      roleTypes,
                      addressTypes,
                      phoneTypes,
                      emailTypes
                    )}
                  />
                )}

                {/* 2nd step Location Type */}
                {activeStep === 1 && (
                  <FormContainer>
                    <LocationType values={values} handleChange={handleChange} />
                  </FormContainer>
                )}
              </Form>
              <CreateFooter>
                <>
                  <Button
                    data-test-id="cancel-btn"
                    variant="outlined"
                    startIcon={<CancelIcon />}
                    color="error"
                    onClick={() => navigate({ to: '/locations', replace: false })}
                    sx={{ mr: 1 }}
                  >
                    {Localize.get('Buttons.Cancel')}
                  </Button>
                  <Button
                    data-test-id="back-btn"
                    color="primary"
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    variant="outlined"
                    sx={{ mr: 1 }}
                    startIcon={<NavigateBeforeIcon />}
                  >
                    {Localize.get('Buttons.Back')}
                  </Button>
                  <Box sx={{ flex: '1 1 auto' }} />

                  {activeStep === LOCATION_STEPS.length - 1 ? (
                    <Button
                      data-test-id="save-btn"
                      startIcon={<SaveIcon />}
                      variant="outlined"
                      disabled={isSubmitting}
                      onClick={handleSubmit}
                    >
                      {Localize.get('Buttons.Save')}
                    </Button>
                  ) : (
                    <Button
                      data-test-id="next-btn"
                      endIcon={<NavigateNextIcon />}
                      variant="outlined"
                      disabled={!isValid}
                      onClick={handleNext}
                    >
                      {Localize.get('Buttons.Next')}
                    </Button>
                  )}
                </>
              </CreateFooter>
            </>
          )}
        </Formik>
      </Box>
    </>
  );
};

export default CreateLocation;
