import Localize from 'react-intl-universal';

import { darken } from '@mui/material';
import { styled } from '@mui/material/styles';

const SHADER = 0.2;

const StyledOpen = styled('span')(({ theme }) => ({
  color: darken(theme.palette.eventStatus.openDark, SHADER)
}));

const StyledInProgress = styled('span')(({ theme }) => ({
  color: darken(theme.palette.eventStatus.inProgressDark, SHADER)
}));

const StyledCompleted = styled('span')(({ theme }) => ({
  color: darken(theme.palette.eventStatus.completedDark, SHADER)
}));

const StyledCancelled = styled('span')(({ theme }) => ({
  color: darken(theme.palette.eventStatus.cancelledDark, SHADER)
}));

const EventStatus = ({ status = 1, children }) =>
  ({
    1: <StyledOpen>{children ? children : Localize.get('Event.Statuses.Open')}</StyledOpen>,
    2: (
      <StyledInProgress>
        {children ? children : Localize.get('Event.Statuses.InProgress')}
      </StyledInProgress>
    ),
    3: (
      <StyledCompleted>
        {children ? children : Localize.get('Event.Statuses.Completed')}
      </StyledCompleted>
    ),
    4: (
      <StyledCancelled>
        {children ? children : Localize.get('Event.Statuses.Cancelled')}
      </StyledCancelled>
    )
  }[status]);

const EVENT_STATUS_MAP = {
  1: 'Event.Statuses.Open',
  2: 'Event.Statuses.InProgress',
  3: 'Event.Statuses.Completed',
  4: 'Event.Statuses.Cancelled'
};

export { EVENT_STATUS_MAP };

export default EventStatus;
