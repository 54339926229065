import { Navigate } from 'react-location';

import Calendar from '@pages/Calendar/Calendar';

const CalendarRoute = () => ({
  path: 'calendar',
  children: [
    { path: '/', element: <Calendar /> },
    {
      element: <Navigate to="/calendar" />
    }
  ]
});

export default CalendarRoute;
