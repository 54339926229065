import moment from 'moment';

import { CLASSROOM_TYPE_ID } from '../iltSessionSlice';

export default {
  seminarNumber: '',
  name: '',
  maxSeats: '',
  course: null,
  minSeats: '',
  iltModule: null,
  status: 1,
  type: CLASSROOM_TYPE_ID,
  startDateTime: moment(),
  endDateTime: moment().add(30, 'm'),
  venueDtos: [],
  hasWaitingList: false,
  isMeaPublished: false,
  automaticWaitingList: false,
  automaticWaitingListCancellation: false,
  hasRegistrationPeriod: false,
  hasCancellationDeadline: false,
  automaticCancellationDate: moment(),
  registrationFrom: new Date(),
  registrationUntil: moment(new Date()).add(30, 'm').toDate(),
  cancellationDeadline: moment(),
  sessionDayDto: {
    name: '',
    dayDate: moment(),
    startTime: null,
    endTime: null,
    meetingRoomId: null,
    instructor: [],
    details: '',
    webinarUrl: '',
    sendReminder: false,
    reminderValue: 1,
    reminderMetric: {
      id: 0,
      value: ''
    }
  }
};
