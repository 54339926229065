export const LOAD_NOTES = 'LOAD_NOTES';
export const SET_NOTES_ERROR = 'SET_NOTES_ERROR';
export const SET_LOADING = 'SET_NOTES_LOADING';
export const SET_NOTES_SELECTION_MODEL = 'SET_NOTES_SELECTION_MODEL';

export const DELETE_SELECTED_NOTES = 'DELETE_SELECTED_NOTES';
export const DELETE_NOTE_SUCCESS = 'DELETE_NOTE_SUCCESS';
export const DELETE_NOTE_ERROR = 'DELETE_NOTE_ERROR';

export const NOTES_DIALOG_OPEN = 'NOTES_DIALOG_OPEN';
export const NOTES_DIALOG_CLOSE = 'NOTES_DIALOG_CLOSE';

export const ADD_NOTES_SUCCESS = 'ADD_NOTES_SUCCESS';
export const ADD_NOTES_ERROR = 'ADD_NOTES_ERROR';

export const UPDATE_NOTES_SUCCESS = 'UPDATE_NOTES_SUCCESS';
export const UPDATE_NOTES_ERROR = 'UPDATE_NOTES_ERROR';

export const SET_ANCHOR_EL = 'SET_ANCHOR_EL';
export const SET_NOTES_TYPE = 'SET_NOTES_TYPE';
