import { createSlice } from '@reduxjs/toolkit';

import { checkLanguage } from '@common/helpers/languages';

export const initialState = {
  lang: checkLanguage()
};

export const usersSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {}
});

export const selectLanguage = (state) => state.user.lang;

const { reducer } = usersSlice;

export default reducer;
