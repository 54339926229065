import React, { useCallback, useEffect, useState } from 'react';
import Localize from 'react-intl-universal';
import { useNavigate, useSearch } from 'react-location';
import { useSelector, useDispatch } from 'react-redux';

import { get } from 'lodash';
import moment from 'moment';

import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import MonitorIcon from '@mui/icons-material/Monitor';

import { checkChangedFields } from '@common/helpers/helpers';
import EntityTypes from '@common/network/EntityTypes';
import { CONFIRM_ACTIONS, openConfirmDialog } from '@components/ConfirmDialog';
import { selectIsFullScreen } from '@components/DetailsToolbar/detailsToolbarSlice';
import {
  FILTER_ACTIONS,
  openFilterDialog,
  resetFilterState,
  scrubFiltersForBE,
  selectIsActive
} from '@components/FilterDialog/filtersSlice';
import Header from '@components/Header';
import LayoutContainer, { LeftContainer, RightContainer } from '@components/LayoutContainer';
import MasterList from '@components/MasterList';
import MasterListFooter from '@components/MasterListFooter';
import MasterListItem from '@components/MasterListItem';
import MasterListTitle from '@components/MasterListTitle';
import MasterListToolbar from '@components/MasterListToolbar';
import { selectIsOpen, setMode } from '@components/RightSidebar/rightSidebarSlice';
import SortDialog from '@components/SortDialog';
import { SEARCH_INPUT_DELAY, DATE_FORMAT } from '@config/inputs';
import useDebounce from '@hooks/handlers/useDebounce';
import useIsMounted from '@hooks/isMounted/isMounted';
import {
  setFilterParams,
  setLoading,
  selectFilter,
  selectTotalElements,
  selectTotalPages,
  selectDetails,
  selectIsLoading,
  selectIsDetailsLoading,
  selectList,
  setSelectedId,
  selectId,
  initialState,
  saveIltSession,
  fetchIltSession,
  fetchIltSessionDetails,
  deleteIltSession,
  resetState,
  WEBINAR_TYPE_ID,
  CLASSROOM_TYPE_ID,
  setDetails,
  fetchIltSessionByExternalId,
  selectUrlSearchParams,
  setUrlSearchParams,
  selectIsMounted,
  setIsMounted
} from '@pages/IltSession/iltSessionSlice';

import { IltSessionDetails, IltSessionTabs } from './components';
import Sidebar from './components/Sidebar';
import iltSessionFilters from './util/fields/filters';
import { SORT_DATA } from './util/sortConfig';

const IltSession = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { externalId = '' } = useSearch();

  const [isOpenDialogSort, setIsOpenDialogSort] = useState(false);
  const setStateIfMounted = useIsMounted();

  const selectedId = useSelector(selectId);
  const data = useSelector(selectList);
  const details = useSelector(selectDetails);
  const totalElements = useSelector(selectTotalElements);
  const totalPages = useSelector(selectTotalPages);
  const searchParams = useSelector(selectUrlSearchParams);
  const filter = useSelector(selectFilter);
  const isLoading = useSelector(selectIsLoading);
  const isDetailsLoading = useSelector(selectIsDetailsLoading);
  const isOpen = useSelector(selectIsOpen);
  const isFullScreen = useSelector(selectIsFullScreen);
  const isMounted = useSelector(selectIsMounted);
  const isFilterActive = useSelector(selectIsActive);

  setStateIfMounted(() => dispatch(setIsMounted(true)), true);

  // Get ilt sessions list on init and on filters change
  useEffect(() => {
    dispatch(fetchIltSession(filter));
  }, [filter.sortBy, filter.sortDirection, filter.page, searchParams.externalId, isMounted]);

  // Get initial List of entities and on sort change
  useEffect(() => dispatch(fetchIltSessionDetails(selectedId)), [selectedId]);

  // Set search url param (/ilt-session?externalId=1)
  useEffect(() => dispatch(setUrlSearchParams({ externalId })), [externalId]);

  // Fetch one ilt session by external id passed via url
  useEffect(() => dispatch(fetchIltSessionByExternalId(searchParams)), [searchParams.externalId]);

  // Get List of entities on search change
  useDebounce(() => dispatch(fetchIltSession(filter)), SEARCH_INPUT_DELAY, [filter.search]);

  // Clear ilt session state
  useEffect(() => {
    return () => {
      dispatch(resetFilterState());
      dispatch(resetState());
    };
  }, []);

  const onSave = (values) => {
    return dispatch(
      saveIltSession({ postData: checkChangedFields(details, values), id: selectedId })
    )
      .unwrap()
      .then(() => {
        return Promise.resolve();
      })
      .catch((rejectedValueOrSerializedError) => {
        return Promise.reject({
          rejectedValueOrSerializedError,
          entityType: EntityTypes.ILT_SESSION
        });
      });
  };

  // Delete Ilt Session
  const onIltSessionDelete = () =>
    dispatch(
      openConfirmDialog({
        title: Localize.get('ConfirmationMessages.Delete', {
          item: Localize.get('IltSession.Item')?.toLowerCase()
        }),
        confirmButton: Localize.get('Buttons.Delete'),
        cancelButton: Localize.get('Buttons.Cancel')
      })
    )
      .unwrap()
      .then((result) => {
        if (result === CONFIRM_ACTIONS.Cancel) {
          return;
        }
        dispatch(deleteIltSession(selectedId))
          .unwrap()
          .then(() => dispatch(setMode('read')))
          .catch((rejectedValueOrSerializedError) => console.error(rejectedValueOrSerializedError));
      });

  const renderMasterListItem = useCallback(
    (item) => (
      <MasterListItem
        key={item.id}
        id={item.id}
        heading={{ left: `${item?.name}`, right: item?.id }}
        rightDetails={{
          firstRow:
            (item?.type?.id == CLASSROOM_TYPE_ID && <MeetingRoomIcon />) ||
            (item?.type?.id == WEBINAR_TYPE_ID && <MonitorIcon />)
        }}
        leftDetails={{
          firstRow: item?.courseName,
          secondRow: moment(get(item, 'startDateTime', '')).format(DATE_FORMAT),
          thirdRow: moment(get(item, 'endDateTime', '')).format(DATE_FORMAT)
        }}
        selectedId={selectedId}
        onItemSelect={(payload) => dispatch(setSelectedId(payload))}
      />
    ),
    [selectedId]
  );

  const onFilterClick = useCallback(
    () =>
      dispatch(
        openFilterDialog({
          title: `${Localize.get('IltSession.Item')} ${Localize.get('Labels.Filter')}`,
          config: iltSessionFilters.map((filter) => ({
            ...filter,
            label: Localize.get(filter.label),
            operator: { label: Localize.get(filter.operator.label), key: filter.operator.key },
            options: filter.options?.map((option) => {
              if (option?.label) {
                return {
                  ...option,
                  key: option?.translateKey ? Localize.get(option.key) : option.key,
                  label: Localize.get(option.label)
                };
              }
              return Localize.get(option);
            })
          }))
        })
      ).then(({ payload }) => {
        if (payload.action === FILTER_ACTIONS.Cancel) {
          return;
        }

        if (payload.action === FILTER_ACTIONS.Reset) {
          dispatch(fetchIltSession(filter));
          return;
        }

        dispatch(
          fetchIltSession({
            ...filter,
            filters: { advancedFilters: scrubFiltersForBE(payload.filters) }
          })
        );
      }),
    []
  );

  const onSearchChange = useCallback(
    (e) => {
      if (searchParams?.externalId) {
        // TODO: Hack (check how to do it with react-location)
        window.history.replaceState(null, null, window.location.pathname);
        dispatch(setUrlSearchParams({ externalId: '' }));
        return;
      }

      dispatch(setLoading(true));
      dispatch(setFilterParams({ key: 'search', value: e.target.value }));
    },
    [searchParams?.externalId]
  );

  return (
    <LayoutContainer>
      <LeftContainer isFullScreen={isFullScreen}>
        <Header>
          <MasterListTitle>{`${Localize.get(
            'IltSession.MasterListTittle'
          )} (${totalElements})`}</MasterListTitle>
        </Header>

        <MasterListToolbar
          isFilterActive={isFilterActive}
          isDisabled={data?.length === 0}
          onSortClick={() => setIsOpenDialogSort(true)}
          onFilterClick={onFilterClick}
          isSortActive={
            filter.sortBy !== initialState.filter.sortBy ||
            filter.sortDirection !== initialState.filter.sortDirection
          }
          searchProps={{
            isVisible: !searchParams?.externalId,
            value: filter.search || searchParams?.externalId,
            onSearchChange
          }}
        />

        <MasterList isLoading={isLoading} data={data} renderMasterItem={renderMasterListItem} />

        <MasterListFooter
          onPageChange={(e, page) => dispatch(setFilterParams({ ...filter, page: page - 1 }))}
          onAddClick={() => navigate({ to: '/ilt-session/new', replace: false })}
          pagination={{ totalPages, page: filter.page + 1 }}
        />

        <SortDialog
          onCancel={() => setIsOpenDialogSort(false)}
          open={isOpenDialogSort}
          sortState={filter}
          fields={SORT_DATA}
          sortHandler={({ sortBy, sortDirection }) =>
            dispatch(
              setFilterParams([
                { key: 'sortBy', value: sortBy },
                { key: 'sortDirection', value: sortDirection }
              ])
            )
          }
        />
      </LeftContainer>

      <RightContainer open={isOpen} isFullScreen={isFullScreen}>
        <IltSessionDetails details={details} entityId={selectedId}>
          <IltSessionTabs
            entityType={EntityTypes.ILT_SESSION}
            setDetails={setDetails}
            entityId={selectedId}
            details={details}
          />
        </IltSessionDetails>
      </RightContainer>

      <Sidebar
        details={details}
        isDetailsLoading={isDetailsLoading}
        onDelete={onIltSessionDelete}
        onSave={onSave}
      />
    </LayoutContainer>
  );
};

export default IltSession;
