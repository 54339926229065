import moment from 'moment';

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

// import { SORT_DIRECTION } from '@common/Constants';
// import { EVENTS_PATHS } from '@common/network/ApiPaths';
import { ACTION_MODES, TAB_KEYS } from '@common/Constants';
import { errorMessageFormatter, successMessageFormatter } from '@common/helpers/MessageFormatter';
import { ILT_SESSION_PATHS } from '@common/network/ApiPaths';
import EntityTypes from '@common/network/EntityTypes';
import { showSnackbar, SnackbarSeverityTypes } from '@components/Snackbar/snackbarSlice';
import { setDetails } from '@pages/IltSession/iltSessionSlice';
import { deleteByPath, getByPathAndParams, postByPathAndData } from '@services/BaseApi';

// import { SORT_DATA_PARTICIPANTS } from '../../util/sortConfig';
export const PAGE_SIZE = 5;
export const PAGE_SIZE_OPTIONS = [5, 10, 15];
export const initialState = {
  data: [],
  rows: [],
  filter: {
    search: '',
    // WAITING FOR THE BE
    // sortBy: SORT_DATA_HOTELS[0].name,
    // sortDirection: SORT_DIRECTION.ASCENDING,
    page: 0,
    size: PAGE_SIZE
  },
  totalPages: 0,
  totalElements: 0,
  isLoading: false,
  selectionModel: [],
  mode: 'details',
  newPrices: {
    typeId: 6,
    price: '',
    currencyId: 1,
    validFrom: moment(),
    validTo: moment().add(1, 'days').format('YYYY-MM-DD')
  },
  createErrors: null
};

export const fetchPrices = createAsyncThunk(
  'iltSessionPrices/prices',
  ({ entityId, filter, rejectWithValue }) => {
    return getByPathAndParams({
      path: ILT_SESSION_PATHS.GET_PRICES,
      pathVariables: { id: entityId },
      params: filter
    })
      .then((response) => response.data)
      .catch((error) => {
        return rejectWithValue(error.response);
      });
  }
);

export const createPrices = createAsyncThunk(
  'iltSessionPrices/create',
  ({ entityId }, { getState, rejectWithValue, dispatch }) => {
    const state = getState();
    const newPrices = state.iltSessionPrices.newPrices;
    const { details } = state.iltSession;

    const formattedValidFrom = moment(newPrices.validFrom).format('YYYY-MM-DD');
    const formattedValidTo = moment(newPrices.validTo).format('YYYY-MM-DD');

    return postByPathAndData({
      path: ILT_SESSION_PATHS.POST_PRICES,
      data: {
        typeId: 6,
        price: newPrices.price,
        currencyId: newPrices.currencyId,
        validFrom: formattedValidFrom,
        validTo: formattedValidTo,
        event: {
          id: entityId
        }
      },
      pathVariables: { id: entityId }
    })
      .then((response) => {
        dispatch(
          showSnackbar({
            message: successMessageFormatter(EntityTypes.PRICES, ACTION_MODES.Create),
            severity: SnackbarSeverityTypes.SUCCESS
          })
        );
        dispatch(
          setDetails({
            ...details,
            counts: {
              ...details.counts,
              price: response.data.counts.price
            }
          })
        );
        return response.data;
      })
      .catch((error) => {
        dispatch(
          showSnackbar({
            message: errorMessageFormatter(error, EntityTypes.PARTICIPANT, ACTION_MODES.Delete),
            severity: SnackbarSeverityTypes.ERROR
          })
        );

        return rejectWithValue(error.response);
      });
  }
);

export const deletePrices = createAsyncThunk(
  'iltSessionPrices/delete',
  ({ id, priceIds }, { dispatch, getState, rejectWithValue }) => {
    return deleteByPath({
      path: ILT_SESSION_PATHS.DELETE_PRICE,
      pathVariables: { id, priceIds }
    })
      .then((response) => {
        dispatch(fetchPrices({ entityId: id, filter: getState().iltSessionPrices.filter }));
        dispatch(setDetails({ counts: { [TAB_KEYS.PRICE]: response.data.count } }));
        return { response, priceIds };
      })
      .catch((error) => rejectWithValue(error.response));
  }
);

export const iltSessionPriceTableSlice = createSlice({
  name: 'liltSessionPrices',
  initialState,
  reducers: {
    resetState: () => initialState,
    setData: (state, { payload }) => {
      state.data = payload;
    },
    setMode: (state, { payload }) => {
      state.mode = payload;
    },
    setNewPrices: (state, { payload }) => {
      state.newPrices = payload;
    },
    setErrors: (state, { payload }) => {
      state.createErrors = payload;
    },
    setCancelCreateMode: (state) => {
      state.mode = 'details';
      state.newPrices = initialState.newPrices;
      state.createErrors = null;
    },
    setSelectionModel: (state, { payload }) => {
      if (Array.isArray(payload)) {
        state.selectionModel = [...payload];
      } else {
        const index = state.selectionModel.findIndex((s) => s === payload);
        if (index === -1) {
          state.selectionModel = [...state.selectionModel, payload];
        } else {
          state.selectionModel = [
            ...state.selectionModel.slice(0, index),
            ...state.selectionModel.slice(index + 1)
          ];
        }
      }
    },
    setFilterParams: (state, action) => {
      let newFilterValues = {};
      // Case when search value is reset to empty and search bar is closed
      if (action.payload.key === 'search' && !action.payload.value && !state.filter.search) {
        state.isLoading = false;
        return;
      }
      if (Array.isArray(action.payload)) {
        newFilterValues = action.payload.reduce(
          (obj, item) => ((obj[item.key] = item.value), obj),
          {}
        );
      } else {
        newFilterValues = { [action.payload.key]: action.payload.value };
      }
      state.filter = { ...state.filter, ...newFilterValues, page: action.payload.page ?? 0 };
    }
  },
  extraReducers: (builder) => {
    builder
      // Get all
      .addCase(fetchPrices.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.totalPages = payload?.totalPages || state.totalPages;
        state.totalElements = payload?.totalElements || state.totalElements;
        state.data = payload?.content ?? [];
      })
      .addCase(fetchPrices.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchPrices.rejected, (state) => {
        state.data = [];
        state.isLoading = false;
      })
      .addCase(createPrices.fulfilled, (state, { payload }) => {
        state.data = [payload, ...state.data];
        state.mode = 'details';
        state.newPrices = initialState.newPrices;
        state.isLoading = false;
        state.totalElements += 1;
      })
      .addCase(createPrices.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createPrices.rejected, (state) => {
        state.isLoading = false;
      })
      //delete prices
      .addCase(deletePrices.fulfilled, (state) => {
        state.selectionModel = [];
      });
  }
});

export const selectList = (state) => state.iltSessionPrices.data;
export const selectRows = (state) => state.iltSessionPrices.rows;
export const selectTotalElements = (state) => state.iltSessionPrices.totalElements;
export const selectTotalPages = (state) => state.iltSessionPrices.totalPages;
export const selectLocationDetails = (state) => state.iltSessionPrices.iltSessionDetails;
export const selectFilter = (state) => state.iltSessionPrices.filter;
export const selectIsLoading = (state) => state.iltSessionPrices.isLoading;
export const selectSelectionModel = (state) => state.iltSessionPrices.selectionModel;
export const selectMode = (state) => state.iltSessionPrices.mode;
export const selectNewPrices = (state) => state.iltSessionPrices.newPrices;
export const selectErrors = (state) => state.iltSessionPrices.createErrors;
export const selectIfPriceSaveIsDisabled = (state) =>
  Boolean(Object.keys(state.iltSessionPrices.createErrors).length);

const { actions, reducer } = iltSessionPriceTableSlice;

export const {
  setData,
  setFilterParams,
  resetState,
  setSelectionModel,
  setMode,
  setNewPrices,
  setErrors,
  setCancelCreateMode
} = actions;

export default reducer;
