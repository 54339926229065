export default {
  content: [
    {
      eventParticipantDto: {
        id: 1,
        masterEventParticipant: null,
        personDto: {
          id: 1,
          participantName: 'Participant 1',
          companyName: 'Company 3',
          hotelName: 'Contrimo Day',
          arrival: '10.07.2023 15:00',
          departure: '11.07.2023 15:00',
          roomType: 'Double',
          contingent: 'A'
        }
      }
    },
    {
      eventParticipantDto: {
        id: 2,
        masterEventParticipant: null,
        personDto: {
          id: 2,
          participantName: 'Participant 2',
          companyName: 'Company 2',
          hotelName: 'Contrimo Day',
          arrival: '12.08.2023 16:00',
          departure: '13.08.2023 16:00',
          roomType: 'Double',
          contingent: 'B'
        }
      }
    },
    {
      eventParticipantDto: {
        id: 3,
        masterEventParticipant: null,
        personDto: {
          id: 3,
          participantName: 'Participant 3',
          companyName: 'Company 1',
          hotelName: 'Contrimo Day',
          arrival: '14.09.2023 17:00',
          departure: '15.09.2023 17:00',
          roomType: 'Single',
          contingent: 'C'
        }
      }
    }
  ],
  pageable: {
    sort: { empty: true, sorted: false, unsorted: true },
    offset: 0,
    pageNumber: 0,
    pageSize: 5,
    paged: true,
    unpaged: false
  },
  totalPages: 1,
  totalElements: 1,
  last: true,
  size: 5,
  number: 0,
  sort: { empty: true, sorted: false, unsorted: true },
  numberOfElements: 1,
  first: true,
  empty: false
};
