import { update, get } from 'lodash';

import { copyObjectWithoutRef } from '@common/helpers/helpers';
const useHandleOnChange = (setState) => {
  const itemHandler = (e) => {
    setState((prevState) => {
      const prevStateCopy = copyObjectWithoutRef(prevState);
      return update({ ...prevStateCopy }, e.target.name, () => {
        return e.target.value;
      });
    });
  };
  const switchHandler = (e) => {
    setState((prevState) => {
      const prevStateCopy = copyObjectWithoutRef(prevState);
      return update({ ...prevStateCopy }, e.target.id, () => {
        return e.target.checked;
      });
    });
  };
  const selectHandler = (e) => {
    setState((prevState) => {
      let prevStateCopy = copyObjectWithoutRef(prevState);
      let newState = update({ ...prevStateCopy }, e.target.name, () => {
        return e.detail.selectedOption.value;
      });
      return newState ? newState : prevStateCopy;
    });
  };
  const removeItemFromList = (path, index) => {
    setState((prevState) => {
      const prevStateCopy = copyObjectWithoutRef(prevState);
      const myList = get(prevStateCopy, path);
      if (myList) {
        myList.splice(index, 1);
        return update({ ...prevStateCopy }, path, myList);
      }
      return prevStateCopy;
    });
  };
  const addItemInList = (path, addedItem, useUnshift = true) => {
    setState((prevState) => {
      const prevStateCopy = copyObjectWithoutRef(prevState);
      const myList = get({ ...prevStateCopy }, path);
      if (myList) {
        if (useUnshift) {
          myList.unshift(addedItem);
        } else {
          myList.push(addedItem);
        }
        return update({ ...prevStateCopy }, path, myList);
      }
      return prevStateCopy;
    });
  };
  return { itemHandler, switchHandler, selectHandler, removeItemFromList, addItemInList };
};
export default useHandleOnChange;
