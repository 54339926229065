import React from 'react';
import Localize from 'react-intl-universal';
import { useMatch } from 'react-location';

import { dateInitFormats } from '@common/Constants';
import { dateToFormat } from '@common/helpers/dates';
import { PARTICIPANTS_PATHS } from '@common/network/ApiPaths';
import RightSidebar from '@components/RightSidebar';

import sidebarHotels from '../utils/fields/sidebarHotels';
import hotelsSchema from '../utils/schemas/hotelsSchema';

const Sidebar = ({ isDetailsLoading = false, onSave = () => {}, onDelete = () => {}, data }) => {
  const {
    data: {
      currency: { data: currency }
    }
  } = useMatch();

  const extendedConfig = (setFieldValue, values, setValues) => {
    return {
      ['hotel']: {
        tokenValue: values?.hotel
          ? [
              {
                title: `${values.hotel.name} `
              }
            ]
          : undefined
      },
      ['accommodation']: {
        disabled: values?.usedFreeBeds && parseInt(values.usedFreeBeds.split('/')[0]) > 0,
        initQueryState: { id: values?.hotel?.id },
        fetchDataPath: PARTICIPANTS_PATHS.HOTELS_ACCOMMODATIONS.replace(':id', values?.hotel?.id),
        tokenValue: values.accommodation?.roomType?.value
          ? [
              {
                title: `${values.accommodation?.roomType?.value} `
              }
            ]
          : undefined,
        onIconClick: (e) => {
          if (e?.target?.name === 'delete') {
            setValues({
              ...values,
              accommodation: null
            });
          }
        },
        selectItem: (value) => {
          setValues({
            ...values,
            accommodation: { ...value },
            maxOccupancy: value.maxOccupancy
          });
        }
      },
      ['currency.id']: {
        value: values?.currency?.id,
        options: currency
      },
      ['validUntil']: {
        onChange: (value) =>
          setFieldValue('validUntil', dateToFormat(value, dateInitFormats.basicDate))
      },
      ['startDate']: {
        disabled: values?.usedFreeBeds && parseInt(values.usedFreeBeds.split('/')[0]) > 0,
        onChange: (value) =>
          setFieldValue('startDate', dateToFormat(value, dateInitFormats.basicDate))
      },
      ['endDate']: {
        disabled: values?.usedFreeBeds && parseInt(values.usedFreeBeds.split('/')[0]) > 0,
        onChange: (value) =>
          setFieldValue('endDate', dateToFormat(value, dateInitFormats.basicDate))
      }
    };
  };

  return (
    <RightSidebar
      config={{
        title: Localize.get('IltSession.HotelContingentDetails'),
        fieldsConfig: sidebarHotels
      }}
      validationSchema={hotelsSchema}
      extendedConfig={extendedConfig}
      data={data}
      isDetailsLoading={isDetailsLoading}
      onSave={onSave}
      onDelete={onDelete}
      disableDelete={true}
    />
  );
};

export default Sidebar;
