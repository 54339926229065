import React, { memo, Fragment, useMemo } from 'react';
import Localize from 'react-intl-universal';

import { Field } from 'formik';
import { omit } from 'lodash';

import RemoveIcon from '@mui/icons-material/Remove';
import { Grid } from '@mui/material';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';

import { mapPropsToArrayItem } from '@common/helpers/mapPropsToComponent';
import { MAX_COLUMN_WIDTH } from '@components/RightSidebarForm';
import FormComponentsMap from '@config/FormComponentsMap';

const ArrayItem = ({
  arrayItemIndex,
  isFieldOrButtonDisabled,
  arrayDefinitions,
  arrayName,
  fields = [],
  context,
  remove,
  arrayItem,
  mapPropsToAddress = null,
  setFieldValue,
  values,
  arrayConfig = null
}) => {
  const fieldsWithArrayItemProps = useMemo(() => {
    let additionalPropsObj = null;

    if (mapPropsToAddress) {
      additionalPropsObj = mapPropsToAddress(setFieldValue, arrayItemIndex, arrayItem, values);
    }

    return mapPropsToArrayItem(fields, additionalPropsObj || {});
  });

  return (
    <Fragment key={arrayItemIndex}>
      <Grid
        spacing={context === 'create' ? 3 : 0}
        container
        rowSpacing={3}
        sx={{ pr: context === 'edit' ? 1 : 0, pb: 1, pl: context === 'edit' ? 2 : 0 }}
      >
        {fieldsWithArrayItemProps.map((field, fieldIndex) => (
          <Grid
            item
            sx={{ mb: context === 'edit' ? 0 : 3 }}
            sm={fieldsWithArrayItemProps[fieldIndex]?.fieldWidth || MAX_COLUMN_WIDTH}
            key={`${arrayItemIndex}-${fieldIndex}`}
          >
            <FormControl key={`${arrayItemIndex}-${field}`} fullWidth size="small">
              <Field
                {...omit(fieldsWithArrayItemProps[fieldIndex], 'fieldWidth')}
                disabled={fieldsWithArrayItemProps[fieldIndex]?.disabled || isFieldOrButtonDisabled}
                name={`${arrayName}.${arrayItemIndex}.${fieldsWithArrayItemProps[fieldIndex].name}`}
                label={Localize.get(fieldsWithArrayItemProps[fieldIndex].label)}
                component={FormComponentsMap[fieldsWithArrayItemProps[fieldIndex].component]}
              />
            </FormControl>
          </Grid>
        ))}
      </Grid>

      {!arrayConfig?.actions?.areButtonsHidden && (
        <Button
          disabled={isFieldOrButtonDisabled || arrayConfig?.actions?.isRemoveDisabled}
          type="button"
          sx={{
            ml: context !== 'create' ? 2 : 0,
            mt: 1,
            mb: ['edit', 'create'].includes(context) ? 2 : 0
          }}
          disableFocusRipple
          variant="outlined"
          startIcon={<RemoveIcon />}
          onClick={() => remove(arrayItemIndex)}
        >
          {Localize.get(arrayDefinitions(arrayName).removeButton)}
        </Button>
      )}
    </Fragment>
  );
};

export default memo(ArrayItem);
