import { Fragment, useMemo } from 'react';
import Localize from 'react-intl-universal';
import { useSearch } from 'react-location';

import { get } from 'lodash';
import moment from 'moment';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { isDateStringValid } from '@common/helpers/dates';
import { useAppStyles } from '@navigation/AppRouter';

const DetailsTitle = ({ details = null, config = { propertiesToShow: [] } }) => {
  const search = useSearch();
  const appStyles = useAppStyles({ isBlur: search?.mode === 'create' });

  const labels = useMemo(
    () =>
      config?.propertiesToShow
        .filter(({ field }) => get(details, field, '' !== ''))
        .map(
          ({ field, label }) =>
            `${Localize.get(label)}: ${
              isDateStringValid(get(details, field, ''))
                ? moment(get(details, field, '')).format('DD.MM.YYYY')
                : get(details, field, '')
            }`
        ),
    [config?.propertiesToShow]
  );

  return (
    <Box
      className={appStyles.blur}
      display="flex"
      flexDirection="row"
      sx={{
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        maxWidth: '950px'
      }}
    >
      {labels.map((label) => (
        <Fragment key={label}>
          <Typography
            sx={{
              fontWeight: 600,
              p: '6px 0px',
              maxWidth: '350px',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis'
            }}
          >
            {label.split(':')[0]}:
          </Typography>
          <Typography
            sx={{
              p: '6px 8px',
              maxWidth: '350px',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis'
            }}
          >
            {label.split(':')[1]}
          </Typography>
        </Fragment>
      ))}
    </Box>
  );
};

export default DetailsTitle;
