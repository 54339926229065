import Localize from 'react-intl-universal';

import * as yup from 'yup';

import { FILTER_KEYS } from './operators';

export default () => {
  return yup.object().shape({
    filters: yup.array().of(
      yup.object().shape({
        value: yup.lazy((_, options) => {
          const { type } = options.parent;

          switch (type) {
            case 'string':
              return yup.string().when('operator', {
                is: (e) => e.key === FILTER_KEYS.IsEmpty || e.key === FILTER_KEYS.IsNotEmpty,
                then: yup.string().notRequired(),
                otherwise: yup.string().required(Localize.get('ValidationMessages.ValueRequired'))
              });

            case 'select':
              return yup
                .string()
                .required(Localize.get('ValidationMessages.ValueRequired'))
                .nullable();

            case 'date':
              return yup
                .date()
                .required(Localize.get('ValidationMessages.ValueRequired'))
                .nullable();

            case 'number':
              return yup
                .number()
                .min(0, Localize.get('ValidationMessages.NegativeNumber'))
                .required(Localize.get('ValidationMessages.ValueRequired'));

            default:
              return yup.mixed().notRequired();
          }
        })
      })
    )
  });
};
