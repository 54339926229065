import { TAB_KEYS } from '@common/Constants';
import AttachmentsTable from '@components/AttachmentsTable';
import NotesTable from '@components/NotesTable';

import ActivitiesOverview from '../Overview/ActivitiesOverview';

export const ACTIVITIES_TABS_COMPONENTS = {
  [TAB_KEYS.OVERVIEW]: ActivitiesOverview,
  [TAB_KEYS.NOTE]: NotesTable,
  [TAB_KEYS.ATTACHMENT]: AttachmentsTable
};

export default {
  activeIndex: 0,
  tabs: [
    {
      label: 'Labels.Overview',
      key: TAB_KEYS.OVERVIEW,
      icon: 'info',
      isCountVisible: false
    },
    {
      label: 'Labels.Attachments',
      key: TAB_KEYS.ATTACHMENT,
      icon: 'attachments',
      options: [
        {
          fieldName: 'add',
          disabled: false,
          icon: 'add'
        },
        {
          fieldName: 'delete',
          disabled: false,
          icon: 'delete'
        }
      ],
      columns: [
        {
          field: 'name',
          headerName: 'Labels.Name',
          width: 250,
          editable: false,
          headerAlign: 'left',
          filterable: true
        },
        {
          field: 'size',
          headerName: 'Labels.Size',
          width: 250,
          editable: false,
          defaultValue: '0 KB',
          headerAlign: 'left',
          filterable: true
        },
        {
          field: 'createdBy',
          headerName: 'Labels.CreatedBy',
          width: 250,
          editable: false,
          defaultValue: 'Unknown User',
          headerAlign: 'left',
          filterable: true
        }
      ]
    },
    {
      label: 'Labels.Notes',
      key: TAB_KEYS.NOTE,
      icon: 'note_alt',
      options: [
        {
          fieldName: 'add_note',
          disabled: false,
          icon: 'add'
        },
        {
          fieldName: 'delete',
          disabled: false,
          icon: 'delete'
        }
      ],
      columns: [
        {
          field: 'type.value',
          headerName: 'Labels.Type',
          width: 250,
          editable: false,
          headerAlign: 'left'
        },
        {
          field: 'text',
          headerName: 'Labels.Text',
          width: 250,
          editable: false,
          headerAlign: 'left'
        }
      ]
    }
  ]
};
