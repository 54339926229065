import React, { useEffect, useState } from 'react';
import Localize from 'react-intl-universal';

import { get } from 'lodash';

import CancelIcon from '@mui/icons-material/Cancel';
import CloseIcon from '@mui/icons-material/Close';
import FilterNoneIcon from '@mui/icons-material/FilterNone';
import SaveIcon from '@mui/icons-material/Save';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';

import CreateFooter from '@components/CreateFooter';

import DialogDataGrid from './DialogDataGrid';

function Tag(props) {
  const { label, onDelete, disabled, ...other } = props;
  return (
    <div {...other}>
      <span>{label}</span>
      <CloseIcon disabled={disabled} onClick={onDelete} />
    </div>
  );
}

const StyledTag = styled(Tag)(
  ({ theme }) => `
  display: flex;
  align-items: center;
  height: 24px;
  margin: 2px;
  line-height: 22px;
  background-color: ${theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.08)' : '#fafafa'};
  border: 1px solid ${theme.palette.mode === 'dark' ? '#303030' : '#e8e8e8'};
  border-radius: 2px;
  box-sizing: content-box;
  padding: 0 4px 0 10px;
  outline: 0;
  overflow: hidden;

  &:focus {
    border-color: ${theme.palette.mode === 'dark' ? '#177ddc' : '#40a9ff'};
    background-color: ${theme.palette.mode === 'dark' ? '#003b57' : '#e6f7ff'};
  }

  & span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 1rem;
    
  }

  & svg {
    font-size: 12px;
    cursor: pointer;
    padding: 4px;
  }
`
);

const StyledIcon = styled(FilterNoneIcon)(
  () => `
    &:hover {
      cursor: pointer;
    }
    
  `
);

const StyleList = styled('ul')(` padding: 0 25px; font-size: 0.9rem;`);

const TokenValueModalChipArray = ({
  field = {},
  form: { touched, errors } = {},
  onIconClick = () => {},
  selectItem = () => {},
  selectionModel = [],
  extendedComponents,
  dialogTitle = '',
  tokenValue = [],
  disabled = false,
  dialogColumns = [],
  fetchDataPath = '',
  initQueryState = {},
  getRowId = undefined,
  required = false,
  ...rest
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const slicedTokenValues = tokenValue.slice(0, 1);
  const tooltip = (
    <StyleList>
      {tokenValue.slice(1, tokenValue.length).map((option, index) => (
        <li key={index}>{option.title}</li>
      ))}
    </StyleList>
  );

  const onSelectChange = (selectedIds, data) => {
    if (!data?.length) {
      return;
    }

    if (!Array.isArray(selectedIds)) {
      return;
    }

    // Save selection data (could be new page)
    const onePageData = data.filter(({ id }) =>
      selectedIds.some((selectedId) => id === selectedId)
    );

    // Merge previousUrlParams selectedItems with onePage data (which can be new page) by removing duplicates
    const mergedValues = [...new Set(onePageData.concat(...selectedItems))];

    const newItems = mergedValues
      // Filter non-selected values
      .filter(({ id }) => selectedIds.includes(id))
      // Remove duplicates
      .filter((value, index, self) => index === self.findIndex((t) => t.id === value.id));

    setSelectedItems(newItems);
  };

  useEffect(() => {
    if (modalOpen) {
      setSelectedItems(selectionModel);
    } else {
      setSelectedItems([]);
    }
  }, [modalOpen]);

  return (
    <>
      <Dialog fullWidth maxWidth={'lg'} onClose={() => setModalOpen(false)} open={modalOpen}>
        <DialogTitle>{Localize.get(dialogTitle)}</DialogTitle>
        <DialogContent>
          <DialogDataGrid
            fetchDataPath={fetchDataPath}
            initQueryState={initQueryState}
            columns={dialogColumns}
            selectionModel={selectionModel}
            extendedComponents={extendedComponents}
            getRowId={getRowId}
            onSelectChange={onSelectChange}
            selectedItems={selectedItems}
          />
        </DialogContent>
        <CreateFooter position="absolute">
          <Button
            data-test-id="cancel-btn"
            variant="outlined"
            onClick={() => {
              setModalOpen(false);
            }}
            color="error"
            startIcon={<CancelIcon />}
          >
            {Localize.get('Buttons.Cancel')}
          </Button>
          <Button
            data-test-id="save-btn"
            variant="outlined"
            startIcon={<SaveIcon />}
            onClick={() => {
              selectItem(selectedItems);
              setModalOpen(false);
            }}
          >
            {Localize.get('Labels.Select')}
          </Button>
        </CreateFooter>
      </Dialog>
      <TextField
        label="limitTags"
        onKeyPress={(event) => event.preventDefault()}
        style={{ caretColor: 'transparent' }}
        autoComplete="off"
        readOnly={true}
        disabled={disabled}
        size="small"
        variant="outlined"
        error={Boolean(get(touched, field?.name) && get(errors, field?.name))}
        helperText={get(touched, field?.name) && get(errors, field?.name)}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                data-test-id={field?.name}
                disabled={disabled}
                onClick={() => setModalOpen(true)}
              >
                <StyledIcon />
              </IconButton>
            </InputAdornment>
          ),
          startAdornment: slicedTokenValues?.length ? (
            <InputAdornment position="start">
              {slicedTokenValues.map((option, index) => (
                <IconButton
                  disableRipple
                  disabled={disabled}
                  key={index}
                  onClick={() => onIconClick(option.id, option)}
                >
                  <StyledTag label={option.title} />
                </IconButton>
              ))}
              {tokenValue?.length > 1 ? (
                <Tooltip title={tooltip}>
                  <span>
                    <IconButton disableRipple disabled={disabled}>
                      <StyledTag
                        label={`${tokenValue?.length - slicedTokenValues?.length} ${Localize.get(
                          'Calendar.ShowMore'
                        )}`}
                      />
                    </IconButton>
                  </span>
                </Tooltip>
              ) : null}
            </InputAdornment>
          ) : null
        }}
        {...field}
        {...rest}
        required={required || Boolean(get(errors, field?.name))}
      ></TextField>
    </>
  );
};

export default TokenValueModalChipArray;
