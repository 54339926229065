import moment from 'moment';

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { dateFormatTime } from '@common/Constants';
import { EMPLOYEES_PATHS } from '@common/network/ApiPaths';
import { getByPathAndParams } from '@services/BaseApi';

export const PAGE_SIZE = 5;
export const PAGE_SIZE_OPTIONS = [5, 10, 15];
export const initialState = {
  data: [],
  rows: [],
  filter: {
    search: '',
    page: 0,
    size: PAGE_SIZE
  },
  totalPages: 0,
  totalElements: 0,
  isLoading: false,
  selectionModel: [],
  mode: 'details'
};

export const fetchParticipations = createAsyncThunk(
  'employeeParticipations/participations',
  async ({ entityId, filter }, { rejectWithValue }) => {
    try {
      const response = await getByPathAndParams({
        path: EMPLOYEES_PATHS.GET_PARTICIPATIONS,
        pathVariables: { id: entityId },
        params: filter
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const employeeParticipationsTableSlice = createSlice({
  name: 'employeeParticipations',
  initialState,
  reducers: {
    resetState: () => initialState,
    setData: (state, { payload }) => {
      state.data = payload;
    },
    setMode: (state, { payload }) => {
      state.mode = payload;
    },
    setSelectionModel: (state, { payload }) => {
      if (Array.isArray(payload)) {
        state.selectionModel = [...payload];
      } else {
        const index = state.selectionModel.findIndex((s) => s === payload);
        if (index === -1) {
          state.selectionModel = [...state.selectionModel, payload];
        } else {
          state.selectionModel = [
            ...state.selectionModel.slice(0, index),
            ...state.selectionModel.slice(index + 1)
          ];
        }
      }
    },
    setFilterParams: (state, action) => {
      const newFilterValues = Array.isArray(action.payload)
        ? action.payload.reduce((obj, item) => ({ ...obj, [item.key]: item.value }), {})
        : { [action.payload.key]: action.payload.value };

      state.filter = {
        ...state.filter,
        ...newFilterValues,
        page: action.payload.page ?? 0
      };
      state.isLoading = !!(action.payload.key === 'search' && !action.payload.value);
    }
  },
  extraReducers: (builder) => {
    builder
      // Get all
      .addCase(fetchParticipations.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.totalPages = payload?.totalPages || state.totalPages;
        state.totalElements = payload?.totalElements || state.totalElements;
        state.data = payload?.content.map((el) => ({
          ...el,
          startDateTime: moment(el.startDateTime).format(dateFormatTime.dateTime),
          endDateTime: moment(el.endDateTime).format(dateFormatTime.dateTime)
        }));
      })
      .addCase(fetchParticipations.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchParticipations.rejected, (state) => {
        state.data = [];
        state.isLoading = false;
      });
  }
});
export const selectList = (state) => state.employeeParticipations.data;
export const selectRows = (state) => state.cemployeeParticipations.rows;
export const selectTotalElements = (state) => state.employeeParticipations.totalElements;
export const selectTotalPages = (state) => state.employeeParticipations.totalPages;
export const selectFilter = (state) => state.employeeParticipations.filter;
export const selectIsLoading = (state) => state.employeeParticipations.isLoading;
export const selectMode = (state) => state.employeeParticipations.mode;
export const selectSelectionModel = (state) => state.employeeParticipations.selectionModel;
const { actions, reducer } = employeeParticipationsTableSlice;
export const { setData, setFilterParams, resetState, setSelectionModel, setMode } = actions;

export default reducer;
