import { ReactLocationSimpleCache } from 'react-location-simple-cache';

import {
  ACTIVITIES_PATHS,
  ADDRESS_PATH,
  CONTACTS_PATHS,
  LOCALES_PATHS,
  PARTICIPANTS_PATHS,
  PERSONS_PATHS
} from '@common/network/ApiPaths';
import ExternalLogin from '@pages/Login/ExternalLogin';
import Login from '@pages/Login/Login';
import { getByPathAndParams } from '@services/BaseApi';

import ActivityRoute from './Activity';
import BusinessPartnerRoute from './BusinessPartner';
import CalendarRoute from './Calendar';
import ContactRoute from './Contact';
import CourseRoute from './Course';
import EmployeeRoute from './Employee';
import EventRoute from './Event';
import IltSessionRoute from './IltSession';
import LaunchpadRoute from './Launchpad';
import LocationRoute from './Location';
import MeetingRoomRoute from './MeetingRoom';
import NotFoundRoute from './NotFound';
import OrgStructureRoute from './OrgStructure';
import ParticipantRoute from './Participant';
import ResourceRoute from './Resource';

const routeCache = new ReactLocationSimpleCache();

const privateRoutes = [
  {
    path: '/login',
    element: <Login />
  },
  {
    path: '/saml-login/:externalToken',
    element: <ExternalLogin />
  },
  {
    // Global data resolver
    loader: routeCache.createLoader(
      async () => ({
        emailTypes: await getByPathAndParams({
          path: CONTACTS_PATHS.EMAIL_TYPES
        }),
        addressTypes: await getByPathAndParams({
          path: ADDRESS_PATH.ADDRESS_TYPES
        }),
        activityTypes: await getByPathAndParams({
          path: ACTIVITIES_PATHS.ACTIVITIES_TYPES
        }),
        phoneTypes: await getByPathAndParams({
          path: CONTACTS_PATHS.CONTACTS_PHONE_TYPES
        }),
        genders: await getByPathAndParams({
          path: PERSONS_PATHS.GENDERS
        }),
        currency: await getByPathAndParams({
          path: LOCALES_PATHS.CURRENCIES
        }),
        participantData: await getByPathAndParams({
          path: PARTICIPANTS_PATHS.PARTICIPANT_FORM
        }),
        participantStatuses: await getByPathAndParams({
          path: PARTICIPANTS_PATHS.PARTICIPANT_STATUSES
        })
      }),
      { policy: 'cache-first' }
    ),
    children: [
      LaunchpadRoute(),
      OrgStructureRoute(),
      CalendarRoute(),
      MeetingRoomRoute(routeCache),
      ActivityRoute(routeCache),
      BusinessPartnerRoute(routeCache),
      LocationRoute(routeCache),
      ContactRoute(routeCache),
      ParticipantRoute(routeCache),
      ResourceRoute(routeCache),
      EventRoute(routeCache),
      EmployeeRoute(routeCache),
      CourseRoute(routeCache),
      IltSessionRoute(routeCache),
      NotFoundRoute(routeCache)
    ]
  }
];

export default privateRoutes;
