import Localize from 'react-intl-universal';

import moment from 'moment';

import { FOCUS_FIELD_TIMEOUT, dateInitFormats } from '@common/Constants';
import { dateToFormat } from '@common/helpers/dates';
import mapPropsToComponent from '@common/helpers/mapPropsToComponent';
import { DIALOG_PATHS } from '@common/network/ApiPaths';
import { WEBINAR_TYPE_ID } from '@pages/IltSession/iltSessionSlice';
import create from '@pages/IltSession/util/fields/create';
import createWebinar from '@pages/IltSession/util/fields/createWebinar';
import initialValues from '@pages/IltSession/util/initialValues';

export const getFieldsConfig = (
  setFieldValue,
  values,
  setValues,
  setFieldTouched,
  filteredTypes,
  status,
  isNavigatedFromCourses,
  errors,
  clearDragAndDrop,
  onSessionDayUpdate,
  onVenueChange,
  onSessionDayDateChange,
  onRoomRemove,
  sessionDayMetrics
) => {
  const isWebinar = values?.type === WEBINAR_TYPE_ID;

  return mapPropsToComponent(isWebinar ? createWebinar : create, {
    ['status']: {
      options: status
    },
    ['type']: {
      options: filteredTypes
    },
    ['publishInCatalog']: {
      tooltip: {
        title: Localize.get('IltSession.Tooltips.PublishInCatalog')
      }
    },
    ['hasWaitingList']: {
      tooltip: {
        title: Localize.get('IltSession.Tooltips.WaitingList')
      }
    },
    ['automaticWaitingList']: {
      isVisible: values?.hasWaitingList,
      tooltip: {
        title: Localize.get('IltSession.Tooltips.AutomaticWaitingList')
      }
    },
    ['automaticWaitingListCancellation']: {
      isVisible: values?.hasWaitingList,
      onClick: (e) => {
        if (e?.target?.checked) {
          setTimeout(
            () => document.getElementsByName('automaticCancellationDate')[0]?.focus(),
            FOCUS_FIELD_TIMEOUT
          );
        }
      },
      tooltip: {
        title: Localize.get('IltSession.Tooltips.AutomaticWaitingListCancellation')
      }
    },
    ['automaticCancellationDate']: {
      isVisible:
        values?.automaticWaitingList &&
        values?.hasWaitingList &&
        values?.automaticWaitingListCancellation,
      onChange: (value) =>
        setFieldValue('automaticCancellationDate', dateToFormat(value, dateInitFormats.dateTime)),
      disablePast: true,
      minDateTime: moment(),
      ampm: false
    },
    ['hasRegistrationPeriod']: {
      tooltip: {
        title: Localize.get('IltSession.Tooltips.RegistrationPeriod')
      },
      onClick: (e) => {
        if (e?.target?.checked) {
          setTimeout(
            () => document.getElementsByName('registrationFrom')[0]?.focus(),
            FOCUS_FIELD_TIMEOUT
          );
        }
      }
    },
    ['hasCancellationDeadline']: {
      tooltip: {
        title: Localize.get('IltSession.Tooltips.CancellationDeadline')
      },
      onClick: (e) => {
        if (e?.target?.checked) {
          setTimeout(
            () => document.getElementsByName('cancellationDeadline')[0]?.focus(),
            FOCUS_FIELD_TIMEOUT
          );
        }
      }
    },
    ['registrationFrom']: {
      isVisible: values?.hasRegistrationPeriod,
      ampm: false,
      onChange: (value) =>
        setFieldValue('registrationFrom', dateToFormat(value, dateInitFormats.dateTime))
    },
    ['registrationUntil']: {
      isVisible: values?.hasRegistrationPeriod,
      ampm: false,
      onChange: (value) =>
        setFieldValue('registrationUntil', dateToFormat(value, dateInitFormats.dateTime))
    },
    ['cancellationDeadline']: {
      isVisible: values?.hasCancellationDeadline,
      ampm: false,
      onChange: (value) =>
        setFieldValue('cancellationDeadline', dateToFormat(value, dateInitFormats.dateTime))
    },
    ['venueDtos']: {
      tokenValue: values?.venueDtos?.map((venue) => ({ title: venue.name, id: venue.id })) ?? [],
      onIconClick: (id, venue) => {
        const filtered = values?.venueDtos?.filter((item) => item.id !== id);
        setFieldValue('venueDtos', filtered);

        // Remove room if belongs to removed venue
        if (venue?.id === values?.sessionDayDto?.meetingRoomId?.venue?.id) {
          setFieldValue('sessionDayDto.meetingRoomId', initialValues.sessionDayDto.meetingRoomId);
          onRoomRemove();
        }

        if (!filtered?.length) {
          setFieldValue('sessionDayDto.meetingRoomId', initialValues.sessionDayDto.meetingRoomId);
          clearDragAndDrop();
        }

        onVenueChange(filtered);
      },
      selectItem: (value = []) => {
        if (!value?.length) {
          setFieldValue('venueDtos', initialValues.venueDtos);
          setFieldValue('sessionDayDto.meetingRoomId', initialValues.sessionDayDto.meetingRoomId);
          clearDragAndDrop();
          return;
        }

        // Remove room if belongs to removed venue when venue is removed
        if (value?.length < values?.venueDtos?.length) {
          value?.forEach((venue) => {
            if (venue?.id !== values?.sessionDayDto?.meetingRoomId?.venue?.id) {
              setFieldValue(
                'sessionDayDto.meetingRoomId',
                initialValues.sessionDayDto.meetingRoomId
              );
              clearDragAndDrop();
            }
          });
        }

        setFieldValue('venueDtos', value);
        onVenueChange(value);
      },
      selectionModel: values.venueDtos
    },
    ['sessionDayDto.instructor']: {
      tokenValue:
        values?.sessionDayDto?.instructor?.map((instructor) => ({
          title: `${instructor.firstName} ${instructor.lastName}`,
          id: instructor.id
        })) ?? [],
      onIconClick: (id) => {
        setFieldValue(
          'sessionDayDto.instructor',
          values.sessionDayDto.instructor.filter((instructor) => instructor.id !== id)
        );
      },
      selectItem: (value = []) => {
        setFieldValue('sessionDayDto.instructor', value);
      },
      selectionModel: values.sessionDayDto.instructor
    },
    ['sessionDayDto.meetingRoomId']: {
      helperText: 'ValidationMessages.MeetingRoomILTSession',
      fetchDataPath: DIALOG_PATHS.MEETING_ROOMS,
      disabled:
        values?.venueDtos?.length === 0 ||
        !values?.sessionDayDto?.name ||
        Boolean(
          errors?.sessionDayDto?.endTime === Localize.get('ValidationMessages.EndTimeAfterStart')
        ),
      tokenValue:
        values?.sessionDayDto?.meetingRoomId?.id && values?.sessionDayDto?.meetingRoomId?.name
          ? [
              {
                title: `${values?.sessionDayDto?.meetingRoomId?.name} `
              }
            ]
          : undefined,
      onIconClick: (e, result, resource) => {
        if (e?.target?.name === 'delete') {
          setFieldValue('sessionDayDto.meetingRoomId', initialValues.sessionDayDto.meetingRoomId);
          onRoomRemove();
        }

        if (e?.target?.name === 'save') {
          if (!result?.length) {
            setFieldValue('sessionDayDto.dayDate', initialValues.sessionDayDto.dayDate);
            setFieldValue('sessionDayDto.startTime', initialValues.sessionDayDto.startTime);
            setFieldValue('sessionDayDto.endTime', initialValues.sessionDayDto.endTime);
            setFieldValue('sessionDayDto.meetingRoomId', initialValues.sessionDayDto.meetingRoomId);
            return;
          }

          const { start, end } = result[0];

          setFieldValue('sessionDayDto.meetingRoomId', {
            ...resource,
            name: resource?.resourceTitle,
            id: resource?.resourceId
          });
          setFieldValue('sessionDayDto.dayDate', moment(start).format(dateInitFormats.basicDate));
          setFieldValue('sessionDayDto.startTime', start);
          setFieldValue('sessionDayDto.endTime', end);
        }
      }
    },
    ['course']: {
      disabled: isNavigatedFromCourses,
      tokenValue:
        values?.course?.id && values.course?.name
          ? [
              {
                title: `${values.course.name} `
              }
            ]
          : undefined,
      onIconClick: (e) => {
        if (e?.target?.name === 'delete') {
          setValues({
            ...values,
            course: initialValues.course,
            iltModule: initialValues.iltModule
          });
        }
      },
      selectItem: (value) => setValues({ ...values, course: value, iltModule: null })
    },
    ['iltModule']: {
      tokenValue:
        values?.iltModule?.id && values.iltModule?.name
          ? [{ title: `${values.iltModule.name}` }]
          : undefined,
      onIconClick: (e) => {
        if (e?.target?.name === 'delete') setFieldValue('iltModule', null);
      },
      selectItem: (value) => {
        setValues({ ...values, iltModule: value, name: value?.name });
        setTimeout(() => document.getElementsByName('name')[0]?.focus(), FOCUS_FIELD_TIMEOUT);
      },
      initQueryState: {
        courseId: values?.course?.id
      },
      disabled: !values?.course?.id || isNavigatedFromCourses
    },
    ['sessionDayDto.name']: {
      onChange: (e) => {
        setFieldValue('sessionDayDto.name', e.target.value);
        onSessionDayUpdate('name', e.target.value);
      }
    },
    ['sessionDayDto.dayDate']: {
      disabled: Boolean(values?.sessionDayDto.meetingRoomId?.id),
      onChange: (value) => {
        setFieldValue('sessionDayDto.dayDate', value || null);
        onSessionDayDateChange(value);
      },
      ampm: false
    },
    ['sessionDayDto.endTime']: {
      disabled: Boolean(values?.sessionDayDto.meetingRoomId?.id),
      ampm: false,
      onChange: (value) => {
        if (!value || !(value instanceof Date)) {
          onSessionDayUpdate('predefinedEnd', null);
          setFieldValue('sessionDayDto.endTime', null, true);
          setFieldTouched('sessionDayDto.endTime', true);
          return;
        }

        setFieldValue('sessionDayDto.endTime', moment(value, 'HH:mm'), true);
        onSessionDayUpdate('predefinedEnd', moment(value, 'HH:mm'));
      }
    },
    ['sessionDayDto.startTime']: {
      disabled: Boolean(values?.sessionDayDto.meetingRoomId?.id),
      ampm: false,
      onChange: (value) => {
        if (!value || !(value instanceof Date)) {
          onSessionDayUpdate('predefinedStart', null);
          setFieldValue('sessionDayDto.startTime', null, true);
          setFieldTouched('sessionDayDto.startTime', true);
          return;
        }

        setFieldValue('sessionDayDto.startTime', moment(value, 'HH:mm'), true);
        onSessionDayUpdate('predefinedStart', moment(value, 'HH:mm'));
      }
    },
    ['sessionDayDto.sendReminder']: {
      tooltip: {
        title: Localize.get('IltSession.Tooltips.SendRemindersBeforeSession')
      }
    },
    ['sessionDayDto.reminderValue']: {
      isVisible: values?.sessionDayDto.sendReminder
    },
    ['sessionDayDto.reminderMetric.id']: {
      isVisible: values?.sessionDayDto.sendReminder,
      options: sessionDayMetrics
    }
  });
};

export default getFieldsConfig;
