import { useSearch } from 'react-location';

import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

const LeftContainer = styled('main', {
  shouldForwardProp: (prop) => prop !== 'isFullScreen'
})(({ theme, isFullScreen }) => {
  const search = useSearch();
  return {
    display: isFullScreen ? 'none' : 'flex',
    flexDirection: 'column',
    position: 'relative',
    width: theme.sizes.leftContainer,
    pointerEvents: search?.mode === 'create' ? 'none' : 'auto',
    filter: search?.mode === 'create' ? 'blur(1px)' : 'none'
  };
});

const RightContainer = styled('main', {
  shouldForwardProp: (prop) => prop !== 'open' && prop !== 'isFullScreen'
})(({ theme, isFullScreen }) => ({
  marginLeft: isFullScreen ? '' : theme.sizes.rightContainerLeftMargin,
  marginRight: isFullScreen ? '' : theme.sizes.rightContainerLeftMargin,
  maxHeight: `calc(100vh - ${theme.sizes.appHeader})`,
  display: 'flex',
  flexDirection: 'column',
  width: isFullScreen ? theme.sizes.rightContainerFullScreen : theme.sizes.rightContainer,
  borderLeft: `1px solid ${theme.palette.grey[400]}`,
  borderRight: `1px solid ${theme.palette.grey[400]}`
}));

const LayoutContainer = styled(Box)({
  display: 'flex',
  width: '100%',
  flexDirection: 'row'
});

export { LeftContainer, RightContainer };

export default LayoutContainer;
