import React, { useEffect } from 'react';
import { Calendar as ReactCalendar, momentLocalizer } from 'react-big-calendar';
import Localize from 'react-intl-universal';
import { useDispatch, useSelector } from 'react-redux';

import moment from 'moment';
import deLocale from 'moment/locale/de';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';

import { alpha, darken } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import { CAL_BAR_STATE_FORMAT } from '@common/Constants';
import { formatToDate, dateToFormat } from '@common/helpers/dates';
import usePrevious from '@hooks/usePreviousState/usePrevious';
import { selectLanguage } from '@pages/Users/usersSlice';

import {
  selectList,
  selectIsLoading,
  fetchEvents,
  selectFilters,
  setSideBarDate,
  selectSideBarDate,
  selectCurrentView,
  setCurrentView
} from '../calendarSlice';
import { getStyle, isRangeChanged, getStartEndDateTimeObj } from '../utils/util';
import CustomEvent from './CustomEvent';
import CustomShowMore from './CustomShowMore';
import CustomToolbar from './CustomToolbar';

const DragAndDropCalendar = withDragAndDrop(ReactCalendar);

const COLOR_OPACITY = 0.8;

moment.updateLocale('en', [deLocale]);
const localizer = momentLocalizer(moment);

const useStyles = makeStyles((theme) => ({
  calendarContainer: {
    textAlign: 'center',
    display: 'flex',
    width: '100%',
    height: 'calc(100vh - 3rem)'
  },
  calendarComponent: {
    height: '100%',
    width: '100%',
    padding: '5px',
    backgroundColor: theme.palette.calendar.main,
    '& .rbc-month-view, & .rbc-time-view, & .rbc-agenda-view table.rbc-agenda-table': {
      border: `1px solid ${theme.palette.calendar.bigCalBorder}`
    },
    '& .rbc-header': {
      borderLeft: `1px solid ${theme.palette.calendar.bigCalBorder}`,
      borderBottom: `1px solid ${theme.palette.calendar.bigCalBorder}`
    },
    '& .rbc-time-header': {
      borderRight: `1px solid ${theme.palette.calendar.bigCalBorder}`
    },
    '& .rbc-time-header-content, & .rbc-day-bg, & .rbc-time-content > * + * > *, & .rbc-agenda-view table.rbc-agenda-table tbody > tr > td + td':
      {
        borderLeft: `1px solid ${theme.palette.calendar.bigCalBorder}`
      },
    '& .rbc-month-row, & .rbc-time-content': {
      borderTop: `1px solid ${theme.palette.calendar.bigCalBorder}`
    },
    '& .rbc-event-label': {
      display: 'none'
    },
    '& .rbc-timeslot-group': {
      borderBottom: `1px solid ${theme.palette.calendar.bigCalBorder}`
    },
    '& .rbc-day-slot .rbc-time-slot': {
      borderTop: `1px solid ${theme.palette.calendar.bigCalBorder}`
    },
    '& .rbc-off-range-bg': {
      backgroundColor: theme.palette.calendar.bigCalOfRange
    },
    '& .rbc-today': {
      backgroundColor: theme.palette.calendar.bigCalToday
    },
    '& .rbc-show-more': {
      textDecoration: 'none',
      margin: 'auto'
    },
    '& .rbc-btn-group > button': {
      color: theme.palette.calendar.labelColor
    },
    '& .rbc-active': {
      color: theme.palette.calendar.main
    },
    '& .rbc-current-time-indicator': {
      opacity: 0
    },
    '& .rbc-overlay': {
      backgroundColor: theme.palette.calendar.main
    }
  }
}));

const DnDCalendar = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const theme = useTheme();

  const eventList = useSelector(selectList);
  const isLoading = useSelector(selectIsLoading);
  const filtersState = useSelector(selectFilters);
  const sideBarDate = useSelector(selectSideBarDate);
  const currentView = useSelector(selectCurrentView);
  const lang = useSelector(selectLanguage);

  const previousSideBarDate = usePrevious(sideBarDate);
  const todaysDate = new Date();

  const onViewClick = (view) => {
    dispatch(setCurrentView(view));
  };

  const onNavigateClicked = (providedDate) => {
    dispatch(setSideBarDate(dateToFormat(providedDate, CAL_BAR_STATE_FORMAT)));
  };

  useEffect(() => {
    if (isRangeChanged(sideBarDate, previousSideBarDate)) {
      const startEndDateTimeObj = getStartEndDateTimeObj(sideBarDate);
      dispatch(
        fetchEvents({
          ...filtersState,
          ...startEndDateTimeObj
        })
      );
    }
  }, [sideBarDate]);

  useEffect(() => {
    const startEndDateTimeObj = getStartEndDateTimeObj(sideBarDate);
    dispatch(
      fetchEvents({
        ...filtersState,
        ...startEndDateTimeObj
      })
    );
  }, [filtersState]);

  return (
    <div className={classes.calendarContainer}>
      <Backdrop
        sx={{
          color: (theme) => theme.palette.primary.main,
          zIndex: (theme) => theme.zIndex.drawer + 1
        }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <DragAndDropCalendar
        className={classes.calendarComponent}
        defaultView="month"
        views={['month', 'week', 'day']}
        formats={{
          eventTimeRangeFormat: () => {
            return '';
          }
        }}
        date={formatToDate(sideBarDate)}
        startAccessor={(event) => new Date(event?.startDateTime ?? '')}
        endAccessor={(event) => new Date(event?.endDateTime ?? '')}
        components={{
          event: CustomEvent,
          toolbar: CustomToolbar
        }}
        defaultDate={todaysDate}
        view={currentView}
        events={eventList.map((myEvent) => ({ ...myEvent, currentView }))}
        localizer={localizer}
        culture={lang}
        onView={onViewClick}
        onNavigate={onNavigateClicked}
        resizable={false}
        selectable={true}
        popup={true}
        messages={{
          agenda: Localize.get('Calendar.Agenda'),
          allDay: Localize.get('Calendar.AllDay'),
          month: Localize.get('Calendar.Month'),
          week: Localize.get('Calendar.Week'),
          day: Localize.get('Calendar.Day'),
          today: Localize.get('Calendar.Today'),
          previous: Localize.get('Calendar.Previous'),
          next: Localize.get('Calendar.Next'),
          date: Localize.get('Calendar.Date'),
          noEventsInRange: Localize.get('Calendar.NoEventsInRange'),
          time: Localize.get('Calendar.Time'),
          tomorrow: Localize.get('Calendar.Tomorrow'),
          yesterday: Localize.get('Calendar.Yesterday'),
          showMore: CustomShowMore
        }}
        eventPropGetter={(event) => {
          let eventColor = getStyle(event.status, theme.palette);
          return {
            style: {
              backgroundColor: alpha(eventColor, COLOR_OPACITY),
              border: `1px solid ${darken(eventColor, 0.15)}`
            }
          };
        }}
      />
    </div>
  );
};

export default DnDCalendar;
