import { Navigate } from 'react-location';

import OrgStructures, { CreateOrgStructure } from '@pages/OrgStructures';

const OrgStructureRoute = () => ({
  path: 'org-structure',
  children: [
    {
      path: '/',
      element: <OrgStructures />
    },
    {
      path: 'new',
      element: <CreateOrgStructure />
    },
    {
      element: <Navigate to="/meeting-rooms" />
    }
  ]
});

export default OrgStructureRoute;
