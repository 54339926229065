import React from 'react';
import { Pie, Bar } from 'react-chartjs-2';
import Localize from 'react-intl-universal';

import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  Title,
  CategoryScale,
  LinearScale,
  BarElement
} from 'chart.js';
// import ChartDataLabels from 'chartjs-plugin-datalabels'; will be used for labels on the chart
import { get } from 'lodash';

import { alpha } from '@mui/material';
import Box from '@mui/material/Box';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import ListItem from '@mui/material/ListItem';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/styles';

import placeholder from '@assets/images/placeholder-chart.png';
import percentage from '@common/helpers/numbers/percentage';

ChartJS.defaults.font.family = '"Roboto", "Helvetica", "Arial",sans-serif';
ChartJS.register(ArcElement, Tooltip, Legend, Title, CategoryScale, LinearScale, BarElement);

const COLOR_OPACITY = 0.6;

const BorderLinearProgress = styled(LinearProgress, {
  shouldForwardProp: (prop) => prop !== 'progressColor'
})(({ theme, progressColor }) => ({
  width: '100%',
  height: 12,
  borderRadius: 3,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[400]
  },
  [`& .${linearProgressClasses.bar}`]: {
    backgroundColor: alpha(get(theme.palette, progressColor), COLOR_OPACITY)
  }
}));

const LinearProgressWithLabel = ({
  value = 0,
  progressColor = 'primary.main',
  label,
  currentValue
}) => {
  return (
    <Box sx={{ width: '100%', mr: 1 }}>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Typography sx={{ mr: 1, fontSize: '11px' }} component="span" color="text.primary">
          {label}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {currentValue}
        </Typography>
      </Box>

      <BorderLinearProgress value={value} variant="determinate" progressColor={progressColor} />
    </Box>
  );
};

const TileCharts = (type, dataset, iconNumber) => {
  const theme = useTheme();

  const backgroundCol = [
    alpha(theme.palette.primary.main, COLOR_OPACITY),
    alpha(theme.palette.info.light, COLOR_OPACITY),
    alpha(theme.palette.success.main, COLOR_OPACITY),
    alpha(theme.palette.error.main, COLOR_OPACITY),
    alpha(theme.palette.info.main, COLOR_OPACITY),
    alpha(theme.palette.grey[50], COLOR_OPACITY),
    alpha(theme.palette.accent.main, COLOR_OPACITY)
  ];

  const returnEventColor = (eventName) => {
    switch (eventName) {
      case 'Open':
      case 'Offen':
        return 'eventStatus.openDark';
      case 'In Progress':
      case 'In Bearbeitung':
        return 'eventStatus.inProgressDark';
      case 'Completed':
      case 'Abgeschlossen':
        return 'eventStatus.completedDark';
      case 'Cancelled':
      case 'Abgesagt':
        return 'eventStatus.cancelledDark';
      default:
        return 'primary.main';
    }
  };

  //Data for the Participant Pie
  const participantsChartData = {
    labels: dataset?.map((el) => el.name),
    datasets: [
      {
        data: dataset?.map((el) => el.users),
        backgroundColor: backgroundCol
      }
    ]
  };

  const calendarChartData = {
    labels: dataset?.map((el) => el.name),
    datasets: [
      {
        data: dataset?.map((el) => el.events),
        backgroundColor: backgroundCol
      }
    ]
  };

  return (
    <>
      {type === 'bar' &&
        (dataset.every((el) => el === 0) ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-around',
              alignItems: 'center',
              marginTop: '1.5rem'
            }}
          >
            <img alt="Chart Placeholder" src={placeholder} style={{ borderRadius: '7px' }} />
          </Box>
        ) : (
          <Bar
            data={calendarChartData}
            // plugins={[ChartDataLabels]} will be used for labels on the chart
            options={{
              indexAxis: 'y',

              scales: {
                y: { ticks: { color: `${theme.palette.chart.label}` } },
                x: { ticks: { color: `${theme.palette.chart.label}` } }
              },
              elements: {
                bar: {
                  borderWidth: 1
                }
              },
              layout: {
                autoPadding: false,
                padding: {
                  top: 20,
                  right: 18,
                  left: 16,
                  bottom: 16
                }
              },
              responsive: true,
              aspectRatio: 2,
              plugins: {
                datalabels: {
                  display: true,
                  color: 'white',
                  font: {
                    size: 16
                  }
                },
                legend: {
                  display: false,
                  position: 'right',
                  font: {
                    size: 14
                  }
                },
                title: {
                  display: true,
                  text: Localize.get('Launchpad.EventPerWeeks'),
                  font: 'Arial'
                }
              }
            }}
          />
        ))}

      {type === 'microBar' &&
        (dataset.every((el) => el === 0) ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-around',
              alignItems: 'center',
              marginTop: '2rem'
            }}
          >
            <img
              style={{ height: '6rem', borderRadius: '7px' }}
              alt="Chart Placeholder"
              src={placeholder}
            />
          </Box>
        ) : (
          <Box>
            {dataset.map((el) => (
              <ListItem key={el.name} sx={{ pb: '3px', pt: '3px' }}>
                <Box sx={{ width: '100%' }}>
                  <LinearProgressWithLabel
                    progressColor={returnEventColor(el.name)}
                    value={percentage(el.data, iconNumber)}
                    currentValue={el.data}
                    totalValue={iconNumber}
                    label={el.name}
                  />
                </Box>
              </ListItem>
            ))}
          </Box>
        ))}
      {type === 'donut' &&
        (dataset.every((el) => el === 0) ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-around',
              alignItems: 'center',
              marginTop: '1.5rem'
            }}
          >
            <img alt="Chart Placeholder" src={placeholder} style={{ borderRadius: '7px' }} />
          </Box>
        ) : (
          <Pie
            data={participantsChartData}
            // plugins={[ChartDataLabels]}
            options={{
              responsive: true,
              aspectRatio: 2,
              layout: {
                autoPadding: false,
                padding: {
                  top: 8,
                  right: 5,
                  left: 5,
                  bottom: 8
                }
              },
              plugins: {
                legend: {
                  display: true,
                  align: 'center',
                  position: 'right',
                  labels: {
                    showActualPercentages: true,
                    color: `${theme.palette.chart.label}`,
                    padding: 8,
                    font: {
                      size: 12
                    }
                  }
                },
                datalabels: {
                  display: true,
                  color: 'white',
                  font: {
                    size: 16
                  }
                }
              }
            }}
          />
        ))}
    </>
  );
};

export default TileCharts;
