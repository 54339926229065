import { darken } from '@mui/material';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

import { Eventcalendar } from '@mobiscroll/react';

const BOOKING_SLOT_COLORS = [
  '#7a8593',
  '#5c6768',
  '#99a6b4',
  '#9fc0d3',
  '#667d96',
  '#c2ad9d',
  '#c9b0de',
  '#afd1f2',
  '#6494b4',
  '#92b4cc',
  '#4c7c9c',
  '#aeb2a9',
  '#2c6ec4',
  '#c8ac95',
  '#859eac'
];

const StyledEvent = styled(Box, {
  shouldForwardProp: (prop) =>
    prop !== 'color' && prop !== 'isSelected' && prop !== 'backgroundColor'
})(({ theme, color, isSelected, backgroundColor }) => {
  return {
    backgroundColor: isSelected ? darken(backgroundColor, 0.2) : backgroundColor,
    borderLeft: `thick solid ${color}`,
    padding: `0 0 0 ${theme.spacing(0.5)}`,
    margin: 0,
    minHeight: theme.spacing(10),
    height: theme.spacing(10),
    maxHeight: theme.spacing(10)
  };
});

const StyledEventStatus = styled('span')(({ theme }) => ({
  color: theme.palette.grey[50],
  fontSize: '12px',
  fontWeight: 400,
  marginBottom: theme.spacing(2)
}));

const StyledEventTitle = styled('span', {
  shouldForwardProp: (prop) => prop !== 'count' && prop !== 'color'
})(({ theme, count }) => {
  return {
    color: theme.palette.grey[50],
    fontSize: count >= 10 ? '14px' : '16px',
    fontWeight: 600,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    width: 'auto',
    whiteSpace: 'nowrap'
  };
});

const StyledEventCalendar = styled(Eventcalendar)(({ theme }) => ({
  '& .mbsc-timeline-row-gutter': {
    height: '1px'
  },
  '& .mbsc-timeline-row': {
    minHeight: theme.spacing(10),
    height: theme.spacing(10),
    maxHeight: theme.spacing(10)
  },
  '& .mbsc-timeline-event': {
    padding: '0'
  },
  '& .mbsc-timeline-event.mbsc-schedule-event-all-day': {
    paddingRight: '0.1em',
    paddingLeft: 0
  },
  '& .mbsc-timeline-resource-col': {
    width: '100px;'
  },
  '& .mbsc-ios.mbsc-calendar-controls': {
    padding: 0
  },
  '& .mbsc-windows.mbsc-calendar-controls': {
    padding: '0 !important'
  },
  '& .mbsc-timeline-resource:has(div.mainResource)': {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    borderTopStyle: 'solid',
    borderTopWidth: '1px'
  },
  '& .mbsc-timeline-resource:has(div)': {
    borderBottomStyle: 'none'
  },
  '& .mbsc-timeline-resource:has(div):last-of-type': {
    borderBottomStyle: 'solid'
  },
  '& .mbsc-timeline-resource-title': {
    padding: 0
  },
  '& .mbsc-timeline-resources': {
    marginTop: '-1px'
  }
}));

export {
  StyledEvent,
  StyledEventStatus,
  StyledEventTitle,
  StyledEventCalendar,
  BOOKING_SLOT_COLORS
};
